import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import RadioButton from 'components/Form/RadioButton';
import { required } from 'lib/validation';
import OptionsType from 'types/Options';

const RadioButtonGroup = ({
  sx,
  options,
  name,
  legend,
  disabled,
  className,
  isRequired,
}) => {
  const field = useField(name);

  return (
    <FormGroup component="fieldset" sx={sx}>
      {!!legend && <FormLabel component="legend">{legend}</FormLabel>}
      <FormControl className={className}>
        {options.map((option) => (
          <Field
            key={option.value}
            component={RadioButton}
            disabled={disabled}
            label={option.label}
            name={name}
            type="radio"
            validate={isRequired && required}
            value={option.value}
          />
        ))}
      </FormControl>
      {field.meta.submitFailed && field.meta.error && (
        <FormHelperText error>{field.meta.error}</FormHelperText>
      )}
    </FormGroup>
  );
};

RadioButtonGroup.propTypes = {
  sx: PropTypes.object,
  legend: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionsType).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  sx: null,
  legend: null,
  disabled: false,
  className: null,
  isRequired: false,
};

export default RadioButtonGroup;
