import PropTypes from 'prop-types';
import { generatePath, Redirect, useParams } from 'react-router-dom';

const withEventAttendeeRouteGuard = (pathname) => (Component) => {
  const EventPriorityListPageContainer = (props) => {
    const { slug } = useParams();
    const { canLoadPage, ...rest } = props;

    if (!canLoadPage) {
      const eventDetailsPage = generatePath(pathname, { slug });

      return <Redirect to={eventDetailsPage} />;
    }

    return <Component {...rest} />;
  };

  EventPriorityListPageContainer.propTypes = {
    canLoadPage: PropTypes.bool,
  };

  EventPriorityListPageContainer.defaultProps = {
    canLoadPage: true,
  };

  return EventPriorityListPageContainer;
};

export default withEventAttendeeRouteGuard;
