import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const teamMemberSection = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);
