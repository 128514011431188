/** @jsxImportSource @emotion/react */
import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ChallengesList from 'components/Shared/ChallengesList';
import DashboardChallengeType from 'types/DashboardChallengeType';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import retailerDisplayedChallengeStatuses from 'constants/retailerDisplayedChallengeStatus';
import RetailerDashboardFilters from '../RetailerDashboardFilters';
import DashboardEventType from '../dashboardEventType';
import filterStyles from './styles';

const defaultEventFilterOptions = {
  ALL: 'All',
  NONE: 'None',
};

const ALL_CHALLENGES_STATUSES_OPTION = 'All';

const AllChallengesContent = ({ challenges, events }) => {
  const [search, setFilterByName] = useState('');
  const [selectedStatus, setFilterByStatus] = useState(ALL_CHALLENGES_STATUSES_OPTION);
  const [selectedEventOption, setFilterByEvent] = useState(defaultEventFilterOptions.ALL);

  const eventOptions = [
    { value: defaultEventFilterOptions.ALL, label: 'All' },
    { value: defaultEventFilterOptions.NONE, label: 'None' },
    ...events.map(({ id, name }) => ({ label: name, value: id })),
  ];
  const challengeStatusOptions = [
    ALL_CHALLENGES_STATUSES_OPTION,
    ...Object.values(retailerDisplayedChallengeStatuses),
  ];

  const filterByName = useCallback((challenge) => {
    const formattedSearch = search.toLowerCase().trim();

    if (!formattedSearch) {
      return true;
    }

    return challenge.name
      .toLowerCase()
      .includes(formattedSearch);
  }, [search]);

  const filterByStatus = useCallback((challenge) => {
    const { displayedStatus } = challenge;

    if (selectedStatus === ALL_CHALLENGES_STATUSES_OPTION) {
      return true;
    }

    return displayedStatus === selectedStatus;
  }, [selectedStatus]);

  const filterBySource = useCallback((challenge) => {
    if (selectedEventOption === defaultEventFilterOptions.ALL) {
      return true;
    }

    if (selectedEventOption === defaultEventFilterOptions.NONE) {
      return challenge.source === DEFAULT_CHALLENGE_SOURCE;
    }

    return challenge.source === selectedEventOption;
  }, [selectedEventOption]);

  const filteredChallenges = useMemo(() => (
    challenges
      .filter((challenge) => (
        filterByName(challenge)
        && filterByStatus(challenge)
        && filterBySource(challenge)
      ))
  ), [challenges, filterByStatus, filterBySource, filterByName]);

  return (
    <div>
      <RetailerDashboardFilters
        css={filterStyles}
        challengeStatusesList={challengeStatusOptions}
        eventOptions={eventOptions}
        selectedStatus={selectedStatus}
        selectedEventOption={selectedEventOption}
        onFilterByStatus={setFilterByStatus}
        onFilterByEvent={setFilterByEvent}
        search={search}
        onFilterByName={setFilterByName}
      />

      <ChallengesList challenges={filteredChallenges} isSourceShown />
    </div>
  );
};

AllChallengesContent.propTypes = {
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired).isRequired,
  events: PropTypes.arrayOf(DashboardEventType.isRequired).isRequired,
};

export default AllChallengesContent;
