import { css } from '@emotion/react';

const dialog = (theme) => (
  css`
    & .MuiDialog-paper {
      max-width: 800px;
      padding: ${theme.spacing(3)};
      box-sizing: border-box;
    }
  `
);

export default dialog;
