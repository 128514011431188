import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import eventParticipationRequestsAPI from 'api/eventParticipationRequests';
import eventParticipationRequestStatus from 'constants/eventParticipationRequestStatus';
import EventParticipationRequestType from 'types/EventParticipationRequest';

const ParticipationRequest = ({ participationRequest, eventId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector((state) => state.user.authUser.id);

  const initialStatus = participationRequest ? participationRequest.status : null;
  const [requestStatus, setRequestStatus] = useState(initialStatus);

  const handleRequestToParticipate = async () => {
    try {
      await eventParticipationRequestsAPI.create({ eventId, userId });
      setRequestStatus(eventParticipationRequestStatus.PENDING);

      enqueueSnackbar('Successfully sent participation request', { variant: 'success' });
    } catch (_) {
      enqueueSnackbar('Failed to send participation request', { variant: 'error' });
    }
  };

  return (
    <>
      {requestStatus === eventParticipationRequestStatus.REJECTED
        ? (
          <Typography variant="body2">Your participation request is rejected</Typography>
        ) : (
          <Button
            disabled={requestStatus === eventParticipationRequestStatus.PENDING}
            onClick={handleRequestToParticipate}
          >
            {requestStatus === eventParticipationRequestStatus.PENDING ? 'Application sent' : 'Attend this event'}
          </Button>
        )}
    </>
  );
};

ParticipationRequest.propTypes = {
  participationRequest: EventParticipationRequestType,
  eventId: PropTypes.string.isRequired,
};

export default ParticipationRequest;
