import { validateEmail } from 'lib/validation';

const validate = (values) => {
  const errors = {};

  if (values.username && values.domain) {
    const email = values.username.concat(values.domain);
    const validatedEmail = validateEmail(email);

    if (validatedEmail) {
      errors.username = validatedEmail;
    }
  }

  return errors;
};

export default validate;
