/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  ThumbLike20Regular,
  ThumbLike20Filled,
  ThumbDislike20Regular,
  ThumbDislike20Filled,
} from '@fluentui/react-icons';
import { addReviewVote } from 'store/thunk/vendorInfo';
import ReviewRelevanceVote from 'types/ReviewRelevanceVote';
import * as classes from './styles';

const reviewVoteOptions = {
  HELPFUL: 1,
  NOT_HELPFUL: -1,
};

const VoteReview = ({
  className,
  reviewId,
  votes,
  userId,
  onUpsertVote,
}) => {
  const userVote = votes.find((item) => item.userId === userId);
  const voteValue = userVote ? userVote.vote : 0;

  const handleRateReview = (vote) => {
    const body = {
      id: userVote ? userVote.id : null,
      userId,
      ratingsReviewsId: reviewId,
      vote,
    };

    onUpsertVote(body);
  };

  const positiveVotesCount = votes.reduce((acc, { vote }) => (
    vote === reviewVoteOptions.HELPFUL ? acc + 1 : acc
  ), 0);

  return (
    <div css={classes.wrap} className={className}>
      <Typography variant="body2">
        {positiveVotesCount > 0
          ? `${positiveVotesCount} of ${votes.length} users found this review helpful`
          : 'Was this review helpful to you?'
        }
      </Typography>
      <div css={classes.buttons}>
        <IconButton
          css={voteValue === reviewVoteOptions.HELPFUL && classes.selectedHelpfulIcon}
          onClick={() => handleRateReview(reviewVoteOptions.HELPFUL)}
        >
          {voteValue === reviewVoteOptions.HELPFUL ? <ThumbLike20Filled /> : <ThumbLike20Regular />}
        </IconButton>
        <IconButton
          css={voteValue === reviewVoteOptions.NOT_HELPFUL && classes.selectedNotHelpfulIcon}
          onClick={() => handleRateReview(reviewVoteOptions.NOT_HELPFUL)}
        >
          {voteValue === reviewVoteOptions.NOT_HELPFUL
            ? <ThumbDislike20Filled />
            : <ThumbDislike20Regular />
          }
        </IconButton>
      </div>
    </div>
  );
};

VoteReview.propTypes = {
  className: PropTypes.string,
  reviewId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  votes: PropTypes.arrayOf(ReviewRelevanceVote.isRequired).isRequired,
  onUpsertVote: PropTypes.func.isRequired,
};

VoteReview.defaultProps = {
  className: null,
};

export default connect(
  (state) => ({ userId: state.user.authUser.id }),
  (dispatch) => ({ onUpsertVote: (body) => dispatch(addReviewVote(body)) }),
)(VoteReview);
