/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  propTypes,
} from 'redux-form';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import RadioButtonYesNo from 'components/UI/RadioButtonYesNo';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import SubmitButton from 'components/UI/SubmitButton';
import Checkbox from 'components/Form/Checkbox';
import TextFieldInput from 'components/UI/TextFieldInput';
import DropDownInput from 'components/UI/DropDownInput';
import validate from './validation';
import {
  CURRENCY_OPTIONS,
  DEPARTMENTS,
  SALES_CYCLE_OPTIONS,
  RETAILER_TYPES,
} from './constants';
import CompanyMaturityStages from './CompanyMaturityStages';
import Employees from './Employees';
import * as classes from './styles';

class GrowthStaff extends Component {
  static propTypes = {
    countryValue: PropTypes.string,
    isVentureBacked: PropTypes.oneOf(Object.values(yesNoOptions)),
    officeOutsideUk: PropTypes.string,
    officeInsideUK: PropTypes.string,
    onUpdateProfile: PropTypes.func.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    isVentureBacked: null,
    countryValue: '',
    officeOutsideUk: '',
    officeInsideUK: '',
  };

  saveCompanyData = (isDraft) => (values) => {
    const { onUpdateProfile } = this.props;
    const { offices = [], vendors = {} } = values;
    const body = { vendors };

    let updatedOffices = [...offices];

    if (updatedOffices.length !== 0) {
      updatedOffices = updatedOffices.map((office) => {
        const obj = { ...office };

        if ('officeOutside' in office) {
          obj.officeOutside = obj.officeOutside === 'yes';
        }

        if ('officeInside' in office) {
          obj.officeInside = obj.officeInside === 'yes';
        }

        return obj;
      });

      if (updatedOffices[1] && updatedOffices[1].country === '') {
        updatedOffices[1].country = 'United Kingdom';
      }
    }

    body.offices = updatedOffices;

    return onUpdateProfile(body, isDraft);
  };

  static renderOptions = ({
    className,
    fields,
    legend,
    meta: { submitFailed, error },
  }) => (
    <FormControl className={className} component="fieldset" fullWidth>
      <FormLabel
        sx={{ mb: 1 }}
        component="legend"
        error={submitFailed && error}
      >
        {legend}
      </FormLabel>
      <FormGroup css={classes.options}>
        {fields.map((field, index) => (
          <Field
            key={field}
            type="checkbox"
            label={fields.get(index).name}
            name={`${field}.checked`}
            component={Checkbox}
          />
        ))}
      </FormGroup>
    </FormControl>
  );

  render() {
    const {
      countryValue,
      officeOutsideUk,
      officeInsideUK,
      isVentureBacked,
      handleSubmit,
      dirty,
      valid,
      submitSucceeded,
      submitting,
    } = this.props;

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <div>
          <div css={classes.section}>
            <Typography variant="body1">
              We ask for the following information to ensure we deliver the best matches between
              consumer brands and vendors.{' '}
              <span css={classes.bold}>
                Unless stated otherwise, all information provided on this page will remain
                confidential to Validify.
              </span>
            </Typography>
          </div>

          <div css={classes.section}>
            <Typography variant="h2" sx={{ mb: 3 }}>Customers</Typography>
            <div css={classes.field}>
              <FieldArray
                name="vendors.customers.types"
                component={GrowthStaff.renderOptions}
                legend="Which types of consumer brand do you target? (Tick all that apply)"
              />
              <Field
                sx={{ mt: 1 }}
                name="vendors.customers.other"
                component={TextFieldInput}
                placeholder="Please specify"
              />
            </div>

            <FieldArray
              css={classes.field}
              name="vendors.customers.departments"
              component={GrowthStaff.renderOptions}
              legend="Which departments do you sell to? (Tick all that apply)"
            />

            <div css={classes.field}>
              <FormLabel sx={{ mb: 1 }} component="fieldset">
                By annual revenue, what size customers do you target?
              </FormLabel>

              <div css={[classes.revenue, classes.revenueSection]}>
                <Field
                  fullWidth
                  label="From"
                  css={classes.control}
                  name="vendors.customers.currency.currencySmallest"
                  component={DropDownInput}
                >
                  {CURRENCY_OPTIONS.map(({ label, value }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </Field>
                <Field
                  css={[classes.control, classes.revenueInput]}
                  component={TextFieldInput}
                  placeholder="e.g. £15,000,000"
                  name="vendors.customers.currency.smallestValue"
                />
              </div>

              <div css={[classes.revenue, classes.revenueSection]}>
                <Field
                  fullWidth
                  label="To"
                  css={classes.control}
                  name="vendors.customers.currency.currencyLargest"
                  component={DropDownInput}
                >
                  {CURRENCY_OPTIONS.map(({ label, value }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </Field>
                <Field
                  css={[classes.control, classes.revenueInput]}
                  component={TextFieldInput}
                  placeholder="e.g. £350,000,000"
                  name="vendors.customers.currency.largestValue"
                />
              </div>
            </div>

            <Field
              css={classes.field}
              fullWidth
              label="How many months does your sales cycle last?"
              name="vendors.financialData.salesCycleLast"
              component={DropDownInput}
            >
              {SALES_CYCLE_OPTIONS.map((item) => (
                <option value={item} key={item}>{item}</option>
              ))}
            </Field>
            <Field
              css={classes.field}
              label="What is your average deal size?"
              name="vendors.customers.averageDealSize"
              component={TextFieldInput}
            />
            <Field
              css={classes.field}
              label="Your customer acquisition cost"
              name="vendors.productService.cost.customer"
              component={TextFieldInput}
            />
            <Field
              title="Do you offer Escrow protection for clients?"
              name="vendors.support.escrow"
              component={RadioButtonYesNo}
            />
          </div>

          <div css={classes.section}>
            <Typography variant="h2" sx={{ mb: 3 }}>Company growth</Typography>
            <CompanyMaturityStages css={classes.field} />
            <Field
              css={classes.field}
              title="Is the company venture backed?"
              name="vendors.financialData.isVentureBacked"
              component={RadioButtonYesNo}
            />
            {isVentureBacked === yesNoOptions.YES && (
              <fieldset css={classes.field}>
                <FormLabel sx={{ mb: 1 }} component="legend">
                  How much funding have you raised to date? (Added to profile)
                </FormLabel>

                <div css={classes.revenueSection}>
                  <Field
                    fullWidth
                    css={classes.control}
                    name="vendors.financialData.funding.currency"
                    component={DropDownInput}
                  >
                    {CURRENCY_OPTIONS.map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </Field>
                  <Field
                    css={[classes.control, classes.revenueInput]}
                    type="number"
                    name="vendors.financialData.funding.amount"
                    component={TextFieldInput}
                  />
                </div>
              </fieldset>
            )}
            <Field
              css={classes.field}
              label="Year-on-Year Growth (%)"
              name="vendors.financialData.growth"
              placeholder="Growth last financial year e.g. 15%"
              type="number"
              component={TextFieldInput}
            />

            <Field
              label="Crunchbase profile link (optional)"
              type="text"
              placeholder="https://"
              name="vendors.financialData.crunchbaseURI"
              component={TextFieldInput}
            />
          </div>

          <div css={classes.section}>
            <Typography variant="h2" sx={{ mb: 3 }}>Employees</Typography>
            {countryValue !== 'United Kingdom' && countryValue !== '' && (
              <>
                <Field
                  css={classes.field}
                  title="Do you have an office in the UK?"
                  name="offices[1].officeInside"
                  component={RadioButtonYesNo}
                />
                {officeInsideUK === 'yes' && <Employees />}

                <Field
                  css={classes.field}
                  placeholder="e.g. 23"
                  name="vendors.financialData.employees.globally"
                  label="Number of employees globally (inc UK) (Profile)"
                  component={TextFieldInput}
                />
                <Field
                  css={classes.field}
                  placeholder="e.g. 23"
                  name="vendors.financialData.employees.UK"
                  label="Number of employees in the UK (Profile)"
                  component={TextFieldInput}
                />
                <Field
                  title="Are you planning to grow the team in the UK in the next 12 months?"
                  name="vendors.financialData.planningGrowth"
                  component={RadioButtonYesNo}
                />
              </>
            )}
              {countryValue === 'United Kingdom' && countryValue !== '' && (
                <>
                  <Field
                    css={classes.field}
                    placeholder="e.g. 23"
                    name="vendors.financialData.employees.UK"
                    label="Number of employees in the UK (Profile)"
                    component={TextFieldInput}
                  />
                  <Field
                    css={classes.field}
                    title="Are you planning to grow the team in the UK in the next 12 months?"
                    name="vendors.financialData.planningGrowth"
                    component={RadioButtonYesNo}
                  />
                  <Field
                    css={officeOutsideUk === 'yes' && classes.field}
                    title="Do you have an office and / or employees outside of the UK?"
                    name="offices[0].officeOutside"
                    component={RadioButtonYesNo}
                  />
                  {officeOutsideUk === 'yes' && (
                    <Field
                      placeholder="e.g. 23"
                      name="vendors.financialData.employees.globally"
                      label="Number of employees globally (inc UK) (Profile)"
                      component={TextFieldInput}
                    />
                  )}
                </>
              )}
              {countryValue === '' && (
                <>
                  <Field
                    css={classes.field}
                    placeholder="e.g. 23"
                    name="vendors.financialData.employees.UK"
                    label="Number of employees in the UK (Profile)"
                    component={TextFieldInput}
                  />
                  <Field
                    css={classes.field}
                    title="Are you planning to grow the team in the UK in the next 12 months?"
                    name="vendors.financialData.planningGrowth"
                    component={RadioButtonYesNo}
                  />
                  <Field
                    css={officeOutsideUk === yesNoOptions.YES && classes.field}
                    title="Do you have an office and / or employees outside of the UK?"
                    name="offices[0].officeOutside"
                    component={RadioButtonYesNo}
                  />
                  {officeOutsideUk === yesNoOptions.YES && (
                    <Field
                      placeholder="e.g. 23"
                      name="vendors.financialData.employees.globally"
                      label="Number of employees globally (inc UK) (Profile)"
                      component={TextFieldInput}
                    />
                  )}
                </>
              )}
            </div>
        </div>
        <SubmitButton
          isLoading={submitting}
          onClick={handleSubmit(this.saveCompanyData(false))}
        >
          Save
        </SubmitButton>
      </DataCaptureFormContainer>
    );
  }
}

const selector = formValueSelector('growthStaffForm');

// TODO: store on BE only array of strings
const DEFAULT_DEPARTMENT_OPTIONS = DEPARTMENTS.map((name) => ({ name, checked: false }));
const DEFAULT_TARGET_RETAILER_LIST = RETAILER_TYPES.map((name) => ({ name, checked: false }));

const mapStateToProps = (state) => {
  let offices = [];

  if (state.user.company.offices) {
    offices = state.user.company.offices.map((office) => {
      const obj = { ...office };

      if ('officeOutside' in office) {
        obj.officeOutside = obj.officeOutside ? 'yes' : 'no';
      }

      if ('officeInside' in office) {
        obj.officeInside = obj.officeInside ? 'yes' : 'no';
      }

      return obj;
    });
  }

  const officeInsideUK = selector(state, 'offices[1].officeInside');
  const officeOutsideUk = selector(state, 'offices[0].officeOutside');
  const isVentureBacked = selector(state, 'vendors.financialData.isVentureBacked');
  const countryValue = selector(state, 'offices[0].country');

  return {
    countryValue,
    officeOutsideUk,
    officeInsideUK,
    isVentureBacked,
    initialValues: {
      offices,
      vendors: {
        ...state.user.company.vendors,
        customers: {
          types: DEFAULT_TARGET_RETAILER_LIST,
          departments: DEFAULT_DEPARTMENT_OPTIONS,
          ...state.user.company.vendors.customers,
        },
      },
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    enableReinitialize: true,
    form: 'growthStaffForm',
    validate,
  })(GrowthStaff),
);
