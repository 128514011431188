export const challengeRedirects = {
  PUBLIC_CHALLENGE_URL: '/public/challenge/:id',
  CHALLENGE_RESPONSE_FOR_RETAILER: '/retailer/rfis/:challengeId/:companySlug',
  SAVED_CHALLENGE: '/rfi/:id',
};

export const OLD_PUBLIC_CHALLENGE_URLS = [
  '/public/challenge/:id',
  '/public/rfi/:id',
];
