import challengeAPI from 'api/challenge';

const asyncValidate = async (values) => {
  const { name, companyId, id } = values;

  if (name) {
    const { data: canGenerateUniqueSlug } = await challengeAPI
      .checkIsSlugUnique({ name, companyId, excludedChallengeId: id });

    if (!canGenerateUniqueSlug) {
      // eslint-disable-next-line
      throw { name: 'This name is already used for another project. Please enter a different name' };
    }
  }

  return null;
};

export default asyncValidate;
