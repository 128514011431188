import { css } from '@emotion/react';

export const topRow = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(-1)};
    margin-bottom: ${theme.spacing(-1)};
    margin-right: ${theme.spacing(-1)};
  `
);

export const textWithOverflowContainer = (theme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  overflow: hidden;
  cursor: pointer;
`;

export const textWithOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const iconsWrap = css`
  flex-shrink: 0;
`;

export const copyIcon = css`
  flex-shrink: 0;
`;

export const icon = (theme) => css`
  color: ${theme.palette.primary.main};
`;

export const deleteIcon = (theme) => css`
  color: ${theme.palette.error.main};
`;

export const card = (theme) => (
  css`
    padding: ${theme.spacing(2)};
    border-radius: 4px;
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const row = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const splittedRow = (theme) => (
  css`
    display: grid;
    gap: ${theme.spacing(2)};
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  `
);

export const column = css`
  flex: 1;
`;

export const caption = (theme) => (
  css`
    margin-bottom: ${theme.spacing(0.5)};
    color: ${theme.primaryPalette.menuGrey};
  `
);
