/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ErrorCircle16Regular } from '@fluentui/react-icons';
import * as classes from './styles';

const EventHint = ({ className, messageAction }) => (
  <Typography variant="body2" css={classes.wrap} className={className}>
    <ErrorCircle16Regular css={classes.warningIcon} />
    <span>To {messageAction} press button above</span>
  </Typography>
);

EventHint.propTypes = {
  className: PropTypes.string,
  messageAction: PropTypes.string.isRequired,
};

EventHint.defaultProps = {
  className: null,
};

export default EventHint;
