import { css } from '@emotion/react';

export const content = css`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const tagAutocomplete = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);

export const errorContainer = css`
  margin: auto;
  text-align: center;
`;

export const errorIcon = (theme) => (
  css`
    margin-bottom: ${theme.spacing(1)};
    color: ${theme.palette.error.main};
  `
);

export const loading = css`
  margin: auto;
`;

export const suggestedQuestions = (theme) => (
  css`
    overflow-y: auto;
    border: solid 1px ${theme.primaryPalette.midLightGrey};
  `
);

export const buttons = (theme) => (
  css`
    justify-content: space-between;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  `
);

export const addRequirements = (theme) => css`
  ${theme.breakpoints.down('sm')} {
    padding-right: ${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
  }
`;

export const cancel = css`
  margin-left: 0 !important;
`;
