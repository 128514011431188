import { css } from '@emotion/react';

export const actions = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    gap: 20px 50px;
    margin-bottom: ${theme.spacing(3)};
  `
);

export const buttons = css`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const basicDetails = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);
