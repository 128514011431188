import { useState } from 'react';
import PropTypes from 'prop-types';
import GeneralModal from 'components/UI/GeneralModal';
import ShareProfileForm from 'components/VendorInfo/ShareProfileForm';
import ShareIcon from 'svg/share';
import ManageProfileButton from '../ManageProfileButton';
import ApproveCompanyTooltip from '../ApproveCompanyTooltip';

const ShareProfile = ({ companyId, disabled, className }) => {
  const [isShareModalOpen, setShareModal] = useState(false);

  const shareButton = (
    <ManageProfileButton
      className={className}
      disabled={disabled}
      icon={ShareIcon}
      onClick={() => setShareModal(true)}
      title="Share"
    />
  );

  return (
    <>
      {disabled ? <ApproveCompanyTooltip>{shareButton}</ApproveCompanyTooltip> : shareButton}
      <GeneralModal
        isOpen={isShareModalOpen}
        onClose={() => setShareModal(false)}
        title="Share profile"
      >
        <ShareProfileForm onClose={() => setShareModal(false)} companyId={companyId} />
      </GeneralModal>
    </>
  );
};

ShareProfile.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ShareProfile.defaultProps = {
  className: null,
  disabled: false,
};

export default ShareProfile;
