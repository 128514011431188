import set from 'lodash/set';
import { required } from 'lib/validation';

const validate = (values) => {
  const errors = {};

  if (values && values.awards) {
    values.awards.forEach((award, i) => {
      if (award.name && !award.year) {
        const error = required(award.year);

        set(errors, `awards[${i}].year`, error);
      }

      if (award.year && !award.name) {
        const error = required(award.name);
        set(errors, `awards[${i}].name`, error);
      }
    });
  }

  return errors;
};

export default validate;
