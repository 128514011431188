import userRoles from 'constants/userRoles';

const userRolesOptions = [
  {
    label: 'Admin',
    value: userRoles.ADMIN,
  },
  {
    label: 'Manager',
    value: userRoles.MANAGER,
  },
];

export default userRolesOptions;
