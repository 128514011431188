/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Delete16Regular } from '@fluentui/react-icons';
import { removeActiveUser } from 'store/modules/companyUsers';
import { deleteUserRole, updateUserRole, updateUserThunk } from 'store/thunk/user';
import PageBody from 'components/UI/PageBody';
import BackButton from 'components/UI/BackButton';
import BottomBar from 'components/UI/BottomBar';
import TextFieldInput from 'components/Form/TextFieldInput';
import SelectField from 'components/Form/SelectField';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import UserPhoneFields from 'components/Shared/UserPhoneFields';
import { required } from 'lib/validation';
import routes from 'constants/routes';
import userRoles from 'constants/userRoles';
import httpResponseCodes from 'constants/httpResponseCodes';
import getErrorMessage from 'utils/getErrorMessage';
import { getUserRole } from 'utils/getUserRole';
import DeleteUserModal from '../DeleteUserModal';
import SaveUserChangesModal from '../SaveUserChangesModal';
import userRolesOptions from '../constants/userRolesOptions';
import validate from '../validateEmailField';
import * as classes from './styles';

const EditUser = ({ user, roles }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const company = useSelector((state) => state.user.authUser.company);
  const dispatch = useDispatch();

  const [isSaveChangesModalOpen, setSaveChangesModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const currentUserId = useSelector((state) => state.user.authUser.id);

  const { emailDomains } = company;
  const formattedEmailDomains = emailDomains.map((domain) => `@${domain}`);

  const userUrl = generatePath(routes.EDIT_USER, { id: user.id });

  let submit = () => {};

  const getInitialValues = () => {
    const { firstName, lastName, phoneNumber } = user;
    const [username, domain] = user.email.split('@');
    const formattedDomain = `@${domain}`;
    const role = getUserRole(user);

    return {
      firstName,
      lastName,
      username,
      domain: formattedDomain,
      phoneNumber,
      role,
    };
  };

  const handleUpdateUserRole = async (roleName) => {
    if (roleName) {
      const role = roles.find(({ roleName: name }) => roleName === name);

      if (role) {
        await dispatch(updateUserRole(user.id, role));
      }
    } else {
      const hasRoleBeforeUpdate = user.invitedRoles;

      if (hasRoleBeforeUpdate) {
        await dispatch(deleteUserRole(user.id));
      }
    }
  };

  const handleRemoveUser = async () => {
    try {
      await dispatch(removeActiveUser(company.id, user));

      history.push(routes.MANAGE_USERS);
    } catch (_) {
      enqueueSnackbar('Failed to remove the user', { variant: 'error' });
    }
  };

  const handleUpdateUser = async ({
    role: roleName,
    username,
    domain,
    ...userData
  }) => {
    try {
      const { id } = user;
      const email = `${username}${domain}`;

      const body = {
        ...userData,
        email,
      };

      await dispatch(updateUserThunk(id, body));

      if (roleName) {
        await handleUpdateUserRole(roleName);
      }

      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (error) {
      const statusCode = get(error, 'response.status');

      if (statusCode === httpResponseCodes.UNPROCESSABLE_ENTITY) {
        enqueueSnackbar('Cannot use existing email', { variant: 'error' });
      } else {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  const handleCancel = ({ dirty, dirtySinceLastSubmit, submitSucceeded }) => () => {
    if ((dirty && !submitSucceeded) || dirtySinceLastSubmit) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const handleConfirmSaveChanges = ({ invalid }) => () => {
    submit();
    setSaveChangesModal(false);

    if (invalid) {
      enqueueSnackbar('Failed to save the user details', { variant: 'error' });
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <PageBody css={classes.pageBody}>
        <Breadcrumbs css={classes.breadcrumb} sx={{ mb: 1 }}>
          <Typography
            css={classes.breadcrumb}
            variant="caption1"
            component={Link}
            to={routes.MANAGE_USERS}
          >
            My company
          </Typography>
          <Typography
            css={classes.breadcrumb}
            variant="caption1"
            component={Link}
            to={userUrl}
          >
            Edit user
          </Typography>
        </Breadcrumbs>

        <Form
          validate={validate}
          initialValues={getInitialValues()}
          onSubmit={handleUpdateUser}
          render={({
            handleSubmit,
            dirty,
            dirtySinceLastSubmit,
            submitSucceeded,
            invalid,
          }) => {
            submit = handleSubmit;

            return (
              <>
                <div css={classes.head}>
                  <div css={classes.titleWrap}>
                    <BackButton
                      css={classes.backButton}
                      onClick={handleCancel({
                        dirty,
                        dirtySinceLastSubmit,
                        submitSucceeded,
                      })}
                    />
                    <Typography variant="h1">Edit user</Typography>
                  </div>

                  {currentUserId !== user.id && (
                    <Button
                      startIcon={<Delete16Regular />}
                      variant="outlined"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete user
                    </Button>
                  )}
                </div>
                <form onSubmit={handleSubmit}>
                  <div css={classes.form}>
                    <Field
                      label="First name"
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      component={TextFieldInput}
                      validate={required}
                    />
                    <Field
                      label="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      component={TextFieldInput}
                      validate={required}
                    />
                    <div css={classes.emailField}>
                      <Field
                        css={classes.usernameField}
                        label="Email"
                        type="text"
                        name="username"
                        placeholder="Email"
                        component={TextFieldInput}
                        validate={required}
                      />
                      <Field
                        css={classes.domainField}
                        name="domain"
                        fullWidth
                        component={SelectField}
                        validate={required}
                        emptyOptionLabel="Not selected"
                      >
                        {formattedEmailDomains.map((domain) => (
                          <option key={domain} value={domain}>
                            {domain}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <UserPhoneFields css={classes.phoneField} isRequired />

                    <RadioButtonGroup
                      css={classes.roleField}
                      sx={{ mb: 2 }}
                      name="role"
                      options={userRolesOptions}
                    />
                  </div>

                  <Button sx={{ mr: 3 }} css={classes.button} type="submit">Save</Button>
                  <Button
                    onClick={handleCancel({ dirty, dirtySinceLastSubmit, submitSucceeded })}
                    variant="text"
                    css={classes.button}
                  >
                    Cancel
                  </Button>

                  <SaveUserChangesModal
                    isOpen={isSaveChangesModalOpen}
                    onClose={() => setSaveChangesModal(false)}
                    onConfirm={handleConfirmSaveChanges({ invalid })}
                  />
                </form>
              </>
            );
          }}
        />
      </PageBody>

      <BottomBar
        css={classes.bottomBar}
        buttonText="Save"
        type="submit"
        onClick={(e) => submit(e)}
      />

      <DeleteUserModal
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onRemove={handleRemoveUser}
      />
    </>
  );
};

EditUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    invitedRoles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      roleName: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    phoneNumber: PropTypes.shape({
      code: PropTypes.string,
      number: PropTypes.string,
    }),
  }).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      roleName: PropTypes.oneOf(Object.values(userRoles)).isRequired,
    }).isRequired,
  ).isRequired,
};

export default EditUser;
