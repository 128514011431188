import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import eventVendorUserAPI from 'api/eventVendorUser';
import eventAPI from 'api/event';
import { authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import VendorEventBusinessPriorities from 'components/VendorEventBusinessPriorities';
import FetchContainer from 'containers/FetchContainer';
import eventHostMeetingPriorityListAPI from 'api/eventHostMeetingPriorityList';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';
import withRouteGuards from 'containers/withRouteGuards';

const VendorEventBusinessPrioritiesPage = (props) => {
  const { slug } = useParams();
  const userId = useSelector((state) => state.user.authUser.id);

  const normalizeAllAttendees = (allRetailUsers) => (
    allRetailUsers.map(({
      eventRetailInvitations,
      company,
      eventTopicPriorityLists: [list],
      ...user
    }) => {
      const [{ id }] = eventRetailInvitations;

      return {
        id,
        company,
        user,
        topics: list && list.topics ? list.topics : [],
        notes: list && list.notes ? list.notes : [],
      };
    })
  );

  const normalizeOrderedAttendees = (attendees) => (
    attendees.map(({ user, id }) => {
      const { eventTopicPriorityLists: [list], company, ...userData } = user;

      return {
        company,
        user: userData,
        id,
        topics: list && list.topics ? list.topics : [],
        notes: list && list.notes ? list.notes : [],
      };
    })
  );
  const getInitialProps = useCallback(async () => {
    const { data: event } = await eventAPI.getEventBySlug(slug);
    const userInvitation = await eventVendorUserAPI.getUserInvitation(userId, event.id);

    if (!userInvitation || !userInvitation.canSavePriorities) {
      return { event, canLoadPage: false };
    }

    const [retailUsers, hostPriorityList] = await Promise.all([
      eventAPI.getRetailAttendees(event.id),
      eventHostMeetingPriorityListAPI.getMeetingPrioritiesOfVendor(event.id, userId),
    ]);

    const normalizedAttendees = normalizeAllAttendees(retailUsers);
    const orderedAttendees = hostPriorityList && hostPriorityList.retailUsers
      ? normalizeOrderedAttendees(hostPriorityList.retailUsers)
      : [];

    return {
      event,
      attendees: normalizedAttendees,
      hostPriorityList,
      orderedAttendees,
    };
  }, [userId, slug]);

  return (
    <FetchContainer
      component={VendorEventBusinessPriorities}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.EVENT_VENDOR_BUSINESS_PRIORITIES)(VendorEventBusinessPrioritiesPage)),
);
