import { css } from '@emotion/react';

export const head = (theme) => css`
  margin-top: ${theme.spacing(5)};
  border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};

  ${theme.breakpoints.up('sm')} {
    padding: 0 ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 ${theme.spacing(3)};
  }
`;

export const content = (theme) => css`
  flex: 1;

  ${theme.breakpoints.up('sm')} {
    padding: ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(3)};
  }
`;

export const tabs = (theme) => css`
  .MuiTabs-flexContainer {
    gap: ${theme.spacing(4)};
  }
`;

export const tab = css`
  min-height: auto;
`;
