import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import UploadedVendorDocument from 'components/UI/UploadedVendorDocument';
import insertHost from 'utils/picture';
import DocumentUploadType from 'types/DocumentUpload';
import 'styles/VendorProfile.scss';
import './SavedDocumentationList.scss';

const SavedDocumentationList = ({ className, title, list }) => {
  const filteredList = list && list.filter(({ link }) => !!link);

  if (!filteredList || !filteredList.length) {
    return null;
  }

  return (
    <div className={clsx('saved-documentation-list', className)}>
      <Typography className="vendor-profile-section-title" variant="h3">
        {title}
      </Typography>
      {filteredList.map((document) => (
        <a
          key={document.link}
          className="saved-documentation-list__link"
          href={insertHost(document.link)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <UploadedVendorDocument document={document} />
        </a>
      ))}
    </div>
  );
};

SavedDocumentationList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(DocumentUploadType),
};

SavedDocumentationList.defaultProps = {
  className: null,
  list: null,
};

export default SavedDocumentationList;
