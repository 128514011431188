import isEmpty from 'lodash/isEmpty';
import companyAPI from 'api/company';
import userAPI from 'api/user';

const validate = async (values, isPreRegisteredForEvent) => {
  const errors = {};

  if (values.email) {
    const lowerCasedEmail = values.email.toLowerCase();

    const isTaken = await userAPI.IsEmailTaken(lowerCasedEmail);

    if (isTaken) {
      errors.email = 'Email is already taken';
    }
  }

  if (values.companyName && !isPreRegisteredForEvent) {
    const isTaken = await companyAPI.isCompanyNameTaken(values.companyName);

    if (isTaken) {
      errors.companyName = 'This company name is taken';
    }
  }

  return isEmpty(errors) ? null : errors;
};

export default validate;
