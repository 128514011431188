/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import base from './styles';

const PageBody = ({ className, children }) => (
  <div className={className} css={base}>{children}</div>
);

PageBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PageBody.defaultProps = {
  className: null,
};

export default PageBody;
