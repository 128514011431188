import {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import checkHasAuthToken from 'utils/checkHasAuthToken';
import useAsyncReference from 'utils/useAsyncReference';

const AUTOSAVE_INTERVAL = 30000;

let interval;

const ChallengeAutosave = ({ onSave }) => {
  const formProps = useContext(ReduxFormContext);
  const formValues = useSelector((state) => getFormValues(formProps.form)(state));

  const ref = useAsyncReference({ formProps, formValues });

  const handleSave = useCallback(() => {
    const isAuthenticated = checkHasAuthToken();

    if (!isAuthenticated) {
      clearInterval(interval);
    }

    if (ref.current.formProps.dirty && !ref.current.formProps.submitting) {
      onSave(ref.current.formValues);
    }
  }, [ref, onSave]);

  useEffect(() => {
    interval = setInterval(handleSave, AUTOSAVE_INTERVAL);

    return () => clearInterval(interval);
  }, [handleSave]);

  return null;
};

ChallengeAutosave.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default ChallengeAutosave;
