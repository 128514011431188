/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Typography } from '@mui/material';
import GeneralModal from 'components/UI/GeneralModal';
import challengeResponseAPI from 'api/challengeResponse';
import ChallengeResponseParticipants from 'components/ChallengeResponseParticipants';
import ChallengeSynopsis from 'components/Challenge/ChallengeSynopsis';
import NoteForChallengeResponder from 'components/Challenge/NoteForChallengeResponder';
import EventChallenge from 'components/RetailerChallenges/EventChallenge';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import routes from 'constants/routes';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import { EventChallengeType } from 'types/Challenge';
import DeclineChallengeForm from '../DeclineChallengeForm';
import * as classes from './styles';

const ResponseChallengeInvitation = ({ challenge, company, onInitResponseForm }) => {
  const [isOpen, setModal] = useState(false);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const handleAcceptChallenge = () => (
    challengeResponseAPI.postChallengeResponse({
      challengeId: challenge.id,
      status: challengeResponseStatus.ACCEPTED,
    })
      .then((res) => {
        const { data: { challengeResponse } } = res;

        onInitResponseForm({ challengeResponse });
      })
      .catch(() => enqueueSnackbar('Failed to accept the RFI', { variant: 'error' }))
  );

  const handleRejectChallenge = ({ rejectionReason }) => (
    challengeResponseAPI.postChallengeResponse({
      challengeId: challenge.id,
      status: challengeResponseStatus.REJECTED,
      rejectionReason,
    })
      .then(() => {
        setModal(false);
        enqueueSnackbar('Your response was recorded', { variant: 'success' });
        history.push(routes.VENDOR_CHALLENGE_DASHBOARD);
      })
      .catch(() => enqueueSnackbar('Failed to reject the RFI', { variant: 'error' }))
  );

  return (
    <>
      <div className="challenge-response-invitation">
        <Typography variant="h1" sx={{ mb: 5 }}>
          {company.name}
        </Typography>
        <Typography variant="h2" sx={{ mb: 3 }}>{challenge.name}</Typography>

        <ChallengeResponseParticipants css={classes.responseTeam} />

        <div css={classes.challengeDetails}>
          {challenge.source === DEFAULT_CHALLENGE_SOURCE
            ? <ChallengeSynopsis challenge={challenge} />
            : <EventChallenge challenge={challenge} />
          }
        </div>

        <div css={classes.actions}>
          <Button onClick={handleAcceptChallenge}>
            Accept
          </Button>
          <Button onClick={() => setModal(true)} variant="outlined">
            Decline
          </Button>
        </div>
        <NoteForChallengeResponder />
      </div>

      <GeneralModal isOpen={isOpen} onClose={() => setModal(false)} title="RFI declined">
        <DeclineChallengeForm onSubmit={handleRejectChallenge} onClose={() => setModal(false)} />
      </GeneralModal>
    </>
  );
};

ResponseChallengeInvitation.propTypes = {
  challenge: PropTypes.oneOfType([ChallengeSynopsisType, EventChallengeType]).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  onInitResponseForm: PropTypes.func.isRequired,
};

export default ResponseChallengeInvitation;
