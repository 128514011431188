import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import userAPI from 'api/user';
import roleAPI from 'api/role';
import EditUser from 'components/ManageUsers/EditUser';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import { adminRouteGuard, authorizedRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const EditUserPage = (props) => {
  const { id } = useParams();

  const getInitialProps = useCallback(async () => {
    const [user, { data: roles }] = await Promise.all([
      userAPI.getUserWithRole(id),
      roleAPI.find(),
    ]);

    return { user, roles };
  }, [id]);

  return <FetchContainer getInitialProps={getInitialProps} component={EditUser} {...props} />;
};

export default withRouteGuards([authorizedRouteGuard, adminRouteGuard])(
  withPageTitle(pageTitles.EDIT_USER)(EditUserPage),
);
