import companyStatus from 'constants/companyStatus';

const companyTypeOptions = [
  {
    label: 'Vendor',
    value: companyStatus.VENDOR,
  },
  {
    label: 'Retailer or brand',
    value: companyStatus.RETAILER,
  },
];

export default companyTypeOptions;
