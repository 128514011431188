/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import * as classes from './styles';

const SCROLL_THRESHOLD_PX = 100;

const PageSubheader = ({ className, renderChildren }) => {
  const isSticky = useScrollTrigger({ disableHysteresis: true, threshold: SCROLL_THRESHOLD_PX });

  return (
    <div className={className} css={[classes.outerContainer, isSticky && classes.sticky]}>
      <div css={classes.innerContainer}>{renderChildren({ isSticky })}</div>
    </div>
  );
};

PageSubheader.propTypes = {
  className: PropTypes.string,
  renderChildren: PropTypes.func.isRequired,
};

PageSubheader.defaultProps = {
  className: null,
};

export default PageSubheader;
