/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import EndChallengeParticipation from '../EndChallengeParticipation';
import footerStyles from './styles';

const ChallengeResponseFormFooter = ({ challengeId }) => (
  <div css={footerStyles}>
    <Button type="submit">Send response</Button>
    <EndChallengeParticipation challengeId={challengeId} />
  </div>
);

ChallengeResponseFormFooter.propTypes = {
  challengeId: PropTypes.string.isRequired,
};

export default ChallengeResponseFormFooter;
