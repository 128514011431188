/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import challengeShortlistedCompanyAPI from 'api/challengeShortlistedCompany';
import routes from 'constants/routes';
import registrationQueryParams from 'constants/registrationQueryParams';
import sectionStyles from './styles';

const SharedChallenge = ({ challenge, company: challengeOwnerCompany, user: challengeOwner }) => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const isAuthenticated = useSelector((state) => !!state.user.authUser.id);
  const userCompany = useSelector((state) => state.user.company);

  const handleAddVendorToChallenge = async () => {
    try {
      await challengeShortlistedCompanyAPI.create({
        challengeId: challenge.id,
        companyId: userCompany.id,
        isSharedChallenge: true,
      });

      const challengeResponseFormUrl = generatePath(
        routes.CHALLENGE_RESPONSE_FORM,
        { challengeId: challenge.id },
      );

      history.push(challengeResponseFormUrl);
    } catch (_) {
      enqueueSnackbar('Failed to include a user to RFI shortlist', { variant: 'error' });
    }
  };

  const registrationUrl = {
    pathname: routes.REGISTER,
    search: new URLSearchParams({
      [registrationQueryParams.SHARED_CHALLENGE_ID]: challenge.id,
    }).toString(),
  };

  return (
    <div>
      <Typography variant="h1" sx={{ mb: 5 }}>{challenge.name}</Typography>
      <div css={sectionStyles}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" component="h4">
          The RFI
        </Typography>
        <Typography variant="body2">{challenge.challenge_description}</Typography>
      </div>
      <div css={sectionStyles}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" component="h4">
          About the RFI owner
        </Typography>
        <Typography variant="body2">{challengeOwner.position || 'A user'} from {challengeOwnerCompany.name}</Typography>
      </div>
      {isAuthenticated
        ? <Button onClick={handleAddVendorToChallenge}>Participate</Button>
        : <Button to={registrationUrl} component={Link}>Participate</Button>}
    </div>
  );
};

SharedChallenge.propTypes = {
  challenge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    challenge_description: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    position: PropTypes.string,
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default SharedChallenge;
