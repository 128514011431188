import { css } from '@emotion/react';

export const wrap = (theme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(4)};
`;

export const warningIcon = css`
  color: #36b5dd;
`;
