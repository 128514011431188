/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import debounce from 'lodash/debounce';
import CompanyPropertyAPI from 'api/companyProperty';
import reasonChange from 'constants/reasonChangeAutocomplete';
import keyCodes from 'constants/keyCodes';
import * as classes from './styles';

const DEBOUNCE_TIMEOUT = 500;
const MAX_INPUT_LENGTH = 2;

const filter = createFilterOptions({
  trim: true,
  stringify: ({ name }) => name,
});

const CompanyPropertyAutocomplete = ({
  className,
  properties: addedProperties,
  onAdd,
  onRemove,
  apiClient,
  label,
  error,
  name,
  getOptionLabel,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const formatValue = (value) => value.toLowerCase().trim();

  const checkIsChosen = () => {
    const formattedInputValue = formatValue(inputValue);

    return addedProperties.some((property) => (
      formattedInputValue === formatValue(getOptionLabel(property))
    ));
  };

  const filterOptions = (suggestedProperties, params) => {
    const filtered = filter(suggestedProperties, params);
    const formattedInputValue = formatValue(inputValue);

    const isChosen = checkIsChosen();

    if (!formattedInputValue || isChosen) {
      return [];
    }

    if (!suggestions.length) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const onInputChange = (_, newInputValue) => setInputValue(newInputValue);

  const handleCreateAndAddProperty = () => {
    const isChosen = checkIsChosen();

    if (!isChosen) {
      apiClient.create({ name: inputValue })
        .then(({ data }) => onAdd(data))
        .catch(() => enqueueSnackbar('Failed to add an option', { variant: 'error' }));
    }
  };

  const handleSelectOption = (newValue) => {
    const option = newValue[newValue.length - 1];

    if (option.id) {
      onAdd(option);
    } else {
      // Handle click on `Add ${name}` option
      handleCreateAndAddProperty();
    }
  };

  const handleChange = (_, newValue, reason) => {
    switch (reason) {
      case reasonChange.CREATE_OPTION:
        handleCreateAndAddProperty();
        break;

      case reasonChange.SELECT_OPTION:
        handleSelectOption(newValue);
        break;

      case reasonChange.REMOVE_OPTION:
        onRemove(newValue);
        break;

      default:
        break;
    }
  };

  const handleKeydown = async (e) => {
    try {
      if (e.keyCode === keyCodes.COMMA) {
        e.preventDefault();

        const isChosen = checkIsChosen();
        const formattedInputValue = formatValue(inputValue);

        if (!isChosen && formattedInputValue && formattedInputValue !== ',') {
          const property = await apiClient.getPropertyByName(formattedInputValue);

          if (property) {
            onAdd(property);
          } else {
            const { data } = await apiClient.create({ name: inputValue });
            onAdd(data);
          }
        } else {
          enqueueSnackbar('Already added', { variant: 'warning' });
        }

        setTimeout(() => setInputValue(''));
      }
    } catch (_) {
      enqueueSnackbar('Failed to add a new item', { variant: 'error' });
    }
  };

  const handleSearch = useMemo(() => debounce((query, isActive) => {
    const formattedQuery = formatValue(query);

    if (!query) {
      setSuggestions([]);
    } else {
      apiClient.getSuggestions(formattedQuery)
        .then((results) => {
          if (isActive) {
            setSuggestions(results);
          }
        })
        .catch(() => enqueueSnackbar('Failed to search', { variant: 'error' }));
    }
  }, DEBOUNCE_TIMEOUT), [enqueueSnackbar, apiClient]);

  useEffect(() => {
    let active = true;

    handleSearch(inputValue, active);

    return () => {
      active = false;
    };
  }, [inputValue, handleSearch]);

  const renderSuggestion = (props, option) => {
    const matches = match(option.name, inputValue);
    const parts = parse(option.name, matches);

    return (
      <li {...props}>
        <Typography variant="body1">
          {parts.map((part, index) => (
            <span key={index} css={part.highlight && classes.highlightStyle}>
              {part.text}
            </span>
          ))}
        </Typography>
      </li>
    );
  };

  const renderSelectedProperties = (selectedOptions, getPropertyProps) => (
    selectedOptions.map((option, index) => (
      <Chip
        key={option.id}
        color="primary"
        variant="outlined"
        label={getOptionLabel(option)}
        {...getPropertyProps({ index })}
      />
    ))
  );

  const renderInput = ({ inputProps, ...params }) => (
    <FormLabel css={classes.wrapper}>
      {label}
      <TextField
        {...params}
        inputProps={{ ...inputProps, onKeyDown: handleKeydown }}
        fullWidth
        name={name}
        css={classes.input}
        color="primary"
        error={!!error}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormLabel>
  );

  return (
    <Autocomplete
      className={className}
      inputValue={inputValue}
      id="company-properties-autocomplete"
      multiple
      options={suggestions}
      autoComplete
      forcePopupIcon={false}
      open={inputValue.length > MAX_INPUT_LENGTH}
      clearOnBlur={false}
      clearIcon={null}
      isOptionEqualToValue={(option, value) => (
        !option.inputValue
        && formatValue(option.name) === formatValue(getOptionLabel(value))
      )}
      getOptionLabel={(option) => (option.inputValue ? option.inputValue : option.name)}
      includeInputInList
      filterSelectedOptions
      freeSolo={false}
      noOptionsText={<Typography variant="body1">No options</Typography>}
      autoSelect
      value={addedProperties}
      onChange={handleChange}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderTags={renderSelectedProperties}
      renderOption={renderSuggestion}
    />
  );
};

CompanyPropertyAutocomplete.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  properties: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  apiClient: PropTypes.instanceOf(CompanyPropertyAPI).isRequired,
};

CompanyPropertyAutocomplete.defaultProps = {
  className: null,
  error: null,
  properties: [],
};

export default CompanyPropertyAutocomplete;
