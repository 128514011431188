import { isPast } from 'date-fns';
import challengeStatus from 'constants/challengeStatus';

export const isEditable = (challenge) => (
  challenge.status === challengeStatus.DRAFT
  || challenge.status === challengeStatus.DRAFT_REVISION
  || challenge.status === challengeStatus.APPROVED_REVISION
);

export const isExpired = (challenge) => (
  challenge.response_deadline && isPast(new Date(challenge.response_deadline))
);

export const isAcceptingResponses = (challenge) => (
  challenge.status === challengeStatus.SUBMITTED
  || challenge.status === challengeStatus.SUBMITTED_REVISION
  || challenge.status === challengeStatus.REQUESTED_REVISION
  || challenge.status === challengeStatus.APPROVED_REVISION
);

export const isRevisionRequestAllowed = (challenge) => (
  challenge.status === challengeStatus.SUBMITTED
  || challenge.status === challengeStatus.SUBMITTED_REVISION
);

export const isClosed = (challenge) => (
  challenge.status === challengeStatus.COMPLETED || challenge.status === challengeStatus.CANCELLED
);
