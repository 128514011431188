import PropTypes from 'prop-types';

const BoxIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 19.3143C1.5 20.1361 2.17031 20.8 3 20.8H21C21.8297 20.8 22.5 20.1361 22.5 19.3143V5.94286H1.5V19.3143ZM9 9.47143C9 9.165 9.25313 8.91429 9.5625 8.91429H14.4375C14.7469 8.91429 15 9.165 15 9.47143V9.84286C15 10.1493 14.7469 10.4 14.4375 10.4H9.5625C9.25313 10.4 9 10.1493 9 9.84286V9.47143ZM22.5 0H1.5C0.670312 0 0 0.663929 0 1.48571V3.71429C0 4.12286 0.3375 4.45714 0.75 4.45714H23.25C23.6625 4.45714 24 4.12286 24 3.71429V1.48571C24 0.663929 23.3297 0 22.5 0Z"
      fill="#656565"
    />
  </svg>
);

BoxIcon.propTypes = {
  className: PropTypes.string,
};

BoxIcon.defaultProps = {
  className: null,
};

export default BoxIcon;
