/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { ChevronDown20Filled, Delete20Regular, Edit20Regular } from '@fluentui/react-icons';
import priorityTypes from 'constants/priorityTypes';
import PriorityCardMobileMenu from './PriorityCardMobileMenu';
import * as classes from './styles';

const PriorityCardMenu = ({
  isSlotSelected,
  isDisabled,
  isExpanded,
  onToggleExpandCard,
  priorityItemName,
  onEdit,
  onRemove,
  isMenuOpen,
  setMenuOpen,
}) => (
  <>
    {!isSlotSelected && (
      <IconButton
        css={classes.iconButton}
        disabled={isDisabled}
        color="primary"
        onClick={onToggleExpandCard}
      >
        <ChevronDown20Filled
          css={[
            classes.collapseChevron,
            isExpanded && classes.rotatedChevron,
            isDisabled && classes.disabled,
          ]}
        />
      </IconButton>
    )}

    <div css={classes.desktopMenu}>
      <IconButton color="primary" onClick={onEdit}>
        <Edit20Regular />
      </IconButton>

      <IconButton color="primary" onClick={onRemove}>
        <Delete20Regular />
      </IconButton>
    </div>

    <div css={classes.mobileMenu}>
      <PriorityCardMobileMenu
        priorityItemName={priorityItemName}
        isOpen={isMenuOpen}
        setMenu={setMenuOpen}
        onEdit={onEdit}
        onRemove={onRemove}
      />
    </div>
  </>
);

PriorityCardMenu.propTypes = {
  isSlotSelected: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpandCard: PropTypes.func.isRequired,
  priorityItemName: PropTypes.oneOf(Object.values(priorityTypes)).isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

PriorityCardMenu.defaultProps = {
  isSlotSelected: false,
};

export default PriorityCardMenu;
