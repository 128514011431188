/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GeneralModal from 'components/UI/GeneralModal';
import modal from './styles';

const RequestMessageModal = ({
  isOpen,
  onClose,
  message,
  companyName,
}) => (
  <GeneralModal
    css={modal}
    maxWidth='xs'
    isOpen={isOpen}
    onClose={onClose}
    title={`Message from ${companyName}`}
  >
    <Typography sx={{ mb: 3 }} variant="body2">
      {message}
    </Typography>
    <Button variant="text" onClick={onClose}>Close</Button>
  </GeneralModal>
);

RequestMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default RequestMessageModal;
