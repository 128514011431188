import PropTypes from 'prop-types';

const ProfileCard = ({ className }) => (
  <svg
    className={className}
    width="54"
    height="42"
    viewBox="0 0 54 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.5 0H4.5C2.01562 0 0 2.01562 0 4.5V6H54V4.5C54 2.01562 51.9844 0 49.5 0ZM0 37.5C0 39.9844 2.01562 42 4.5 42H49.5C51.9844 42 54 39.9844 54 37.5V9H0V37.5ZM33 15.75C33 15.3375 33.3375 15 33.75 15H47.25C47.6625 15 48 15.3375 48 15.75V17.25C48 17.6625 47.6625 18 47.25 18H33.75C33.3375 18 33 17.6625 33 17.25V15.75ZM33 21.75C33 21.3375 33.3375 21 33.75 21H47.25C47.6625 21 48 21.3375 48 21.75V23.25C48 23.6625 47.6625 24 47.25 24H33.75C33.3375 24 33 23.6625 33 23.25V21.75ZM33 27.75C33 27.3375 33.3375 27 33.75 27H47.25C47.6625 27 48 27.3375 48 27.75V29.25C48 29.6625 47.6625 30 47.25 30H33.75C33.3375 30 33 29.6625 33 29.25V27.75ZM16.5 15C19.8094 15 22.5 17.6906 22.5 21C22.5 24.3094 19.8094 27 16.5 27C13.1906 27 10.5 24.3094 10.5 21C10.5 17.6906 13.1906 15 16.5 15ZM6.29062 34.1438C7.07812 31.7344 9.3375 30 12 30H12.7687C13.9219 30.4781 15.1781 30.75 16.5 30.75C17.8219 30.75 19.0875 30.4781 20.2313 30H21C23.6625 30 25.9219 31.7344 26.7094 34.1438C27.0094 35.0719 26.2219 36 25.2469 36H7.75312C6.77812 36 5.99062 35.0625 6.29062 34.1438Z"
      fill="#D1D700"
    />
  </svg>
);

ProfileCard.propTypes = {
  className: PropTypes.string,
};

ProfileCard.defaultProps = {
  className: null,
};

export default ProfileCard;
