import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, generatePath, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PageBody from 'components/UI/PageBody';
import { updateCompanyProfile } from 'store/thunk/company';
import getMatchType from 'types/Match';
import LocationType from 'types/Location';
import routes from 'constants/routes';
import retailerDataCaptureFormTabs from 'constants/retailerDataCaptureFormTabs';
import getErrorMessage from 'utils/getErrorMessage';
import { RETAILER_REGISTRATION_STEPS_DATA, RETAILER_REGISTRATION_STEPS as steps } from './constants';
import BasicDetails from './BasicDetails';
import AccountPage from './AccountPage';
import SalesStores from './SalesStores';

const profileMenuList = Object.values(RETAILER_REGISTRATION_STEPS_DATA);

const RetailerDataCaptureForm = ({ location, history, match }) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { activeTab } = match.params;
  const { pathname } = location;

  const lastCompletedStep = useSelector((state) => state.user.company.companyRegistrationStatus);
  const currentStep = profileMenuList.find(({ path }) => path === activeTab);

  const handleUpdateProfile = useCallback(
    (nextStep) => async (values, isDraft) => {
      try {
        const companyRegistrationStatus = nextStep > lastCompletedStep
          ? nextStep
          : lastCompletedStep;

        await dispatch(updateCompanyProfile({ companyRegistrationStatus, ...values }));

        if (!nextStep && !isDraft) {
          enqueueSnackbar('Successfully saved', { variant: 'success' });

          return;
        }

        if (!isDraft) {
          const { path } = profileMenuList.find(({ stepNumber }) => stepNumber === nextStep);
          const nextStepUrl = generatePath(routes.EDIT_RETAILER_DETAILS, { activeTab: path });

          history.push(nextStepUrl);
        }
      } catch (error) {
        const message = getErrorMessage(error, 'Failed to update company profile');
        enqueueSnackbar(message, { variant: 'error' });
      }
    },
    [lastCompletedStep, dispatch, enqueueSnackbar, history],
  );

  const renderContent = () => {
    switch (activeTab) {
      case retailerDataCaptureFormTabs.BASIC_DETAILS:
        return <BasicDetails onUpdateProfile={handleUpdateProfile(steps.SALES_STORES)} />;

      case retailerDataCaptureFormTabs.SALES_STORES:
        return <SalesStores onUpdateProfile={handleUpdateProfile(steps.ACCOUNT)} />;

      case retailerDataCaptureFormTabs.ACCOUNT:
        return <AccountPage onUpdateProfile={handleUpdateProfile(null)} />;

      default:
        return <Redirect to={RETAILER_REGISTRATION_STEPS_DATA.BASIC_DETAILS.path} />;
    }
  };

  return (
    <PageBody>
      <Breadcrumbs sx={{ mb: 5 }}>
        <Link to={routes.MAIN}>Home</Link>
        <Link to={pathname}>{currentStep ? currentStep.title : 'Unknown'}</Link>
      </Breadcrumbs>
      {renderContent()}
    </PageBody>
  );
};

RetailerDataCaptureForm.propTypes = {
  location: LocationType.isRequired,
  history: PropTypes.object.isRequired,
  match: getMatchType({ activeTab: PropTypes.string.isRequired }),
};

export default RetailerDataCaptureForm;
