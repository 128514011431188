import { css } from '@emotion/react';

export const sidebar = (theme) => (
  css`
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1200;
    overflow-x: hidden;
    color: ${theme.primaryPalette.grey};
    background-color: ${theme.primaryPalette.white};
    box-shadow: ${theme.boxShadows.large};
    transition: width 0.2s ease-in, left 0.2s ease-in;

    ${theme.breakpoints.down('md')} {
      left: -260px;
    }
  `
);

export const expanded = (theme) => (
  css`
    width: ${theme.layout.sidebar};
    overflow-y: auto;

    ${theme.breakpoints.down('md')} {
      left: 0;
    }
  `
);

export const hidden = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      width: 0;
      overflow-y: hidden;
      border: none;
    }
  `
);

export const content = (theme) => (
  css`
    width: ${theme.layout.sidebar};
    height: 100%;
    transition: width 0.2s ease-in;
  `
);

export const contentHidden = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      width: 0;
    }
  `
);
