/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import recommendSolutionOptions from 'constants/review';
import FieldInputType from 'types/FieldInput';
import * as classes from './styles';

const RecommendSolutionField = ({ className, input: { value, onChange } }) => (
  // TODO: use aria-label and aria-labelledby
  <div className={className} css={classes.field}>
    <div css={classes.icons}>
      <ThumbUpIcon
        onClick={() => onChange(recommendSolutionOptions.RECOMMEND)}
        css={[classes.icon, value === recommendSolutionOptions.RECOMMEND && classes.activeIcon]}
      />
      <ThumbDownIcon
        onClick={() => onChange(recommendSolutionOptions.NOT_RECOMMEND)}
        css={[classes.icon, value === recommendSolutionOptions.NOT_RECOMMEND && classes.activeIcon]}
      />
    </div>
    <Typography variant="subtitle2" component="label">
      Would you recommend this solution?
    </Typography>
  </div>
);

RecommendSolutionField.propTypes = {
  className: PropTypes.string,
  input: FieldInputType.isRequired,
};

RecommendSolutionField.defaultProps = {
  className: null,
};

export default RecommendSolutionField;
