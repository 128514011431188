import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Typography } from '@mui/material';
import VideoPlayer from 'components/UI/VideoPlayer';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import DocumentUploadType from 'types/DocumentUpload';
import VideoPlaceholder from 'static/images/videoPlaceholder.png';
import 'styles/VendorProfile.scss';
import List from '../List';
import SavedDocumentationList from '../SavedDocumentationList';
import insertHost from '../../../utils/picture';
import './CompanyOverview.scss';

const CompanyOverview = ({
  isFeatureDemoShown,
  videoLink,
  productDemoVideo,
  websiteScreenshot,
  features,
  pricing,
  roadmap,
  productDocumentation,
  showcaseProduct,
  onOpenInviteNewUserModal,
}) => (
  <>
    <Typography className="vendor-profile-title" variant="h1">
      Overview
    </Typography>

    {(showcaseProduct && (websiteScreenshot || videoLink)) && (
      <div className="vendor-profile-section">
        {showcaseProduct === yesNoOptions.YES && videoLink && (
          <VideoPlayer url={videoLink} className="company-overview__company-video" />
        )}
        {showcaseProduct === yesNoOptions.NO && websiteScreenshot && (
          <img
            className="company-overview__showcase-image"
            src={insertHost(websiteScreenshot)}
            alt=""
          />
        )}
      </div>
    )}

    <SavedDocumentationList
      className="vendor-profile-section"
      title="Product documentation"
      list={productDocumentation}
    />

    <List className="vendor-profile-section" title="Pricing" list={pricing} />
    <List className="vendor-profile-section" title="Product features" list={features} />
    {!!productDemoVideo && (
      <div className="vendor-profile-section">
        {isFeatureDemoShown ? (
          <VideoPlayer
          className="company-overview__company-video"
          url={productDemoVideo}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            className="company-overview__product-picture"
            src={VideoPlaceholder}
            alt=""
            onClick={() => typeof onOpenInviteNewUserModal === 'function' && onOpenInviteNewUserModal()}
          />
        )}
      </div>
    )}
    <List className="vendor-profile-section" title="Future features" list={roadmap} />
  </>
);

CompanyOverview.propTypes = {
  isFeatureDemoShown: PropTypes.bool.isRequired,
  videoLink: PropTypes.string,
  productDemoVideo: PropTypes.string,
  websiteScreenshot: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string.isRequired),
  pricing: PropTypes.arrayOf(PropTypes.string.isRequired),
  roadmap: PropTypes.arrayOf(PropTypes.string.isRequired),
  productDocumentation: PropTypes.arrayOf(DocumentUploadType.isRequired).isRequired,
  showcaseProduct: PropTypes.oneOf(Object.values(yesNoOptions)),
  onOpenInviteNewUserModal: PropTypes.func,
};

CompanyOverview.defaultProps = {
  showcaseProduct: null,
  description: '',
  videoLink: '',
  productDemoVideo: '',
  websiteScreenshot: '',
  features: [],
  pricing: [],
  roadmap: [],
  onOpenInviteNewUserModal: null,
};

const mapStateToProps = (state) => {
  const vendorProductService = get(state, 'vendorInfo.company.vendors.productService', {});
  const productDocumentation = get(state, 'vendorInfo.company.vendors.productDocumentation', []);

  return {
    ...pick(vendorProductService, [
      'features',
      'websiteScreenshot',
      'videoLink',
      'productDemoVideo',
      'showcaseProduct',
      'pricing',
      'roadmap',
    ]),
    productDocumentation,
  };
};

export default connect(mapStateToProps)(CompanyOverview);
