import { css } from '@emotion/react';

const filters = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      margin-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('lg')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export default filters;
