/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import PageBody from 'components/UI/PageBody';
import SocialMediaFormSection from 'components/Form/SocialMediaFormSection';
import AvatarField from 'components/Shared/AvatarField';
import TextFieldInput from 'components/Form/TextFieldInput';
import UserPhoneFields from 'components/Shared/UserPhoneFields';
import Modal from 'components/UI/Modal';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import { updateUserThunk } from 'store/thunk';
import routes from 'constants/routes';
import composeValidators from 'utils/composeValidators';
import ChangePasswordForm from '../ChangePasswordForm';
import * as classes from './styles';

class EditUserProfileForm extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    saveUserProfile: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.shape({
        code: PropTypes.string,
        number: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    initialValues: null,
  };

  state = {
    showChangePasswordModal: false,
  };

  handleChangeModal = (isModalOpen) => {
    this.setState({ showChangePasswordModal: isModalOpen });
  };

  handleSaveProfile = async (values) => {
    const {
      history,
      saveUserProfile,
      userId,
      enqueueSnackbar,
    } = this.props;

    try {
      await saveUserProfile(userId, values);
      history.push(routes.USER_PROFILE);
    } catch (error) {
      enqueueSnackbar('Failed to update profile', { variant: 'error' });
    }
  };

  render() {
    const { showChangePasswordModal } = this.state;
    const { userId, initialValues } = this.props;

    return (
      <PageBody>
        <div>
          <Breadcrumbs sx={{ mb: 5 }}>
            <Link to={routes.MAIN}>Home</Link>
            <Link to={routes.USER_PROFILE}>User profile</Link>
            <Link to={routes.EDIT_USER_PROFILE}>Edit profile</Link>
          </Breadcrumbs>

          <Typography sx={{ mb: 5 }} variant="h1">
            Edit your profile
          </Typography>

          <Form
            onSubmit={this.handleSaveProfile}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div css={classes.fields}>
                  <Field
                    css={classes.field}
                    userId={userId}
                    name="icon"
                    model="company"
                    fileNamePrefix="logo"
                    component={AvatarField}
                  />

                  <Field
                    css={classes.field}
                    label="First name"
                    name="firstName"
                    component={TextFieldInput}
                    validate={required}
                  />
                  <Field
                    css={classes.field}
                    label="Last name"
                    name="lastName"
                    component={TextFieldInput}
                    validate={required}
                  />
                  <Field
                    css={classes.field}
                    label="Job title"
                    name="position"
                    component={TextFieldInput}
                    validate={required}
                  />
                  <Field
                    css={classes.field}
                    label="Email"
                    name="email"
                    component={TextFieldInput}
                    validate={composeValidators(
                      required, validateEmail, validateIsCorporateEmailDomain,
                    )}
                  />

                  <UserPhoneFields css={classes.field} />

                  <fieldset css={classes.field}>
                    <FormLabel css={classes.label} component="legend">
                      Your social media links
                    </FormLabel>
                    <SocialMediaFormSection />
                  </fieldset>
                  <Field label="About" name="description" component={TextFieldInput} multiline rows={4} />
                </div>

                <div css={classes.buttons}>
                  <Button onClick={() => this.handleChangeModal(true)}>Change Password</Button>
                  <Button type="submit">Save</Button>
                </div>
              </form>
            )}
          />
        </div>

        <Modal show={showChangePasswordModal} modalClosed={() => this.handleChangeModal(false)}>
          <ChangePasswordForm onCloseModal={() => this.handleChangeModal(false)} />
        </Modal>
      </PageBody>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.authUser.id,
  initialValues: state.user.authUser,
  enqueueSnackbar: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { saveUserProfile: updateUserThunk })(
  (withSnackbar(EditUserProfileForm)),
);
