import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import companyStatus from 'constants/companyStatus';
import userRoles from 'constants/userRoles';
import { getUserRoleFromStore } from 'utils/getUserRole';
import CompanyType from 'types/Company';
import {
  getCompanyProfileLink,
  getEventsMenuItem,
  managePersonalAccountMenuItem,
  vendorChallengeDashboardMenuItem,
  manageUsersMenuItem,
  retailerChallengesMenuItem,
} from '../menuItems';
import HomeMenuItem from '../HomeMenuItem';

const HomeMenu = ({
  role,
  company,
  history,
  eventMenu,
}) => {
  const getRegistrationNotificationCount = () => {
    if (company.adminApprove || company.type === companyStatus.RETAILER) {
      return 0;
    }

    if (!(company.vendors && company.vendors.areBasicDetailsSaved)) {
      return 2;
    }

    return 1;
  };

  const getRegistrationMenuItem = () => {
    const notificationCount = getRegistrationNotificationCount();
    const menuItem = getCompanyProfileLink(company.type);

    return { ...menuItem, notificationCount };
  };

  const getUserMenu = () => {
    let menu = [];

    const eventsMenuItem = getEventsMenuItem(eventMenu, company.type);

    if (company.type === companyStatus.VENDOR && company.adminApprove) {
      menu = [...menu, vendorChallengeDashboardMenuItem, eventsMenuItem];
    }

    if (company.type === companyStatus.RETAILER) {
      menu = [...menu, retailerChallengesMenuItem, eventsMenuItem];
    }

    if (role === userRoles.ADMIN) {
      menu = [...menu, getRegistrationMenuItem(), manageUsersMenuItem];
    }

    menu = [...menu, managePersonalAccountMenuItem];

    return menu;
  };

  const menu = getUserMenu();

  return (
    <div>
      {menu.map(({ pathname, ...rest }) => (
        <HomeMenuItem key={pathname} onClick={() => history.push(pathname)} {...rest} />
      ))}
    </div>
  );
};

HomeMenu.propTypes = {
  role: PropTypes.oneOf(Object.values(userRoles)),
  company: PropTypes.shape(CompanyType).isRequired,
  history: PropTypes.object.isRequired,
  eventMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ),
};

HomeMenu.defaultProps = {
  role: null,
  eventMenu: null,
};

export default connect((state) => ({
  role: getUserRoleFromStore(state),
  company: state.user.company,
  eventMenu: state.user.eventMenu,
}))(withRouter(HomeMenu));
