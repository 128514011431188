import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FetchContainer from 'containers/FetchContainer';
import SolutionsSearchForRetailer from 'components/SolutionsSearchPages/SolutionsSearchForRetailer';
import { getSavedVendorList } from 'store/modules/savedVendors';
import withRouteGuards from 'containers/withRouteGuards';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const SolutionsSearchForRetailerPage = () => {
  const userId = useSelector((state) => state.user.authUser.id);
  const dispatch = useDispatch();

  const getInitialProps = useCallback(() => (
    dispatch(getSavedVendorList(userId))
  ), [dispatch, userId]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={SolutionsSearchForRetailer}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.SEARCH_SOLUTION_PAGE)(SolutionsSearchForRetailerPage)),
);
