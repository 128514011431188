/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BookmarkSearchRegular } from '@fluentui/react-icons';
import routes from 'constants/routes';
import * as classes from './styles';

const EmptySearchResults = ({ className }) => (
  <div className={className}>
    <Typography variant="subtitle2" css={[classes.noResults, classes.text]}>
      No results found
    </Typography>
    <div css={classes.content}>
      <div css={classes.iconContainer}>
        <BookmarkSearchRegular css={classes.icon} />
      </div>
      <Typography variant="subtitle2" css={classes.text} sx={{ mb: 4 }}>
        If there is something specific you are looking for, you can set an RFI and we will do the
        rest.
      </Typography>
      <Button variant="outlined" component={Link} to={routes.CREATE_NEW_CHALLENGE}>
        Set RFI
      </Button>
    </div>
  </div>
);

EmptySearchResults.propTypes = {
  className: PropTypes.string,
};

EmptySearchResults.defaultProps = {
  className: null,
};

export default EmptySearchResults;
