import { useContext } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect, Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ChallengeForm from 'components/ChallengeForm';
import PageBody from 'components/UI/PageBody';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import routes from 'constants/routes';
import getMatchType from 'types/Match';
import LocationType from 'types/Location';
import EventType from 'types/Event';
import { isEditable } from 'utils/challengeCheckers';
import getInitialFormValues from './getInitialFormValues';

const EditSavedChallengeForm = ({ event, location, match: { params: { id } } }) => {
  const { state: { challenge, milestones } } = useContext(ChallengeContext);

  if (!(isEditable(challenge) || challenge.source !== DEFAULT_CHALLENGE_SOURCE)) {
    return <Redirect to={generatePath(routes.SAVED_CHALLENGE, { id })} />;
  }

  const breadcrumbs = [
    { title: 'Home', to: routes.MAIN },
    { title: 'RFIs', to: routes.RETAILER_CHALLENGE_DASHBOARD },
    { title: challenge.name, to: location },
  ];

  return (
    <PageBody>
      <Breadcrumbs sx={{ mb: 5 }}>
        {breadcrumbs.map(({ title, to }) => <Link key={to} to={to}>{title}</Link>)}
      </Breadcrumbs>
      <ChallengeForm event={event} initialValues={getInitialFormValues(challenge, milestones)} />
    </PageBody>
  );
};

EditSavedChallengeForm.propTypes = {
  event: EventType,
  location: LocationType.isRequired,
  match: getMatchType({ id: PropTypes.string.isRequired }),
};

EditSavedChallengeForm.defaultProps = {
  event: null,
};

export default EditSavedChallengeForm;
