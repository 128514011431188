import { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import checkHasAuthToken from 'utils/checkHasAuthToken';
import { AuthContext } from 'containers/AuthProvider';

class DataCaptureFormContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isDirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static contextType = AuthContext;

  componentWillUnmount() {
    const {
      isDirty,
      isValid,
      onSubmit,
      submitSucceeded,
    } = this.props;
    const { isSessionExpired } = this.context;
    // TODO: move cookie management to context and get rid of it
    const hasAuthorization = checkHasAuthToken();

    if (isValid && isDirty && !submitSucceeded && hasAuthorization && !isSessionExpired) {
      onSubmit();
    }
  }

  render() {
    const { children, isValid, isDirty } = this.props;
    const { isSessionExpired } = this.context;
    const hasAuthorization = checkHasAuthToken();

    return (
      <>
        <Prompt
          message="You have unsaved changes. Are you sure you want to leave?"
          when={!isValid && isDirty && hasAuthorization && !isSessionExpired}
        />
        {children}
      </>
    );
  }
}

export default DataCaptureFormContainer;
