/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { required, maxLength40 } from 'lib/validation';
import TextFieldInput from 'components/UI/TextFieldInput';
import { imageUploadConfig } from 'constants/fileUploadConfigs';
import FileUpload from 'components/UI/FileUpload';
import insertHost from 'utils/picture';
import * as classes from './styles';

class AddCaseStudyFormSection extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    children: PropTypes.node,
    titleFieldPlaceholder: PropTypes.string,
    descriptionFieldPlaceholder: PropTypes.string,
    description: PropTypes.string.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    formTitle: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: null,
    titleFieldPlaceholder: null,
    descriptionFieldPlaceholder: null,
  };

  renderUploadField = ({ input: { onChange, value }, meta: { submitFailed, error } }) => {
    const { onUploadFile, onDeleteFile } = this.props;

    return (
      <div css={classes.field}>
        {value ? (
          <>
            <img css={classes.image} src={insertHost(value)} alt="" />
            <button
              css={classes.button}
              onClick={() => {
                onDeleteFile(value);
                onChange('');
              }}
              type="button"
            >
              Remove
            </button>
          </>
        ) : (
          <FileUpload {...imageUploadConfig} onUpload={onUploadFile(onChange, value, 'picture')} />
        )}
        {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
      </div>
    );
  };

  render() {
    const {
      buttonText,
      children,
      description,
      descriptionFieldPlaceholder,
      formTitle,
      onSubmit,
      titleFieldPlaceholder,
    } = this.props;

    return (
      <div>
        <div css={classes.group}>
          <FormLabel sx={{ mb: 1 }} component="h3">{formTitle}</FormLabel>
          <Typography variant="body2">{description}</Typography>
        </div>

        <FormLabel css={classes.label}>Client logo</FormLabel>
        <Field component={this.renderUploadField} name="newEndorsement.picture" />
        <Field
          css={classes.field}
          validate={[required]}
          component={TextFieldInput}
          name="newEndorsement.title"
          placeholder={titleFieldPlaceholder}
        />
        <Field
          css={classes.field}
          validate={[required, maxLength40]}
          component={TextFieldInput}
          name="newEndorsement.text"
          placeholder={descriptionFieldPlaceholder}
        />
        {children}
        <div css={classes.submit}>
          <Button onClick={onSubmit}>{buttonText}</Button>
        </div>
      </div>
    );
  }
}

export default AddCaseStudyFormSection;
