import PropTypes from 'prop-types';

const InvitedUserType = PropTypes.shape({
  invitedUserEmail: PropTypes.string.isRequired,
  inviteToken: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export default InvitedUserType;
