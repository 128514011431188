/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TagSearchInput from 'components/SolutionsSearch/TagSearchInput';
import routes from 'constants/routes';
import * as classes from './styles';

const SolutionsSearchForm = ({ className }) => {
  const [value, onChange] = useState('');

  const history = useHistory();

  const handleGoToSearchResults = (selectedValue) => {
    const trimmedValue = selectedValue.trim();

    if (trimmedValue) {
      const searchString = new URLSearchParams({ query: selectedValue }).toString();
      const pathname = routes.PUBLIC_SEARCH_SOLUTION;

      history.push({ pathname, search: searchString });
    }
  };

  return (
    <div className={className} css={classes.wrapper}>
      <TagSearchInput
        value={value}
        onSetValue={onChange}
        onSelect={handleGoToSearchResults}
        css={classes.autocomplete}
      />
      <Button variant="outlined" onClick={() => handleGoToSearchResults(value)}>Search</Button>
    </div>
  );
};

SolutionsSearchForm.propTypes = {
  className: PropTypes.string,
};

SolutionsSearchForm.defaultProps = {
  className: null,
};

export default SolutionsSearchForm;
