import { css } from '@emotion/react';

export const video = css`
  margin: 0 auto;
`;

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);
