/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import SnackbarCloseButton from './SnackbarCloseButton';
import sx from './styles';

const AUTO_HIDE_TIMEOUT = 5000;

const SnackbarContainer = ({ children }) => (
  <SnackbarProvider
    sx={sx}
    autoHideDuration={AUTO_HIDE_TIMEOUT}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    iconVariant={{
      info: <InfoOutlinedIcon />,
      success: <CheckCircleOutlineOutlined />,
      error: <HighlightOffOutlined />,
    }}
    action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
  >
    {children}
  </SnackbarProvider>
);

SnackbarContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarContainer;
