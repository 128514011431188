/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ChevronLeftFilled } from '@fluentui/react-icons';
import * as classes from './styles';

const BackButton = ({ className, onClick }) => {
  const history = useHistory();

  return (
    <button css={classes.button} className={className} onClick={onClick || history.goBack}>
      <ChevronLeftFilled css={classes.icon} />
    </button>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  className: null,
  onClick: null,
};

export default BackButton;
