import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Form, Field } from 'react-final-form';
import Button from '@mui/material/Button';
import TextFieldInput from 'components/Form/TextFieldInput';
import { required, validateEmail } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import { updateCompanyProfile } from 'store/thunk/company';

const CompanyPrimaryContact = ({ className }) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useSelector((state) => ({
    contactName: state.user.company.contactName,
    contactEmail: state.user.company.contactEmail,
  }));

  const dispatch = useDispatch();

  const handleUpdateCompanyProfile = async (values) => {
    try {
      await dispatch(updateCompanyProfile(values));
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (_) {
      enqueueSnackbar('Failed to update a primary contact', { variant: 'error' });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleUpdateCompanyProfile}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={className}>
            <Field
              sx={{ mb: 2 }}
              type="text"
              name="contactName"
              label="Contact name"
              validate={required}
              component={TextFieldInput}
            />
            <Field
              type="email"
              name="contactEmail"
              label="Contact email"
              validate={composeValidators(required, validateEmail)}
              component={TextFieldInput}
            />
          </div>
          <Button type="submit">Save</Button>
        </form>
      )}
    />
  );
};

CompanyPrimaryContact.propTypes = {
  className: PropTypes.string,
};

CompanyPrimaryContact.defaultProps = {
  className: null,
};

export default CompanyPrimaryContact;
