import { css } from '@emotion/react';

export const iconButton = (theme) => (
  css`
    &:focus {
      color: ${theme.palette.primary.main} !important;
      box-shadow: none !important;
    }
  `
);

export const collapseChevron = (theme) => (
  css`
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms;
  `
);

export const rotatedChevron = css`
  transform: rotate(180deg);
`;

export const disabled = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey} !important;
  `
);

export const desktopMenu = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      gap: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
);

export const mobileMenu = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `
);
