import { css } from '@emotion/react';

export const table = css`
  width: 100%;
  font-size: 0;
`;

export const th = (theme) => (
  css`
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)};
    text-align: left;
    color: ${theme.primaryPalette.menuGrey};

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(2)};
    }
  `
);

export const row = (theme) => (
  css`
    box-shadow: 0px 0px 10px rgba(50, 55, 64, 0.07);
    border-radius: 4px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
    `
);

export const td = (theme) => css`
  padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
  height: 64px;
  vertical-align: middle;
  box-sizing: border-box;

  &:first-of-type {
    padding-left: ${theme.spacing(2)};
  }

  &:last-of-type {
    padding-right: ${theme.spacing(2)};
  }
`;

export const logoCell = css`
  max-width: 110px;
`;

export const logo = css`
  max-width: 100%;
  max-height: 56px;
  height: auto;
`;

export const vendorNameTd = css`
  max-width: 225px;
`;

export const creationDateTd = css`
  max-width: 80px;
`;

export const message = css`
  max-width: 450px;
`;
