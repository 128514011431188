import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Register from 'components/Auth/RegisterPage';
import challengeAPI from 'api/challenge';
import invitedUserAPI from 'api/invitedUser';
import reviewRequestAPI from 'api/reviewRequest';
import preRegisteredUserAPI from 'api/preRegisteredUser';
import eventInvitedAttendeeAPI from 'api/eventInvitedAttendee';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import { unauthorizedRouteGuard } from 'utils/routeGuard';
import companyStatus from 'constants/companyStatus';
import params from 'constants/registrationQueryParams';
import registrationReason from 'constants/registrationReason';
import { isAcceptingResponses, isExpired } from 'utils/challengeCheckers';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const RegisterPage = () => {
  const { search } = useLocation();

  const getInitialProps = useCallback(async () => {
    if (search) {
      const queryParams = new URLSearchParams(search);

      const invitationToCompanyToken = queryParams.get(params.INVITATION_TO_COMPANY_TOKEN);
      const reviewInvitationToken = queryParams.get(params.REVIEW_INVITATION_TOKEN);
      const preRegisteredForChallengeUserId = queryParams.get(params.PREREGISTERED_FOR_RFI_USER_ID);
      const eventId = queryParams.get(params.EVENT_ID);
      const preRegisteredForEventEmail = queryParams.get(params.PRE_REGISTERED_FOR_EVENT_EMAIL);
      const sharedChallengeId = queryParams.get(params.SHARED_CHALLENGE_ID);

      if (invitationToCompanyToken) {
        const invitation = await invitedUserAPI.findInvitedUserForRegistrationByToken(
          invitationToCompanyToken,
        );

        if (invitation) {
          const { companyId, invitedUserEmail } = invitation;

          return {
            data: {
              reason: registrationReason.INVITED_TO_COMPANY,
              companyId,
              email: invitedUserEmail,
            },
          };
        }
      } else if (reviewInvitationToken) {
        const email = queryParams.get(params.INVITED_REVIEWER_EMAIL);
        const { data: [invitation] } = await reviewRequestAPI.getInvitationForNewUser(email);

        if (invitation && invitation.company) {
          return {
            data: {
              reason: registrationReason.INVITED_FOR_REVIEW,
              companySlug: invitation.company.slug,
              status: companyStatus.RETAILER,
              email,
            },
          };
        }
      } else if (preRegisteredForChallengeUserId) {
        const { data: [preRegisteredUser] } = await preRegisteredUserAPI.find({
          where: {
            id: preRegisteredForChallengeUserId,
          },
        });

        if (preRegisteredUser) {
          return {
            data: {
              ...preRegisteredUser,
              reason: registrationReason.PRE_REGISTERED_FOR_CHALLENGE,
              status: companyStatus.VENDOR,
            },
          };
        }
      } else if (preRegisteredForEventEmail && eventId) {
        const invitation = await eventInvitedAttendeeAPI.getInvitation({
          email: preRegisteredForEventEmail,
          eventId,
        });

        if (invitation) {
          const { user: users, company: { name, id, companyWebsite } } = invitation;
          const userData = users.find((user) => user.email === preRegisteredForEventEmail);

          return {
            data: {
              reason: registrationReason.PRE_REGISTERED_FOR_EVENT,
              firstName: userData.firstName,
              lastName: userData.lastName,
              position: userData.position,
              email: userData.email,
              companyId: id,
              companyWebsite,
              companyName: name,
              status: companyStatus.RETAILER,
              eventId,
            },
          };
        }
      } else if (sharedChallengeId) {
        const { data: sharedChallenge } = await challengeAPI.getSharedChallengeForRegistration(
          sharedChallengeId,
        );

        if (isAcceptingResponses(sharedChallenge) && !isExpired(sharedChallenge)) {
          return {
            data: {
              reason: registrationReason.INVITED_TO_SHARED_CHALLENGE,
              status: companyStatus.VENDOR,
              challengeId: sharedChallenge.id,
            },
          };
        }
      }
    }

    return null;
  }, [search]);

  return <FetchContainer getInitialProps={getInitialProps} component={Register} />;
};

export default withRouteGuards([unauthorizedRouteGuard])(
  (withPageTitle(pageTitles.REGISTER)(RegisterPage)),
);
