const hasTopicMatchWithTags = (topic, tags) => tags.find(({ name }) => name === topic);
const getMatchedTopicsCount = (topics, tags) => (
  topics.reduce((acc, { name }) => (hasTopicMatchWithTags(name, tags) ? acc + 1 : acc), 0)
);

const sortByMatchedTags = (attendees, tags) => (
  attendees.sort((a, b) => {
    if (a.topics && b.topics) {
      return getMatchedTopicsCount(b.topics, tags) - getMatchedTopicsCount(a.topics, tags);
    }

    if (a.topics && !b.topics) {
      const hasMatchedTopic = a.topics.some(({ name }) => hasTopicMatchWithTags(name, tags));

      return hasMatchedTopic ? -1 : 0;
    }

    if (b.topics && !a.topics) {
      const hasMatchedTopic = b.topics.some(({ name }) => hasTopicMatchWithTags(name, tags));

      return hasMatchedTopic ? 1 : 0;
    }

    return 0;
  })
);

export default sortByMatchedTags;
