import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes, fieldArrayMetaPropTypes } from 'redux-form';
import difference from 'lodash/differenceBy';
import CompanyPropertyAutocomplete from 'components/Shared/CompanyPropertyAutocomplete';
import tagAPI from 'api/tag';

const ChallengeTagFieldArray = ({
  className,
  fields,
  meta: { submitFailed, error },
}) => {
  const tags = fields.getAll();

  const handleRemove = (filteredTags) => {
    const removedTag = difference(tags, filteredTags);
    const removedTagIndex = tags.findIndex((tag) => tag === removedTag[0]);

    fields.remove(removedTagIndex);
  };

  return (
    <CompanyPropertyAutocomplete
      className={className}
      label="Add tags"
      name={fields.name}
      properties={tags}
      onAdd={(tag) => fields.push(tag.name)}
      onRemove={handleRemove}
      apiClient={tagAPI}
      error={submitFailed && error ? error : null}
      getOptionLabel={(option) => option}
    />
  );
};

ChallengeTagFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
};

ChallengeTagFieldArray.defaultProps = {
  className: null,
};

export default ChallengeTagFieldArray;
