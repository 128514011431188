/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DropDownInput from 'components/UI/DropDownInput';
import { COMPANY_GROWTH_STAGES } from '../constants';
import * as classes from './styles';

const CompanyMaturityStages = ({ className }) => (
  <div className={className}>
    <Field
      fullWidth
      name="vendors.stageOfMaturity"
      id="stageOfMaturity"
      label="Stage of maturity"
      component={DropDownInput}
    >
      {COMPANY_GROWTH_STAGES.map((item) => (
        <option value={item} key={item}>{item}</option>
      ))}
    </Field>

    <div css={classes.container}>
      <div css={classes.block}>
        <span css={classes.bold}>Early</span> - Choose this if your
        company is less than 2 years old, it is seed funded (if funding has been raised), or has
        less than 3 client references.
      </div>
      <div css={classes.block}>
        <span css={classes.bold}>Growth</span> - Choose this if your
        company is up to 4 years old, has raised Series A funding (if funding has been raised), or
        has more than 10 client references.
      </div>
      <div css={classes.block}>
        <span css={classes.bold}>Mature</span> - Choose this if your
        company is more than 4 years old, has raised Series B funding or more (if funding has been
        raised), or has more than 20 client references.
      </div>
    </div>
  </div>
);

CompanyMaturityStages.propTypes = {
  className: PropTypes.string,
};

CompanyMaturityStages.defaultProps = {
  className: null,
};

export default CompanyMaturityStages;
