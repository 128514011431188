const registrationQueryParams = {
  INVITATION_TO_COMPANY_TOKEN: 'inviteToCompanyToken',
  REVIEW_INVITATION_TOKEN: 'tokenforEndorsement',
  PREREGISTERED_FOR_RFI_USER_ID: 'preRegisteredUserId',
  INVITED_REVIEWER_EMAIL: 'emailFromUser',
  EVENT_ID: 'eventId',
  PRE_REGISTERED_FOR_EVENT_EMAIL: 'email',
  SHARED_CHALLENGE_ID: 'sharedRFIId',
};

export default registrationQueryParams;
