import PropTypes from 'prop-types';

const UploadIcon = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="35"
    viewBox="0 0 23 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8445 1.12654C16.8445 0.504371 16.1956 0 15.3952 0H7.27923C6.47881 0 5.82995 0.504371 5.82995 1.12654V13.2932C5.82995 13.9154 6.47881 14.4198 7.27923 14.4198H15.3952C16.1956 14.4198 16.8445 13.9154 16.8445 13.2932V1.12654Z"
      fill="#D1D700"
    />
    <path
      d="M9.93539 27.9335C10.5 28.6549 11.8471 28.6549 12.4117 27.9335L22.1336 15.5122C22.7212 14.7615 22.026 13.8002 20.8955 13.8002L1.45156 13.8002C0.321064 13.8002 -0.374161 14.7615 0.213425 15.5122L9.93539 27.9335Z"
      fill="#D1D700"
    />
    <rect
      width="2.96611"
      height="22.347"
      rx="1.48306"
      transform="matrix(4.84304e-08 1 1 -3.94522e-08 0 32.0339)"
      fill="#D1D700"
    />
  </svg>
);

UploadIcon.propTypes = {
  className: PropTypes.string,
};

UploadIcon.defaultProps = {
  className: null,
};

export default UploadIcon;
