import PropTypes from 'prop-types';
import { Fab, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import clsx from 'clsx';
import FileIcon from 'svg/fileIcon';
import './UploadedFileField.scss';

const UploadedFileField = ({ className, value: { name, link }, onRemove }) => (
  <div className={clsx('document-upload-field', className)}>
    <FileIcon className="document-upload-field__icon" />
    <Typography className="document-upload-field__file-name" variant="body2">
      {name}
    </Typography>
    <Fab size="small" onClick={() => onRemove(link)}>
      <Clear fontSize="small" />
    </Fab>
  </div>
);

UploadedFileField.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

UploadedFileField.defaultProps = {
  className: null,
};

export default UploadedFileField;
