/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PageContentPlaceholder from 'components/Shared/PageContentPlaceholder';
import FetchError from 'components/FetchError';
import httpResponseCodes from 'constants/httpResponseCodes';
import errorContainerStyles from './styles';

let timeout;

const FetchContainer = ({ component: Component, getInitialProps, ...rest }) => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isLoaderShown, setLoaderShown] = useState(false);

  const [pageProps, setPageInitialProps] = useState({});

  const handleUpdateInitialProps = useCallback(
    (updatedState) => {
      setPageInitialProps({ ...pageProps, ...updatedState });
    },
    [pageProps],
  );

  useEffect(() => {
    setLoading(true);
    setLoaderShown(false);

    timeout = setTimeout(() => setLoaderShown(true), 100);

    getInitialProps()
      .then((data) => {
        if (data) {
          setPageInitialProps(data);
        }

        setLoading(false);
      })
      .catch((error) => {
        const responseStatus = get(error, 'response.status');

        // We handle 401 errors separately in axios response interceptor
        if (responseStatus !== httpResponseCodes.UNAUTHORIZED) {
          setError(true);
          setLoading(false);
        }
      });
  }, [getInitialProps]);

  useEffect(() => {
    if (!isLoading) {
      setLoaderShown(false);
    }

    return () => clearTimeout(timeout);
  }, [isLoading]);

  if (isError) {
    return <FetchError css={errorContainerStyles} />;
  }

  if (isLoading && !isLoaderShown) {
    return null;
  }

  if (isLoading && !isLoaderShown) {
    return null;
  }

  if (isLoading) {
    return <PageContentPlaceholder />;
  }

  return <Component setPageProps={handleUpdateInitialProps} {...pageProps} {...rest} />;
};

FetchContainer.propTypes = {
  getInitialProps: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired])
    .isRequired,
};

export default FetchContainer;
