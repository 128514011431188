/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Button from '@mui/material/Button';
import TextFieldInput from 'components/Form/TextFieldInput';
import composeValidators from 'utils/composeValidators';
import { maxLength100, required } from 'lib/validation';
import * as classes from './styles';

const DeclineChallengeForm = ({ onSubmit, onClose }) => (
  <div>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div css={classes.section}>
            <Field
              label="Please specify the reason why you are rejecting the RFI"
              name="rejectionReason"
              component={TextFieldInput}
              type="text"
              multiline
              rows="6"
              validate={composeValidators(required, maxLength100)}
            />
          </div>
          <div css={classes.buttons}>
            <Button type="submit" onClick={handleSubmit}>Submit</Button>
            <Button onClick={onClose}>Close</Button>
          </div>
        </form>
      )}
    />
  </div>
);

DeclineChallengeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeclineChallengeForm;
