/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  Field,
  getFormSyncErrors,
  formValueSelector,
  propTypes,
} from 'redux-form';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { withSnackbar } from 'notistack';

import DocumentUploadField from 'components/Shared/DocumentUploadField';
import TextFieldInput from 'components/UI/TextFieldInput';
import RadioButton from 'components/Form/RadioButton';
import getFileNameFromLink from 'utils/getFileNameFromLink';
import getErrorMessage from 'utils/getErrorMessage';
import * as thunks from 'store/thunk';
import fileAPI from 'api/file';
import { required } from 'lib/validation';
import { CaseStudyType } from 'types/Endorsements';
import SavedCaseStudy from 'components/Shared/SavedCaseStudy';
import SavedEndorsementContainer from '../SavedEndorsementContainer';
import AddCaseStudyFormSection from '../AddCaseStudyFormSection';
import * as classes from './styles';

const caseStudyFormats = {
  FILE: 'file',
  LINK: 'link',
};

const endorsementTypes = {
  CASE_STUDY: 'caseStudy',
  TESTIMONIAL: 'testimonial',
};

const MAX_CASE_STUDIES = 3;

const CASE_STUDIES_FORM_ID = 'casestudiesForm';

class CaseStudies extends Component {
  state = {
    documentFileName: null,
  };

  static propTypes = {
    createOrUpdate: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    errors: PropTypes.shape({
      caseStudyFormat: PropTypes.string,
    }).isRequired,
    endorsementLink: PropTypes.string,
    endorsementPicture: PropTypes.string,
    endorsementType: PropTypes.oneOf(Object.values(endorsementTypes)),
    caseStudyFormat: PropTypes.oneOf(Object.values(caseStudyFormats)),
    caseStudies: PropTypes.arrayOf(CaseStudyType),
    enqueueSnackbar: PropTypes.func.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    caseStudies: null,
    endorsementPicture: null,
    endorsementLink: null,
    endorsementType: null,
    caseStudyFormat: null,
  };

  componentDidUpdate(prevProps) {
    const {
      caseStudyFormat,
      endorsementLink,
      endorsementType,
      resetSection,
    } = this.props;

    if (
      caseStudyFormat === caseStudyFormats.LINK
      && prevProps.caseStudyFormat === caseStudyFormats.FILE
      && !!endorsementLink
    ) {
      this.handleRemoveFileOnServer(endorsementLink);
      this.setState({ documentFileName: null });
      resetSection('newEndorsement.link');
    }

    if (
      caseStudyFormat === caseStudyFormats.FILE
      && prevProps.caseStudyFormat === caseStudyFormats.LINK
      && !!endorsementLink
    ) {
      resetSection('newEndorsement.link');
    }

    if (
      endorsementType === endorsementTypes.TESTIMONIAL
      && prevProps.endorsementType === endorsementTypes.CASE_STUDY
      && prevProps.caseStudyFormat === caseStudyFormats.FILE
      && prevProps.endorsementLink
    ) {
      this.handleRemoveFileOnServer(prevProps.endorsementLink);
    }

    if (
      !!prevProps.endorsementType
      && !!endorsementType
      && endorsementType !== prevProps.endorsementType
    ) {
      if (prevProps.endorsementPicture) {
        this.handleRemoveFileOnServer(prevProps.endorsementPicture);
      }

      resetSection('newEndorsement');
      resetSection('caseStudyFormat');
    }
  }

  handleDeleteCaseStudy = (i) => {
    const { caseStudies, enqueueSnackbar } = this.props;

    const { picture } = caseStudies[i];
    const filteredList = caseStudies.filter((_, index) => index !== i);

    try {
      Promise.all([
        !!picture && this.handleRemoveFileOnServer(picture),
        this.handleSaveCaseStudies(filteredList),
      ]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  handleSubmitCaseStudy = async ({ newEndorsement }) => {
    const { caseStudies, reset } = this.props;
    const updatedCaseStudies = Array.isArray(caseStudies)
      ? [...caseStudies, newEndorsement]
      : [newEndorsement];

    await this.handleSaveCaseStudies(updatedCaseStudies);

    reset();
  };

  handleSaveCaseStudies = async (updatedCaseStudies) => {
    const { createOrUpdate, enqueueSnackbar } = this.props;

    try {
      const body = { vendors: { caseStudies: updatedCaseStudies } };

      await createOrUpdate(body);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  handleRemoveFileOnServer = async (link) => {
    const { userId, enqueueSnackbar } = this.props;

    try {
      const fileName = getFileNameFromLink(link);

      await fileAPI.deleteFileFromServer(userId, fileName);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  handleSaveFile = (onChange, value, name) => async (formData) => {
    const { userId } = this.props;

    const { body } = await fileAPI.uploadFile('casestudies', name, formData, userId);

    onChange(body);
  };

  handleSaveDocument = (onChange) => (formData) => {
    const { name: documentFileName } = formData.get('file');
    this.setState({ documentFileName });

    return this.handleSaveFile(onChange, null, 'link')(formData);
  };

  render() {
    const {
      handleSubmit,
      submitFailed,
      errors,
      caseStudyFormat,
      endorsementType,
      caseStudies,
    } = this.props;
    const { documentFileName } = this.state;

    return (
      <div>
        {!!caseStudies && (
          caseStudies.map((item, i) => (
            <SavedEndorsementContainer
              css={classes.field}
              key={i}
              onDelete={() => this.handleDeleteCaseStudy(i)}
            >
              <SavedCaseStudy document={item} />
            </SavedEndorsementContainer>
          ))
        )}

        {(!caseStudies || caseStudies.length < MAX_CASE_STUDIES) && (
          <>
            <fieldset css={!!endorsementType && classes.field}>
              <FormLabel component="legend">
                Add another case study or testimonial
              </FormLabel>
              <div>
                <Field
                  name="endorsementType"
                  type="radio"
                  label="Case Study"
                  value={endorsementTypes.CASE_STUDY}
                  component={RadioButton}
                />
                <Field
                  name="endorsementType"
                  type="radio"
                  label="Testimonial"
                  value={endorsementTypes.TESTIMONIAL}
                  component={RadioButton}
                  />
              </div>
            </fieldset>

            <div>
              {endorsementType === endorsementTypes.CASE_STUDY && (
                <AddCaseStudyFormSection
                  onUploadFile={this.handleSaveFile}
                  buttonText="Save case study"
                  description="You can upload up to three client case studies you want featured"
                  descriptionFieldPlaceholder="Summary of the case study (40 words maximum)"
                  formTitle="Add a case study"
                  onDeleteFile={this.handleRemoveFileOnServer}
                  onSubmit={handleSubmit(this.handleSubmitCaseStudy)}
                  titleFieldPlaceholder="Case study title"
                >
                  <div>
                    <FormControl component="fieldset" css={classes.field}>
                      <FormLabel component="legend" css={classes.label}>
                        Please select the appropriate case study format
                      </FormLabel>
                      <div css={classes.radioGroup}>
                        <Field
                          component={RadioButton}
                          label="Link"
                          type="radio"
                          name="caseStudyFormat"
                          value={caseStudyFormats.LINK}
                          validate={[required]}
                        />
                        <Field
                          component={RadioButton}
                          label="PDF upload"
                          type="radio"
                          name="caseStudyFormat"
                          value={caseStudyFormats.FILE}
                          validate={[required]}
                        />
                      </div>
                      {submitFailed && errors.caseStudyFormat && (
                        <FormHelperText error>{errors.caseStudyFormat}</FormHelperText>
                      )}
                    </FormControl>

                    {caseStudyFormat === caseStudyFormats.FILE && (
                      <Field
                        css={classes.field}
                        name="newEndorsement.link"
                        component={DocumentUploadField}
                        onSaveFile={this.handleSaveDocument}
                        onRemoveFile={this.handleRemoveFileOnServer}
                        fileName={documentFileName}
                        validate={[required]}
                      />
                    )}
                    {caseStudyFormat === caseStudyFormats.LINK && (
                      <Field
                        css={classes.field}
                        validate={[required]}
                        component={TextFieldInput}
                        name="newEndorsement.link"
                        placeholder="Please provide the URL for the case study"
                      />
                    )}
                  </div>
                </AddCaseStudyFormSection>
              )}

              {endorsementType === endorsementTypes.TESTIMONIAL && (
                <AddCaseStudyFormSection
                  buttonText="Save Testimonial"
                  onUploadFile={this.handleSaveFile}
                  description="Please provide the following information for your testimonial, then click 'Save testimonial'."
                  descriptionFieldPlaceholder="Please provide a quote from your client (40 words maximum)"
                  formTitle="Add a testimonial"
                  onDeleteFile={this.handleRemoveFileOnServer}
                  onSubmit={handleSubmit(this.handleSubmitCaseStudy)}
                  titleFieldPlaceholder="Client name"
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.authUser.id,
  caseStudies: state.user.company.vendors.caseStudies,
  errors: getFormSyncErrors(CASE_STUDIES_FORM_ID)(state),
  endorsementType: formValueSelector(CASE_STUDIES_FORM_ID)(state, 'endorsementType'),
  caseStudyFormat: formValueSelector(CASE_STUDIES_FORM_ID)(state, 'caseStudyFormat'),
  endorsementLink: formValueSelector(CASE_STUDIES_FORM_ID)(state, 'newEndorsement.link'),
  endorsementPicture: formValueSelector(CASE_STUDIES_FORM_ID)(state, 'newEndorsement.picture'),
});

const mapDispatchToProps = (dispatch) => ({
  createOrUpdate: (updatedData) => dispatch(thunks.updateCompanyProfile(updatedData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({ form: CASE_STUDIES_FORM_ID })(withSnackbar(CaseStudies)),
);
