import { css } from '@emotion/react';

export const fields = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);

export const buttons = css`
  display: flex;
  justify-content: space-between;
`;

export const modal = (theme) => (
  css`
    padding-bottom: ${theme.spacing(1)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);
