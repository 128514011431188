import { css } from '@emotion/react';

export const buttons = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  `
);

export default buttons;
