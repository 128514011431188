/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Link,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ShareProfile from 'components/Shared/ShareProfile';
import routes from 'constants/routes';
import companyStatus from 'constants/companyStatus';
import contactFormTrigger from 'constants/contactFormTrigger';
import insertHost from 'utils/picture';
import ContactVendor from '../ContactVendor';
import SaveVendor from '../SaveVendor';
import * as classes from './styles';

const VendorProfileHead = ({ breadcrumbs, isSticky }) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;

  const isAuthenticated = useSelector((state) => !!state.user.authUser.id);
  const isRetailer = useSelector((state) => state.user.company.type === companyStatus.RETAILER);
  const company = useSelector((state) => state.vendorInfo.company);

  const isPublicVendorProfile = matchPath(pathname, {
    path: routes.PUBLIC_VENDOR_INFO,
    strict: true,
  });

  const handleGoBack = () => {
    const isPrivateVendorProfile = !!matchPath(pathname, { path: routes.VENDOR_INFO });

    const queryParams = new URLSearchParams(search);
    const query = queryParams.get('query');

    if (isPrivateVendorProfile && query) {
      const searchPageQuery = new URLSearchParams({ query }).toString();

      history.push({ pathname: routes.SEARCH_SOLUTION_PAGE, search: searchPageQuery });
    } else if (isPublicVendorProfile && query) {
      const searchPageQuery = new URLSearchParams({ query }).toString();

      history.push({ pathname: routes.PUBLIC_SEARCH_SOLUTION, search: searchPageQuery });
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div css={classes.container}>
        <Breadcrumbs sx={{ mb: 2 }}>
          {breadcrumbs.map(({ title, to }) => <Link key={to} to={to}>{title}</Link>)}
        </Breadcrumbs>
        <div css={classes.content}>
          <div>
            <div css={classes.top}>
              <IconButton size="small" css={classes.icon} onClick={handleGoBack}>
                <ChevronLeft />
              </IconButton>
              {!!company.logo && (
                <img css={classes.getImageStyles(isSticky)} src={insertHost(company.logo)} alt="" />
              )}
            </div>
            <Typography variant="h3">{company.name}</Typography>
          </div>

          {isAuthenticated && (
            <div css={classes.buttons}>
              <ShareProfile css={classes.button} companyId={company.id} />
              {isAuthenticated && isRetailer && (
                <>
                  <ContactVendor
                    css={classes.button}
                    companyId={company.id}
                    contactName={company.contactName}
                    trigger={contactFormTrigger.VENDOR_CONTACT_FORM}
                  />
                  <SaveVendor css={classes.button} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

VendorProfileHead.propTypes = {
  isSticky: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default React.memo(VendorProfileHead);
