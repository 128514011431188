export const PERSONAL_DATA = [
  'personal data that you provide by completing forms on the Website, including if you sign up to receive our news , reports and updates, if you subscribe to any of the Services or if you request any other information;',
  'if you register or create an account with us, your log-in and password details;',
  'information contained in communications you send to us, for example when you report a problem or to submit queries, concerns or comments regarding the Website or its content; and',
  'data from surveys that we may, from time to time, run on the Website for research purposes, if you choose to respond to, or participate in, them.',
];

export const DATA_COLLECTING_RESOURCES = [
  'when you register or create an account with us;',
  'when you use the Services;',
  'automatically when you navigate through the Website. Information collected automatically may include usage details, geo-location data, IP addresses and information collected through cookies, and other tracking technologies (which may not be information which identifies you) (please see paragraph entitled “Cookies” below for further information);',
  'when you provide content and other information when you use the Services or post on our social media accounts;',
  'when you visit our Website and any other webpage that we own and manage;',
  'you manage/change your account information;',
  'contact us by phone, email, post or via the Website;',
  'subscribe to receive our news and updates; and/or',
  'when participate in surveys.',
];

export const PURPOSES_USING_PERSONAL_DATA = [
  'to provide you with the information, products and services that you request from us;',
  'to provide you with information about other products and services we offer that are similar to those that you have already purchased or enquired about (please see paragraph entitled “Marketing” below for further information);',
  'to ensure that content from this Website is presented in the most effective manner for you and for your computer;',
  'to administer this Website and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;',
  'to improve this Website to ensure that content is presented in the most effective manner for you and for your computer;',
  'to allow you to participate in interactive features of our Services, when you choose to do so;',
  'to liaise with partners in connection with your use of the Services;',
  'to deal with enquiries or complaints;',
  'as part of our efforts to keep this Website safe and secure;',
  'to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;',
  'to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;',
  'to conduct our internal business and management processes, for example accounting or auditing purposes; and',
  'for any other purposes that you would reasonably expect.',
];

export const DISCLOSURE_PERSONAL_DATA = [
  'any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006;',
  'other third parties we engage to help us run our business - such as our customer support service provider; payment processors; contractors; couriers; pay roll service providers; debt collection agencies and other parties that assist with debt-recovery functions;',
  'analytics and search engine service providers that assist us in the improvement and optimisation of this Website;',
  'credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;',
  'our professional advisors, including lawyers, accountants, tax advisors and auditors;',
  'law enforcement bodies, Courts of law or as otherwise required or authorised by law; and',
  '  regulatory or government bodies for the purposes of resolving complaints or disputes both internally and externally or to comply with any investigation by one of those bodies;',
];
