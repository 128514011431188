import { css } from '@emotion/react';

export const wrapper = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: center;
    }

    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  `
);

export const autocomplete = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex: 1;
      margin-right: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
