const preRegisteredUserFields = [
  'companyName',
  'phoneNumber',
  'email',
  'firstName',
  'lastName',
  'companyWebsite',
  'status',
  'reason',
];

export default preRegisteredUserFields;
