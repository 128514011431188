import { css } from '@emotion/react';

export const root = css`
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
`;

export const clickable = css`
  cursor: pointer;
`;

export const active = (theme) => (
  css`
    color: #fff;
    background-color: ${theme.secondaryPalette.blue};
  `
);

export const notActive = (theme) => (
  css`
    color: ${theme.primaryPalette.mediumGrey};
    background-color: #edf4f5;
  `
);
