import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import ChallengeSynopsis from 'components/Challenge/ChallengeSynopsis';
import challengeParticipationRequestAPI from 'api/challengeRequestParticipation';
import routes from 'constants/routes';
import getErrorMessage from 'utils/getErrorMessage';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import LocationType from 'types/Location';
import ChallengeParticipationRequestType from 'types/ChallengeParticipationRequest';
import challengeRequestParticipationStatus from 'constants/challengeParticipationRequestStatus';
import challengeStatus from 'constants/challengeStatus';
import companyStatus from 'constants/companyStatus';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import CompanyRegistrationBanner from 'components/Shared/CompanyRegistrationBanner';
import { isExpired } from 'utils/challengeCheckers';
import './PublicChallengeSynopsis.scss';

const PublicChallengeSynopsis = ({
  isAuthenticated,
  isVendorApproved,
  challenge,
  location,
  enqueueSnackbar,
  participationRequest,
  setPageProps,
  challengeResponse,
  company,
}) => {
  const { id: challengeId } = challenge;

  const isResponseDisabled = (
    challengeResponse
    && challengeResponse.status === challengeResponseStatus.REMOVED_FROM_SHORTLIST
  );

  const handleSendParticipationRequest = () => (
    challengeParticipationRequestAPI.sendParticipationRequest(challengeId)
      .then(({ data }) => {
        enqueueSnackbar('Success', { variant: 'success' });
        setPageProps({ participationRequest: data });
      })
      .catch((sendError) => {
        const message = getErrorMessage(sendError, 'Failed to send request');
        enqueueSnackbar(message, { variant: 'error' });
      })
  );

  const getParticipationRequestStatus = () => {
    if (!participationRequest) {
      return 'Request to participate';
    }

    if (participationRequest.status === challengeRequestParticipationStatus.PENDING) {
      return 'Participation request sent';
    }

    if (participationRequest.status === challengeRequestParticipationStatus.REJECTED) {
      return 'Your participation request is rejected';
    }

    return 'Your participation request is approved';
  };

  const getCloseReason = () => {
    if (!challenge) {
      return null;
    }

    if (challenge.status === challengeStatus.COMPLETED) {
      return 'The RFI owner selected a winner of the RFI.';
    }

    if (challenge.status === challengeStatus.PAUSED) {
      return 'The RFI has been placed on hold.';
    }

    if (challenge.status === challengeStatus.CANCELLED) {
      return 'The RFI is cancelled.';
    }

    if (isExpired(challenge)) {
      return 'The response deadline has passed.';
    }

    return null;
  };

  const closeReason = getCloseReason();

  const breadcrumbs = isAuthenticated
    ? [
      { title: 'Home', to: routes.MAIN },
      { title: 'RFIs', to: routes.VENDOR_CHALLENGE_DASHBOARD },
      { title: challenge.name, to: location.pathname },
    ] : [
      { title: 'Login', to: routes.LOGIN },
      { title: challenge.name, to: location.pathname },
    ];

  return (
    <div>
      <Breadcrumbs sx={{ mb: 5 }}>
        {breadcrumbs.map(({ title, to }) => <Link key={to} to={to}>{title}</Link>)}
      </Breadcrumbs>

      {/* TODO: separate vendor and guest version */}
      <div className="public-challenge-synopsis">
        <Typography sx={{ mb: 5 }} variant="h1">{company.name}</Typography>
        <Typography sx={{ mb: 3 }} variant="h2">{challenge.name}</Typography>

        {!isExpired(challenge) && isResponseDisabled && (
          <CompanyRegistrationBanner
            className="public-challenge-synopsis__banner"
            title="RFI participation is ended"
            additionalInfo="You are no longer participating in this RFI. If you provided a response it has been saved. Keep checking for further opportunities and thank you for your interest."
          />
        )}

        {!!closeReason && (
          <div className="public-challenge-synopsis__expired expired-challenge-info">
            <Typography className="expired-challenge-info__title" variant="h2">
              RFI is closed
            </Typography>
            <Typography variant="body1">
              {closeReason} We are not accepting any new responses at this time.
            </Typography>
          </div>
        )}

        <ChallengeSynopsis challenge={challenge} />

        {!(isResponseDisabled || closeReason) && (
          <div className="public-challenge-synopsis__actions">
            {isAuthenticated && isVendorApproved && (
              <Button onClick={handleSendParticipationRequest} disabled={!!participationRequest}>
                {getParticipationRequestStatus()}
              </Button>
            )}
            {!isAuthenticated && (
              <>
                <Button component={Link} to={routes.REGISTER}>Find out more</Button>
                <Button
                  variant="outlined"
                  component={Link}
                  to={{
                    pathname: routes.LOGIN,
                    search: new URLSearchParams({ backPath: location.pathname }).toString(),
                  }}
                >
                  Login
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PublicChallengeSynopsis.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isVendorApproved: PropTypes.bool.isRequired,
  challenge: ChallengeSynopsisType.isRequired,
  challengeResponse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(challengeResponseStatus)).isRequired,
  }),
  participationRequest: ChallengeParticipationRequestType,
  enqueueSnackbar: PropTypes.func.isRequired,
  location: LocationType.isRequired,
  setPageProps: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

PublicChallengeSynopsis.defaultProps = {
  participationRequest: null,
  challengeResponse: null,
};

export default connect((state) => ({
  isAuthenticated: !!state.user.authUser.id,
  isVendorApproved: (
    state.user.company.type === companyStatus.VENDOR
    && state.user.company.adminApprove
  ),
}))(withSnackbar(PublicChallengeSynopsis));
