/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { required } from 'lib/validation';
import userAPI from 'api/user';
import TextFieldInput from 'components/Form/TextFieldInput';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import routes from 'constants/routes';
import httpResponseCodes from 'constants/httpResponseCodes';
import LocationType from 'types/Location';
import validate from './validation';
import * as classes from './styles';

const MESSAGE_DISPLAY_TIMEOUT = 2000;

const ResetPasswordForm = ({ history, location }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('access_token');

  useEffect(() => {
    if (!token) {
      history.push(routes.LOGIN);
    }
  }, [token, history]);

  const handleSubmitNewPassword = async ({ password }) => {
    try {
      await userAPI.resetPassword(password, token);

      enqueueSnackbar('Your password has been reset successfully.', { variant: 'success' });
      setTimeout(() => history.push(routes.LOGIN), MESSAGE_DISPLAY_TIMEOUT);
    } catch (error) {
      const errorCode = get(error, 'response.data.error.code');

      const errorMessage = errorCode === httpResponseCodes.UNAUTHORIZED
        ? 'Sorry, the link you used has expired. Please start the recovery process again'
        : 'Failed to update password';

      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <PublicPageContentWrapper css={classes.container}>
      <Typography variant="h1" sx={{ mb: 5 }}>Reset password</Typography>

      <Form
        onSubmit={handleSubmitNewPassword}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              sx={{ mb: 2 }}
              type="password"
              name="password"
              label="New password"
              component={TextFieldInput}
              validate={required}
            />
            <Field
              sx={{ mb: 2 }}
              type="password"
              name="confirmPassword"
              label="Confirm password"
              component={TextFieldInput}
              validate={required}
            />
            <div css={classes.footer}>
              <Button type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      />
    </PublicPageContentWrapper>
  );
};

ResetPasswordForm.propTypes = {
  history: PropTypes.object.isRequired,
  location: LocationType.isRequired,
};

export default ResetPasswordForm;
