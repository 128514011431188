import APIClient from './client';

class InvitedUserAPI extends APIClient {
  constructor() {
    super('/inviteUserToCompany');
  }

  getInvitationForRegistration = async (where) => {
    const filter = {
      where,
      fields: ['id', 'companyId', 'invitedUserEmail'],
    };

    const { data: [invitedUser] } = await this.find(filter);

    return invitedUser;
  };

  findInvitedUserForRegistrationByEmail = (email) => (
    this.getInvitationForRegistration({ invitedUserEmail: email })
  );

  findInvitedUserForRegistrationByToken = async (token) => (
    this.getInvitationForRegistration({ inviteToken: token })
  );

  resendInvitation = (id) => this.postRequest('/resend-invitation', { id });
}

const invitedUserAPI = new InvitedUserAPI();

export default invitedUserAPI;
