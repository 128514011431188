/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Delete20Regular, Edit20Regular, MoreVertical20Filled } from '@fluentui/react-icons';
import priorityTypes from 'constants/priorityTypes';
import * as classes from './styles';

const PriorityCardMobileMenu = ({
  priorityItemName,
  isOpen,
  setMenu,
  onRemove,
  onEdit,
}) => (
  <ClickAwayListener onClickAway={() => setMenu(false)}>
    <div>
      <IconButton onClick={() => setMenu((prev) => !prev)}>
        <MoreVertical20Filled css={classes.icon} />
      </IconButton>
      {isOpen && (
        // TODO: move styles to theme
        <List css={classes.dropdown}>
          <ListItem css={classes.dropdownButton} variant="text" onClick={onEdit}>
            <Edit20Regular css={classes.icon} />
            <Typography variant="body2">Edit {priorityItemName}</Typography>
          </ListItem>

          {!!onRemove && (
            <ListItem css={classes.dropdownButton} variant="text" onClick={onRemove}>
              <Delete20Regular css={classes.icon} />
              <Typography variant="body2">Delete {priorityItemName}</Typography>
            </ListItem>
          )}
        </List>
      )}
    </div>
  </ClickAwayListener>
);

PriorityCardMobileMenu.propTypes = {
  priorityItemName: PropTypes.oneOf(Object.values(priorityTypes)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setMenu: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default PriorityCardMobileMenu;
