import PropTypes from 'prop-types';

const LightLogo = ({ className, ...rest }) => (
  <svg
    className={className}
    width="125"
    height="30"
    viewBox="0 0 125 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M49.4244 0.0192871H42.6135V24.2849H49.4244V0.0192871Z" fill="#EBEBEB" />
    <path
      d="M33.0159 17.9928C32.9124 19.8296 31.7092 20.7482 30.161 20.7482C28.9225 20.7482 28.0278 19.9322 28.0278 19.1151C28.0278 17.9241 28.8191 17.5506 30.3318 17.2104C31.2576 17.0355 32.1586 16.7501 33.015 16.3605V17.9928H33.0159ZM39.5513 13.0954C39.5513 9.69505 39.6196 6.19116 31.0887 6.19116C26.8573 6.19116 22.1104 7.00714 21.9044 11.9735H28.2338C28.2681 11.2235 28.6813 10.1714 30.7793 10.1714C31.88 10.1714 33.015 10.6139 33.015 11.8037C33.015 12.9272 32.086 13.1983 31.1573 13.3681C27.6831 14.0143 21.0782 13.8106 21.0782 19.389C21.0782 23.0965 23.9332 24.7288 27.3734 24.7288C29.5751 24.7288 31.6731 24.2527 33.1175 22.6196H33.1858C33.1651 23.1851 33.2473 23.7498 33.4285 24.2866H40.3762C39.6178 23.1643 39.551 21.6674 39.551 20.3414V13.0954H39.5513Z"
      fill="#EBEBEB"
    />
    <path d="M59.5709 8.17749H52.76V24.2837H59.5709V8.17749Z" fill="#EBEBEB" />
    <path d="M0 0.0192871L2.01029 6.68268H9.48714L7.63644 0.0192871H0Z" fill="#D1D700" />
    <path
      d="M15.8017 0.0192871L11.7228 14.7275H11.654L9.86061 8.16103L2.45203 8.14844L7.3209 24.2849H15.7823L23.3799 0.0192871H15.8017Z"
      fill="#EBEBEB"
    />
    <path d="M91.3199 8.17749H84.509V24.2837H91.3199V8.17749Z" fill="#EBEBEB" />
    <path d="M91.3199 0.0192871H84.509V6.68058H91.3199V0.0192871Z" fill="#D1D700" />
    <path
      d="M117.944 6.63279L115.055 16.7689H114.987L112.131 6.63279H102.329C102.227 5.00055 102.776 4.52356 104.427 4.52356C104.982 4.56987 105.534 4.65002 106.079 4.76355V0.134996C105.047 0.101097 104.049 0 103.018 0C100.265 0 98.5464 0.203694 97.0664 1.66705C95.7597 2.95911 95.5877 4.89765 95.6559 6.6331H92.8693V10.7832H95.6559V24.2857H102.467V10.782H106.385L107.867 14.7625C108.246 15.7825 110.86 22.2794 110.86 23.1302C110.86 23.9126 110.62 24.2525 110.206 24.4561C109.725 24.6937 108.83 24.6262 108.143 24.6262H106.561V30H109.658C112.307 30 115.058 30.0339 116.848 27.8229C117.914 26.4958 118.533 24.49 119.084 22.9256L125 6.6337L117.944 6.63279Z"
      fill="#EBEBEB"
    />
    <path d="M59.5721 0.0192871H52.7612V6.68058H59.5721V0.0192871Z" fill="#D1D700" />
    <path
      d="M71.782 19.8297C69.3403 19.8297 68.7893 17.5498 68.7893 15.442C68.7893 13.3676 69.3403 11.0883 71.782 11.0883C74.2237 11.0883 74.7746 13.3682 74.7746 15.442C74.7746 17.5504 74.2249 19.8297 71.782 19.8297ZM74.5347 8.57133H74.4652C73.4337 7.07438 71.6102 6.19061 69.5805 6.19061C63.5949 6.19061 61.9784 11.2242 61.9784 15.3742C61.9784 19.7955 64.4213 24.7279 69.478 24.7279C72.8153 24.7279 73.8469 23.4695 74.7064 22.3472H74.7759V24.2854H81.3453V0H74.5347V8.57133Z"
      fill="#EBEBEB"
    />
  </svg>
);

LightLogo.propTypes = {
  className: PropTypes.string,
};

LightLogo.defaultProps = {
  className: null,
};

export default LightLogo;
