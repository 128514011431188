/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, propTypes } from 'redux-form';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import SavedEndorsement from 'components/Shared/SavedEndorsement';
import FileUpload from 'components/UI/FileUpload';
import TextFieldInput from 'components/UI/TextFieldInput';
import insertHost from 'utils/picture';
import getFileNameFromLink from 'utils/getFileNameFromLink';
import getErrorMessage from 'utils/getErrorMessage';
import fileAPI from 'api/file';
import * as thunks from 'store/thunk';
import { imageUploadConfig } from 'constants/fileUploadConfigs';
import { required, maxLength20 } from 'lib/validation';
import { ArticleType } from 'types/Endorsements';
import SavedEndorsementContainer from '../SavedEndorsementContainer';
import * as classes from './styles';

const MAX_ARTICLES = 3;

class Articles extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(ArticleType.isRequired),
    createOrUpdate: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    articles: null,
  };

  handleAddArticle = async ({ newArticle }) => {
    const { articles, reset } = this.props;
    const newArticlesList = Array.isArray(articles) ? [...articles, newArticle] : [newArticle];

    await this.updateArticleList(newArticlesList);

    reset();
  };

  handleDeleteArticle = async (i) => {
    const { articles, enqueueSnackbar } = this.props;

    const { picture } = articles[i];

    try {
      const filteredArticleList = articles.filter((_, index) => index !== i);

      Promise.all([
        this.updateArticleList(filteredArticleList),
        !!picture && this.handleRemoveFileOnServer(picture),
      ]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  updateArticleList = async (articles) => {
    const { createOrUpdate, enqueueSnackbar } = this.props;

    try {
      const body = { vendors: { articles } };

      await createOrUpdate(body);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  handleRemoveFileOnServer = async (link) => {
    const { userId, enqueueSnackbar } = this.props;

    try {
      const fileName = getFileNameFromLink(link);

      await fileAPI.deleteFileFromServer(userId, fileName);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  handleSaveFile = (onChange) => async (formData) => {
    const { userId } = this.props;

    const response = await fileAPI.uploadFile('articles', 'picture', formData, userId);

    onChange(response.body);

    return response;
  };

  renderUploadField = ({ input: { onChange, value }, meta: { submitFailed, error } }) => (
    <div css={classes.field}>
      <FormLabel component="h3" sx={{ mb: 1 }}>
        Client logo
      </FormLabel>
      {!!value && <img css={classes.imageUpload} src={insertHost(value)} alt="" />}
      <FileUpload
        {...imageUploadConfig}
        isIcon={!!value}
        onUpload={this.handleSaveFile(onChange)}
      />
      {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );

  render() {
    const { articles, handleSubmit } = this.props;

    return (
      <div>
        {!!articles && articles.map((item, index) => (
          <SavedEndorsementContainer
            key={index}
            css={classes.savedArticles}
            onDelete={() => this.handleDeleteArticle(index)}
          >
            <SavedEndorsement document={item} showLink />
          </SavedEndorsementContainer>
        ))}

        {(!articles || articles.length < MAX_ARTICLES) && (
          <>
            <fieldset>
              <FormLabel sx={{ mb: 1 }} component="legend">Add another article</FormLabel>
              <Field
                sx={{ mb: 2 }}
                validate={[required]}
                component={TextFieldInput}
                name="newArticle.topic"
                placeholder="Topic"
              />
              <Field
                sx={{ mb: 2 }}
                validate={[required, maxLength20]}
                component={TextFieldInput}
                name="newArticle.text"
                placeholder="Description (20 words maximum)"
              />
              <Field
                sx={{ mb: 2 }}
                validate={[required]}
                component={TextFieldInput}
                type="url"
                name="newArticle.link"
                placeholder="https://"
              />
              <Field
                name="newArticle.picture"
                component={this.renderUploadField}
              />
            </fieldset>
            <div css={classes.submit}>
              <Button onClick={handleSubmit(this.handleAddArticle)}>Save Article</Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.authUser.id,
  articles: state.user.company.vendors.articles,
});

const mapDispatchToProps = (dispatch) => ({
  createOrUpdate: (updatedData) => dispatch(thunks.updateCompanyProfile(updatedData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'articlesForm' })(withSnackbar(Articles)),
);
