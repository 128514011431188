/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import SelectField from 'components/Form/SelectField';
import getEventGuestName from 'components/Events/utils/getEventGuestName';
import { required } from 'lib/validation';
import EventAttendeeType from 'types/EventAttendee';
import SelectedAttendeePriorities from '../SelectedAttendeePriorities';
import * as classes from './styles';

const SelectAttendeeForm = ({
  className,
  initialValues,
  onSubmit,
  onClose,
  options,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitAttendee = async (values, form) => {
    if (!form.pristine) {
      try {
        const attendee = options.find(({ id }) => id === values.id);
        await onSubmit(attendee);

        onClose();
      } catch (_) {
        enqueueSnackbar('Failed to save attendee', { variant: 'error' });
      }
    }
  };

  return (
    <div css={classes.form} className={className}>
      <Form
        onSubmit={handleSubmitAttendee}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="id"
              fullWidth
              sx={{ mb: 2 }}
              component={SelectField}
              validate={required}
              emptyOptionLabel="Not selected"
            >
              {options.map((attendee) => (
                <option key={attendee.id} value={attendee.id}>
                  {getEventGuestName(attendee)}
                </option>
              ))}
            </Field>

            <SelectedAttendeePriorities
              options={options}
              selectedAttendeeId={values.id}
              css={classes.priorities}
            />

            <div css={classes.formButtonsWrap}>
              <Button type="submit" variant="outlined">
                Save
              </Button>
              <Button onClick={() => onClose()} variant="text">
                Cancel
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

SelectAttendeeForm.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(EventAttendeeType.isRequired).isRequired,
};

SelectAttendeeForm.defaultProps = {
  className: null,
  initialValues: null,
};

export default SelectAttendeeForm;
