/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import { fieldInputPropTypes } from 'redux-form';
import fileAPI from 'api/file';
import FileUpload from 'components/UI/FileUpload';
import UploadedFileField from 'components/UI/UploadedFileField';
import { challengeResponseAttachmentConfig } from 'constants/fileUploadConfigs';
import labelStyles from './styles';

const AttachmentField = ({ input: { onChange, value } }) => {
  const companyId = useSelector((state) => state.user.company.id);

  const handleSaveFile = async (formData) => {
    const { name } = formData.get('file');
    const { body } = await fileAPI.uploadFile('challenge-response', 'attachment', formData, companyId);

    onChange({ name, link: body });
  };

  const handleRemoveFile = () => onChange(null);

  return (
    <>
      <FormLabel css={labelStyles}>Attachment</FormLabel>
      {value ? (
        <UploadedFileField value={value} onRemove={handleRemoveFile} />
      ) : (
        <FileUpload {...challengeResponseAttachmentConfig} onUpload={handleSaveFile} />
      )}
    </>
  );
};

AttachmentField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
};

export default AttachmentField;
