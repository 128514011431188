import { css } from '@emotion/react';

const actions = (theme) => (
  css`
    padding: 0;
    display: flex;

    ${theme.breakpoints.up('sm')} {
      justify-content: flex-start;
    }

    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

      > * {
        width: 100%;
        margin: 0 auto ${theme.spacing(2)}!important;
      }
    }
  `
);

export default actions;
