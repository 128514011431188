const insertHost = (link) => {
  if (
    link
    && link.indexOf('https') === -1
    && link.indexOf('/api/containers/') > -1
  ) {
    return `${process.env.REACT_APP_API_HOST}${link}`;
  }

  if (link) {
    return link;
  }

  return '';
};

export default insertHost;
