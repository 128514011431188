import APIClient from './client';

class EventTopicNotesAPI extends APIClient {
  constructor() {
    super('/event-priority-notes');
  }
}

const eventTopicNotesAPI = new EventTopicNotesAPI();

export default eventTopicNotesAPI;
