import { css } from '@emotion/react';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0 auto;
`;

export const main = (theme) => (
  css`
    flex: 1;

    &::before {
      content: '';
      display: block;
      ${theme.mixins.toolbar};
    }
  `
);

export const links = css`
  max-width: 600px;
  margin: 0 auto;
`;

export const partners = css`
  margin: auto auto 0;
`;

export const footer = (theme) => (
  css`
    padding-top: ${theme.spacing(2)};
  `
);
