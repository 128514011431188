import PropTypes from 'prop-types';

const AwardIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 3.25H18.25V0.75H5.75V3.25H3.25C1.875 3.25 0.75 4.375 0.75 5.75V7C0.75 10.1875 3.15 12.7875 6.2375 13.175C7.025 15.05 8.7125 16.4625 10.75 16.875V20.75H5.75V23.25H18.25V20.75H13.25V16.875C15.2875 16.4625 16.975 15.05 17.7625 13.175C20.85 12.7875 23.25 10.1875 23.25 7V5.75C23.25 4.375 22.125 3.25 20.75 3.25ZM3.25 7V5.75H5.75V10.525C4.3 10 3.25 8.625 3.25 7ZM20.75 7C20.75 8.625 19.7 10 18.25 10.525V5.75H20.75V7Z"
      fill="#D1D700"
    />
  </svg>
);

AwardIcon.propTypes = {
  className: PropTypes.string,
};

AwardIcon.defaultProps = {
  className: null,
};

export default AwardIcon;
