import routes from 'constants/routes';

const publicPages = [
  routes.LOGIN,
  routes.REGISTER,
  routes.FORGOT_PASSWORD,
  routes.TERMS_AND_CONDITIONS,
  routes.PRIVACY,
  routes.VENDOR_PAYMENTS,
  routes.PUBLIC_SEARCH_SOLUTION,
  routes.PUBLIC_VENDOR_INFO_TAB,
  routes.REGISTRATION_SUCCESS,
  routes.RECOVER_PASSWORD,
];

export default publicPages;
