import PropTypes from 'prop-types';

const ChallengeWatcherType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isNotified: PropTypes.bool,
  email: PropTypes.string,
  userId: PropTypes.string,
  challengeId: PropTypes.string,

  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
});

export default ChallengeWatcherType;
