import { css } from '@emotion/react';

export const listItem = (theme) => (
  css`
    position: relative;
    padding-left: ${theme.spacing(3)};
    list-style-type: none;

    &::before {
      position: absolute;
      top: 6.5px;
      left: 0;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${theme.palette.primary.main};
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export default listItem;
