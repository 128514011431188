/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import routes from 'constants/routes';
import ReviewRequestType from 'types/ReviewRequest';
import RequestReviewCard from '../ReviewRequestCard';
import ReviewRequestsTable from '../ReviewRequestsTable';
import NoRequestsFallback from '../NoRequestsFallback';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';

const PendingReviewRequests = ({ requests }) => {
  const history = useHistory();

  const formatDate = (date) => format(new Date(date), DATE_FORMAT);

  const handleOpenVendorReviewForm = (slug) => {
    const pathname = generatePath(routes.VENDOR_PROFILE_TAB, {
      slug,
      tab: VENDOR_INFO_TABS.CONTACT.path,
    });

    const search = new URLSearchParams({ openModal: true }).toString();

    history.push({ pathname, search });
  };

  return requests.length ? (
    <>
      <div css={classes.requestCards}>
        {requests.map((request) => (
          <RequestReviewCard
            key={request.id}
            css={classes.card}
            request={request}
            onFormatDate={formatDate}
            onOpenReviewForm={() => handleOpenVendorReviewForm(request.company.slug)}
          />
        ))}
      </div>

      <ReviewRequestsTable
        css={classes.table}
        requests={requests}
        onFormatDate={formatDate}
        onOpenReviewForm={(request) => handleOpenVendorReviewForm(request.company.slug)}
      />
    </>
  ) : (
    <NoRequestsFallback />
  );
};

PendingReviewRequests.propTypes = {
  requests: PropTypes.arrayOf(ReviewRequestType.isRequired).isRequired,
};

export default PendingReviewRequests;
