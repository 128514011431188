const vendorDataCaptureFormTabs = {
  BASIC_DETAILS: 'basic-details',
  SOLUTION: 'solution',
  TECHNICAL_DETAILS: 'technical-details',
  ENDORSEMENTS: 'endorsements',
  COMPANY: 'company',
  GROWTH_STAFF: 'growth-staff',
};

export default vendorDataCaptureFormTabs;
