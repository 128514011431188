import { css } from '@emotion/react';

export const success = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const actions = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(2)};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
    }

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
);

export const submit = css`
  text-align: center;
`;
