import { css } from '@emotion/react';

export const table = (theme) => (
  css`
    width: 100%;
    border-spacing: 0 ${theme.spacing(1)};
    line-height: 0;
  `
);

export const th = (theme) => (
  css`
    padding-bottom: ${theme.spacing(1)};
    text-align: left;
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const cell = (theme) => (
  css`
    box-sizing: border-box;
    padding-right: ${theme.spacing(1)};
    padding-left: ${theme.spacing(1)};
    vertical-align: middle;

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(0.5)};
    }
  `
);

export const logo = css`
  max-width: 112px;
`;

export const name = css`
  max-width: 130px;
`;

export const overallRating = css`
  max-width: 120px;
`;
