import CompanyPropertyAPI from './companyProperty';

class TagAPI extends CompanyPropertyAPI {
  constructor() {
    super('/tags');
  }

  getTagsWithRequirements = async (tagNames, addedQuestions, limit) => {
    const filter = {
      // TODO: query requirements, not tags when a challenge will store tagIds, not tag names
      where: {
        name: {
          inq: tagNames,
        },
      },
      fields: ['id', 'name'],
      include: {
        relation: 'challengeRequirements',
        scope: {
          limit,
          where: {
            isReviewed: true,
            description: {
              nin: addedQuestions,
            },
          },
        },
      },
    };

    const { data } = await this.find(filter);

    return data;
  };
}

const tagAPI = new TagAPI();

export default tagAPI;
