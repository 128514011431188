import APIClient from './client';

class RoleAPI extends APIClient {
  constructor() {
    super('/InvitedRole');
  }
}

const roleAPI = new RoleAPI();

export default roleAPI;
