/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import getContainerStyles from './styles';

const YesNoAnswer = ({ className, size, isSelected }) => (
  <div className={className} css={getContainerStyles({ size, isSelected })} />
);

YesNoAnswer.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

YesNoAnswer.defaultProps = {
  className: null,
};

export default YesNoAnswer;
