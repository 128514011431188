import { css } from '@emotion/react';

export const appBar = (theme) => (
  css`
    bottom: 0;
  `
);

export const toolbar = (theme) => css`
  min-height: unset !important;
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
  background-color: ${theme.primaryPalette.white};
  box-shadow: ${theme.boxShadows.regular};
`;
