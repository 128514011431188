/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes } from 'redux-form';
import Typography from '@mui/material/Typography';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import SubmitButton from 'components/UI/SubmitButton';
import Articles from './Articles';
import CaseStudies from './CaseStudies';
import AwardsBlock from './AwardsBlock';
import validate from './validation';
import sectionStyles from './styles';

class Endorsments extends Component {
  static propTypes = {
    onUpdateProfile: PropTypes.func.isRequired,
    ...propTypes,
  };

  saveCompanyData = (isDraft) => ({ awards }) => {
    const { onUpdateProfile } = this.props;

    const filteredAwards = awards.filter(({ name, year }) => name && year);
    const body = { vendors: { awards: filteredAwards } };

    return onUpdateProfile(body, isDraft);
  };

  render() {
    const {
      handleSubmit,
      valid,
      dirty,
      submitSucceeded,
      submitting,
    } = this.props;

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <div css={sectionStyles}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Testimonials and case studies
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            You can upload up to three client case studies or testimonials you want featured
          </Typography>
          <CaseStudies />
        </div>

        <div css={sectionStyles}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Articles
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            You can upload up to three instances of press or media coverage for your company or
            solution
          </Typography>
          <Articles />
        </div>

        <AwardsBlock />

        <SubmitButton
          isLoading={submitting}
          onClick={handleSubmit(this.saveCompanyData(false))}
        >
          Save
        </SubmitButton>
      </DataCaptureFormContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: { awards: state.user.company.vendors.awards || [] },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'endorsmentsForm',
    enableReinitialize: true,
    validate,
  })(Endorsments),
);
