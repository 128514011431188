/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  fieldArrayMetaPropTypes,
  fieldArrayFieldsPropTypes,
  getFormValues,
} from 'redux-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import challengeAPI from 'api/challenge';
import FORM_ID from 'components/ChallengeForm/formId';
import SavedMilestones from 'components/Challenge/SavedMilestones';
import ManageMilestoneForm from 'components/ChallengeForm/ManageMilestoneForm';
import * as classes from './styles';

const ChallengeMilestonesFieldArray = ({
  fields,
  meta: {
    error,
    submitFailed,
  },
  className,
  onSaveChallengeDraft,
}) => {
  const milestones = fields.getAll();

  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState(null);
  const [isModalOpen, setModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formValues = useSelector((state) => getFormValues(FORM_ID)(state));

  const handleOpenEditMilestone = (index) => {
    setSelectedMilestoneIndex(index);
    setModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMilestoneIndex(null);
    setModal(false);
  };

  const handleAddMilestone = async (values) => {
    try {
      const { name, dueDate } = values;

      let { id: challengeId } = formValues;

      if (!challengeId) {
        const challenge = await onSaveChallengeDraft(formValues);
        challengeId = challenge.id;
      }

      const { data: milestone } = await challengeAPI.addMilestone(challengeId, { name, dueDate });

      fields.push(milestone);
      handleCloseModal();
      enqueueSnackbar('Successfully added', { variant: 'success' });
    } catch (_) {
      enqueueSnackbar('Failed to add milestone', { variant: 'error' });
    }
  };

  const handleUpdateMilestone = async (values) => {
    try {
      const { id: milestoneId, challengeId, ...body } = values;

      const { data } = await challengeAPI.editMilestone(challengeId, milestoneId, body);

      fields.remove(selectedMilestoneIndex);
      fields.insert(selectedMilestoneIndex, data);
      handleCloseModal();

      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (_) {
      enqueueSnackbar('Failed to update milestone', { variant: 'error' });
    }
  };

  const isExistingMilestoneEdited = typeof selectedMilestoneIndex === 'number';

  const getInitialValues = () => {
    const editedMilestone = isExistingMilestoneEdited && milestones[selectedMilestoneIndex];

    if (!editedMilestone) {
      return null;
    }

    const { name, ...rest } = editedMilestone;

    return { milestoneName: name, ...rest };
  };

  return (
    <fieldset className={className}>
      <Typography component="legend" sx={{ mb: 1 }} variant="body1">
        Add milestones
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }} css={classes.description}>
        Provide some key milestones (eg: review and completion dates) for this RFI
      </Typography>

      {!!fields.length && (
        <SavedMilestones
          css={classes.list}
          onEdit={handleOpenEditMilestone}
          milestones={milestones}
        />
      )}
      <Button onClick={() => setModal(true)} type="button">
        Add milestone
      </Button>
      {submitFailed && <FormHelperText sx={{ mt: 1 }} error>{error}</FormHelperText>}

      <ManageMilestoneForm
        initialValues={getInitialValues()}
        isOpen={isModalOpen}
        onSave={isExistingMilestoneEdited ? handleUpdateMilestone : handleAddMilestone}
        onClose={handleCloseModal}
      />
    </fieldset>
  );
};

ChallengeMilestonesFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
  onSaveChallengeDraft: PropTypes.func.isRequired,
};

ChallengeMilestonesFieldArray.defaultProps = {
  className: null,
};

export default ChallengeMilestonesFieldArray;
