import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes, fieldArrayMetaPropTypes } from 'redux-form';
import { useSnackbar } from 'notistack';
import differenceBy from 'lodash/differenceBy';
import companyAPI from 'api/company';
import integrationAPI from 'api/integration';
import CompanyPropertyAutocomplete from 'components/Shared/CompanyPropertyAutocomplete';

const CompanyIntegrationFieldArray = ({
  className,
  fields,
  meta: { submitFailed, error },
}) => {
  const companyId = useSelector((state) => state.user.company.id);

  const { enqueueSnackbar } = useSnackbar();

  const integrations = fields.getAll();

  const handleAdd = (tag) => {
    companyAPI.addIntegrationToCompany(companyId, tag.id)
      .then(() => fields.push(tag))
      .catch(() => enqueueSnackbar('Failed to add an integration'));
  };

  const handleRemove = (filteredList) => {
    const [{ id: removedTagId }] = differenceBy(integrations, filteredList, 'id');
    const removedTagIndex = integrations.findIndex((tag) => tag.id === removedTagId);

    return companyAPI.removeIntegrationFromCompany(companyId, removedTagId)
      .then(() => fields.remove(removedTagIndex))
      .catch(() => enqueueSnackbar('Failed to remove an integration'));
  };

  return (
    <CompanyPropertyAutocomplete
      className={className}
      label="Please add any approved or certified integrations your solution has"
      name={fields.name}
      properties={integrations}
      onAdd={handleAdd}
      onRemove={handleRemove}
      error={submitFailed && error ? error : null}
      apiClient={integrationAPI}
      getOptionLabel={(option) => option.name}
    />
  );
};

CompanyIntegrationFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
};

CompanyIntegrationFieldArray.defaultProps = {
  className: null,
};

export default CompanyIntegrationFieldArray;
