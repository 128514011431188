export const EMAIL_SUPPORT = 'info@validify.co.uk';

export const CHALLENGE_EMAIL_CONTACT = 'challenge@validify.tech';

export const CONTACT_ADDRESS = '3 Manor Courtyard, Hughenden Avenue, High Wycombe, Buckinghamshire, HP13 5RE';

export const COMPANY_NAME = 'Validify Limited';

export const COMPANY_SITE_TECH = 'www.validify.tech';

export const COMPANY_SITE_UK = 'www.validify.co.uk';

export const MEDIA_PARTNER = 'retailtechnology.co.uk';

export const SALES_EMAIL_ADDRESS = 'sales@validify.co.uk';
