import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import set from 'lodash/set';
import { required } from 'lib/validation';

const shouldValidateArray = (values, key) => values[key] && values[key].length > 1;

const validate = (values) => {
  const errors = {};

  if (
    shouldValidateArray(values, 'yesNoQuestions')
    || (get(values, 'questions[0].description'))
  ) {
    values.yesNoQuestions.forEach(({ description }, index) => set(errors, `yesNoQuestions[${index}]`, {
      description: required(description),
    }));
  }

  if (
    values
    && values.yesNoQuestions
    && uniqBy(values.yesNoQuestions, 'description').length !== values.yesNoQuestions.length
  ) {
    errors.yesNoQuestions = {
      _error: 'Cannot submit questions with the same text or questions without description',
    };
  }

  if (shouldValidateArray(values, 'openEndedQuestions')) {
    errors.openEndedQuestions = values.openEndedQuestions.map(({ question }) => ({
      question: required(question),
    }));
  }

  return errors;
};

export default validate;
