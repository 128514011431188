import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm, change, propTypes } from 'redux-form';
import Typography from '@mui/material/Typography';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import challengeAPI from 'api/challenge';
import ChallengeReviewers from 'components/ChallengeReviewers';
import challengeStatus from 'constants/challengeStatus';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import focusFirstInvalidFormControl from 'utils/focusFirstInvalidFormControl';
import getErrorMessage from 'utils/getErrorMessage';
import ChallengeFormFields from './ChallengeFields';
import ChallengeUnmountSaver from './ChallengeUnmountSaver';
import ChallengeAutosave from './ChallengeAutosave';
import validate from './validation';
import asyncValidate from './asyncValidate';
import CHALLENGE_FORM_ID from './formId';

const ChallengeForm = ({
  handleSubmit,
  initialValues,
  onPublish,
  form,
}) => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { status: initialStatus, source } = initialValues;
  const isRevising = (
    initialStatus === challengeStatus.APPROVED_REVISION
    || initialStatus === challengeStatus.DRAFT_REVISION
  );

  const sanitizeYesNoQuestions = (questions) => {
    if (
      !questions
      || (questions.length === 1 && !questions[0].description)
    ) {
      return [];
    }

    const filteredQuestions = questions.filter(({ description }) => !!description);

    return uniqBy(filteredQuestions, 'description');
  };

  const sanitizeOpenEndedQuestions = (questions) => {
    if (!questions || (questions.length === 1 && !questions[0].question)) {
      return [];
    }

    return questions;
  };

  const handleUpdateFieldValue = useCallback(
    (key, value) => dispatch(change(form, key, value)),
    [dispatch, form],
  );

  const handleSubmissionError = useCallback(
    (error) => {
      const message = getErrorMessage(error, 'Failed to save RFI');
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const handleSaveYesNoQuestions = useCallback(async (challengeId, yesNoQuestions) => {
    const sanitizedYesNoQuestions = sanitizeYesNoQuestions(yesNoQuestions);
    const questionsData = sanitizedYesNoQuestions.map(({ mustHave, description }) => ({
      description,
      mustHave,
    }));

    await challengeAPI.saveYesNoQuestions(challengeId, questionsData);
  }, []);

  const handleSaveChallenge = useCallback(
    async (
      {
        yesNoQuestions,
        whitelistVendors,
        blacklistVendors,
        openEndedQuestions,
        ...rest
      },
    ) => {
      try {
        const { data } = await challengeAPI.saveChallenge({
          blacklistVendors: compact(blacklistVendors),
          whitelistVendors: compact(whitelistVendors),
          openEndedQuestions: sanitizeOpenEndedQuestions(openEndedQuestions),
          ...omit(rest, 'milestones'),
        });

        handleUpdateFieldValue('id', data.id);
        handleUpdateFieldValue('status', data.status);

        if (yesNoQuestions) {
          await handleSaveYesNoQuestions(data.id, yesNoQuestions);
        }

        return data;
      } catch (error) {
        handleSubmissionError(error);

        // TODO: handle it for reviewers
        return null;
      }
    }, [handleUpdateFieldValue, handleSaveYesNoQuestions, handleSubmissionError],
  );

  const handlePublish = useCallback(
    (values) => {
      const status = isRevising ? challengeStatus.SUBMITTED_REVISION : challengeStatus.SUBMITTED;

      return handleSaveChallenge({ ...values, status }).then(onPublish);
    },
    [handleSaveChallenge, isRevising, onPublish],
  );

  const getDraftChallengeStatus = useCallback(() => {
    if (source === DEFAULT_CHALLENGE_SOURCE) {
      return isRevising ? challengeStatus.DRAFT_REVISION : challengeStatus.DRAFT;
    }

    return challengeStatus.DRAFT;
  }, [isRevising, source]);

  const handleSaveDraft = useCallback(
    (values) => {
      const status = getDraftChallengeStatus();

      return handleSaveChallenge({ ...values, status });
    },
    [handleSaveChallenge, getDraftChallengeStatus],
  );

  const handleSaveOnUnmount = useCallback(
    (values) => (
      handleSaveDraft(values)
        .then(() => enqueueSnackbar('Successfully saved', { variant: 'success' }))
    ),
    [enqueueSnackbar, handleSaveDraft],
  );

  const isNewChallenge = !initialValues.id;

  return (
    <>
      <Typography sx={{ mb: 5 }} variant="h1">
        {isNewChallenge ? 'New RFI' : 'Edit saved RFI'}
      </Typography>
      {isNewChallenge && (
        <Typography sx={{ mb: 3 }} variant="h3">
          Please detail your business RFI:
        </Typography>
      )}
      <ChallengeFormFields
        onSubmit={handleSubmit(handlePublish)}
        isCreatedFromEvent={initialValues.isCreatedFromEvent}
        onSaveChallengeDraft={handleSaveDraft}
      >
        {!initialValues.isCreatedFromEvent && (
          <ChallengeReviewers onSaveChallengeDraft={handleSaveDraft} />
        )}
      </ChallengeFormFields>
      <ChallengeUnmountSaver
        form={form}
        onSave={handleSaveOnUnmount}
        isEditable={(values) => (
          values.status !== challengeStatus.SUBMITTED
          && values.status !== challengeStatus.SUBMITTED_REVISION
        )}
      />
      <ChallengeAutosave onSave={handleSaveDraft} />
    </>
  );
};

ChallengeForm.propTypes = {
  onPublish: PropTypes.func.isRequired,
  ...propTypes,
};

export default reduxForm({
  form: CHALLENGE_FORM_ID,
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
  onSubmitFail: focusFirstInvalidFormControl,
})(ChallengeForm);
