/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayMetaPropTypes, fieldArrayFieldsPropTypes } from 'redux-form';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormHelperText from '@mui/material/FormHelperText';
import Clear from '@mui/icons-material/Clear';
import { required } from 'lib/validation';
import TextFieldInput from 'components/UI/TextFieldInput';
import * as classes from './styles';

const getFieldValidators = (validators = [], minItems, fields) => {
  if (!minItems && (fields && fields.length === 1 && !fields.get(1))) {
    return [];
  }

  if (minItems > 1 && fields && fields.length === 1 && !!fields.get(1)) {
    return validators;
  }

  return [required, ...validators];
};

const FieldList = ({
  className,
  fields,
  meta: { error, submitFailed, touched },
  maxItems,
  minItems,
  fieldValidators,
  buttonText,
}) => (
  <div className={className}>
    {(touched || submitFailed) && error && <FormHelperText error>{error}</FormHelperText>}

    <ul>
      {fields.map((name, i) => (
        <li key={name} css={classes.entry}>
          <Field
            css={classes.input}
            name={name}
            component={TextFieldInput}
            validate={getFieldValidators(fieldValidators, minItems, fields)}
          />
          <Fab sx={{ ml: 1 }} size="small" onClick={() => fields.remove(i)}>
            <Clear fontSize="small" />
          </Fab>
        </li>
      ))}
    </ul>
    {(typeof maxItems !== 'number' || fields.length < maxItems) && (
      <Button onClick={() => fields.push('')} sx={{ mt: 2 }}>
        {buttonText}
      </Button>
    )}
  </div>
);

FieldList.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
  maxItems: PropTypes.number,
  minItems: PropTypes.number,
  fieldValidators: PropTypes.arrayOf(PropTypes.func.isRequired),
  buttonText: PropTypes.string,
};

FieldList.defaultProps = {
  className: null,
  fieldValidators: [],
  maxItems: null,
  minItems: 0,
  buttonText: 'Add',
};

export default FieldList;
