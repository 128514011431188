import { css } from '@emotion/react';

const radioWrap = (theme) => (
  css`
    margin: 0 ${theme.spacing(1)} 0 0;
    display: flex;
    align-items: center;
  `
);

export default radioWrap;
