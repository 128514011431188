/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import { StarRegular } from '@fluentui/react-icons';
import * as classes from './styles';

const ICON_SIZE = 72;

const ReviewDashboardFallback = () => (
  <div css={classes.fallback}>
    <div css={classes.iconWrap}>
      <StarRegular fontSize={ICON_SIZE} />
    </div>
    <Typography css={classes.subtitle} variant="subtitle2">There aren&apos;t any ratings</Typography>
  </div>
);

export default ReviewDashboardFallback;
