/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  propTypes,
} from 'redux-form';
import { FormLabel, Typography } from '@mui/material';

import { maxLength100, maxLength15 } from 'lib/validation';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import SubmitButton from 'components/UI/SubmitButton';
import TextFieldInput from 'components/UI/TextFieldInput';
import DropDownInput from 'components/UI/DropDownInput';
import RadioButtonYesNo from 'components/UI/RadioButtonYesNo';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import CompanyIntegrationFieldArray from 'components/VendorDataCaptureForm/CompanyIntegrationFieldArray/CompanyIntegrationFieldArray';
import CloudHostingField from 'components/Shared/CloudHostingField';
import documentationType from 'constants/documentationType';
import { ProductServiceType } from 'types/Vendor';
import CompanyDocumentation from '../../CompanyDocumentation';
import validate from './technicalDetails.formValidation';
import trialArray, { TRIAL_TERMS } from './trialsArray';
import FEATURES from './constants';
import SupportFields from './SupportFields';
import * as classes from './styles';

const MAX_UPLOAD_TECHNICAL_DOCUMENTATION = 5;

class TechnicalDetails extends Component {
  saveCompanyData = (isDraft) => ({
    support,
    cloudHosting,
    integrationLabels,
    ...productServiceFields
  }) => {
    const { onUpdateProfile, productService } = this.props;

    const body = {
      cloudHosting,
      integrationLabels,
      vendors: { support, productService: { ...productService, ...productServiceFields } },
    };

    return onUpdateProfile(body, isDraft);
  };

  render() {
    const {
      handleSubmit,
      purchase,
      dirty,
      valid,
      submitSucceeded,
      submitting,
    } = this.props;

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Technical Overview
          </Typography>
          <CloudHostingField css={classes.field} />
          <Field
            label="Please provide a brief technical overview of your solution"
            placeholder="e.g. Combining enterprise functionality, an open architecture and app ecosystem, and market-leading performance, our solution enables businesses to grow online sales with less cost, time and complexity than on-premise software. (100 words maximum)"
            name="techoverview"
            multiline
            rows="6"
            validate={[maxLength100]}
            component={TextFieldInput}
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Support
          </Typography>
          <FieldArray
            css={classes.field}
            name="support.feature"
            component={SupportFields}
          />

          <FormLabel component="fieldset">
            <legend>
              What is the minimum trial period recommended for consumer brands to measure their
              success?
            </legend>
            <div css={classes.input}>
              <div css={classes.trials}>
                <div css={classes.trialsSelect}>
                  <Field name="trial.terms" component={DropDownInput} fullWidth>
                    {trialArray.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </div>
                <div css={classes.trialsSelect}>
                  <Field name="trial.type_terms" component={DropDownInput} fullWidth>
                    {TRIAL_TERMS.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          </FormLabel>
        </div>

        <div css={classes.section}>
          <Field
            css={purchase === yesNoOptions.YES && classes.field}
            title="Does the client need to purchase additional software or hardware to implement your solution?"
            name="cost.license"
            component={RadioButtonYesNo}
          />
          {purchase === yesNoOptions.YES && (
            <Field
              placeholder="Please provide details on the additional software or hardware required."
              name="support.details"
              multiline
              rows="6"
              component={TextFieldInput}
            />
          )}
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            End-user training
          </Typography>
          <Field
            name="hasEndUserTraining"
            component={RadioButtonYesNo}
            title="Is end-user training available?"
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Technical documentation
          </Typography>
          <CompanyDocumentation
            descriptionPlaceholder="Description (15 words maximum)"
            descriptionValidator={maxLength15}
            maxFiles={MAX_UPLOAD_TECHNICAL_DOCUMENTATION}
            title="You can upload up to five integration documents (optional)"
            type={documentationType.TECHNICAL}
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Data security
          </Typography>
          <Field
            label="Your data security strategy"
            placeholder=" e.g. We are hosted in Google Cloud Data Centers, located in their central data center region in the US. For production traffic, we utilize this region as our primary data storage location, with our infrastructure replicated across 4 availability zones across this region. Each client is protected by multiple layers of security to prevent unauthorised access, including perimeter and server-specific firewalls, file integrity scanners, intrusion detection software, web application firewalls and 24/7 monitoring. We also hold anumber of formal accreditations including PCI DSS and ISO 27001. (100 words maximum)"
            name="dataSecurity"
            multiline
            rows="6"
            validate={maxLength100}
            component={TextFieldInput}
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Data privacy compliance
          </Typography>
          <Field
            label="Your compliance strategy"
            placeholder="e.g. We protect personal integrity and always strive for a high level of data protection under the EU General Data Protection Regulation or to companies that are certified under EU/US Privacy Shield Framework or other framework compliant with GDPR. (100 words maximum)"
            name="dataPrivacyCompliance"
            multiline
            rows="6"
            validate={maxLength100}
            component={TextFieldInput}
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Integration and data requirements
          </Typography>
          <Field
            label="Your client integration process"
            placeholder="e.g. We are an enterprise software vendor and as such we have multiple integration and connectivity options via custom integration methods and we also provide a rich, fully documented set of APIs that allow 3rd parties to integrate their technology with approximately 10 man days of IT effort and have the solution live in around 4 weeks. API documentation is readily available. (100 words maximum)"
            name="integrationDataRequirement"
            multiline
            rows="6"
            validate={maxLength100}
            component={TextFieldInput}
          />
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Pre-existing integrations
          </Typography>
          <FieldArray name="integrationLabels" component={CompanyIntegrationFieldArray} />
        </div>
        <SubmitButton
          isLoading={submitting}
          onClick={handleSubmit(this.saveCompanyData(false))}
        >
          Save
        </SubmitButton>
      </DataCaptureFormContainer>
    );
  }
}

TechnicalDetails.propTypes = {
  purchase: PropTypes.string,
  onUpdateProfile: PropTypes.func.isRequired,
  productService: ProductServiceType.isRequired,
  ...propTypes,
};

const selector = formValueSelector('technicalDetailsForm');

const mapStateToProps = (state) => {
  const support = { ...state.user.company.vendors.support };
  const isTypes = support.feature;

  if (isEmpty(isTypes)) {
    support.feature = FEATURES.map((item) => ({
      name: item,
      checked: !!(isTypes && isTypes.includes(item)),
    }));
  }

  const { cloudHosting, integrationLabels, vendors: { productService = {} } } = state.user.company;
  const {
    dataPrivacyCompliance,
    dataSecurity,
    hasEndUserTraining,
    integrationDataRequirement,
    cost,
    trial,
    techoverview,
  } = productService;

  const purchase = selector(state, 'cost.license');

  return {
    purchase,
    productService,
    initialValues: {
      cloudHosting,
      support,
      dataPrivacyCompliance,
      dataSecurity,
      hasEndUserTraining,
      integrationDataRequirement,
      cost,
      trial,
      techoverview,
      integrationLabels,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    enableReinitialize: true,
    form: 'technicalDetailsForm',
    validate,
  })(TechnicalDetails),
);
