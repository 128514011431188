import { generatePath } from 'react-router-dom';
import { isAcceptingResponses } from 'utils/challengeCheckers';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import challengeStatus from 'constants/challengeStatus';
import routes from 'constants/routes';
import { isExpired } from './challengeCheckers';

const getRedirectUrlForVendorChallenge = (
  challenge,
  challengeResponse,
  isShortlisted,
  challengeOwnerCompany,
) => {
  const { slug: challengeSlug, id } = challenge;
  const { slug: companySlug } = challengeOwnerCompany;

  if (challenge.status === challengeStatus.PAUSED && !(
    challengeResponse
    && (
      challengeResponse.status === challengeResponseStatus.REJECTED
      || challengeResponse.status === challengeResponseStatus.SUBMITTED
    )
  )) {
    return generatePath(
      routes.PUBLIC_CHALLENGE_INFO,
      {
        rfiSlug: challengeSlug,
        retailerSlug: companySlug,
      },
    );
  }

  if (challenge.status === challengeStatus.PAUSED && !!(
    challengeResponse
    && (
      challengeResponse.status === challengeResponseStatus.REJECTED
      || challengeResponse.status === challengeResponseStatus.SUBMITTED
    )
  )) {
    return generatePath(
      routes.PUBLIC_CHALLENGE_INFO,
      {
        rfiSlug: challengeSlug,
        retailerSlug: companySlug,
      },
    );
  }

  if (
    !isShortlisted
    || ((isExpired(challenge) || !isAcceptingResponses(challenge))
      && !challengeResponse)
  ) {
    return generatePath(
      routes.PUBLIC_CHALLENGE_INFO,
      {
        rfiSlug: challengeSlug,
        retailerSlug: companySlug,
      },
    );
  }

  if (
    isShortlisted
    && challengeResponse
    && (
      challengeResponse.status === challengeResponseStatus.REJECTED
      || challengeResponse.status === challengeResponseStatus.SUBMITTED)
  ) {
    return generatePath(routes.CHALLENGE_RESPONSE_FOR_VENDOR, { challengeId: id });
  }

  if (
    isShortlisted
    && !isExpired(challenge)
    && isAcceptingResponses(challenge)
    && (
      !challengeResponse
      || challengeResponse.status === challengeResponseStatus.ACCEPTED
      || challengeResponse.status === challengeResponseStatus.DRAFT
      || challengeResponse.status === challengeResponseStatus.UNDER_REVISION
    )
  ) {
    return generatePath(routes.CHALLENGE_RESPONSE_FORM, { challengeId: id });
  }

  return null;
};

export default getRedirectUrlForVendorChallenge;
