import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import YesNoAnswer from 'components/UI/YesNoAnswer';
import './YesNoAnswerRow.scss';

const YES_NO_ANSWER_ICON_SIZE = 24;

const YesNoAnswerRow = ({
  isHighlighted,
  title,
  values,
  questionIndex,
}) => (
  <tr key={title}>
    <th className={clsx('yes-no-question-text', isHighlighted && 'highlighted')}>
      <Typography variant="body1">{`Q${questionIndex + 1}. ${title}`}</Typography>
    </th>

    {values.map((value, index) => (
      <td key={index} className={clsx('yes-no-answer-cell', isHighlighted && 'highlighted')}>
        {typeof value === 'boolean' && (
          <YesNoAnswer
            className="yes-no-answer-cell__content"
            isSelected={value}
            size={YES_NO_ANSWER_ICON_SIZE}
          />
        )}
      </td>
    ))}
  </tr>
);

YesNoAnswerRow.propTypes = {
  isHighlighted: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.bool).isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default YesNoAnswerRow;
