import PropTypes from 'prop-types';

const TagType = PropTypes.shape({
  createdAt: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
});

export default TagType;
