import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import './EmptyReview.scss';

const EmptyReview = ({ className, buttonProps: { onClick, text: buttonText }, text }) => (
  <div className={clsx('empty-review', className)}>
    <Typography className="empty-review__text" variant="body1">
      {text}
    </Typography>
    <Button onClick={onClick}>{buttonText}</Button>
  </div>
);

EmptyReview.propTypes = {
  className: PropTypes.string,
  buttonProps: PropTypes.exact({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

EmptyReview.defaultProps = {
  className: null,
};

export default EmptyReview;
