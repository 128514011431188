/** @jsxImportSource @emotion/react */
import SolutionsSearchForm from 'components/SolutionsSearch/SolutionsSearchForm';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import LoginForm from '../LoginForm';
import contentStyles from './styles';

const Login = () => (
  <PublicPageContentWrapper css={contentStyles}>
    <LoginForm />
    <SolutionsSearchForm />
  </PublicPageContentWrapper>
);

export default Login;
