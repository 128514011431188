import { css } from '@emotion/react';

const form = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(4)};
    }
  `
);

export default form;
