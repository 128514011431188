import { css } from '@emotion/react';

export const entry = (theme) => css`
  display: flex;
  gap: ${theme.spacing(2)};
`;

export const position = (theme) => css`
  width: 12px;
  align-self: stretch;
  padding-top: ${theme.spacing(2)};
`;

export const collapseHeader = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `
);

export const card = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${theme.spacing(2)};
    box-shadow: ${theme.boxShadows.regular};
    border-radius: 4px;
  `
);

export const button = (theme) => css`
  align-self: flex-start;
  margin: -${theme.spacing(1)} 0;
`;

export const chevron = (theme) => (
  css`
    color: ${theme.palette.primary.main};
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms;
  `
);

export const rotatedChevron = css`
  transform: rotate(180deg);
`;

export const contentWrapper = (theme) => (
  css`
    padding-top: ${theme.spacing(1)};
  `
);
