const MAX_UPLOAD_SIZE = 20971520;
const MAX_IMAGE_SIZE = 2097152;

export const documentUploadConfig = {
  accept: '.pdf',
  maxSize: MAX_UPLOAD_SIZE,
  restrictionInfo: 'Allowed file types: PDF. Max file size: 20 MB',
};

export const avatarUploadConfig = {
  maxSize: MAX_IMAGE_SIZE,
  restrictionInfo: 'Allowed file types: .png, .svg. Max file size: 2 MB',
  accept: '.svg, .png',
};

export const imageUploadConfig = {
  maxSize: MAX_IMAGE_SIZE,
  restrictionInfo: 'Allowed file types: png, jpg. Max file size: 2 MB',
  accept: '.jpg, .png',
};

export const challengeCaseStudyConfig = {
  maxSize: MAX_UPLOAD_SIZE,
  restrictionInfo: 'Allowed PDF, Word and PowerPoint documents. Max file size: 20MB',
  accept: '.pdf, .doc, .docx, .pptx, .ppt',
};

const MAX_CHALLENGE_RESPONSE_UPLOAD = 5 * 1024 * 1024;

export const challengeResponseAttachmentConfig = {
  maxSize: MAX_CHALLENGE_RESPONSE_UPLOAD,
  restrictionInfo: 'Allowed PDF documents. Max file size: 5MB',
  accept: '.pdf',
};

export default MAX_UPLOAD_SIZE;
