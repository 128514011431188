import { css } from '@emotion/react';

export const defaultTopic = (theme) => css`
  padding: ${theme.spacing(2)} 0;
  margin: -${theme.spacing(2)} 0;
  width: 100%;
  cursor: pointer;
`;

export const emptyTopic = (theme) => css`
  color: ${theme.primaryPalette.menuGrey};
`;

export const collapse = css`
  width: 100%;
`;

export const note = (theme) => css`
  padding-bottom: ${theme.spacing(3)};
`;

export const head = (theme) => css`
  box-sizing: border-box;
  padding: ${theme.spacing(2)} 0;
`;

export const savedData = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const iconsWrap = (theme) => css`
  position: relative;
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
  margin: -${theme.spacing(1)} -${theme.spacing(1)} -${theme.spacing(1)} 0;
`;

export const form = (theme) => (
  css`
    padding-bottom: ${theme.spacing(1)}
  `
);
