import { css } from '@emotion/react';

export const burgerIcon = css`
  padding: 13px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const burgerIconBar = (theme) => (
  css`
    width: ${theme.spacing(2.5)};
    height: 2px;
    background-color: currentColor;
    border-radius: 3px;
    transition: 0.4s all;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  `
);

export const burgerIconBarOpen = css`
  &:first-of-type {
    transform: rotate(-45deg) translate(-5.25px, 5.5px);
  }

  &:nth-of-type(2) {
    opacity: 0;
  }

  &:last-of-type {
    transform: rotate(45deg) translate(-4.75px, -4.25px);
  }
`;
