/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import IconClose from '@mui/icons-material/Close';
import button from './styles';

const ICON_SIZE = '10px';

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton css={button} size="small" onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose fontSize={ICON_SIZE} />
    </IconButton>
  );
};

SnackbarCloseButton.propTypes = {
  snackbarKey: PropTypes.number.isRequired,
};

export default SnackbarCloseButton;
