import PropTypes from 'prop-types';
import recommendSolutionOptions from 'constants/review';
import ReviewRelevanceVote from './ReviewRelevanceVote';

export const RatingType = PropTypes.shape({
  field: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const ReviewType = PropTypes.shape({
  pros: PropTypes.string,
  cons: PropTypes.string,
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  recommendSolution: PropTypes.oneOf(Object.values(recommendSolutionOptions)).isRequired,
  ratings: PropTypes.arrayOf(RatingType.isRequired),
  voteReviews: PropTypes.arrayOf(ReviewRelevanceVote.isRequired).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  company: PropTypes.shape({
    logo: PropTypes.string,
  }),
});

export default ReviewType;
