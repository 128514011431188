/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import userAPI from 'api/user';
import { UIContext } from 'containers/UIProvider';
import TextFieldInput from 'components/Form/TextFieldInput';
import SubmitButton from 'components/UI/SubmitButton';
import composeValidators from 'utils/composeValidators';
import getErrorMessage from 'utils/getErrorMessage';
import { required, validateEmail, validatePasswordLength } from 'lib/validation';
import routes from 'constants/routes';
import { authCookies } from 'constants/storageKeys';
import DEFAULT_COOKIE_EXPIRATION_DAYS from 'constants/defaultCookieExpiration';
import httpResponseCodes from 'constants/httpResponseCodes';
import { EMAIL_SUPPORT } from 'constants/contacts';
import VerifyEmailModal from '../VerifyEmailModal';
import * as classes from './styles';

const errorCodes = {
  EMAIL_NOT_VERIFIED: 'LOGIN_FAILED_EMAIL_NOT_VERIFIED',
  DEACTIVATED_ACCOUNT: 'deactivatedAccount',
};

const LoginForm = ({ className }) => {
  const { onSetUserLoaded } = useContext(UIContext);

  const location = useLocation();
  const history = useHistory();

  const [isVerifyEmailModalOpen, setVerifyEmailModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleLoginError = (error) => {
    const statusCode = get(error, 'response.status');

    if (statusCode === httpResponseCodes.UNAUTHORIZED) {
      const errorCode = get(error, 'response.data.error.code');

      if (errorCode === errorCodes.EMAIL_NOT_VERIFIED) {
        setVerifyEmailModal(true);
      } else if (errorCode === errorCodes.DEACTIVATED_ACCOUNT) {
        const message = `Your account was deactivated by Admin.
        If you feel this is in error please contact ${EMAIL_SUPPORT}`;
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Wrong email/password', { variant: 'error' });
      }
    } else {
      const errorMessage = getErrorMessage(error, 'Unknown error. Please try again');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const handleRedirectAuthorizedUser = () => {
    const queryParams = new URLSearchParams(location.search);
    const backPath = queryParams.get('backPath');

    const redirectPath = backPath || routes.MAIN;

    history.replace(redirectPath);
  };

  const handleLogin = ({ email: userEmail, password }) => (
    userAPI.loginUser({ email: userEmail.toLowerCase(), password })
      .then((res) => {
        const { userId, id } = res.data;
        Cookies.set(authCookies.USER_ID, userId, { expires: DEFAULT_COOKIE_EXPIRATION_DAYS });
        Cookies.set(authCookies.TOKEN, id, { expires: DEFAULT_COOKIE_EXPIRATION_DAYS });
        localStorage.setItem('isAuthenticated', true);

        onSetUserLoaded(false);
        handleRedirectAuthorizedUser();
      })
      .catch((e) => handleLoginError(e))
  );

  return (
    <div css={classes.blockWrap} className={className}>
      <Typography sx={{ mb: 3 }} variant="h1">Log In</Typography>
      <Typography sx={{ mb: 3 }} css={classes.subtitle} variant="h3">
        Validify Access is where consumer brands{' '}
        <span css={classes.highlightedText}>source, evaluate, procure</span> technology solutions.
      </Typography>
      <Form
        onSubmit={handleLogin}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div css={classes.section}>
              <Field
                sx={{ mb: 2 }}
                placeholder="e.g. Joe@Smith.com"
                type="email"
                name="email"
                component={TextFieldInput}
                label="Email"
                id="sign-in-email"
                validate={composeValidators(required, validateEmail)}
              />
              <Field
                placeholder="*****"
                type="password"
                name="password"
                component={TextFieldInput}
                label="Password"
                id="sign-in-password"
                validate={composeValidators(required, validatePasswordLength)}
              />
            </div>
            <div css={classes.forgotPassword}>
              <Link
                variant="body2"
                component={RouterLink}
                to={routes.FORGOT_PASSWORD}
              >
                Forgot password?
              </Link>
            </div>
            <div css={classes.buttons}>
              <div css={classes.submit}>
                <SubmitButton
                  isLoading={submitting}
                  type="submit"
                  fullWidth
                >
                  Login
                </SubmitButton>
              </div>
              <div css={classes.register}>
                <Typography variant="subtitle2" css={classes.registerLabel} sx={{ mr: 2 }}>
                  Don&apos;t have an account?
                </Typography>
                <Button
                  variant="text"
                  component={RouterLink}
                  to={routes.REGISTER}
                >
                  Register
                </Button>
              </div>
            </div>

            <VerifyEmailModal
              email={values.email}
              isOpen={isVerifyEmailModalOpen}
              onClose={() => setVerifyEmailModal(false)}
            />
          </form>
        )}
      />
    </div>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

LoginForm.defaultProps = {
  className: null,
};

export default LoginForm;
