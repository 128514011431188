import { css } from '@emotion/react';

const footer = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `
);

export default footer;
