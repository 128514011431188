/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes } from 'redux-form';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormLabel from '@mui/material/FormLabel';
import Clear from '@mui/icons-material/Clear';

import TextFieldInput from 'components/UI/TextFieldInput';
import fileAPI from 'api/file';
import { required } from 'lib/validation';
import UploadedVendorDocument from 'components/UI/UploadedVendorDocument';
import getFileNameFromLink from 'utils/getFileNameFromLink';
import DocumentUploadType from 'types/DocumentUpload';
import DocumentUploadField from '../DocumentUploadField';
import * as classes from './styles';

class DocumentUpload extends Component {
  static propTypes = {
    descriptionPlaceholder: PropTypes.string,
    descriptionValidator: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(DocumentUploadType.isRequired),
    maxFiles: PropTypes.number.isRequired,
    onUpdateDocumentList: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    savedDocuments: null,
    descriptionPlaceholder: null,
  };

  state = {
    fileName: '',
  };

  handleDeleteSavedDocument = async (fileLink) => {
    const { documents, onUpdateDocumentList } = this.props;

    await this.handleRemoveFile(fileLink);

    const filteredDocuments = documents ? documents.filter(({ link }) => link !== fileLink) : [];

    onUpdateDocumentList(filteredDocuments);
  };

  handleSubmitFile = async ({ upload }) => {
    const { documents, onUpdateDocumentList, reset } = this.props;

    await onUpdateDocumentList([...documents, upload]);
    reset();
  };

  handleRemoveFile = (link) => {
    const { userId } = this.props;

    const fileName = getFileNameFromLink(link);

    return fileAPI.deleteFileFromServer(userId, fileName);
  };

  handleSaveFile = (onChange) => async (formData) => {
    const { userId } = this.props;

    const { name: fileName } = formData.get('file');
    this.setState({ fileName });
    const { body } = await fileAPI.uploadFile('vandor.documentation', 'link', formData, userId);
    onChange(body);
  };

  render() {
    const {
      handleSubmit,
      maxFiles,
      descriptionPlaceholder,
      descriptionValidator,
      documents,
      title,
    } = this.props;
    const { fileName } = this.state;

    return (
      <div>
        {!!documents && !!documents.length && (
          <div css={classes.formGroup}>
            <FormLabel sx={{ mb: 1 }} component="div">Saved uploads</FormLabel>
            {documents.map((item) => (
              <div css={classes.savedUpload} key={item.link}>
                <UploadedVendorDocument css={classes.fileInfo} document={item} />
                <Fab size="small" onClick={() => this.handleDeleteSavedDocument(item.link)}>
                  <Clear fontSize="small" />
                </Fab>
              </div>
            ))}
          </div>
        )}

        {(!documents || documents.length < maxFiles) && (
          <>
            <fieldset css={classes.fieldset}>
              <FormLabel sx={{ mb: 1 }} component="legend">{title}</FormLabel>
              <Field
                css={classes.formGroup}
                name="upload.link"
                validate={[required]}
                onSaveFile={this.handleSaveFile}
                onRemoveFile={this.handleRemoveFile}
                component={DocumentUploadField}
                fileName={fileName}
              />
              <Field
                sx={{ mb: 2 }}
                validate={[required]}
                component={TextFieldInput}
                name="upload.title"
                placeholder="Title"
              />
              <Field
                sx={{ mb: 2 }}
                validate={[required, descriptionValidator]}
                component={TextFieldInput}
                name="upload.text"
                placeholder={descriptionPlaceholder}
              />
              <Field
                validate={[required]}
                component={TextFieldInput}
                name="upload.date"
                placeholder="dd/mm/yyyy"
              />
            </fieldset>
            <Button onClick={handleSubmit(this.handleSubmitFile)}>
              Save File
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  userId: state.user.authUser.id,
}))(reduxForm({ form: 'documentForm', enableReinitialize: true })(DocumentUpload));
