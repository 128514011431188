/** @jsxImportSource @emotion/react */
import { Field } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/UI/TextFieldInput';
import * as classes from './styles';

const MAX_AWARDS = 3;

const AwardsBlock = () => (
  <div>
    <Typography sx={{ mb: 3 }} variant="h2">Awards</Typography>
    <div>
      <Hidden smDown>
        <div css={classes.award}>
          <FormLabel css={classes.awardName} component="h3">
            Please provide any awards you have received
          </FormLabel>
          <FormLabel css={classes.year} component="h3">
            Year
          </FormLabel>
        </div>
      </Hidden>

      {Array.from({ length: MAX_AWARDS }, (_, i) => i).map((i) => (
        <div key={i} css={classes.award}>
          <div css={classes.awardName}>
            <Field
              component={TextFieldInput}
              name={`awards[${i}].name`}
              placeholder="Award Name..."
            />
          </div>
          <div css={classes.year}>
            <Field
              name={`awards[${i}].year`}
              component={TextFieldInput}
              placeholder="0000"
            />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default AwardsBlock;
