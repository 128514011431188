import { useContext } from 'react';
import { generatePath, Redirect } from 'react-router-dom';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import routes from 'constants/routes';
import challengeStatus from 'constants/challengeStatus';
import ChallengeResponsesDashboardContent from './ChallengeResponsesDashboardContent';

const ChallengeResponsesDashboard = () => {
  const { state: { challenge } } = useContext(ChallengeContext);

  return (
    challenge.status === challengeStatus.DRAFT ? (
      <Redirect to={generatePath(routes.EDIT_SAVED_CHALLENGE, { id: challenge.id })} />
    ) : (
      <ChallengeResponsesDashboardContent />
    )
  );
};

export default ChallengeResponsesDashboard;
