import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FetchContainer from 'containers/FetchContainer';
import withPageTitle from 'containers/withPageTitle';
import withRouteGuards from 'containers/withRouteGuards';
import EditEventTopicPriorities from 'components/EditEventTopicPriorities';
import eventAPI from 'api/event';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import eventTopicPriorityAPI from 'api/eventTopicPriorityList';
import eventRetailerUserAPI from 'api/eventRetailUser';
import pageTitles from 'constants/pageTitles';
import eventTopicAPI from '../api/eventTopic';

const EditEventTopicPrioritiesPage = (props) => {
  const { slug } = useParams();
  const userId = useSelector((state) => state.user.authUser.id);

  const getInitialProps = useCallback(async () => {
    const { data: event } = await eventAPI.getEventBySlug(slug);
    const isEventAttendee = await eventRetailerUserAPI.isUserEventAttendee(userId, event.id);

    if (!isEventAttendee) {
      return { canLoadPage: false };
    }

    const topics = await eventTopicAPI.getEventTopics(event.id);
    const priorityList = await eventTopicPriorityAPI.getPrioritizedTopicsForEvent(userId, event.id);

    if (priorityList) {
      const { topics: orderedTopics, notes, ...priorities } = priorityList;

      return {
        event,
        topics,
        priorityList: priorities,
        orderedTopics,
        notes,
      };
    }

    return { event, topics };
  }, [userId, slug]);

  return (
    <FetchContainer
      component={EditEventTopicPriorities}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  withPageTitle(pageTitles.EVENT_RETAILER_BUSINESS_PRIORITIES)(EditEventTopicPrioritiesPage),
);
