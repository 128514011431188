/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'constants/routes';
import { required, validateEmail } from 'lib/validation';
import TextFieldInput from 'components/Form/TextFieldInput';
import SubmitButton from 'components/UI/SubmitButton';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import userAPI from 'api/user';
import composeValidators from 'utils/composeValidators';
import { EMAIL_SUPPORT } from 'constants/contacts';
import * as classes from './styles';

const ForgotPasswordForm = () => {
  const [isRequestSucceeded, setSuccess] = useState(false);
  const [isRequestFailed, setError] = useState(false);

  const isRequestSent = isRequestSucceeded || isRequestFailed;

  const handleResetPassword = async ({ email }) => {
    try {
      const formattedEmail = email.toLowerCase();

      await userAPI.resetUserPassword(formattedEmail);

      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <PublicPageContentWrapper css={classes.form}>
      <Typography sx={{ mb: 3 }} variant="h1">
        Password reset
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Enter the email that you used when register to recover your password. You will
        receive a password reset link.
      </Typography>

      {isRequestSucceeded && (
        <>
          <Typography sx={{ mb: 2 }} variant="body1">
            If we have a record of your account we are now sending you an email allowing you
            to reset your password.
          </Typography>
          <Typography sx={{ mb: 4 }} variant="body1">
            If you do not receive that email in the next 30 mins please contact support:{' '}
            <Link href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</Link>
          </Typography>
          <Button component={RouterLink} to={routes.LOGIN}>Login</Button>
        </>
      )}
      {isRequestFailed && (
        <>
          <Typography variant="body1">
            Sorry, we cannot reset your password at this time. Please contact support for
            assistance <Link href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</Link>
          </Typography>
        </>
      )}
      {!isRequestSent && (
        <Form
          onSubmit={handleResetPassword}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                sx={{ mb: 5 }}
                label="Email address"
                type="email"
                name="email"
                placeholder="Email"
                component={TextFieldInput}
                validate={composeValidators(required, validateEmail)}
              />
              <div css={classes.footer}>
                <SubmitButton isLoading={submitting} type="submit">Send request</SubmitButton>
                <Button variant="outlined" component={RouterLink} to={routes.LOGIN}>Login</Button>
              </div>
            </form>
          )}
        />
      )}
    </PublicPageContentWrapper>
  );
};

export default ForgotPasswordForm;
