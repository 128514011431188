/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ArrowSort16Regular } from '@fluentui/react-icons';
import buttonStyles from './styles';

const SortButton = ({ className, onClick, label }) => (
  <Typography
    css={buttonStyles}
    className={className}
    onClick={onClick}
    variant="caption1"
  >
    {label} <ArrowSort16Regular />
  </Typography>
);

SortButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

SortButton.defaultProps = {
  className: null,
};

export default SortButton;
