/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { generatePath, Redirect, Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import PageContainer from 'containers/PageContainer';
import ResponseDetails from 'components/ChallengeResponse/ResponseDetails';
import ChallengeRequirementsModal from 'components/Challenge/ChallengeRequirementsModal';
import CompanyRegistrationBanner from 'components/Shared/CompanyRegistrationBanner';
import challengeResponseAPI from 'api/challengeResponse';
import { isExpired, isAcceptingResponses } from 'utils/challengeCheckers';
import challengeStatus from 'constants/challengeStatus';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import routes from 'constants/routes';
import ChallengeType from 'types/Challenge';
import LocationType from 'types/Location';
import { ChallengeResponseType } from 'types/ChallengeResponse';
import * as classes from './styles';

const breadcrumbs = [
  { to: routes.MAIN, name: 'Home' },
  { to: routes.VENDOR_CHALLENGE_DASHBOARD, name: 'RFIs' },
];

const ChallengeResponseForVendor = ({
  error,
  challenge,
  challengeResponse,
  enqueueSnackbar,
  setPageProps,
  location,
}) => {
  const handleRequestChallengeParticipation = () => (
    challengeResponseAPI.requestChallengeParticipation(challenge.id)
      .then(() => {
        const updatedResponse = { ...challengeResponse, isParticipationRequestSent: true };

        setPageProps({ challengeResponse: updatedResponse });
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to send request', { variant: 'error' }))
  );

  const handleRequestRevision = () => (
    challengeResponseAPI.requestResponseEdit(challenge.id)
      .then(() => enqueueSnackbar('Your request sent successfully', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Failed to request edition', { variant: 'error' }))
  );

  if (!error && challengeResponse.status === challengeResponseStatus.REMOVED_FROM_SHORTLIST) {
    const { slug: rfiSlug, company: { slug: retailerSlug } } = challenge;
    const path = generatePath(routes.PUBLIC_CHALLENGE_INFO, { rfiSlug, retailerSlug });

    return <Redirect to={path} />;
  }

  if (!error && !(
    challengeResponse.status === challengeResponseStatus.REJECTED
    || challengeResponse.status === challengeResponseStatus.SUBMITTED
    || (
      challengeResponse.status === challengeResponseStatus.UNDER_REVISION
      && isExpired(challenge)
    )
  )) {
    const path = generatePath(routes.CHALLENGE_RESPONSE_FORM, { challengeId: challenge.id });

    return <Redirect to={path} />;
  }

  return (
    <PageContainer
      error={error}
      renderChildren={() => (
        <>
          <Breadcrumbs sx={{ mb: 5 }}>
            {breadcrumbs.map(({ to, name }) => (
              <Link key={to} to={to}>
                {name}
              </Link>
            ))}
            <Link to={location.pathname}>{`Response on ${challenge.name}` || 'Unknown'}</Link>
          </Breadcrumbs>

          {/* TODO: refactor it */}
          {challenge.status === challengeStatus.CANCELLED && (
            <CompanyRegistrationBanner
              css={classes.banner}
              title="Revision of the response is disabled"
              additionalInfo="The RFI was cancelled by owner. We are not accepting any updates."
            />
          )}

          {challenge.status === challengeStatus.PAUSED && (
            <CompanyRegistrationBanner
              css={classes.banner}
              title="Revision of the response is disabled"
              additionalInfo="The RFI has been placed on hold. We are not accepting any updates at this time."
            />
          )}

          {challenge.status === challengeStatus.COMPLETED && (
            <CompanyRegistrationBanner
              css={classes.banner}
              title="Revision of the response is disabled"
              additionalInfo="The RFI was closed by owner. We are not accepting any updates."
            />
          )}

          {challengeResponse.status === challengeResponseStatus.REJECTED && (
            <>
              <div css={classes.actions}>
                <ChallengeRequirementsModal challenge={challenge} isNoteForResponderShown={false} />
                <Button
                  disabled={(
                    challengeResponse.isParticipationRequestSent
                    || !isAcceptingResponses(challenge)
                    || isExpired(challenge)
                  )}
                  onClick={handleRequestChallengeParticipation}
                  type="button"
                >
                  {challengeResponse.isParticipationRequestSent
                    ? 'Participation request sent'
                    : 'Request to participate'
                  }
                </Button>
              </div>

              <Typography sx={{ mb: 3 }} variant="h2">
                Your company rejected this RFI
              </Typography>
              {!!challengeResponse.rejectionReason && (
                <Typography variant="body1">The reason: {challengeResponse.rejectionReason}</Typography>
              )}
            </>
          )}

          {(
            challengeResponse.status === challengeResponseStatus.SUBMITTED
            || (
              isExpired(challenge)
              && challengeResponse.status === challengeResponseStatus.UNDER_REVISION
            )
          ) && (
            <>
              <div css={classes.actions}>
                <ChallengeRequirementsModal challenge={challenge} isNoteForResponderShown={false} />
                <Button
                  disabled={!isAcceptingResponses(challenge) || isExpired(challenge)}
                  onClick={handleRequestRevision}
                  type="button"
                >
                  Request revision
                </Button>
              </div>

              <ResponseDetails
                challenge={challenge}
                challengeResponse={challengeResponse}
                title={`Response on RFI '${challenge.name}'`}
              />
            </>
          )}
        </>
      )}
    />
  );
};

ChallengeResponseForVendor.propTypes = {
  error: PropTypes.string,
  location: LocationType.isRequired,
  challenge: ChallengeType,
  enqueueSnackbar: PropTypes.func.isRequired,
  setPageProps: PropTypes.func.isRequired,
  challengeResponse: ChallengeResponseType,
};

ChallengeResponseForVendor.defaultProps = {
  error: null,
  challenge: null,
  challengeResponse: null,
};

export default withSnackbar(ChallengeResponseForVendor);
