import { css } from '@emotion/react';

export const modal = (theme) => (
  css`
    .MuiDialog-paper {
      max-width: 500px;

      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(3)};
      }
    }
  `
);

export const subtitle = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(2.5)};
    }
  `
);

export const buttons = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      gap: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      gap: ${theme.spacing(2)};
    }
  `
);
