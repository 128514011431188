/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Error from '@mui/icons-material/Error';
import ReactPlayer from 'react-player/lazy';
import * as classes from './styles';

const VideoPlayer = ({ url, className }) => {
  const [isError, setError] = useState(false);

  return isError ? (
    <div css={classes.errorContainerStyles}>
      <Error css={classes.errorIcon} />
      <Typography variant="body1">We noticed a problem with the video. We cannot play this media</Typography>
    </div>
  ) : (
    <ReactPlayer
      css={classes.video}
      className={className}
      url={url}
      controls
      onError={() => setError(true)}
    />
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

VideoPlayer.defaultProps = {
  className: null,
};

export default VideoPlayer;
