import { Component } from 'react';
import PropTypes from 'prop-types';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

class ExampleComponent extends Component {
  static propTypes = {
    setCaptureToken: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line class-methods-use-this
  componentDidMount = () => {
    loadReCaptcha(process.env.REACT_APP_GOOGLE_CAPTURE_SITE_KEY);
  };

  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken) this.props.setCaptureToken(recaptchaToken);
  };

  render() {
    return (
      <>
        <ReCaptcha
          ref={(ref) => { this.recaptcha = ref; }}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTURE_SITE_KEY}
          action="action_name"
          verifyCallback={this.verifyCallback}
        />
      </>
    );
  }
}

export default ExampleComponent;
