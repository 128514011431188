import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import FetchContainer from 'containers/FetchContainer';
import withRouteGuards from 'containers/withRouteGuards';
import ChallengeForRetailer from 'components/RetailerChallenges/ChallengeForRetailer';
import challengeAPI from 'api/challenge';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const ChallengeForRetailerPage = (props) => {
  const { id } = useParams();

  const getInitialProps = useCallback(async () => {
    const challenge = await challengeAPI.getChallengeDetails(id);

    return { challenge };
  }, [id]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={ChallengeForRetailer}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGE)(ChallengeForRetailerPage)),
);
