import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import './ChallengeSynopsis.scss';

const ChallengeSynopsis = ({ className, challenge }) => {
  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');

  return (
    <div className={clsx('challenge-synopsis', className)}>
      {!!challenge.business_description && (
        <div className="challenge-synopsis__section">
          <Typography
            sx={{ mb: 1 }}
            variant="subtitle1"
            component="h4"
          >
            The business
          </Typography>
          <Typography variant="body1">{challenge.business_description}</Typography>
        </div>
      )}
      <div className="challenge-synopsis__section">
        <Typography
          sx={{ mb: 1 }}
          variant="subtitle1"
          component="h4"
        >
          The RFI
        </Typography>
        <Typography variant="body1">{challenge.challenge_description}</Typography>
      </div>
      <div className="challenge-synopsis__section">
        <Typography
          sx={{ mb: 1 }}
          variant="subtitle1"
          component="h4"
        >
          RFI objectives
        </Typography>
        <Typography variant="body1">{challenge.objectives}</Typography>
      </div>
      <div className="challenge-synopsis__section">
        <Typography
          sx={{ mb: 1 }}
          variant="subtitle1"
          component="h4"
        >
          Business and technical constraints
        </Typography>
        <Typography variant="body1">{challenge.constraints}</Typography>
      </div>
      {challenge.companyRevenue && (
        <div className="challenge-synopsis__section">
          <Typography
            sx={{ mb: 1 }}
            variant="subtitle1"
            component="h4"
          >
            About the company
          </Typography>
          <Typography variant="body1">
            Our annual revenue is: {challenge.companyRevenue}
          </Typography>
        </div>
      )}
      <div className="challenge-synopsis__section">
        <Typography variant="subtitle1" component="h4" sx={{ mb: 1 }}>
          Response deadline
        </Typography>
        <Typography variant="h3" component="p">
          {formatDate(challenge.response_deadline)}
        </Typography>
      </div>
    </div>
  );
};

ChallengeSynopsis.propTypes = {
  className: PropTypes.string,
  challenge: ChallengeSynopsisType.isRequired,
};

ChallengeSynopsis.defaultProps = {
  className: null,
};

export default ChallengeSynopsis;
