/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import routes from 'constants/routes';
import LightLogo from 'svg/lightLogo';
import insertHost from 'utils/picture';
import * as classes from './styles';

const Header = ({ children }) => {
  const user = useSelector((state) => state.user.authUser);
  const avatarSrc = insertHost(user.icon);
  const isAuthenticated = !!user.id;

  return (
    <AppBar position="fixed" css={classes.headerContainer}>
      <Toolbar css={classes.headerToolbar}>
        {children}
        <Link css={classes.logoLink} to={isAuthenticated ? routes.MAIN : routes.LOGIN}>
          <LightLogo css={classes.logo} />
        </Link>

        {isAuthenticated && (
          <Link css={classes.userLink} to={routes.USER_PROFILE}>
            {avatarSrc
              ? <img css={[classes.avatarWrap, classes.avatarImage]} src={avatarSrc} alt="" />
              : <AccountCircle css={classes.avatarWrap} />
            }
            <Typography variant="subtitle2" css={classes.userName}>
              {user.firstName} {user.lastName}
            </Typography>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
