/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TextFieldInput from 'components/Form/TextFieldInput';
import UserPhoneFields from 'components/Shared/UserPhoneFields';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import registrationReason from 'constants/registrationReason';
import phoneFieldsStyle from './styles';

const UserDetailsStep = ({ className, initialValues, onEmailBlur }) => {
  const reason = initialValues && initialValues.reason;
  const isPositionPreFilled = !!(initialValues && initialValues.position);

  return (
    <div className={className}>
      <Field
        sx={{ mb: 2 }}
        disabled={(
          reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || reason === registrationReason.PRE_REGISTERED_FOR_EVENT
        )}
        placeholder="e.g. Joe"
        name="firstName"
        component={TextFieldInput}
        label="First name"
        validate={required}
      />
      <Field
        sx={{ mb: 2 }}
        disabled={(
          reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || reason === registrationReason.PRE_REGISTERED_FOR_EVENT
        )}
        placeholder="e.g. Smith"
        name="lastName"
        component={TextFieldInput}
        label="Last name"
        validate={required}
      />
      <Field
        sx={{ mb: 2 }}
        type="email"
        placeholder="e.g. Joe@Smith.com"
        name="email"
        onBlur={onEmailBlur}
        component={TextFieldInput}
        disabled={(
          reason === registrationReason.INVITED_FOR_REVIEW
          || reason === registrationReason.INVITED_TO_COMPANY
          || reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || reason === registrationReason.PRE_REGISTERED_FOR_EVENT
        )}
        label="Work email"
        validate={composeValidators(validateEmail, validateIsCorporateEmailDomain)}
      />
      <Field
        sx={{ mb: 2 }}
        type="password"
        placeholder="*****"
        name="password"
        component={TextFieldInput}
        label="Password"
        validate={required}
      />
      <Field
        sx={{ mb: 2 }}
        disabled={reason === registrationReason.PRE_REGISTERED_FOR_EVENT && isPositionPreFilled}
        placeholder="e.g. CEO"
        name="position"
        component={TextFieldInput}
        label="Position"
        validate={required}
      />
      {reason === registrationReason.PRE_REGISTERED_FOR_EVENT && (
        <UserPhoneFields css={phoneFieldsStyle} isRequired />
      )}
    </div>
  );
};

UserDetailsStep.propTypes = {
  className: PropTypes.string,
  onEmailBlur: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

UserDetailsStep.defaultProps = {
  className: null,
  initialValues: null,
};

export default UserDetailsStep;
