/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import ChallengesList from 'components/Shared/ChallengesList';
import getRetailerDisplayedChallengeStatus from 'utils/getDisplayedRetailerChallengeStatus';
import DashboardChallengeType from 'types/DashboardChallengeType';
import EventHint from '../EventHint';
import * as classes from './styles';

const RetailerEventChallengeDashboard = ({ challenges }) => {
  const challengesWithDisplayedStatus = challenges.map((challenge) => ({
    ...challenge,
    displayedStatus: getRetailerDisplayedChallengeStatus(challenge),
  }));

  return (
    <div>
      <EventHint messageAction="create new RFI" css={classes.hint} />
      <ChallengesList challenges={challengesWithDisplayedStatus} isSourceShown={false} />
    </div>
  );
};

RetailerEventChallengeDashboard.propTypes = {
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired).isRequired,
};

export default RetailerEventChallengeDashboard;
