const registrationReason = {
  INVITED_TO_COMPANY: 'invitedToCompany',
  INVITED_FOR_REVIEW: 'invitedForReview',
  INVITED_TO_SHARED_CHALLENGE: 'invitedToSharedChallenge',
  PRE_REGISTERED_FOR_CHALLENGE: 'preRegisteredForChallenge',
  PRE_REGISTERED_FOR_EVENT: 'preRegisteredForEvent',
  JOINING_TO_COMPANY: 'joiningToCompany',
};

export default registrationReason;
