/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import EventType from 'types/Event';
import LocationType from 'types/Location';
import breadcrumb from './styles';

const EventPageBreadcrumbs = ({ sx, event, pageBreadcrumbs }) => {
  const { slug } = useParams();

  const companyType = useSelector((state) => state.user.company.type);
  const eventPathname = companyType === companyStatus.VENDOR
    ? routes.VENDOR_EVENT_DETAILS
    : routes.RETAILER_EVENT_DETAILS;

  const eventUrl = generatePath(eventPathname, { slug });
  const eventDashboardUrl = companyType === companyStatus.VENDOR
    ? routes.VENDOR_EVENTS_DASHBOARD
    : routes.RETAILER_EVENTS_DASHBOARD;

  const breadcrumbs = [
    { to: eventDashboardUrl, title: 'Events' },
    { to: eventUrl, title: event.name },
    ...pageBreadcrumbs,
  ];

  return (
    <Breadcrumbs css={breadcrumb} sx={sx}>
      {breadcrumbs.map(({ title, to }) => (
        <Link key={title} to={to}>
          <Typography css={breadcrumb} variant="caption1">{title}</Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

EventPageBreadcrumbs.propTypes = {
  event: EventType.isRequired,
  sx: PropTypes.object.isRequired,
  pageBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        LocationType.isRequired,
      ]).isRequired,
    }).isRequired,
  ),
};

EventPageBreadcrumbs.defaultProps = {
  pageBreadcrumbs: [],
};

export default EventPageBreadcrumbs;
