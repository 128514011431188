import round from 'lodash/round';
import OVERALL_RATING_FIELD_NAME from 'constants/overallRatingFieldName';

const getVendorAverageRating = (reviews) => {
  if (!reviews) {
    return 0;
  }

  const { totalRating, voted } = reviews.reduce(
    (acc, review) => {
      const overallRating = review.ratings && review.ratings.find((rating) => (
        rating.field === OVERALL_RATING_FIELD_NAME
      ));

      if (overallRating && overallRating.value > 0) {
        return {
          totalRating: acc.totalRating + overallRating.value,
          voted: acc.voted + 1,
        };
      }

      return acc;
    },
    { totalRating: 0, voted: 0 },
  );

  if (voted === 0) {
    return 0;
  }

  return round(totalRating / voted, 1);
};

export default getVendorAverageRating;
