import { css } from '@emotion/react';

export const header = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const content = (theme) => (
  css`
    padding: ${theme.spacing(3)};
    border-radius: ${theme.spacing(1)};
    box-shadow: 0 0 10px rgba(50, 55, 64, 0.07);
  `
);

export const collapse = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);

export const questionList = (theme) => (
  css`
    border: 1px solid ${theme.primaryPalette.midLightGrey};
    border-radius: 4px;

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)};
    }
  `
);

export const collapseHeader = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};
    cursor: pointer;
  `
);

export const collapseChevron = (theme) => (
  css`
    color: ${theme.palette.primary.main};
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms;
  `
);

export const rotatedChevron = css`
  transform: rotate(180deg);
`;

export const desktopButtonLabel = (theme) => (
  css`
    font: inherit;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
);

export const mobileButtonLabel = (theme) => (
  css`
    font: inherit;

    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `
);
