/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import Typography from '@mui/material/Typography';
import { ReOrderDotsVertical20Filled } from '@fluentui/react-icons';
import * as classes from './styles';

const DRAG_HANDLE_CLASS_NAME = 'event-priorities-card-drag-handle';

const EventPriorityList = ({
  className,
  slots,
  onSetSlots,
  renderSlot,
}) => (
  <ReactSortable
    list={slots}
    setList={onSetSlots}
    tag="ol"
    className={className}
    handle={`.${DRAG_HANDLE_CLASS_NAME}`}
  >
    {slots.map((slot, index) => (
      <li key={slot.id} css={classes.li}>
        <Typography css={classes.counter} variant="subtitle1" component="span">
          {index + 1}
        </Typography>

        <div css={classes.wrap}>
          <div css={classes.dragHandle} className={DRAG_HANDLE_CLASS_NAME}>
            <ReOrderDotsVertical20Filled css={classes.handleIcon} />
          </div>

          <div css={classes.content}>
            {renderSlot(slot, index)}
          </div>
        </div>
      </li>
    ))}
  </ReactSortable>
);

EventPriorityList.propTypes = {
  className: PropTypes.string,
  onSetSlots: PropTypes.func.isRequired,
  renderSlot: PropTypes.func.isRequired,
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ).isRequired,
};

EventPriorityList.defaultProps = {
  className: null,
};

export default EventPriorityList;
