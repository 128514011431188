import { useSelector } from 'react-redux';
import companyAPI from 'api/company';
import EventsDashboard from 'components/Events/EventsDashboard';
import FetchContainer from 'containers/FetchContainer';
import withRouteGuards from 'containers/withRouteGuards';
import { approvedCompanyGuard, authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';
import routes from 'constants/routes';

const VendorEventsDashboardPage = () => {
  const companyId = useSelector((state) => state.user.company.id);

  const getInitialProps = async () => {
    const { data: events } = await companyAPI.getVendorEvents(companyId);

    return { events };
  };

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={EventsDashboard}
      eventPagePathname={routes.VENDOR_EVENT_DETAILS}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, approvedCompanyGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.EVENTS_DASHBOARD)(VendorEventsDashboardPage)),
);
