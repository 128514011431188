import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import withPageTitle from 'containers/withPageTitle';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import eventAPI from 'api/event';
import EventChallengeForm from 'components/Events/EventChallengeForm';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import pageTitles from 'constants/pageTitles';

const CreateEventChallengePage = (props) => {
  const { slug } = useParams();

  const getInitialProps = useCallback(async () => {
    const { data: event } = await eventAPI.getEventBySlug(slug);

    return { event };
  }, [slug]);

  return (
    <FetchContainer
      component={EventChallengeForm}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.EVENT_CHALLENGE_FORM)(CreateEventChallengePage)),
);
