import { css } from '@emotion/react';

export const recommendationWrap = (theme) => (
  css`
    overflow: hidden;
    margin-bottom: ${theme.spacing(4)};
    border: 1px solid ${theme.primaryPalette.midLightGrey};
    border-radius: 4px;
  `
);

export const recommendedRequirement = (theme) => (
  css`
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};
    }

    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing(1.5)};

    }

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(1)};
    }

    ${theme.breakpoints.up('sm')} {
      align-items: center;
    }

    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)};
    }
  `
);

export const description = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const addButton = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex-shrink: 0;
    }
  `
);

export const showMoreButton = (theme) => (
  css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    border: none;
    padding: ${theme.spacing(1)};
    color: ${theme.palette.primary.main};
    background-color: ${theme.primaryPalette.veryLightYellow};
    outline: none;
    cursor: pointer;
  `
);

export const icon = (theme) => (
  css`
    margin-right: ${theme.spacing(1)};
  `
);

export const loading = css`
  padding: 100px 0;
  text-align: center;
`;
