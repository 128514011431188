import APIClient from './client';

class FileAPI extends APIClient {
  constructor() {
    super('/containers');
  }

  uploadFile = async (model, name, file, userId) => {
    const { data } = await this.postFile(`/${userId}/upload`, file, { model, name });

    return data;
  };

  deleteFileFromServer = async (id, file) => {
    const { data } = await this.deleteRequest(`/${id}/files/${file}`);

    return data;
  };
}

const fileAPI = new FileAPI();

export default fileAPI;
