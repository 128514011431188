import { useState } from 'react';
import PropTypes from 'prop-types';
import { isAfter } from 'date-fns';
import { TextField, Typography } from '@mui/material';
import StatusBar from 'components/Shared/StatusBar';
import 'styles/ChallengeDashboardTable.scss';
import './AllChallengesContent.scss';

const AllChallengesContent = ({
  defaultState,
  challenges,
  challengeStates,
  isMatchedByState,
  renderChallenge,
}) => {
  const [value, onChange] = useState('');
  const [selectedState, selectChallengeState] = useState(defaultState);

  const handleChange = (e) => onChange(e.target.value);

  const isMatchedByName = (challenge) => {
    if (!value) {
      return true;
    }

    const regExp = new RegExp(value, 'i');

    return challenge.name.match(regExp);
  };

  const filteredChallenges = challenges
    .filter((challenge) => isMatchedByName(challenge) && isMatchedByState(selectedState, challenge))
    .sort((a, b) => {
      const dateA = new Date(a.response_deadline);
      const dateB = new Date(b.response_deadline);

      return isAfter(dateA, dateB) ? -1 : 1;
    });

  const challengeStateList = Object.values(challengeStates);

  return (
    <div className="all-challenges-content">
      <div className="all-challenges-content__state-filter">
        {challengeStateList.map((state) => (
          <StatusBar
            key={state}
            className="all-challenges-content__state"
            onClick={() => selectChallengeState(state)}
            isActive={state === selectedState}
          >
            {state}
          </StatusBar>
        ))}
      </div>

      <TextField
        className="all-challenges-content__name-field"
        onChange={handleChange}
        value={value}
        placeholder="Filter"
        name="challenge-name-filter"
      />

      <div>
        {filteredChallenges.length ? (
          <div className="challenge-dashboard-list">
            {filteredChallenges.map(renderChallenge)}
          </div>
        ) : (
          <Typography variant="body1">No RFIs found</Typography>
        )}
      </div>
    </div>
  );
};

AllChallengesContent.propTypes = {
  defaultState: PropTypes.string.isRequired,
  renderChallenge: PropTypes.func.isRequired,
  isMatchedByState: PropTypes.func.isRequired,
  challengeStates: PropTypes.objectOf(PropTypes.string.isRequired),
  challenges: PropTypes.array.isRequired,
};

export default AllChallengesContent;
