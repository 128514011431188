import { css } from '@emotion/react';

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const field = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);
