import { css } from '@emotion/react';

export const emptyDashboard = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const tableHeader = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.spacing(1)};
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
);

export const item = (theme) => (
  css`
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(50, 55, 64, 0.07);

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(1.25)} ${theme.spacing(2)};

      &:not(:last-child) {
        margin-bottom: ${theme.spacing(1)};
      }
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)};

      &:not(:last-child) {
        margin-bottom: ${theme.spacing(3)};
      }
    }
  `
);

export const link = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-decoration: none;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      gap: ${theme.spacing(2)};
    }
  `
);

export const eventDetails = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      gap: ${theme.spacing(6)};
    }

    ${theme.breakpoints.down('sm')} {
      justify-content: space-between;
    }
  `
);

export const column = (theme) => (
  css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.down('sm')} {
      flex: 1;
    }
  `
);

export const sortButton = (theme) => css`
  display: flex;
  gap: ${theme.spacing(1)};
  padding: 0 !important;
  color: ${theme.primaryPalette.menuGrey} !important;

  &, &:hover, &:focus, &:active {
    background: none !important;
    border: none !important;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const subtitle = (theme) => (
  css`
    color: ${theme.primaryPalette.mediumGrey};
  `
);

export const caption = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const columnTitle = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(0.5)};
    }
  `
);

export const descriptionHeader = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(7.5)};
  `
);

export const eventDate = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      margin: ${theme.spacing(-2)};
      padding: ${theme.spacing(2)};
      min-width: 80px;
    }
  `
);

export const fallback = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const iconWrap = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.spacing(15)};
    height: ${theme.spacing(15)};
    margin-bottom: ${theme.spacing(4)};
    background: ${theme.primaryPalette.yellow};
    color: ${theme.primaryPalette.primaryYellow};
    border-radius: 50%;
  `
);
