import { css } from '@emotion/react';

export const section = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(5)};
    }
  `
);

export const fields = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);
