import constants from '../constants/vendorInfo';

export const initialState = () => ({
  company: {},
  vendorInfo: {},
  isError: false,
  isSuccess: false,
});

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case constants.GET_COMPANY:
      return {
        ...state,
        company: action.company,
        isSuccess: true,
        isError: false,
      };
    case constants.CLEAR_COMPANY:
      return initialState();

    case constants.ADD_REVIEW: {
      const reviews = state.company.ratingsReviews;

      const updatedReviews = reviews ? [...reviews, action.review] : [action.review];

      return {
        ...state,
        company: {
          ...state.company,
          ratingsReviews: updatedReviews,
        },
      };
    }

    case constants.UPSERT_REVIEW_VOTE: {
      const { vote } = action;
      const { ratingsReviews: reviews } = state.company;

      const updatedUserVote = reviews.map((review) => {
        const isNewVote = review.voteReviews.some((voteReview) => voteReview.id === vote.id);

        if (review.id === vote.ratingsReviewsId) {
          if (isNewVote) {
            const voteReviewList = review.voteReviews.map(
              (reviewVote) => (reviewVote.id === vote.id ? vote : reviewVote),
            );

            return { ...review, voteReviews: voteReviewList };
          }

          return { ...review, voteReviews: [...review.voteReviews, vote] };
        }

        return review;
      });

      return {
        ...state,
        company: {
          ...state.company,
          ratingsReviews: updatedUserVote,
        },
      };
    }

    case constants.GET_COMPANY_ERROR:
      return { ...state, isSuccess: false, isError: true };

    default: return state;
  }
};

export default reducer;
