import { css } from '@emotion/react';

export const errorContainerStyles = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    padding: ${theme.spacing(4)};
    text-align: center;
    background-color: #fff;
  `
);

export const errorIcon = (theme) => (
  css`
    width: 100%;
    margin-bottom: 5px;
    color: ${theme.primaryPalette.errorColor};
  `
);

export const video = css`
  width: 100% !important;
  max-width: 640px;
  height: auto !important;
  aspect-ratio: 16/9;
`;
