import { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { FormHelperText } from '@mui/material';
import { withSnackbar } from 'notistack';
import FileUpload from 'components/UI/FileUpload';
import { avatarUploadConfig } from 'constants/fileUploadConfigs';
import Modal from 'components/UI/Modal';
import CropImage from 'components/UI/CropImage';
import fileAPI from 'api/file';
import insertHost from 'utils/picture';
import getErrorMessage from 'utils/getErrorMessage';
import FieldInputType from 'types/FieldInput';
import FieldMetaType from 'types/FieldMeta';
import './AvatarField.scss';

class AvatarField extends Component {
  static propTypes = {
    className: PropTypes.string,
    fileNamePrefix: PropTypes.string,
    input: PropTypes.oneOfType([
      PropTypes.shape(fieldInputPropTypes),
      FieldInputType,
    ]).isRequired,
    meta: PropTypes.oneOfType([
      PropTypes.shape(fieldMetaPropTypes),
      FieldMetaType,
    ]).isRequired,
    model: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    label: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    fileNamePrefix: null,
    label: null,
  };

  state = {
    fileString: null,
    isCropModalOpen: false,
  };

  handleInit = (formData) => {
    const file = formData.get('file');

    return this.handleAcceptImage(file);
  };

  handleAcceptImage = (file) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      this.setState({
        fileString: reader.result.toString(),
        isCropModalOpen: true,
      });
    });

    return new Promise(() => ({ body: file.name }));
  };

  handleUploadFile = async (file) => {
    const {
      fileNamePrefix,
      input: { onChange, name },
      model,
      userId,
      enqueueSnackbar,
    } = this.props;

    try {
      const body = new FormData();
      body.append('file', file);

      const fileName = fileNamePrefix || name;
      const res = await fileAPI.uploadFile(model, fileName, body, userId);

      onChange(res.body);
      this.handleCloseCropModal();

      enqueueSnackbar('Image was uploaded', { variant: 'success' });
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  handleCloseCropModal = () => {
    this.setState({ fileString: null, isCropModalOpen: false });
  };

  render() {
    const {
      className,
      label,
      input: { value, name },
      meta: { error, submitFailed },
    } = this.props;
    const { fileString, isCropModalOpen } = this.state;

    return (
      <>
       <div className={className}>
          {!!value && <img className="avatar-field-image" src={insertHost(value)} alt="" />}{' '}
          <FileUpload
            isIcon={!!value}
            name={name}
            label={label}
            onUpload={this.handleInit}
            {...avatarUploadConfig}
          />
          {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
       </div>
        <Modal show={isCropModalOpen} modalClosed={this.handleCloseCropModal}>
          <CropImage
            image={fileString}
            onSave={this.handleUploadFile}
            onLoad={this.handleAcceptImage}
          />
        </Modal>
      </>
    );
  }
}

export default withSnackbar(AvatarField);
