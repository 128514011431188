/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ChallengeResponseParticipants from 'components/ChallengeResponseParticipants';
import ChallengeRequirementsModal from 'components/Challenge/ChallengeRequirementsModal';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import { EventChallengeType } from 'types/Challenge';
import buttonStyles from './styles';

const ChallengeResponseFormHeader = ({ challenge, className }) => (
  <div className={className}>
    <Typography sx={{ mb: 5 }} variant="h1">
      RFI response
    </Typography>
    <div css={buttonStyles}>
      <ChallengeResponseParticipants />
      <ChallengeRequirementsModal challenge={challenge} isNoteForResponderShown />
    </div>
  </div>
);

ChallengeResponseFormHeader.propTypes = {
  className: PropTypes.string,
  challenge: PropTypes.oneOfType([EventChallengeType, ChallengeSynopsisType]).isRequired,
};

ChallengeResponseFormHeader.defaultProps = {
  className: null,
};

export default ChallengeResponseFormHeader;
