import { useState } from 'react';
import PropTypes from 'prop-types';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import { EventChallengeType } from 'types/Challenge';
import ResponseChallengeInvitation from '../ResponseChallengeInvitation';
import ChallengeResponseSuccess from '../ChallengeResponseSuccess';
import ChallengeResponseForm from '../ChallengeResponseForm';
import formatInitialValues from '../formatInitialValues';

const ChallengeResponseFormContent = ({
  challenge,
  challengeResponse,
  company,
  setPageProps,
}) => {
  const [isPublished, setPublishStatus] = useState(false);

  return (
    <div>
      {challengeResponse && challengeResponse.id ? (
        <>
          {isPublished ? (
            <ChallengeResponseSuccess />
          ) : (
            <ChallengeResponseForm
              initialValues={formatInitialValues(challengeResponse, challenge)}
              challenge={challenge}
              onPublish={() => setPublishStatus(true)}
            />
          )}
        </>
      ) : (
        <ResponseChallengeInvitation
          challenge={challenge}
          onInitResponseForm={setPageProps}
          company={company}
        />
      )}
    </div>
  );
};

ChallengeResponseFormContent.propTypes = {
  setPageProps: PropTypes.func.isRequired,
  challenge: PropTypes.oneOfType([ChallengeSynopsisType, EventChallengeType]).isRequired,
  challengeResponse: PropTypes.object,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

ChallengeResponseFormContent.defaultProps = {
  challengeResponse: null,
};

export default ChallengeResponseFormContent;
