import { Switch, Route } from 'react-router-dom';

import routes from 'constants/routes';

import AuthPageLayout from 'components/Auth/AuthPageLayout';
import GeneralLayout from 'components/GeneralLayout';
import PageNotFound from 'components/PageNotFound';

import LoginPage from 'pages/Login';
import RegisterPage from 'pages/Register';
import RegisterSuccessPage from 'pages/RegisterSuccess';
import ForgotPasswordPage from 'pages/ForgotPassword';
import RecoverPasswordPage from 'pages/RecoverPassword';
import TermsAndConditionsPage from 'pages/TermsAndConditions';
import PrivacyPage from 'pages/Privacy';
import HomePage from 'pages/Home';
import ManageUsersPage from 'pages/ManageUsers';
import ManageCompanyPrimaryContactPage from 'pages/ManageCompanyPrimaryContact';
import ManageCompanyEmailDomainsPage from 'pages/ManageCompanyEmailDomains';
import EditVendorProfilePage from 'pages/EditVendorProfile';
import EditRetailerProfilePage from 'pages/EditRetailerProfile';
import ReviewRequestPage from 'pages/ReviewRequest';
import RetailerReviewsPage from 'pages/RetailerReviews';
import VendorPaymentsPage from 'pages/VendorPayments';
import CreateNewChallengePage from 'pages/CreateNewChallenge';
import ChallengeResponseForVendorPage from 'pages/ChallengeResponseForVendor';
import VendorChallengeDashboardPage from 'pages/VendorChallengeDashboard';
import RetailerChallengeDashboardPage from 'pages/RetailerChallengeDashboard';
import UserProfilePage from 'pages/UserProfile';
import EditUserProfilePage from 'pages/EditUserProfile';
import SavedSolutionsPage from 'pages/SavedSolutions';
import ChallengeResponseFormPage from 'pages/ChallengeResponseForm';
import PublicChallengePage from 'pages/PublicChallenge';
import PublicChallengePageRedirect from 'pages/PublicChallengeRedirect';
import VendorProfileForAuthenticatedPage from 'pages/VendorProfileForAuthenticated';
import VendorProfileForGuestPage from 'pages/VendorProfileForGuest';
import RetailerEventsDashboardPage from 'pages/RetailerEventsDashboard';
import VendorEventsDashboardPage from 'pages/VendorEventsDashboard';
import SolutionsSearchForRetailerPage from 'pages/SolutionsSearchForRetailer';
import SolutionsSearchForGuestPage from 'pages/SolutionsSearchForGuest';
import ChallengeForRetailerPages from 'pages/ChallengeForRetailer';
import SavedChallengeRedirect from 'pages/SavedChallengeRedirect';
import VendorEventDetailsPage from 'pages/VendorEvent';
import RetailerEventDetailsPage from 'pages/RetailerEvent';
import CreateEventChallengePage from 'pages/CreateEventChallenge';
import EditEventTopicPrioritiesPage from 'pages/EditEventTopicPrioritiesPage';
import VendorEventBusinessPrioritiesPage from 'pages/VendorEventBusinessPriorities';
import EditUserPage from 'pages/EditCompanyUser';
import { challengeRedirects, OLD_PUBLIC_CHALLENGE_URLS } from 'constants/challengeRedirects';
import publicPages from 'constants/publicPages';

const routesWithGeneralLayout = Object.values(routes).filter((route) => (
  !publicPages.includes(route)
));

const Routes = () => (
  <Switch>
    <Route path={publicPages} exact>
      <AuthPageLayout>
        <Switch>
          <Route path={routes.LOGIN} component={LoginPage} exact />
          <Route path={routes.REGISTER} component={RegisterPage} exact />
          <Route component={ForgotPasswordPage} path={routes.FORGOT_PASSWORD} exact />
          <Route component={TermsAndConditionsPage} path={routes.TERMS_AND_CONDITIONS} exact />
          <Route component={PrivacyPage} path={routes.PRIVACY} exact />
          <Route component={VendorPaymentsPage} path={routes.VENDOR_PAYMENTS} exact />
          <Route
            component={SolutionsSearchForGuestPage}
            path={routes.PUBLIC_SEARCH_SOLUTION}
            exact
          />
          <Route component={VendorProfileForGuestPage} path={routes.PUBLIC_VENDOR_INFO} />
          <Route component={RegisterSuccessPage} path={routes.REGISTRATION_SUCCESS} exact />
          <Route component={RecoverPasswordPage} path={routes.RECOVER_PASSWORD} exact />
          <Route component={PageNotFound} />
        </Switch>
      </AuthPageLayout>
    </Route>
    <Route path={routesWithGeneralLayout}>
      <GeneralLayout>
        <Switch>
          <Route
            component={SolutionsSearchForRetailerPage}
            path={routes.SEARCH_SOLUTION_PAGE}
            exact
          />
          <Route
            component={VendorChallengeDashboardPage}
            path={routes.VENDOR_CHALLENGE_DASHBOARD}
            exact
          />
          <Route
            component={VendorEventsDashboardPage}
            path={routes.VENDOR_EVENTS_DASHBOARD}
            exact
          />
          <Route
            component={RetailerEventsDashboardPage}
            path={routes.RETAILER_EVENTS_DASHBOARD}
            exact
          />
          <Route component={ReviewRequestPage} path={routes.REQUEST_REVIEW} exact />
          <Route component={PublicChallengePageRedirect} path={OLD_PUBLIC_CHALLENGE_URLS} exact />
          <Route component={PublicChallengePage} path={routes.PUBLIC_CHALLENGE_INFO} />
          <Route
            component={ChallengeResponseFormPage}
            path={routes.CHALLENGE_RESPONSE_FORM}
            exact
          />
          <Route component={HomePage} path={routes.MAIN} exact />
          <Route component={CreateNewChallengePage} path={routes.CREATE_NEW_CHALLENGE} exact />
          <Route
            component={ChallengeResponseForVendorPage}
            path={routes.CHALLENGE_RESPONSE_FOR_VENDOR}
            exact
          />
          <Route
            component={RetailerChallengeDashboardPage}
            path={routes.RETAILER_CHALLENGE_DASHBOARD}
            exact
          />
          <Route component={ManageUsersPage} path={routes.MANAGE_USERS} exact />
          <Route component={EditUserPage} path={routes.EDIT_USER} exact />
          <Route
            component={ManageCompanyPrimaryContactPage}
            path={routes.MANAGE_PRIMARY_COMPANY_CONTACT}
            exact
          />
          <Route
            component={ManageCompanyEmailDomainsPage}
            path={routes.MANAGE_EMAIL_DOMAINS}
            exact
          />
          <Route component={RetailerReviewsPage} path={routes.RATINGS_REVIEWS} exact />
          <Route component={EditVendorProfilePage} path={routes.EDIT_VENDOR_DETAILS} exact />
          <Route component={EditRetailerProfilePage} path={routes.EDIT_RETAILER_DETAILS} exact />
          <Route component={UserProfilePage} path={routes.USER_PROFILE} exact />
          <Route component={EditUserProfilePage} path={routes.EDIT_USER_PROFILE} exact />
          <Route component={SavedSolutionsPage} path={routes.SAVED_SOLUTIONS} exact />
          <Route
            path={routes.VENDOR_EVENT_DETAILS}
            component={VendorEventDetailsPage}
            exact
          />
          <Route
            path={routes.RETAILER_EVENT_DETAILS}
            component={RetailerEventDetailsPage }
            exact
          />
          <Route
            path={routes.EVENT_CHALLENGE_FORM}
            component={CreateEventChallengePage}
            exact
          />
          <Route
            path={routes.EDIT_RETAILER_EVENT_BUSINESS_PRIORITIES}
            component={EditEventTopicPrioritiesPage}
            exact
          />
          <Route
            path={routes.EDIT_VENDOR_EVENT_BUSINESS_PRIORITIES}
            component={VendorEventBusinessPrioritiesPage}
            exact
          />
          <Route
            component={ChallengeForRetailerPages}
            path={routes.CHALLENGE_FOR_RETAILER_PAGES}
            exact={false}
          />
          <Route
            component={SavedChallengeRedirect}
            path={challengeRedirects.SAVED_CHALLENGE}
            exact
          />
          <Route component={VendorProfileForAuthenticatedPage} path={routes.VENDOR_INFO} />
          <Route component={PageNotFound} />
        </Switch>
      </GeneralLayout>
    </Route>
  </Switch>
);

export default Routes;
