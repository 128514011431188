import { css } from '@emotion/react';

export const fallback = (theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${theme.breakpoints.down('md')} {
    padding-top: ${theme.spacing(3)};
  }
`;

export const iconWrap = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin-bottom: ${theme.spacing(4)};
    background: ${theme.palette.primary.light};
    color: ${theme.palette.primary.main};
    border-radius: 50%;
  `
);

export const subtitle = (theme) => (
  css`
    color: ${theme.primaryPalette.mediumGrey};
  `
);
