import PropTypes from 'prop-types';
import companyRevenue from 'constants/companyRevenue';

const ChallengeSynopsisType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  business_description: PropTypes.string,
  challenge_description: PropTypes.string.isRequired,
  response_deadline: PropTypes.string.isRequired,
  companyRevenue: PropTypes.oneOf([...companyRevenue, '']),
  objectives: PropTypes.string.isRequired,
  constraints: PropTypes.string.isRequired,
});

export default ChallengeSynopsisType;
