/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Clear from '@mui/icons-material/Clear';
import { required } from 'lib/validation';
import TextFieldInput from 'components/Form/TextFieldInput';
import composeValidators from 'utils/composeValidators';
import FieldArrayFieldsType from 'types/FieldArrayFields';
import FieldArrayMetaType from 'types/FieldArrayMeta';
import * as classes from './styles';

const getFieldValidators = (validators = [], minItems, fields) => {
  if (!minItems && (fields && fields.length === 1 && !fields.value[0])) {
    return [];
  }

  if (minItems > 1 && fields && fields.length === 1 && !!fields.value[0]) {
    return validators;
  }

  return validators.length ? [required, ...validators] : required;
};

// TODO: implement array-wide error
const FieldList = ({
  className,
  fields,
  maxItems,
  minItems,
  fieldValidators,
  buttonText,
}) => (
  <div className={className}>
    <ul>
      {fields.map((name, i) => (
        <li key={name} css={classes.entry}>
          <Field
            css={classes.input}
            name={name}
            component={TextFieldInput}
            validate={
            getFieldValidators(fieldValidators, minItems, fields).length > 1
              ? composeValidators(getFieldValidators(fieldValidators, minItems, fields))
              : getFieldValidators(fieldValidators, minItems, fields)
            }
          />
          <Fab sx={{ ml: 1 }} size="small" onClick={() => fields.remove(i)}>
            <Clear fontSize="small" />
          </Fab>
        </li>
      ))}
    </ul>
    {(typeof maxItems !== 'number' || fields.length < maxItems) && (
      <Button onClick={() => fields.push('')} sx={{ mt: 2 }}>
        {buttonText}
      </Button>
    )}
  </div>
);

FieldList.propTypes = {
  className: PropTypes.string,
  fields: FieldArrayFieldsType.isRequired,
  meta: FieldArrayMetaType.isRequired,
  maxItems: PropTypes.number,
  minItems: PropTypes.number,
  fieldValidators: PropTypes.arrayOf(PropTypes.func.isRequired),
  buttonText: PropTypes.string,
};

FieldList.defaultProps = {
  className: null,
  fieldValidators: [],
  maxItems: null,
  minItems: 0,
  buttonText: 'Add',
};

export default FieldList;
