import APIClient from './client';

class UserRoleAPI extends APIClient {
  constructor() {
    super('/InvitedUserRole');
  }

  assignRole = (userId, roleId) => {
    const filter = { userId };

    return this.upsertWithWhere(filter, { invitedRoleId: roleId });
  };
}

const userRoleAPI = new UserRoleAPI();

export default userRoleAPI;
