import { css } from '@emotion/react';

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);

export const blockWrap = (theme) => (
  css`
    padding: ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)};
    box-shadow: ${theme.boxShadows.large};
    border-radius: ${theme.spacing(2)};
  `
);

export const buttons = (theme) => css`
  ${theme.breakpoints.up('sm')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const submit = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const subtitle = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const highlightedText = (theme) => (
  css`
    font-family: ${theme.boldFont};
    color: ${theme.primaryPalette.primaryYellow};
    `
);

export const register = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const registerLabel = (theme) => (
  css`
    margin-right: ${theme.spacing(2)};
    color: ${theme.primaryPalette.mediumGrey};
  `
);

export const forgotPassword = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
    text-align: right;
  `
);
