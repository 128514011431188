import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import EditSavedChallengeForm from 'components/RetailerChallenges/EditSavedChallengeForm';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const EditSavedChallengeFormPage = (props) => {
  const { id } = useParams();
  const { getChallengeMilestones } = useContext(ChallengeContext);

  const getInitialProps = useCallback(async () => {
    const { data: event } = await challengeAPI.getChallengeEvent(id);

    await getChallengeMilestones();

    return { event };
  }, [id, getChallengeMilestones]);

  return (
    <FetchContainer
      component={EditSavedChallengeForm}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default (withPageTitle(pageTitles.EDIT_SAVED_CHALLENGE)(EditSavedChallengeFormPage));
