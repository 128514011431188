/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import socialLink from './styles';

const SocialMediaLink = ({ className, name, link }) => (
  <a
    css={socialLink}
    className={className}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Typography variant="body1">{name} profile</Typography>
  </a>
);

SocialMediaLink.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

SocialMediaLink.defaultProps = {
  className: null,
};

export default SocialMediaLink;
