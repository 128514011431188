/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, FieldArray, Form } from 'redux-form';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Hidden from '@mui/material/Hidden';

import CustomFieldArray from 'components/UI/CustomFieldArray';
import TextFieldInput from 'components/UI/TextFieldInput';
import DatePicker from 'components/UI/DatePicker';
import DropDownInput from 'components/UI/DropDownInput';
import companyRevenue from 'constants/companyRevenue';
import {
  maxLength15,
  required,
  validateFieldArrayLength,
  validateIsTodayOrAfter,
  validateTagsArrayLength,
} from 'lib/validation';
import ChallengeTagFieldArray from '../ChallengeTagFieldArray';
import YesNoQuestionsFieldArray from '../YesNoQuestionsFieldArray';
import OpenEndedQuestionFieldArray from '../OpenEndedQuestionFieldArray';
import ChallengeMilestonesFieldArray from '../ChallengeMilestonesFieldArray';
import CHALLENGE_FORM_ID from '../formId';
import * as classes from './styles';

const tagLimits = { MIN: 1, MAX: 20 };

const ChallengeFields = ({
  children,
  onSubmit,
  isCreatedFromEvent,
  onSaveChallengeDraft,
}) => (
  <Form
    name={CHALLENGE_FORM_ID}
    autoComplete="off"
    onSubmit={onSubmit}
    className="create-challenge"
  >
    <div css={classes.section}>
      <Field
        css={classes.field}
        label="RFI name"
        name="name"
        validate={[required, maxLength15]}
        component={TextFieldInput}
      />
      {!isCreatedFromEvent && (
        <Field
          css={classes.field}
          label="Describe your business"
          placeholder="e.g. High street fashion consumer brand with 100 stores. 2 million customers, or anything else to help understand the scale of your business"
          name="business_description"
          multiline
          rows="2"
          validate={[required]}
          component={TextFieldInput}
        />
      )}
      <Field
        css={classes.field}
        label="Describe the challenge"
        placeholder="What is the challenge your business currently faces and list the solution requirements"
        name="challenge_description"
        component={TextFieldInput}
        validate={[required]}
        multiline
        rows="6"
      />
      <Field
        css={classes.field}
        label="What is the objective"
        placeholder="What are you looking to achieve from this project, please provide any information that will help the vendors understand your core objective, KPI's, etc..."
        name="objectives"
        component={TextFieldInput}
        validate={[required]}
        multiline
        rows="6"
      />
      <Field
        label="Response deadline (3-4 weeks recommend)"
        css={classes.field}
        name="response_deadline"
        validate={[required, validateIsTodayOrAfter]}
        component={DatePicker}
      />
      {!isCreatedFromEvent && (
        <>
          <Field
            css={classes.field}
            label="Any business or technical constraints eg: budget"
            placeholder="Please provide any relevant information"
            name="constraints"
            validate={[required]}
            component={TextFieldInput}
            multiline
            rows="2"
          />
          <Field
            fullWidth
            label="Please indicate your company's revenue"
            css={classes.field}
            component={DropDownInput}
            name="companyRevenue"
          >
            {companyRevenue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
        </>
      )}
    </div>

    {!isCreatedFromEvent && (
      <>
        <FieldArray
          css={classes.section}
          name="metadata"
          component={ChallengeTagFieldArray}
          validate={[validateTagsArrayLength(tagLimits.MIN, tagLimits.MAX)]}
        />
        <FieldArray
          css={classes.section}
          name="yesNoQuestions"
          component={YesNoQuestionsFieldArray}
        />

        <div css={classes.section}>
          <FormLabel css={classes.label}>
            Add any other requirements you would like to ask. Requirements specific
            to your needs allow vendors to tailor their responses.
          </FormLabel>
          <FieldArray name="openEndedQuestions" component={OpenEndedQuestionFieldArray} />
        </div>

        <CustomFieldArray
          css={classes.field}
          subLabel="(Company name or URL)"
          label="Include specific vendors"
          name="whitelistVendors"
          buttonText="Add vendor"
        />

        <CustomFieldArray
          css={classes.field}
          label="Exclude specific vendors"
          subLabel="(Company name or URL)"
          name="blacklistVendors"
          buttonText="Add vendor"
        />

        <div css={classes.section}>{children}</div>
      </>
    )}
    <FieldArray
      css={classes.section}
      name="milestones"
      component={ChallengeMilestonesFieldArray}
      validate={[validateFieldArrayLength(tagLimits.MIN)]}
      onSaveChallengeDraft={onSaveChallengeDraft}
    />
    <div css={classes.submit}>
      <Button type="submit">
        <Hidden implementation="css" smDown>
          Send RFI requirements
        </Hidden>
        <Hidden implementation="css" smUp>
          Submit
        </Hidden>
      </Button>
    </div>
  </Form>
);

ChallengeFields.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isCreatedFromEvent: PropTypes.bool,
  onSaveChallengeDraft: PropTypes.func.isRequired,
};

ChallengeFields.defaultProps = {
  isCreatedFromEvent: false,
};

export default ChallengeFields;
