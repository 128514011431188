import { css } from '@emotion/react';

const NOTIFICATION_BADGE_SIZE = '21px';

export const menuItem = (theme) => (
  css`
    padding: 20px;
    border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};
    color: ${theme.primaryPalette.menuGrey};
    cursor: pointer;

    &:hover {
      color: ${theme.primaryPalette.grey};
    }

    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: center;
    }
  `
);

export const icon = (theme) => (
  css`
    width: 28px;
    height: 28px;
    margin-right: ${theme.spacing(3)};
  `
);

export const menuItemMain = (theme) => (
  css`
    display: flex;
    width: 140px;
    font-family: ${theme.boldFont};

    ${theme.breakpoints.up('sm')} {
      margin-right: ${theme.spacing(6)}
    }
  `
);

export const menuItemWrapper = (theme) => (
  css`
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const menuItemName = (theme) => (
  css`
    margin-right: ${theme.spacing(1)};
  `
);

export const notification = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${NOTIFICATION_BADGE_SIZE};
    height: ${NOTIFICATION_BADGE_SIZE};
    margin-left: auto;
    font-size: 12px;
    color: #fffdfd;
    background-color: ${theme.primaryPalette.signalRed};
    border-radius: 50%;
  `
);
