import { css } from '@emotion/react';

export const wrap = css`
  overflow-wrap: break-word;
`;

export const section = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const questionsWrap = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const subtitle = (theme) => (
  css`
    margin-bottom: ${theme.spacing(1)};
  `
);

export const yesNoSection = (theme) => (
  css`
    margin-bottom: 3px;
    font-family: ${theme.boldFont};
    font-size: 12px;
    color: ${theme.primaryPalette.grey};
  `
);

export const tag = css`
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;
