/** @jsxImportSource @emotion/react */
import { Skeleton, Typography } from '@mui/material';
import PageBody from 'components/UI/PageBody';
import * as classes from './styles';

const LINES_PER_PARAGRAPH_COUNT = 4;
const PARAGRAPH_COUNT = 4;

const generateNumbersArray = (length) => Array.from({ length }, (_, k) => k);
const linesItemsArray = generateNumbersArray(LINES_PER_PARAGRAPH_COUNT);
const paragraphItemsArray = generateNumbersArray(PARAGRAPH_COUNT);

const PageContentPlaceholder = () => (
  <PageBody>
    <Typography css={classes.title} component="div" variant="h1">
      <Skeleton />
    </Typography>
    {paragraphItemsArray.map((p) => (
      <div key={p} css={classes.section}>
        {linesItemsArray.map((item) => (
          <Typography key={item} component="div" variant="body1">
            <Skeleton />
          </Typography>
        ))}
      </div>
    ))}
  </PageBody>
);

export default PageContentPlaceholder;
