import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `
);

export const buttons = css`
  display: flex;
  align-items: flex-end;
`;

export const title = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
