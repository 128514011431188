/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import GeneralModal from 'components/UI/GeneralModal';
import InviteNewUser from 'components/PublicVendorPage/InviteNewUser';
import { VENDOR_REGISTRATION_STEPS_DATA } from 'components/VendorDataCaptureForm/constants';
import { RETAILER_REGISTRATION_STEPS_DATA } from 'components/RetailerDataCaptureForm/constants';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import routes from 'constants/routes';
import companyStatus from 'constants/companyStatus';
import { isAdmin, isAuthenticated as checkIsAuthenticated } from 'utils/checkUserIdentity';
import getEventMenuItemUrl from 'utils/getEventMenuItemUrl';
import UserType from 'types/User';
import CompanyType from 'types/Company';
import LocationType from 'types/Location';
import ProfileIcon from 'svg/profile';
import NavigationMenu from './NavigationMenu';
import { vendorMenu, guestMenu, retailerMenu } from './mainMenu';
import step from './containerStyles';

const DEFAULT_EDIT_VENDOR_PROFILE_TAB = VENDOR_REGISTRATION_STEPS_DATA.BASIC_DETAILS.path;
const DEFAULT_RETAILER_TAB = RETAILER_REGISTRATION_STEPS_DATA.BASIC_DETAILS.path;
const DEFAULT_VENDOR_PROFILE_TAB = VENDOR_INFO_TABS.BASIC_DETAILS.path;

const NavigationMenuContainer = ({
  user,
  location,
  match,
  company,
  companyPublicVendor,
  eventMenu,
  isCompanyFetchSuccess,
  ...rest
}) => {
  const [isInviteModalOpen, setInviteModal] = useState(false);

  const isAuthenticated = checkIsAuthenticated(user);
  const isCompanyAdmin = isAdmin(user);

  const isVendorPage = !!matchPath(location.pathname, { path: routes.VENDOR_INFO });
  const isPublicVendorPage = !!matchPath(location.pathname, { path: routes.PUBLIC_VENDOR_INFO });

  const getPublicVendorProfileMenu = () => {
    // TODO: merge this logic with code for private vendor profile
    const vendorProfileMenuList = Object.values(VENDOR_INFO_TABS);
    const publicVendorPageTabs = vendorProfileMenuList.map(({ title, path }) => {
      const pathname = generatePath(
        routes.PUBLIC_VENDOR_INFO_TAB,
        { slug: companyPublicVendor.slug, tab: path },
      );

      return {
        to: `${pathname}${location.search}`,
        title,
        disabled: path !== DEFAULT_VENDOR_PROFILE_TAB,
        isSelected: () => isPublicVendorPage && path === DEFAULT_VENDOR_PROFILE_TAB,
      };
    });

    const pathname = generatePath(routes.PUBLIC_VENDOR_INFO_TAB, {
      slug: companyPublicVendor.slug,
      tab: DEFAULT_VENDOR_PROFILE_TAB,
    });

    const publicVendorProfileMenu = [{
      title: 'Profile',
      to: `${pathname}${location.search}`,
      submenu: publicVendorPageTabs,
      icon: ProfileIcon,
      isSelected: () => isPublicVendorPage,
    }];

    return isAuthenticated ? publicVendorProfileMenu : [...publicVendorProfileMenu, ...guestMenu];
  };

  const getEventMenu = () => ({
    to: getEventMenuItemUrl(eventMenu, company.type),
    isSelected: () => (
      location.pathname.startsWith('/retailer/events')
      || location.pathname.startsWith('/vendor/events')
    ),
  });

  const getVendorMenu = () => {
    const menu = vendorMenu.map((item) => {
      if (item.to === routes.EDIT_VENDOR_DETAILS) {
        const to = generatePath(
          routes.EDIT_VENDOR_DETAILS,
          { activeTab: DEFAULT_EDIT_VENDOR_PROFILE_TAB },
        );

        const submenu = item.submenu.map((subItem) => {
          if (subItem.title === VENDOR_REGISTRATION_STEPS_DATA.BASIC_DETAILS.title) {
            const title = (
              <>
                {subItem.title}{' '}
                <span css={step}>&#42;</span>
              </>
            );

            return { ...subItem, title };
          }

          return subItem;
        });

        return {
          ...item,
          to,
          submenu,
        };
      }

      if (item.to === routes.VENDOR_EVENTS_DASHBOARD && Array.isArray(eventMenu)) {
        return { ...item, ...getEventMenu() };
      }

      return item;
    });

    return menu;
  };

  const getRetailerMenu = () => {
    let menu = retailerMenu;

    menu = menu
      .map((item) => {
        if (item.to === routes.EDIT_RETAILER_DETAILS) {
          return {
            ...item,
            to: generatePath(routes.EDIT_RETAILER_DETAILS, { activeTab: DEFAULT_RETAILER_TAB }),
          };
        }

        if (item.to === routes.RETAILER_EVENTS_DASHBOARD && Array.isArray(eventMenu)) {
          return { ...item, ...getEventMenu() };
        }

        return item;
      });

    if (isVendorPage && isCompanyFetchSuccess) {
      const vendorProfileMatch = matchPath(
        location.pathname,
        {
          path: routes.VENDOR_PROFILE_TAB,
          exact: true,
          strict: true,
        },
      );

      const vendorProfileMenuList = Object.values(VENDOR_INFO_TABS);
      const vendorProfileTabs = vendorProfileMenuList.map(({ title, path }) => {
        const pathname = generatePath(
          routes.VENDOR_PROFILE_TAB,
          { slug: companyPublicVendor.slug, tab: path },
        );

        return {
          to: `${pathname}${location.search}`,
          title,
          isSelected: () => vendorProfileMatch && vendorProfileMatch.params.tab === path,
        };
      });

      const defaultTabMenuItem = vendorProfileTabs.find(({ title }) => (
        title === VENDOR_INFO_TABS.BASIC_DETAILS.title
      ));
      const { to: defaultTabPathname } = defaultTabMenuItem;

      menu = [
        {
          title: 'Vendor profile',
          to: defaultTabPathname,
          icon: ProfileIcon,
          submenu: vendorProfileTabs,
          isSelected: () => !!vendorProfileMatch,
        },
        ...menu,
      ];
    }

    return menu;
  };

  const getMenu = () => {
    let menu;

    if (isPublicVendorPage && isCompanyFetchSuccess) {
      menu = getPublicVendorProfileMenu();
    } else if (!isAuthenticated) {
      menu = guestMenu;
    } else if (company.type === companyStatus.VENDOR) {
      menu = getVendorMenu();
    } else if (company.type === companyStatus.RETAILER) {
      menu = getRetailerMenu();
    }

    menu = menu
      .filter(({ isAdminRightsRequired }) => !isAdminRightsRequired || isCompanyAdmin)
      .map((item) => (
        item.isCompanyApproveRequired && !company.adminApprove
          ? { ...item, disabled: true }
          : item
      ));

    return menu;
  };

  const isCompanyNameShown = (isVendorPage || isPublicVendorPage) && isCompanyFetchSuccess;

  return (
    <>
      <NavigationMenu
        menu={getMenu()}
        onDisabledClick={() => setInviteModal(true)}
        vendorCompanyName={
          (isCompanyNameShown && !!companyPublicVendor) && companyPublicVendor.name
        }
        {...rest}
      />
      <GeneralModal
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModal(false)}
        title="Authorization required"
      >
        <InviteNewUser handleClose={() => this.handleInviteUserModal(false)} />
      </GeneralModal>
    </>
  );
};

NavigationMenuContainer.propTypes = {
  // Important: company and user are initialized as empty objects in store if user is
  // unauthenticated. This behavior should be changed in the future.
  user: PropTypes.oneOfType([
    UserType.isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  company: PropTypes.oneOfType([
    PropTypes.shape(CompanyType).isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  companyPublicVendor: PropTypes.oneOfType([
    PropTypes.shape(CompanyType).isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  match: PropTypes.object.isRequired,
  location: LocationType.isRequired,
  eventMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  ),
  isCompanyFetchSuccess: PropTypes.bool.isRequired,
};

NavigationMenuContainer.defaultProps = {
  eventMenu: null,
};

export default connect((state) => ({
  user: state.user.authUser,
  company: state.user.company,
  companyPublicVendor: state.vendorInfo.company,
  isCompanyFetchSuccess: state.vendorInfo.isSuccess,
  eventMenu: state.user.eventMenu,
}))(withRouter(NavigationMenuContainer));
