import { css } from '@emotion/react';

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const submit = css`
  text-align: center;
`;

export const field = (theme) => (
  css`
    display: block;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);
