/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import FieldInputType from 'types/FieldInput';
import FieldMetaType from 'types/FieldMeta';
import container from './styles';

const SelectField = ({
  input,
  meta: { touched, error, submitting },
  label,
  className,
  children,
  id,
  emptyOptionLabel,
  sx,
  disabled,
}) => {
  // TODO: temporary dirty hack for a11y. All select fields should have id and id should be required
  const inputId = id || input.name;

  return (
    <FormControl
      disabled={disabled}
      error={!!((touched || submitting) && error)}
      className={className}
      css={container}
      sx={sx}
    >
      {!!label && (
        <InputLabel
          shrink={!!(emptyOptionLabel || input.value)}
          htmlFor={inputId}
        >
          {label}
        </InputLabel>
      )}
      <NativeSelect id={inputId} {...input} fullWidth>
        {emptyOptionLabel && <option value="">{emptyOptionLabel}</option>}
        {children}
      </NativeSelect>
      {!!((touched || submitting) && error) && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

SelectField.propTypes = {
  sx: PropTypes.object,
  emptyOptionLabel: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  input: FieldInputType.isRequired,
  meta: FieldMetaType.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  className: null,
  emptyOptionLabel: null,
  label: null,
  id: null,
  sx: null,
  disabled: false,
};

export default SelectField;
