/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PageSubheader from 'components/GeneralLayout/PageSubheader';
import VendorCompanyCard from 'components/Shared/VendorCompanyCard';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import routes from 'constants/routes';
import formatSearchResults from 'utils/formatSearchResults';
import useDebounce from 'utils/useDebounce';
import EmptySearchResults from './EmptySearchResults';
import TagSearchInput from './TagSearchInput';
import * as classes from './styles';

const SolutionsSearch = ({
  isPublic,
  onSearch,
  isFallbackShownOnEmptyQuery,
  title,
}) => {
  const [companies, setCompanies] = useState([]);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [inputValue, onInputChange] = useState('');

  const location = useLocation();
  const history = useHistory();

  const handleOpenCompanyProfile = ({ slug }) => {
    const route = isPublic ? routes.PUBLIC_VENDOR_INFO_TAB : routes.VENDOR_PROFILE_TAB;
    const tab = VENDOR_INFO_TABS.BASIC_DETAILS.path;

    const pathname = generatePath(route, { slug, tab });

    const newLocation = location.pathname !== routes.SAVED_SOLUTIONS && inputValue
      ? { pathname, search: new URLSearchParams({ query: inputValue }).toString() }
      : { pathname };

    history.push(newLocation);
  };

  const handleSolutionSearch = useCallback(async (query) => {
    try {
      const data = await onSearch(query);
      const formattedData = formatSearchResults(data);
      setCompanies(formattedData);
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [onSearch]);

  const debouncedSearch = useDebounce(handleSolutionSearch);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');

    if (query) {
      onInputChange(query);
    }
  }, [location.search]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    debouncedSearch(inputValue);
  }, [debouncedSearch, inputValue]);

  return (
    <>
      <PageSubheader
        renderChildren={() => (
          <div css={[classes.verticalGutters, classes.getContainerStyles(isPublic)]}>
            <Typography variant="h1" css={classes.title}>{title}</Typography>
            <TagSearchInput
              value={inputValue}
              onSetValue={onInputChange}
              onSelect={onInputChange}
            />
          </div>
        )}
      />

      <div css={classes.getContainerStyles(isPublic)}>
        {isError && <Typography variant="body2">Failed to search</Typography>}

        {isLoading && (
          <div css={classes.spinnerContainer}>
            <CircularProgress />
          </div>
        )}

        {
          !isError
          && !isLoading
          && (!companies.length && ((!inputValue && isFallbackShownOnEmptyQuery) || !!inputValue))
          && (
            <EmptySearchResults css={[classes.noResults, classes.bottomGutter]} />
          )
        }

        {!isError && !isLoading && !!companies.length && (
          <div css={classes.bottomGutter}>
            {companies.map((company) => (
              <VendorCompanyCard
                key={company.id}
                css={classes.card}
                company={company}
                onClick={handleOpenCompanyProfile}
                searchPathname={location.pathname}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

SolutionsSearch.propTypes = {
  isPublic: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  isFallbackShownOnEmptyQuery: PropTypes.bool,
};

SolutionsSearch.defaultProps = {
  isFallbackShownOnEmptyQuery: false,
};

export default SolutionsSearch;
