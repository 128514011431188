import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DropDownInput from 'components/UI/DropDownInput';
import cloudHostingOptions from 'constants/cloudHostingOptions';

const options = Object.values(cloudHostingOptions);

const CloudHostingField = ({ className }) => (
  <Field
    className={className}
    label="Which of the following is your solution hosted on?"
    fullWidth
    component={DropDownInput}
    name="cloudHosting"
  >
    {options.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </Field>
);

CloudHostingField.propTypes = {
  className: PropTypes.string,
};

CloudHostingField.defaultProps = {
  className: null,
};

export default CloudHostingField;
