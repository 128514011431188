import { css } from '@emotion/react';

export const option = (theme) => (
  css`
    border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};
  `
);

export const popupFooter = (theme) => (
  css`
    border-top: 1px solid ${theme.primaryPalette.midLightGrey};
    padding: ${theme.spacing(0.75)} ${theme.spacing(2)};
  `
);

export const openModalButton = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      height: initial !important;
      padding-right: ${theme.spacing(2)} !important;
      padding-left: ${theme.spacing(2)} !important;
    }
  `
);
