/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import QuestionHints from '../QuestionHints';
import * as classes from './styles';

const QUESTION_HINTS_ID = 'yes-no-question-hints-modal';

const QuestionHintsModal = ({
  handleAddHintedRequirements,
  tags,
  addedQuestions,
  isOpen,
  onClose,
}) => {
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby={QUESTION_HINTS_ID}
      fullScreen={isMobileDevice}
      css={classes.dialog}
      keepMounted
    >
      <DialogTitle css={classes.dialogTitle}>
        <Typography component="span" variant="h3" id={QUESTION_HINTS_ID}>
          Requirements library
        </Typography>
        <IconButton
          aria-label="Close modal"
          onClick={onClose}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <QuestionHints
        tags={tags}
        addedQuestions={addedQuestions}
        onSubmit={handleAddHintedRequirements}
        onClose={onClose}
        isDisplayed={isOpen}
      />
    </Dialog>
  );
};

QuestionHintsModal.propTypes = {
  handleAddHintedRequirements: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
  addedQuestions: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

QuestionHintsModal.defaultProps = {
  tags: [],
  addedQuestions: [],
};

export default QuestionHintsModal;
