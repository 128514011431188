/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/Form/TextFieldInput';
import GeneralModal from 'components/UI/GeneralModal';
import SubmitButton from 'components/UI/SubmitButton';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import buttonStyles from './styles';

const EditChallengeReviewer = ({
  isOpen,
  onClose,
  onSubmit,
  isNotified,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, submitting, reset }) => (
      <form onSubmit={handleSubmit}>
        <GeneralModal
          title="Edit saved reviewer"
          isOpen={isOpen}
          onClose={onClose}
          TransitionProps={{ onExited: reset }}
        >
          {isNotified && (
            <Typography sx={{ mb: 2 }} variant="body1">
              Are you sure you want to update this email address?
            </Typography>
          )}
          <Field
            sx={{ mb: 2 }}
            name="email"
            label="Email"
            component={TextFieldInput}
            validate={composeValidators(required, validateEmail, validateIsCorporateEmailDomain)}
          />
          <SubmitButton
            css={buttonStyles}
            onClick={handleSubmit}
            isLoading={submitting}
            type="submit"
          >
            Submit
          </SubmitButton>
        </GeneralModal>
      </form>
    )}
  />
);

EditChallengeReviewer.propTypes = {
  isNotified: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

EditChallengeReviewer.defaultProps = {
  initialValues: null,
};

export default EditChallengeReviewer;
