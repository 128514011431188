import PropTypes from 'prop-types';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PageBody from 'components/UI/PageBody';
import routes from 'constants/routes';
import getRedirectUrlForVendorChallenge from 'utils/getRedirectUrlForVendorChallenge';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import { EventChallengeType } from 'types/Challenge';
import ChallengeResponseFormPageContent from '../ChallengeResponseFormPageContent';

const ChallengeResponseFormPage = ({
  company,
  challenge,
  challengeResponse,
  isShortlisted,
  setPageProps,
}) => {
  const location = useLocation();

  const redirectUrl = getRedirectUrlForVendorChallenge(
    challenge,
    challengeResponse,
    isShortlisted,
    company,
  );

  if (redirectUrl && redirectUrl !== location.pathname) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <PageBody>
      <Breadcrumbs sx={{ mb: 5 }}>
        <Link to={routes.MAIN}>Home</Link>
        <Link to={routes.VENDOR_CHALLENGE_DASHBOARD}>RFIs</Link>
        <Link to={location}>Response on {challenge.name}</Link>
      </Breadcrumbs>

      <ChallengeResponseFormPageContent
        company={company}
        challengeResponse={challengeResponse}
        challenge={challenge}
        setPageProps={setPageProps}
      />
    </PageBody>
  );
};

ChallengeResponseFormPage.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  challenge: PropTypes.oneOfType([ChallengeSynopsisType, EventChallengeType]).isRequired,
  challengeResponse: PropTypes.object,
  isShortlisted: PropTypes.bool.isRequired,
  setPageProps: PropTypes.func.isRequired,
};

ChallengeResponseFormPage.defaultProps = {
  challengeResponse: null,
};

export default ChallengeResponseFormPage;
