import { css } from '@emotion/react';

export const fieldsContainer = css`
  display: flex;
  align-items: baseline;
`;

export const codeField = (theme) => (
  css`
    width: 100px;
    margin-right: ${theme.spacing(1)};
  `
);

export const number = css`
  flex: 1;
`;
