/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import * as classes from './styles';

const CompanyRegistrationBanner = ({
  className,
  list,
  additionalInfo,
  title,
}) => (
  <div className={className} css={classes.container}>
    <ReportProblemOutlined css={classes.icon} />
    <div>
      <Typography css={classes.title} variant="h2">{title}</Typography>
      {!!additionalInfo && <p css={classes.content}>{additionalInfo}</p>}
      {!!list.length && (
        <div>
          {list.length === 1 ? (
            <p css={classes.content}>{list[0]}</p>
          ) : (
            <ol css={[classes.list, classes.content]}>
              {list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          )}
        </div>
      )}
    </div>
  </div>
);

CompanyRegistrationBanner.propTypes = {
  className: PropTypes.string,
  additionalInfo: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string.isRequired),
};

CompanyRegistrationBanner.defaultProps = {
  className: null,
  additionalInfo: null,
  list: [],
};

export default CompanyRegistrationBanner;
