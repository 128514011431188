import { css } from '@emotion/react';

export const main = (theme) => (
  css`
    max-width: ${theme.layout.publicPageContent};
    margin: 0 auto;
  `
);

export const head = (theme) => (
  css`
    display: flex;
    margin-bottom: ${theme.spacing(4)};

    ${theme.breakpoints.up('sm')} {
      justify-content: space-between;
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `
);

export const section = (theme) => (
  css`
    &:not(:last-child) {
       margin-bottom: ${theme.spacing(4)};
    }
  `
);

export const list = (theme) => (
  css`
    margin: 10px 0;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 20px;
    }
  `
);

export const highlight = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);

export const textBlock = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);
