const challengeLogTypes = {
  REJECT: 'reject',
  END_PARTICIPATION: 'endParticipation',
  REQUEST_PARTICIPATION: 'requestParticipation',
  PAUSE_CHALLENGE: 'pauseChallenge',
  COMPLETE_CHALLENGE: 'completeChallenge',
  CANCEL_CHALLENGE: 'cancelChallenge',
};

module.exports = challengeLogTypes;
