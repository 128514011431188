/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'constants/routes';
import { EMAIL_SUPPORT } from 'constants/contacts';
import * as classes from './styles';

const FooterLinks = ({ className }) => (
  <div css={classes.container} className={className}>
    <Link css={classes.link} href={`mailto:${EMAIL_SUPPORT}`} variant="body2">Contact us</Link>
    <Link
      css={classes.link}
      to={routes.VENDOR_PAYMENTS}
      component={RouterLink}
      variant="body2"
    >
      Pricing
    </Link>
    <Link
      css={classes.link}
      to={routes.PRIVACY}
      component={RouterLink}
      variant="body2"
    >
      Privacy policy
    </Link>
    <Link
      css={classes.link}
      to={routes.TERMS_AND_CONDITIONS}
      component={RouterLink}
      variant="body2"
    >
      Terms &amp; conditions
    </Link>
  </div>
);

FooterLinks.propTypes = {
  className: PropTypes.string,
};

FooterLinks.defaultProps = {
  className: null,
};

export default FooterLinks;
