import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import pageTitles from 'constants/pageTitles';

const Head = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <link rel="manifest" href="/manifest.json" />
    <link rel="shortcut icon" href="/favicons/favicon.png" />
    <link href="/static/fonts.css" rel="stylesheet" type="text/css" />
    <title>{title}</title>
  </Helmet>
);

Head.propTypes = {
  title: PropTypes.string,
};

Head.defaultProps = {
  title: pageTitles.DEFAULT,
};

export default Head;
