import { css } from '@emotion/react';

const buttons = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
  `
);

export default buttons;
