/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
  Copy20Regular,
  Delete20Regular,
  Edit20Regular,
  Send20Regular,
} from '@fluentui/react-icons';
import UserType from 'types/User';
import InvitedUserType from 'types/InvitedUser';
import * as classes from './styles';

const UserCard = ({
  className,
  user,
  renderUserRole,
  onResendInvitation,
  getEditUserPageUrl,
  onDeleteUser,
  onCopyEmailToClipboard,
  onCopyPhoneNumberToClipboard,
  isUserRegistered,
}) => {
  const role = renderUserRole(user);

  return (
    <div css={classes.card} className={className}>
      <Typography css={[classes.topRow, classes.row]} variant="subtitle2" component="div">
        <div css={[classes.textWithOverflowContainer]} onClick={() => onCopyEmailToClipboard(user)}>
          <span css={classes.textWithOverflow}>
            {user.invitedUserEmail || user.email}
          </span>
          <Copy20Regular css={[classes.icon, classes.copyIcon]} />
        </div>

        <div css={classes.iconsWrap}>
          {isUserRegistered ? (
            <Link to={getEditUserPageUrl(user)}>
              <IconButton aria-label="open-edit-user-page" css={classes.icon}>
                <Edit20Regular />
              </IconButton>
            </Link>
          ) : (
            <Tooltip placement="top-start" title="Resend invitation">
              <IconButton
                aria-label="resend-user-invitation"
                css={classes.icon}
                onClick={() => onResendInvitation(user.id)}
              >
                <Send20Regular />
              </IconButton>
            </Tooltip>
          )}

          <IconButton
            aria-label="delete-user"
            css={classes.deleteIcon}
            onClick={() => onDeleteUser(user.id)}
          >
            <Delete20Regular />
          </IconButton>
        </div>
      </Typography>

      {isUserRegistered && (
        <div css={classes.row}>
            <div css={classes.column}>
              <Typography css={classes.caption} variant="caption1">Name</Typography>
              <Typography variant="body2">{user.firstName} {user.lastName}</Typography>
            </div>
        </div>
      )}

      {!!(user.phoneNumber || role) && (
        <div css={[classes.row, classes.splittedRow]}>
          {!!user.phoneNumber && (
            <div
              css={[classes.column]}
              onClick={() => onCopyPhoneNumberToClipboard(user)}
            >
              <Typography css={classes.caption} variant="caption1">Phone</Typography>
              <Typography variant="body2" css={classes.textWithOverflowContainer}>
                <span css={classes.textWithOverflow}>
                  {user.phoneNumber.code} {user.phoneNumber.number}
                </span>
                <Copy20Regular css={[classes.icon, classes.copyIcon]} />
              </Typography>
            </div>
          )}

          {!!role && (
            <div css={classes.column}>
              <Typography css={classes.caption} variant="caption1">Role</Typography>
              <Typography variant="body2">{renderUserRole(user)}</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.oneOfType([
    UserType.isRequired,
    InvitedUserType.isRequired,
  ]).isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  getEditUserPageUrl: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  renderUserRole: PropTypes.func.isRequired,
  onCopyEmailToClipboard: PropTypes.func.isRequired,
  onCopyPhoneNumberToClipboard: PropTypes.func.isRequired,
  isUserRegistered: PropTypes.bool.isRequired,
};

UserCard.defaultProps = {
  className: null,
};

export default UserCard;
