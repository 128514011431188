/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import checkHasAuthToken from 'utils/checkHasAuthToken';
import axios from 'api/axios';
import { AuthContext } from 'containers/AuthProvider';
import GeneralModal from 'components/UI/GeneralModal';
import { localStorageAuthKey } from 'constants/storageKeys';
import LocationType from 'types/Location';
import { getRedirectUnauthorizedUser } from 'utils/getRedirectPath';
import useLogout from 'utils/useLogout';
import httpResponseCodes from 'constants/httpResponseCodes';
import buttonsStyles from './styles';

const UNAUTHORIZED_RESPONSE_ERROR_CODE = 'AUTHORIZATION_REQUIRED';
const CHECK_AUTH_TOKEN_TIMEOUT = 60000;

let timeout;

const ExpiredAuthModal = ({ location }) => {
  const { isSessionExpired, setSessionStatus } = useContext(AuthContext);
  const handleLogout = useLogout();

  const handleCheckTokenExpiration = useCallback(() => {
    const hasAuthToken = checkHasAuthToken();
    const isLoggedIn = localStorage.getItem(localStorageAuthKey) === 'true';

    if (hasAuthToken) {
      timeout = setTimeout(handleCheckTokenExpiration, CHECK_AUTH_TOKEN_TIMEOUT);
    } else {
      clearTimeout(timeout);

      if (isLoggedIn && !isSessionExpired) {
        setSessionStatus(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSessionActive = checkHasAuthToken();

  useEffect(() => {
    if (isSessionActive) {
      handleCheckTokenExpiration();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionActive]);

  useEffect(() => {
    const unauthorizedErrorInterceptor = axios.interceptors.response.use(undefined, (error) => {
      const statusCode = get(error, 'response.status');
      const errorCode = get(error, 'response.data.error.code');

      if (statusCode === httpResponseCodes.UNAUTHORIZED
        && errorCode === UNAUTHORIZED_RESPONSE_ERROR_CODE
        && !isSessionExpired
      ) {
        setSessionStatus(true);
      }

      return Promise.reject(error);
    });

    return () => axios.interceptors.response.eject(unauthorizedErrorInterceptor);
  }, [isSessionExpired, setSessionStatus]);

  const handleClose = () => {
    setSessionStatus(false);

    const redirectUrl = getRedirectUnauthorizedUser(location);
    handleLogout(redirectUrl);
  };

  return (
    <GeneralModal isOpen={isSessionExpired} onClose={handleClose} title="Your session has expired">
      <Typography sx={{ mb: 2 }} variant="body1">
        You have been logged out automatically because your session has expired. Please login again
        to continue.
      </Typography>
      <DialogActions css={buttonsStyles}>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </GeneralModal>
  );
};

ExpiredAuthModal.propTypes = {
  location: LocationType.isRequired,
};

export default withRouter(ExpiredAuthModal);
