import PropTypes from 'prop-types';

const ProfileIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.75C6 9.50736 7.00736 8.5 8.25 8.5C9.49264 8.5 10.5 9.50736 10.5 10.75C10.5 11.9926 9.49264 13 8.25 13C7.00736 13 6 11.9926 6 10.75ZM8.25 10C7.83579 10 7.5 10.3358 7.5 10.75C7.5 11.1642 7.83579 11.5 8.25 11.5C8.66421 11.5 9 11.1642 9 10.75C9 10.3358 8.66421 10 8.25 10ZM8.25 14C7.00736 14 6 15.0074 6 16.25C6 17.4926 7.00736 18.5 8.25 18.5C9.49264 18.5 10.5 17.4926 10.5 16.25C10.5 15.0074 9.49264 14 8.25 14ZM7.5 16.25C7.5 15.8358 7.83579 15.5 8.25 15.5C8.66421 15.5 9 15.8358 9 16.25C9 16.6642 8.66421 17 8.25 17C7.83579 17 7.5 16.6642 7.5 16.25ZM12 10.75C12 10.3358 12.3358 10 12.75 10H17.25C17.6642 10 18 10.3358 18 10.75C18 11.1642 17.6642 11.5 17.25 11.5H12.75C12.3358 11.5 12 11.1642 12 10.75ZM12.75 15.5C12.3358 15.5 12 15.8358 12 16.25C12 16.6642 12.3358 17 12.75 17H17.25C17.6642 17 18 16.6642 18 16.25C18 15.8358 17.6642 15.5 17.25 15.5H12.75ZM6 6.75C6 6.33579 6.33579 6 6.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H6.75C6.33579 7.5 6 7.16421 6 6.75ZM6.25 3C4.45507 3 3 4.45507 3 6.25V17.75C3 19.5449 4.45507 21 6.25 21H17.75C19.5449 21 21 19.5449 21 17.75V6.25C21 4.45507 19.5449 3 17.75 3H6.25ZM4.5 6.25C4.5 5.2835 5.2835 4.5 6.25 4.5H17.75C18.7165 4.5 19.5 5.2835 19.5 6.25V17.75C19.5 18.7165 18.7165 19.5 17.75 19.5H6.25C5.2835 19.5 4.5 18.7165 4.5 17.75V6.25Z"
      fill="currentColor"
    />
  </svg>
);

ProfileIcon.propTypes = {
  className: PropTypes.string,
};

ProfileIcon.defaultProps = {
  className: null,
};

export default ProfileIcon;
