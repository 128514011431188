import { css } from '@emotion/react';

const wrapper = (theme) => (
  css`
    ${theme.breakpoints.up('md')} {
      padding: ${theme.spacing(4)} ${theme.spacing(5)} ${theme.spacing(8)};
    }

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(5)};
    }
  `
);

export default wrapper;
