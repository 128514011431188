import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';

const ChallengeResponseSuccess = () => (
  <div>
    <Typography sx={{ mb: 2 }} variant="body1">
      Your response was successfully submitted.
    </Typography>
    <Button to={routes.MAIN} component={Link}>Back to home</Button>
  </div>
);

export default ChallengeResponseSuccess;
