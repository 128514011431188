/** @jsxImportSource @emotion/react */
import ErrorContainer from 'components/UI/ErrorContainer';
import containerStyles from './styles';

const PageNotFound = () => (
  <ErrorContainer
    css={containerStyles}
    title="404"
    subtitle="Page Not Found"
    description="The page you requested was not found."
  />
);

export default PageNotFound;
