import omit from 'lodash/omit';
import pick from 'lodash/pick';
import reviewAPI from 'api/review';
import voteReviewAPI from 'api/voteReview';
import { addReviewToVendor as addReviewAction } from 'store/actions/vendorInfo';
import * as actions from '../actions';
import { sendErrorNotification } from '../modules/notifications';

export const addVendorReview = (review) => async (dispatch, getState) => {
  const state = getState();

  const reviewedCompany = pick(state.vendorInfo.company, ['id', 'logo', 'name']);

  const reviewer = omit(state.user.authUser, ['invitedRoles', 'ratingsReviews', 'company']);
  const reviewerCompany = pick(state.user.authUser.company, ['id', 'name', 'smallLogoLink']);

  const { data } = await reviewAPI.create(review);

  const updatedReview = {
    ...data,
    user: {
      ...reviewer,
      company: reviewerCompany,
    },
    company: reviewedCompany,
    voteReviews: [],
  };

  dispatch(addReviewAction(updatedReview));
};

export const addReviewVote = (body) => async (dispatch) => {
  try {
    const { data } = await voteReviewAPI.put(body);
    dispatch(actions.upsertReviewVote(data));
  } catch (error) {
    dispatch(sendErrorNotification(error));
  }
};
