const VENDOR_INFO_TABS = {
  BASIC_DETAILS: {
    path: 'basic-details',
    title: 'Basic details',
  },
  SOLUTION: {
    path: 'solution',
    title: 'Solution',
  },
  DETAILS: {
    path: 'technical-details',
    title: 'Technical details',
  },
  ENDORSEMENTS: {
    path: 'endorsements',
    title: 'Endorsements',
  },
  COMPANY: {
    path: 'company',
    title: 'Company',
  },
  CONTACT: {
    path: 'ratings-reviews',
    title: 'Ratings and reviews',
  },
};

export default VENDOR_INFO_TABS;
