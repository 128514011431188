import { css } from '@emotion/react';

const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export default field;
