import { css } from '@emotion/react';

export const pageBody = css`
  flex: 1;
`;

export const breadcrumb = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const head = css`
  display: flex;
  justify-content: space-between;
`;

export const titleWrap = (theme) => (
  css`
    position: relative;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const backButton = css`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
`;

export const form = (theme) => (
  css`
    padding: ${theme.spacing(3)};
    border-radius: ${theme.spacing(1)};
    box-shadow: ${theme.boxShadows.large};

    ${theme.breakpoints.up('sm')} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.spacing(3)} ${theme.spacing(3)};
      max-width: 850px;
      margin-bottom: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(3)};
    }
  `
);

export const emailField = (theme) => (
  css`
    display: flex;
    align-items: baseline;

    ${theme.breakpoints.up('sm')} {
      padding-bottom: ${theme.spacing(4)};
    }
  `
);

export const phoneField = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `
);

export const roleField = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(5)};
      width: 100%;
    }
  `
);

export const button = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `
);

export const bottomBar = (theme) => (
  css`
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
);

export const usernameField = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex: 1;
    }
  `
);

export const domainField = css`
  max-width: 200px;

  .MuiNativeSelect-select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
