import userAPI from 'api/user';
import companyAPI from 'api/company';
import invitedUserAPI from 'api/invitedUser';
import { sendErrorNotification, sendSuccessNotification } from './notifications';

export const ADD_INVITED_USER = 'ADD_INVITED_USER';

export const REMOVE_INVITED_USER = 'REMOVE_INVITED_USER';

export const REMOVE_COMPANY_USER = 'REMOVE_COMPANY_USER';

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

const addInvitedUser = (user) => ({ type: ADD_INVITED_USER, user });
const deleteInvitedUser = (id) => ({ type: REMOVE_INVITED_USER, id });
const removeCompanyUser = (id) => ({ type: REMOVE_COMPANY_USER, id });
const updateUserList = (user) => ({ type: UPDATE_USER_LIST, user });

// TODO: extract related business logic to reducer from user reducer

export const inviteNewUser = (body) => async (dispatch) => {
  try {
    const { data: invitedUser } = await invitedUserAPI.create(body);

    dispatch(sendSuccessNotification());
    dispatch(addInvitedUser(invitedUser));
  } catch (error) {
    dispatch(sendErrorNotification(error));
  }
};

export const removeInvitedUser = (userId) => async (dispatch) => {
  try {
    await invitedUserAPI.deleteById(userId);

    dispatch(sendSuccessNotification('User was removed successfully'));
    dispatch(deleteInvitedUser(userId));
  } catch (error) {
    dispatch(sendErrorNotification(error));
  }
};

export const removeActiveUser = (companyId, user) => async (dispatch) => {
  await companyAPI.deleteActiveUserFromCompany(companyId, user.id);

  dispatch(sendSuccessNotification('User was removed successfully'));
  dispatch(removeCompanyUser(user.id));
};

export const approveUserRegistration = (userId) => async (dispatch) => {
  try {
    const user = await userAPI.approveParticipationInCompany(userId);
    dispatch(sendSuccessNotification('User was approved'));
    dispatch(updateUserList(user));
  } catch (error) {
    dispatch(sendErrorNotification(error, 'Failed to approve user'));
  }
};

export const rejectRegistrationRequest = (userId) => async (dispatch) => {
  try {
    const user = await userAPI.rejectParticipationInCompany(userId);

    dispatch(sendSuccessNotification('User request was removed'));
    dispatch(updateUserList(user));
  } catch (error) {
    dispatch(sendErrorNotification(error));
  }
};
