import PropTypes from 'prop-types';
import companyStatus from 'constants/companyStatus';

// TODO: complete all fields of type
const CompanyType = {
  adminApprove: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(companyStatus)).isRequired,
  userRequestApprove: PropTypes.bool,
  number: PropTypes.string,
  // Important: These props are mandatory in new version of company profile, but this fields were
  // optional before and they can be absent in old profiles
  companyWebsite: PropTypes.string,
  logo: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default CompanyType;
