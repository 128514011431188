import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import PageBody from 'components/UI/PageBody';
import AllChallengesContent from 'components/Shared/AllChallengesContent';
import challengeResponseAPI from 'api/challengeResponse';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import requestStatus from 'constants/challengeParticipationRequestStatus';
import challengeStatus from 'constants/challengeStatus';
import routes from 'constants/routes';
import { isExpired, isClosed } from 'utils/challengeCheckers';
import formatChallengeDeadline from 'utils/formatChallengeDeadline';
import 'styles/ChallengeDashboardTable.scss';
import DashboardPageContainer from '../DashboardPageContainer';
import { getChallengeResponse, getParticipationRequest } from '../helpers';
import { getChallengeResponseFormUrl, getChallengeUrl, getSavedChallengeResponseUrl } from '../formatChallengeLinks';
import ChallengeDashboardItem from '../types';
import './AllChallengesPage.scss';

const breadcrumbs = [
  { title: 'Home', to: routes.MAIN },
  { title: 'RFIs', to: routes.VENDOR_CHALLENGE_DASHBOARD },
];

const challengeStates = {
  ALL: 'All',
  REQUESTED_PARTICIPATION: 'Pending approval',
  INVITED: 'Invited',
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  DECLINED: 'Declined',
  PAUSED: 'On hold',
  IN_REVISION: 'Revising',
  CLOSED: 'Closed',
  REQUESTED_RESTORE_PARTICIPATION: 'Requesting involvement again',
};

const statesList = Object.values(challengeStates);

const AllChallengesPage = ({ challenges, setPageProps }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleRequestRestoreParticipation = (challenge) => (
    challengeResponseAPI.requestChallengeParticipation(challenge.id)
      .then(() => {
        const updatedChallenges = challenges.map((challengeItem) => {
          if (challengeItem.id === challenge.id) {
            return {
              ...challengeItem,
              challengeResponses: [
                {
                  ...challengeItem.challengeResponses[0],
                  isParticipationRequestSent: true,
                },
              ],
            };
          }

          return challengeItem;
        });

        setPageProps({ challenges: updatedChallenges });
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to send request', { variant: 'error' }))
  );

  const isDraftResponse = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge) || challenge.status === challengeStatus.PAUSED)
      && (
        challengeResponse.status === challengeResponseStatus.DRAFT
        || challengeResponse.status === challengeResponseStatus.ACCEPTED
      )
    );
  };

  const isRequestedParticipationInChallenge = (challenge) => {
    const participationRequest = getParticipationRequest(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge))
      && participationRequest.status === requestStatus.PENDING
    );
  };

  const isInvitedToChallenge = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);
    const participationRequest = getParticipationRequest(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge) || challenge.status === challengeStatus.PAUSED)
      && !challengeResponse.status
      && !(
        participationRequest.status === requestStatus.PENDING
        || participationRequest.status === requestStatus.REJECTED
      )
    );
  };

  const isRevisingResponse = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge) || challenge.status === challengeStatus.PAUSED)
      && challengeResponse.status === challengeResponseStatus.UNDER_REVISION
    );
  };

  const isParticipationDeclined = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);
    const participationRequest = getParticipationRequest(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge))
      && (
        participationRequest.status === requestStatus.REJECTED
        || challengeResponse.status === challengeResponseStatus.REMOVED_FROM_SHORTLIST
        || (
          challengeResponse.status === challengeResponseStatus.REJECTED
          && !challengeResponse.isParticipationRequestSent
        )
      )
    );
  };

  const isRequestedRestoreParticipation = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    return (
      !(isExpired(challenge) || isClosed(challenge))
      && challengeResponse.status === challengeResponseStatus.REJECTED
      && challengeResponse.isParticipationRequestSent
    );
  };

  /**
   * Condition: a challenge response was sent and challenge isn't ended
   */
  const isActiveChallenge = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    return (
      !isClosed(challenge)
      && (
        challengeResponse.status === challengeResponseStatus.SUBMITTED
        || (
          challengeResponse.status === challengeResponseStatus.UNDER_REVISION
          && isExpired(challenge)
        )
      )
    );
  };

  const isParticipationClosed = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    return (
      isClosed(challenge)
      || (
        isExpired(challenge)
        && !(
          challengeResponse.status === challengeResponseStatus.SUBMITTED
          || challengeResponse.status === challengeResponseStatus.UNDER_REVISION
        )
      )
    );
  };

  const isPausedParticipation = (challenge) => challenge.status === challengeStatus.PAUSED;

  const isMatchedByState = (challengeState, challenge) => {
    switch (challengeState) {
      case challengeStates.ALL:
        return true;
      case challengeStates.ACTIVE:
        return isActiveChallenge(challenge);
      case challengeStates.CLOSED:
        return isParticipationClosed(challenge);
      case challengeStates.INVITED:
        return isInvitedToChallenge(challenge);
      case challengeStates.REQUESTED_PARTICIPATION:
        return isRequestedParticipationInChallenge(challenge);
      case challengeStates.DRAFT:
        return isDraftResponse(challenge);
      case challengeStates.IN_REVISION:
        return isRevisingResponse(challenge);
      case challengeStates.DECLINED:
        return isParticipationDeclined(challenge);
      case challengeStates.PAUSED:
        return isPausedParticipation(challenge);
      case challengeStates.REQUESTED_RESTORE_PARTICIPATION:
        return isRequestedRestoreParticipation(challenge);
      default:
        return false;
    }
  };

  const getChallengeDeadline = (challenge) => {
    const formattedDeadline = formatChallengeDeadline(challenge);

    return `${isExpired(challenge) ? 'Expired' : 'Expires'} ${formattedDeadline}`;
  };

  const getChallengeState = (challenge) => {
    const matchedState = statesList.find(
      (state) => state !== challengeStates.ALL && isMatchedByState(state, challenge),
    );

    return matchedState || 'Unknown';
  };

  const renderButton = (challenge) => {
    const challengeResponse = getChallengeResponse(challenge);

    if (
      !(isExpired(challenge) || isClosed(challenge) || challenge.status === challengeStatus.PAUSED)
      && challengeResponse.status === challengeResponseStatus.REJECTED
      && !challengeResponse.isParticipationRequestSent
    ) {
      return (
        <Button onClick={() => handleRequestRestoreParticipation(challenge)}>
          Request participation
        </Button>
      );
    }

    if (isInvitedToChallenge(challenge)) {
      return (
        <Button component={Link} to={getChallengeResponseFormUrl(challenge)}>
          View RFI
        </Button>
      );
    }

    if (isDraftResponse(challenge) || isRevisingResponse(challenge)) {
      return (
        <Button component={Link} to={getChallengeResponseFormUrl(challenge)}>
          Edit response
        </Button>
      );
    }

    if (
      challengeResponse.status === challengeResponseStatus.SUBMITTED
      || challengeResponse.status === challengeResponseStatus.REJECTED
      || (
        isExpired(challenge)
        && challengeResponse.status === challengeResponseStatus.UNDER_REVISION
      )
    ) {
      return (
        <Button component={Link} to={getSavedChallengeResponseUrl(challenge)}>View response</Button>
      );
    }

    if (
      /**
       * Important: at this moment we don't show a challenge response if a challenge doesn't
       * accepting new responses and a challenge response is incomplete. In the future, UI should be
       * able to display these challenges.
       * TODO: remove it
       */
      (isExpired(challenge) || isClosed(challenge) || challenge.status === challengeStatus.PAUSED)
      || (
        !(
          challengeResponse.status === challengeResponseStatus.SUBMITTED
          || challengeResponse.status === challengeResponseStatus.UNDER_REVISION
        )
      )
    ) {
      return <Button component={Link} to={getChallengeUrl(challenge)}>View RFI</Button>;
    }

    return null;
  };

  const renderChallenge = (challenge) => (
    <div key={challenge.id} className="challenge-dashboard-item">
      <div className={clsx(
        'challenge-dashboard-item__content',
        'vendor-challenge',
      )}>
        <Typography className="vendor-challenge__name" variant="h3">
          {challenge.name}
        </Typography>
        {!!challenge.event && (
          <Typography variant="body1" className="vendor-challenge__event-name">
            {challenge.event.name}
          </Typography>
        )}
        <Typography className="vendor-challenge__deadline" variant="body1">
          {getChallengeDeadline(challenge)}
        </Typography>
        <Typography className="vendor-challenge__state" variant="body1">
          {getChallengeState(challenge)}
        </Typography>
        <div className="vendor-challenge__cta-container">
          {renderButton(challenge)}
        </div>
      </div>
    </div>
  );

  return (
    <PageBody>
      <DashboardPageContainer breadcrumbs={breadcrumbs}>
        <Typography sx={{ mb: 5 }} variant="h1">RFIs</Typography>
        <AllChallengesContent
          defaultState={challengeStates.ALL}
          isMatchedByState={isMatchedByState}
          challenges={challenges}
          renderChallenge={renderChallenge}
          challengeStates={challengeStates}
          />
      </DashboardPageContainer>
    </PageBody>
  );
};

AllChallengesPage.propTypes = {
  challenges: PropTypes.arrayOf(ChallengeDashboardItem.isRequired).isRequired,
  setPageProps: PropTypes.func.isRequired,
};

export default AllChallengesPage;
