import PropTypes from 'prop-types';

const PeopleIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.6 7.2C4.92375 7.2 6 6.12375 6 4.8C6 3.47625 4.92375 2.4 3.6 2.4C2.27625 2.4 1.2 3.47625 1.2 4.8C1.2 6.12375 2.27625 7.2 3.6 7.2ZM20.4 7.2C21.7238 7.2 22.8 6.12375 22.8 4.8C22.8 3.47625 21.7238 2.4 20.4 2.4C19.0763 2.4 18 3.47625 18 4.8C18 6.12375 19.0763 7.2 20.4 7.2ZM21.6 8.4H19.2C18.54 8.4 17.9438 8.66625 17.5088 9.0975C19.02 9.92625 20.0925 11.4225 20.325 13.2H22.8C23.4638 13.2 24 12.6637 24 12V10.8C24 9.47625 22.9238 8.4 21.6 8.4ZM12 8.4C14.3213 8.4 16.2 6.52125 16.2 4.2C16.2 1.87875 14.3213 0 12 0C9.67875 0 7.8 1.87875 7.8 4.2C7.8 6.52125 9.67875 8.4 12 8.4ZM14.88 9.6H14.5688C13.7888 9.975 12.9225 10.2 12 10.2C11.0775 10.2 10.215 9.975 9.43125 9.6H9.12C6.735 9.6 4.8 11.535 4.8 13.92V15C4.8 15.9937 5.60625 16.8 6.6 16.8H17.4C18.3938 16.8 19.2 15.9937 19.2 15V13.92C19.2 11.535 17.265 9.6 14.88 9.6ZM6.49125 9.0975C6.05625 8.66625 5.46 8.4 4.8 8.4H2.4C1.07625 8.4 0 9.47625 0 10.8V12C0 12.6637 0.53625 13.2 1.2 13.2H3.67125C3.9075 11.4225 4.98 9.92625 6.49125 9.0975Z"
      fill="currentColor"
    />
  </svg>
);

PeopleIcon.propTypes = {
  className: PropTypes.string,
};

PeopleIcon.defaultProps = {
  className: null,
};

export default PeopleIcon;
