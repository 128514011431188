import { css } from '@emotion/react';

export const titleWrap = (theme) => (
  css`
    position: relative;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const backButton = css`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
`;
