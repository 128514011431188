import { combineReducers } from 'redux';
import { reducer } from 'redux-form';
import notificationReducer from '../modules/notifications';
import savedVendorsReducer from '../modules/savedVendors';
import userReducer from './user';
import vendorInfoReducers from './vendorInfo';

const appReducer = combineReducers({
  user: userReducer,
  vendorInfo: vendorInfoReducers,
  notifications: notificationReducer,
  form: reducer,
  savedVendors: savedVendorsReducer,
});

export default appReducer;
