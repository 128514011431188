export const LICENCE_PLAN_OPTIONS = [
  'Ability to create and manage your profile.',
  'Intro call to explain how to get the most out of the platform.',
  'Tier 1 vetting - via a 30 minute webchat.',
  'Access to our consumer brand management suite - Endorsements, Top 10 picker and Sales Companion.',
];

export const paypalButtonConfig = {
  ID: process.env.REACT_APP_PAYPAL_BUTTON_ID,
  SUBMIT_URL: 'https://www.paypal.com/cgi-bin/webscr',
  ACTION: '_s-xclick',
  BUTTON_SRC: 'http://www.validify.co.uk',
};

export const GO_CARDLESS_LINK = `https://pay.gocardless.com/${process.env.REACT_APP_GO_CARDLESS_LINK_ID}`;
