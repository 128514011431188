import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';

import SnackbarContainer from 'containers/SnackBarContainer';
import { AuthProvider } from 'containers/AuthProvider';
import { UIProvider } from 'containers/UIProvider';
import { PageHeadProvider } from 'containers/PageHeadProvider';
import Routes from 'containers/Routes';
import store from 'store';
import mainTheme from 'theme/mainTheme';
import ExpiredAuthModal from 'components/ExpiredAuthModal';
import SnackbarNotifications from 'components/SnackbarNotifications';
import PageScrollResetter from 'components/PageScrollResetter';
import UILoadUserData from 'components/UILoadUserData';
import FetchError from 'components/FetchError';
import history from '../history';

import 'styles/index.css';
import 'styles/global.scss';
import 'styles/style-overrides.scss';

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <UIProvider>
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarContainer>
                  <Sentry.ErrorBoundary fallback={<FetchError />}>
                    <ExpiredAuthModal />
                    <PageHeadProvider>
                      <UILoadUserData>
                        <PageScrollResetter />
                        <SnackbarNotifications />
                        <Routes />
                      </UILoadUserData>
                    </PageHeadProvider>
                  </Sentry.ErrorBoundary>
                </SnackbarContainer>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AuthProvider>
      </UIProvider>
    </Router>
  </Provider>
);

export default App;
