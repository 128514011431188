import { required } from 'lib/validation';

const validate = (values) => {
  const clientReferenceErrors = values
  && values.clientReferences
  && values.clientReferences.map(({ companyName, companyUrl }) => {
    if (!companyName && !companyUrl) {
      const clientReferenceError = {};

      if (!companyName) {
        clientReferenceError.companyName = "Please enter company's name";
      }

      if (!companyUrl) {
        clientReferenceError.companyUrl = "Please enter company's site";
      }

      return clientReferenceError;
    }

    return undefined;
  });

  const openEndedQuestionsErrors = values
  && values.openEndedQuestions
  && values.openEndedQuestions.map((item) => {
    if (item.isRequired) {
      return {
        answer: required(item.answer),
      };
    }

    return undefined;
  });

  return {
    clientReferences: clientReferenceErrors,
    openEndedQuestions: openEndedQuestionsErrors,
  };
};

export default validate;
