import { css } from '@emotion/react';

export const pageBody = css`
  flex: 1;
`;

export const subtitle = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const form = (theme) => (
  css`
    max-width: 600px;
    padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(5)};
    box-shadow: ${theme.boxShadows.large};
    border-radius: 4px;
  `
);

export const row = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      gap: ${theme.spacing(5)};
      margin-bottom: ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      gap: ${theme.spacing(1)};
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const button = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
);

export const bottomBar = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `
);
