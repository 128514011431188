/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { required, validateEmail } from 'lib/validation';
import companyAPI from 'api/company';
import TextFieldInput from 'components/Form/TextFieldInput';
import getErrorMessage from 'utils/getErrorMessage';
import composeValidators from 'utils/composeValidators';
import * as classes from './styles';

// TODO: use FieldArray for share to multiple users
class ShareProfileForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    alreadyShared: false,
    emailWhatHaveInvite: null,
  };

  handleReturnToForm = () => this.setState({ alreadyShared: false, emailWhatHaveInvite: null });

  handleSubmitShare = async (form, values) => {
    const { companyId, enqueueSnackbar } = this.props;

    try {
      await companyAPI.shareVendorProfile({ companyId, ...values });

      this.setState({
        alreadyShared: true,
        emailWhatHaveInvite: values.contactEmail,
      });

      form.reset();
    } catch (error) {
      const errorMessage = getErrorMessage(error, 'Failed to share profile');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  render() {
    const { onClose } = this.props;
    const { alreadyShared, emailWhatHaveInvite } = this.state;

    return alreadyShared ? (
      <>
        <div css={classes.success}>
          <Typography variant="h3" sx={{ mb: 3 }}>Profile shared!</Typography>
          <Typography variant="body1">
            You have successfully shared this profile with {emailWhatHaveInvite}
          </Typography>
        </div>
        <div css={classes.actions}>
          <Button onClick={this.handleReturnToForm}>Share again</Button>
          <Button onClick={onClose} variant="outlined">Close</Button>
        </div>
      </>
    ) : (
      <>
        <Typography variant="h2" sx={{ mb: 3 }}>
          To share this profile please provide details of the recipient below.
        </Typography>
        <Form
          onSubmit={(values, form) => this.handleSubmitShare(form, values)}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                sx={{ mb: 2 }}
                name="contactName"
                validate={required}
                component={TextFieldInput}
                label="Contact name"
              />
              <Field
                sx={{ mb: 2 }}
                name="contactEmail"
                validate={composeValidators(required, validateEmail)}
                component={TextFieldInput}
                label="Contact e-mail"
              />
              <Field
                sx={{ mb: 2 }}
                name="textMessage"
                component={TextFieldInput}
                label="Message"
                multiline
                rows="6"
              />
              <div css={classes.submit}>
                <Button type="submit" disabled={submitting}>Share</Button>
              </div>
            </form>
          )}
        />
      </>
    );
  }
}

export default withSnackbar(ShareProfileForm);
