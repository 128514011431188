import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes, fieldArrayMetaPropTypes } from 'redux-form';
import { useSnackbar } from 'notistack';
import differenceBy from 'lodash/differenceBy';
import companyAPI from 'api/company';
import tagAPI from 'api/tag';
import CompanyPropertyAutocomplete from 'components/Shared/CompanyPropertyAutocomplete';

const CompanyTagFieldArray = ({
  fields,
  meta: { submitFailed, error },
  className,
  label,
}) => {
  const companyId = useSelector((state) => state.user.company.id);
  const { enqueueSnackbar } = useSnackbar();

  const tags = fields.getAll();

  const handleAdd = (tag) => (
    companyAPI.addTagToCompany(companyId, tag.id)
      .then(() => fields.push(tag))
      .catch(() => enqueueSnackbar('Failed to add a tag'))
  );

  const handleRemove = (filteredList) => {
    const [{ id: removedTagId }] = differenceBy(tags, filteredList, 'id');
    const removedTagIndex = tags.findIndex((tag) => tag.id === removedTagId);

    return companyAPI.deleteTagFromCompany(companyId, removedTagId)
      .then(() => fields.remove(removedTagIndex))
      .catch(() => enqueueSnackbar('Failed to remove a tag', { variant: 'error' }));
  };

  return (
    <CompanyPropertyAutocomplete
      className={className}
      label={label}
      name={fields.name}
      properties={tags}
      onAdd={handleAdd}
      onRemove={handleRemove}
      apiClient={tagAPI}
      error={submitFailed && error ? error : null}
      getOptionLabel={(option) => option.name}
    />
  );
};

CompanyTagFieldArray.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
};

CompanyTagFieldArray.defaultProps = {
  className: null,
};

export default CompanyTagFieldArray;
