import { css } from '@emotion/react';

export const dialog = (theme) => (
  css`
    & .MuiPaper-root {
      display: flex;
      overflow-y: hidden;

      ${theme.breakpoints.up('md')} {
        width: 800px;
        height: 625px;
        border-radius: 16px;
      }
    }
  `
);

export const dialogTitle = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacing(3)};
  `
);
