import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import { getEventDetailsPageUrl } from './getEventDetailsUrl';

const getEventsDashboardUrl = (type) => (
  type === companyStatus.RETAILER
    ? routes.RETAILER_EVENTS_DASHBOARD
    : routes.VENDOR_EVENTS_DASHBOARD
);

const getEventMenuItemUrl = (events, companyType) => (
  events && events.length === 1
    ? getEventDetailsPageUrl(events[0], companyType)
    : getEventsDashboardUrl(companyType)
);

export default getEventMenuItemUrl;
