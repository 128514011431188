import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { required, validateIsTodayOrAfter } from 'lib/validation';
import DatePicker from 'components/Form/DatePicker';
import SubmitButton from 'components/UI/SubmitButton';
import composeValidators from 'utils/composeValidators';

const RestartChallenge = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field
          label="Please set a deadline of restarted RFI"
          name="deadline"
          component={DatePicker}
          validate={composeValidators(required, validateIsTodayOrAfter)}
          sx={{ mb: 2 }}
          fullWidth
        />
        <SubmitButton isLoading={submitting} type="submit">Save</SubmitButton>
      </form>
    )}
  />
);

RestartChallenge.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

RestartChallenge.defaultProps = {
  initialValues: null,
};

export default RestartChallenge;
