/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ArrowSort16Regular, Edit20Regular, Eye20Regular } from '@fluentui/react-icons';
import { isEditable } from 'utils/challengeCheckers';
import DashboardChallengeType from 'types/DashboardChallengeType';
import * as classes from './styles';

const ChallengesTable = ({
  className,
  challenges,
  formatMilestoneDeadline,
  getMilestone,
  renderChallengeStatus,
  getChallengeDeadline,
  getChallengePathname,
  checkIsDraft,
  onToggleDeadlineSorting,
  isSourceShown,
}) => (
  <table css={classes.table} className={className}>
    <thead>
      <tr>
        <Typography
          css={[classes.cell, classes.th, isSourceShown ? classes.name : classes.extendedName]}
          variant="caption1"
          component="th"
        >
          Name
        </Typography>
        <Typography css={[classes.cell, classes.th]} variant="caption1" component="th">
          <button css={classes.sortButton} onClick={onToggleDeadlineSorting}>
            Deadline <ArrowSort16Regular />
          </button>
        </Typography>
        <Typography
          css={[classes.cell, classes.th, classes.status]}
          variant="caption1"
          component="th"
        >
          Status
        </Typography>
        {isSourceShown && (
          <Typography
            css={[classes.cell, classes.th, classes.eventName]}
            variant="caption1"
            component="th"
          >
            Event
          </Typography>
        )}
        <Typography
          css={[classes.cell, classes.th]}
          variant="caption1"
          component="th"
        >
          Responses
        </Typography>
        <Typography
          css={[classes.cell, classes.th, classes.milestone]}
          variant="caption1"
          component="th"
        >
          Next milestone
        </Typography>
        <th css={[classes.cell, classes.th]} />
      </tr>
    </thead>
    <tbody>
      {challenges.map((challenge) => {
        const milestone = getMilestone(challenge);
        const isDraftChallenge = checkIsDraft(challenge);

        return (
          <tr key={challenge.id} css={classes.row}>
            <td css={[classes.name, classes.croppedText, classes.cell]}>
              <Tooltip placement="top-start" title={challenge.name}>
                <Typography variant="subtitle1" component="span">{challenge.name}</Typography>
              </Tooltip>
            </td>
            <Typography
              css={[classes.deadline, classes.cell]}
              variant="body2"
              component="td"
            >
              {getChallengeDeadline(challenge)}
            </Typography>
            <Typography
              css={[classes.status, classes.cell]}
              variant="body2"
              component="td"
            >
              {renderChallengeStatus(challenge)}
            </Typography>
            {isSourceShown && (
              <td css={[classes.cell, classes.croppedText, classes.eventName]}>
                {!!challenge.event && (
                  <Tooltip placement="top-start" title={challenge.event.name}>
                    <Typography variant="body2" component="span">{challenge.event.name}</Typography>
                  </Tooltip>
                )}
              </td>
            )}
            <Typography css={classes.cell} variant="body2" component="td">
              {!isDraftChallenge && challenge.challengeResponses.length}
            </Typography>
            <td css={[classes.cell, classes.croppedText, classes.milestone]}>
              {!!milestone && (
                <Tooltip placement="top-start" title={milestone.name}>
                  <Typography variant="body2" component="span">
                    <span css={classes.milestoneDate}>{formatMilestoneDeadline(milestone)}</span>
                    {milestone.name}
                  </Typography>
                </Tooltip>
              )}
            </td>
            <td css={classes.cell}>
              <IconButton
                aria-label="open-challenge-page"
                component={Link}
                to={getChallengePathname(challenge)}
                css={classes.icon}
              >
                {isEditable(challenge) ? (<Edit20Regular />) : (<Eye20Regular />)}
              </IconButton>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

ChallengesTable.propTypes = {
  className: PropTypes.string,
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired).isRequired,
  formatMilestoneDeadline: PropTypes.func.isRequired,
  getMilestone: PropTypes.func.isRequired,
  renderChallengeStatus: PropTypes.func.isRequired,
  getChallengeDeadline: PropTypes.func.isRequired,
  getChallengePathname: PropTypes.func.isRequired,
  checkIsDraft: PropTypes.func.isRequired,
  onToggleDeadlineSorting: PropTypes.func.isRequired,
  isSourceShown: PropTypes.bool.isRequired,
};

ChallengesTable.defaultProps = {
  className: null,
};

export default ChallengesTable;
