import { generatePath } from 'react-router-dom';
import { CalendarLtr24Regular, Scales24Regular } from '@fluentui/react-icons';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PeopleIcon from 'svg/people';
import ProfileIcon from 'svg/profile';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import getEventMenuItemUrl from 'utils/getEventMenuItemUrl';

const DEFAULT_EDIT_PROFILE_TAB = 'basic-details';

export const managePersonalAccountMenuItem = {
  pathname: routes.USER_PROFILE,
  name: 'My details',
  description: 'Edit your personal details',
  icon: AccountCircle,
};

export const retailerChallengesMenuItem = {
  pathname: routes.RETAILER_CHALLENGE_DASHBOARD,
  name: 'RFIs',
  description: 'Connect with software and service vendors to help solve specific RFIs',
  icon: Scales24Regular,
};

const getEditProfileLink = (companyType) => {
  const base = companyType === companyStatus.VENDOR
    ? routes.EDIT_VENDOR_DETAILS
    : routes.EDIT_RETAILER_DETAILS;

  return generatePath(base, { activeTab: DEFAULT_EDIT_PROFILE_TAB });
};

export const getCompanyProfileLink = (companyType) => ({
  pathname: getEditProfileLink(companyType),
  name: 'Profile',
  description: 'To get the best results you should keep your profile updated',
  icon: ProfileIcon,
});

export const manageUsersMenuItem = {
  name: 'Users',
  pathname: routes.MANAGE_USERS,
  description: 'Configure the users and roles in your company',
  icon: PeopleIcon,
};

export const vendorChallengeDashboardMenuItem = {
  pathname: routes.VENDOR_CHALLENGE_DASHBOARD,
  name: 'RFIs',
  description: 'Connect with consumer brands to help them solve their specific RFIs',
  icon: Scales24Regular,
};

export const getEventsMenuItem = (eventMenu, companyType) => {
  const pathname = getEventMenuItemUrl(eventMenu, companyType);

  return {
    pathname,
    name: 'Events',
    description: 'View recent and upcoming events hosted by Validify and our Partners',
    icon: CalendarLtr24Regular,
  };
};
