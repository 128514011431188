import { css } from '@emotion/react';

export const getContainerStyles = (isPublic) => (theme) => (
  css`
    max-width: ${isPublic ? theme.layout.publicPageContent : theme.layout.protectedPageContent};
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    ${theme.breakpoints.down('sm')} {
      padding-right: ${theme.spacing(3)};
      padding-left: ${theme.spacing(3)};
    }

    ${theme.breakpoints.up('sm')} {
      padding-right: ${theme.spacing(4)};
      padding-left: ${theme.spacing(4)};
    }
  `
);

export const verticalGutters = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('sm')} {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
    }
  `
);

export const title = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const noResults = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      padding-top: ${theme.spacing(2)};
    }
  `
);

export const spinnerContainer = css`
  margin: 100px 0;
  text-align: center;
`;

export const bottomGutter = (theme) => (
  css`
    padding-bottom: ${theme.spacing(5)};
  `
);

export const card = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);
