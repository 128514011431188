import { css } from '@emotion/react';

export const wrapper = (theme) => (
  css`
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 260px;
    overflow-y: auto;
    padding-bottom: env(safe-area-inset-bottom);

    &::before {
      display: block;
      content: '';
      width: 100%;
      ${theme.mixins.toolbar};
    }
  `
);

export const list = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

// TODO: remove this dirty hack
export const vendorProfileMenu = css`
  min-height: calc(100% - 59px);
`;

export const listItemOuter = (theme) => css`
  flex-grow: 0;
  padding: 6px 12px 6px 24px;
  min-height: 56px;

  &.Mui-disabled {
    color: ${theme.primaryPalette.mediumGrey};
  }

  &.Mui-selected {
    background-color: #fff;

    & > span:first-of-type {
      background-color: ${theme.primaryPalette.lightYellow};
      border-radius: 4px;
    }

    & svg {
      color: ${theme.palette.primary.main};
    }
  }

  & .MuiTouchRipple-root {
    color: ${theme.palette.primary.main};
  }

  &:hover,
  &.Mui-selected:hover,
  &:active {
    background-color: ${theme.primaryPalette.lightYellow};
  }
`;

export const listItemInner = css`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 19px;
`;

export const menuItemIcon = (theme) => (
  css`
    width: 20px;
    margin-right: ${theme.spacing(2)};
    color: inherit;
  `
);

export const listItemText = css`
  /* MUI override */
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
`;

export const subListItem = css`
  padding-left: 56px;
`;

export const menuItemLogout = (theme) => (
  css`
    margin-top: auto;
    margin-bottom: ${theme.spacing(2)};
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const listItemLogoutText = (theme) => (
  css`
    /* MUI override */
    & .MuiTypography-root {
      color: ${theme.primaryPalette.menuGrey};
    }
  `
);

export const subList = css`
  /* MUI override */
  padding-top: 0;
  padding-bottom: 0;
`;

export const subListOpen = css`
  /* MUI override */
  min-height: auto !important;
`;

export const name = (theme) => (
  css`
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    font-size: 18px;
    font-family: ${theme.boldFont};
    line-height: 1.5;
  `
);
