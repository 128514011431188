/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import { ScalesRegular } from '@fluentui/react-icons';
import * as classes from './styles';

const ICON_SIZE = 72;

const ChallengeDashboardFallback = () => (
  <div css={classes.fallback}>
    <div css={classes.iconWrap}>
      <ScalesRegular fontSize={ICON_SIZE} />
    </div>
    <Typography css={classes.subtitle} variant="subtitle2">There aren&apos;t any RFIs here. </Typography>
  </div>
);

export default ChallengeDashboardFallback;
