import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import clsx from 'clsx';
import { CHALLENGE_EMAIL_CONTACT } from 'constants/contacts';
import './NoteForChallengeResponder.scss';

const NoteForChallengeResponder = ({ className }) => (
  <div className={clsx('note-for-challenge-responder', className)}>
    <p className="note-for-challenge-responder__subheader">Notes for responder</p>
    <p className="note-for-challenge-responder__text">
      Your company profile on the validify platform will be assessed for all general company
      information, please ensure your RFI response focused on responding to the specific RFI
      requirements
    </p>
    <p className="note-for-challenge-responder__text">Do not exceed the word count restrictions</p>
    <p className="note-for-challenge-responder__text">
      Before you submit the response to the RFI make sure your profile on the validify platform is
      up to date and has maximum detail. We would also recommend requesting ratings &amp; reviews
      from your clients to enhance the profile
    </p>
    <p className="note-for-challenge-responder__text">
      It maybe possible to determine or guess the identify of the brand from the detail in the RFI,
      any attempt to contact the retailer directly or circumvent the RFI process will result in your
      solution being ineligible for future RFIs
    </p>
    <p className="note-for-challenge-responder__text">
      If you have any questions related to the RFI please email them to{' '}
      <Link href={`mailto:${CHALLENGE_EMAIL_CONTACT}`}>{CHALLENGE_EMAIL_CONTACT}</Link>
    </p>
  </div>
);

NoteForChallengeResponder.propTypes = {
  className: PropTypes.string,
};

NoteForChallengeResponder.defaultProps = {
  className: null,
};

export default NoteForChallengeResponder;
