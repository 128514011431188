import { css } from '@emotion/react';

export const container = css`
  margin: 0 auto;
  text-align: center;
`;

export const iconWrapper = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: 0 auto ${theme.spacing(4)};
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.light};
    border-radius: 130px;
  `
);

export const text = (theme) => (
  css`
    color: ${theme.primaryPalette.mediumGrey};
  `
);
