import APIClient from './client';

class VoteReviewAPI extends APIClient {
  constructor() {
    super('/voteReviews');
  }
}

const voteReviewAPI = new VoteReviewAPI();

export default voteReviewAPI;
