/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link, useParams } from 'react-router-dom';
import omit from 'lodash/omit';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EventHeader from 'components/Events/EventHeader';
import EventDetails from 'components/Events/EventDetails';
import EventVendorAttendees from 'components/Events/EventVendorAttendees';
import RetailerEventChallengeDashboard from 'components/Events/RetailerEventChallengeDashboard';
import EventAgenda from 'components/Events/EventAgenda';
import SavedTopicPriorities from 'components/Events/SavedTopicPriorities';
import ParticipationRequest from 'components/Events/ParticipationRequest';
import TabPanel from 'components/UI/TabPanel';
import EventPageTheme from 'components/Events/EventPageTheme';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import EventParticipationRequestType from 'types/EventParticipationRequest';
import EventType from 'types/Event';
import CompanySearchResultType from 'types/CompanySearchResult';
import DashboardChallengeType from 'types/DashboardChallengeType';
import EventTopicType from 'types/EventTopic';
import EventTopicNoteType from 'types/EventTopicNote';
import { retailerEventTabIds, AGENDA_TAB, eventTabKeys } from './constants';
import * as classes from './styles';

const RetailerEvent = ({
  event,
  vendors,
  challenges,
  orderedTopics,
  notes,
  isEventAttendee,
  participationRequest,
}) => {
  const [activeTab, setActiveTab] = useState(retailerEventTabIds[eventTabKeys.EVENT_DETAILS]);

  const { slug } = useParams();

  const { primaryColor } = event;

  const getA11yProps = (key) => ({
    'aria-controls': `event-tab-panel-${key}`,
    'aria-label': `event-tab-panel-${key}-label`,
  });

  const eventChallengeFormUrl = generatePath(routes.EVENT_CHALLENGE_FORM, { slug });
  const editRetailerPrioritiesUrl = generatePath(
    routes.EDIT_RETAILER_EVENT_BUSINESS_PRIORITIES,
    { slug },
  );

  const content = (
    <div>
      <EventHeader
        css={!isEventAttendee && classes.shortHeader}
        event={event}
        isEventAttendee={isEventAttendee}
        subtitle={isEventAttendee ? 'To attend the event we ask you to set business priorities and create a new RFI for this event' : null}
      >
        {isEventAttendee && (
          <div css={classes.retailerButtons}>
            <Button variant="outlined" component={Link} to={eventChallengeFormUrl}>
              Create RFI
            </Button>
            <Button component={Link} to={editRetailerPrioritiesUrl}>
              Set business priorities
            </Button>
          </div>
        )}
      </EventHeader>
      {isEventAttendee ? (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, id) => setActiveTab(id)}
            aria-label="event-tabs"
            variant="scrollable"
            scrollButtons={false}
            css={classes.tabs}
          >
            {Object.values(omit(eventTabKeys, AGENDA_TAB)).map((tab) => (
              <Tab css={classes.tab} key={tab} label={tab} {...getA11yProps(tab)} />
            ))}
            <Tab css={classes.tab} label="Agenda" {...getA11yProps('Agenda')} />
          </Tabs>

          <div css={classes.content}>
            <TabPanel activeId={activeTab} id={retailerEventTabIds[eventTabKeys.EVENT_DETAILS]}>
              <EventDetails event={event} />
            </TabPanel>
            <TabPanel
              activeId={activeTab}
              id={retailerEventTabIds[eventTabKeys.BUSINESS_PRIORITIES]}
            >
              <SavedTopicPriorities topics={orderedTopics} notes={notes}/>
            </TabPanel>
            <TabPanel activeId={activeTab} id={retailerEventTabIds[eventTabKeys.ATTENDEES]}>
              <EventVendorAttendees vendors={vendors} />
            </TabPanel>
            <TabPanel activeId={activeTab} id={retailerEventTabIds[eventTabKeys.RFIS]}>
              <RetailerEventChallengeDashboard challenges={challenges} />
            </TabPanel>
            <TabPanel activeId={activeTab} id={retailerEventTabIds[eventTabKeys[AGENDA_TAB]]}>
              <EventAgenda
                event={event}
                canLoadAgenda={isEventAttendee}
                companyType={companyStatus.RETAILER}
              />
            </TabPanel>
          </div>
        </>
      ) : (
        <div css={classes.content}>
          <ParticipationRequest eventId={event.id} participationRequest={participationRequest} />
        </div>
      )}
    </div>
  );

  return primaryColor
    ? (
      <EventPageTheme primaryColor={primaryColor}>
        {content}
      </EventPageTheme>
    ) : content;
};

RetailerEvent.propTypes = {
  event: EventType.isRequired,
  vendors: PropTypes.arrayOf(CompanySearchResultType.isRequired),
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired),
  orderedTopics: PropTypes.arrayOf(EventTopicType.isRequired),
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired),
  isEventAttendee: PropTypes.bool.isRequired,
  participationRequest: EventParticipationRequestType,
};

RetailerEvent.defaultProps = {
  participationRequest: null,
  notes: [],
  orderedTopics: [],
  vendors: [],
  challenges: [],
};

export default RetailerEvent;
