import { css } from '@emotion/react';

export const header = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)} ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(4)};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
);

export const hint = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);
