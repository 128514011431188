import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import { SubmittedChallengeResponseType } from 'types/ChallengeResponse';
import ResponseDetails from './ResponseDetails';

const ResponseDetailsForRetailer = ({ challengeResponse, title }) => {
  const { state: { challenge } } = useContext(ChallengeContext);

  return (
    <ResponseDetails
      challenge={challenge}
      challengeResponse={challengeResponse}
      title={title}
    />
  );
};

ResponseDetailsForRetailer.propTypes = {
  title: PropTypes.string.isRequired,
  challengeResponse: SubmittedChallengeResponseType.isRequired,
};

export default ResponseDetailsForRetailer;
