import { useCallback, useContext } from 'react';
import PublishedChallenge from 'components/RetailerChallenges/PublishedChallenge';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import FetchContainer from 'containers/FetchContainer';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const PublishedChallengePage = (props) => {
  const {
    getChallengeResponses,
    getChallengeReviewers,
    getChallengeMilestones,
  } = useContext(ChallengeContext);

  const getInitialProps = useCallback(() => (
    Promise.all([
      getChallengeResponses(),
      getChallengeReviewers(),
      getChallengeMilestones(),
    ])
  ), [getChallengeResponses, getChallengeReviewers, getChallengeMilestones]);

  return (
    <FetchContainer getInitialProps={getInitialProps} component={PublishedChallenge} {...props} />
  );
};

export default (withPageTitle(pageTitles.CHALLENGE)(PublishedChallengePage));
