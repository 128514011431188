/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import { format } from 'date-fns';
import partition from 'lodash/partition';
import { sortOptions, sortOptionsList } from 'components/Reviews/sortOptions';
import ReviewType from 'types/Review';
import { sortReviewsByVotes, sortBySubmissionDate, sortByOverallRating } from 'utils/sortReviews';
import OVERALL_RATING_FIELD_NAME from 'constants/overallRatingFieldName';
import sortOrder from 'constants/sortOrder';
import ReviewsTable from '../ReviewsTable';
import ReviewMobileCard from '../ReviewMobileCard';
import ReviewDashboardFallback from '../ReviewDashboardFallback';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';
const SELECT_SORT_TYPE_ID = 'sort-review-select';

const ReviewList = ({
  reviews,
  showReviewerName,
  canVote,
  getLogoUrl,
  getCompanyName,
}) => {
  const [selectedSortType, setSortType] = useState(sortOptions.MOST_RELEVANT);

  const getFormattedDate = (review) => format(new Date(review.createdAt), DATE_FORMAT);

  const getRatings = (review) => {
    const { ratings: reviewRatings } = review;

    return partition(reviewRatings, (rating) => rating.field === OVERALL_RATING_FIELD_NAME);
  };

  const sortedReviews = useMemo(() => {
    switch (selectedSortType) {
      case sortOptions.MOST_RELEVANT:
        return sortReviewsByVotes(reviews, sortOrder.DESC);
      case sortOptions.LEAST_RELEVANT:
        return sortReviewsByVotes(reviews, sortOrder.ASC);
      case sortOptions.HIGHEST_RATED:
        return sortByOverallRating(reviews, sortOrder.DESC);
      case sortOptions.LOWEST_RATED:
        return sortByOverallRating(reviews, sortOrder.ASC);
      case sortOptions.NEWEST:
        return sortBySubmissionDate(reviews, sortOrder.DESC);
      case sortOptions.OLDEST:
        return sortBySubmissionDate(reviews, sortOrder.ASC);
      default:
        return reviews;
    }
  }, [reviews, selectedSortType]);

  return (
    <div>
      {reviews.length ? (
        <>
          <FormControl css={classes.select}>
            <InputLabel css={classes.filterLabel} htmlFor={SELECT_SORT_TYPE_ID}>Sort</InputLabel>
            <NativeSelect
              value={selectedSortType}
              css={classes.filter}
              inputProps={{ name: 'sort-review-select', id: SELECT_SORT_TYPE_ID }}
              onChange={(e) => setSortType(e.target.value)}
            >
              {sortOptionsList.map(({ value, label }) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </NativeSelect>
          </FormControl>

          <ReviewsTable
            css={classes.table}
            reviews={sortedReviews}
            showReviewerName={showReviewerName}
            getFormattedDate={getFormattedDate}
            getRatings={getRatings}
            getLogoUrl={getLogoUrl}
            getCompanyName={getCompanyName}
            canVote={canVote}
          />

          <div css={classes.cards}>
            {sortedReviews.map((review) => (
              <ReviewMobileCard
                key={review.id}
                css={classes.card}
                review={review}
                showReviewerName={showReviewerName}
                getFormattedDate={getFormattedDate}
                getRatings={getRatings}
                getLogoUrl={getLogoUrl}
                getCompanyName={getCompanyName}
                canVote={canVote}
              />
            ))}
          </div>
        </>
      ) : (
        <ReviewDashboardFallback />
      )}
    </div>
  );
};

ReviewList.propTypes = {
  reviews: PropTypes.arrayOf(ReviewType.isRequired).isRequired,
  showReviewerName: PropTypes.bool.isRequired,
  getLogoUrl: PropTypes.func.isRequired,
  getCompanyName: PropTypes.func.isRequired,
  canVote: PropTypes.bool,
};

ReviewList.defaultProps = {
  canVote: false,
};

export default ReviewList;
