import APIClient from './client';

class PreRegisteredUserAPI extends APIClient {
  constructor() {
    super('/pre-registered-users');
  }
}

const preRegisteredUserAPI = new PreRegisteredUserAPI();

export default preRegisteredUserAPI;
