import { css } from '@emotion/react';

export const field = css`
  display: flex;
  align-items: center;
`;

export const icons = (theme) => (
  css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(2)};
  `
);

export const icon = (
  css`
    color: #e6e6e6;
    cursor: pointer;
  `
);

export const activeIcon = (theme) => (
  css`
    color: ${theme.primaryPalette.primaryYellow};
  `
);
