import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import UIContext from './UIContext';

const UIProvider = ({ children }) => {
  const [isUserLoaded, handleUserSet] = useState(false);

  const onSetUserLoaded = useCallback((value) => handleUserSet(value), []);

  return (
    <UIContext.Provider value={{ isUserLoaded, onSetUserLoaded }}>
      {children}
    </UIContext.Provider>
  );
};

UIProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UIProvider;
