import PropTypes from 'prop-types';
import eventParticipationRequestStatus from 'constants/eventParticipationRequestStatus';

const EventParticipationRequestType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  rejectionReason: PropTypes.string,
  status: PropTypes.oneOf(Object.values(eventParticipationRequestStatus)).isRequired,
  userId: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export default EventParticipationRequestType;
