/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import EventTopicType from 'types/EventTopic';
import EventTopicNoteType from 'types/EventTopicNote';
import formatMultilineText from 'utils/formatMultilineText';
import matchTopicsWithNotes from '../utils/matchTopicsWithNotes';
import * as classes from './styles';

const SavedTopicPriorityList = ({ className, topics, notes }) => {
  const topicsWithNotes = matchTopicsWithNotes(topics, notes);

  return (
    <Typography variant="subtitle2" component="ul" css={classes.ul} className={className}>
      {topicsWithNotes.map((topic) => (
        <li key={topic.id} css={classes.li}>
          <Typography variant="subtitle2">{topic.name}</Typography>
          {!!topic.note && (
            <Typography variant="body2">
              {formatMultilineText(topic.note.comment)}
            </Typography>
          )}
        </li>
      ))}
    </Typography>
  );
};

SavedTopicPriorityList.propTypes = {
  className: PropTypes.string,
  topics: PropTypes.arrayOf(EventTopicType.isRequired).isRequired,
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired).isRequired,
};

SavedTopicPriorityList.defaultProps = {
  className: null,
};

export default SavedTopicPriorityList;
