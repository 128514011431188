import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { FormHelperText } from '@mui/material';
import UploadedFileField from 'components/UI/UploadedFileField';
import FileUploadDropzone from 'components/UI/FileUpload';
import { documentUploadConfig } from 'constants/fileUploadConfigs';

const DocumentUploadField = ({
  className,
  input: { onChange, value },
  meta: { error, submitFailed },
  onRemoveFile,
  onSaveFile,
  fileName,
}) => (
  <div className={className}>
    {value ? (
      <UploadedFileField
        value={{ link: value, name: fileName }}
        onRemove={() => { onRemoveFile(value); onChange(''); }}
      />
    ) : (
      <FileUploadDropzone onUpload={onSaveFile(onChange)} {...documentUploadConfig} />
    )}
    {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
  </div>
);

DocumentUploadField.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onSaveFile: PropTypes.func.isRequired,
};

DocumentUploadField.defaultProps = {
  className: null,
  fileName: null,
};

export default DocumentUploadField;
