import PropTypes from 'prop-types';
import { RatingType } from './Review';
import TagType from './Tags';

const CompanySearchResultType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  founded: PropTypes.shape({
    where: PropTypes.string.isRequired,
  }).isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      ratings: PropTypes.arrayOf(RatingType.isRequired).isRequired,
    }).isRequired,
  ).isRequired,
  shortDescription: PropTypes.string,
  tags: PropTypes.arrayOf(TagType.isRequired).isRequired,
  slug: PropTypes.string.isRequired,
});

export default CompanySearchResultType;
