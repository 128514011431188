/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import ManageChallengeStatus from 'components/ManageChallengeStatus';
import PublicChallengeDetails from 'components/Challenge/ChallengeSynopsis';
import ChallengeActions from 'components/ManageChallengeStatus/ChallengeActions';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import routes from 'constants/routes';
import { isAcceptingResponses, isExpired } from 'utils/challengeCheckers';
import PrivateChallengeDetails from '../ChallengePrivateDetails';
import * as classes from './styles';

const RegularChallenge = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { state: { challenge } } = useContext(ChallengeContext);

  const retailerSlug = useSelector((state) => state.user.company.slug);

  const responsesDashboardUrl = generatePath(
    routes.CHALLENGE_RESPONSES_DASHBOARD,
    { challengeId: challenge.id },
  );

  const handleCopyChallengeURLToClipboard = () => {
    const pathname = generatePath(
      routes.PUBLIC_CHALLENGE_INFO,
      { retailerSlug, rfiSlug: challenge.slug },
    );
    const query = new URLSearchParams({ isShared: true }).toString();

    const publicChallengeURL = `${window.location.origin}${pathname}?${query}`;

    navigator.clipboard.writeText(publicChallengeURL);

    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  return (
    <div>
      {!isExpired(challenge) && isAcceptingResponses(challenge) && (
        <Button onClick={handleCopyChallengeURLToClipboard} sx={{ mb: 2 }}>
          Copy share link
        </Button>
      )}
      <div css={classes.actions}>
        <ManageChallengeStatus />
        <div css={classes.buttons}>
          <Button component={Link} to={responsesDashboardUrl}>
            Responses
          </Button>
          <ChallengeActions />
        </div>
      </div>

      <PublicChallengeDetails css={classes.basicDetails} challenge={challenge} />
      <PrivateChallengeDetails />
    </div>
  );
};

export default RegularChallenge;
