import { css } from '@emotion/react';

export const container = css`
  max-width: 600px;
  margin: 0 auto;
`;

export const footer = css`
  text-align: center;
`;
