import PropTypes from 'prop-types';

const MenuItemType = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
  to: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

const MenuItemShape = PropTypes.shape({
  ...MenuItemType,
  submenu: PropTypes.arrayOf(PropTypes.shape(MenuItemType).isRequired),
});

export default MenuItemShape;
