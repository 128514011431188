import { css } from '@emotion/react';

const suggestion = (theme) => (
  css`
    &, &.Mui-selected {
      background-color: #fff;
    }

    &:hover {
      background-color: ${theme.primaryPalette.veryLightYellow};
    }
  `
);

export default suggestion;
