/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { SearchFilled } from '@fluentui/react-icons';
import * as classes from './styles';

const FONT_SIZE = 18;

const SELECT_STATUS_ID = 'filter-challenges-by-status';
const SELECT_EVENTS_ID = 'filter-challenges-by-events';

const RetailerDashboardFilters = ({
  className,
  selectedStatus,
  selectedEventOption,
  challengeStatusesList,
  eventOptions,
  onFilterByStatus,
  onFilterByEvent,
  search,
  onFilterByName,
}) => (
  <div className={className}>
    <TextField
      sx={{ mb: 1 }}
      css={classes.search}
      onChange={(e) => onFilterByName(e.target.value)}
      value={search}
      placeholder="Search"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchFilled fontSize={FONT_SIZE} />
          </InputAdornment>
        ),
      }}
      name="challenge-search"
    />

    <div css={classes.filtersWrap}>
      <FormControl css={classes.wrap}>
        <InputLabel css={classes.filterLabel} htmlFor={SELECT_EVENTS_ID}>Events</InputLabel>
        <NativeSelect
          value={selectedEventOption}
          css={classes.filter}
          inputProps={{ name: 'challenge-events', id: SELECT_EVENTS_ID }}
          onChange={(e) => onFilterByEvent(e.target.value)}
        >
          {eventOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>

      <FormControl css={classes.wrap}>
        <InputLabel css={classes.filterLabel} htmlFor={SELECT_STATUS_ID}>Status</InputLabel>
        <NativeSelect
          value={selectedStatus}
          css={classes.filter}
          inputProps={{ name: 'challenge-status', id: SELECT_STATUS_ID }}
          onChange={(e) => onFilterByStatus(e.target.value)}
        >
          {challengeStatusesList.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  </div>
);

RetailerDashboardFilters.propTypes = {
  className: PropTypes.string,
  selectedStatus: PropTypes.string.isRequired,
  selectedEventOption: PropTypes.string.isRequired,
  challengeStatusesList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  eventOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onFilterByStatus: PropTypes.func.isRequired,
  onFilterByEvent: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  onFilterByName: PropTypes.func.isRequired,
};

RetailerDashboardFilters.defaultProps = {
  className: null,
};

export default RetailerDashboardFilters;
