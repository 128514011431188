/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import VendorCompanyCard from 'components/Shared/VendorCompanyCard';
import routes from 'constants/routes';
import CompanySearchResultType from 'types/CompanySearchResult';
import * as classes from './styles';

const EventVendorAttendees = ({ vendors }) => {
  const history = useHistory();

  const handleClickCard = ({ slug }) => {
    const vendorProfileUrl = generatePath(routes.VENDOR_INFO, { slug });

    history.push(vendorProfileUrl);
  };

  return (
    <div>
      {vendors.map((company) => (
        <VendorCompanyCard
          key={company.id}
          css={classes.card}
          onClick={handleClickCard}
          company={company}
          searchPathname={routes.SEARCH_SOLUTION_PAGE}
        />
      ))}
    </div>
  );
};

EventVendorAttendees.propTypes = {
  vendors: PropTypes.arrayOf(CompanySearchResultType.isRequired).isRequired,
};

export default EventVendorAttendees;
