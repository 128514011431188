const challengeStatus = {
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  REQUESTED_REVISION: 'requestedRevision',
  APPROVED_REVISION: 'approvedRevision',
  DRAFT_REVISION: 'draftRevision',
  SUBMITTED_REVISION: 'submittedRevision',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
  COMPLETED: 'completed',
};

export default challengeStatus;
