import constants from '../constants/user';
import {
  ADD_INVITED_USER,
  REMOVE_COMPANY_USER,
  REMOVE_INVITED_USER,
  UPDATE_USER_LIST,
} from '../modules/companyUsers';

export const initialState = () => ({
  authUser: {},
  userRole: {},
  company: {},
  companyVendor: {},
  error: '',
});

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case constants.GET_USER_SUCCESS: {
      const userObj = { ...action.user };
      const { company } = userObj;
      const userRole = userObj.invitedRoles[0] || {};
      const companyVendor = company.vendors ? company.vendors : {};

      return {
        ...state,
        eventMenu: action.eventMenu,
        authUser: userObj,
        userRole,
        company: {
          ...state.company,
          ...company,
          // Important: loopback API can return no data on nested queries of `hasManyThrough`
          // relation. If a company has no tags loopback will omit the field. That's why this
          // fallback is inserted.
          tags: company.tags || [],
        },
        companyVendor,
        error: '',
      };
    }
    case constants.GET_USER_FAIL:
    case constants.LOGIN_USER_FAIL:
      return {
        ...state,
        authUser: {},
        company: {},
        error: action.error,
      };

    case constants.SET_COMPANY_USER: {
      const { vendors, retailers, ...companyData } = action.company;

      return {
        ...state,
        company: {
          ...state.company,
          ...companyData,
          retailers: { ...state.company.retailers, ...retailers },
          vendors: { ...state.company.vendors, ...vendors },
        },
      };
    }

    case constants.UPDATE_USER: {
      const updatedUserData = action.data;
      const updatedCompanyUsers = state.company.users
        .map((user) => {
          if (user.id === updatedUserData.id) {
            return { ...user, ...updatedUserData };
          }

          return user;
        });

      const currentUser = updatedUserData.id === state.authUser.id
        ? { ...state.authUser, ...updatedUserData }
        : { ...state.authUser };

      return {
        ...state,
        authUser: currentUser,
        company: {
          ...state.company,
          users: updatedCompanyUsers,
        },
        error: '',
      };
    }

    case constants.RESET_USER_STATE:
      return initialState();
    case constants.CLEAR_USER_ERROR:
      return {
        ...state,
        error: '',
      };

    case constants.UPDATE_USER_ROLE_SUCCESS: {
      const { id, role } = action;

      const updatedUsers = state.company.users.map((user) => (
        user.id === id ? { ...user, invitedRoles: [role] } : user
      ));

      return {
        ...state,
        company: {
          ...state.company,
          users: updatedUsers,
        },
      };
    }

    case constants.DELETE_USER_ROLE_SUCCESS: {
      const { id } = action;
      const updatedUsersList = state.company.users.map((user) => (
        user.id === id ? { ...user, invitedRoles: [] } : user
      ));

      return {
        ...state,
        company: {
          ...state.company,
          users: updatedUsersList,
        },
      };
    }

    // TODO: extract state and logic of user management to separate module
    case ADD_INVITED_USER: {
      const invitedUsers = state.company.inviteUserToCompanies;
      const updatedInvitedUsers = [...invitedUsers, action.user];

      return {
        ...state,
        company: {
          ...state.company,
          inviteUserToCompanies: updatedInvitedUsers,
        },
      };
    }

    case REMOVE_INVITED_USER: {
      const invitedUsers = state.company.inviteUserToCompanies;
      const updatedInvitedUsers = invitedUsers.filter(({ id }) => id !== action.id);

      return {
        ...state,
        company: {
          ...state.company,
          inviteUserToCompanies: updatedInvitedUsers,
        },
      };
    }

    case REMOVE_COMPANY_USER: {
      const activeUsers = state.company.users;
      const updatedActiveUsers = activeUsers.filter(({ id }) => id !== action.id);

      return {
        ...state,
        company: {
          ...state.company,
          users: updatedActiveUsers,
        },
      };
    }

    case UPDATE_USER_LIST: {
      const { users } = state.company;
      const { user: updatedUser } = action;

      const updatedUserList = users.map((companyUser) => {
        if (companyUser.id === updatedUser.id) {
          return { ...companyUser, ...updatedUser };
        }

        return companyUser;
      });

      return {
        ...state,
        company: {
          ...state.company,
          users: updatedUserList,
        },
      };
    }

    case constants.ADD_ACTIVE_USER: {
      const { user } = action;
      const { users } = state.company;

      return {
        ...state,
        company: {
          ...state.company,
          users: [...users, user],
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
