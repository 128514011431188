/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import CompanyRegistrationBanner from 'components/Shared/CompanyRegistrationBanner';
import banner from './styles';

const VendorRegistrationBanner = ({ isApprovalRequestSent }) => (
  <CompanyRegistrationBanner
    css={banner}
    title="Attention"
    list={
      isApprovalRequestSent
        ? ['Your profile is pending approval']
        : ['Your profile is not complete', 'Your profile has not been published']
    }
  />
);

VendorRegistrationBanner.propTypes = {
  isApprovalRequestSent: PropTypes.bool.isRequired,
};

export default VendorRegistrationBanner;
