import PageNotFoundImage from 'static/images/PageNotFound.png';
import './CompanyNotFound.scss';

const CompanyNotFound = () => (
  <div className="company-not-found">
    <img
      alt="Company did not found"
      src={PageNotFoundImage}
      className="company-not-found__image"
    />
  </div>
);

export default CompanyNotFound;
