import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { maxLength100, required, validateIsTodayOrAfter } from 'lib/validation';
import TextFieldInput from 'components/Form/TextFieldInput';
import DatePicker from 'components/Form/DatePicker';
import SubmitButton from 'components/UI/SubmitButton';
import composeValidators from 'utils/composeValidators';

const PauseChallenge = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field
          sx={{ mb: 2 }}
          label="Provide a reason"
          name="pauseReason"
          component={TextFieldInput}
          multiline
          rows="6"
          validate={composeValidators(required, maxLength100)}
        />
        <Field
          sx={{ mb: 2 }}
          label="Provide an expected restart date"
          name="expectedRestart"
          component={DatePicker}
          fullWidth
          validate={composeValidators(required, validateIsTodayOrAfter)}
        />
        <SubmitButton isLoading={submitting} type="submit">Save</SubmitButton>
      </form>
    )}
  />
);

PauseChallenge.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PauseChallenge;
