/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';

import PageBody from 'components/UI/PageBody';
import CompanyRegistrationBanner from 'components/Shared/CompanyRegistrationBanner';
import companyStatus from 'constants/companyStatus';
import CompanyType from 'types/Company';
import HomeMenu from './HomeMenu';
import bannerStyles from './styles';

const Home = ({ company }) => (
  <PageBody>
    {company.type === companyStatus.VENDOR && !company.userRequestApprove && (
      <CompanyRegistrationBanner
        css={bannerStyles}
        title="Welcome to Validify Access"
        additionalInfo="We ask you to complete your company profile and submit to us for approval. This must happen before:"
        list={['Your company profile is published', 'You can participate in RFIs']}
      />
    )}
    <Typography sx={{ mb: 5 }} variant="h1">{company.name}</Typography>
    <HomeMenu />
  </PageBody>
);

Home.propTypes = {
  company: PropTypes.shape(CompanyType).isRequired,
};

export default connect((state) => ({ company: state.user.company }))(Home);
