/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withSnackbar } from 'notistack';
import FormLabel from '@mui/material/FormLabel';
import challengeReviewerAPI from 'api/challengeReviewer';
import userAPI from 'api/user';
import getErrorMessage from 'utils/getErrorMessage';
import AddUserForm from 'components/ManageUsers/AddUserForm';
import ConfirmModal from 'components/Shared/ConfirmModal';
import FORM_ID from 'components/ChallengeForm/formId';
import ChallengeReviewersTable from './ChallengeReviewersTable';
import EditChallengeReviewer from './EditChallengeReviewer';
import tableStyles from './styles';

const ChallengeReviewers = ({
  className,
  companyId,
  onSaveChallengeDraft,
  enqueueSnackbar,
  values,
}) => {
  const [reviewers, setReviewers] = useState([]);

  const [selectedForDeleteReviewer, selectReviewerForDelete] = useState(null);
  const [selectedForEditReviewer, selectReviewerForEdit] = useState(null);

  useEffect(() => {
    if (values.id) {
      challengeReviewerAPI.loadChallengeReviewers(values.id)
        .then(({ data }) => setReviewers(data))
        .catch(() => enqueueSnackbar('Failed to load RFI reviewers', { variant: 'error' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveReviewer = (id) => {
    challengeReviewerAPI.deleteById(id)
      .then(() => {
        setReviewers((prevReviewers) => prevReviewers.filter((reviewer) => reviewer.id !== id));
        enqueueSnackbar('Reviewer was removed successfully', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to remove RFI reviewer', { variant: 'error' }));
  };

  const handleClickRemoveButton = (reviewer) => {
    if (!reviewer.isNotified) {
      handleRemoveReviewer(reviewer.id);
    } else {
      selectReviewerForDelete(reviewer);
    }
  };

  const handleAddReviewer = async ({ email }) => {
    try {
      /**
       * If user creates a new challenge challengeId isn't set. We need to save a challenge document
       * on server-side at first and use its id.
       */
      let { id } = values;

      if (!id) {
        const challenge = await onSaveChallengeDraft(values);
        id = challenge.id;
      }

      const { data } = await challengeReviewerAPI.create({ email, challengeId: id, companyId });

      const reviewer = await handleLoadReviewerUserInfo(data);

      setReviewers((prevReviewers) => [...prevReviewers, reviewer]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleLoadReviewerUserInfo = async (reviewer) => {
    const isUserRegistered = !!reviewer.userId;

    if (!isUserRegistered) {
      return reviewer;
    }

    const { data: user } = await userAPI.loadReviewerUserInfo(reviewer.userId);

    return { ...reviewer, user };
  };

  const handleUpdateReviewer = async ({ email }) => {
    try {
      const { data } = await challengeReviewerAPI
        .updateById(selectedForEditReviewer.id, { email });

      const updatedReviewer = await handleLoadReviewerUserInfo(data);

      setReviewers((prevReviewers) => (prevReviewers.map((reviewer) => (
        reviewer.id === updatedReviewer.id ? updatedReviewer : reviewer
      ))));

      enqueueSnackbar('Reviewer was updated', { variant: 'success' });
      selectReviewerForEdit(null);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleClickEditButton = (reviewer) => {
    selectReviewerForEdit(reviewer);
  };

  return (
    <>
      <div className={className}>
        <FormLabel sx={{ mb: 1 }} component="legend">
          Add the email of each person who will review the responses
        </FormLabel>

        {!!reviewers.length && (
          <ChallengeReviewersTable
            css={tableStyles}
            onEditClick={handleClickEditButton}
            onRemoveClick={handleClickRemoveButton}
            reviewers={reviewers}
          />
        )}
        <AddUserForm onSubmit={handleAddReviewer} />
      </div>

      <EditChallengeReviewer
        isOpen={!!selectedForEditReviewer}
        isNotified={!!(selectedForEditReviewer && selectedForEditReviewer.isNotified)}
        onClose={() => selectReviewerForEdit(null)}
        initialValues={{ email: selectedForEditReviewer && selectedForEditReviewer.email }}
        onSubmit={handleUpdateReviewer}
      />

      <ConfirmModal
        title="Confirm removal of reviewer"
        description="Are you sure you want to remove this user from the list of reviewers?"
        isOpen={!!selectedForDeleteReviewer}
        onClose={() => selectReviewerForDelete(null)}
        onConfirm={() => {
          handleRemoveReviewer(selectedForDeleteReviewer.id);
          selectReviewerForDelete(null);
        }}
      />
    </>
  );
};

ChallengeReviewers.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  onSaveChallengeDraft: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

ChallengeReviewers.defaultProps = {
  className: null,
};

export default connect((state) => ({
  companyId: state.user.company.id,
  values: getFormValues(FORM_ID)(state),
}))(withSnackbar(ChallengeReviewers));
