import { css } from '@emotion/react';

const sectionStyles = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)}
    }
  `
);

export default sectionStyles;
