import { css } from '@emotion/react';

export const modal = (theme) => (
  css`
    .MuiDialog-paper {
      max-width: 600px;

      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(3)};
      }
    }
  `
);

export const field = (theme) => (
  css`
    display: flex;
    align-items: baseline;
    margin-bottom: ${theme.spacing(6)};
  `
);

export const username = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex: 1;
    }
  `
);

export const domain = css`
  max-width: 200px;

  .MuiNativeSelect-select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const buttons = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      gap: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      gap: ${theme.spacing(2)};
    }
  `
);
