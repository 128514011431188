/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import * as classes from './styles';

const ManageProfileButton = ({
  className,
  onClick,
  icon: Icon,
  title,
  disabled,
}) => (
  <button
    className={className}
    css={[classes.button, disabled && classes.disabled]}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    <Icon css={classes.icon} />
    <div css={classes.caption}>{title}</div>
  </button>
);

ManageProfileButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]).isRequired,
  title: PropTypes.string.isRequired,
};

ManageProfileButton.defaultProps = {
  className: null,
  disabled: false,
};

export default ManageProfileButton;
