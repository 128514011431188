import PropTypes from 'prop-types';

const FieldMetaType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  dirtySinceLastSubmit: PropTypes.bool.isRequired,
  error: PropTypes.any,
  initial: PropTypes.any,
  invalid: PropTypes.bool.isRequired,
  modified: PropTypes.bool.isRequired,
  modifiedSinceLastSubmit: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitError: PropTypes.any,
  submitSucceeded: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  validating: PropTypes.bool.isRequired,
  visited: PropTypes.bool.isRequired,
});

export default FieldMetaType;
