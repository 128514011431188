import APIClient from './client';

class EventInvitedAttendeeAPI extends APIClient {
  constructor() {
    super('/event-invited-attendees');
  }

  getInvitation = async ({ email, eventId }) => {
    const { data: invitation } = await this.getRequest('/load-invitation', null, { email, eventId });

    return invitation;
  };
}

const eventInvitedAttendeeAPI = new EventInvitedAttendeeAPI();

export default eventInvitedAttendeeAPI;
