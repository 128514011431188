/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, fieldInputPropTypes, fieldArrayFieldsPropTypes } from 'redux-form';
import fileAPI from 'api/file';
import FileUpload from 'components/UI/FileUpload';
import UploadedFileField from 'components/UI/UploadedFileField';
import { challengeCaseStudyConfig } from 'constants/fileUploadConfigs';
import caseStudyStyles from './styles';

const CaseStudyUploadField = ({ input: { onChange, value }, onSaveFile, onRemoveFile }) => (
  value.link ? (
    <UploadedFileField css={caseStudyStyles} value={value} onRemove={onRemoveFile} />
  ) : (
    <FileUpload {...challengeCaseStudyConfig} onUpload={onSaveFile(onChange)} />
  )
);

CaseStudyUploadField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  onSaveFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};

const CaseStudyUploads = ({ fields, userId }) => {
  const handleSaveFile = (onChange) => async (formData) => {
    const { body } = await fileAPI.uploadFile('challengeResponse.caseStudy', 'link', formData, userId);
    const { name } = formData.get('file');
    onChange({ name, link: body });
    fields.push({ name: '', link: '' });
  };

  const handleRemoveFile = (index) => {
    if (fields.length === 1) {
      fields.push({ name: '', link: '' });
    }

    setTimeout(() => fields.remove(index));
  };

  return (
    <div>
      {fields.map((field, i) => (
        <Field
          key={i}
          name={field}
          onSaveFile={handleSaveFile}
          onRemoveFile={() => handleRemoveFile(i)}
          component={CaseStudyUploadField}
        />
      ))}
    </div>
  );
};

CaseStudyUploads.propTypes = {
  userId: PropTypes.string.isRequired,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

export default connect((state) => ({ userId: state.user.authUser.id }))(CaseStudyUploads);
