const globalSales = [
  '<£50m',
  '£50m - £100m',
  '£101m - £200m',
  '£201m - £300m',
  '£301m - £400m',
  '£401m - £500m',
  '£501m - £600m',
  '£601m - £700m',
  '£701m - £800m',
  '£801m - £900m',
  '£901m - £1bn',
  '£1bn - £2bn',
  '£2bn - £3bn',
  '>£3bn',
];

export default globalSales;
