import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withSnackbar } from 'notistack';
import DocumentUpload from 'components/Shared/DocumentUpload';
import { updateCompanyProfile } from 'store/thunk/company';
import DocumentUploadType from 'types/DocumentUpload';
import documentationType from 'constants/documentationType';
import getErrorMessage from 'utils/getErrorMessage';

class CompanyDocumentation extends Component {
  static propTypes = {
    descriptionPlaceholder: PropTypes.string,
    descriptionValidator: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(DocumentUploadType.isRequired).isRequired,
    maxFiles: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(documentationType)).isRequired,
    onUpdateProfile: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    descriptionPlaceholder: null,
  };

  handleUpdateDocumentList = async (updatedList) => {
    const { onUpdateProfile, type, enqueueSnackbar } = this.props;

    const body = { vendors: { [type]: updatedList } };

    try {
      await onUpdateProfile(body);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  render() {
    const {
      descriptionPlaceholder,
      descriptionValidator,
      documents,
      title,
      maxFiles,
    } = this.props;

    return (
      <DocumentUpload
        descriptionPlaceholder={descriptionPlaceholder}
        descriptionValidator={descriptionValidator}
        documents={documents}
        maxFiles={maxFiles}
        onUpdateDocumentList={this.handleUpdateDocumentList}
        title={title}
      />
    );
  }
}

const mapStateToProps = (state, { type }) => ({
  documents: get(state, `user.company.vendors[${type}]`, []),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateProfile: (updatedData) => dispatch(updateCompanyProfile(updatedData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(CompanyDocumentation));
