/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from 'components/UI/TabPanel';
import ReviewsList from 'components/Reviews/ReviewsList';
import PendingReviewRequests from 'components/RetailerReviews/PendingReviewRequests';
import ReviewRequestType from 'types/ReviewRequest';
import { retailerReviewTabIds, reviewTabNames } from './tabs';
import * as classes from './styles';

const RetailerReviews = ({ requests }) => {
  const reviews = useSelector((state) => state.user.authUser.ratingsReviews);

  const getLogoUrl = (review) => review.company.logo;
  const getCompanyName = (review) => review.company.name;

  const [activeTab, setActiveTab] = useState(retailerReviewTabIds[reviewTabNames.YOUR_RATINGS]);

  const getA11yProps = (key) => ({
    'aria-controls': `retailer-review-tabpanel-${key}`,
  });

  return (
    <>
      <div css={classes.head}>
        <Typography sx={{ mb: 4 }} variant="h1">Ratings</Typography>
        <Tabs
          value={activeTab}
          onChange={(_, id) => setActiveTab(id)}
          aria-label="retailer-reviews-tabs"
          variant="scrollable"
          scrollButtons={false}
          css={classes.tabs}
        >
          {Object.values(reviewTabNames)
            .map((tab) => (
              <Tab css={classes.tab} key={tab} label={tab} {...getA11yProps(tab)} />
            ))}
        </Tabs>
      </div>

      <div css={classes.content}>
        <TabPanel activeId={activeTab} id={retailerReviewTabIds[reviewTabNames.YOUR_RATINGS]}>
          <ReviewsList
            reviews={reviews}
            showReviewerName={false}
            getLogoUrl={getLogoUrl}
            getCompanyName={getCompanyName}
          />
        </TabPanel>
        <TabPanel
          fullWidth
          activeId={activeTab}
          id={retailerReviewTabIds[reviewTabNames.PENDING_REVIEW_REQUESTS]}
        >
          <PendingReviewRequests requests={requests} />
        </TabPanel>
      </div>
    </>
  );
};

RetailerReviews.propTypes = {
  requests: PropTypes.arrayOf(ReviewRequestType.isRequired).isRequired,
};

export default RetailerReviews;
