/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import getEventGuestName from 'components/Events/utils/getEventGuestName';
import SavedPriorityCard from 'components/Events/SavedPriorityCard';
import SavedTopicPriorityList from 'components/Events/SavedTopicPriorityList';
import EventHint from 'components/Events/EventHint';
import EventAttendeeType from 'types/EventAttendee';
import * as classes from './styles';

const EventRetailerAttendees = ({ showHint, retailerAttendees }) => (
  <div>
    {showHint && (
      <EventHint messageAction="set meeting priorities" css={classes.hint} />
    )}

    {retailerAttendees.map((attendee, index) => (
      <SavedPriorityCard
        key={attendee.user.id}
        index={index + 1}
        css={classes.card}
        title={getEventGuestName(attendee)}
      >
        {!!attendee.topics && (
          <SavedTopicPriorityList
            css={classes.list}
            topics={attendee.topics}
            notes={attendee.notes}
          />
        )}
      </SavedPriorityCard>
    ))}
  </div>
);

EventRetailerAttendees.propTypes = {
  showHint: PropTypes.bool.isRequired,
  retailerAttendees: PropTypes.arrayOf(EventAttendeeType.isRequired).isRequired,
};

export default EventRetailerAttendees;
