/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  change,
  reduxForm,
  Form,
  propTypes,
} from 'redux-form';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import ChallengeUnmountSaver from 'components/ChallengeForm/ChallengeUnmountSaver';
import ChallengeAutosave from 'components/ChallengeForm/ChallengeAutosave';
import challengeResponseAPI from 'api/challengeResponse';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import focusFirstInvalidFormControl from 'utils/focusFirstInvalidFormControl';
import getErrorMessage from 'utils/getErrorMessage';
import ChallengeSynopsisType from 'types/ChallengeSynopsis';
import { EventChallengeType } from 'types/Challenge';
import ChallengeResponseFormHeader from './ChallengeResponseFormHeader';
import ChallengeResponseFormFooter from './ChallengeResponseFormFooter';
import ChallengeResponseFields from './ChallengeResponseFields';
import validate from './ChallengeResponseFields/validate';
import headerStyles from './styles';

const ChallengeResponseForm = ({
  challenge,
  form,
  handleSubmit,
  onPublish,
}) => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateFieldValue = useCallback(
    (key, value) => dispatch(change(form, key, value)),
    [dispatch, form],
  );

  const handleError = useCallback(
    (e) => {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const saveChallengeResponse = useCallback(
    ({
      caseStudyAssets,
      clientReferences,
      questions,
      openEndedQuestions,
      ...rest
    }) => {
      const filterYesNoAnswers = (answers) => {
        if (!answers || !answers.length) {
          return [];
        }

        return answers.map((answer) => omit(answer, ['name', 'requirements']));
      };

      const filterFreeFormAnswers = (answers = []) => (
        answers.map((answer) => pick(answer, ['questionId', 'answer']))
      );

      const sanitizedClientReferences = clientReferences
        ? clientReferences.filter(({ companyName, companyUrl }) => companyName || companyUrl)
        : [];

      const sanitizedCaseStudyAssets = caseStudyAssets
        ? caseStudyAssets.filter(({ link }) => !!link)
        : [];

      return challengeResponseAPI.postChallengeResponse({
        challengeId: challenge.id,
        clientReferences: sanitizedClientReferences,
        caseStudyAssets: sanitizedCaseStudyAssets,
        questions: filterYesNoAnswers(questions),
        openEndedQuestions: filterFreeFormAnswers(openEndedQuestions),
        ...rest,
      })
        .then(({ data: { challengeResponse } }) => {
          handleUpdateFieldValue('id', challengeResponse.id);
          handleUpdateFieldValue('status', challengeResponse.status);
        })
        .catch(handleError);
    },
    [challenge, handleUpdateFieldValue, handleError],
  );

  const publishResponse = useCallback(
    (values) => (
      saveChallengeResponse({ ...values, status: challengeResponseStatus.SUBMITTED })
        .then(onPublish)
    ),
    [saveChallengeResponse, onPublish],
  );

  const saveResponseDraft = useCallback(
    (values) => saveChallengeResponse({ ...values, status: challengeResponseStatus.DRAFT }),
    [saveChallengeResponse],
  );

  return (
    <Form onSubmit={handleSubmit(publishResponse)} name={form}>
      <ChallengeUnmountSaver
        form={form}
        onSave={saveResponseDraft}
        isEditable={(values) => values.status !== challengeResponseStatus.SUBMITTED}
      />
      <ChallengeAutosave onSave={saveResponseDraft} />
      <ChallengeResponseFormHeader css={headerStyles} challenge={challenge} />
      <ChallengeResponseFields />
      <ChallengeResponseFormFooter challengeId={challenge.id} />
    </Form>
  );
};

ChallengeResponseForm.propTypes = {
  challenge: PropTypes.oneOfType([ChallengeSynopsisType, EventChallengeType]).isRequired,
  onPublish: PropTypes.func.isRequired,
  ...propTypes,
};

export default reduxForm({
  form: 'challengeResponseForm',
  validate,
  onSubmitFail: focusFirstInvalidFormControl,
})(ChallengeResponseForm);
