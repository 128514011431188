import { css } from '@emotion/react';

export const form = css`
  max-width: 420px;
  margin: 0 auto;
`;

export const footer = css`
  display: flex;
  justify-content: space-between;
`;
