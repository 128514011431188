import { css } from '@emotion/react';

export const shortHeader = (theme) => (
  css`
    padding-bottom: ${theme.spacing(2)} !important;
  `
);

export const wrapper = (theme) => css`
  margin-top: ${theme.spacing(3)};

  ${theme.breakpoints.up('sm')} {
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  }
`;

export const linkWrapper = (theme) => (
  css`
    padding-top: ${theme.spacing(3)};
  `
);

export const content = (theme) => css`
  ${theme.breakpoints.up('sm')} {
    padding: ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(3)};
  }
`;

export const tabs = (theme) => css`
  margin-top: -${theme.spacing(7)};

  ${theme.breakpoints.up('sm')} {
    margin-left: ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('sm')} {
    margin-left: ${theme.spacing(3)};
  }

  .MuiTabs-flexContainer {
    gap: ${theme.spacing(4)};
  }
`;

export const tab = css`
  min-height: auto;
`;
