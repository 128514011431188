/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, propTypes } from 'redux-form';

import FormLayout from 'components/Shared/FormLayout';
import OfficeAddressFormSection from 'components/Shared/OfficeAddressFormSection';
import BasicCompanyInfoFormSection from 'components/Shared/BasicCompanyInfoFormSection';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import section from './styles';

const validate = (values) => {
  const errors = {
    offices: [
      {
        country: '',
      },
    ],
  };

  if (!values.offices
        || !values.offices[0]
        || !values.offices[0].country
        || values.offices[0].country === ''
  ) {
    errors.offices[0].country = 'Cannot be empty';
  }

  return errors;
};

const REGISTRATION_DURATION_MINUTES = 10;

class BasicDetails extends Component {
  static propTypes = {
    countryValue: PropTypes.string,
    onUpdateProfile: PropTypes.func.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    countryValue: '',
  };

  saveCompanyData = (isDraft) => (body) => {
    const { onUpdateProfile } = this.props;

    onUpdateProfile(body, isDraft);
  };

  render() {
    const {
      countryValue,
      userId,
      handleSubmit,
      valid,
      dirty,
      submitSucceeded,
    } = this.props;

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isValid={valid}
        isDirty={dirty}
        submitSucceeded={submitSucceeded}
      >
        <FormLayout
          buttonText="Continue"
          onSubmit={handleSubmit(this.saveCompanyData(false))}
          title="Basic details"
          >
          <BasicCompanyInfoFormSection
            css={section}
            registrationDurationMinutes={REGISTRATION_DURATION_MINUTES}
            uploadFieldName="smallLogoLink"
            userId={userId}
          />
          <OfficeAddressFormSection countryValue={countryValue} />
        </FormLayout>
      </DataCaptureFormContainer>
    );
  }
}

const selector = formValueSelector('basicDetails');

const mapStateToProps = (state) => {
  const countryValue = selector(state, 'offices[0].country');

  return {
    userId: state.user.authUser.id,
    countryValue,
    initialValues: {
      smallLogoLink: state.user.company.logo,
      ...pick(state.user.company, [
        'offices',
        'name',
        'companyWebsite',
        'number',
        'country',
        'smallLogoLink',
      ]),
    },
  };
};

export default connect(mapStateToProps)(reduxForm({
  enableReinitialize: true,
  validate,
  form: 'basicDetails',
})(BasicDetails));
