import PropTypes from 'prop-types';
import clsx from 'clsx';
import 'styles/VendorProfile.scss';
import './VendorStats.scss';

const VendorStats = ({
  caption,
  children,
  className,
  title,
}) => (
  <div className={clsx('vendor-stats', className)}>
    <div className="vendor-stats__title vendor-profile-section-title" variant="body1">
      {title}
    </div>
    <div className="vendor-stats__value">
      <div>
        <div>{children}</div>
        {caption && <div className="vendor-stats__caption">{caption}</div>}
      </div>
    </div>
  </div>
);

VendorStats.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

VendorStats.defaultProps = {
  caption: null,
  className: null,
};

export default VendorStats;
