import { Home24Regular, Briefcase24Regular } from '@fluentui/react-icons';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';

export const homeMenuItem = { title: 'Home', to: routes.MAIN, icon: Home24Regular };

export const getCompanyUsersMenuItem = (companyType) => ({
  isAdminRightsRequired: true,
  title: 'My company',
  to: routes.MANAGE_USERS,
  icon: Briefcase24Regular,
  submenu: [
    { title: 'Users and groups', to: routes.MANAGE_USERS },
    {
      title: companyType === companyStatus.RETAILER ? 'Primary contact' : 'Contacts',
      to: routes.MANAGE_PRIMARY_COMPANY_CONTACT,
    },
    { title: 'Email domains', to: routes.MANAGE_EMAIL_DOMAINS },
  ],
});
