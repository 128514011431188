import { useContext } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { isEditable, isExpired, isRevisionRequestAllowed } from 'utils/challengeCheckers';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import challengeStatus from 'constants/challengeStatus';
import routes from 'constants/routes';

const ChallengeActions = () => {
  const { state: { challenge }, requestRevision } = useContext(ChallengeContext);

  const { status } = challenge;

  const isDeadlinePassed = isExpired(challenge);
  const isRevisionRequestPending = status === challengeStatus.REQUESTED_REVISION;

  const REDIRECT_LINK = generatePath(routes.EDIT_SAVED_CHALLENGE, { id: challenge.id });

  return (
    <>
      {(!isDeadlinePassed && (
        isRevisionRequestAllowed(challenge) || status === challengeStatus.REQUESTED_REVISION
      ))
        && (
          <Button onClick={requestRevision} disabled={isRevisionRequestPending}>
            {isRevisionRequestPending ? 'Requested revision' : 'Request revision'}
          </Button>
        )
      }
      {(!isDeadlinePassed && isEditable(challenge)) && (
        <Button component={RouterLink} to={REDIRECT_LINK}>Edit RFI</Button>
      )}
    </>
  );
};

export default ChallengeActions;
