import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing(4)};

    ${theme.breakpoints.up('sm')} {
      justify-content: flex-end;
    }
  `
);

export const button = (theme) => (
  css`
    margin-bottom: ${theme.spacing(1)};

    ${theme.breakpoints.up('sm')} {
      &:not(:first-of-type) {
        margin-left: ${theme.spacing(1)};
      }
    }

    ${theme.breakpoints.down('sm')} {
      &:not(:last-child) {
        margin-right: ${theme.spacing(1)};
      }
    }
  `
);

export const icon = css`
  width: 1em;
  height: 1em;

  // TODO: change icon and remove override
  path {
    fill: currentColor;
  }
`;
