import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import FieldInputType from 'types/FieldInput';
import FieldMetaType from 'types/FieldMeta';

const RatingField = ({
  className,
  icon,
  input: { name, onChange },
  meta: { touched, error, submitting },
  label,
}) => (
  <div className={className}>
    <Typography sx={{ mb: 1 }} variant="subtitle2">
      {label}
    </Typography>
    <Rating icon={icon} emptyIcon={icon} onChange={(_, value) => onChange(value)} name={name} />
    {(touched || submitting) && error && <FormHelperText error>{error}</FormHelperText>}
  </div>
);

RatingField.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  input: FieldInputType.isRequired,
  meta: FieldMetaType.isRequired,
  label: PropTypes.string,
};

RatingField.defaultProps = {
  className: null,
  icon: undefined,
  label: null,
};

export default RatingField;
