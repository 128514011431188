import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ListItem from 'components/UI/ListItem';
import 'styles/VendorProfile.scss';

const List = ({ className, list, title }) => (
  !!(list && list.length) && (
    <div className={className}>
      <Typography className="vendor-profile-section-title" variant="h2">{title}</Typography>
      <ul>
        {list.map((item) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </ul>
    </div>
  )
);

List.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string.isRequired),
};

List.defaultProps = {
  className: null,
  list: [],
};

export default List;
