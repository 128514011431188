import { css } from '@emotion/react';

export const getButtonStyles = (width) => (theme) => (
  css`
    &:disabled {
      width: ${width}px;
      background-color: ${theme.primaryPalette.darkYellow};
    }
  `
);

export const spinner = css`
  color: currentColor;
`;
