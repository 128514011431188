import PropTypes from 'prop-types';

const LinkIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9599 17.5171H21.2982C21.0779 17.5171 20.8666 17.6046 20.7108 17.7604C20.555 17.9162 20.4674 18.1276 20.4674 18.3479V24.1637H3.85089V7.54718H11.3283C11.5487 7.54718 11.76 7.45964 11.9158 7.30383C12.0716 7.14802 12.1592 6.9367 12.1592 6.71635V5.0547C12.1592 4.83435 12.0716 4.62302 11.9158 4.46721C11.76 4.3114 11.5487 4.22387 11.3283 4.22387H3.02007C2.35902 4.22387 1.72505 4.48647 1.25762 4.9539C0.790188 5.42133 0.527588 6.0553 0.527588 6.71635L0.527588 24.9945C0.527588 25.6556 0.790188 26.2895 1.25762 26.757C1.72505 27.2244 2.35902 27.487 3.02007 27.487H21.2982C21.9593 27.487 22.5933 27.2244 23.0607 26.757C23.5281 26.2895 23.7907 25.6556 23.7907 24.9945V18.3479C23.7907 18.1276 23.7032 17.9162 23.5474 17.7604C23.3916 17.6046 23.1802 17.5171 22.9599 17.5171ZM25.8678 0.900566H19.2212C18.1115 0.900566 17.5569 2.24599 18.3384 3.02956L20.1938 4.8849L7.53768 17.5363C7.4215 17.6521 7.32932 17.7897 7.26642 17.9411C7.20352 18.0926 7.17114 18.255 7.17114 18.4191C7.17114 18.5831 7.20352 18.7455 7.26642 18.897C7.32932 19.0485 7.4215 19.186 7.53768 19.3018L8.71486 20.4769C8.83064 20.5931 8.96822 20.6853 9.1197 20.7482C9.27118 20.8111 9.43359 20.8435 9.59761 20.8435C9.76164 20.8435 9.92405 20.8111 10.0755 20.7482C10.227 20.6853 10.3646 20.5931 10.4804 20.4769L23.1302 7.82343L24.985 9.67617C25.7639 10.4551 27.114 9.90984 27.114 8.79342V2.14681C27.114 1.81628 26.9827 1.4993 26.749 1.26558C26.5153 1.03187 26.1983 0.900566 25.8678 0.900566V0.900566Z"
      fill="#CCCCCC"
    />
  </svg>
);

LinkIcon.propTypes = {
  className: PropTypes.string,
};

LinkIcon.defaultProps = { className: null };

export default LinkIcon;
