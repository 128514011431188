import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './reducers';

const DEVELOPMENT_MODE = 'development';

const appliedMiddlewares = applyMiddleware(thunk);
const enhancer = process.env.NODE_ENV === DEVELOPMENT_MODE
  ? composeWithDevTools(appliedMiddlewares)
  : appliedMiddlewares;

const store = createStore(reducers, {}, enhancer);

export default store;
