import PropTypes from 'prop-types';

const TabPanel = ({ children, activeId, id }) => (
  <div
    role="tabpanel"
    hidden={activeId !== id}
    id={`event-tab-panel-${id}`}
    aria-labelledby={`event-tab-panel-${id}-label`}
  >
    <div>
      {children}
    </div>
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  activeId: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
