import APIClient from './client';

class ChallengeParticipantAPI extends APIClient {
  constructor() {
    super('/challenge-response-participant');
  }

  loadResponseParticipants = (challengeId, companyId) => {
    const filter = { where: { challengeId, companyId }, include: 'user' };

    return this.find(filter);
  };
}

const challengeParticipantAPI = new ChallengeParticipantAPI();

export default challengeParticipantAPI;
