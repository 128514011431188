import { css } from '@emotion/react';

export const section = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const fields = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const field = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const addNewUser = css`
  max-width: 700px;
`;
