/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import insertHost from 'utils/picture';
import ReviewRequestType from 'types/ReviewRequest';
import ReviewRequestMessage from '../ReviewRequestMessage';
import * as classes from './styles';

const ReviewRequestCard = ({
  className,
  request,
  onFormatDate,
  onOpenReviewForm,
}) => (
  <div className={className} css={classes.container} onClick={onOpenReviewForm}>
    <div css={classes.main}>
      <div>
        <Typography variant="h2">{request.company.name}</Typography>
        <Typography
          css={classes.creationDate}
          variant="caption1"
        >
          {onFormatDate(request.createdAt)}
        </Typography>
      </div>
      {!!request.company.logo && (
        <div css={classes.logoWrapper}>
          <img
            css={classes.logo}
            src={insertHost(request.company.logo)}
            alt={`${request.company.name} logo`}
          />
        </div>
      )}
    </div>
    {!!request.textMessage && (
      <div css={classes.messageField}>
        <Typography variant="caption1" css={classes.messageCaption}>
          Message
        </Typography>
        <Typography variant="body2">
          <ReviewRequestMessage
            message={request.textMessage}
            companyName={request.company.name}
          />
        </Typography>
      </div>
    )}
  </div>
);

ReviewRequestCard.propTypes = {
  request: ReviewRequestType.isRequired,
  onFormatDate: PropTypes.func.isRequired,
  onOpenReviewForm: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ReviewRequestCard.defaultProps = {
  className: null,
};

export default ReviewRequestCard;
