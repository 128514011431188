import { css } from '@emotion/react';

export const headerContainer = (theme) => (
  css`
    z-index: ${theme.zIndex.drawer + 1};
    box-shadow: none;
  `
);

export const headerToolbar = (theme) => (
  css`
    background-color: ${theme.primaryPalette.menu};
    color: ${theme.primaryPalette.mediumGrey};
  `
);

export const logoLink = css`
  margin: 0 auto;
`;

export const logo = css`
  vertical-align: middle;
`;

export const userLink = (theme) => (
  css`
    position: absolute;
    right: ${theme.spacing(3)};
    padding: ${theme.spacing(1)} 0;
    display: flex;
    align-items: center;
    color: ${theme.primaryPalette.white};
    text-decoration: none;
  `
);

export const userName = (theme) => (
  css`
    color: currentColor;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }

    ${theme.breakpoints.up('sm')} {
      margin-left: ${theme.spacing(2)};
    }
  `
);

export const avatarWrap = (theme) => (
  css`
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
  `
);

export const avatarImage = css`
  border-radius: 50%;
  object-fit: cover;
`;
