import APIClient from './client';

class ReviewAPI extends APIClient {
  constructor() {
    super('/ratingsReviews');
  }
}

const reviewAPI = new ReviewAPI();

export default reviewAPI;
