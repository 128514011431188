import { css } from '@emotion/react';

export const reviewer = (theme) => (
  css`
    ${theme.breakpoints.up(500)} {
      min-width: 300px;
    }
  `
);

export const cell = (theme) => (
  css`
    padding: ${theme.spacing(1)};

    &:first-of-type {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  `
);
