import { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import challengeAPI from 'api/challenge';
import ChallengeType, { EventChallengeType } from 'types/Challenge';
import reducer, {
  cancelChallengeSuccess,
  pauseChallengeSuccess,
  restartChallengeSuccess,
  completeChallengeSuccess,
  requestRevisionSuccess,
  getChallengeResponsesSuccess,
  getChallengeReviewersSuccess,
  getChallengeMilestonesSuccess,
} from './challenge.module';
import ChallengeContext from './ChallengeContext';

const ChallengeProvider = ({ children, challenge }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateStatusSuccess = (onSuccess) => {
    if (onSuccess) {
      onSuccess();
    }

    enqueueSnackbar('Success', { variant: 'success' });
  };

  const handleError = () => enqueueSnackbar('Failed to update the RFI', { variant: 'error' });

  const [state, dispatch] = useReducer(reducer, { challenge });

  const cancelChallenge = async (body, onSuccess) => {
    try {
      const { data: updatedChallenge } = await challengeAPI.cancelChallenge(
        state.challenge.id,
        body,
      );
      const { cancellationReason } = updatedChallenge;

      dispatch(cancelChallengeSuccess(cancellationReason));
      handleUpdateStatusSuccess(onSuccess);
    } catch (_) {
      handleError();
    }
  };

  const pauseChallenge = async (body, onSuccess) => {
    try {
      const { data: updatedChallenge } = await challengeAPI.pauseChallenge(
        state.challenge.id,
        body,
      );
      const { expectedRestart, pauseReason } = updatedChallenge;

      dispatch(pauseChallengeSuccess(expectedRestart, pauseReason));
      handleUpdateStatusSuccess(onSuccess);
    } catch (_) {
      handleError();
    }
  };

  const restartChallenge = async (body, onSuccess) => {
    try {
      const { data: updatedChallenge } = await challengeAPI.restartChallenge(
        state.challenge.id,
        body,
      );
      const { response_deadline: deadline } = updatedChallenge;

      dispatch(restartChallengeSuccess(deadline));
      handleUpdateStatusSuccess(onSuccess);
    } catch (_) {
      handleError();
    }
  };

  const completeChallenge = async (body, onSuccess) => {
    try {
      const { data: updatedChallenge } = await challengeAPI.completeChallenge(
        state.challenge.id,
        body,
      );
      const { winnerId } = updatedChallenge;

      dispatch(completeChallengeSuccess(winnerId));
      handleUpdateStatusSuccess(onSuccess);
    } catch (_) {
      handleError();
    }
  };

  const requestRevision = async () => {
    try {
      await challengeAPI.requestRevision(state.challenge.id);

      dispatch(requestRevisionSuccess());
      handleUpdateStatusSuccess();
    } catch (_) {
      handleError();
    }
  };

  const getChallengeResponses = useCallback(async () => {
    if (!state.challengeResponses) {
      const { data: challengeResponses } = await challengeAPI.getResponses(state.challenge.id);

      dispatch(getChallengeResponsesSuccess(challengeResponses));
    }
  }, [state.challenge.id, state.challengeResponses]);

  const getChallengeReviewers = useCallback(async () => {
    if (!state.challengeReviewers) {
      const { data: challengeReviewers } = await challengeAPI.getReviewers(state.challenge.id);

      dispatch(getChallengeReviewersSuccess(challengeReviewers));
    }
  }, [state.challenge.id, state.challengeReviewers]);

  const getChallengeMilestones = useCallback(async () => {
    if (!state.milestones) {
      const { data: milestones } = await challengeAPI.getMilestones(state.challenge.id);

      dispatch(getChallengeMilestonesSuccess(milestones));
    }
  }, [state.challenge.id, state.milestones]);

  return (
    <ChallengeContext.Provider
      value={{
        state,
        cancelChallenge,
        pauseChallenge,
        restartChallenge,
        completeChallenge,
        requestRevision,
        getChallengeResponses,
        getChallengeReviewers,
        getChallengeMilestones,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  );
};

ChallengeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  challenge: PropTypes.oneOfType([ChallengeType, EventChallengeType]).isRequired,
};

export default ChallengeProvider;
