import { css } from '@emotion/react';

export const content = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &::before {
      content: '';
      display: block;
      ${theme.mixins.toolbar};
    }
  `
);

export const headerIconContainer = (theme) => (
  css`
    position: absolute;
    left: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    color: ${theme.primaryPalette.mediumGrey};

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
);

export const burger = (theme) => (
  css`
    color: currentColor;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
);

export const mainContent = (theme) => (
  css`
    box-sizing: border-box;
    transition: padding-left 0.2s ease-in;

    ${theme.breakpoints.up('md')} {
      padding-left: ${theme.layout.sidebar};
    }
  `
);

export const mainContentCollapsed = (theme) => (
  css`
    ${theme.breakpoints.up('md')} {
      padding-left: ${theme.layout.sidebar};
    }
  `
);
