/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Fab from '@mui/material/Fab';
import { Add20Filled } from '@fluentui/react-icons';
import invitedUserAPI from 'api/invitedUser';
import { inviteNewUser, removeActiveUser, removeInvitedUser } from 'store/modules/companyUsers';
import { getUserRole } from 'utils/getUserRole';
import routes from 'constants/routes';
import userRoles from 'constants/userRoles';
import UsersTable from '../UsersTable';
import UserCard from '../UserCard';
import InviteUserModal from '../InviteUserModal';
import DeleteUserModal from '../DeleteUserModal';
import * as classes from './styles';

const userRolesOptions = {
  ADMIN_ROLE: 'Admin',
  MANAGER_ROLE: 'Manager',
};

const UsersList = () => {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.user.authUser.company);
  const userId = useSelector((state) => state.user.authUser.id);
  const activeUsers = useSelector((state) => state.user.company.users);
  const invitedUsers = useSelector((state) => state.user.company.inviteUserToCompanies);

  const { enqueueSnackbar } = useSnackbar();

  const [isOpenInviteModal, setOpenInviteModal] = useState(false);
  const [userIdToDelete, selectUserIdToDelete] = useState(null);

  const users = [...activeUsers, ...invitedUsers];

  const { emailDomains } = company;
  const formattedEmailDomains = emailDomains.map((domain) => `@${domain}`);

  const handleInviteUser = async ({ username, domain }) => {
    const userEmail = `${username}${domain}`;

    await dispatch(inviteNewUser({
      userEmail,
      companyId: company.id,
      userId,
    }));

    setOpenInviteModal(false);
  };

  const handleRemoveUser = () => {
    const user = users.find(({ id: selectedUserId }) => userIdToDelete === selectedUserId);

    if (!user) {
      enqueueSnackbar('User not found', { variant: 'error ' });
    } else if (user.invitedUserEmail) {
      dispatch(removeInvitedUser(userIdToDelete));
    } else {
      dispatch(removeActiveUser(company.id, user));
    }

    selectUserIdToDelete(null);
  };

  const handleResendInvitation = async (id) => {
    try {
      await invitedUserAPI.resendInvitation(id);

      enqueueSnackbar('Successfully resent invitation', { variant: 'success' });
    } catch (_) {
      enqueueSnackbar('Failed to resend invitation', { variant: 'error' });
    }
  };

  const getEditUserPageUrl = (user) => generatePath(routes.EDIT_USER, { id: user.id });

  const getRoleClassName = (displayedRole) => {
    switch (displayedRole) {
      case userRolesOptions.ADMIN_ROLE:
        return classes.adminRole;
      case userRolesOptions.MANAGER_ROLE:
        return classes.managerRole;
      default:
        return null;
    }
  };

  const getFormattedRole = (user) => {
    const role = getUserRole(user);

    switch (role) {
      case userRoles.ADMIN:
        return 'Admin';
      case userRoles.MANAGER:
        return 'Manager';
      default:
        return null;
    }
  };

  const renderUserRole = (user) => {
    if (user.email) {
      const role = getFormattedRole(user);

      return role ? <span css={getRoleClassName(role)}>{getFormattedRole(user)}</span> : null;
    }

    return <span css={classes.invitedUser}>Invited</span>;
  };

  const handleCopyUserDataToClipboard = (text) => {
    navigator.clipboard.writeText(text);

    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  const handleCopyUserEmailToClipboard = (user) => {
    const email = user.invitedUserEmail || user.email;

    handleCopyUserDataToClipboard(email);
  };

  const handleCopyUserPhoneNumberToClipboard = (user) => {
    const phoneNumber = user.phoneNumber
      ? `${user.phoneNumber.code} ${user.phoneNumber.number}`
      : null;

    if (phoneNumber) {
      handleCopyUserDataToClipboard(phoneNumber);
    }
  };

  return (
    <div>
      <UsersTable
        css={classes.desktopUI}
        users={users}
        onDeleteUser={selectUserIdToDelete}
        onResendInvitation={handleResendInvitation}
        getEditUserPageUrl={getEditUserPageUrl}
        renderUserRole={renderUserRole}
        onCopyEmailToClipboard={handleCopyUserEmailToClipboard}
        onCopyPhoneNumberToClipboard={handleCopyUserPhoneNumberToClipboard}
      />

      <div css={classes.mobileUI}>
        {users.map((user) => (
          <UserCard
            key={user.id}
            css={classes.card}
            user={user}
            onDeleteUser={() => selectUserIdToDelete(user.id)}
            onResendInvitation={handleResendInvitation}
            getEditUserPageUrl={getEditUserPageUrl}
            renderUserRole={renderUserRole}
            onCopyEmailToClipboard={handleCopyUserEmailToClipboard}
            onCopyPhoneNumberToClipboard={handleCopyUserPhoneNumberToClipboard}
            isUserRegistered={!user.invitedUserEmail}
          />
        ))}
      </div>

      <InviteUserModal
        isOpen={isOpenInviteModal}
        onClose={() => setOpenInviteModal(false)}
        onSendInvitation={handleInviteUser}
        emailDomains={formattedEmailDomains}
      />

      <DeleteUserModal
        isOpen={!!userIdToDelete}
        onClose={() => selectUserIdToDelete(null)}
        onRemove={handleRemoveUser}
      />

      <Fab
        css={classes.inviteButton}
        size="medium"
        color="primary"
        onClick={() => setOpenInviteModal(true)}
      >
        <Add20Filled />
      </Fab>
    </div>
  );
};

export default UsersList;
