import APIClient from './client';

class EventTopicPriorityListAPI extends APIClient {
  constructor() {
    super('/event-topic-priority-lists');
  }

  getPrioritizedTopicsForEvent = async (userId, eventId) => {
    const { data: [priorities] } = await this.find({
      include: ['topics', 'notes'],
      where: {
        userId,
        eventId,
      },
    });

    return priorities;
  };
}

const eventTopicPriorityListAPI = new EventTopicPriorityListAPI();

export default eventTopicPriorityListAPI;
