/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import companyAPI from 'api/company';
import routes from 'constants/routes';
import contactFormTrigger from 'constants/contactFormTrigger';
import composeValidators from 'utils/composeValidators';
import { required, validateEmail } from 'lib/validation';
import TextFieldInput from 'components/Form/TextFieldInput';
import SubmitButton from 'components/UI/SubmitButton';
import Recaptcha from 'components/Shared/Recaptcha';
import ThankYouForRequest from '../ThankYouForRequest';
import FailSendMessage from '../FailSendMessage';
import submitStyles from './styles';

const screens = {
  FORM: 'form',
  THANK_YOU_FOR_REQUEST: 'thankYouForRequest',
  MESSAGE_SEND_FAIL: 'messageSendFail',
};

const ContactVendorForm = ({ onClose }) => {
  const [captureToken, setCaptureToken] = useState('');
  const [activeScreen, setActiveScreen] = useState(screens.FORM);

  const companyId = useSelector((state) => state.vendorInfo.company.id);

  const handleSendContactRequest = async (values) => {
    try {
      if (captureToken) {
        const body = {
          triggeredBy: contactFormTrigger.VENDOR_CONTACT_FORM,
          receiverCompanyId: companyId,
          ...values,
        };

        await companyAPI.contactVendor(body);

        setActiveScreen(screens.THANK_YOU_FOR_REQUEST);
      }
    } catch (error) {
      setActiveScreen(screens.MESSAGE_SEND_FAIL);
    }
  };

  return (
    <>
      {activeScreen === screens.THANK_YOU_FOR_REQUEST && <ThankYouForRequest onClose={onClose} />}
      {activeScreen === screens.MESSAGE_SEND_FAIL && <FailSendMessage onClose={onClose} />}
      {activeScreen === screens.FORM && (
        <div>
          <Form
            onSubmit={handleSendContactRequest}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  sx={{ mb: 2 }}
                  type="text"
                  name="firstName"
                  label="First Name"
                  component={TextFieldInput}
                  validate={required}
                />
                <Field
                  sx={{ mb: 2 }}
                  type="text"
                  name="lastName"
                  label="Last Name"
                  component={TextFieldInput}
                  validate={required}
                />
                <Field
                  sx={{ mb: 2 }}
                  type="text"
                  name="companyName"
                  label="Company Name"
                  component={TextFieldInput}
                  validate={required}
                />
                <Field
                  sx={{ mb: 2 }}
                  type="email"
                  name="companyEmail"
                  label="Company email address"
                  component={TextFieldInput}
                  validate={composeValidators(required, validateEmail)}
                />
                <Field
                  sx={{ mb: 2 }}
                  type="text"
                  name="comment"
                  rows={3}
                  multiline
                  label="Comment"
                  component={TextFieldInput}
                  validate={required}
                />
                <Typography sx={{ mb: 2 }} variant="body1">
                  Please be advised that all communications sent using the Validify Access platform
                  are monitored by Validify in line with our{' '}
                  <Link component={RouterLink} to={routes.TERMS_AND_CONDITIONS}>
                    Terms &amp; Conditions
                  </Link>
                </Typography>
                <div css={submitStyles}>
                  <SubmitButton type="submit" isLoading={submitting} onClick={handleSubmit}>
                    Submit
                  </SubmitButton>
                </div>
                <Recaptcha setCaptureToken={setCaptureToken} />
              </form>
            )}
          />
        </div>
      )}
    </>
  );
};

ContactVendorForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContactVendorForm;
