import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  matchPath,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageBody from 'components/UI/PageBody';
import PageSubheader from 'components/GeneralLayout/PageSubheader';
import routes from 'constants/routes';
import companyStatus from 'constants/companyStatus';
import { clearCompany } from 'store/actions/vendorInfo';
import LocationType from 'types/Location';
import CompanyNotFound from 'components/Shared/CompanyNotFound';
import VENDOR_INFO_TABS from './constants';
import VendorProfileHead from './VendorProfileHead';
import BasicDetails from './BasicDetails';
import CompanyOverview from './CompanyOverview';
import TechnicalDetails from './TechnicalDetails';
import Endorsements from './Endorsements';
import Company from './Company';
import Reviews from './RatingsReviewsTab';

const VendorDetails = ({
  location: { pathname },
  match: {
    url,
    params: { slug },
  },
}) => {
  const dispatch = useDispatch();

  const userCompany = useSelector((state) => state.user.company);
  const isError = useSelector((state) => state.vendorInfo.isError);

  useEffect(() => () => dispatch(clearCompany()), [dispatch]);

  const companyProfileUrl = generatePath(routes.VENDOR_PROFILE_TAB, {
    slug,
    tab: VENDOR_INFO_TABS.BASIC_DETAILS.path,
  });

  const getCurrentTabName = () => {
    const tabRouteMatch = matchPath(pathname, { path: routes.VENDOR_PROFILE_TAB });

    if (!tabRouteMatch) {
      return 'Unknown';
    }

    const { tab } = tabRouteMatch.params;

    const tabsList = Object.values(VENDOR_INFO_TABS);
    const activeTab = tabsList.find(({ path }) => path === tab);

    return activeTab ? activeTab.title : 'Unknown';
  };

  if (isError) {
    return <CompanyNotFound />;
  }

  const breadcrumbs = [
    { to: routes.MAIN, title: 'Home' },
    { to: companyProfileUrl, title: 'Company' },
    { to: pathname, title: getCurrentTabName() },
  ];

  return (
    <>
      <PageSubheader
        renderChildren={({ isSticky }) => (
          <VendorProfileHead isSticky={isSticky} breadcrumbs={breadcrumbs} />
        )}
      />
      <PageBody>
        <Switch>
          <Route
            path={`${url}/${VENDOR_INFO_TABS.BASIC_DETAILS.path}`}
            component={() => (
              <BasicDetails isTagClickable={userCompany.type === companyStatus.RETAILER} />
            )}
            exact
          />
          <Route
            component={() => <CompanyOverview isFeatureDemoShown />}
            path={`${url}/${VENDOR_INFO_TABS.SOLUTION.path}`}
            exact
          />
          <Route
            component={TechnicalDetails}
            path={`${url}/${VENDOR_INFO_TABS.DETAILS.path}`}
            exact
          />
          <Route
            component={Endorsements}
            path={`${url}/${VENDOR_INFO_TABS.ENDORSEMENTS.path}`}
            exact
          />
          <Route component={Company} path={`${url}/${VENDOR_INFO_TABS.COMPANY.path}`} exact />
          <Route component={Reviews} path={`${url}/${VENDOR_INFO_TABS.CONTACT.path}`} exact />

          <Redirect to={`${url}/${VENDOR_INFO_TABS.BASIC_DETAILS.path}`} />
        </Switch>
      </PageBody>
    </>
  );
};

VendorDetails.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: LocationType.isRequired,
};

export default VendorDetails;
