/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Form, Field } from 'react-final-form';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/Form/TextFieldInput';
import RatingField from './RatingField';
import RecommendSolutionField from './RecommendSolutionField';
import RatingsFieldArray from './RatingsFieldArray';
import initialValues from './constants';
import validate from './validation';
import * as classes from './styles';

const ReviewVendorForm = ({ onClose, onSubmit }) => {
  const handleSubmitReview = ({ ratings, overallRating, ...rest }) => {
    const body = { ratings: [overallRating, ...ratings], ...rest };

    onSubmit(body);
  };

  return (
    <div>
      <Typography sx={{ mb: 5 }} variant="h1">
        New rating and review
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Pros and Cons are mandatory if the overall rating is less than three ticks and/or you
        wouldn&apos;t recommend this solution
      </Typography>

      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={handleSubmitReview}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              css={classes.field}
              label="Overall rating"
              name="overallRating.value"
              component={RatingField}
            />

            <Field
              css={classes.field}
              name="recommendSolution"
              component={RecommendSolutionField}
            />

            <Divider sx={{ mb: 2 }} />

            <FieldArray css={classes.ratings} name="ratings" component={RatingsFieldArray} />
            <Field sx={{ mb: 2 }} label="Pros" type="text" name="pros" multiline rows="2" component={TextFieldInput} />
            <Field sx={{ mb: 2 }} label="Cons" type="text" name="cons" multiline rows="2" component={TextFieldInput} />

            <div css={classes.buttons}>
              <Button type="submit">Save</Button>
              {!!onClose && <Button variant="outlined" onClick={onClose}>Close</Button>}
            </div>
          </form>
        )}
      />
    </div>
  );
};

ReviewVendorForm.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ReviewVendorForm.defaultProps = {
  onClose: null,
};

export default ReviewVendorForm;
