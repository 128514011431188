/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NativeSelect from '@mui/material/NativeSelect';

import AddUserForm from 'components/ManageUsers/AddUserForm';
import ChallengeWatcherType from 'types/ChallengeWatcher';
import * as classes from './styles';

const options = {
  EXISTING_USER: 'existingUser',
  INVITE_USER: 'inviteUser',
};

const AddParticipant = ({ participants, onSubmit }) => {
  const [value, onChange] = useState(options.EXISTING_USER);

  const activeUsers = useSelector((state) => state.user.company.users);
  const invitedUsers = useSelector((state) => state.user.company.inviteUserToCompanies);

  const handleSaveRegisteredUser = (e) => {
    onSubmit({ email: e.target.value });

    setTimeout(() => {
      e.target.value = '';
    }, 1000);
  };

  const isActiveUserSelected = ({ id }) => participants.some(({ userId }) => userId === id);

  const isInvitedUserSelected = ({ invitedUserEmail }) => participants
    .some(({ email }) => email === invitedUserEmail);

  return (
    <div>
      <FormControl component="fieldset" css={classes.formControl}>
        <FormLabel component="legend">Select from existing users or invite someone new</FormLabel>

        <RadioGroup
          css={classes.radioGroup}
          name="is-participant-registered"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <FormControlLabel value={options.EXISTING_USER} control={<Radio />} label="Existing user" />
          <FormControlLabel value={options.INVITE_USER} control={<Radio />} label="Invite new user" />
        </RadioGroup>
      </FormControl>

      {value === options.INVITE_USER ? (
        <AddUserForm onSubmit={onSubmit} />
      ) : (
        <div>
          <NativeSelect css={classes.select} onChange={handleSaveRegisteredUser}>
            <option value="" hidden>Please select</option>
            {activeUsers.map((user) => (
              <option key={user.id} value={user.email} disabled={isActiveUserSelected(user)}>
                {user.firstName} {user.lastName} - {user.email}
              </option>
            ))}
            {invitedUsers.map((user) => (
              <option
                key={user.id}
                value={user.invitedUserEmail}
                disabled={isInvitedUserSelected(user)}
              >
                {user.invitedUserEmail}
              </option>
            ))}
          </NativeSelect>
        </div>
      )}
    </div>
  );
};

AddParticipant.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  participants: PropTypes.arrayOf(ChallengeWatcherType.isRequired).isRequired,
};

export default AddParticipant;
