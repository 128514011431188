import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import EmptyReview from 'components/Reviews/EmptyReview';
import routes from 'constants/routes';
import 'styles/VendorProfile.scss';

const EmptyReviewsList = ({
  companyName,
  history,
  isVendorUser,
  onAddReview,
  canReviewCompany,
}) => (
  <div>
    <Typography className="vendor-profile-title" variant="h1">
      Ratings and reviews
    </Typography>
    {isVendorUser && (
      <EmptyReview
        buttonProps={{
          onClick: () => history.push(routes.REQUEST_REVIEW),
          text: 'Request a review',
        }}
        text="You have not received any reviews"
      />
    )}
    {canReviewCompany && (
      <EmptyReview
        buttonProps={{
          onClick: onAddReview,
          text: 'Rate and Review',
        }}
        text={`Be the first to review ${companyName}`}
      />
    )}
    {!canReviewCompany && !isVendorUser && (
      <Typography variant="body1">This company doesn&apos;t have reviews</Typography>
    )}
  </div>
);

EmptyReviewsList.propTypes = {
  canReviewCompany: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  isVendorUser: PropTypes.bool.isRequired,
  onAddReview: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(EmptyReviewsList);
