import { generatePath } from 'react-router-dom';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';

export const getEventDetailsPagePathname = (companyType) => (
  companyType === companyStatus.RETAILER
    ? routes.RETAILER_EVENT_DETAILS
    : routes.VENDOR_EVENT_DETAILS
);

export const getEventDetailsPageUrl = (event, companyType) => {
  const pathname = getEventDetailsPagePathname(companyType);
  const [slug] = event.slugs.slice(-1);

  return generatePath(pathname, { slug });
};
