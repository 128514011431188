import PropTypes from 'prop-types';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';

const FeatureType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
});

export const SupportType = PropTypes.shape({
  details: PropTypes.string,
  escrow: PropTypes.oneOf(Object.values(yesNoOptions)),
  purchase: PropTypes.oneOf(Object.values(yesNoOptions)),
  feature: PropTypes.arrayOf(FeatureType.isRequired),
});

export const TrialType = PropTypes.shape({
  terms: PropTypes.string.isRequired,
  type_terms: PropTypes.string.isRequired,
});

export const CostType = PropTypes.shape({
  additional: PropTypes.string,
  licence: PropTypes.oneOf(Object.values(yesNoOptions)),
  retailers: PropTypes.string,
});

export const ProductServiceType = PropTypes.shape({
  bussinessBenefits: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  cost: CostType,
  dataSecurity: PropTypes.string,
  dataPrivacyCompliance: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string.isRequired),
  integrationDataRequirement: PropTypes.string,
  integrationDataRequired: PropTypes.string,
  integrations: PropTypes.arrayOf(PropTypes.string.isRequired),
  pricing: PropTypes.arrayOf(PropTypes.string.isRequired),
  showcaseProduct: PropTypes.oneOf(Object.values(yesNoOptions)),
  techoverview: PropTypes.string,
  trial: TrialType,
  videoLink: PropTypes.string,
  websiteScreenshot: PropTypes.string,
});

export const StaffMemberType = PropTypes.shape({
  name: PropTypes.string,
  position: PropTypes.string,
  avatar: PropTypes.string,
});

export const FinancialDataType = PropTypes.shape({
  crunchBaseURI: PropTypes.string,
  employees: PropTypes.shape({
    UK: PropTypes.string,
    globally: PropTypes.string,
    // TODO: find out why some documents contain this field
    employees: PropTypes.string,
  }),
  funding: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.string,
  }),
  growth: PropTypes.string,
  isVentureBacked: PropTypes.oneOf(Object.values(yesNoOptions)),
  planningGrowth: PropTypes.oneOf(Object.values(yesNoOptions)),
});

export const FoundedType = PropTypes.shape({
  where: PropTypes.string,
  when: PropTypes.string,
});
