import PropTypes from 'prop-types';
import companyRevenue from 'constants/companyRevenue';
import challengeStatus from 'constants/challengeStatus';
import ChallengeWatcherType from './ChallengeWatcher';
import MilestoneType from './Milestone';

export const ChallengeYesNoQuestionType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  requirement: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  mustHave: PropTypes.bool.isRequired,
});

const ChallengeType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  business_description: PropTypes.string,
  challenge_description: PropTypes.string,
  response_deadline: PropTypes.string,
  companyRevenue: PropTypes.oneOf([...companyRevenue, '']),
  objectives: PropTypes.string,
  constraints: PropTypes.string,
  yesNoQuestions: PropTypes.arrayOf(ChallengeYesNoQuestionType.isRequired),
  openEndedQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      isRequired: PropTypes.bool.isRequired,
      question: PropTypes.string.isRequired,
    }),
  ),
  whitelistVendors: PropTypes.arrayOf(PropTypes.string.isRequired),
  blacklistVendors: PropTypes.arrayOf(PropTypes.string.isRequired),
  metadata: PropTypes.arrayOf(PropTypes.string.isRequired),

  challengeReviewers: PropTypes.arrayOf(ChallengeWatcherType.isRequired),
  milestones: PropTypes.arrayOf(MilestoneType.isRequired),

  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
});

export const EventChallengeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    challengeStatus.DRAFT,
    challengeStatus.SUBMITTED,
    challengeStatus.CANCELLED,
  ]).isRequired,
  name: PropTypes.string,
  response_deadline: PropTypes.string,
  challenge_description: PropTypes.string,
  objectives: PropTypes.string,
  source: PropTypes.string.isRequired,

  milestones: PropTypes.arrayOf(MilestoneType.isRequired),
});

export default ChallengeType;
