/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GeneralModal from 'components/UI/GeneralModal';
import * as classes from './styles';

const DeleteUserModal = ({ isOpen, onClose, onRemove }) => (
  <GeneralModal
    css={classes.modal}
    isOpen={isOpen}
    onClose={onClose}
    title="Are you sure you want to delete user?"
  >
    <Typography css={classes.subtitle} variant="body2">
      After deleting this user will be removed from company.
    </Typography>
    <div css={classes.buttons}>
      <Button onClick={onRemove}>Delete</Button>
      <Button onClick={onClose} variant="text">Cancel</Button>
    </div>
  </GeneralModal>
);

DeleteUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DeleteUserModal;
