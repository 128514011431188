import omit from 'lodash/omit';
import defaultValues from 'components/ChallengeForm/emptyFormValues';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';

const isFieldArrayFilled = (value) => !!(Array.isArray(value) && value.length);

const formatInitialValues = ({
  whitelistVendors,
  blacklistVendors,
  openEndedQuestions,
  yesNoQuestions,
  source,
  ...rest
}, milestones) => {
  const filteredFields = omit(rest, ['companyId']);

  const formattedYesNoQuestions = yesNoQuestions.map((question) => ({
    mustHave: question.mustHave,
    description: question.requirement.description,
  }));

  const data = {
    whitelistVendors: isFieldArrayFilled(whitelistVendors)
      ? whitelistVendors
      : defaultValues.whitelistVendors,
    blacklistVendors: isFieldArrayFilled(blacklistVendors)
      ? blacklistVendors
      : defaultValues.blacklistVendors,
    openEndedQuestions: isFieldArrayFilled(openEndedQuestions)
      ? openEndedQuestions
      : defaultValues.openEndedQuestions,
    milestones,
    source,
    ...filteredFields,
  };

  return (
    source === DEFAULT_CHALLENGE_SOURCE
      ? { ...data, yesNoQuestions: formattedYesNoQuestions }
      : data
  );
};

export default formatInitialValues;
