import { css } from '@emotion/react';

export const invitedUser = (theme) => css`
  color: ${theme.primaryPalette.orange};
`;

export const adminRole = (theme) => css`
  color: ${theme.primaryPalette.blue};
`;

export const managerRole = (theme) => css`
  color: ${theme.palette.primary.main};
`;

export const desktopUI = (theme) => (
  css`
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  `
);

export const mobileUI = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      display: none;
    }
  `
);

export const card = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const inviteButton = (theme) => (
  css`
    position: fixed;
    bottom: ${theme.spacing(5)};

    ${theme.breakpoints.up('lg')} {
      right: ${theme.spacing(10)};
    }

    ${theme.breakpoints.down('lg')} {
      right: ${theme.spacing(3)};
    }
  `
);
