/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import * as classes from './styles';

const HomeMenuItem = ({
  onClick,
  icon: Icon,
  description,
  name,
  notificationCount,
}) => (
  <div css={classes.menuItem} onClick={onClick}>
    <div css={classes.menuItemWrapper}>
      <Icon css={classes.icon} />
      <div css={classes.menuItemMain}>
        <div css={classes.menuItemName}>{name}</div>
        {notificationCount > 0 && <div css={classes.notification}>{notificationCount}</div>}
      </div>
    </div>
    <div>{description}</div>
  </div>
);

HomeMenuItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.func.isRequired]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  notificationCount: PropTypes.number,
};

HomeMenuItem.defaultProps = {
  notificationCount: 0,
};

export default HomeMenuItem;
