/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';

import { savePrimaryContact } from 'store/thunk/company';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import UserType from 'types/User';
import InvitedUserType from 'types/InvitedUser';
import PrimaryContactType from 'types/PrimaryContact';
import vendorPrimaryContactRoleNames from 'constants/vendorPrimaryContactRoleNames';
import EditPrimaryContact from '../EditPrimaryContact';
import formStyles from './styles';

const PrimaryContacts = ({
  className,
  users,
  invitedUsers,
  salesLead,
  marketingLead,
  technicalLead,
  onSaveContact,
}) => {
  const getInitialValues = (position) => (
    position ? { ...position, isNewUser: yesNoOptions.NO } : { isNewUser: yesNoOptions.YES }
  );

  const handleSaveContact = (positionName) => (values) => {
    const filteredValues = values.isNewUser === yesNoOptions.YES
      ? pick(values, ['contactFirstName', 'contactLastName', 'contactEmail', 'isNewUser'])
      : pick(values, ['id', 'isRegistered', 'isNewUser']);

    onSaveContact({ ...filteredValues, role: positionName });
  };

  return (
    <div className={className}>
      <EditPrimaryContact
        css={formStyles}
        onSubmit={handleSaveContact(vendorPrimaryContactRoleNames.SALES_LEAD)}
        initialValues={getInitialValues(salesLead)}
        title="Sales lead"
        activeUsers={users}
        invitedUsers={invitedUsers}
      />
      <EditPrimaryContact
        css={formStyles}
        onSubmit={handleSaveContact(vendorPrimaryContactRoleNames.TECHNICAL_LEAD)}
        initialValues={getInitialValues(technicalLead)}
        title="Technical lead"
        activeUsers={users}
        invitedUsers={invitedUsers}
      />
      <EditPrimaryContact
        css={formStyles}
        onSubmit={handleSaveContact(vendorPrimaryContactRoleNames.MARKETING_LEAD)}
        initialValues={getInitialValues(marketingLead)}
        title="Marketing lead"
        activeUsers={users}
        invitedUsers={invitedUsers}
      />
    </div>
  );
};

PrimaryContacts.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(UserType.isRequired).isRequired,
  invitedUsers: PropTypes.arrayOf(InvitedUserType).isRequired,
  salesLead: PropTypes.shape(PrimaryContactType),
  technicalLead: PropTypes.shape(PrimaryContactType),
  marketingLead: PropTypes.shape(PrimaryContactType),
  onSaveContact: PropTypes.func.isRequired,
};

PrimaryContacts.defaultProps = {
  className: null,
  salesLead: null,
  technicalLead: null,
  marketingLead: null,
};

export default connect(
  (state) => ({
    salesLead: state.user.company.salesLead,
    technicalLead: state.user.company.technicalLead,
    marketingLead: state.user.company.marketingLead,
    users: state.user.company.users,
    invitedUsers: state.user.company.inviteUserToCompanies,
  }),
  (dispatch) => ({ onSaveContact: (body) => dispatch(savePrimaryContact(body)) }),
)(PrimaryContacts);
