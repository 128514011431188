import { css } from '@emotion/react';

export const actions = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(3)};
  `
);

export const banner = (theme) => (
  css`
    margin-bottom: ${theme.spacing(5)};
    color: ${theme.primaryPalette.white};
    background-color: ${theme.secondaryPalette.blue};
  `
);
