import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { FinancialDataType, StaffMemberType } from 'types/Vendor';
import insertHost from 'utils/picture';
import CompanyStats from '../CompanyStats';
import 'styles/VendorProfile.scss';
import './Company.scss';

const Company = ({ financialData, staffMembers }) => (
  <div>
    <Typography variant="h1" className="vendor-profile-block-title">Company</Typography>

    <CompanyStats
      className="vendor-profile-block"
      financialData={financialData}
    />

    {!!staffMembers && !!staffMembers.length && (
      <div className="vendor-profile-block">
        <Typography className="vendor-profile-section-title" variant="h3">
          Key team members
        </Typography>
        <ul className="vendor-staff-members">
          {staffMembers.map((member, i) => (
            <li key={i} className="vendor-staff-members__item">
              {!!member.avatar && (
                <div className="vendor-staff-members__image-container">
                  <img
                    className="vendor-staff-members__image"
                    src={insertHost(member.avatar)}
                    alt=""
                  />
                </div>
              )}
              <div>
                <Typography variant="subtitle2">{member.name}</Typography>
                <Typography variant="body1">{member.position}</Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

Company.propTypes = {
  financialData: FinancialDataType,
  staffMembers: PropTypes.arrayOf(StaffMemberType.isRequired),
};

Company.defaultProps = {
  financialData: null,
  staffMembers: [],
};

const mapStateToProps = (state) => {
  const { financialData, staffMembers } = state.vendorInfo.company.vendors;

  return { financialData, staffMembers };
};

export default connect(mapStateToProps)(Company);
