import { css } from '@emotion/react';

const wrap = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${theme.boldFont};
  `
);

export default wrap;
