import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import VideoPlayer from 'components/UI/VideoPlayer';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import { ChallengeYesNoQuestionType } from 'types/Challenge';
import { SubmittedChallengeResponseType } from 'types/ChallengeResponse';
import matchFreeFormAnswersWithQuestions from 'utils/matchFreeFormAnswersWithQuestions';
import LinkIcon from 'svg/link';
import ChallengeResponseYesNoAnswers from '../ChallengeResponseYesNoAnswers';
import SavedAttachment from '../SavedAttachment';
import './ResponseDetails.scss';

const ResponseDetails = ({
  challenge: { yesNoQuestions, openEndedQuestions },
  challengeResponse,
  title,
}) => {
  const formattedResponseUpdateDate = format(new Date(challengeResponse.updatedAt), 'dd MMM y');
  const freeFormAnswers = matchFreeFormAnswersWithQuestions(
    openEndedQuestions,
    challengeResponse.openEndedQuestions,
  );

  return (
    <div className="vendor-response-details">
      <div className="vendor-response-details__title">
        <Typography variant="h2">{title}</Typography>
        <Typography variant="body1">Last updated: {formattedResponseUpdateDate}</Typography>
      </div>

      <VideoPlayer
        className="vendor-response-details__video"
        url={challengeResponse.demonstrationVideo}
      />

      {!!(yesNoQuestions && yesNoQuestions.length) && (
        <ChallengeResponseYesNoAnswers
          className="vendor-response-details__answers"
          questions={yesNoQuestions}
          answers={challengeResponse.questions}
        />
      )}

      <div className="vendor-response-details__section">
        <Typography className="vendor-response-details__subtitle" variant="h3">
          Short product description
        </Typography>
        <Typography variant="body1">{challengeResponse.productDescription}</Typography>
      </div>

      {!!challengeResponse.challengeRespond && (
        <div className="vendor-response-details__section">
          <Typography className="vendor-response-details__subtitle" variant="h3">
            Vendor&apos;s response
          </Typography>
          <Typography variant="body1">{challengeResponse.challengeRespond}</Typography>
        </div>
      )}

      {!!challengeResponse.additionalCommentOnRequirements && (
        <div className="vendor-response-details__section">
          <Typography className="vendor-response-details__subtitle" variant="h3">
            Additional commentary on requirements
          </Typography>
          <Typography variant="body1">
            {challengeResponse.additionalCommentOnRequirements}
          </Typography>
        </div>
      )}

      {!!freeFormAnswers.length && (
        <div className="vendor-response-details__section">
          <Typography className="vendor-response-details__subtitle" variant="h3">
            Answers on free-form questions
          </Typography>

          <ul className="response-free-form-questions">
            {freeFormAnswers.map(({ question, answer }, i) => (
              <li className="response-free-form-questions__item" key={question}>
                <Typography
                  className="response-free-form-questions__label"
                  variant="subtitle2"
                >{`Q ${i + 1}. ${question}`}</Typography>
                {answer
                  ? <Typography variant="body1">{answer}</Typography>
                  : <Typography
                      className="response-free-form-questions__empty-answer"
                      variant="body1"
                    >
                      Optional question was not answered
                    </Typography>
                }
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="vendor-response-details__section">
        <Typography className="vendor-response-details__subtitle" variant="h3">
          Minimum deployment time
        </Typography>
        <Typography variant="body1">{challengeResponse.minimumDeploymentTime}</Typography>
      </div>

      <div className="vendor-response-details__section">
        <Typography variant="subtitle2" component="p">
          {challengeResponse.GDPRRegulations === yesNoOptions.YES ? 'Conforms' : "Doesn't conform"} with
          GDPR regulations
        </Typography>
      </div>

      <div className="vendor-response-details__section">
        <Typography className="vendor-response-details__subtitle" variant="h3">
          Client references of vendor
        </Typography>

        <ul className="response-client-references">
          {challengeResponse.clientReferences.map(({ companyName: clientName, companyUrl }) => (
            <li key={clientName || companyUrl} className="response-client-references__item">
              <Typography variant="body1">
                {clientName || companyUrl}
              </Typography>
              {!!companyUrl && (
                <a className="response-client-references__link" href={companyUrl} target="_blank" rel="noreferrer">
                  <LinkIcon />
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="vendor-response-details__section">
        <Typography className="vendor-response-details__subtitle" variant="h3">
          Additional information on case study
        </Typography>
        <Typography variant="body1">{challengeResponse.caseStudyInfo}</Typography>
      </div>

      {!!(challengeResponse.caseStudyAssets && challengeResponse.caseStudyAssets.length) && (
        <div className="vendor-response-details__section">
          <Typography className="vendor-response-details__subtitle" variant="h3">
            Case study assets
          </Typography>

          <ul className="response-case-studies">
            {challengeResponse.caseStudyAssets.map((asset) => (
              <li className="response-case-studies__item" key={asset.link}>
                <SavedAttachment {...asset} />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="vendor-response-details__section">
        <Typography className="vendor-response-details__subtitle" variant="h3">
          Pricing model
        </Typography>
        <Typography variant="body1">{challengeResponse.pricingModel}</Typography>
      </div>

      {!!(challengeResponse.additionalInfo || challengeResponse.attachment) && (
        <div className="vendor-response-details__section">
          <Typography className="vendor-response-details__subtitle" variant="h3">
            Additional information
          </Typography>
          {!!challengeResponse.additionalInfo && (
            <Typography className="vendor-response-details__additional-info" variant="body1">
              {challengeResponse.additionalInfo}
            </Typography>
          )}
          {!!challengeResponse.attachment && <SavedAttachment {...challengeResponse.attachment} />}
        </div>
      )}
    </div>
  );
};

ResponseDetails.propTypes = {
  challenge: PropTypes.shape({
    yesNoQuestions: PropTypes.arrayOf(ChallengeYesNoQuestionType.isRequired).isRequired,
    openEndedQuestions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    }).isRequired),
  }).isRequired,
  title: PropTypes.string.isRequired,
  challengeResponse: SubmittedChallengeResponseType.isRequired,
};

export default ResponseDetails;
