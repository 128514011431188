import PropTypes from 'prop-types';
import clsx from 'clsx';
import insertHost from 'utils/picture';
import { CaseStudyType } from 'types/Endorsements';
import SavedEndorsement from '../SavedEndorsement';
import './SavedCaseStudy.scss';

const SavedCaseStudy = ({ className, document, ...rest }) => (
  document.link ? (
    <a
      className={clsx('saved-case-study-link', className)}
      href={insertHost(document.link)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <SavedEndorsement document={document} {...rest} showLink={false} />
    </a>
  ) : (
    <SavedEndorsement className={className} document={document} {...rest} showLink={false} />
  )
);

SavedCaseStudy.propTypes = {
  className: PropTypes.string,
  document: CaseStudyType.isRequired,
};

SavedCaseStudy.defaultProps = {
  className: null,
};

export default SavedCaseStudy;
