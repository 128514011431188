/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import EventPageBreadcrumbs from 'components/Events/EventPageBreadcrumbs';
import EventPageTheme from 'components/Events/EventPageTheme';
import PageBody from 'components/UI/PageBody';
import BackButton from 'components/UI/BackButton';
import routes from 'constants/routes';
import EventType from 'types/Event';
import EventAttendeeType from 'types/EventAttendee';
import EventPriorityListType from 'types/EventPriorityList';
import withEventAttendeeRouteGuard from 'containers/withEventAttendeeRouteGuard';
import ManageBusinessPriorities from './ManageBusinessPriorities';
import * as classes from './styles';

const VendorEventBusinessPriorities = ({
  event,
  attendees,
  hostPriorityList,
  orderedAttendees,
}) => {
  const location = useLocation();
  const { primaryColor } = event;

  const content = (
    <PageBody>
      <EventPageBreadcrumbs
        sx={{ mb: 1 }}
        event={event}
        pageBreadcrumbs={[{ to: location, title: 'Set meeting priorities' }]}
      />
      <div css={classes.titleWrap}>
        <BackButton css={classes.backButton} />
        <Typography variant="h1">Set meeting priorities</Typography>
      </div>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Please tell us the attendees you are focused on
      </Typography>
      <Typography sx={{ mb: 3 }} variant="body1">
        We will always attempt to arrange one-to-one meetings with your highest priorities. However,
        events always have limited capacity and this is not always possible.
      </Typography>
      {!!attendees.length && (
        <ManageBusinessPriorities
          event={event}
          attendees={attendees}
          orderedAttendees={orderedAttendees}
          priorityListId={hostPriorityList ? hostPriorityList.id : null}
        />
      )}
    </PageBody>
  );

  return primaryColor
    ? (
      <EventPageTheme primaryColor={primaryColor}>
        {content}
      </EventPageTheme>
    ) : content;
};

VendorEventBusinessPriorities.propTypes = {
  event: EventType.isRequired,
  hostPriorityList: EventPriorityListType,
  orderedAttendees: PropTypes.arrayOf(EventAttendeeType).isRequired,
  attendees: PropTypes.arrayOf(EventAttendeeType).isRequired,
};

VendorEventBusinessPriorities.defaultProps = {
  hostPriorityList: null,
};

export default withEventAttendeeRouteGuard(routes.VENDOR_EVENT_DETAILS)(
  VendorEventBusinessPriorities,
);
