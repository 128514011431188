import sortOrder from 'constants/sortOrder';
import challengeDashboardRelationsFragment from './fragments/challengeDashboardRelationsFragment';
import APIClient from './client';

class EventAPI extends APIClient {
  constructor() {
    super('/events');
  }

  getEventBySlug = (slug) => {
    const filter = {
      where: {
        slugs: slug,
      },
    };

    return this.findOne(filter);
  };

  getEventWithParticipationRequest = (slug, userId) => {
    const filter = {
      where: {
        slugs: slug,
      },
      include: {
        relation: 'participationRequests',
        scope: {
          where: { userId },
          limit: 1,
        },
      },
    };

    return this.findOne(filter);
  };

  getRetailAttendees = async (id) => {
    const filter = {
      fields: ['id', 'position', 'companyId'],
      include: [
        {
          relation: 'eventRetailInvitations',
          scope: {
            where: {
              eventId: id,
            },
          },
        },
        {
          relation: 'eventTopicPriorityLists',
          scope: {
            include: ['notes', 'topics'],
            where: {
              eventId: id,
            },
          },
        },
        {
          relation: 'company',
          scope: {
            fields: ['id', 'name'],
          },
        },
      ],
    };

    const { data: retailUsers } = await this.getRequest(`/${id}/retailUsers`, filter);

    return retailUsers;
  };

  getAgenda = async (id) => {
    try {
      const { data: agenda } = await this.getRequest(`/${id}/agenda`);

      return agenda;
    } catch (_) {
      return null;
    }
  };

  getEventDetailsForRetailer = (id, userId) => {
    const filter = {
      include: [
        {
          relation: 'challenges',
          scope: {
            include: challengeDashboardRelationsFragment,
            order: `response_deadline ${sortOrder.DESC}`,
          },
        },
        {
          relation: 'vendors',
          scope: {
            include: [
              {
                relation: 'vendors',
                scope: {
                  fields: [
                    'techoveriew',
                    'productService',
                    'companyOverview',
                    'solutionOverview',
                    'shortDescription',
                    'bussinessBenefits',
                    'founded',
                    'clientsList',
                    'stageOfMaturity',
                    'features',
                  ],
                },
              },
              {
                relation: 'tags',
                scope: {
                  fields: ['name'],
                },
              },
              {
                relation: 'ratingsReviews',
                scope: {
                  fields: ['pros', 'cons', 'ratings'],
                },
              },
            ],
          },
        },
        {
          relation: 'topicPriorityLists',
          scope: {
            include: ['topics', 'notes'],
            where: {
              userId,
            },
          },
        },
      ],
    };

    return this.findById(id, filter);
  };
}

const eventAPI = new EventAPI();

export default eventAPI;
