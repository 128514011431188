import { css } from '@emotion/react';

export const answer = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }

    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `
);

export const info = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const options = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      flex-shrink: 0;
      margin-left: ${theme.spacing(2)};
    }
  `
);

export const button = (theme) => (
  css`
    &:first-of-type {
      margin-right: ${theme.spacing(2)};
    }
  `
);
