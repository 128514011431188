import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Head from 'components/Head';
import PageHeadContext from './PageHeadContext';

const PageHeadProvider = ({ children }) => {
  const [head, setPageTitle] = useState('Validify');

  const onSetPageTitle = useCallback((value) => setPageTitle(value), []);

  return (
    <PageHeadContext.Provider value={{ onSetPageTitle }}>
      <Head title={head} />
      {children}
    </PageHeadContext.Provider>
  );
};

PageHeadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeadProvider;
