/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FieldInputType from 'types/FieldInput';
import buttonWrap from './styles';

const RadioButton = ({
  className,
  id,
  label,
  input,
  ...rest
}) => (
  <FormControlLabel
    css={buttonWrap}
    className={className}
    control={<Radio id={id} {...input} {...rest} />}
    label={<Typography variant="body2">{label}</Typography>}
  />
);

RadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  input: PropTypes.oneOfType([
    FieldInputType.isRequired,
    PropTypes.shape(fieldInputPropTypes),
  ]).isRequired,
};

RadioButton.defaultProps = {
  className: null,
  id: undefined,
};

export default RadioButton;
