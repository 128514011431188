import { css } from '@emotion/react';

const requirement = (theme) => css`
  box-sizing: border-box;
  width: 100%;
  margin-right: 0;
  padding: ${theme.spacing(0.75)} ${theme.spacing(0.75)} ${theme.spacing(0.75)} ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};

  & .MuiCheckbox-root {
    align-self: baseline;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export default requirement;
