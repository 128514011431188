import PropTypes from 'prop-types';
import reviewRequestStatus from 'constants/reviewRequestStatus';

const ReviewRequestType = PropTypes.shape({
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    logo: PropTypes.string,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.oneOf(Object.values(reviewRequestStatus)).isRequired,
  textMessage: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  // If invite was sent when user registered on platform
  userId: PropTypes.string,
  // If invite was sent when user didn't register on platform
  email: PropTypes.string,
});

export default ReviewRequestType;
