import getErrorMessage from 'utils/getErrorMessage';

const ENQUEUE_ERROR_SNACKBAR = 'ENQUEUE_ERROR_SNACKBAR';
const ENQUEUE_SUCCESS_SNACKBAR = 'ENQUEUE_SUCCESS_SNACKBAR';
const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

const DEFAULT_ERROR_MESSAGE = 'Failed to execute request';
const DEFAULT_SUCCESS_MESSAGE = 'Success';

export const sendErrorNotification = (error, fallbackMessage = DEFAULT_ERROR_MESSAGE) => ({
  type: ENQUEUE_ERROR_SNACKBAR,
  error,
  fallbackMessage,
});

export const sendSuccessNotification = (message = DEFAULT_SUCCESS_MESSAGE) => ({
  type: ENQUEUE_SUCCESS_SNACKBAR,
  message,
});

export const removeNotification = (key) => ({ type: REMOVE_SNACKBAR, key });

const initialState = [];

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_ERROR_SNACKBAR: {
      const message = getErrorMessage(action.error) || action.fallbackMessage;
      const notification = {
        key: Date.now(),
        message,
        options: { variant: 'error' },
      };

      return [...state, notification];
    }

    case ENQUEUE_SUCCESS_SNACKBAR: {
      const { message } = action;
      const notification = {
        key: Date.now(),
        message,
        options: { variant: 'success' },
      };

      return [...state, notification];
    }

    case REMOVE_SNACKBAR:
      return state.filter((notification) => notification.key !== action.key);

    default:
      return state;
  }
};

export default notificationReducer;
