import { authCookies } from 'constants/storageKeys';
import DEFAULT_COOKIE_EXPIRATION_DAYS from 'constants/defaultCookieExpiration';
import APIClient from './client';

const reviewsRelation = {
  relation: 'ratingsReviews',
  scope: {
    include: [
      'voteReviews',
      {
        relation: 'user',
        scope: {
          include: {
            relation: 'company',
            scope: {
              fields: ['id', 'name', 'logo'],
            },
          },
        },
      },
      {
        relation: 'company',
        scope: {
          fields: ['logo', 'id', 'name'],
        },
      },
    ],
  },
};

const getEventsFilter = (eventRelationName) => ({
  relation: eventRelationName,
  scope: {
    fields: ['id', 'slugs'],
    where: {
      endDate: {
        gt: new Date(),
      },
    },
  },
});

class UserAPI extends APIClient {
  constructor() {
    super('/users');
  }

  loginUser = (credentials) => this.postRequest('/login', credentials);

  resetPassword = (newPassword, token) => this.postRequest(
    '/reset-password',
    { newPassword },
    { Authorization: token },
  );

  resetUserPassword = (email) => this.postRequest('/reset', { email });

  getUserWithRole = async (id) => {
    const filter = {
      fields: ['id', 'firstName', 'lastName', 'email', 'phoneNumber', 'invitedRoles'],
      include: 'invitedRoles',
    };

    const { data } = await this.findById(id, filter);

    return data;
  };

  // TODO: separate for retailer and vendor
  getUserWithCompany = async (id) => {
    const filter = {
      include: [
        'invitedRoles',
        reviewsRelation,
        {
          relation: 'company',
          scope: {
            include: [
              getEventsFilter('retailerEvents'),
              getEventsFilter('vendorEvents'),
              'vendors',
              'tags',
              'retailers',
              'inviteUserToCompanies',
              'integrationLabels',
              {
                relation: 'users',
                scope: {
                  include: [
                    'invitedRoles',
                  ],
                },
              },
            ],
          },
        },
      ],
    };

    const { data } = await this.findById(id, filter);

    return data;
  };

  changePassword = async (passwords) => {
    const response = await this.postRequest('/update-password', passwords);
    const { response: token } = response.data;

    this.cookies.set(authCookies.TOKEN, token, { expires: DEFAULT_COOKIE_EXPIRATION_DAYS });
  };

  IsEmailTaken = async (email) => {
    const { data: { count } } = await this.count({ email });

    return count > 0;
  };

  resendVerificationLink = (email) => this.postRequest('/resend-verification-link', { email });

  loadReviewerUserInfo = (id) => {
    const filter = { fields: ['id', 'email', 'firstName', 'lastName'] };

    return this.findById(id, filter);
  };

  deleteUserRole = (id, roleId) => this.deleteRequest(`/${id}/invitedRoles/rel/${roleId}`);

  registerNewUserWithCompany = (body) => this.postRequest('/register-new-user-with-company', body);

  registerPreRegisteredVendorForChallenge = (body) => this.postRequest('/register-vendor-for-challenge', body);

  registerRetailerForReview = (body) => this.postRequest('/register-retailer-for-review', body);

  registerInvitedUser = (body) => this.postRequest('/register-invited-user', body);

  registerWithTakenEmailDomain = (body) => this.postRequest('/register-with-taken-email-domain', body);

  registerForEvent = (body) => this.postRequest('/register-for-event', body);

  registerVendorForSharedChallenge = (body) => this.postRequest('/register-vendor-to-shared-challenge', body);
}

const userAPI = new UserAPI();

export default userAPI;
