import PropTypes from 'prop-types';
import challengeRequestParticipationStatus from 'constants/challengeParticipationRequestStatus';

const ChallengeParticipationRequestType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(challengeRequestParticipationStatus)).isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,

  challenge: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
});

export default ChallengeParticipationRequestType;
