const insertProtocol = (link) => `https://www.${link}`;

export const formatSocialMediaLink = (link, host, formatShortLink) => {
  const isFullPath = link.includes(host);

  if (isFullPath) {
    return insertProtocol(link);
  }

  const shortLink = formatShortLink ? formatShortLink(link) : link;
  const formattedLink = `${host}/${shortLink}`;

  return insertProtocol(formattedLink);
};

export const formatLinkedIn = (link) => {
  const isCompanyProfile = link.includes('company/');

  return isCompanyProfile ? link : `company/${link}`;
};

export const formatInstagram = (username) => username.replace('@', '');
