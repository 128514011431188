import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import SolutionsSearch from 'components/SolutionsSearch';

const SavedSolutions = () => {
  const savedSolutions = useSelector((state) => state.savedVendors);

  const handleSearch = useCallback((query) => {
    const formattedQuery = query.trim().toLowerCase();

    const savedCompanies = savedSolutions.map(({ company }) => company);

    if (!formattedQuery) {
      return savedCompanies;
    }

    return savedCompanies.filter(({ tags, name }) => (
      name.toLowerCase().includes(formattedQuery)
      || tags.some((tag) => (tag.name.toLowerCase().includes(formattedQuery)))
    ));
  }, [savedSolutions]);

  return (
    <SolutionsSearch
      onSearch={handleSearch}
      title="Saved solutions"
      isPublic={false}
      isFallbackShownOnEmptyQuery
    />
  );
};

export default SavedSolutions;
