import PropTypes from 'prop-types';
import ErrorContainer from 'components/UI/ErrorContainer';

const FetchError = ({ className }) => (
  <ErrorContainer
    className={className}
    title="Error"
    description="Something went wrong during the requested operation."
    isSupportLinkShown
  />
);

FetchError.propTypes = {
  className: PropTypes.string,
};

FetchError.defaultProps = {
  className: null,
};

export default FetchError;
