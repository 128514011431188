import { css } from '@emotion/react';

export const recommendation = (theme) => (
  css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.75)};
  `
);

export const recommendedIcon = (theme) => (
  css`
    color: ${theme.palette.primary.main};
  `
);

export const notRecommendedIcon = (theme) => (
  css`
    color: ${theme.palette.error.main};
  `
);
