import PropTypes from 'prop-types';

const DarkLogo = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="148"
    height="36"
    viewBox="0 0 148 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M58.5186 0.0229492H50.4545V29.1417H58.5186V0.0229492Z" fill="#777777" />
    <path
      d="M39.0909 21.5914C38.9684 23.7956 37.5437 24.8979 35.7106 24.8979C34.2443 24.8979 33.1849 23.9187 33.1849 22.9381C33.1849 21.509 34.1218 21.0608 35.9128 20.6526C37.0091 20.4426 38.0758 20.1001 39.0898 19.6327V21.5914H39.0909ZM46.8288 15.7146C46.8288 11.6341 46.9096 7.42944 36.8091 7.42944C31.7991 7.42944 26.1787 8.40861 25.9348 14.3682H33.4288C33.4694 13.4683 33.9587 12.2058 36.4427 12.2058C37.7459 12.2058 39.0898 12.7368 39.0898 14.1645C39.0898 15.5126 37.9898 15.8381 36.8903 16.0418C32.7769 16.8172 24.9567 16.5728 24.9567 23.2668C24.9567 27.7159 28.3369 29.6746 32.4101 29.6746C35.017 29.6746 37.501 29.1033 39.2112 27.1435H39.292C39.2675 27.8222 39.3648 28.4998 39.5794 29.144H47.8055C46.9075 27.7972 46.8284 26.0009 46.8284 24.4098V15.7146H46.8288Z"
      fill="#777777"
    />
    <path d="M70.532 9.81299H62.4679V29.1404H70.532V9.81299Z" fill="#777777" />
    <path d="M0 0.0229492L2.38018 8.01902H11.2328L9.04154 0.0229492H0Z" fill="#D1D700" />
    <path
      d="M18.7092 0.0229492L13.8799 17.6728H13.7983L11.675 9.79305L2.9032 9.77793L8.66794 29.1417H18.6862L27.6818 0.0229492H18.7092Z"
      fill="#777777"
    />
    <path d="M108.123 9.81299H100.059V29.1404H108.123V9.81299Z" fill="#777777" />
    <path d="M108.123 0.0229492H100.059V8.0165H108.123V0.0229492Z" fill="#D1D700" />
    <path
      d="M139.646 7.95935L136.225 20.1227H136.144L132.764 7.95935H121.158C121.036 6.00065 121.687 5.42827 123.642 5.42827C124.299 5.48384 124.952 5.58002 125.597 5.71626V0.161995C124.376 0.121316 123.194 0 121.973 0C118.714 0 116.679 0.244433 114.927 2.00046C113.38 3.55093 113.176 5.87718 113.257 7.95971H109.957V12.9398H113.257V29.1429H121.321V12.9384H125.96L127.715 17.7151C128.164 18.939 131.258 26.7353 131.258 27.7562C131.258 28.6951 130.974 29.1029 130.484 29.3474C129.914 29.6325 128.855 29.5515 128.042 29.5515H126.168V36H129.835C132.971 36 136.229 36.0406 138.348 33.3875C139.61 31.7949 140.343 29.3881 140.995 27.5107L148 7.96043L139.646 7.95935Z"
      fill="#777777"
    />
    <path d="M70.5333 0.0229492H62.4692V8.0165H70.5333V0.0229492Z" fill="#D1D700" />
    <path
      d="M84.9898 23.7956C82.0988 23.7956 81.4464 21.0597 81.4464 18.5304C81.4464 16.0411 82.0988 13.3059 84.9898 13.3059C87.8808 13.3059 88.5331 16.0418 88.5331 18.5304C88.5331 21.0604 87.8822 23.7956 84.9898 23.7956ZM88.249 10.2856H88.1667C86.9453 8.48926 84.7864 7.42873 82.3833 7.42873C75.2962 7.42873 73.3823 13.469 73.3823 18.4491C73.3823 23.7546 76.2748 29.6735 82.2619 29.6735C86.2133 29.6735 87.4346 28.1634 88.4523 26.8166H88.5345V29.1425H96.3127V0H88.249V10.2856Z"
      fill="#777777"
    />
  </svg>
);

DarkLogo.propTypes = {
  className: PropTypes.string,
};

DarkLogo.defaultProps = {
  className: null,
};

export default DarkLogo;
