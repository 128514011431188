const challengeResponseStatus = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  UNDER_REVISION: 'underRevision',
  REMOVED_FROM_SHORTLIST: 'disabled',
};

export default challengeResponseStatus;
