/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link, useParams } from 'react-router-dom';
import omit from 'lodash/omit';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import EventPageTheme from 'components/Events/EventPageTheme';
import EventHeader from 'components/Events/EventHeader';
import EventDetails from 'components/Events/EventDetails';
import EventAgenda from 'components/Events/EventAgenda';
import EventRetailerAttendees from 'components/Events/EventRetailerAttendees';
import ParticipationRequest from 'components/Events/ParticipationRequest';
import TabPanel from 'components/UI/TabPanel';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import EventParticipationRequestType from 'types/EventParticipationRequest';
import EventType from 'types/Event';
import EventAttendeeType from 'types/EventAttendee';
import { AGENDA_TAB, eventTabNames, vendorEventTabIds } from './constants';
import * as classes from './styles';

const VendorEvent = ({
  userInvitation,
  event,
  retailerAttendees,
  participationRequest,
}) => {
  const [activeTab, setActiveTab] = useState(vendorEventTabIds[eventTabNames.EVENT_DETAILS]);

  const { slug } = useParams();

  const { primaryColor } = event;

  const getA11yProps = (key) => ({
    'aria-controls': `event-tabpanel-attendees-${key}`,
  });

  const editVendorPrioritiesUrl = generatePath(
    routes.EDIT_VENDOR_EVENT_BUSINESS_PRIORITIES,
    { slug },
  );

  const canSubmitPriorities = !!userInvitation && userInvitation.canSavePriorities;

  const agendaTabTooltip = canSubmitPriorities ? null : 'You aren\'t allowed to submit priorities';

  const content = (
    <div>
      <EventHeader
        css={!userInvitation && classes.shortHeader}
        event={event}
        subtitle={canSubmitPriorities ? 'To attend the event we ask you to set meeting priorities' : null}
      >
        {canSubmitPriorities && (
          <Button component={Link} to={editVendorPrioritiesUrl}>
            Set meeting priorities
          </Button>
        )}
      </EventHeader>
      {userInvitation ? (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, id) => setActiveTab(id)}
            aria-label="event-tabs"
            variant="scrollable"
            scrollButtons={false}
            css={classes.tabs}
          >
            {Object.values(omit(eventTabNames, AGENDA_TAB))
              .map((tab) => (
              <Tab css={classes.tab} key={tab} label={tab} {...getA11yProps(tab)} />
              ))}
            {/* FIXME: a11y warning */}
            {canSubmitPriorities
              ? <Tab css={classes.tab} label="Agenda" {...getA11yProps('Agenda')} />
              : (
                <Tooltip arrow placement="top-start" title={agendaTabTooltip}>
                  <span>
                    <Tab css={classes.tab} disabled label="Agenda" {...getA11yProps('Agenda')} />
                  </span>
                </Tooltip>
              )}
          </Tabs>

          <div css={classes.content}>
            <TabPanel activeId={activeTab} id={vendorEventTabIds[eventTabNames.EVENT_DETAILS]}>
              <EventDetails event={event} />
            </TabPanel>
            <TabPanel activeId={activeTab} id={vendorEventTabIds[eventTabNames.ATTENDEES]}>
              <EventRetailerAttendees
                retailerAttendees={retailerAttendees}
                showHint={canSubmitPriorities}
              />
            </TabPanel>
            <TabPanel activeId={activeTab} id={vendorEventTabIds[eventTabNames[AGENDA_TAB]]}>
              <EventAgenda
                event={event}
                canLoadAgenda={canSubmitPriorities}
                companyType={companyStatus.VENDOR}
              />
            </TabPanel>
          </div>
        </>
      ) : (
        <div css={classes.content}>
          <ParticipationRequest eventId={event.id} participationRequest={participationRequest} />
        </div>
      )}
    </div>
  );

  return primaryColor
    ? (
      <EventPageTheme primaryColor={primaryColor}>
        {content}
      </EventPageTheme>
    ) : content;
};

VendorEvent.propTypes = {
  event: EventType.isRequired,
  userInvitation: PropTypes.shape({
    canSavePriorities: PropTypes.bool.isRequired,
  }),
  retailerAttendees: PropTypes.arrayOf(EventAttendeeType.isRequired),
  participationRequest: EventParticipationRequestType,
};

VendorEvent.defaultProps = {
  userInvitation: null,
  retailerAttendees: [],
  participationRequest: null,
};

export default VendorEvent;
