import { css } from '@emotion/react';

export const pageBody = css`
  flex: 1;
`;

export const content = (theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.up('sm')} {
    margin-bottom: ${theme.spacing(5)};
  }

  ${theme.breakpoints.down('sm')} {
    margin-bottom: ${theme.spacing(3)};
  }
`;

export const createChallengeLink = (theme) => (
  css`
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `
);

export const bottomBar = (theme) => (
  css`
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
);
