import reviewRequestStatus from 'constants/reviewRequestStatus';
import APIClient from './client';

class ReviewRequestAPI extends APIClient {
  constructor() {
    super('/endorsementsModel');
  }

  getInvitationForNewUser = (email) => (
    this.find({
      where: {
        email,
        status: reviewRequestStatus.PENDING,
      },
      include: {
        relation: 'company',
        scope: {
          fields: ['slug'],
        },
      },
    })
  );

  getPendingReviewRequests = (userId, email) => {
    const filter = {
      include: { relation: 'company', scope: { fields: ['logo', 'name', 'slug'] } },
      where: {
        and: [
          { status: reviewRequestStatus.PENDING },
          /**
           * Important: if a user received invitation before registration we can find his invite by
           * email. After registration, we store only his `id` in invitation document.
           */
          { or: [{ userId }, { email }] }],
      },
    };

    return this.find(filter);
  };
}

const reviewRequestAPI = new ReviewRequestAPI();

export default reviewRequestAPI;
