/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  fieldArrayFieldsPropTypes,
  fieldArrayMetaPropTypes,
  formValueSelector,
} from 'redux-form';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  AddCircle16Regular,
  ChevronDown20Filled,
  LightbulbFilament20Regular,
} from '@fluentui/react-icons';
import CHALLENGE_FORM_ID from '../formId';
import RequirementFields from '../RequirementFields';
import RequirementRecommendations from '../RequirementRecommendations';
import QuestionHintsModal from '../QuestionHintsModal';
import * as classes from './styles';

const challengeFormSelector = formValueSelector(CHALLENGE_FORM_ID);

const YesNoQuestionsFieldArray = ({ className, fields, meta }) => {
  const selectedTags = useSelector((state) => challengeFormSelector(state, 'metadata'));
  const addedQuestions = useSelector((state) => challengeFormSelector(state, 'yesNoQuestions'));

  const addedQuestionTexts = useMemo(
    () => addedQuestions && addedQuestions.map(({ description }) => description),
    [addedQuestions],
  );

  const [isCollapsed, setCollapsed] = useState(true);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [isModalOpen, setModal] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedQuestionIndex(null);
    }
  }, [isModalOpen]);

  const handleAddHintedRequirements = (addedRequirements) => {
    addedRequirements.forEach(({ description }) => fields.push({ description, mustHave: false }));

    if (typeof selectedQuestionIndex === 'number' && !fields.get(selectedQuestionIndex).description) {
      fields.move(selectedQuestionIndex, fields.length + addedRequirements.length - 1);
    }
  };

  const handleOpenModalFromAutocomplete = (fieldIndex) => {
    setModal(true);
    setSelectedQuestionIndex(fieldIndex);
  };

  return (
    <>
      <div className={className}>
        <div css={classes.header}>
          <Typography variant="h3" component="legend">Requirements</Typography>
          <Button
            variant="text"
            onClick={() => setModal(true)}
            startIcon={<LightbulbFilament20Regular />}
          >
            Requirements library
          </Button>
        </div>

        <div css={classes.content}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Add specific requirements that need a Yes or No answer. Select &apos;Must have&apos;
            checkbox to point out it later in requirements.
          </Typography>

          <div css={classes.questionList}>
            <div css={classes.collapseHeader} onClick={() => setCollapsed(!isCollapsed)}>
              <Typography variant="subtitle1">Question list</Typography>
              <ChevronDown20Filled css={[
                classes.collapseChevron, isCollapsed && classes.rotatedChevron]}
              />
            </div>
            <Collapse in={isCollapsed} css={classes.collapse}>
              <RequirementFields
                fields={fields}
                addedQuestionTexts={addedQuestionTexts}
                onOpenModal={handleOpenModalFromAutocomplete}
              />
            </Collapse>

            {!!(meta.error && meta.submitFailed) && (
              <FormHelperText error sx={{ mb: 2, mt: -2 }}>{meta.error}</FormHelperText>
            )}

            <RequirementRecommendations
              addedQuestionTexts={addedQuestionTexts}
              selectedTags={selectedTags}
              onOpenHints={() => setModal(true)}
              onAddRequirement={fields.push}
            />

            <Button
              onClick={() => fields.push({ description: '', mustHave: false })}
              variant="text"
              startIcon={<AddCircle16Regular />}
              type="button"
            >
              <span css={classes.desktopButtonLabel}>Add custom question</span>
              <span css={classes.mobileButtonLabel}>Add question</span>
            </Button>
          </div>
        </div>
      </div>

      <QuestionHintsModal
        handleAddHintedRequirements={handleAddHintedRequirements}
        isOpen={isModalOpen}
        onClose={() => setModal(false)}
        tags={selectedTags}
        addedQuestions={addedQuestionTexts}
      />
    </>
  );
};

YesNoQuestionsFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
};

YesNoQuestionsFieldArray.defaultProps = {
  className: null,
};

export default YesNoQuestionsFieldArray;
