/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

import GeneralModal from 'components/UI/GeneralModal';
import EventChallenge from 'components/RetailerChallenges/EventChallenge';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import ChallengeType, { EventChallengeType } from 'types/Challenge';
import ChallengeSynopsis from '../ChallengeSynopsis';
import NoteForChallengeResponder from '../NoteForChallengeResponder';
import noteStyles from './styles';

const ChallengeRequirementsModal = ({ className, challenge, isNoteForResponderShown }) => {
  const [isModalOpen, setModal] = useState(false);

  return (
    <>
      <Button className={className} onClick={() => setModal(true)} type="button">
        View requirements
      </Button>

      <GeneralModal isOpen={isModalOpen} onClose={() => setModal(false)} title="RFI details">
        <Typography sx={{ mb: 3 }} variant="h2">
          {challenge.name}
        </Typography>
        {
          challenge.source === DEFAULT_CHALLENGE_SOURCE
            ? <ChallengeSynopsis challenge={challenge} />
            : <EventChallenge challenge={challenge} />
        }
        {isNoteForResponderShown && <NoteForChallengeResponder css={noteStyles} />}
      </GeneralModal>
    </>
  );
};

ChallengeRequirementsModal.propTypes = {
  className: PropTypes.string,
  isNoteForResponderShown: PropTypes.bool.isRequired,
  challenge: PropTypes.oneOfType([ChallengeType, EventChallengeType]).isRequired,
};

ChallengeRequirementsModal.defaultProps = {
  className: null,
};

export default ChallengeRequirementsModal;
