/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import SavedTopicPriorityList from 'components/Events/SavedTopicPriorityList';
import getEventGuestName from 'components/Events/utils/getEventGuestName';
import PriorityCardMenu from 'components/Events/PriorityCardMenu';
import priorityTypes from 'constants/priorityTypes';
import EventAttendeeType from 'types/EventAttendee';
import SelectAttendeeForm from '../SelectAttendeeForm';
import * as classes from './styles';

const AttendeeCard = ({
  slot,
  onSelect,
  onClose,
  onRemove,
  onSubmit,
  unorderedAttendees,
  isAttendeeSelected,
}) => {
  const [areRetailerPrioritiesShown, setShowRetailerPriories] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setShowRetailerPriories(false);
    setMenuOpen(false);
  }, [isAttendeeSelected]);

  const isSlotFilled = typeof slot.id === 'string';

  return (
    <>
      <div css={classes.head}>
        {isAttendeeSelected ? (
          <SelectAttendeeForm
            css={classes.form}
            onSubmit={onSubmit}
            onClose={onClose}
            options={isSlotFilled && isAttendeeSelected
              ? [slot, ...unorderedAttendees]
              : unorderedAttendees}
            initialValues={isSlotFilled ? { id: slot.id } : null}
          />
        ) : (
          <div css={classes.savedData}>
            <Typography
              variant={isSlotFilled ? 'subtitle2' : 'body2'}
              component="span"
              css={[classes.defaultAttendee, !isSlotFilled && classes.emptyAttendee]}
              onClick={onSelect}
            >
              {isSlotFilled ? getEventGuestName(slot) : 'Select attendee'}
            </Typography>

            {isSlotFilled && (
              <div css={classes.iconsWrap}>
                <PriorityCardMenu
                  isDisabled={!(slot.topics && slot.topics.length)}
                  isExpanded={areRetailerPrioritiesShown}
                  onToggleExpandCard={() => setShowRetailerPriories((prev) => !prev)}
                  priorityItemName={priorityTypes.ATTENDEE}
                  onEdit={onSelect}
                  onRemove={() => onRemove(slot)}
                  isMenuOpen={isMenuOpen}
                  setMenuOpen={setMenuOpen}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {!isAttendeeSelected && isSlotFilled && !!slot.topics.length && (
        <Collapse in={areRetailerPrioritiesShown} css={classes.collapse}>
          <SavedTopicPriorityList
            css={classes.priorityList}
            topics={slot.topics}
            notes={slot.notes}
          />
        </Collapse>
      )}
    </>
  );
};

AttendeeCard.propTypes = {
  className: PropTypes.string,
  slot: PropTypes.oneOfType([
    EventAttendeeType,
    PropTypes.shape({ id: PropTypes.number.isRequired }),
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isAttendeeSelected: PropTypes.bool.isRequired,
  unorderedAttendees: PropTypes.arrayOf(
    EventAttendeeType.isRequired,
  ).isRequired,
};

AttendeeCard.defaultProps = {
  className: null,
};

export default AttendeeCard;
