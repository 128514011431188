const PERSONAL_EMAIL_DOMAINS = [
  'gmail.com',
  'googlemail.com',
  'hotmail.com',
  'outlook.com',
  'btinternet.com',
  'yahoo.co.uk',
  'inbox.com',
  'mail.com',
  'icloud.com',
  'yahoo.com',
  'aol.com',
  'hotmail.co.uk',
  'msn.com',
  'live.com',
  'live.co.uk',
  'me.com',
];

export default PERSONAL_EMAIL_DOMAINS;
