import APIClient from './client';

class ChallengeShortlistedCompanyAPI extends APIClient {
  constructor() {
    super('/challenge-shortlisted-companies');
  }
}

const challengeShortlistedCompanyAPI = new ChallengeShortlistedCompanyAPI();

export default challengeShortlistedCompanyAPI;
