/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputAdornment from '@mui/material/InputAdornment';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';

import TextFieldInput from 'components/UI/TextFieldInput';
import {
  validateTwitterLink,
  validateInstagramLink,
  validateLinkedInLink,
  validateFacebookLink,
} from 'lib/validation';
import adornmentStyles from './styles';

class SocialMediaFormSection extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  static renderSocialMediaInput = ({ adornmentIcon, ...props }) => (
    <TextFieldInput
      {...props}
      endAdornment={
        <InputAdornment css={adornmentStyles} position="end">
          {adornmentIcon}
        </InputAdornment>
      }
    />
  );

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <Field
          sx={{ mb: 2 }}
          type="url"
          name="linkedin"
          placeholder="https://www.linkedin.com/awesomeCo"
          validate={[validateLinkedInLink]}
          adornmentIcon={<LinkedIn />}
          component={SocialMediaFormSection.renderSocialMediaInput}
        />
        <Field
          sx={{ mb: 2 }}
          type="url"
          name="facebook"
          adornmentIcon={<Facebook />}
          placeholder="https://www.facebook.com/awesomeCo"
          validate={[validateFacebookLink]}
          component={SocialMediaFormSection.renderSocialMediaInput}
        />
        <Field
          sx={{ mb: 2 }}
          type="url"
          name="twitter"
          placeholder="e.g. @awesomeCo"
          adornmentIcon={<Twitter />}
          validate={[validateTwitterLink]}
          component={SocialMediaFormSection.renderSocialMediaInput}
        />
        <Field
          type="url"
          name="instagram"
          placeholder="e.g. @awesomeCo"
          adornmentIcon={<Instagram />}
          validate={[validateInstagramLink]}
          component={SocialMediaFormSection.renderSocialMediaInput}
        />
      </div>
    );
  }
}

export default SocialMediaFormSection;
