/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import RadioButton from 'components/Form/RadioButton';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import FieldInputType from 'types/FieldInput';
import FieldMetaType from 'types/FieldMeta';
import fieldsStyles from './styles';

const RadioButtonYesNo = ({
  className,
  input,
  meta,
  title,
}) => (
  <FormControl className={className} component="fieldset">
    <FormLabel sx={{ mb: 1 }} component="legend" error={!!(meta.submitFailed && meta.error)}>
      {title}
    </FormLabel>
    <div css={fieldsStyles}>
      <Field
        id={`${input.name}.yes`}
        type="radio"
        label="Yes"
        value={yesNoOptions.YES}
        name={input.name}
        component={RadioButton}
      />
      <Field
        id={`${input.name}.no`}
        type="radio"
        label="No"
        value={yesNoOptions.NO}
        name={input.name}
        component={RadioButton}
      />
    </div>
  </FormControl>
);

RadioButtonYesNo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  input: PropTypes.oneOfType([
    FieldInputType,
    PropTypes.shape(fieldInputPropTypes),
  ]).isRequired,
  meta: PropTypes.oneOfType([
    FieldMetaType,
    PropTypes.shape(fieldMetaPropTypes),
  ]).isRequired,
};

RadioButtonYesNo.defaultProps = {
  className: null,
};

export default RadioButtonYesNo;
