import { css } from '@emotion/react';
import { lighten } from '@mui/material/styles';

export const text = (theme) => (
  css`
    color: ${theme.primaryPalette.mediumGrey};
  `
);

export const noResults = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(5)};
    }

    ${theme.breakpoints.up('sm')} {
      margin-bottom: ${theme.spacing(2.5)};
    }
  `
);

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
`;

export const iconContainer = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin-bottom: ${theme.spacing(4)};
    /* TODO: move me to theme */
    background-color: ${lighten(theme.palette.primary.main, 0.8)};
    border-radius: 50%;
  `
);

export const icon = (theme) => (
  css`
    font-size: 75px;
    color: ${theme.palette.primary.main};
  `
);
