import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextFieldInput from 'components/Form/TextFieldInput';
import registrationReason from 'constants/registrationReason';
import { required, validateUrl } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import companyTypeOptions from './companyTypeOptions';

const CompanyDetailsStep = ({ className, initialValues }) => {
  const reason = initialValues && initialValues.reason;
  const isWebsitePreFilled = !!(initialValues && initialValues.companyWebsite);

  return (
    <div className={className}>
      <RadioButtonGroup
        sx={{ mb: 2 }}
        legend="Our company is a:"
        name="status"
        isRequired
        options={companyTypeOptions}
        disabled={
          reason === registrationReason.INVITED_FOR_REVIEW
          || reason === registrationReason.INVITED_TO_COMPANY
          || reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || reason === registrationReason.PRE_REGISTERED_FOR_EVENT
          || reason === registrationReason.INVITED_TO_SHARED_CHALLENGE
        }
      />
      <Field
        sx={{ mb: 2 }}
        type="text"
        placeholder="e.g. Awesome Inc."
        name="companyName"
        component={TextFieldInput}
        disabled={(
          reason === registrationReason.INVITED_TO_COMPANY
          || reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || reason === registrationReason.PRE_REGISTERED_FOR_EVENT
        )}
        label="Company name"
        validate={required}
      />
      <Field
        sx={{ mb: 2 }}
        disabled={(
          reason === registrationReason.INVITED_TO_COMPANY
          || reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE
          || (
            reason === registrationReason.PRE_REGISTERED_FOR_EVENT
            && isWebsitePreFilled
          )
        )}
        name="companyWebsite"
        component={TextFieldInput}
        label="Company website"
        validate={composeValidators(required, validateUrl)}
      />
    </div>
  );
};

CompanyDetailsStep.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object,
};

CompanyDetailsStep.defaultProps = {
  className: null,
  initialValues: null,
};

export default CompanyDetailsStep;
