import { css } from '@emotion/react';

export const headerContainer = (theme) => (
  css`
    position: relative;
    margin-bottom: ${theme.spacing(4)};
  `
);

export const backButton = css`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
`;
