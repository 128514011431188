/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CallMade from '@mui/icons-material/CallMade';
import ThumbUp from '@mui/icons-material/ThumbUp';
import BoxIcon from 'svg/box';
import GeneralModal from 'components/UI/GeneralModal';
import ContactVendorForm from '../ContactVendorForm';
import * as classes from './styles';

const PublicVendorPageHead = ({ onOpenInviteNewUserModal }) => {
  const [isContactModalOpen, setContactModal] = useState(false);

  return (
    <>
      <div css={classes.container}>
        <Button
          variant="outlined"
          css={classes.button}
          onClick={onOpenInviteNewUserModal}
          startIcon={<ThumbUp />}
        >
          Rate and Review
        </Button>
        <Button
          variant="outlined"
          css={classes.button}
          onClick={onOpenInviteNewUserModal}
          startIcon={<CallMade />}
        >
          Share profile
        </Button>
        <Button
          variant="outlined"
          css={classes.button}
          onClick={onOpenInviteNewUserModal}
          startIcon={<BoxIcon css={classes.icon} />}
        >
          Save vendor
        </Button>
        <Button css={classes.button} onClick={() => setContactModal(true)}>
          Contact vendor
        </Button>
      </div>

      <GeneralModal
        isOpen={isContactModalOpen}
        onClose={() => setContactModal(false)}
        title="Contact vendor"
      >
        <ContactVendorForm onClose={() => setContactModal(false)} />
      </GeneralModal>
    </>
  );
};

PublicVendorPageHead.propTypes = {
  onOpenInviteNewUserModal: PropTypes.func.isRequired,
};

export default PublicVendorPageHead;
