/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormSection, reduxForm, propTypes } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import SubmitButton from 'components/UI/SubmitButton';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import SocialMediaFormSection from 'components/Shared/SocialMediaFormSection';
import TeamMemberSection from './TeamMemberSection';
import * as classes from './styles';

const MAX_TEAM_MEMBERS = 3;
const TEAM_MEMBERS_INDICES = Array.from({ length: MAX_TEAM_MEMBERS }, (_, i) => i);

class Company extends Component {
  saveCompanyData = (isDraft) => (vendors) => {
    const { onUpdateProfile } = this.props;

    return onUpdateProfile({ vendors }, isDraft);
  };

  render() {
    const {
      dirty,
      handleSubmit,
      valid,
      submitSucceeded,
      submitting,
    } = this.props;

    return (
      <DataCaptureFormContainer
        isDirty={dirty}
        isValid={valid}
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        submitSucceeded={submitSucceeded}
      >
        <div css={classes.container}>
          <Typography variant="h2" sx={{ mb: 3 }}>Key team members</Typography>
          <FormLabel component="h3" sx={{ mb: 1 }}>
            Please add up to three of your key team members
          </FormLabel>
          {TEAM_MEMBERS_INDICES.map((index) => (
            <FormSection name={`staffMembers[${index}]`} key={index}>
              <TeamMemberSection css={classes.teamMemberSection}/>
            </FormSection>
          ))}
        </div>
        <div css={classes.container}>
          <Typography variant="h2" sx={{ mb: 3 }}>Social media (optional)</Typography>
          <FormSection name="socialMedia">
            <SocialMediaFormSection />
          </FormSection>
        </div>

        <SubmitButton
          isLoading={submitting}
          onClick={handleSubmit(this.saveCompanyData(false))}
        >
          Save
        </SubmitButton>
      </DataCaptureFormContainer>
    );
  }
}

Company.propTypes = {
  onUpdateProfile: PropTypes.func.isRequired,
  ...propTypes,
};

const mapStateToProps = (state) => ({
  initialValues: {
    staffMembers: state.user.company.vendors.staffMembers,
    socialMedia: state.user.company.vendors.socialMedia,
  },
});

export default connect(mapStateToProps)(reduxForm({ form: 'companyForm', enableReinitialize: true })(Company));
