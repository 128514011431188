export const sortOptions = {
  MOST_RELEVANT: 'mostRelevant',
  LEAST_RELEVANT: 'leastRelevant',
  HIGHEST_RATED: 'highestRated',
  LOWEST_RATED: 'lowestRated',
  NEWEST: 'newest',
  OLDEST: 'oldest',
};

export const sortOptionsList = [
  {
    label: 'Most relevant first',
    value: sortOptions.MOST_RELEVANT,
  },
  {
    label: 'Least relevant first',
    value: sortOptions.LEAST_RELEVANT,
  },
  {
    label: 'Highest rated first',
    value: sortOptions.HIGHEST_RATED,
  },
  {
    label: 'Lowest rated first',
    value: sortOptions.LOWEST_RATED,
  },
  {
    label: 'Newest first',
    value: sortOptions.NEWEST,
  },
  {
    label: 'Oldest first',
    value: sortOptions.OLDEST,
  },
];
