import { css } from '@emotion/react';

export const title = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const button = (theme) => (
  css`
    position: absolute;
    top: 3px;
    right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    padding: 0;
    border: 0;
    text-align: center;
    color: ${theme.primaryPalette.grey};
    background-color: transparent;
    cursor: pointer;
  `
);

export const content = css`
  padding: 0;
`;

export const dialog = (theme) => (
  css`
    & .MuiDialog-paper {
      padding: 32px;
      border-radius: ${theme.spacing(2)};
      background-color: ${theme.backgroundColor};
    }
  `
);
