import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import GeneralModal from 'components/UI/GeneralModal';
import CustomFieldArray from 'components/Form/CustomFieldArray';

const EmailDomainsChangeRequest = ({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
}) => (
  <GeneralModal title="Request email domain change" isOpen={isOpen} onClose={onClose}>
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <CustomFieldArray
              name="emailDomains"
              buttonText="Add domain"
              label="Please suggest a new list of email domains"
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Save</Button>
            <Button variant="outlined" onClick={onClose}>Close</Button>
          </DialogActions>
        </form>
      )}
    />
  </GeneralModal>
);

EmailDomainsChangeRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

EmailDomainsChangeRequest.defaultProps = {
  initialValues: null,
};

export default EmailDomainsChangeRequest;
