import { css } from '@emotion/react';

export const legend = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const options = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  `
);

export const option = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `
);
