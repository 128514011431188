import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import LocationType from 'types/Location';
import 'styles/ChallengeDashboardTable.scss';

const DashboardPageContainer = ({ children, breadcrumbs }) => (
  <div>
    <div className="challenge-dashboard-head">
      <Breadcrumbs>
        {breadcrumbs.map(({ title, to }) => (
          <Link key={to} to={to}>
            {title}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
    {children}
  </div>
);

DashboardPageContainer.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([PropTypes.string.isRequired, LocationType.isRequired]),
    }).isRequired,
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default DashboardPageContainer;
