import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    padding: ${theme.spacing(1)};
    margin-top: ${theme.spacing(2)};
    font-size: 14px;
    line-height: 1.3;
    color: #888;
    background-color: #eaeaea;
    border-radius: 5px;
  `
);

export const block = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const bold = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);
