import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const ApproveCompanyTooltip = ({ children }) => (
  <Tooltip
    arrow
    placement="top-start"
    title="Your company profile is currently being reviewed. Some features will be disabled during this time. Please bear with us."
  >
    <div>{children}</div>
  </Tooltip>
);

ApproveCompanyTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApproveCompanyTooltip;
