import { css } from '@emotion/react';

export const highlightStyle = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);

export const wrapper = css`
  display: block;
`;

export const input = (theme) => (
  css`
    margin-top: 5px;

    & .MuiAutocomplete-inputRoot {
      padding: ${theme.spacing(1)} !important;

      & .MuiAutocomplete-input {
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
      }
    }
  `
);
