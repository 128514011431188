import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LocationType from 'types/Location';

const withRouteGuards = (guards) => (Component) => {
  const RouteComponent = (props) => {
    const { location, match: { params } } = props;

    const user = useSelector((state) => ({
      invitedRoles: [state.user.userRole],
      ...state.user.authUser,
    }));

    const handleGetRedirectUrl = () => {
      for (const guard of guards) {
        const redirectUrl = guard(user, location, params);

        if (redirectUrl) {
          return redirectUrl;
        }
      }

      return null;
    };

    const redirectUrl = handleGetRedirectUrl();

    return redirectUrl ? <Redirect to={redirectUrl} /> : <Component {...props} />;
  };

  RouteComponent.propTypes = {
    location: LocationType.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
  };

  return RouteComponent;
};

export default withRouteGuards;
