import PropTypes from 'prop-types';

const PolicyIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7568 9.30287C17.0621 9.02298 17.0828 8.54855 16.8029 8.24321C16.523 7.93787 16.0485 7.91724 15.7432 8.19714L10.2726 13.2119L8.28033 11.2197C7.98744 10.9268 7.51256 10.9268 7.21967 11.2197C6.92678 11.5126 6.92678 11.9874 7.21967 12.2803L9.71967 14.7803C10.0036 15.0643 10.4608 15.0742 10.7568 14.8029L16.7568 9.30287ZM20.25 5C17.5866 5 14.992 4.05652 12.45 2.15C12.1833 1.95 11.8167 1.95 11.55 2.15C9.00797 4.05652 6.41341 5 3.75 5C3.33579 5 3 5.33579 3 5.75V11C3 16.0012 5.95756 19.6757 11.7251 21.9478C11.9018 22.0174 12.0982 22.0174 12.2749 21.9478C18.0424 19.6757 21 16.0012 21 11V5.75C21 5.33579 20.6642 5 20.25 5ZM4.5 6.47793C7.07735 6.32585 9.58084 5.38829 12 3.67782C14.4192 5.38829 16.9227 6.32585 19.5 6.47793V11C19.5 15.2556 17.0466 18.3789 12 20.4419C6.95337 18.3789 4.5 15.2556 4.5 11V6.47793Z"
      fill="currentColor"
    />
  </svg>
);

PolicyIcon.propTypes = {
  className: PropTypes.string,
};

PolicyIcon.defaultProps = {
  className: null,
};

export default PolicyIcon;
