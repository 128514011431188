import PropTypes from 'prop-types';
import EventTopicType from './EventTopic';
import EventPriorityListType from './EventPriorityList';
import EventTopicNoteType from './EventTopicNote';

const EventAttendeeType = PropTypes.shape({
  id: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    position: PropTypes.string,
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(EventTopicType.isRequired),
  priorityList: EventPriorityListType,
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired),
});

export default EventAttendeeType;
