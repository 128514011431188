import challengeStatus from 'constants/challengeStatus';
import retailerDisplayedChallengeStatuses from 'constants/retailerDisplayedChallengeStatus';
import { isExpired } from './challengeCheckers';

const getRetailerDisplayedChallengeStatus = (challenge) => {
  const { status } = challenge;

  if (status === challengeStatus.DRAFT) {
    return retailerDisplayedChallengeStatuses.DRAFT;
  }

  if (
    !isExpired(challenge)
    && (status === challengeStatus.SUBMITTED || status === challengeStatus.SUBMITTED_REVISION)
  ) {
    return retailerDisplayedChallengeStatuses.ACTIVE;
  }

  if (
    isExpired(challenge)
    && (status === challengeStatus.SUBMITTED || status === challengeStatus.SUBMITTED_REVISION)
  ) {
    return retailerDisplayedChallengeStatuses.AWAITING_UPDATE;
  }

  if (status === challengeStatus.REQUESTED_REVISION) {
    return retailerDisplayedChallengeStatuses.REQUESTED_REVISION;
  }

  if (status === challengeStatus.APPROVED_REVISION) {
    return retailerDisplayedChallengeStatuses.APPROVED_REVISION;
  }

  if (status === challengeStatus.DRAFT_REVISION) {
    return retailerDisplayedChallengeStatuses.IN_REVISION;
  }

  if (status === challengeStatus.PAUSED) {
    return retailerDisplayedChallengeStatuses.PAUSED;
  }

  if (status === challengeStatus.CANCELLED) {
    return retailerDisplayedChallengeStatuses.CANCELLED;
  }

  if (status === challengeStatus.COMPLETED) {
    return retailerDisplayedChallengeStatuses.COMPLETED;
  }

  return 'Unknown';
};

export default getRetailerDisplayedChallengeStatus;
