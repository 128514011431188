/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import eventAPI from 'api/event';
import companyStatus from 'constants/companyStatus';
import EventType from 'types/Event';
import formatDate from 'utils/formatDate';
import EventAgendaMeetings from '../EventAgendaMeetings';
import * as classes from './styles';

const DATE_FORMAT = 'do MMMM yyyy, h:mm aaa';

const EventAgenda = ({ event, canLoadAgenda, companyType }) => {
  const [isLoading, setLoading] = useState(false);
  const [agenda, setAgenda] = useState(null);

  useEffect(() => {
    if (canLoadAgenda) {
      setLoading(true);

      eventAPI.getAgenda(event.id)
        .then((fetchedAgenda) => setAgenda(fetchedAgenda))
        .finally(() => setLoading(false));
    }
  }, [event.id, canLoadAgenda]);

  const { eventDate } = event;
  const formattedEventDate = formatDate(eventDate, DATE_FORMAT);

  if (isLoading) {
    return (
      <div css={classes.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!agenda) {
    return <Typography variant="body1">Agenda for this event is not prepared</Typography>;
  }

  const renderGuestMeetingDetails = (meeting) => (
    <>
      <Typography
        css={classes.subtitle}
        variant="subtitle2"
        color="primary"
        component="span"
      >
        {meeting.hostCompanyName}
      </Typography>
      <Typography variant="caption1">{meeting.hostRole}</Typography>
    </>
  );

  const renderHostMeetingDetails = (meeting) => (
    <>
      <Typography
        css={classes.subtitle}
        variant="subtitle2"
        color="primary"
        component="span"
      >
        {meeting.website}
      </Typography>
      <Typography variant="caption1">{meeting.guestRole}</Typography>
    </>
  );

  return (
    <div>
      <div css={classes.description}>
        <Typography css={classes.paragraph} variant="subtitle1">
          This is the agenda for {event.name} on {formattedEventDate}
        </Typography>
        <Typography css={classes.paragraph} variant="body1">
          Agendas are likely to change. This page will update constantly
          to reflect the latest version of the agenda
        </Typography>
      </div>
      <EventAgendaMeetings
        agenda={agenda}
        attendeeToMeet={companyType === companyStatus.RETAILER ? 'Host' : 'Guest'}
        renderMeetingDetails={(
          companyType === companyStatus.RETAILER
            ? renderGuestMeetingDetails
            : renderHostMeetingDetails
        )}
      />
    </div>
  );
};

EventAgenda.propTypes = {
  event: EventType.isRequired,
  canLoadAgenda: PropTypes.bool.isRequired,
  companyType: PropTypes.oneOf(Object.values(companyStatus)).isRequired,
};

export default EventAgenda;
