import { createTheme } from '@mui/material/styles';
import { StarFilled } from '@fluentui/react-icons';
import PentagonIcon from 'svg/PentagonIcon';
import generatePalette from './generatePalette';

const DEFAULT_MAIN_COLOR = '#d1e102';
const DEFAULT_TYPOGRAPHY_COLOR = '#707070';

export const primaryPalette = {
  primaryYellow: DEFAULT_MAIN_COLOR,
  lightYellow: '#feffee',
  veryLightYellow: '#fdffed',
  darkYellow: '#c1cf08',
  yellow: '#faffcc',
  lightOrange: '#ffe600',
  grey: '#707070',
  lightGrey: '#c7c7c7',
  primaryDarkGrey: '#23241e',
  secondaryGrey: '#98a3a4',
  mediumGrey: '#cdcdcd',
  backgroundGreyColor: '#f8f8f8',
  errorColor: '#fe6847',
  white: '#fff',
  midLightGrey: '#ebebeb',
  menu: '#707070',
  menuGrey: '#a0a0a0',
  veryLightGrey: '#f4f4f4',
  signalRed: '#fc7e7c',
  orange: '#ffb800',
  blue: '#36b5dd',
};

const secondaryPalette = {
  blue: '#98cfe0',
  lightGrey: '#f6f6f6',
  grey: '#eaeaea',
};

const fonts = {
  regular: 'Montserrat-Regular',
  bold: 'Montserrat-Bold',
  medium: 'Montserrat-Medium',
  semiBold: 'Montserrat-SemiBold',
};

const buttonShadows = {
  REGULAR: '0px 1px 14px rgba(90, 87, 87, 0.12)',
  FOCUS: '0px 5px 8px rgba(90, 87, 87, 0.14)',
};

const boxShadows = {
  regular: '0 0 10px rgba(50, 55, 64, 0.07)',
  large: '0 0 20px rgba(50, 55, 64, 0.05)',
};

const fabSizes = {
  SMALL: 32,
  MEDIUM: 40,
  LARGE: 48,
};

const DEFAULT_FONT_SIZE = 14;
const DEFAULT_LINE_HEIGHT = 1.4;
const MOBILE_BREAKPOINT_WIDTH = '600px';

const DEFAULT_TYPOGRAPHY_STYLES = {
  fontFamily: fonts.regular,
  lineHeight: DEFAULT_LINE_HEIGHT,
};

const buttonStyles = {
  [`@media (min-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
};

const mainTheme = createTheme({
  components: {
    MuiLoadingButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink):not(.Mui-disabled)': {
            color: primaryPalette.menuGrey,
          },
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            fontSize: 16,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: fonts.regular,
          '&:not(.Mui-focused)': {
            backgroundColor: '#f7f7f8',
            '& .MuiSvgIcon-root': {
              color: primaryPalette.grey,
            },
          },
          '&:hover': {
            borderColor: primaryPalette.grey,
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: primaryPalette.menuGrey,
          },
          '&:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: primaryPalette.grey,
          },
        },
        input: {
          paddingTop: 8.5,
          paddingBottom: 8.5,
          '&:placeholder': {
            color: primaryPalette.menuGrey,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& > :not(:first-of-type)': {
            marginLeft: '24px',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 4,
          color: primaryPalette.mediumGrey,

          '&.Mui-disabled': {
            color: primaryPalette.midLightGrey,
          },

          '.MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
            fontSize: '20px',
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        avatar: <PentagonIcon />,
      },
      styleOverrides: {
        deletableColorPrimary: {
          '&:focus': {
            backgroundColor: primaryPalette.lightYellow,
          },
        },
        deleteIconColorPrimary: {
          '&, &:hover': {
            color: 'currentColor',
          },
        },
        root: ({ theme }) => ({
          minHeight: 'auto',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 4,
          '& .MuiChip-avatarColorPrimary, & .MuiChip-avatarColorSecondary': {
            backgroundColor: 'transparent',
            color: 'currentColor',
          },
        }),
        label: {
          paddingRight: 15,
          fontSize: DEFAULT_FONT_SIZE,
          fontFamily: fonts.bold,
        },
        avatar: {
          /**
           * This override will take transition config from `root` of `Chip` and
           * apply it to `color` property of icon.
           */
          transition: 'inherit',
          transitionProperty: 'color',
        },
        clickable: {
          '&:active': {
            boxShadow: 'none',
          },
        },
        colorPrimary: ({ theme }) => ({
          '&, &.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.veryLight,
          },
        }),
        colorSecondary: {
          '&, &.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
            border: `1px solid ${secondaryPalette.grey}`,
            color: primaryPalette.mediumGrey,
            backgroundColor: secondaryPalette.lightGrey,
          },
        },
      },
    },
    MuiFab: {
      defaultProps: {
        color: 'primary',
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          zIndex: 'initial',
          borderRadius: 4,
          '&, &:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: buttonShadows.REGULAR,
          },
          '&:focus': {
            boxShadow: buttonShadows.FOCUS,
          },
        },
        primary: {
          color: primaryPalette.white,
          '&, &:hover': {
            backgroundColor: primaryPalette.primaryYellow,
          },
          '&:active': {
            backgroundColor: primaryPalette.darkYellow,
          },
          '&.Mui-disabled': {
            color: primaryPalette.white,
            backgroundColor: primaryPalette.mediumGrey,
          },
        },
        primaryOutlined: {
          color: primaryPalette.primaryYellow,
          background: 'transparent',
          border: '1px solid currentColor',
          '&:hover': {
            backgroundColor: primaryPalette.lightYellow,
            boxShadow: buttonShadows.REGULAR,
          },
          '&:active': {
            backgroundColor: primaryPalette.yellow,
            color: primaryPalette.darkYellow,
            boxShadow: buttonShadows.REGULAR,
          },
          '&.Mui-disabled': {
            color: primaryPalette.mediumGrey,
            backgroundColor: primaryPalette.white,
          },
        },
        sizeSmall: {
          width: fabSizes.SMALL,
          height: fabSizes.SMALL,
          minHeight: fabSizes.SMALL,
          minWidth: fabSizes.SMALL,
        },
        sizeMedium: {
          width: fabSizes.MEDIUM,
          height: fabSizes.MEDIUM,
          minHeight: fabSizes.MEDIUM,
          minWidth: fabSizes.MEDIUM,
        },
        sizeLarge: {
          width: fabSizes.LARGE,
          height: fabSizes.LARGE,
          minHeight: fabSizes.LARGE,
          minWidth: fabSizes.LARGE,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          display: 'inline-block',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: primaryPalette.grey,
          fontSize: 14,
          fontFamily: fonts.regular,
          '&.Mui-shrink': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          color: primaryPalette.grey,
          fontFamily: fonts.regular,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 4,
          color: primaryPalette.mediumGrey,

          '&.Mui-disabled': {
            color: primaryPalette.midLightGrey,
          },

          '.MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
            fontSize: '20px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 1,
          right: 24,
          overflowY: 'visible !important',
          backgroundColor: primaryPalette.white,
          lineHeight: DEFAULT_LINE_HEIGHT,
          '&.Mui-disabled': {
            color: primaryPalette.mediumGrey,
          },
          '&:not(.MuiInputLabel-shrink)': {
            fontSize: 16,
          },
          '&:not(.MuiInputLabel-shrink):not(.Mui-disabled)': {
            color: primaryPalette.menuGrey,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: primaryPalette.grey,
          fontFamily: fonts.regular,
          '&.Mui-error': {
            color: primaryPalette.errorColor,
          },
          '&.Mui-focused': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: primaryPalette.mediumGrey,
          },
          '&::before': {
            borderBottomColor: 'currentColor !important',
          },
        }),
        underline: {
          '&.Mui-disabled::before': {
            borderBottomStyle: 'solid',
            borderBottomColor: 'currentColor',
          },
        },
        input: {
          color: primaryPalette.grey,
          '&:disabled': {
            color: 'currentColor',
          },
          '&::placeholder': {
            color: primaryPalette.menuGrey,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          lineHeight: 1.4,
          fontFamily: fonts.semiBold,
          textTransform: 'initial',
          '&, &:active': {
            boxShadow: 'none',
          },
          [`@media (min-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            height: 40,
            fontSize: 16,
          },
          [`@media (max-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            height: 32,
            fontSize: 14,
          },
        },
        contained: {
          ...buttonStyles,
          color: primaryPalette.white,
          '&:disabled': {
            backgroundColor: primaryPalette.mediumGrey,
            color: primaryPalette.white,
          },
          '&:hover': {
            boxShadow: buttonShadows.REGULAR,
          },
          '&:focus': {
            boxShadow: buttonShadows.FOCUS,
          },
        },
        containedPrimary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
        outlined: {
          ...buttonStyles,
          borderColor: 'currentColor !important',
          '&:disabled': {
            color: primaryPalette.mediumGrey,
          },
        },
        outlinedPrimary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.veryLight,
            boxShadow: buttonShadows.REGULAR,
          },
          '&:focus': {
            backgroundColor: theme.palette.primary.veryLight,
            boxShadow: buttonShadows.FOCUS,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.light,
          },
        }),
        text: ({ theme }) => ({
          minWidth: 'auto',
          paddingLeft: 4,
          paddingRight: 4,
          border: 'solid 1px transparent',
          '&:has(.MuiButton-startIcon)': {
            fontFamily: fonts.medium,
            '&:not(:focus):not(:disabled):not(:hover)': {
              color: primaryPalette.menu,
            },
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
          '&:active, &:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: primaryPalette.mediumGrey,
            '& .MuiButton-startIcon': {
              color: 'inherit',
            },
          },
          '&:focus:not(:active)': {
            borderColor: 'currentColor',
            boxShadow: buttonShadows.FOCUS,
          },
        }),
        startIcon: ({ theme }) => ({
          marginRight: 10,
          marginLeft: 0,
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&.Mui-disabled': {
            color: primaryPalette.mediumGrey,
          },
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: 'inherit',
          },
          '&:hover, &.Mui-disabled': {
            backgroundColor: `${primaryPalette.white} !important`,
          },
        },
        colorPrimary: ({ theme }) => ({
          '.MuiSvgIcon-root': {
            color: `${primaryPalette.white}!important`,
          },
          '&:hover': {
            color: theme.palette.primary.main,
            boxShadow: buttonShadows.REGULAR,
          },
          '&:focus': {
            color: theme.palette.primary.dark,
            boxShadow: buttonShadows.FOCUS,
          },
          '&:active': {
            color: theme.palette.primary.dark,
            boxShadow: buttonShadows.FOCUS,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: fonts.regular,
          '&.Mui-error': {
            color: primaryPalette.errorColor,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: primaryPalette.grey,
          lineHeight: DEFAULT_LINE_HEIGHT,
        },
        h1: {
          fontFamily: fonts.bold,
          [`@media (min-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 24,
          },
          [`@media (max-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 20,
          },
        },
        h2: {
          fontFamily: fonts.bold,
          [`@media (min-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 20,
          },
          [`@media (max-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 18,
          },
        },
        h3: {
          fontFamily: fonts.bold,
          [`@media (min-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 18,
          },
          [`@media (max-width: ${MOBILE_BREAKPOINT_WIDTH})`]: {
            fontSize: 16,
          },
        },
        h4: undefined,
        h5: undefined,
        h6: undefined,
        subtitle1: {
          fontFamily: fonts.medium,
          fontSize: 16,
        },
        subtitle2: {
          fontFamily: fonts.medium,
          fontSize: 14,
        },
        body1: {
          fontSize: 16,
          fontFamily: fonts.regular,
        },
        body2: {
          fontSize: 14,
          fontFamily: fonts.regular,
        },
        caption1: {
          fontSize: 12,
          fontFamily: fonts.regular,
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          color: primaryPalette.grey,
          fontFamily: fonts.regular,
          '&:focus': {
            background: primaryPalette.white,
          },

          '&:not(:disabled) ~ .MuiSvgIcon-root': {
            color: primaryPalette.grey,
          },
          '&:disabled ~ .MuiSvgIcon-root': {
            color: primaryPalette.mediumGrey,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderLeft: `solid 1px ${primaryPalette.primaryYellow}`,
          borderRight: `solid 1px ${primaryPalette.primaryYellow}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${primaryPalette.primaryYellow}`,
          borderBottomColor: primaryPalette.primaryYellow,
        },
        body: {
          ...DEFAULT_TYPOGRAPHY_STYLES,
          fontSize: DEFAULT_FONT_SIZE,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...DEFAULT_TYPOGRAPHY_STYLES,
          padding: '8px 12px',
          border: '1px solid transparent',
          fontSize: 16,
          color: primaryPalette.grey,
          textTransform: 'none',
          borderRadius: 4,

          '&:active': {
            color: theme.palette.primary.dark,
            background: theme.palette.primary.light,
            borderColor: `${theme.palette.primary.dark} !important`,

            '.MuiBadge-badge': {
              background: theme.palette.primary.dark,
            },
          },
          '&.Mui-selected': {
            background: theme.palette.primary.light,
            borderColor: 'transparent',
          },
          '&:hover': {
            color: theme.palette.primary.dark,
            background: theme.palette.primary.light,
            borderColor: theme.palette.primary.dark,
          },

          '&.Mui-disabled': {
            color: primaryPalette.mediumGrey,
            '.MuiBadge-badge': {
              backgroundColor: primaryPalette.mediumGrey,
            },
          },
          '.MuiBadge-badge': {
            ...DEFAULT_TYPOGRAPHY_STYLES,
            position: 'relative',
            marginLeft: 8,
            padding: 4,
            color: primaryPalette.white,
            backgroundColor: DEFAULT_MAIN_COLOR,
            borderRadius: 4,
            transform: 'none',
          },
        }),
      },
    },
    MuiRating: {
      defaultProps: {
        icon: <StarFilled />,
        emptyIcon: <StarFilled />,
      },
      styleOverrides: {
        icon: {
          fontSize: 16.3,
        },
        iconFilled: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        iconEmpty: primaryPalette.midLightGrey,
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& *': {
            fontFamily: `${fonts.regular} !important`,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: `${primaryPalette.grey} !important`,
            '&, &:hover': {
              backgroundColor: `${primaryPalette.primaryYellow} !important`,
            },
          },
          '&:not(.Mui-selected)': {
            border: 'none',
          },
        },
        day: {
          color: primaryPalette.grey,
        },
        dayDisabled: {
          color: primaryPalette.lightGrey,
        },
        today: {
          color: primaryPalette.white,
          '&, &:hover': {
            backgroundColor: `${primaryPalette.grey} !important`,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        color: primaryPalette.lightGrey,
        fontSizeSmall: {
          fontSize: '1.15em',
        },

        primary: {
          color: primaryPalette.primaryYellow,

          '&:hover, &:focus': {
            color: primaryPalette.yellow,
          },
          '&:focus': {
            boxShadow: buttonShadows.FOCUS,
          },
          '&:active': {
            color: primaryPalette.darkYellow,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: primaryPalette.menuGrey,
          '& a': {
            color: 'currentColor',
            textDecoration: 'none',
          },

          '& .MuiBreadcrumbs-separator': {
            margin: '0 4px',
            fontSize: '12px',
          },
        },
      },
    },
  },
  mainFont: fonts.regular,
  boldFont: fonts.bold,
  fontVariants: fonts,
  backgroundColor: primaryPalette.white,
  palette: {
    primary: generatePalette(DEFAULT_MAIN_COLOR),
    secondary: {
      main: primaryPalette.grey,
    },
    error: {
      main: primaryPalette.errorColor,
    },
    text: {
      primary: DEFAULT_TYPOGRAPHY_COLOR,
    },
  },
  primaryPalette,
  secondaryPalette,
  layout: {
    sidebar: '260px',
    protectedPageContent: '1100px',
    publicPageContent: '800px',
  },
  boxShadows,
});

export default mainTheme;
