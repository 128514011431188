import { css } from '@emotion/react';

const getContainerStyles = ({ isSelected, size }) => (theme) => (
  css`
    box-sizing: border-box;
    width: ${size}px;
    height: ${size}px;
    background-color: ${isSelected ? theme.primaryPalette.primaryYellow : 'transparent'};
    border: ${isSelected ? 'none' : `solid 1px ${theme.primaryPalette.lightGrey}`};
    border-radius: 50%;
  `
);

export default getContainerStyles;
