/** @jsxImportSource @emotion/react */
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ListItem from 'components/UI/ListItem';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import {
  COMPANY_NAME,
  COMPANY_SITE_UK,
  EMAIL_SUPPORT,
  CONTACT_ADDRESS,
} from 'constants/contacts';
import {
  GENERAL_SERVICES,
  CONTENT_RESTRICTIONS,
  INTELLECTUAL_PROPERTY_RESTRICTIONS,
  MONTHLY_SUBSCRIPTION_FEES,
  ANNUAL_SUBSCRIPTION_FEES,
  OPT_ANNUAL_SUBSCRIPTION_FEES,
  ACCEPTANCE_OF_TERMS,
  EVENT_OF_DEFAULT,
} from './termsData';
import * as classes from './styles';

const Terms = () => (
  <PublicPageContentWrapper css={classes.main}>
    <div css={classes.head}>
      <Typography variant="h1">
        Terms &amp; conditions
      </Typography>
      <Typography variant="subtitle2">Last updated 14th July 2020</Typography>
    </div>

    <article>
      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Introduction
        </Typography>
        <Typography variant="body1">
          This website <Link href={`https://${COMPANY_SITE_UK}/`} variant="body1">{COMPANY_SITE_UK}</Link> and all
          the pages, content and sub-domains contained in it) (the
          <strong css={classes.highlight}>“Website”</strong>) is owned and operated by{' '}
          {COMPANY_NAME} (<strong css={classes.highlight}>“Validify”</strong>,
          <strong css={classes.highlight}>“we”</strong>,
          <strong css={classes.highlight}>“us”</strong>, or
          <strong css={classes.highlight}>“our”</strong> as the context dictates), a company
          registered in England (No.
          <strong css={classes.highlight}>09346111</strong>) whose address is{' '}
          {CONTACT_ADDRESS}.
        </Typography>
        <Typography variant="body1">
          Validify provides an online service that gives consumer brands access to information on
          emerging retail technology solutions that have been selected and vetted by Validify, and
          that gives selected technology vendors access to Validify&apos;s consumer brand community (the{' '}
          <strong css={classes.highlight}>“Service”</strong>
          ). The Service is only accessible to our subscribers, and not to visitors of the Website.
        </Typography>
        <Typography variant="body1">
          The terms and conditions set out below (
          <strong css={classes.highlight}>“Terms“</strong>) govern your use of the Website,
          and if you are a subscriber, your use of the Service, and comprise the following:
        </Typography>
        <ul css={classes.list}>
          {GENERAL_SERVICES.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 2 }}>General terms</Typography>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Key Definitions
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          In these Terms the following definitions shall apply:
        </Typography>
        <ListItem>
          <strong css={classes.highlight}>Consumer brands</strong> means consumer brands who
          use the Service for the purposes of searching for and identifying a Vendor that meets
          their technology needs
        </ListItem>
        <ListItem>
          <strong css={classes.highlight}>Subscriber Fees</strong> means a Consumer brand or
          Vendor that subscribes to the Service.
        </ListItem>
        <ListItem>
          <strong css={classes.highlight}>“User“, “you” and “your”</strong> means anyone
          using the Website and/or making use of the Service.
        </ListItem>
        <ListItem>
          <strong css={classes.highlight}>Validify</strong> means technology vendors that
          have been selected and vetted by Validify to offer their services to Consumer brands.
        </ListItem>
        <ListItem>
          <strong css={classes.highlight}>Vendors</strong> means technology vendors who
          register on the Website in order to offer their services to Consumer brands.
        </ListItem>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Nature of the Service and the Role of Validify
        </Typography>
        <Typography variant="body1">
          The primary purpose of the Service is to help Consumer brands find the right Vendors for
          their technology needs. It is the responsibility of the Consumer brand to identify and
          select an appropriate Vendor, to agree the charges and other terms of use with the Vendor
          and to assess the suitability, if applicable, of any available technology solutions.
        </Typography>
        <Typography variant="body1">
          Prior to the initial registration of a Vendor on the Website, Validify takes steps (to be
          determined at Validify&apos;s discretion from time to time) to verify the credentials of
          the Vendor and its technology solutions. Validify also seeks verification from each Vendor
          that the description of their technology solutions on the Website is accurate. Validify
          shall not be responsible for conducting any other checks on Vendors or their technology
          solutions.
        </Typography>
        <Typography variant="body1">
          Validify does not recommend any Vendor or solution, and the fact that a Vendor or solution
          is listed on the Website does not mean that they are approved or endorsed by Validify in
          any way.
        </Typography>
        <Typography variant="body1">
          Validify reserves the right (at its sole discretion) to refuse to list a technology vendor
          as a Vendor on the Website. It also reserves the right (at its sole discretion) to refuse
          to list a particular technology solution on the Website.
        </Typography>
        <Typography variant="body1">
          In providing the Service, Validify is not acting as a contractor or agent for any other
          person .
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Information
        </Typography>
        <Typography variant="body1">
          Although we make reasonable attempts to ensure that it is correct, Validify cannot
          guarantee the accuracy of the information on the Website or that is available via the
          Service, and we are not liable for any problems or losses arising from errors in such
          information.
        </Typography>
        <Typography variant="body1">
          Information on the Website or that is available via the Service is provided for general
          guidance only. Validify does not accept any responsibility for any loss which may arise
          from the reliance on any such information.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Use of the Website and Content
        </Typography>
        <Typography variant="body1">
          You agree to use the Website and the Service only for lawful purposes and in a way that
          does not infringe the rights of or restrict or inhibit anyone else&apos;s use of the
          Website or the Service.
        </Typography>
        <Typography variant="body1">
          You understand that all postings, messages, text, files, images, photos, video, sounds, or
          other materials ( Content ) posted on, transmitted through, or linked from the Website are
          the sole responsibility of the person from whom such content originated, and Validify does
          not control, and is not responsible for Content provided by any other person (including,
          without limitation, any advertiser on the Website).
        </Typography>
        <Typography variant="body1">
          You are entirely responsible for all Content that you upload, post, email or otherwise
          make available via the Service.
        </Typography>
        <Typography variant="body1">
          You agree not to upload, post, email, or otherwise make available any Content:
        </Typography>
        <ul css={classes.list}>
          {CONTENT_RESTRICTIONS.map((item, i) => (<ListItem key={i} >{item}</ListItem>))}
        </ul>
        <Typography variant="body1">
          You acknowledge that Validify does not pre-screen or approve Content, but that Validify
          shall have the right (but not the obligation) at its sole discretion to refuse, delete,
          edit or move any Content that is available on the Website for violating the letter or
          spirit of the terms or for any other reason.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Feedback
        </Typography>
        <Typography variant="body1">
          All Consumer brands will, in time, be able to complete feedback evaluations on Vendors and
          their technology solutions. Consumer brands must ensure that any feedback posted by them
          conforms to the rules regarding Content in the section entitled “Use of the Website and
          Content” above. Validify is not responsible for the content of feedback posted by Consumer
          brands, or any harm done by such content.
        </Typography>
        <Typography variant="body1">
          Validify shall take reasonable measures to verify that the feedback is genuine, and may at
          its sole discretion remove feedback whose authenticity is in doubt. Validify does not
          accept any responsibility for damage caused by false or bogus feedback, or its failure or
          refusal to remove it.
        </Typography>
        <Typography variant="body1">
          Where a Vendor or other User believes feedback may be fraudulent, defamatory or abusive,
          they should immediately inform Validify by contacting Vadify through the Contact Us page
          or by sending an email to <Link href={`mailto:${EMAIL_SUPPORT}`} variant="body1">{EMAIL_SUPPORT}</Link>.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Third Party Links
        </Typography>
        <Typography variant="body1">
          The Website and Content may contain links to other websites which are independent of
          Validify. Validify makes no representation or warranty as to the accuracy, completeness or
          authenticity of the information contained in any other site, and if you follow a link to
          another website you do so at your own risk.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Intellectual Property
        </Typography>
        <Typography variant="body1">
          Validify owns all the intellectual property rights relating to the Website, the Service,
          and any software used in connection with the Service{' '}
          <strong css={classes.highlight}>(Software)</strong>, including the designs,
          text, database, graphics and layouts, and you agree not use or copy any part of them
          except as set out in these Terms.
        </Typography>
        <Typography variant="body1">
          Validify grants you a personal, non-transferable and non-exclusive right and license to
          use the Service provided that you do not (and do not allow any third party to):
        </Typography>
        <ul css={classes.list}>
          {INTELLECTUAL_PROPERTY_RESTRICTIONS.map((item, i) => (
            <ListItem key={i}>{item}</ListItem>
          ))}
        </ul>
        <Typography variant="body1">
          You agree not to access the Service by any means other than through the interface that is
          provided by Validify for use in accessing the Service.
        </Typography>
        <Typography variant="body1">
          The intellectual property rights in Content uploaded by Users to the Website are retained
          by the copyright owner, and Content is added to the Website by Users at their own risk. In
          doing so you are giving irrevocable permission for us to store, display and use the
          Content as we see fit. Please see our Privacy Policy for further details of how your
          personal data is collected and used, and your rights regarding your data.
        </Typography>
        <Typography variant="body1">
          You may not use the Validify name, trade mark or logo without our prior express consent.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Dealings between Users
        </Typography>
        <Typography variant="body1">
          Your interactions with organisations and/or individuals found on, or through, the Website,
          including responsibility for delivery of and payment for products or services, and any
          other terms, conditions, warranties or representations associated with such dealings, are
          solely between you and such organisations and/or individuals. You agree that Validify
          shall not be responsible, or liable, for any loss or damage of any sort arising from any
          such dealings.
        </Typography>
        <Typography variant="body1">
          In the event that you have a dispute with one or more other Users, you hereby release
          Validify , its officers, employees, agents and successors in rights from claims, demands
          and damages (actual and consequential) of every kind or nature, known or unknown,
          suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related
          to such disputes and/or the Service (save to the extent caused by Validify’s own
          negligence or wilful default).
        </Typography>
        <Typography variant="body1">
          You acknowledge and agree that Validify is under no obligation to become involved in any
          dispute between Users.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Disclaimer of Liability
        </Typography>
        <Typography variant="body1">
          Validify does not recommend, endorse or approve any of the Vendors listed on the Website.
        </Typography>
        <Typography variant="body1">
          It is the sole responsibility of the Consumer brand to select an appropriate Vendor, to
          agree the terms of engagement with the Vendor and to assess the suitability of any
          products or services offered or recommended to them. Validify shall not be liable for any
          loss or damage resulting from the purchase of (or failure to purchase) any products or
          services as a result of or in connection with the provision of any service by a Vendor to
          a Consumer brand.
        </Typography>
        <Typography variant="body1">
          You agree that the Website and the Service are provided on an as is or as available basis,
          and accordingly use of the Website and the Service is entirely at your own risk. Any and
          all warranties relating to the Website and the Service, including, without limitation, any
          implied warranties as to fitness for a particular purpose and non-infringement of
          proprietary rights are expressly disclaimed to the fullest extent permitted by law.
        </Typography>
        <Typography variant="body1">
          Validify takes reasonable care to ensure that the Website and its electronic
          communications are virus-free. However, Validify disclaims any warranty that the Website
          is free of viruses or other harmful components.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Indemnity
        </Typography>
        <Typography variant="body1">
          You agree to indemnify and hold harmless Validify, its officers, employees, agents and
          service Vendors from any claim or demand, including reasonable legal fees and court costs,
          made by any third party due to or arising out of Content you submit, post or make
          available through the Service, your use of the Service, your violation of the Terms, or
          your violation of any rights of any person or organisation.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Limitations on Service
        </Typography>
        <Typography variant="body1">
          You acknowledge that Validify may establish limits concerning use of the Service,
          including but not limited to the maximum number of days that Content will be retained by
          the Service, the maximum number and size of postings, email messages, or other Content
          that may be transmitted or stored by the Service, and the frequency with which you may
          access the Service.
        </Typography>
        <Typography variant="body1">
          You agree that Validify has no responsibility or liability for the deletion or failure to
          store any Content maintained or transmitted by the Service. You also acknowledge that
          Validify reserves the right at any time to modify, suspend or discontinue the Service (or
          any part of it) with or without notice.
        </Typography>
        <Typography variant="body1">
          Validify shall not be liable to you or to any third party for any modification, suspension
          or discontinuance of the Service or any part of it, including for loss of profit or
          consequential loss or damage.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Terms Policy
        </Typography>
        <Typography variant="body1">
          Validify has established a Terms Policy which governs how Users’ information is collected
          and used, and this policy is located in the Legal section of the website. Your use of the
          Website and/or the Service signifies your acknowledgement of, and agreement to, our Terms
          Policy.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Changes to Terms
        </Typography>
        <Typography variant="body1">
          Validify reserves the right to change or update these Terms from time to time. As a User
          of the Website, you are responsible for informing yourself of any updates of these Terms
          by visiting this section. Any significant changes in the Terms will be notified by an
          announcement on the Website and/or an email to active Users.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Governing Law and Jurisdiction
        </Typography>
        <Typography variant="body1">
          These Terms shall be governed by and interpreted in accordance with English law. Disputes
          arising in connection with these Terms shall be subject to the exclusive jurisdiction of
          the English courts.
        </Typography>
        <Typography variant="body1">
          If any provision of these Terms is held to be illegal or unenforceable such provision
          shall be severed and the remainder of these Terms shall remain in full force and effect,
          unless the business purpose of the contract between us is substantially frustrated as a
          result.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 2 }}>Subscriber Specific Terms</Typography>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Acceptance of Terms
        </Typography>
        <Typography variant="body1">
          In submitting an online registration form (
          <strong css={classes.highlight}>Registration Form</strong>) to participate in the
          Service you agree to comply with and be bound by (i) our general terms and conditions (
          <strong css={classes.highlight}>General Terms</strong>
          ), and (ii) the terms and conditions set out below (
          <strong css={classes.highlight}>Subscriber Terms</strong>
          ), which together with the Registration Form constitute a binding contract between you and
          Validify. Definitions used in the General Terms also apply in these Subscriber Terms.
        </Typography>
        <Typography variant="body1">
          If you are a vendor, by accepting registration to participate in the Service following
          completion of the selection and vetting process conducted by Validify, you agree to comply
          with and be bound by (i) the General Terms, and (ii) the Subscriber Terms, which together
          with the Registration Form, constitute a binding contract between you and Validify.
        </Typography>
        <Typography variant="body1">
          Definitions used in the General Terms also apply in these Subscriber Terms.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Completion of Profile
        </Typography>
        <Typography variant="body1">
          At the time of registration for the Service you will be asked to complete your online
          profile, which you must do as fully and accurately as possible.
        </Typography>
        <Typography variant="body1">
          You must not encourage people to contact you through any channel, other than Validify, on
          your Validify profile.
        </Typography>
        <Typography variant="body1">
          As part of the registration process you will be given or create a username and password to
          allow you to access the Service. You alone are responsible for maintaining the
          confidentiality of the password, and are fully responsible for all activities that occur
          under your username and password. You are also accountable for all activities regarding
          your username, with or without your knowledge or permission. You agree to immediately
          notify Validify of any unauthorised use of your username or password or any other breach
          of security. If you knowingly provide your log-on information to another person, your
          access to the Service may be suspended temporarily or terminated. You agree that Validify
          assumes no liability for activities accessed through your username and password, nor can
          or will Validify be liable for any loss or damage arising from your failure to comply with
          this clause.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Accuracy of Information
        </Typography>
        <Typography variant="body1">
          By completing a Registration Form and using the Service you unconditionally confirm that
          the details provided by you in your Registration Form and in your online firm profile are
          true, fair and accurate in all material respects and complies with all applicable
          regulatory or statutory requirements. You are responsible for keeping your profile up to
          date at all times and you must promptly notify Validify of any material changes in
          relation to your details or status which may affect your eligibility to use the Service.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Term
        </Typography>
        <Typography variant="body1">
          Monthly - currently £300 per month: Your initial subscription period shall commence on
          the date upon which you provide to us valid, up-to-date and complete debit or credit card
          details and Validify has activated your profile on the Website (
          <strong css={classes.highlight}>“Commencement Date”</strong>) and shall continue
          until the end of the following calendar month, and thereafter, your subscription shall
          automatically renew for successive periods of one calendar month unless and until
          terminated by you or Validify.
        </Typography>
        <Typography variant="body1">
          Annual £1,500 per annum- : For a discounted subscription fee, you may opt for an annual
          subscription to the Service. In this case, your initial subscription period shall be one
          calendar year from the date upon which you provide to us valid, up-to-date and complete
          debit or credit card details or approved purchase order information acceptable to us (and
          any other relevant valid, up-to-date and complete contact and billing details) and
          Validify has activated your profile on the Website (
          <strong css={classes.highlight}>“Commencement Date”</strong>
          ), and thereafter, your subscription shall automatically renew for successive periods of
          one calendar year unless and until terminated by you or Validify. You will get one months
          notice of renewal and have the option to terminate your subscription
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Subscription Fees and Payment
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 1 }} component="h4">
          Subscription Fees
        </Typography>

        <div css={classes.textBlock}>
          <Typography variant="subtitle2" component="h5">Changes</Typography>
          <Typography variant="body1">
            Any significant changes to our charging structure, subscription fees or addition or
            changes to other charges will be communicated in advance via an e-mail to active
            subscribers.
          </Typography>
        </div>
        <div css={classes.textBlock}>
          <Typography variant="subtitle2" component="h5">VAT</Typography>
          <Typography variant="body1">
            All Subscription Fees quoted by Validify are exclusive of VAT which will be charged as
            an additional item on Subscription Fees.
          </Typography>
        </div>
        <div css={classes.textBlock}>
          <Typography variant="subtitle2" component="h5">Payment</Typography>
          <Typography variant="body1">
            If you provide us with your debit or credit card details, you hereby authorise Validify
            to, and we shall, bill your bank account directly or through a third party supplier
            (subject to that party&apos;s terms and conditions, as accepted separately by you):
          </Typography>
        </div>

        <Typography variant="body1">If your subscription is monthly:</Typography>
        <ul css={classes.list}>
          {MONTHLY_SUBSCRIPTION_FEES.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
        <Typography variant="body1">And if your subscription is annual:</Typography>
        <ul css={classes.list}>
          {ANNUAL_SUBSCRIPTION_FEES.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
        <Typography variant="body1">
          If you opt for an annual subscription to the Service and you provide us with approved
          purchase order information, Validify shall invoice you:
        </Typography>
        <ul css={classes.list}>
          {OPT_ANNUAL_SUBSCRIPTION_FEES.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
        <Typography variant="body1">
          All invoices shall be payable without deduction or set-off within (seven) 7 days of
          receipt.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Acceptance of Terms
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Validify provides an online service that gives consumer brands access to information on
          emerging retail technology solutions that have been selected and vetted by Validify, [and
          access to those solutions on a trial basis], and that gives technology vendors access to
          Validify&apos;s consumer brand community. In using this service or any other service
          provided by Validify (whether you are a consumer brand, vendor or otherwise), you agree to
          comply with and be bound by the terms and conditions set out below ( Terms ) which
          constitute a binding contract between you and Validify. If you do not wish to be bound by
          the Terms you should not use the service.
        </Typography>
        <Typography variant="body1">
          <strong css={classes.highlight}>
            If Validify does not receive payment by the due date in each case, and without prejudice
            to any other rights and remedies Validify may have:{' '}
          </strong>
        </Typography>
        <ul css={classes.list}>
          {ACCEPTANCE_OF_TERMS.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
        <Typography variant="subtitle1">Suspension of the Service</Typography>
        <Typography variant="body1">
          Your obligation to pay Subscription Fees shall not be affected by any disruption,
          discontinuance, downtime or suspension of the Service for any reason.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Cancellation
        </Typography>
        <div css={classes.textBlock}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Your right to cancel
          </Typography>
          <Typography variant="body1">
            You may cancel your subscription to the Service at any time by giving Validify at least
            one calendar month`s notice in writing. Any Subscription Fees already paid by you in
            respect of periods following the cancellation date shall be forfeited and shall belong
            to Validify.
          </Typography>
        </div>
        <div css={classes.textBlock}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Cancellation by Validify by notice
          </Typography>
          <Typography variant="body1">
            You agree that Validify, in its sole discretion, may cancel your subscription to the
            Service by giving you at least one calendar month`s notice in writing. Upon cancellation
            by Validify by notice, Validify shall refund any Subscription Fees already paid by you
            in respect of periods following the cancellation date.
          </Typography>
        </div>
        <div css={classes.textBlock}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Cancellation by Validify for an Event of Default
          </Typography>
          <Typography variant="body1">
            Validify may cancel your subscription and/or delete, suspend or deactivate your user
            account or otherwise terminate or restrict your access to the Service immediately and
            without notice and remove and discard any content within the Website if you commit an
            “Event of Default”. Each of the following shall be an Event of Default:
          </Typography>
        </div>
        <ul css={classes.list}>
          {EVENT_OF_DEFAULT.map((item, i) => (<ListItem key={i}>{item}</ListItem>))}
        </ul>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Publicity and Marketing
        </Typography>
        <Typography variant="body1">
          You agree that Validify may issue a press release or other suitable form of publicity
          (including on the Website) advertising that you are a subscriber to the Service, and you
          further agree that Validify may (and grant Validify a licence to) use your name, logo
          and/or trade marks (without your consent or notice to you) in any such publicity and
          marketing materials.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Compliance with Law
        </Typography>
        <Typography variant="body1">
          You acknowledge and agree that in completing a Registration Form, participating in the
          Service and providing services to Consumer brands you are required to inform yourself of
          and comply with all applicable laws, regulations and guidelines (whether mandatory or not)
          (including, without limitation, all applicable laws regarding the transmission of
          technical data exported from the country in which you reside) and Validify shall have no
          responsibility for reviewing or verifying your compliance.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Governing Law &amp; Jurisdiction
        </Typography>
        <Typography variant="body1">
          These Subscriber Terms shall be governed by and interpreted in accordance with English
          law. Disputes arising in connection with these Subscriber Terms shall be subject to the
          exclusive jurisdiction of the English courts.
        </Typography>
        <Typography variant="body1">
          If any provision of these Subscriber Terms is held to be illegal or unenforceable such
          provision shall be severed and the remainder of these Subscriber Terms shall remain in
          full force and effect, unless the business purpose of the contract between us is
          substantially frustrated as a result.
        </Typography>
      </section>
    </article>
  </PublicPageContentWrapper>
);

export default Terms;
