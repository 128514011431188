import { css } from '@emotion/react';

export const entry = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.up('sm')} {
      align-items: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const requirementsList = (theme) => (
  css`
    padding-top: ${theme.spacing(2)};
  `
);

export const fields = (theme) => (
  css`
    flex: 1;
    margin-right: ${theme.spacing(3)};

    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: flex-start;
      gap: ${theme.spacing(3)};
    }
  `
);

export const input = (theme) => (
  css`
    margin-top: 0;

    ${theme.breakpoints.up('sm')} {
      flex: 1;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const checkbox = (theme) => (
  css`
    margin-right: 0;
    margin-left: -5px;

    ${theme.breakpoints.up('sm')} {
      margin-top: ${theme.spacing(0.75)};
    }
  `
);

export const remove = (theme) => (
  css`
    height: fit-content;
    margin-top: ${theme.spacing(1)};
    padding: 0;
    color: ${theme.primaryPalette.primaryYellow};
  `
);
