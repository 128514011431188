import PropTypes from 'prop-types';
import SavedTopicPriorityList from 'components/Events/SavedTopicPriorityList';
import EventAttendeeType from 'types/EventAttendee';

const SelectedAttendeePriorities = ({ options, className, selectedAttendeeId }) => {
  const selectedGuest = !!selectedAttendeeId && options.find(({ id }) => id === selectedAttendeeId);

  if (!selectedGuest) {
    return null;
  }

  return (
    <SavedTopicPriorityList
      className={className}
      topics={selectedGuest.topics}
      notes={selectedGuest.notes}
    />
  );
};

SelectedAttendeePriorities.propTypes = {
  options: PropTypes.arrayOf(EventAttendeeType.isRequired).isRequired,
  className: PropTypes.string,
  selectedAttendeeId: PropTypes.string,
};

SelectedAttendeePriorities.defaultProps = {
  className: null,
  selectedAttendeeId: null,
};

export default SelectedAttendeePriorities;
