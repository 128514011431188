/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import FieldInputType from 'types/FieldInput';
import FieldMetaType from 'types/FieldMeta';
import inputStyles from './styles';

const Input = ({
  className,
  input,
  type,
  meta: {
    touched,
    error,
    submitError,
    submitting,
  },
  label,
  placeholder,
  endAdornment,
  sx,
  ...props
}) => (
  <TextField
    css={inputStyles}
    sx={sx}
    className={className}
    label={label}
    fullWidth
    InputProps={{ endAdornment }}
    error={!!((touched || submitting) && (error || submitError))}
    autoComplete="off"
    autoCorrect="off"
    spellCheck="off"
    type={type}
    helperText={(touched || submitting) && (error || submitError) ? (error || submitError) : ''}
    placeholder={placeholder}
    id={label ? input.name : null}
    {...input}
    {...props}
  />
);

Input.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  endAdornment: PropTypes.node,
  placeholder: PropTypes.string,
  input: FieldInputType.isRequired,
  type: PropTypes.string,
  meta: FieldMetaType.isRequired,
  label: PropTypes.string,
};

Input.defaultProps = {
  className: null,
  sx: null,
  endAdornment: null,
  placeholder: '',
  label: '',
  type: 'text',
};

export default Input;
