import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import GeneralModal from 'components/UI/GeneralModal';

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
}) => (
  <GeneralModal isOpen={isOpen} onClose={onClose} title={title}>
    <Typography sx={{ mb: 2 }} variant="body1">{description}</Typography>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm}>Confirm</Button>
    </DialogActions>
  </GeneralModal>
);

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
