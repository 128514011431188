/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/UI/TextFieldInput';
import { maxLength150 } from 'lib/validation';
import fieldStyles from './styles';

const FreeFormAnswersFieldArray = ({ className, fields }) => (
  !!fields.length && (
    <div className={className}>
      <Typography sx={{ mb: 3 }} variant="h2">
        Open-ended questions
      </Typography>
      <div>
        {fields.map((field, i) => (
          <Field
            key={field}
            css={fieldStyles}
            label={`Question ${i + 1}. ${fields.get(i).question}`}
            component={TextFieldInput}
            name={`${field}.answer`}
            placeholder="Please enter your answer"
            validate={[maxLength150]}
            multiline
            rows="6"
          />
        ))}
      </div>
    </div>
  )
);

FreeFormAnswersFieldArray.propTypes = {
  className: PropTypes.string.isRequired,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

FreeFormAnswersFieldArray.defaultProps = {
  className: null,
};

export default FreeFormAnswersFieldArray;
