import partition from 'lodash/partition';

export const groupQuestionsByRequirements = (questions = []) => (
  partition(questions, ({ mustHave }) => mustHave)
);

export const sortYesNoQuestions = (questions = []) => (
  questions.sort((a, b) => {
    if (a.mustHave && !b.mustHave) {
      return -1;
    }

    if (!a.mustHave && b.mustHave) {
      return 1;
    }

    return 0;
  })
);

export const matchYesNoQuestionsWithAnswers = (questions = [], answers = []) => (
  questions.map((question) => {
    const submittedAnswer = answers.find((answer) => answer.questionId === question.id);

    return {
      questionId: question.id,
      value: (submittedAnswer && typeof submittedAnswer.value === 'boolean') ? submittedAnswer.value : null,
      description: question.requirement.description,
      mustHave: question.mustHave,
    };
  })
);

export const processYesNoAnswers = (questions = [], answers = []) => {
  const matchedAnswers = matchYesNoQuestionsWithAnswers(questions, answers);
  const [mustHaveAnswers, niceToHaveAnswers] = groupQuestionsByRequirements(matchedAnswers);

  const indexedMustHaveAnswers = mustHaveAnswers.map((answer, index) => ({ ...answer, index }));
  const indexedNiceToHaveAnswers = niceToHaveAnswers.map((answer, index) => ({
    ...answer,
    index: index + mustHaveAnswers.length,
  }));

  return [indexedMustHaveAnswers, indexedNiceToHaveAnswers];
};
