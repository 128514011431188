/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Typography from '@mui/material/Typography';
import { required } from 'lib/validation';
import TextFieldInput from 'components/UI/TextFieldInput';
import DropDownInput from 'components/UI/DropDownInput';
import { COUNTRIES, EU_COUNTRIES, UNITED_KINGDOM } from 'constants/countries';

const OfficeAddressFormSection = ({ className, countryValue }) => (
  <div className={className}>
    <Typography sx={{ mb: 3 }} variant="h2">Head offices address</Typography>
    <Field
      fullWidth
      label="Country"
      sx={{ mb: 2 }}
      name="offices[0].country"
      component={DropDownInput}
      validate={required}
    >
      {COUNTRIES.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ))}
    </Field>
    <Field
      sx={{ mb: 2 }}
      label="Address"
      validate={[required]}
      component={TextFieldInput}
      name="offices[0].address1"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
    />
    <Field
      sx={{ mb: 2 }}
      label="Town / City"
      validate={[required]}
      component={TextFieldInput}
      name="offices[0].city"
    />
    <Field
      sx={{
        mb: (countryValue === UNITED_KINGDOM || EU_COUNTRIES.includes(countryValue)) ? 2 : 0,
      }}
      label="Post Code"
      validate={[required]}
      component={TextFieldInput}
      name="offices[0].post"
      placeholder="Post Code"
    />
    {countryValue === UNITED_KINGDOM && (
      <>
        <Field
          sx={{ mb: 2 }}
          label="UK registration number"
          validate={[required]}
          component={TextFieldInput}
          name="offices[0].registrationNumber"
          placeholder="UK registration number"
        />
        <Field
          label="VAT number"
          validate={[required]}
          component={TextFieldInput}
          name="offices[0].VAT"
          placeholder="VAT number e.g. GB660454836"
        />
      </>
    )}
    {EU_COUNTRIES.includes(countryValue) && (
      <Field
        sx={{ mb: 2 }}
        label="VAT number"
        validate={[required]}
        component={TextFieldInput}
        name="offices[0].VAT"
        placeholder="VAT Number e.g. GB660454836"
      />
    )}
  </div>
);

OfficeAddressFormSection.propTypes = {
  className: PropTypes.string,
  countryValue: PropTypes.string,
};

OfficeAddressFormSection.defaultProps = {
  className: null,
  countryValue: null,
};

export default OfficeAddressFormSection;
