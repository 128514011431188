/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Chip from 'components/UI/Chip';
import routes from 'constants/routes';
import TagType from 'types/Tags';
import * as classes from './styles';

const SearchTags = ({
  className,
  tagClassName,
  isTagClickable,
  history,
  tags,
}) => {
  const isAuthenticated = useSelector((state) => !!state.user.authUser.id);

  const handleSearchByTag = (tag) => {
    const search = new URLSearchParams({ query: tag }).toString();
    const pathname = isAuthenticated
      ? routes.SEARCH_SOLUTION_PAGE
      : routes.PUBLIC_SEARCH_SOLUTION;

    history.push({ pathname, search });
  };

  if (!tags.length) {
    return null;
  }

  return (
    <div className={className} css={classes.container}>
      {tags.map((tag) => (
        <Chip
          className={tagClassName}
          css={classes.tag}
          key={tag.id}
          clickable={isTagClickable}
          color="primary"
          label={tag.name}
          onClick={() => {
            if (isTagClickable) {
              handleSearchByTag(tag.name);
            }
          }}
        />
      ))}
    </div>
  );
};

SearchTags.propTypes = {
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  isTagClickable: PropTypes.bool,
  history: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(TagType.isRequired).isRequired,
};

SearchTags.defaultProps = {
  className: null,
  tagClassName: null,
  onClick: null,
  isTagClickable: true,
};

export default withRouter(SearchTags);
