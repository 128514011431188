import routes from 'constants/routes';
import SignInIcon from 'svg/signIn';
import FormIcon from 'svg/form';
import TermsIcon from 'svg/terms';
import PolicyIcon from 'svg/policy';

const guestMenu = [
  { title: 'Sign in', to: routes.LOGIN, icon: SignInIcon },
  { title: 'Register', to: routes.REGISTER, icon: FormIcon },
  { title: 'Terms & conditions', to: routes.TERMS_AND_CONDITIONS, icon: TermsIcon },
  { title: 'Privacy policy', to: routes.PRIVACY, icon: PolicyIcon },
];

export default guestMenu;
