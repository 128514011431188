/** @jsxImportSource @emotion/react */
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ListItem from 'components/UI/ListItem';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import routes from 'constants/routes';
import {
  COMPANY_NAME,
  COMPANY_SITE_TECH,
  COMPANY_SITE_UK,
  CONTACT_ADDRESS,
  EMAIL_SUPPORT,
  MEDIA_PARTNER,
} from 'constants/contacts';
import {
  PERSONAL_DATA,
  DATA_COLLECTING_RESOURCES,
  PURPOSES_USING_PERSONAL_DATA,
  DISCLOSURE_PERSONAL_DATA,
} from './privacyData';
import * as classes from './styles';

const Privacy = () => (
  <PublicPageContentWrapper css={classes.main}>
    <div css={classes.head}>
      <Typography variant="h1">Privacy policy</Typography>
      <Typography variant="subtitle2">Last updated 21st July 2020</Typography>
    </div>

    <article>
      <section css={classes.section}>
        <Typography variant="body1">
          Validify Innovations Limited (“we”, “us”, or “our” as the context dictates) is committed
          to protecting and respecting your privacy. This policy sets out the basis on which any
          personal data we collect from you, or that you provide to us, will be processed by us.
          Please read the following carefully to understand our practices regarding personal data
          and how we will treat it.
        </Typography>
        <Typography variant="body1">
          This policy (together with our{' '}
          <Link variant="body1" component={RouterLink} to={routes.TERMS_AND_CONDITIONS}>
            {' '}
            website terms
          </Link>{' '}
          of use and any other documents referred to in it), applies to your use of the{' '}
          <Link variant="body1" href={`https://${COMPANY_SITE_TECH}/`}>{COMPANY_SITE_TECH}</Link> and{' '}
          <Link variant="body1" href={`https://${COMPANY_SITE_UK}/`}>{COMPANY_SITE_UK}</Link> websites (
          <strong css={classes.highlight}>Website</strong>) and any of the services
          accessible through the Website (
          <strong css={classes.highlight}>the Services</strong>).
        </Typography>
        <Typography variant="body1">
          By visiting the Website and/or using the Services, you are accepting the practices
          described in this policy.
        </Typography>
        <Typography variant="body1">
          For the purposes of the General Data Protection Regulation (Regulation (EU) 2016/679), the
          data controller is a company registered in England (No. 09346111) whose address is{' '}
          {CONTACT_ADDRESS}.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Personal data we collect
        </Typography>
        <Typography variant="body1">
          The type of information we collect from you will depend upon the type of interaction you
          have with us.
        </Typography>
        <Typography variant="body1">
          Broadly speaking, the types of personal data that we collect may include:
        </Typography>
        <ul css={classes.list}>
          {PERSONAL_DATA.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
        <Typography variant="body1">
          You are under no obligation to provide any such data. However, if you choose to withhold
          requested information, you may not be able to access all of the Website’s contents and
          services.
        </Typography>
        <Typography variant="body1">
          We may collect personal data from you in a variety of ways, including the following:
        </Typography>
        <ul css={classes.list}>
          {DATA_COLLECTING_RESOURCES.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
        <Typography variant="body1">
          We may also collect personal data relating to you from third parties. This may include
          (but is not limited to) the collection of personal data relating to you from:
        </Typography>
        <ul css={classes.list}>
          <ListItem>our partners;</ListItem>
          <ListItem>
            our media partner{' '}<Link variant="body2" href={`https://${MEDIA_PARTNER}/`}>{MEDIA_PARTNER}</Link>
          </ListItem>
          <ListItem>
            our customer support service provider, when you make a customer service inquiry
          </ListItem>
          <ListItem>someone you duly authorise to act on your behalf; and</ListItem>
          <ListItem>
            recruiters we have retained and from referees you have provided in support of a job
            application.
          </ListItem>
        </ul>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Cookies
        </Typography>
        <Typography variant="body1">
          The Website uses cookies to better the user’s experience while visiting it. Where
          applicable, this Website uses a cookie control system allowing the user on their first
          visit to this Website to allow or disallow the use of cookies on their computer / device.
          This complies with the legal requirement for websites to obtain explicit consent from
          users before leaving behind or reading files such as cookies on a user’s computer /
          device.
        </Typography>
        <Typography variant="body1">
          Cookies are small files saved to the user’s computer hard drive that track, save and store
          information about the user’s interactions and usage of this Website. This allows this
          Website, through its server, to provide users with a tailored experience within this
          Website. You are advised that if you wish to deny the use and saving of cookies from this
          Website on to your computer hard drive you should take necessary steps within your web
          browser security settings to block all cookies from this Website and its external serving
          vendors, although this may disable or render unusable some of the features of this
          Website.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          How we use personal data
        </Typography>
        <Typography variant="body1">
          We use information relating to you that we collect, or that you provide to us, for the
          purposes of our legitimate interests as follows:
        </Typography>
        <ul css={classes.list}>
          {PURPOSES_USING_PERSONAL_DATA.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
        <Typography variant="body1">
          We may combine this data with personal data you give to us and personal data we collect
          that relates to you. We may use this personal data and the combined data for the purposes
          set out above (depending on the type of data we receive).
        </Typography>
        <Typography variant="body1">
          You have the right to object to this processing if you wish, and if you wish to do so,
          please send an email to <Link variant="body1" href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</Link> or
          write to us at {COMPANY_NAME}, {CONTACT_ADDRESS}. Please bear in mind that if you object
          this may affect our ability to carry out the tasks above for your benefit.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Disclosure of personal data
        </Typography>
        <Typography variant="body1">
          We may disclose personal data relating to you to third parties in certain circumstances
          including (but not limited to) the following:
        </Typography>
        <ul css={classes.list}>
          {DISCLOSURE_PERSONAL_DATA.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
        <Typography variant="body1">
          We may also disclose personal data to third parties:
        </Typography>
        <ul css={classes.list}>
          <ListItem>
            in the event that we sell or buy any business or assets, in which case we may disclose
            your personal data to the prospective seller or buyer of such business or assets;
          </ListItem>
          <ListItem>
            if we or substantially all of our assets are acquired by a third party, in which case
            personal data held by us about our customers will be one of the transferred assets;
          </ListItem>
          <ListItem>
            if we restructure our business, or if integrate our business with another group of
            companies, in which case personal data held by us will be disclosed to the new owner
            to be used in the same ways set out in this policy;
          </ListItem>
          <ListItem>
            if we are under a duty to disclose or share personal data relating to you in order to
            comply with any legal obligation, or in order to enforce or apply our terms and
            conditions on which we supply services to you{' '}
            <Link component={RouterLink} to={routes.TERMS_AND_CONDITIONS} variant="body2">
              website terms
            </Link>{' '}
            and other agreements; or to protect the rights, property, or safety of us, our
            customers, or others. This includes exchanging information with other companies and
            organisations for the purposes of fraud protection and credit risk reduction;
          </ListItem>
          <ListItem>for any other purposes that you would reasonably expect.</ListItem>
        </ul>
        <Typography variant="body1">
          Before we disclose any personal data to a third party, we take steps to ensure that the
          third party will protect personal data in accordance with applicable privacy laws and in a
          manner consistent with this policy. Third parties are required to restrict their use of
          this information to the purpose for which the information was provided.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          External Links
        </Typography>
        <Typography variant="body1">
          Although this Website only looks to include quality, safe and relevant external links
          users should always adopt a policy of caution before clicking any external web links
          mentioned throughout this Website.
        </Typography>
        <Typography variant="body1">
          This Website may, from time to time, contain links to and from the websites of our partner
          networks, advertisers and affiliates. If you follow a link to any of these websites,
          please note that these websites have their own privacy policies and that we do not accept
          any responsibility or liability for these policies. Please check these policies before you
          submit any personal data to these websites.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Social Media Platforms
        </Typography>
        <Typography variant="body1">
          Communication, engagement and actions taken through external social media platforms that
          we participate on are subject to the terms and conditions as well as the privacy policies
          of those social media platforms.
        </Typography>
        <Typography variant="body1">
          This Website may use social sharing buttons which help share web content directly from our
          web pages to the social media platform in question. Where you use such social sharing
          buttons you do so at your own discretion – you should note that the social media platform
          may track and save your request to share a web page respectively through your social media
          platform account. Please note these social media platforms have their own privacy
          policies, and we do not accept any responsibility or liability for these policies. Please
          check these policies before you submit any personal data to these social media platforms.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Where we store personal data
        </Typography>
        <Typography variant="body1">
          The personal data that we collect from you may be transferred to, and stored at, locations
          outside the European Economic Area (<strong css={classes.highlight}>EEA</strong>
          ). It may also be processed by staff operating outside the EEA who work for us.
        </Typography>
        <Typography variant="body1">
          As described in this privacy policy, we may also share personal data relating to you with
          other companies within our group or with third parties who are located overseas for
          business purposes and operational, support and continuity purposes, for example, when we
          use IT service providers or data storage services. Countries where personal data relating
          to you may be stored and / or processed, or where recipients of personal data relating to
          you may be located may have data protection laws which differ to the data protection laws
          in your country of residence.
        </Typography>
        <Typography variant="body1">
          By submitting your personal data, you accept that personal data relating to you may be
          transferred, stored or processed in this way. We will take all steps reasonably necessary
          to ensure that your data is treated securely and in accordance with this policy.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Security
        </Typography>
        <Typography variant="body1">
          Unfortunately, the transmission of information via the Internet is not completely secure.
          Although we will do our best to protect personal data relating to you, we cannot guarantee
          the security of such data transmitted to the Website; any transmission is at your own
          risk. Once we have received personal data relating to you, we use strict procedures and
          security features to try to prevent unauthorised access.
        </Typography>
        <Typography variant="body1">
          All information you provide to us is stored on secure servers. The safety and security of
          your information and any personal data relating to you also depends on you. Where we have
          given you (or where you have chosen) a password that enables you to access certain parts
          of the Website and/or the Services, you are responsible for keeping this password
          confidential. We ask you not to share a password with anyone.
        </Typography>
        <Typography variant="body1">
          We take steps to destroy or de-identify personal data when the information is no longer
          required for any purpose for which it may be used or disclosed by us and we are no longer
          required by law to retain the information.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Your rights
        </Typography>
        <Typography variant="body1">
          You have the right to access personal data held relating to you. To protect your privacy,
          we may take steps to verify your identity before taking any action in response to any
          request. You will not have to pay a fee to access personal information relating to you (or
          to exercise any of the other rights). However, we may charge a reasonable fee if your
          request for access is clearly unfounded or excessive. Alternatively, we may refuse to
          comply with the request in such circumstances.
        </Typography>
        <Typography variant="body1">
          We also want to make sure that your personal information is accurate and up to date. You
          may ask us to correct or remove any information you think is inaccurate.
        </Typography>
        <Typography variant="body1">
          To make a request under this section, please send an email to{' '}
          <Link href={`mailto:${EMAIL_SUPPORT}`} variant="body1">{EMAIL_SUPPORT}</Link> or by writing to us at{' '}
          {COMPANY_NAME}, {CONTACT_ADDRESS}.
        </Typography>
        <Typography variant="body1">
          If you have a concern about the way we are collecting or using your personal information,
          we request that you raise your concern with us in the first instance. Alternatively, you
          can contact the Information Commissioner&apos;s Office at
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Changes to this policy
        </Typography>
        <Typography variant="body1">
          We review and amend our privacy policy from time to time. Any changes we make to this
          policy in the future will be posted on this page and, where appropriate, notified to you
          by e-mail. Please check back frequently to see any updates or changes to this policy. The
          new terms may be displayed on-screen and you may be required to read and accept them to
          continue your use of the Website.
        </Typography>
      </section>

      <section css={classes.section}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Contact
        </Typography>
        <Typography variant="body1">
          Questions, comments and requests regarding this policy are welcomed. You may also ask us
          to correct or update any personal data we hold relating to you at any time.
        </Typography>
        <Typography variant="body1">
          You can contact us by emailing us at{' '}
          <Link href={`mailto:${EMAIL_SUPPORT}`} variant="body1">{EMAIL_SUPPORT}</Link> or writing to us at:{' '}
          {COMPANY_NAME}, {CONTACT_ADDRESS}.
        </Typography>
      </section>
    </article>
  </PublicPageContentWrapper>
);

export default Privacy;
