import APIClient from './client';

class ChallengeRequirementAPI extends APIClient {
  constructor() {
    super('/challenge-requirements');
  }

  getRequirementsByName = async (query) => {
    const filter = {
      limit: 10,
      where: {
        isReviewed: true,
        description: {
          like: query,
          options: 'i',
        },
      },
    };

    const { data } = await this.find(filter);

    return data;
  };
}

const challengeRequirementAPI = new ChallengeRequirementAPI();

export default challengeRequirementAPI;
