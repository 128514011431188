import PropTypes from 'prop-types';

const EventTopicNoteType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  topicId: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export default EventTopicNoteType;
