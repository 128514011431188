import { css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-wrap: wrap;
`;

export const tag = (theme) => (
  css`
    margin: 0 ${theme.spacing(2)} ${theme.spacing(1)} 0;
  `
);
