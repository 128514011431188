import PropTypes from 'prop-types';

const EventPriorityListType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  priorities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

export default EventPriorityListType;
