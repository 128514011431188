import { css } from '@emotion/react';

export const fields = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const submit = css`
  text-align: center;
`;
