import { css } from '@emotion/react';

export const button = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 70px;
    padding: ${theme.spacing(1)};;
    border: none;
    font-size: 14px;
    font-family: ${theme.boldFont};
    color: ${theme.primaryPalette.primaryYellow};
    background-color: transparent;
    cursor: pointer;
  `
);

export const disabled = (theme) => (
  css`
    color: ${theme.primaryPalette.midLightGrey};
    pointer-events: none;
  `
);

export const icon = css`
  width: 30px;
  margin: auto 0;
`;

export const caption = (theme) => (
  css`
    margin-top: ${theme.spacing(1)};
  `
);
