import APIClient from './client';

class EventParticipationRequestsAPI extends APIClient {
  constructor() {
    super('/event-participation-requests');
  }
}

const eventParticipationRequestsAPI = new EventParticipationRequestsAPI();

export default eventParticipationRequestsAPI;
