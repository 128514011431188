/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import RequestMessageModal from './RequestMessageModal';
import * as classes from './styles';

const ReviewRequestMessage = ({ message, companyName }) => {
  const [showRequestMessage, setShowRequestMessage] = useState(false);

  const handleToggleModal = (e) => {
    e.stopPropagation();
    setShowRequestMessage(!showRequestMessage);
  };

  return (
    <>
      <div css={classes.message}>
        <Truncate
          css={classes.truncate}
          lines={2}
          trimWhitespace
          ellipsis={
            <span>...&nbsp;
              <button css={classes.button} onClick={handleToggleModal}>show more</button>
            </span>
          }
        >
          {message}
        </Truncate>
      </div>

      <RequestMessageModal
        isOpen={showRequestMessage}
        onClose={handleToggleModal}
        message={message}
        companyName={companyName}
      />
    </>
  );
};

ReviewRequestMessage.propTypes = {
  message: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default ReviewRequestMessage;
