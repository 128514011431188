import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    box-sizing: border-box;
    max-width: 1100px;
    margin: 0 auto;

    ${theme.breakpoints.up(500)} {
      padding: 20px 50px;
    }

    ${theme.breakpoints.down(500)} {
      padding: 15px 25px;
    }
  `
);

export const content = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: ${theme.spacing(1)} 0;
    }
  `
);

export const main = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      margin-right: ${theme.spacing(1)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const top = (theme) => (
  css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `
);

export const icon = (theme) => (
  css`
    color: ${theme.primaryPalette.primaryYellow};
  `
);

export const getImageStyles = (isSticky) => (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      max-width: 100px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: ${isSticky ? '100px' : '200px'};
      transition: max-width 0.3s ease-in-out;
    }
  `
);

export const buttons = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.down('lg')} {
      flex-wrap: wrap;
      align-items: flex-end;
    }

    ${theme.breakpoints.between('sm', 'lg')} {
      justify-content: flex-end;
    }

    ${theme.breakpoints.down('sm')} {
      justify-content: space-between;
    }
  `
);

export const button = (theme) => (
  css`
    ${theme.breakpoints.up(500)} {
      min-width: 120px;
    }
  `
);
