import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  generatePath,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import routes from 'constants/routes';
import ChallengeForm from './ChallengeForm';
import ChallengeSubmitSuccess from './ChallengeSubmitSuccess';

const ChallengeFormContainer = (props) => {
  const [isPublished, setPublished] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  const handleOpenNewChallenge = () => {
    const { event } = props;

    const isNewRegularChallenge = pathname === routes.CREATE_NEW_CHALLENGE;
    const isNewEventChallenge = matchPath(pathname, {
      path: routes.EVENT_CHALLENGE_FORM,
      strict: true,
      exact: true,
    });

    if (isNewEventChallenge || isNewRegularChallenge) {
      setPublished(false);
    } else {
      const redirectUrl = event
        ? generatePath(routes.EVENT_CHALLENGE_FORM, { slug: event.slugs.slice(-1)[0] })
        : routes.CREATE_NEW_CHALLENGE;

      history.push(redirectUrl);
    }
  };

  return (
    isPublished
      ? <ChallengeSubmitSuccess onOpenNewChallenge={handleOpenNewChallenge} />
      : <ChallengeForm onPublish={() => setPublished(true)} {...props} />
  );
};

ChallengeFormContainer.propTypes = {
  event: PropTypes.shape({
    slugs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

ChallengeFormContainer.defaultProps = {
  event: null,
};

export default ChallengeFormContainer;
