import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(50, 55, 64, 0.07);
  `
);

export const main = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `
);

export const creationDate = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const logoWrapper = css`
  width: 80px;
`;

export const logo = css`
  max-width: 100%;
  max-height: 40px;
  height: auto;
`;

export const messageField = (theme) => (
  css`
    margin-top: ${theme.spacing(2)};
  `
);

export const messageCaption = (theme) => (
  css`
    display: inline-block;
    margin-bottom: ${theme.spacing(0.5)};
    color: ${theme.primaryPalette.menuGrey};
  `
);
