import { css } from '@emotion/react';

export const logo = (theme) => css`
  height: auto;
  object-fit: contain;

  ${theme.breakpoints.up('sm')} {
    max-width: 112px;
    max-height: 56px;
  }

  ${theme.breakpoints.down('sm')} {
    max-width: 80px;
    max-height: 40px;
  }
`;

export const card = (theme) => (
  css`
    box-sizing: border-box;
    padding: ${theme.spacing(2)};
    box-shadow: ${theme.boxShadows.large};
    border-radius: 8px;
    cursor: pointer;
  `
);

export const head = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2.5)};
  `
);

export const companyDetails = css`
  display: flex;
  flex-direction: column;
`;

export const rating = (theme) => css`
  display: flex;
  align-items: center;
  gap: 5.5px;
  margin-top: ${theme.spacing(0.75)};
  color: ${theme.palette.primary.main};
`;

export const footer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const tags = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
  `
);

export const checkbox = (theme) => css`
  display: flex;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  box-sizing: border-box;

  ${theme.breakpoints.up('sm')} {
    margin: 0 -${theme.spacing(2)} 0 0;
    width: 100px;
    gap: ${theme.spacing(1)};
  }

  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
    justify-content: flex-end;
  }
`;

export const icon = (theme) => css`
  color: ${theme.primaryPalette.grey};
`;
