import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, generatePath, useHistory } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';

import VideoPlayer from 'components/UI/VideoPlayer';
import PageBody from 'components/UI/PageBody';
import ManageChallengeStatus from 'components/ManageChallengeStatus';
import ChallengeActions from 'components/ManageChallengeStatus/ChallengeActions';
import routes from 'constants/routes';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import LinkIcon from 'svg/link';
import sortChallengeResponses from 'utils/sortChallengeResponses';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import ChallengeResponsesTable from '../ChallengeResponsesTable';
import ChallengeResponsesMobileList from '../ChallengeResponsesMobileList';
import './ChallengeResponsesDashboardContent.scss';

const ChallengeResponsesDashboardContent = ({ firstName }) => {
  const history = useHistory();
  const [videoLink, setVideoLink] = useState(null);

  const { state: { challenge, challengeResponses } } = useContext(ChallengeContext);

  const {
    id,
    name,
    yesNoQuestions,
  } = challenge;

  const handleOpenChallengeResponse = (companySlug) => {
    const path = generatePath(
      routes.CHALLENGE_RESPONSE_FOR_RETAILER,
      { challengeId: id, companySlug },
    );

    history.push(path);
  };

  const formattedResponsesCount = challengeResponses.length === 1 ? '1 response' : `${challengeResponses.length} responses`;
  const sortedChallengeResponses = sortChallengeResponses(challengeResponses);

  const checkCanViewResponse = ({ status }) => (
    status === challengeResponseStatus.SUBMITTED
    || status === challengeResponseStatus.UNDER_REVISION
  );

  const renderResponseStatus = ({ status }) => {
    if (
      status === challengeResponseStatus.SUBMITTED
      || status === challengeResponseStatus.UNDER_REVISION
    ) {
      return <LinkIcon className="vendor-response-link-icon" />;
    }

    if (status === challengeResponseStatus.ACCEPTED || status === challengeResponseStatus.DRAFT) {
      return <Typography variant="body2">Awaiting response</Typography>;
    }

    return null;
  };

  return (
    <>
      <PageBody>
        <div className="challenge-responses-dashboard">
          <Breadcrumbs className="challenge-responses-dashboard__breadcrumbs">
            <Link to={routes.MAIN}>Home</Link>
            <Link to={routes.RETAILER_CHALLENGE_DASHBOARD}>RFIs</Link>
            <Link to={generatePath(routes.SAVED_CHALLENGE, { id })}>{name}</Link>
            <Link to={generatePath(routes.CHALLENGE_RESPONSES_DASHBOARD, { challengeId: id })}>
              Responses
            </Link>
          </Breadcrumbs>
          <Typography className="challenge-responses-dashboard__title" variant="h1">
            {name}
          </Typography>

          <div className="challenge-responses-dashboard__status">
            <div className="challenge-responses-dashboard__summary">
              <h2 className="challenge-responses-dashboard__subtitle">Hi {firstName},</h2>
              {challengeResponses.length ? (
                <Typography className="challenge-responses-dashboard__overview" variant="body1">
                  You have received {formattedResponsesCount} to your RFI
                </Typography>
              ) : (
                <Typography variant="body1">
                  This RFI has not yet received any responses. Please check back again soon.
                </Typography>
              )}
            </div>
            {challenge.source === DEFAULT_CHALLENGE_SOURCE && (
              <div className="challenge-responses-dashboard__actions">
                <ManageChallengeStatus />
                <ChallengeActions />
              </div>
            )}
          </div>

          {!!challengeResponses.length && (
            <>
              <Hidden lgDown>
                <ChallengeResponsesTable
                  challengeResponses={sortedChallengeResponses}
                  questions={yesNoQuestions}
                  onOpenResponse={handleOpenChallengeResponse}
                  onOpenVideo={setVideoLink}
                  checkCanViewResponse={checkCanViewResponse}
                  renderResponseStatus={renderResponseStatus}
                />
              </Hidden>
              <Hidden lgUp>
                <ChallengeResponsesMobileList
                  challengeResponses={sortedChallengeResponses}
                  onOpenResponse={handleOpenChallengeResponse}
                  onOpenVideo={setVideoLink}
                  checkCanViewResponse={checkCanViewResponse}
                  renderResponseStatus={renderResponseStatus}
                />
              </Hidden>
            </>
          )}
        </div>
      </PageBody>

      <Backdrop
        className="video-player-backdrop"
        open={!!videoLink}
        onClick={() => setVideoLink(null)}
      >
        {videoLink && <VideoPlayer url={videoLink} />}
      </Backdrop>
    </>
  );
};

ChallengeResponsesDashboardContent.propTypes = {
  firstName: PropTypes.string.isRequired,
};

export default connect((state) => ({ firstName: state.user.authUser.firstName }))(
  ChallengeResponsesDashboardContent,
);
