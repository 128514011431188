/** @jsxImportSource @emotion/react */
import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import PriorityCardMenu from 'components/Events/PriorityCardMenu';
import EventTopicType from 'types/EventTopic';
import priorityTypes from 'constants/priorityTypes';
import TopicPriorityForm from '../TopicPriorityForm';
import * as classes from './styles';

const TopicCard = ({
  topic,
  note,
  topics,
  onSave,
  onSelectSlot,
  onCancel,
  onRemove,
  isSlotSelected,
}) => {
  const [isNoteShown, setShowNote] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setShowNote(false);
  }, [isSlotSelected]);

  const handleEdit = () => {
    setMenuOpen(false);
    onSelectSlot();
  };

  const initialValues = note ? { id: topic.id, comment: note } : { id: topic.id };

  return (
    <>
      <div css={classes.head}>
        {isSlotSelected ? (
          <TopicPriorityForm
            css={classes.form}
            topics={topics}
            onSave={onSave}
            onClose={onCancel}
            initialValues={typeof topic.id !== 'number' ? initialValues : null}
          />
        ) : (
          <div css={classes.savedData}>
            <Typography
              variant={topic.name ? 'subtitle2' : 'body2'}
              component="span"
              css={[classes.defaultTopic, !topic.name && classes.emptyTopic]}
              onClick={handleEdit}
            >
              {topic.name ? topic.name : 'Select topic'}
            </Typography>

            {topic.name && (
              <div css={classes.iconsWrap}>
                <PriorityCardMenu
                  isSlotSelected={isSlotSelected}
                  isDisabled={!note}
                  isExpanded={isNoteShown}
                  onToggleExpandCard={() => setShowNote(!isNoteShown)}
                  priorityItemName={priorityTypes.TOPIC}
                  onEdit={handleEdit}
                  onRemove={() => onRemove(topic)}
                  isMenuOpen={isMenuOpen}
                  setMenuOpen={setMenuOpen}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {!!note && !isSlotSelected && (
        <Collapse in={isNoteShown} css={classes.collapse}>
          <Typography css={classes.note} variant="body2">
            {note.split('\n').map((item, key) => (
              <Fragment key={key}>{item}<br/></Fragment>
            ))}
          </Typography>
        </Collapse>
      )}
    </>
  );
};

TopicCard.propTypes = {
  topic: PropTypes.oneOfType([
    EventTopicType.isRequired,
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ]).isRequired,
  note: PropTypes.string,
  topics: PropTypes.arrayOf(EventTopicType.isRequired).isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectSlot: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isSlotSelected: PropTypes.bool,
};

TopicCard.defaultProps = {
  note: null,
  isSlotSelected: false,
};

export default TopicCard;
