import { css } from '@emotion/react';

export const ul = (theme) => (
  css`
    padding-left: ${theme.spacing(2)};
    list-style: decimal;
  `
);

export const li = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
