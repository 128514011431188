import { css } from '@emotion/react';

export const ratings = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const field = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const buttons = css`
  display: flex;
  justify-content: space-between;
`;
