import { css } from '@emotion/react';

export const outerContainer = (theme) => (
  css`
    position: sticky;
    z-index: 110;
    background-color: ${theme.primaryPalette.white};

    ${theme.breakpoints.up('sm')} {
      top: 64px;
    }

    ${theme.breakpoints.down('sm')} {
      top: 56px;
    }
  `
);

export const sticky = css`
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07);
`;

export const innerContainer = css`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 100%;
  transition: max-width 0.2s ease-in;
`;
