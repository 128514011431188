/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  Delete20Regular,
  Edit20Regular,
  Send20Regular,
  Copy20Regular,
} from '@fluentui/react-icons';
import UserType from 'types/User';
import InvitedUserType from 'types/InvitedUser';
import * as classes from './styles';

const UsersTable = ({
  className,
  users,
  onDeleteUser,
  onResendInvitation,
  getEditUserPageUrl,
  renderUserRole,
  onCopyEmailToClipboard,
  onCopyPhoneNumberToClipboard,
}) => (
  <table css={classes.table} className={className}>
    <thead>
    <tr>
      <Typography
        css={[classes.cell, classes.th]}
        variant="caption1"
        component="th"
      >
        Email
      </Typography>
      <Typography
        css={[classes.cell, classes.th]}
        variant="caption1"
        component="th"
      >
        Name
      </Typography>
      <Typography
        css={[classes.cell, classes.th]}
        variant="caption1"
        component="th"
      >
        Phone
      </Typography>
      <Typography
        css={[classes.cell, classes.th]}
        variant="caption1"
        component="th"
      >
        Role
      </Typography>
      <th css={[classes.cell, classes.th]} />
      <th css={[classes.cell, classes.th]} />
    </tr>
    </thead>
    <tbody>
      {users.map((user) => {
        const isUserRegistered = !user.invitedUserEmail;

        return (
          <tr key={user.id} css={classes.row}>
            <Typography
              css={[classes.email, classes.cell]}
              variant="body2"
              component="td"
              onClick={() => onCopyEmailToClipboard(user)}
            >
              <div css={classes.contentToCopy}>
                <span css={classes.textWithOverflow}>
                  {user.invitedUserEmail || user.email}
                </span>
                <Copy20Regular css={[classes.icon, classes.copyIcon]} />
              </div>
            </Typography>

            <Typography
              css={[classes.extendedName, classes.cell]}
              variant="body2"
              component="td"
            >
              {isUserRegistered && `${user.firstName} ${user.lastName}`}
            </Typography>

            <Typography
              css={[classes.phoneNumberTd, classes.cell]}
              variant="body2"
              component="td"
              onClick={() => onCopyPhoneNumberToClipboard(user)}
            >
              {!!user.phoneNumber && (
                <div css={classes.contentToCopy}>
                  <span css={classes.textWithOverflow}>
                    {user.phoneNumber.code} {user.phoneNumber.number}
                  </span>
                  <Copy20Regular css={[classes.icon, classes.copyIcon]} />
                </div>
              )}
            </Typography>

            <Typography
              css={[classes.role, classes.cell]}
              variant="body2"
              component="td"
            >
              {renderUserRole(user)}
            </Typography>

            <td css={classes.cell}>
              {isUserRegistered ? (
                <Link to={getEditUserPageUrl(user)}>
                  <IconButton aria-label="open-edit-user-page" css={classes.icon}>
                    <Edit20Regular />
                  </IconButton>
                </Link>
              ) : (
                <Tooltip placement="top-start" title="Resend invitation">
                  <IconButton
                    aria-label="resend-user-invitation"
                    css={classes.icon}
                    onClick={() => onResendInvitation(user.id)}
                  >
                    <Send20Regular />
                  </IconButton>
                </Tooltip>
              )}
            </td>

            <td css={classes.cell}>
              <IconButton
                aria-label="delete-user"
                css={classes.deleteIcon}
                onClick={() => onDeleteUser(user.id)}
              >
                <Delete20Regular />
              </IconButton>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.oneOfType([
    UserType.isRequired,
    InvitedUserType.isRequired,
  ]).isRequired).isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  getEditUserPageUrl: PropTypes.func.isRequired,
  renderUserRole: PropTypes.func.isRequired,
  onCopyEmailToClipboard: PropTypes.func.isRequired,
  onCopyPhoneNumberToClipboard: PropTypes.func.isRequired,
};

UsersTable.defaultProps = {
  className: null,
};

export default UsersTable;
