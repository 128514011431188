import { css } from '@emotion/react';

export const requestCards = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      display: none;
    }
  `
);

export const table = (theme) => (
  css`
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  `
);

export const card = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)}
    }
  `
);
