import { generatePath } from 'react-router-dom';
import routes from 'constants/routes';

// TODO: reuse in other places of app
export const getChallengeResponseFormUrl = (challenge) => (
  generatePath(routes.CHALLENGE_RESPONSE_FORM, { challengeId: challenge.id })
);

export const getChallengeUrl = (challenge) => {
  const { slug: rfiSlug, company: { slug: retailerSlug } } = challenge;

  return generatePath(routes.PUBLIC_CHALLENGE_INFO, { retailerSlug, rfiSlug });
};

export const getSavedChallengeResponseUrl = (challenge) => (
  generatePath(routes.CHALLENGE_RESPONSE_FOR_VENDOR, { challengeId: challenge.id })
);
