const formatSearchResults = (results) => (
  results
    .filter((searchDocument) => !!searchDocument.id)
    .map((searchDocument) => ({
      id: searchDocument.id,
      name: searchDocument.name,
      logo: searchDocument.logo,
      founded: searchDocument.vendors.founded,
      stageOfMaturity: searchDocument.vendors.stageOfMaturity,
      shortDescription: searchDocument.vendors.shortDescription,
      clients: searchDocument.vendors.clientsList,
      reviews: searchDocument.ratingsReviews,
      tags: searchDocument.tags,
      slug: searchDocument.slug,
    }))
);

export default formatSearchResults;
