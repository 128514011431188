import pick from 'lodash/pick';
import { sortYesNoQuestions, matchYesNoQuestionsWithAnswers } from 'utils/prepareChallengeYesNoQuestions';
import matchFreeFormAnswersWithQuestions from 'utils/matchFreeFormAnswersWithQuestions';

const emptyCaseStudyAssetField = { name: '', value: '' };

const whiteListFieldNames = [
  'attachment',
  'productDescription',
  'challengeRespond',
  'questions',
  'demonstrationVideo',
  'additionalCommentOnRequirements',
  'minimumDeploymentTime',
  'GDPRRegulations',
  'clientReferences',
  'caseStudyInfo',
  'caseStudyAssets',
  'pricingModel',
  'additionalInfo',
  'openEndedQuestions',
  'id',
  'status',
];

const initialValues = {
  caseStudyAssets: [emptyCaseStudyAssetField],
  clientReferences: [
    {
      companyName: '',
      companyUrl: '',
    },
  ],
};

// TODO: fix field naming of question fields in API and rename them here
const formatInitialValues = (responseValues, challengeInfo) => {
  const filteredValues = pick(responseValues, whiteListFieldNames);
  const {
    clientReferences,
    caseStudyAssets,
    questions: yesNoAnswers,
    openEndedQuestions: freeFormAnswers,
    ...rest
  } = filteredValues;

  const yesNoQuestions = challengeInfo && challengeInfo.yesNoQuestions;
  const sortedQuestions = sortYesNoQuestions(yesNoQuestions);
  const matchedYesNoAnswers = matchYesNoQuestionsWithAnswers(sortedQuestions, yesNoAnswers);

  const openEndedQuestions = challengeInfo && challengeInfo.openEndedQuestions;
  const matchedFreeFormAnswers = matchFreeFormAnswersWithQuestions(
    openEndedQuestions,
    freeFormAnswers,
  );

  return {
    questions: matchedYesNoAnswers,
    openEndedQuestions: matchedFreeFormAnswers,
    clientReferences:
      Array.isArray(clientReferences) && clientReferences.length
        ? clientReferences
        : initialValues.clientReferences,
    caseStudyAssets:
      Array.isArray(caseStudyAssets) && caseStudyAssets.length
        ? [...caseStudyAssets, emptyCaseStudyAssetField]
        : initialValues.caseStudyAssets,
    ...rest,
  };
};

export default formatInitialValues;
