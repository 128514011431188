/** @jsxImportSource @emotion/react */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import TextFieldInput from 'components/UI/TextFieldInput';
import insertHost from 'utils/picture';
import { maxLength100 } from 'lib/validation';
import FileUpload from 'components/UI/FileUpload';
import fileAPI from 'api/file';
import { imageUploadConfig } from 'constants/fileUploadConfigs';
import * as classes from './styles';

class TeamMemberSection extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    userId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  handleSaveFile = (onChange) => async (formData) => {
    const { userId } = this.props;

    const response = await fileAPI.uploadFile('member', 'avatar', formData, userId);
    onChange(response.body);

    return response;
  };

  renderUploadField = ({ input: { onChange, value } }) => (
    <div css={[classes.field, value ? classes.filledUpload : classes.emptyUpload]}>
      {!!value && (
        <img
          css={classes.uploadedImage}
          src={insertHost(value)}
          alt=""
        />
      )}
      <FileUpload
        {...imageUploadConfig}
        isIcon={!!value}
        onUpload={this.handleSaveFile(onChange)}
      />
    </div>
  );

  render() {
    const { className } = this.props;

    return (
      <div css={classes.section} className={className}>
        <Field name="avatar" component={this.renderUploadField} />
        <div css={classes.fields}>
          <Field
            sx={{ mb: 2 }}
            name="name"
            placeholder="Name"
            validate={maxLength100}
            component={TextFieldInput}
          />
          <Field
            name="position"
            placeholder="Job title"
            validate={maxLength100}
            component={TextFieldInput}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({ userId: state.user.authUser.id }))(
  (TeamMemberSection),
);
