const initialValues = {
  overallRating: {
    field: 'Overall rating',
    value: null,
  },
  ratings: [
    {
      field: 'Ease of Use',
      value: 0,
    },
    {
      field: 'Value for Money',
      value: 0,
    },
    {
      field: 'Ease of Integration',
      value: 0,
    },
    {
      field: 'Features & Functionality',
      value: 0,
    },
  ],
  recommendSolution: null,
  pros: '',
  cons: '',
};

export default initialValues;
