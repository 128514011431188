import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import routes from 'constants/routes';

const ChallengeResponseBreadcrumbs = ({
  className,
  challenge: { id, name },
  companyName,
  companySlug,
}) => {
  const savedChallengeUrl = generatePath(routes.SAVED_CHALLENGE, { id });

  const challengeResponsesUrl = generatePath(
    routes.CHALLENGE_RESPONSES_DASHBOARD,
    { challengeId: id },
  );

  const responseUrl = generatePath(
    routes.CHALLENGE_RESPONSE_FOR_RETAILER,
    { challengeId: id, companySlug },
  );

  return (
    <Breadcrumbs className={className}>
      <Link to={routes.MAIN}>Home</Link>
      <Link to={routes.RETAILER_CHALLENGE_DASHBOARD}>RFIs</Link>
      <Link to={savedChallengeUrl}>{name}</Link>
      <Link to={challengeResponsesUrl}>Responses</Link>
      <Link to={responseUrl}>Response from {companyName}</Link>
    </Breadcrumbs>
  );
};

ChallengeResponseBreadcrumbs.propTypes = {
  className: PropTypes.string,
  challenge: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
  companySlug: PropTypes.string.isRequired,
};

ChallengeResponseBreadcrumbs.defaultProps = {
  className: null,
};

export default ChallengeResponseBreadcrumbs;
