import { css } from '@emotion/react';

export const avatarContainer = css`
  width: 130px;
  height: 130px;
`;

export const avatar = css`
  max-width: 100%;
  height: auto;
`;

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(4)};
  `
);

export const footer = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)} ${theme.spacing(2)};
    justify-content: space-between;
  `
);

export const profileDetails = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)} 40%;
  `
);

export const line = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);
