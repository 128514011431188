import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import PageSubheader from 'components/GeneralLayout/PageSubheader';
import GeneralModal from 'components/UI/GeneralModal';
import PageBody from 'components/UI/PageBody';
import CompanyNotFound from 'components/Shared/CompanyNotFound';
import BasicDetails from 'components/VendorInfo/BasicDetails';
import VendorProfileHead from 'components/VendorInfo/VendorProfileHead';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import routes from 'constants/routes';
import LocationType from 'types/Location';
import PublicVendorPageHead from './PublicVendorPageHead';
import InviteNewUser from './InviteNewUser';

class PublicVendorInfo extends Component {
  static propTypes = {
    companyName: PropTypes.string,
    location: LocationType.isRequired,
    isError: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    companyName: null,
  };

  state = {
    isInviteNewUserModalOpen: false,
  };

  handleInviteUserModal = (isOpen) => this.setState({ isInviteNewUserModalOpen: isOpen });

  render() {
    const {
      isError,
      companyName,
      location: { pathname },
      match: { url },
    } = this.props;
    const { isInviteNewUserModalOpen } = this.state;

    if (isError) {
      return <CompanyNotFound />;
    }

    const breadcrumbs = [
      { title: 'Login', to: routes.LOGIN },
      { title: companyName, to: pathname },
      { title: 'Basic details', to: pathname },
    ];

    const tabPathname = `${url}/${VENDOR_INFO_TABS.BASIC_DETAILS.path}`;

    return (
      <>
        <Switch>
          <Route path={tabPathname}>
            <PageSubheader
              renderChildren={({ isSticky }) => (
                <VendorProfileHead isSticky={isSticky} breadcrumbs={breadcrumbs} />
              )}
            />
            <PageBody>
              <PublicVendorPageHead
                onOpenInviteNewUserModal={() => this.handleInviteUserModal(true)}
              />
              <BasicDetails isTagClickable />
            </PageBody>
          </Route>
          <Redirect to={tabPathname} />
        </Switch>
        <GeneralModal
          isOpen={isInviteNewUserModalOpen}
          onClose={() => this.handleInviteUserModal(false)}
          title="Authorization required"
        >
          <InviteNewUser handleClose={() => this.handleInviteUserModal(false)} />
        </GeneralModal>
      </>
    );
  }
}

export default connect((state) => ({
  isError: state.vendorInfo.isError,
  companyName: state.vendorInfo.company.name,
}))(PublicVendorInfo);
