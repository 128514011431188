import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Typography } from '@mui/material';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import { SupportType, TrialType } from 'types/Vendor';
import DocumentUploadType from 'types/DocumentUpload';
import TagType from 'types/Tags';
import 'styles/VendorProfile.scss';
import TextBlock from '../TextBlock';
import List from '../List';
import SavedDocumentationList from '../SavedDocumentationList';
import VendorStats from '../VendorStats';
import './TechnicalDetails.scss';

const NOT_SET_TRIAL = 'N/A';

const TechnicalDetails = ({
  dataSecurity,
  hasEndUserTraining,
  dataPrivacyCompliance,
  integrationDataRequirement,
  documentation,
  licence,
  integrationLabels,
  trial,
  techoverview,
  support: { feature },
}) => {
  const selectedFeatures = feature.filter(({ checked }) => checked).map(({ name }) => name);
  const integrationsList = integrationLabels.map(({ name }) => name);

  const getFormattedTrialTerm = () => {
    if (!trial.terms || trial.terms === NOT_SET_TRIAL) {
      return null;
    }

    const result = trial.terms === '1' ? trial.type_terms.slice(0, -1) : trial.type_terms;

    return result.toLowerCase();
  };

  return (
    <>
      <Typography className="vendor-profile-title" variant="h1">
        Technical details
      </Typography>
      <TextBlock
        className="vendor-profile-section"
        title="Technical overview"
        text={techoverview}
      />
      <List className="vendor-profile-section" title="Support" list={selectedFeatures} />

      <div className="technical-details-stats vendor-profile-section">
        <VendorStats
          className="technical-details-stats__item"
          title="Minimum trial period"
          caption={getFormattedTrialTerm()}
        >
          {trial.terms && trial.type_terms ? trial.terms : NOT_SET_TRIAL}
        </VendorStats>
        {hasEndUserTraining === yesNoOptions.YES && (
          <VendorStats
            className="technical-details-stats__item"
            title="Training"
            text="End-user training is available"
          >
            {hasEndUserTraining}
          </VendorStats>
        )}

        {licence && (
          <VendorStats
            className="technical-details-stats__item"
            title="Additional licenses required?"
          >
            {licence}
          </VendorStats>
        )}
      </div>

      <SavedDocumentationList
        className="vendor-profile-section"
        title="Technical documentation"
        list={documentation}
      />

      <TextBlock className="vendor-profile-section" title="Data security" text={dataSecurity} />
      <TextBlock
        className="vendor-profile-section"
        title="Data privacy compliance"
        text={dataPrivacyCompliance}
      />
      <TextBlock
        className="vendor-profile-section"
        title="Integration and data requirements"
        text={integrationDataRequirement}
      />
      <List
        className="vendor-profile-section"
        title="Pre-existing integrations"
        list={integrationsList}
      />
    </>
  );
};

TechnicalDetails.propTypes = {
  dataSecurity: PropTypes.string,
  dataPrivacyCompliance: PropTypes.string,
  hasEndUserTraining: PropTypes.oneOf(Object.values(yesNoOptions)),
  integrationDataRequirement: PropTypes.string,
  licence: PropTypes.oneOf(Object.values(yesNoOptions)),
  support: SupportType,
  trial: TrialType,
  integrationLabels: PropTypes.arrayOf(TagType),
  documentation: PropTypes.arrayOf(DocumentUploadType.isRequired).isRequired,
  techoverview: PropTypes.string,
};

TechnicalDetails.defaultProps = {
  techoverview: '',
  hasEndUserTraining: yesNoOptions.NO,
  support: {
    escrow: yesNoOptions.NO,
    purchase: yesNoOptions.NO,
    feature: [],
  },
  trial: {
    terms: '',
    type_terms: '',
  },
  integrationLabels: [],
};

const mapStateToProps = (state) => {
  const companyObject = get(state, 'vendorInfo.company', {});
  const companyVendor = get(state, 'vendorInfo.company.vendors', {});
  const vendorProductService = get(state, 'vendorInfo.company.vendors.productService', {});
  const documentation = get(companyVendor, 'technicalDocumentation', []);

  return {
    ...pick(companyObject, ['integrationLabels']),
    ...pick(companyVendor, ['support', 'licence']),
    ...pick(vendorProductService, [
      'techoverview',
      'trial',
      'dataSecurity',
      'dataPrivacyCompliance',
      'integrationDataRequirement',
      'techOverview',
      'integrations',
      'hasEndUserTraining',
    ]),
    documentation,
  };
};

export default connect(mapStateToProps)(TechnicalDetails);
