/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import userAPI from 'api/user';
import TextFieldInput from 'components/Form/TextFieldInput';
import { validatePassword, required } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import validate from './validation';
import submitStyles from './styles';

class ChangePasswordForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  handleUpdatePassword = async ({ oldPassword, newPassword }) => {
    const { onCloseModal, enqueueSnackbar } = this.props;

    try {
      await userAPI.changePassword({ oldPassword, newPassword });

      enqueueSnackbar('Password updated successfully', { variant: 'success' });
      onCloseModal();
    } catch (_) {
      enqueueSnackbar('Failed to change password', { variant: 'error' });
    }
  };

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <Typography sx={{ mb: 3 }} variant="h2">
          Change your password
        </Typography>
        <Form
          onSubmit={this.handleUpdatePassword}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                sx={{ mb: 2 }}
                component={TextFieldInput}
                name="oldPassword"
                label="Old password"
                type="password"
                validate={required}
              />
              <Field
                sx={{ mb: 2 }}
                component={TextFieldInput}
                name="newPassword"
                label="New password"
                type="password"
                validate={composeValidators(required, validatePassword)}
              />
              <Field
                sx={{ mb: 2 }}
                component={TextFieldInput}
                name="confirmPassword"
                label="Confirm new password"
                type="password"
                validate={required}
              />

              <div css={submitStyles}>
                <Button type="submit">Update your password</Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withSnackbar(ChangePasswordForm);
