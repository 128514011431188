/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import * as classes from './styles';

const BottomBar = ({ className, buttonText, ...buttonProps }) => (
  <AppBar
    position="sticky"
    css={classes.appBar}
    className={className}
    component="footer"
  >
    <Toolbar css={classes.toolbar}>
      <Button fullWidth {...buttonProps}>
        {buttonText}
      </Button>
    </Toolbar>
  </AppBar>
);

BottomBar.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

BottomBar.defaultProps = {
  className: null,
};

export default BottomBar;
