/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompleteRegistrationIcon from 'svg/completeRegistration';
import * as classes from './styles';

const ThankYouForRequest = ({ onClose }) => (
  <div css={classes.container}>
    <CompleteRegistrationIcon css={classes.image} />
    <Typography variant="h3" sx={{ mb: 2 }}>
      Thank you!
    </Typography>
    <Typography variant="body1" css={classes.text}>
      Your message has been sent. The solution provider will respond to you soon.
    </Typography>
    <Button onClick={onClose}>Close</Button>
  </div>
);

ThankYouForRequest.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ThankYouForRequest;
