import APIClient from './client';

class SavedVendorAPI extends APIClient {
  constructor() {
    super('/user-saved-vendor');
  }

  getSavedVendors = (userId) => {
    const filter = {
      where: {
        userId,
      },
      include: [
        {
          relation: 'company',
          scope: {
            include: ['vendors', 'ratingsReviews', 'tags'],
          },
        },
      ],
    };

    return this.find(filter);
  };
}

const savedVendorAPI = new SavedVendorAPI();

export default savedVendorAPI;
