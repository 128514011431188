import { css } from '@emotion/react';

export const sessions = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      display: flex;
      gap: ${theme.spacing(5)};
    }
  `
);

export const session = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      flex: 1;
    }

    ${theme.breakpoints.down('lg')} {
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(5)};
      }
    }
  `
);

export const table = (theme) => (
  css`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 ${theme.spacing(1)};

    ${theme.breakpoints.up('md')} {
      max-width: 510px;
    }
  `
);

export const cell = (theme) => (
  css`
    padding-right: ${theme.spacing(1.5)};
    padding-left: ${theme.spacing(1.5)};

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(2)};
    }
  `
);

export const th = (theme) => (
  css`
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
    text-align: left;
    color:  ${theme.primaryPalette.menuGrey};
  `
);

export const td = (theme) => (
  css`
    padding-top: ${theme.spacing(1.25)};
    padding-bottom: ${theme.spacing(1.25)};
    vertical-align: middle;
  `
);

export const row = css`
  box-shadow: 0px 0px 10px rgba(50, 55, 64, 0.07);
  border-radius: 4px;
`;

export const slot = (
  css`
    width: 25px;
  `
);

export const time = (
  css`
    width: 50px;
  `
);

export const attendeeToMeet = css`
  text-align: left;
  word-break: break-word;
`;

export const emptySlot = (theme) => (
  css`
    padding: 10px 0;
    color: ${theme.primaryPalette.menuGrey};
  `
);
