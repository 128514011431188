import { CalendarLtr24Regular, Star24Regular, Scales24Regular } from '@fluentui/react-icons';
import routes from 'constants/routes';
import { profileMenuList } from 'components/VendorDataCaptureForm/constants';
import companyStatus from 'constants/companyStatus';
import ProfileIcon from 'svg/profile';
import { homeMenuItem, getCompanyUsersMenuItem } from './commonMenuItems';

const isChallengeMenuItemActive = (match) => {
  const { path } = match;

  return (
    path === routes.CHALLENGE_RESPONSE_FORM
    || path === routes.CHALLENGE_RESPONSE_FOR_VENDOR
    || path === routes.PUBLIC_CHALLENGE_INFO
    || path === routes.VENDOR_CHALLENGE_DASHBOARD
  );
};

const vendorMenu = [
  homeMenuItem,
  {
    isCompanyApproveRequired: true,
    title: 'RFIs',
    to: routes.VENDOR_CHALLENGE_DASHBOARD,
    isSelected: isChallengeMenuItemActive,
    icon: Scales24Regular,
  },
  {
    isCompanyApproveRequired: true,
    title: 'Events',
    to: routes.VENDOR_EVENTS_DASHBOARD,
    icon: CalendarLtr24Regular,
  },
  {
    isAdminRightsRequired: true,
    title: 'Profile',
    to: routes.EDIT_VENDOR_DETAILS,
    submenu: profileMenuList,
    icon: ProfileIcon,
  },
  getCompanyUsersMenuItem(companyStatus.VENDOR),
  {
    isCompanyApproveRequired: true,
    title: 'Get rated',
    to: routes.REQUEST_REVIEW,
    icon: Star24Regular,
  },
];

export default vendorMenu;
