import PropTypes from 'prop-types';
import clsx from 'clsx';
import './PentagonIcon.scss';

const PentagonIcon = ({ className }) => (
  <div className={clsx('pentagon-icon', className)}>
    <svg className="icon" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.34485 1.18172L9.34553 1.18268L12.0536 5.00004L9.34553 8.8174L9.34485 8.81837C9.19638 9.0287 8.94727 9.16659 8.66704 9.16671C8.66691 9.16671 8.66679 9.16671 8.66667 9.16671L1.33379 9.16004H1.33333C0.872874 9.16004 0.5 8.78731 0.5 8.33337V1.66671C0.5 1.21277 0.872874 0.840041 1.33333 0.840041L1.33379 0.840041L8.66667 0.833374C8.66673 0.833374 8.6668 0.833374 8.66687 0.833374C8.94717 0.83344 9.19635 0.971337 9.34485 1.18172Z"
        fill="#D1D700"
      />
    </svg>
  </div>
);

PentagonIcon.propTypes = {
  className: PropTypes.string,
};

PentagonIcon.defaultProps = {
  className: null,
};

export default PentagonIcon;
