/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import composeValidators from 'utils/composeValidators';
import FieldList from './FieldList';
import subLabelStyles from './styles';

const CustomFieldArray = ({
  className,
  label,
  maxItems,
  minItems,
  name,
  fieldValidators,
  arrayValidators,
  subLabel,
  buttonText,
}) => (
  <fieldset className={className} component="fieldset">
    <FormLabel sx={{ mb: 1 }} component="legend">{label}</FormLabel>
    {!!subLabel && (
      <Typography sx={{ mb: 1 }} css={subLabelStyles} variant="body2">
        {subLabel}
      </Typography>
    )}
    <FieldArray
      name={name}
      component={FieldList}
      fieldValidators={fieldValidators}
      minItems={minItems}
      maxItems={maxItems}
      validate={arrayValidators.length ? composeValidators(arrayValidators) : null}
      buttonText={buttonText}
    />
  </fieldset>
);

CustomFieldArray.propTypes = {
  arrayValidators: PropTypes.arrayOf(PropTypes.func.isRequired),
  className: PropTypes.string,
  fieldValidators: PropTypes.arrayOf(PropTypes.func.isRequired),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxItems: PropTypes.number,
  minItems: PropTypes.number,
  subLabel: PropTypes.string,
  buttonText: PropTypes.string,
};

CustomFieldArray.defaultProps = {
  arrayValidators: [],
  className: null,
  fieldValidators: [],
  subLabel: null,
  maxItems: null,
  minItems: 0,
  buttonText: undefined,
};

export default CustomFieldArray;
