import { useContext } from 'react';
import ChallengeResponsesDashboard from 'components/ChallengeResponsesDashboard';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import FetchContainer from 'containers/FetchContainer';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const ChallengeResponsesDashboardPage = (props) => {
  const { getChallengeResponses } = useContext(ChallengeContext);

  return (
    <FetchContainer
      getInitialProps={getChallengeResponses}
      component={ChallengeResponsesDashboard}
      {...props}
    />
  );
};

export default (withPageTitle(pageTitles.CHALLENGE_RESPONSES_DASHBOARD)(
  ChallengeResponsesDashboardPage,
));
