import PropTypes from 'prop-types';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';

export const DraftChallengeResponseType = PropTypes.shape({
  attachment: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    challengeResponseStatus.ACCEPTED,
    challengeResponseStatus.DRAFT,
  ]).isRequired,
  productDescription: PropTypes.string,
  challengeRespond: PropTypes.string,
  demonstrationVideo: PropTypes.string,
  additionalCommentOnRequirements: PropTypes.string,
  minimumDeploymentTime: PropTypes.string,
  GDPRRegulations: PropTypes.oneOf(Object.values(yesNoOptions)),
  clientReferences: PropTypes.arrayOf(PropTypes.shape({
    clientName: PropTypes.string,
    companyUrl: PropTypes.string,
  })),
  caseStudyInfo: PropTypes.string,
  caseStudyAssets: PropTypes.arrayOf(PropTypes.string.isRequired),
  pricingModel: PropTypes.string,
  additionalInfo: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      value: PropTypes.bool,
    }),
  ),
  openEndedQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      answer: PropTypes.string,
    }),
  ),
});

export const SubmittedChallengeResponseType = PropTypes.shape({
  attachment: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    challengeResponseStatus.SUBMITTED,
    challengeResponseStatus.UNDER_REVISION,
  ]).isRequired,
  productDescription: PropTypes.string.isRequired,
  challengeRespond: PropTypes.string.isRequired,
  demonstrationVideo: PropTypes.string.isRequired,
  additionalCommentOnRequirements: PropTypes.string,
  minimumDeploymentTime: PropTypes.string.isRequired,
  GDPRRegulations: PropTypes.oneOf(Object.values(yesNoOptions)).isRequired,
  clientReferences: PropTypes.arrayOf(PropTypes.shape({
    clientName: PropTypes.string,
    companyUrl: PropTypes.string,
  })).isRequired,
  caseStudyInfo: PropTypes.string.isRequired,
  caseStudyAssets: PropTypes.arrayOf(PropTypes.string.isRequired),
  pricingModel: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
    }),
  ),
  openEndedQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      answer: PropTypes.string,
    }),
  ).isRequired,
});

export const RejectedChallengeResponseType = PropTypes.shape({
  status: PropTypes.oneOf([challengeResponseStatus.REJECTED]).isRequired,
  isParticipationRequestSent: PropTypes.bool,
  rejectionReason: PropTypes.string,
});

export const DisabledChallengeResponseType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf([challengeResponseStatus.REMOVED_FROM_SHORTLIST]).isRequired,
});

export const ChallengeResponseType = PropTypes.oneOfType([
  RejectedChallengeResponseType.isRequired,
  SubmittedChallengeResponseType.isRequired,
  DraftChallengeResponseType.isRequired,
  DisabledChallengeResponseType.isRequired,
]);
