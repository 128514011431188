/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GeneralModal from 'components/UI/GeneralModal';
import * as classes from './styles';

const SaveUserChangesModal = ({ isOpen, onClose, onConfirm }) => {
  const history = useHistory();

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title="You have unsaved changes"
    >
      <Typography variant="body2" sx={{ mb: 5 }}>
        You are about to leave this page without saving. All changes will be lost. Do you want to
        leave without saving?
      </Typography>

      <div css={classes.buttons}>
        <Button onClick={onConfirm}>Save</Button>
        <Button variant="text" onClick={history.goBack}>Leave without saving</Button>
      </div>
    </GeneralModal>
  );
};

SaveUserChangesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SaveUserChangesModal;
