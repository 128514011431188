import { useState } from 'react';
import PropTypes from 'prop-types';
import ContactIcon from 'svg/contact';
import GeneralModal from 'components/UI/GeneralModal';
import ManageProfileButton from 'components/Shared/ManageProfileButton';
import contactFormTrigger from 'constants/contactFormTrigger';
import ContactVendorForm from '../ContactVendorForm';

const ContactVendor = ({
  className,
  companyId,
  contactName,
  trigger,
}) => {
  const [contactFormModal, setContactFormModalOpen] = useState(false);

  return (
    <>
      <ManageProfileButton
        className={className}
        icon={ContactIcon}
        onClick={() => setContactFormModalOpen(true)}
        title="Contact"
      />
      <GeneralModal
        isOpen={contactFormModal}
        onClose={() => setContactFormModalOpen(false)}
        title="Contact vendor"
      >
        <ContactVendorForm
          companyId={companyId}
          contactName={contactName}
          trigger={trigger}
          onSuccess={() => setContactFormModalOpen(false)}
        />
      </GeneralModal>
    </>
  );
};

ContactVendor.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  trigger: PropTypes.oneOf(Object.values(contactFormTrigger)).isRequired,
};

ContactVendor.defaultProps = {
  className: null,
};

export default ContactVendor;
