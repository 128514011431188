import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import checkHasAuthToken from 'utils/checkHasAuthToken';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';

class ChallengeUnmountSaver extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    isEditable: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
  };

  static contextType = ReduxFormContext;

  componentWillUnmount() {
    const { isEditable, onSave, values } = this.props;
    const { dirty, submitting } = this.context;

    const isAuthenticated = checkHasAuthToken();

    if (
      isAuthenticated
      && dirty
      && !submitting
      && (isEditable(values) || values.source !== DEFAULT_CHALLENGE_SOURCE)
    ) {
      onSave(values);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return null;
  }
}

export default connect((state, ownProps) => ({ values: getFormValues(ownProps.form)(state) }))(
  ChallengeUnmountSaver,
);
