import { useContext } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import PageContainer from 'containers/PageContainer';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import getMatchType from 'types/Match';
import { ChallengeResponseType } from 'types/ChallengeResponse';
import ChallengeResponseBreadcrumbs from './ChallengeResponseBreadcrumbs';
import VendorResponderInfo from './VendorResponderInfo';
import ResponseDetailsForRetailer from './ResponseDetails/ResponseDetailsForRetailer';
import './ChallengeResponse.scss';

const ChallengeResponsePage = ({
  error,
  challengeResponse,
  match,
  vendorCompany,
}) => {
  const { state: { challenge } } = useContext(ChallengeContext);

  return (
    <PageContainer
      error={error}
      renderChildren={() => (
        <>
          <ChallengeResponseBreadcrumbs
            className="challenge-response__breadcrumbs"
            challenge={challenge}
            companyName={vendorCompany.name}
            companySlug={vendorCompany.slug}
          />
          {challengeResponse.status === challengeResponseStatus.REJECTED && (
            <Typography variant="body1">This vendor rejected your RFI</Typography>
          )}

          {(
            challengeResponse.status === challengeResponseStatus.DRAFT
            || challengeResponse.status === challengeResponseStatus.ACCEPTED
          ) && (
            <Typography variant="body1">Vendor haven&amp;t submitted response on this RFI yet</Typography>
          )}

          {(
            challengeResponse.status === challengeResponseStatus.REMOVED_FROM_SHORTLIST
          ) && (
            <Typography variant="body1">
              This vendor was removed from RFI shortlist by Validify Admin.
            </Typography>
          )}

          {(
            challengeResponse.status === challengeResponseStatus.SUBMITTED
            || challengeResponse.status === challengeResponseStatus.UNDER_REVISION
          ) && (
            <div className="challenge-response">
              <VendorResponderInfo
                className="challenge-response__vendor-responder-info"
                slug={match.params.companySlug}
                vendorCompany={vendorCompany}
              />

              <ResponseDetailsForRetailer
                title={`Response from ${vendorCompany.name}`}
                challengeResponse={challengeResponse}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

ChallengeResponsePage.propTypes = {
  error: PropTypes.string,
  match: getMatchType({
    challengeId: PropTypes.string.isRequired,
    companySlug: PropTypes.string.isRequired,
  }),
  vendorCompany: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    logo: PropTypes.string,
    slug: PropTypes.string.isRequired,
  }),
  challengeResponse: ChallengeResponseType.isRequired,
};

ChallengeResponsePage.defaultProps = {
  error: null,
};

export default ChallengeResponsePage;
