import PropTypes from 'prop-types';
import clsx from 'clsx';

const SaveIcon = ({ className }) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx('icon', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7V2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0H2C1.45 0 1 0.45 1 1C1 1.55 1.45 2 2 2H3V7C3 8.66 1.66 10 0 10V12H5.97V19L6.97 20L7.97 19V12H14V10C12.34 10 11 8.66 11 7Z"
      fill="#D1D700"
    />
  </svg>
);

SaveIcon.propTypes = {
  className: PropTypes.string,
};

SaveIcon.defaultProps = {
  className: null,
};

export default SaveIcon;
