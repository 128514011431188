import routes from 'constants/routes';
import { getRedirectAuthenticatedFromPublicVendorPage, getRedirectUnauthorizedUser } from './getRedirectPath';
import {
  isUnauthenticated,
  isAuthenticated,
  isRetailer,
  isVendor,
  isAdmin,
  isUserCompanyApproved,
} from './checkUserIdentity';

const routeGuard = (checker, redirectUrl) => (user, location, params) => {
  const canAccess = checker(user);

  if (canAccess) {
    return null;
  }

  if (typeof redirectUrl === 'string') {
    return redirectUrl;
  }

  if (typeof redirectUrl === 'function') {
    return redirectUrl(user, location, params);
  }

  return routes.MAIN;
};

export const unauthorizedRouteGuard = routeGuard(isUnauthenticated);

export const authorizedRouteGuard = routeGuard(isAuthenticated, (_, location) => {
  if (location.pathname === routes.MAIN) {
    return routes.LOGIN;
  }

  return getRedirectUnauthorizedUser(location);
});

export const adminRouteGuard = routeGuard(isAdmin);

export const retailerRouteGuard = routeGuard(isRetailer);

export const vendorRouteGuard = routeGuard(isVendor);

export const approvedCompanyGuard = routeGuard(isUserCompanyApproved);

export const publicVendorProfileGuard = routeGuard(
  isUnauthenticated,
  (user, _, { slug }) => (
    getRedirectAuthenticatedFromPublicVendorPage(user, slug)
  ),
);

export const publicChallengeSynopsisGuard = routeGuard((user) => (
  isUnauthenticated(user) || isVendor(user)
));

export const eventRouteGuard = routeGuard((user) => (
  isRetailer(user) || (isVendor(user) && isUserCompanyApproved(user))
));
