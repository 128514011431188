import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import RetailerChallengeDashboard from 'components/RetailerChallenges/AllChallengesDashboard';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import companyAPI from 'api/company';
import withRouteGuards from 'containers/withRouteGuards';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const RetailerChallengeDashboardPage = () => {
  const companyId = useSelector((state) => state.user.company.id);

  const getInitialProps = useCallback(async () => {
    const [{ data: challenges }, { data: events }] = await Promise.all([
      challengeAPI.getRetailerChallenges(companyId),
      companyAPI.getRetailerEventsForChallengeDashboard(companyId),
    ]);

    return { challenges, events };
  }, [companyId]);

  return (
    <FetchContainer getInitialProps={getInitialProps} component={RetailerChallengeDashboard} />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGES_DASHBOARD)(RetailerChallengeDashboardPage)),
);
