import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import withPageTitle from 'containers/withPageTitle';
import VendorEvent from 'components/Events/VendorEvent';
import eventAPI from 'api/event';
import eventVendorUserAPI from 'api/eventVendorUser';
import eventHostMeetingPriorityListAPI from 'api/eventHostMeetingPriorityList';
import { authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import pageTitles from 'constants/pageTitles';

const VendorEventPage = (props) => {
  const { slug } = useParams();
  const userId = useSelector((state) => state.user.authUser.id);

  const normalizeAttendeesData = (usersWithPriorities) => (
    usersWithPriorities.map((data) => {
      const { eventTopicPriorityLists: [list], company, ...user } = data;

      if (!list) {
        return {
          company,
          user,
        };
      }

      const { topics, notes, ...priorityList } = list;

      return {
        company,
        user,
        topics,
        notes,
        priorityList,
      };
    })
  );

  const getInitialProps = useCallback(async () => {
    const { data: event } = await eventAPI.getEventWithParticipationRequest(slug, userId);

    const participationRequest = event.participationRequests.find(({ userId: id }) => (
      id === userId
    ));

    const userInvitation = await eventVendorUserAPI.getUserInvitation(userId, event.id);

    if (!userInvitation) {
      return {
        userInvitation,
        event,
        participationRequest,
      };
    }

    const [hostPriorityList, retailUsers] = await Promise.all([
      eventHostMeetingPriorityListAPI.getMeetingPrioritiesOfVendor(event.id, userId),
      eventAPI.getRetailAttendees(event.id),
    ]);

    const prioritizedRetailers = hostPriorityList && hostPriorityList.retailUsers
      ? normalizeAttendeesData(hostPriorityList.retailUsers.map(({ user }) => user))
      : [];

    const retailerAttendees = (
      userInvitation
      && userInvitation.canSavePriorities
      && prioritizedRetailers.length
        ? prioritizedRetailers
        : normalizeAttendeesData(retailUsers)
    );

    return {
      event,
      userInvitation,
      retailerAttendees,
      participationRequest,
    };
  }, [userId, slug]);

  return (
    <FetchContainer
      component={VendorEvent}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.EVENT_PAGE)(VendorEventPage)),
);
