import { css } from '@emotion/react';

export const list = css`
  padding-left: 18px;
  list-style: initial;
  list-style-type: decimal;
`;

export const entryWrap = (theme) => (
  css`
    display: list-item;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const entry = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: currentColor;
`;
