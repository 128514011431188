import PropTypes from 'prop-types';

const SignInIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7545 13.9992C18.9966 13.9992 20.0034 15.0061 20.0034 16.2481V16.8235C20.0034 17.7178 19.6838 18.5826 19.1023 19.262C17.5329 21.0955 15.1457 22.0004 12.0004 22.0004C8.8545 22.0004 6.46849 21.0952 4.90219 19.2609C4.32242 18.582 4.00391 17.7185 4.00391 16.8258V16.2481C4.00391 15.0061 5.01076 13.9992 6.25278 13.9992H17.7545ZM17.7545 15.4992H6.25278C5.83919 15.4992 5.50391 15.8345 5.50391 16.2481V16.8258C5.50391 17.3614 5.69502 17.8795 6.04287 18.2869C7.29618 19.7546 9.26206 20.5004 12.0004 20.5004C14.7387 20.5004 16.7063 19.7545 17.9627 18.2866C18.3117 17.879 18.5034 17.3601 18.5034 16.8235V16.2481C18.5034 15.8345 18.1681 15.4992 17.7545 15.4992ZM12.0004 2.00391C14.7618 2.00391 17.0004 4.24248 17.0004 7.00391C17.0004 9.76533 14.7618 12.0039 12.0004 12.0039C9.23894 12.0039 7.00036 9.76533 7.00036 7.00391C7.00036 4.24248 9.23894 2.00391 12.0004 2.00391ZM12.0004 3.50391C10.0674 3.50391 8.50036 5.07091 8.50036 7.00391C8.50036 8.9369 10.0674 10.5039 12.0004 10.5039C13.9334 10.5039 15.5004 8.9369 15.5004 7.00391C15.5004 5.07091 13.9334 3.50391 12.0004 3.50391Z"
      fill="currentColor"
    />
  </svg>
);

SignInIcon.propTypes = {
  className: PropTypes.string,
};

SignInIcon.defaultProps = {
  className: null,
};

export default SignInIcon;
