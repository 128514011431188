import { css } from '@emotion/react';

export const card = (theme) => (
  css`
    padding: ${theme.spacing(2)};
    border-radius: 4px;
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const row = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const splittedRow = (theme) => (
  css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.spacing(2)};
  `
);

export const column = css`
  flex: 1;
`;

export const caption = (theme) => (
  css`
    margin-bottom: ${theme.spacing(0.5)};
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const logo = css`
  height: 40px;
`;

export const ratings = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    `
);

export const nonEmptyRatings = (theme) => (
  css`
    padding-top: ${theme.spacing(3)};
    border-top: 1px solid ${theme.primaryPalette.midLightGrey};
  `
);

export const wrap = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `
);

export const ratingIcon = (theme) => (
  css`
    .MuiRating-iconFilled {
      color: ${theme.primaryPalette.lightOrange} !important;
    }
  `
);

export const ratingsContainer = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(1.25)};
  `
);
