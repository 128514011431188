import { css } from '@emotion/react';

const modal = (theme) => (
  css`
    .MuiDialogContent-root {
      ${theme.breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  `
);

export default modal;
