/* eslint-disable no-param-reassign */
import isPast from 'date-fns/isPast';
import get from 'lodash/get';
import set from 'lodash/set';
import PERSONAL_EMAIL_DOMAINS from 'constants/personalEmailDomains';

const EMAIL_DOMAINS_COUNT = 2;

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!(regex.test(email) && email.split('@').length === EMAIL_DOMAINS_COUNT)) {
    return 'Email does not have right format.';
  }

  return null;
};

export const validateYearsFounded = (value) => {
  const regexp = new RegExp('[0-9]{4}');
  const regexBreak = regexp.exec(value);

  if (
    !regexBreak
    || (regexBreak.length && regexBreak.length >= 1 && regexBreak[0] !== value)
    || value > new Date().getFullYear()
  ) {
    return 'Year not valid';
  }

  return undefined;
};

const TWITTER_PATTERN = new RegExp('(http(s)?://(.*.)?twitter.com/)?@?[A-z 0-9 _]+/?');
const INSTAGRAM_PATTERN = new RegExp('(http(s)?://(.*.)?instagram.com/)?@?[A-z 0-9 _]+/?');
const LINKEDIN_PATTERN = new RegExp('(http(s)?://([w]+.)?(www.)?linkedin.com/)?([A-z -.0-9]+/?){1,2}');
const FACEBOOK_PATTERN = new RegExp('(http(s)?://(en-gb.)?(www.)?(facebook|fb).com/)?([A-z -.0-9])+/?');

const validateSocialMedia = (value, socialMediaName, pattern) => {
  const regexBreak = pattern.exec(value);

  if (!!value && regexBreak !== null && regexBreak.length >= 1 && regexBreak[0] !== value) {
    return `${socialMediaName} not valid`;
  }

  return undefined;
};

export const validateTwitterLink = (value) => validateSocialMedia(value, 'Twitter', TWITTER_PATTERN);

export const validateInstagramLink = (value) => validateSocialMedia(value, 'Instagram', INSTAGRAM_PATTERN);

export const validateLinkedInLink = (value) => validateSocialMedia(value, 'Linkedin', LINKEDIN_PATTERN);

export const validateFacebookLink = (value) => validateSocialMedia(value, 'Facebook', FACEBOOK_PATTERN);

const MIN_PASSWORD_LENGTH = 7;

export const validatePasswordLength = (password) => (
  password && password.length >= MIN_PASSWORD_LENGTH ? undefined : 'Password needs at least 7 characters.'
);

// TODO: clean set password page and reuse form validator from ChangePasswordForm.
export const validatePasswords = (passwords) => {
  if (!passwords || !(passwords.password.length >= 7)) {
    return 'Password needs at least 7 characters.';
  }

  if (!passwords || !passwords.password || !passwords.confirmPassword) {
    return 'Password and conform password fields are required.';
  }

  if (!passwords || passwords.password !== passwords.confirmPassword) {
    return 'Password and conform password must match.';
  }

  return null;
};

export const checkPassword = (errors, values, name, name2) => {
  if (!(values[name] && values[name].length >= 7)) {
    errors[name] = 'Password needs at least 7 characters.';
  }

  if (!(values[name] && values[name2] && values[name] === values[name2])) {
    errors[name] = 'Passwords do not match';
    errors[name2] = 'Passwords do not match';
  }

  return errors;
};

const MAX_PHONE_DIGITS = 20;

export const validatePhoneLength = (value) => {
  if (!value) {
    return undefined;
  }

  return value.length > MAX_PHONE_DIGITS ? `Max ${MAX_PHONE_DIGITS} digits allowed` : undefined;
};

const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{7,}$/;

export const validatePassword = (value) => {
  if (!value.match(PASSWORD_REGEXP)) {
    return 'Password must contain one uppercase letter and one number';
  }

  return undefined;
};

const YOUTUBE_REGEXP = /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
const VIMEO_REGEXP = /^(https?:\/\/)?(vimeo\.com)\/.+$/;

export const validateVideoLink = (value) => {
  if (value.match(YOUTUBE_REGEXP) || value.match(VIMEO_REGEXP)) {
    return undefined;
  }

  return 'Invalid link format';
};

export const validateIsBoolean = (value) => (typeof value === 'boolean' ? undefined : 'Required');

export const required = (value) => (value && value.trim() !== '' ? undefined : 'Cannot be empty');

export const validateIsChecked = (value) => (value ? undefined : 'Required');

export const maxLength = (max) => (value) => (value && value.split(' ').length - 1 > max ? `Must be ${max} words or less` : undefined);

export const maxLength10 = maxLength(10);

export const maxLength15 = maxLength(15);

export const maxLength20 = maxLength(20);

export const maxLength40 = maxLength(40);

export const maxLength100 = maxLength(100);

export const maxLength150 = maxLength(150);

export const maxLength200 = maxLength(200);

export const maxLength1000 = maxLength(1000);

export const validateUrl = (value) => {
  const regexp = new RegExp(
    '^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  if (!regexp.test(value)) {
    return 'Website does not have right format.';
  }

  return '';
};

export const validateFieldArrayLength = (minArrLength, maxArrLength) => (fieldArray) => {
  if (
    (!fieldArray && minArrLength > 0)
    || (minArrLength && !!fieldArray && (fieldArray.length < minArrLength))
  ) {
    return `Please add at least ${minArrLength} ${minArrLength === 1 ? 'entry' : 'entries'}`;
  }

  if (maxArrLength && fieldArray && fieldArray.length > maxArrLength) {
    return `You cannot submit more than ${maxArrLength} items`;
  }

  return undefined;
};

export const validateTagsArrayLength = (minArrLength, maxArrLength) => (fieldArray) => {
  if (!fieldArray || fieldArray.length < minArrLength || fieldArray.length > maxArrLength) {
    return `You need to select between ${minArrLength} and ${maxArrLength} tags`;
  }

  return undefined;
};

export const validateIsCorporateEmailDomain = (value) => {
  if (value) {
    const [, userDomainName] = value.split('@');

    return PERSONAL_EMAIL_DOMAINS.includes(userDomainName)
      ? 'This looks like a personal email account. Please only use company address.'
      : undefined;
  }

  return undefined;
};

export const validateIsTodayOrAfter = (value) => {
  if (!value) {
    return undefined;
  }

  const date = new Date(value);

  return isPast(date) ? 'Cannot use a date in the past' : undefined;
};

/**
 * Validates that all fields are empty or filled. Fails when there is at least one filled field and
 * other fields are empty.
 */
export const validateRelatedFields = (values, fieldPaths) => {
  if (!values) {
    return undefined;
  }

  const areAllFieldsEmpty = fieldPaths.every((path) => !get(values, path));

  if (areAllFieldsEmpty) {
    return undefined;
  }

  const areFieldsFilled = fieldPaths.every((path) => !!get(values, path));

  if (areFieldsFilled) {
    return undefined;
  }

  return fieldPaths.reduce((errors, path) => {
    const value = get(values, path);

    if (!value) {
      const error = required(value);
      set(errors, path, error);
    }

    return errors;
  }, {});
};
