import APIClient from './client';

class EventVendorUserAPI extends APIClient {
  constructor() {
    super('/event-vendor-users');
  }

  getUserInvitation = async (userId, eventId) => {
    const { data: [invitation] } = await this.find({
      where: {
        userId,
        eventId,
      },
    });

    return invitation;
  };
}

const eventVendorUserAPI = new EventVendorUserAPI();

export default eventVendorUserAPI;
