import { css } from '@emotion/react';

export const wrap = css`
  width: 100%;
  max-width: 500px;
`;

export const changeStatusForm = (theme) => (
  css`
    margin-top: ${theme.spacing(2)};
  `
);

export default wrap;
