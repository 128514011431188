import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import FieldInputType from 'types/FieldInput';

const CustomCheckbox = ({
  className,
  label,
  input,
  ...rest
}) => (
  <FormControlLabel
    className={className}
    control={<Checkbox {...input} {...rest} />}
    label={<Typography variant="body2">{label}</Typography>}
  />
);

CustomCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  input: FieldInputType.isRequired,
};

CustomCheckbox.defaultProps = {
  className: null,
};

export default CustomCheckbox;
