import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { PlayCircleOutline } from '@mui/icons-material';
import insertHost from 'utils/picture';
import getVendorAverageRating from 'utils/getVendorAverageRating';
import { groupQuestionsByRequirements } from 'utils/prepareChallengeYesNoQuestions';
import { ChallengeYesNoQuestionType } from 'types/Challenge';
import ChallengeResponsesTableContainer from '../ChallengeResponsesTableContainer';
import YesNoAnswerRow from '../YesNoAnswerRow';
import { ChallengeResponseType } from '../types';
import './ChallengeResponsesTable.scss';

const ChallengeResponsesTable = ({
  checkCanViewResponse,
  onOpenResponse,
  onOpenVideo,
  challengeResponses,
  renderResponseStatus,
  questions,
}) => {
  const [mustHaveQuestions, niceToHaveQuestions] = groupQuestionsByRequirements(questions);

  const collectVendorAnswersToQuestion = (id) => (
    challengeResponses.map((response) => {
      const canViewResponse = checkCanViewResponse(response);

      if (!canViewResponse || !response.questions) {
        return null;
      }

      const questionEntry = !!response.questions
        && response.questions.find(({ questionId }) => questionId === id);

      if (!questionEntry) {
        return false;
      }

      return questionEntry.value;
    })
  );

  const matchVendorAnswersWithQuestion = (questionList) => questionList
    .map(({ requirement, id }) => ({
      title: requirement.description,
      values: collectVendorAnswersToQuestion(id),
    }));

  const mustHaveAnswers = matchVendorAnswersWithQuestion(mustHaveQuestions);
  const niceToHaveAnswers = matchVendorAnswersWithQuestion(niceToHaveQuestions);

  return (
    <ChallengeResponsesTableContainer>
      <table className="challenge-responses-table">
        <thead>
          <tr>
            <th />

            {challengeResponses.map((response) => {
              const averageRating = getVendorAverageRating(response.company.ratingsReviews);

              return (
                <th key={response.id} className="challenge-responder-company-cell">
                  {response.company.logo ? (
                    <img
                      className="challenge-responder-company-cell__logo"
                      src={insertHost(response.company.logo)}
                      alt=""
                    />
                  ) : (
                    <Typography variant="body1">{response.company.name}</Typography>
                  )}

                  {!!averageRating && (
                    <div className="challenge-responder-company-cell__rating">
                      <Rating readOnly value={averageRating} />
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>
              <Typography variant="body1">View RFI response</Typography>
            </th>
            {challengeResponses.map((response) => {
              const canViewResponse = checkCanViewResponse(response);

              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <td
                  key={response.id}
                  className={clsx('vendor-response-link-cell', canViewResponse && 'clickable')}
                  onClick={() => {
                    if (canViewResponse) {
                      onOpenResponse(response.company.slug);
                    }
                  }}
                >
                  {renderResponseStatus(response)}
                </td>
              );
            })}
          </tr>

          <tr>
            <th>
              <Typography variant="body1">Product demonstration video</Typography>
            </th>
            {challengeResponses.map((response) => (
              checkCanViewResponse(response) ? (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <td
                  key={response.id}
                  className="vendor-demo-video-cell"
                  onClick={() => onOpenVideo(response.demonstrationVideo)}
                >
                  {<PlayCircleOutline className="vendor-demo-video-cell__icon" />}
                </td>
              ) : (
                <td key={response.id} />
              )
            ))}
          </tr>

          {!!mustHaveAnswers.length && (
            <>
              <tr className="must-have-questions-title">
                <th className="must-have-questions-title-cell">Must have</th>
                {challengeResponses.map(({ id }, _) => <td key={id} />)}
              </tr>
              {mustHaveAnswers.map((answer, i) => (
                <YesNoAnswerRow {...answer} key={i} questionIndex={i} isHighlighted />
              ))}
            </>
          )}
          {niceToHaveAnswers.map((answer, i) => (
            <YesNoAnswerRow
              key={i}
              questionIndex={mustHaveAnswers.length + i}
              isHighlighted={false}
              {...answer}
            />
          ))}
        </tbody>
      </table>
    </ChallengeResponsesTableContainer>
  );
};

ChallengeResponsesTable.propTypes = {
  checkCanViewResponse: PropTypes.func.isRequired,
  onOpenVideo: PropTypes.func.isRequired,
  onOpenResponse: PropTypes.func.isRequired,
  renderResponseStatus: PropTypes.func.isRequired,
  challengeResponses: PropTypes.arrayOf(ChallengeResponseType.isRequired).isRequired,
  questions: PropTypes.arrayOf(ChallengeYesNoQuestionType.isRequired).isRequired,
};

export default ChallengeResponsesTable;
