import { css } from '@emotion/react';

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);
