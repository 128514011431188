/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import GeneralModal from 'components/UI/GeneralModal';
import ConfirmModal from 'components/Shared/ConfirmModal';
import challengeParticipantAPI from 'api/challengeParticipant';
import userAPI from 'api/user';
import getErrorMessage from 'utils/getErrorMessage';
import AddParticipant from './AddParticipant';
import participantStyles from './styles';

const ChallengeResponseParticipants = ({ className }) => {
  const [isOpen, setModal] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [selectedForDeleteParticipant, selectParticipantForDelete] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const companyId = useSelector((state) => state.user.company.id);
  const userData = useSelector((state) => ({
    email: state.user.authUser.email,
    id: state.user.authUser.id,
  }));

  const { challengeId } = useParams();

  useEffect(() => {
    challengeParticipantAPI.loadResponseParticipants(challengeId, companyId)
      .then(({ data }) => data)
      .then((users) => {
        setParticipants(users);

        const isUserIncluded = users.some(({ userId }) => userId === userData.id);

        if (!isUserIncluded) {
          handleAddParticipant({ email: userData.email });
        }
      })
      .catch(() => enqueueSnackbar('Failed to load contacts', { variant: 'error' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveParticipant = () => {
    const { id } = selectedForDeleteParticipant;

    challengeParticipantAPI.deleteById(id)
      .then(() => {
        setParticipants((prevParticipants) => prevParticipants
          .filter((participant) => participant.id !== id));
        enqueueSnackbar('Contact was removed', { variant: 'success' });
        selectParticipantForDelete(null);
      })
      .catch(() => enqueueSnackbar('Failed to remove contact', { variant: 'error' }));
  };

  const handleAddParticipant = async ({ email }) => {
    try {
      const { data } = await challengeParticipantAPI.create({ email, challengeId, companyId });

      const participant = await handleLoadParticipantUserInfo(data);

      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleLoadParticipantUserInfo = async (participant) => {
    const isUserRegistered = !!participant.userId;

    if (!isUserRegistered) {
      return participant;
    }

    const { data: user } = await userAPI.loadReviewerUserInfo(participant.userId);

    return { ...participant, user };
  };

  const getChallengeResponseParticipantName = (participant) => (
    participant.userId
      ? `${participant.user.firstName} ${participant.user.lastName}`
      : `${participant.email}`
  );

  return (
    <>
      <Button className={className} onClick={() => setModal(true)} type="button">
        Response team
      </Button>

      <GeneralModal title="RFI response team" isOpen={isOpen} onClose={() => setModal(false)}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Manage the contacts in your company who will receive notifications related to this RFI
        </Typography>
        <div css={participantStyles}>
          {participants.map((participant) => (
            <Chip
              key={participant.id}
              color="primary"
              onDelete={() => selectParticipantForDelete(participant)}
              label={getChallengeResponseParticipantName(participant)}
            />
          ))}
        </div>

        <AddParticipant participants={participants} onSubmit={handleAddParticipant} />
      </GeneralModal>

      <ConfirmModal
        isOpen={!!selectedForDeleteParticipant}
        onClose={() => selectParticipantForDelete(null)}
        onConfirm={handleRemoveParticipant}
        title="Removal of RFI participant"
        description="Are you sure you want to remove this user?"
      />
    </>
  );
};

ChallengeResponseParticipants.propTypes = {
  className: PropTypes.string,
};

ChallengeResponseParticipants.defaultProps = {
  className: null,
};

export default ChallengeResponseParticipants;
