import { css } from '@emotion/react';

export const main = (theme) => (
  css`
    max-width: 600px;
    margin: 0 auto;

    ${theme.breakpoints.up('sm')} {
      padding-top: ${theme.spacing(4)};
    }
  `
);

export const container = (theme) => (
  css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `
);

export const chevron = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      font-size: 24px;
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  `
);

export const offers = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      margin-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('sm')} {
      margin-bottom: ${theme.spacing(5)};
    }
  `
);

export const box = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    min-height: 245px;
    padding: ${theme.spacing(3)};
    text-align: center;
    border-radius: 16px;
    box-shadow: ${theme.boxShadows.large};

    ${theme.breakpoints.up('sm')} {
      flex: 1;

      &:not(:last-child) {
        margin-right: ${theme.spacing(2)};
      }
    }

    ${theme.breakpoints.down('sm')} {
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(4)};
      }
    }
  `
);

export const sum = (theme) => (
  css`
    margin: 0 ${theme.spacing(1)};
    font-size: 66px;
    font-family: ${theme.fontVariants.bold};
    color: ${theme.palette.text.primary};
    line-height: 1.4;
  `
);

export const billingPeriod = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3.5)};
  `
);

export const discount = (theme) => (
  css`
    margin: -${theme.spacing(1)} 0 auto auto;
  `
);
