import { css } from '@emotion/react';

const adornment = (theme) => (
  css`
    color: ${theme.primaryPalette.primaryYellow};
    opacity: 0.5;
  `
);

export default adornment;
