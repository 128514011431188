import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import mainTheme from 'theme/mainTheme';
import generatePalette from 'theme/generatePalette';

const EventPageTheme = ({ children, primaryColor }) => {
  const eventPagePalette = {
    palette: {
      primary: generatePalette(primaryColor),
    },
  };

  const theme = createTheme(mainTheme, eventPagePalette);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

EventPageTheme.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default EventPageTheme;
