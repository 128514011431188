/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import CheckboxField from 'components/Form/Checkbox';
import RequirementDescriptionField from '../RequirementDescriptionField';
import * as classes from './styles';

const RequirementFields = ({ fields, addedQuestionTexts, onOpenModal }) => (
  <div css={classes.requirementsList}>
    {fields.length
      ? (
        <fieldset css={classes.requirementsList} name="yesNoQuestions">
          {fields.map((field, i) => (
            <div key={field} css={classes.entry}>
              <div css={classes.fields}>
                <Field
                  id={`requirements-autocomplete-${i}`}
                  css={classes.input}
                  component={RequirementDescriptionField}
                  name={`${field}.description`}
                  addedQuestionTexts={addedQuestionTexts}
                  onOpenModal={() => onOpenModal(i)}
                  fullWidth
                />
                <Field
                  css={classes.checkbox}
                  component={CheckboxField}
                  name={`${field}.mustHave`}
                  label="Must have"
                  type="checkbox"
                />
              </div>

              <IconButton
                aria-label="Remove requirement"
                css={classes.remove}
                onClick={() => fields.remove(i)}
                size="medium"
              >
                <Close fontSize="medium" />
              </IconButton>
            </div>
          ))}
        </fieldset>
      ) : <Typography variant="body2">No questions added</Typography>
    }
  </div>
);

RequirementFields.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  addedQuestionTexts: PropTypes.arrayOf(PropTypes.string),
  onOpenModal: PropTypes.func.isRequired,
};

RequirementFields.defaultProps = {
  addedQuestionTexts: [],
};

export default RequirementFields;
