const retailerDisplayedChallengeStatuses = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  REQUESTED_REVISION: 'Requested revision',
  APPROVED_REVISION: 'Approved revision',
  IN_REVISION: 'In revision',
  PAUSED: 'On hold',
  AWAITING_UPDATE: 'Awaiting update',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
};

export default retailerDisplayedChallengeStatuses;
