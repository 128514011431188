/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';
import set from 'lodash/set';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import getErrorMessage from 'utils/getErrorMessage';
import invitedUserAPI from 'api/invitedUser';
import companyAPI from 'api/company';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import registrationReason from 'constants/registrationReason';
import UserDetailsStep from '../UserDetailsStep';
import CompanyDetailsStep from '../CompanyDetailsStep';
import AcceptTermsConditions from '../AcceptTermsConditions';
import validate from './validate';
import * as classes from './styles';

const RegisterForm = ({ className, initialValues: formInitialValues, onSubmitUser }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isCompanyDetailsShown, setShowCompanyDetails] = useState(false);

  const getCompanyWithUserEmailDomain = (email) => {
    const [, emailDomain] = email.split('@');

    return companyAPI.findCompanyWithTakenEmailDomain(emailDomain);
  };

  const handleEmailBlur = (form) => async (_, emailValue) => {
    try {
      if (isCompanyDetailsShown) {
        const company = await getCompanyWithUserEmailDomain(emailValue.toLowerCase());

        if (company) {
          form.change('companyId', company.id);
          setShowCompanyDetails(false);
        }
      }
    } catch (__) {
      enqueueSnackbar('Failed to check user email', { variant: 'error' });
    }
  };

  const handleSubmitUserDetails = async (
    values,
    { isPreRegisteredForEvent, isInvitedToSharedChallenge },
  ) => {
    try {
      const { email } = values;

      const lowerCasedEmail = email.toLowerCase();

      const [company, userInvitation] = await Promise.all([
        getCompanyWithUserEmailDomain(lowerCasedEmail),
        invitedUserAPI.findInvitedUserForRegistrationByEmail(lowerCasedEmail),
      ]);

      if (userInvitation) {
        onSubmitUser({
          ...values,
          reason: registrationReason.INVITED_TO_COMPANY,
          companyId: userInvitation.companyId,
        });
      } else if (
        company
        && (
          (!isPreRegisteredForEvent && !isInvitedToSharedChallenge)
          || company.type === companyStatus.RETAILER)
      ) {
        onSubmitUser({
          ...values,
          reason: registrationReason.JOINING_TO_COMPANY,
          companyId: company.id,
        });
      } else if (company && company.type === companyStatus.VENDOR && isInvitedToSharedChallenge) {
        onSubmitUser({
          ...values,
          companyId: company.id,
          reason: registrationReason.INVITED_TO_SHARED_CHALLENGE,
        });
      } else {
        setShowCompanyDetails(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleSanitizeValues = (values, isPreRegisteredForEvent) => {
    set(values, 'email', values.email.toLowerCase());

    if (
      isCompanyDetailsShown
      && (
        !isPreRegisteredForEvent
        || (isPreRegisteredForEvent && !values.companyId)
      )
    ) {
      return omit(values, ['companyId', 'agree']);
    }

    return omit(values, ['companyName', 'companyWebsite', 'status', 'agree']);
  };

  const handleSubmitStep = async (values, form) => {
    const formState = form.getState();
    const isPreRegisteredForEvent = (
      formState.initialValues
      && formInitialValues.reason === registrationReason.PRE_REGISTERED_FOR_EVENT
    );

    const isInvitedToSharedChallenge = (
      formState.initialValues
      && formInitialValues.reason === registrationReason.INVITED_TO_SHARED_CHALLENGE
    );

    const sanitizedValues = handleSanitizeValues(values, isPreRegisteredForEvent);

    const errors = await validate(sanitizedValues, isPreRegisteredForEvent);

    if (errors) {
      return errors;
    }

    if (isCompanyDetailsShown) {
      onSubmitUser(sanitizedValues);
    } else {
      handleSubmitUserDetails(
        sanitizedValues,
        { isPreRegisteredForEvent, isInvitedToSharedChallenge },
      );
    }

    return null;
  };

  return (
    <Form
      onSubmit={handleSubmitStep}
      initialValues={formInitialValues}
      render={({
        errors,
        form,
        handleSubmit,
        submitFailed,
        initialValues,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={className}>
            <UserDetailsStep initialValues={initialValues} onEmailBlur={handleEmailBlur(form)} />
            <Collapse in={isCompanyDetailsShown}>
              {isCompanyDetailsShown && (
                <CompanyDetailsStep initialValues={initialValues} />
              )}
            </Collapse>
            <AcceptTermsConditions
              css={classes.termsAndConditionsField}
              submitFailed={submitFailed}
              error={errors && errors.agree}
            />
            <div css={classes.formFooter}>
              <Button type="submit">Submit</Button>
              <Button variant="outlined" to={routes.LOGIN} component={Link}>Sign in</Button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

RegisterForm.propTypes = {
  initialValues: PropTypes.shape({
    reason: PropTypes.string.isRequired,
    email: PropTypes.string,
  }),
  className: PropTypes.string,
  onSubmitUser: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  initialValues: null,
  className: null,
};

export default RegisterForm;
