/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { isPast } from 'date-fns';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { isAcceptingResponses, isExpired } from 'utils/challengeCheckers';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import challengeStatus from 'constants/challengeStatus';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import CancelChallenge from './CancelChallenge';
import PauseChallenge from './PauseChallenge';
import RestartChallenge from './RestartChallenge';
import CompleteChallenge from './CompleteChallenge';
import * as classes from './styles';

const values = {
  PAUSE: 'pause',
  RESTART: 'restart',
  CANCEL: 'cancel',
  COMPLETE: 'complete',
};

const options = {
  [values.PAUSE]: 'On hold',
  [values.RESTART]: 'Restarted',
  [values.CANCEL]: 'Cancelled',
  [values.COMPLETE]: 'Completed',
};

const SELECT_ID = 'manage-challenge-status';

const ManageChallengeStatus = ({ className }) => {
  const {
    state: {
      challenge,
      challengeResponses,
    },
    cancelChallenge,
    pauseChallenge,
    restartChallenge,
    completeChallenge,
  } = useContext(ChallengeContext);

  const { status, expectedRestart } = challenge;

  const [value, onChange] = useState('');

  const isEditDisabled = (
    status === challengeStatus.CANCELLED
    || status === challengeStatus.COMPLETED
  );

  const isDeadlinePassed = isExpired(challenge);
  const canReceiveResponses = isAcceptingResponses(challenge);

  const getRestartFormInitialValues = () => {
    const expectedRestartDate = new Date(expectedRestart);

    return isPast(expectedRestartDate) ? null : { deadline: expectedRestart };
  };

  const responders = challengeResponses
    .reduce((acc, { status: responseStatus, company }) => (
      responseStatus === challengeResponseStatus.SUBMITTED
        ? [...acc, company]
        : acc
    ), []);

  const handleUpdateStatus = (handler) => (body) => handler(body, () => onChange(''));

  const renderMenu = () => {
    switch (value) {
      case values.CANCEL:
        return <CancelChallenge onSubmit={handleUpdateStatus(cancelChallenge)} />;

      case values.PAUSE:
        return <PauseChallenge onSubmit={handleUpdateStatus(pauseChallenge)} />;

      case values.RESTART:
        return (
          <RestartChallenge
            initialValues={getRestartFormInitialValues()}
            onSubmit={handleUpdateStatus(restartChallenge)}
          />
        );

      case values.COMPLETE:
        return (
          <CompleteChallenge
            responders={responders}
            onSubmit={handleUpdateStatus(completeChallenge)}
            initialValues={{ winnerId: null }}
          />
        );

      default:
        return null;
    }
  };

  const getOptions = () => {
    if (canReceiveResponses) {
      return pick(options, [values.PAUSE, values.CANCEL, values.COMPLETE]);
    }

    if (status === challengeStatus.PAUSED) {
      return pick(options, [values.RESTART, values.CANCEL]);
    }

    return [];
  };

  const selectOptions = Object.entries(getOptions());

  const getFormattedChallengeStatus = () => {
    if (isDeadlinePassed && canReceiveResponses) {
      return 'Ended (Response deadline expired)';
    }

    switch (challenge.status) {
      case challengeStatus.SUBMITTED:
      case challengeStatus.SUBMITTED_REVISION:
        return 'Active';
      case challengeStatus.REQUESTED_REVISION:
        return 'Requested revision';
      case challengeStatus.APPROVED_REVISION:
        return 'Approved revision';
      case challengeStatus.CANCELLED:
        return 'Cancelled';
      case challengeStatus.PAUSED:
        return 'On hold';
      case challengeStatus.COMPLETED:
        return 'Ended';
      default:
        return 'Unknown';
    }
  };

  const currentStatus = getFormattedChallengeStatus();
  const menu = renderMenu();

  return (
    <div css={classes.wrap} className={className}>
      <FormControl fullWidth disabled={isEditDisabled}>
        {!isEditDisabled && (
          <InputLabel htmlFor={SELECT_ID}>Status</InputLabel>
        )}
        <NativeSelect inputProps={{ id: SELECT_ID }} onChange={(e) => onChange(e.target.value)}>
          <option value="">{currentStatus}</option>
          {selectOptions.map(([optionValue, optionName]) => (
            <option key={optionValue} value={optionValue}>
              {optionName}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {!!menu && <div css={classes.changeStatusForm}>{menu}</div>}
    </div>
  );
};

ManageChallengeStatus.propTypes = {
  className: PropTypes.string,
};

ManageChallengeStatus.defaultProps = {
  className: null,
};

export default ManageChallengeStatus;
