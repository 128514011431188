import vendorInfoConstants from '../constants/vendorInfo';

export const getCompany = (company) => ({
  type: vendorInfoConstants.GET_COMPANY,
  company,
});

export const clearCompany = () => ({
  type: vendorInfoConstants.CLEAR_COMPANY,
});

export const addReviewToVendor = (review) => ({ type: vendorInfoConstants.ADD_REVIEW, review });

export const upsertReviewVote = (vote) => ({ type: vendorInfoConstants.UPSERT_REVIEW_VOTE, vote });

export const getCompanyError = () => ({ type: vendorInfoConstants.GET_COMPANY_ERROR });
