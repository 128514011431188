/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Add from '@mui/icons-material/Add';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Remove from '@mui/icons-material/Remove';
import * as classes from './styles';

async function renderCroppedImage(image, crop, scale) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  // eslint-disable-next-line no-param-reassign
  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  // eslint-disable-next-line no-param-reassign
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();

  return canvas;
}

function toBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
}

const ASPECT_RATIO = 3 / 1;

const CropImage = ({ image, onSave, onLoad }) => {
  const [percentCrop, setPercentCrop] = useState();
  const [pixelCrop, setPixelCrop] = useState();

  const [scale, setScale] = useState(1);

  const imgRef = useRef();

  const handleLoad = (e) => {
    const [file] = e.target.files;

    if (file) {
      onLoad(file);
    }
  };

  const handleSaveCroppedImage = async () => {
    const canvas = await renderCroppedImage(imgRef.current, pixelCrop, scale);
    const blob = await toBlob(canvas);
    onSave(blob);
  };

  const handleImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        ASPECT_RATIO,
        width,
        height,
      ),
      width,
      height,
    );

    setPercentCrop(crop);
  };

  return (
    <div>
      <Typography css={classes.title} variant="h1">
        Crop your image
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Please select cropped area of the uploaded image
      </Typography>
      <div css={classes.editorContainer}>
        <ReactCrop
          crop={percentCrop}
          onChange={(_, crop) => (crop.height > 1 || crop.width > 1) && setPercentCrop(crop)}
          onComplete={(crop) => setPixelCrop(crop)}
          aspect={ASPECT_RATIO}
          scale={scale}
        >
          <img
            css={classes.getScaledImageClassName(scale)}
            onLoad={handleImageLoad}
            src={image}
            ref={imgRef}
            alt=""
          />
        </ReactCrop>
      </div>

      <Slider
        sx={{ mb: 4 }}
        marks={[
          {
            value: 0,
            label: <Remove css={classes.zoomIcon} fontSize="small" />,
          },
          {
            value: 1,
            label: <Add css={classes.zoomIcon} fontSize="small" />,
          },
        ]}
        value={scale}
        step={0.1}
        max={1}
        min={0}
        onChange={(_, value) => setScale(value)}
      />

      <div css={classes.buttons}>
        <Button sx={{ mb: 1 }} onClick={handleSaveCroppedImage}>Save</Button>
        <FormLabel>
          Change image
          <input css={classes.input} onChange={handleLoad} accept=".svg, .png" type="file" />
        </FormLabel>
      </div>
    </div>
  );
};

CropImage.propTypes = {
  image: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

CropImage.defaultProps = {
  image: null,
};

export default CropImage;
