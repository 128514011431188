import { Field } from 'redux-form';
import TextFieldInput from 'components/UI/TextFieldInput';

const Employees = () => (
  <div>
    <Field
      sx={{ mb: 2 }}
      name="offices[1].address1"
      placeholder="Address"
      label="Address Line 1"
      component={TextFieldInput}
    />
    <Field
      sx={{ mb: 2 }}
      label="Town / City"
      name="offices[1].city"
      placeholder="Town / City"
      component={TextFieldInput}
    />
    <Field
      sx={{ mb: 2 }}
      label="Post Code"
      name="offices[1].post"
      placeholder="Post Code"
      component={TextFieldInput}
    />
    <Field
      sx={{ mb: 2 }}
      label="UK registration number"
      name="offices[1].registrationNumber"
      placeholder="UK registration number"
      component={TextFieldInput}
    />
    <Field
      sx={{ mb: 2 }}
      label="VAT Number"
      name="offices[1].VAT"
      placeholder="e.g. GB660454836"
      component={TextFieldInput}
    />
  </div>
);

export default Employees;
