export const GENERAL_SERVICES = [
  'General Terms',
  'Subscriber Specific Terms',
  'Privacy and Cookie Policy',
  'Validify Code of Conduct',
];

export const CONTENT_RESTRICTIONS = [
  'that is false or misleading;',
  'that infringes any intellectual property rights of, or a duty of confidentiality to any other person;',
  'that advertises products or services the sale or supply of which is prohibited or restricted by applicable law;',
  'that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;',
  'or that is libellous, abusive, threatening, vulgar, obscene, or otherwise objectionable.',
];

export const INTELLECTUAL_PROPERTY_RESTRICTIONS = [
  'download, reproduce, copy, resell or exploit for any commercial purposes, any aspect of the Service, or use automated means to download data from the Service (including without limitation, spiders, robots, crawlers or data mining tools, but excepting standard internet search engines);',
  'modify, create a derivative work of, decompile or disassemble, reverse engineer or otherwise attempt to discover any source code contained in the Website;',
  'or sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the Service.',
];

export const MONTHLY_SUBSCRIPTION_FEES = [
  'prior to the Commencement Date, in respect of the period beginning on the Commencement Date until the end of the following calendar month;',
  'and on the 1st of every month, in respect of each new subscription period thereafter;',
];

export const ANNUAL_SUBSCRIPTION_FEES = [
  'prior to the Commencement Date, in respect of the initial twelve months;',
  'on each anniversary of the Commencement Date, in respect of each new subscription period thereafter.',
];

export const OPT_ANNUAL_SUBSCRIPTION_FEES = [
  'prior to the Commencement Date, in respect of the initial twelve months;',
  'and prior to each anniversary of the Commencement Date in respect of each new subscription period thereafter.',
];

export const ACCEPTANCE_OF_TERMS = [
  'Validify may, without liability to you, disable your password, account and access to all or any part of the Service, and Valdify shall be under no obligation to provide you with access to the Service whilst any fees remain unpaid;',
  'interest shall accrue on a daily basis on such due amounts at an annual rate equal to 4% per annum above Bank of England`s base lending rate from time to time, commencing on the due date and continuing until fully paid, whether before or after judgment.',
];

export const EVENT_OF_DEFAULT = [
  'you fail to comply with the spirit of Validify’s Code of Conduct;',
  'we receive a complaint about you from a consumer brand or other user or you become involved in a dispute with a consumer brand or other user, in circumstances where Validify considers, in its absolute discretion, that your continued participation in the Service or presence on the Website may cause harm to the business or reputation of (i) the Service, (ii) Validify or (iii) Validify’s officers or employees;',
  'you otherwise commit a material breach of the General Terms or these Subscriber Terms (including, without limitation, failure to make any payment of Subscription Fees when due);',
  '(i) you become unable to pay your debts within the meaning of the Insolvency Act 1986, or (ii) a receiver, liquidator, administrator or trustee in bankruptcy is appointed in respect of all or any part of your undertaking or assets, or (iii) you enter into a creditors’ voluntary arrangement or an individual voluntary arrangement, or (iv) any order is made or resolution is passed in connection with the liquidation, administration, bankruptcy or winding up of you or your business.',
  'you are obliged to notify Validify immediately in writing upon becoming aware that an Event of Default has occurred;',
  'if Validify cancels your subscription as a result of an Event of Default, any   Subscription Fees already paid by you in respect of periods following the cancellation date shall be forfeited and shall belong to Validify.',
];
