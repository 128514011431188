import { useContext } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import insertHost from 'utils/picture';
import ProfileCard from 'svg/profileCard';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import ContactVendor from 'components/VendorInfo/ContactVendor';
import ManageProfileButton from 'components/Shared/ManageProfileButton';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import contactFormTrigger from 'constants/contactFormTrigger';
import routes from 'constants/routes';
import './VendorResponderInfo.scss';

const VendorResponderInfo = ({ className, vendorCompany, slug }) => {
  const { state: { challenge } } = useContext(ChallengeContext);
  const history = useHistory();

  const vendorProfilePathname = generatePath(routes.VENDOR_PROFILE_TAB, {
    slug,
    tab: VENDOR_INFO_TABS.BASIC_DETAILS.path,
  });

  return (
    <div className={clsx('vendor-responder-info', className)}>
      <Typography className="vendor-responder-info__title" variant="h1">
        {challenge.name}
      </Typography>
      <div className="vendor-responder-info__top">
        <div className="vendor-responder-info__main">
          {vendorCompany.logo ? (
            <img
              alt=""
              className="small-logo"
              src={insertHost(vendorCompany.logo)}
            />
          ) : (
            <Typography variant="h3">{vendorCompany.name}</Typography>
          )}
        </div>

        <div className="vendor-responder-info__buttons">
          <ContactVendor
            trigger={contactFormTrigger.RFI_MESSAGE}
            companyId={vendorCompany.id}
            contactName={vendorCompany.contactName}
          />
          <ManageProfileButton
            icon={ProfileCard}
            onClick={() => history.push(vendorProfilePathname)}
            title="Company profile"
          />
        </div>
      </div>

      {!!vendorCompany.vendors.shortDescription && (
        <div className="vendor-responder-info__company-description">
          <Typography className="vendor-responder-info__company-label" variant="h3">
            Company overview
          </Typography>
          <Typography variant="body1">{vendorCompany.vendors.shortDescription}</Typography>
        </div>
      )}
    </div>
  );
};

VendorResponderInfo.propTypes = {
  className: PropTypes.string,
  vendorCompany: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    logo: PropTypes.string,
    contactName: PropTypes.string.isRequired,
    vendors: PropTypes.shape({
      shortDescription: PropTypes.string,
    }).isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
};

VendorResponderInfo.defaultProps = {
  className: PropTypes.string,
};

export default VendorResponderInfo;
