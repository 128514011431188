import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Typography } from '@mui/material';
import moneyFormat from 'utils/moneyFormat';
import getCurrencySymbol from 'utils/getCurrencySymbol';
import { FinancialDataType } from 'types/Vendor';
import VendorStats from '../VendorStats';
import './CompanyStats.scss';

const CompanyStats = ({ className, financialData }) => {
  const amount = get(financialData, 'funding.amount', 0);
  const currency = get(financialData, 'funding.currency', null);
  const growth = get(financialData, 'growth', null);

  const isVentureBacked = get(financialData, 'isVentureBacked', false);

  const formattedFunding = moneyFormat(amount);
  const currencySymbol = getCurrencySymbol(currency);

  const fundingStat = isVentureBacked && formattedFunding && currencySymbol
    ? `${currencySymbol}${formattedFunding}`
    : null;

  const employeesStat = get(financialData, 'employees.globally', null);
  const growthStat = growth ? `${growth}%` : null;

  return (
    <div className={className}>
      <Typography variant="h3">
        Key Company Stats
      </Typography>
      <div className="company-stats">
        {fundingStat && (
          <VendorStats className="company-stats__item" title="Total funding">
            <span className="company-stats__value">{fundingStat}</span>
          </VendorStats>
        )}
        {employeesStat && (
          <VendorStats className="company-stats__item" title="Employees">
            <span className="company-stats__value">{employeesStat}</span>
          </VendorStats>
        )}
        {growthStat && (
          <VendorStats className="company-stats__item" title="Growth (12 month)">
            <span className="company-stats__value">{growthStat}</span>
          </VendorStats>
        )}
      </div>
    </div>
  );
};

CompanyStats.propTypes = {
  className: PropTypes.string.isRequired,
  financialData: FinancialDataType,
};

CompanyStats.defaultProps = {
  className: null,
  financialData: null,
};

export default CompanyStats;
