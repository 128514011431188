import { css } from '@emotion/react';

const content = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
    max-width: 600px;
    margin: 0 auto;
  `
);

export default content;
