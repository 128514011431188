const matchFreeFormAnswersWithQuestions = (questions, answers) => {
  if (!(questions && questions.length)) {
    return [];
  }

  if (!(answers && answers.length)) {
    return questions.map(({ id, isRequired, question }) => ({
      questionId: id,
      question,
      isRequired,
      answer: '',
    }));
  }

  return questions.map(({ id, isRequired, question }) => {
    const submittedAnswer = answers.find(({ questionId }) => questionId === id);

    return {
      questionId: id,
      question,
      isRequired,
      answer: submittedAnswer ? submittedAnswer.answer : '',
    };
  });
};

export default matchFreeFormAnswersWithQuestions;
