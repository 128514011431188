import PropTypes from 'prop-types';
import { Description } from '@mui/icons-material';
import clsx from 'clsx';
import DocumentUploadType from 'types/DocumentUpload';
import './UploadedVendorDocument.scss';

const UploadedVendorDocument = ({ className, document: { date, text, title } }) => (
  <div className={clsx('uploaded-document', className)}>
    <Description className="uploaded-document__icon" fontSize="large" />
    <div className="uploaded-document__description">
      <div className="uploaded-document__title">{title}</div>
      {!!date && <div className="uploaded-document__date">{date}</div>}
      {!!text && <div className="uploaded-document__text">{text}</div>}
    </div>
  </div>
);

UploadedVendorDocument.propTypes = {
  className: PropTypes.string,
  document: DocumentUploadType.isRequired,
};

UploadedVendorDocument.defaultProps = {
  className: null,
};

export default UploadedVendorDocument;
