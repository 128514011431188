import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import reviewRequestAPI from 'api/reviewRequest';
import FetchContainer from 'containers/FetchContainer';
import withRouteGuards from 'containers/withRouteGuards';
import withPageTitle from 'containers/withPageTitle';
import RetailerReviews from 'components/RetailerReviews';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import pageTitles from 'constants/pageTitles';

const RetailerReviewsPage = (props) => {
  const { id, email } = useSelector((state) => state.user.authUser);

  const getInitialProps = useCallback(async () => {
    const { data } = await reviewRequestAPI.getPendingReviewRequests(id, email);

    return { requests: data };
  }, [id, email]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={RetailerReviews}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.RATINGS_REVIEWS)(RetailerReviewsPage)),
);
