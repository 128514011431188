/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { endOfDay, isValid } from 'date-fns';
import './DatePicker.scss';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';

const DatePickerComponent = ({
  className,
  input: {
    onBlur,
    onChange,
    value,
    ...inputProps
  },
  meta: {
    touched,
    error,
    submitting,
    submitFailed,
  },
  label,
  fullWidth,
  placeholder,
  sx,
}) => {
  const handleChange = (date) => {
    if (isValid(date)) {
      const formattedDate = endOfDay(date);
      const dateISOString = formattedDate.toISOString();

      onChange(dateISOString);
    } else {
      onChange(null);
    }
  };

  return (
    <FormLabel className={className} css={classes.datepicker} sx={sx}>
      {label}
      <DesktopDatePicker
        inputFormat={DATE_FORMAT}
        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
        onChange={handleChange}
        value={value ? new Date(value) : null}
        disablePast
        // Validation of min date is handled by redux-form.
        // It will provide error message in `meta.error` if date is in past
        minDateMessage=""
        placeholder={placeholder}
        error={error && submitFailed}
        inputProps={inputProps}
        renderInput={(props) => (
          <TextField
            fullWidth={fullWidth}
            css={classes.input}
            {...props}
          />
        )}
      />
      {(touched || submitting) && error && <FormHelperText error>{error}</FormHelperText>}
    </FormLabel>
  );
};

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.object,
  ]),
};

DatePickerComponent.defaultProps = {
  className: null,
  placeholder: null,
  fullWidth: false,
  sx: null,
};

export default DatePickerComponent;
