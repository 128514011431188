/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as classes from './styles';

const SPINNER_SIZE = 24;

const SubmitButton = ({
  disabled,
  isLoading,
  children,
  ...rest
}) => {
  const ref = useRef();
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, []);

  return (
    <Button
      ref={ref}
      css={(isLoading && classes.getButtonStyles(width))}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? <CircularProgress size={SPINNER_SIZE} css={classes.spinner} /> : children}
    </Button>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

SubmitButton.defaultProps = {
  disabled: false,
};

export default SubmitButton;
