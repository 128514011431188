/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import * as classes from './styles';

const MODAL_TITLE_ID = 'general-modal-title';

const GeneralModal = ({
  isOpen,
  onClose,
  children,
  title,
  TransitionProps,
  className,
  maxWidth,
}) => (
  <Dialog
    className={className}
    open={isOpen}
    onClose={onClose}
    aria-labelledby={MODAL_TITLE_ID}
    maxWidth={maxWidth}
    TransitionProps={TransitionProps}
    css={classes.dialog}
  >
    <DialogTitle css={classes.title}>
      <Typography component="span" variant="h3" id={MODAL_TITLE_ID}>
        {title}
      </Typography>
      <button css={classes.button} onClick={onClose}>
        <Close fontSize="small" />
      </button>
    </DialogTitle>
    <DialogContent css={classes.content}>{children}</DialogContent>
  </Dialog>
);

GeneralModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  TransitionProps: PropTypes.object,
  maxWidth: PropTypes.string,
};

GeneralModal.defaultProps = {
  className: null,
  TransitionProps: null,
  maxWidth: 'md',
};

export default GeneralModal;
