import get from 'lodash/get';
import { required } from 'lib/validation';

const MIN_OVERALL_RATING_WITHOUT_DETAILS = 3;
const DETAILS_FIELDS = ['pros', 'cons'];

const validate = (values) => {
  const errors = {};

  const overallRatingValue = get(values, 'overallRating.value', 0);
  const overallRating = Number(overallRatingValue);

  if (
    !values
    || overallRating < MIN_OVERALL_RATING_WITHOUT_DETAILS
    || !values.recommendSolution
  ) {
    DETAILS_FIELDS.forEach((fieldName) => {
      const fieldValue = values[fieldName];
      const error = required(fieldValue);

      if (error) {
        errors[fieldName] = error;
      }
    });
  }

  return errors;
};

export default validate;
