import orderBy from 'lodash/orderBy';
import challengeResponseStatus from 'constants/challengeResponseStatus';

const sortChallengeResponses = (responses) => (
  orderBy(responses, [
    ({ status }) => {
      if (
        status === challengeResponseStatus.SUBMITTED
        || status === challengeResponseStatus.UNDER_REVISION
      ) {
        return 1;
      }

      if (status === challengeResponseStatus.ACCEPTED || status === challengeResponseStatus.DRAFT) {
        return 0;
      }

      return -1;
    },
    ({ updatedAt }) => new Date(updatedAt),
  ], ['desc', 'asc'])
);

export default sortChallengeResponses;
