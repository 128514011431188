import PropTypes from 'prop-types';

const EventTopicType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export default EventTopicType;
