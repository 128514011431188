import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Checkbox from 'components/Form/Checkbox';
import { validateIsChecked } from 'lib/validation';
import routes from 'constants/routes';

const AcceptTermsConditions = ({ error, className, submitFailed }) => (
  <div className={className}>
    <Field
      name="agree"
      component={Checkbox}
      type="checkbox"
      validate={validateIsChecked}
      label={
        <>
          I agree with the{' '}
          <Link href={routes.TERMS_AND_CONDITIONS} target="_blank">
            Terms &amp; Conditions
          </Link>{' '}
          and{' '}
          <Link href={routes.PRIVACY} target="_blank">
            Privacy Policy
          </Link>
        </>
      }
    />
    {(submitFailed && error) && <FormHelperText error>{error}</FormHelperText>}
  </div>
);

AcceptTermsConditions.propTypes = {
  className: PropTypes.string,
  submitFailed: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

AcceptTermsConditions.defaultProps = {
  className: null,
  error: null,
};

export default AcceptTermsConditions;
