import { css } from '@emotion/react';

export const buttons = (theme) => (
  css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
      justify-content: space-around;
    }
  `
);

export const button = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(2)};
      }
    }
  `
);

export const fields = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const submit = css`
  text-align: center;
`;
