import { useContext, useEffect } from 'react';
import { PageHeadContext } from 'containers/PageHeadProvider';

const withPageTitle = (title) => (Component) => {
  const PageComponent = (props) => {
    const { onSetPageTitle } = useContext(PageHeadContext);

    useEffect(() => {
      onSetPageTitle(title);
    }, [onSetPageTitle]);

    return <Component {...props} />;
  };

  return PageComponent;
};

export default withPageTitle;
