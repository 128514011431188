import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeNotification } from 'store/modules/notifications';

let displayedKeys = [];

const SnackbarNotifications = ({
  enqueueSnackbar,
  closeSnackbar,
  notifications,
  onRemoveNotification,
}) => {
  const storeDisplayedKeys = (id) => {
    displayedKeys = [...displayedKeys, id];
  };

  const removeDisplayedKeys = (id) => {
    displayedKeys = [...displayedKeys.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options }) => {
      if (!displayedKeys.includes(key)) {
        enqueueSnackbar(message, {
          key,
          ...options,
          onExited: (_, notificationKey) => {
            onRemoveNotification(notificationKey);
            removeDisplayedKeys(notificationKey);
          },
        });

        storeDisplayedKeys(key);
      }
    });
  }, [notifications, onRemoveNotification, closeSnackbar, enqueueSnackbar]);

  return null;
};

SnackbarNotifications.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      key: PropTypes.number.isRequired,
      options: PropTypes.shape({
        variant: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
};

export default withSnackbar(
  connect(
    (state) => ({ notifications: state.notifications }),
    (dispatch) => ({ onRemoveNotification: (key) => dispatch(removeNotification(key)) }),
  )(SnackbarNotifications),
);
