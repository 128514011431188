import { useMemo } from 'react';
import debounce from 'lodash/debounce';

const DEFAULT_DEBOUNCE_SEARCH = 300;

const useDebounce = (fn, debounceTimeout = DEFAULT_DEBOUNCE_SEARCH) => {
  const debouncedFn = useMemo(() => debounce(fn, debounceTimeout), [debounceTimeout, fn]);

  return debouncedFn;
};

export default useDebounce;
