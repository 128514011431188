/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import EventTopicType from 'types/EventTopic';
import EventTopicNoteType from 'types/EventTopicNote';
import SavedPriorityCard from 'components/Events/SavedPriorityCard';
import EventHint from 'components/Events/EventHint';
import formatMultilineText from 'utils/formatMultilineText';
import matchTopicsWithNotes from '../utils/matchTopicsWithNotes';
import * as classes from './styles';

const SavedTopicPriorities = ({ notes, topics }) => {
  const topicsWithNotes = matchTopicsWithNotes(topics, notes);

  return (
    <div>
      <EventHint messageAction="set business priorities" css={classes.hint} />

      {topicsWithNotes.map((topic, i) => (
        <SavedPriorityCard key={topic.id} css={classes.card} index={i + 1} title={topic.name}>
          {!!topic.note && (
            <Typography variant="body2">
              {formatMultilineText(topic.note.comment)}
            </Typography>
          )}
        </SavedPriorityCard>
      ))}
    </div>
  );
};

SavedTopicPriorities.propTypes = {
  topics: PropTypes.arrayOf(EventTopicType.isRequired),
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired),
};

SavedTopicPriorities.defaultProps = {
  notes: [],
  topics: [],
};

export default SavedTopicPriorities;
