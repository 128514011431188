import challengeResponseStatus from 'constants/challengeResponseStatus';

const challengeDashboardRelationsFragment = [
  {
    relation: 'challengeResponses',
    scope: {
      fields: ['id', 'status'],
      where: {
        status: challengeResponseStatus.SUBMITTED,
      },
    },
  },
  {
    relation: 'milestones',
    scope: {
      limit: 1,
      where: {
        dueDate: {
          gt: new Date(),
        },
      },
    },
  },
];

export default challengeDashboardRelationsFragment;
