/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import wrapperStyles from './styles';

const PublicPageContentWrapper = ({ className, children }) => (
  <div css={wrapperStyles} className={className}>{children}</div>
);

PublicPageContentWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PublicPageContentWrapper.defaultProps = {
  className: null,
};

export default PublicPageContentWrapper;
