/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import listItemStyles from './styles';

const ListItem = ({ children }) => (
  <Typography variant="body2" component="li" css={listItemStyles}>
    {children}
  </Typography>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListItem;
