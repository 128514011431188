import PropTypes from 'prop-types';
import { Typography, Rating } from '@mui/material';
import { PlayCircleOutline } from '@mui/icons-material';
import insertHost from 'utils/picture';
import getVendorAverageRating from 'utils/getVendorAverageRating';
import { ChallengeResponseType } from '../types';
import './ChallengeResponsesMobileList.scss';

const ChallengeResponsesMobileList = ({
  challengeResponses,
  onOpenVideo,
  onOpenResponse,
  checkCanViewResponse,
  renderResponseStatus,
}) => (
  <div>
    {challengeResponses.map((response) => {
      const averageRating = getVendorAverageRating(response.company.ratingsReviews);
      const canOpenChallengeResponse = checkCanViewResponse(response);

      return (
        <div className="challenge-response-mobile-card" key={response.id}>
          <div className="challenge-response-mobile-card__responder">
            <div className="challenge-response-mobile-card__company">
              {response.company.logo ? (
                <img
                  className="challenge-response-mobile-card__logo"
                  src={insertHost(response.company.logo)}
                  alt=""
                />
              ) : (
                <Typography variant="body1">{response.company.name}</Typography>
              )}
            </div>

            {!!averageRating && (
              <Rating
                size="small"
                readOnly
                value={averageRating}
              />
            )}
          </div>

          <div className="challenge-response-mobile-card__response-details">
            {canOpenChallengeResponse && (
              <PlayCircleOutline
                className="challenge-response-mobile-card__video"
                onClick={() => onOpenVideo(response.demonstrationVideo)}
              />
            )}
            <div
              className="challenge-response-mobile-card__link"
              onClick={() => {
                if (canOpenChallengeResponse) {
                  onOpenResponse(response.company.slug);
                }
              }}
            >
              {renderResponseStatus(response)}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

ChallengeResponsesMobileList.propTypes = {
  checkCanViewResponse: PropTypes.func.isRequired,
  onOpenResponse: PropTypes.func.isRequired,
  onOpenVideo: PropTypes.func.isRequired,
  challengeResponses: PropTypes.arrayOf(ChallengeResponseType.isRequired).isRequired,
  renderResponseStatus: PropTypes.func.isRequired,
};

export default ChallengeResponsesMobileList;
