import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import { getSavedVendorList } from 'store/modules/savedVendors';
import RetailerEvent from 'components/Events/RetailerEvent';
import eventAPI from 'api/event';
import eventRetailerUserAPI from 'api/eventRetailUser';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import formatSearchResults from 'utils/formatSearchResults';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const RetailerEventPage = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const userId = useSelector((state) => (state.user.authUser.id));

  const getInitialProps = useCallback(async () => {
    const { data: event } = await eventAPI.getEventWithParticipationRequest(slug, userId);
    const [isEventAttendee] = await Promise.all([
      eventRetailerUserAPI.isUserEventAttendee(userId, event.id),
      dispatch(getSavedVendorList(userId)),
    ]);

    const participationRequest = event.participationRequests.find(({ userId: id }) => (
      id === userId
    ));

    if (!participationRequest && !isEventAttendee) {
      return {
        event,
        isEventAttendee: false,
        participationRequest,
      };
    }

    if (!!participationRequest && !isEventAttendee) {
      return {
        event,
        isEventAttendee: false,
        participationRequest,
      };
    }

    const {
      data: {
        challenges,
        vendors,
        topicPriorityLists,
      },
    } = await eventAPI.getEventDetailsForRetailer(event.id, userId);

    if (topicPriorityLists && topicPriorityLists[0]) {
      const { topics: orderedTopics, notes } = topicPriorityLists[0];

      return {
        event,
        orderedTopics,
        notes,
        isEventAttendee,
        vendors: formatSearchResults(vendors),
        challenges,
        participationRequest,
      };
    }

    return {
      event,
      isEventAttendee,
      vendors: formatSearchResults(vendors),
      challenges,
      participationRequest,
    };
  }, [userId, dispatch, slug]);

  return (
    <FetchContainer
      component={RetailerEvent}
      getInitialProps={getInitialProps}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.EVENT_DETAILS_PAGE)(RetailerEventPage)),
);
