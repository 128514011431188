import { css } from '@emotion/react';

const TABLET_BREAKPOINT = 768;

export const trials = css`
  display: flex;
`;

export const trialsSelect = (theme) => (
  css`
    flex: 1;

    &:not(:last-child) {
      ${theme.breakpoints.up(TABLET_BREAKPOINT)} {
        margin-right: ${theme.spacing(5)};
      }

      ${theme.breakpoints.down(TABLET_BREAKPOINT)} {
        margin-right: ${theme.spacing(2)};
      }
    }
  `
);

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const input = (theme) => (
  css`
    margin-top: ${theme.spacing(1)};
  `
);
