import sortOrder from 'constants/sortOrder';
import challengeStatus from 'constants/challengeStatus';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import challengeDashboardRelationsFragment from './fragments/challengeDashboardRelationsFragment';
import yesNoQuestionsFragment from './fragments/yesNoQuestionsFragment';
import APIClient from './client';

class ChallengeAPI extends APIClient {
  constructor() {
    super('/challenge');
  }

  getPublicChallengeDetails = async (slug, challengeOwnerCompanyId, userCompanyId) => {
    const filter = {
      where: {
        slug,
        companyId: challengeOwnerCompanyId,
        status: {
          nin: [challengeStatus.DRAFT, challengeStatus.DRAFT_REVISION],
        },
      },
      include: [
        {
          relation: 'user',
          scope: {
            fields: ['id', 'position'],
          },
        },
        {
          relation: 'company',
          scope: {
            fields: ['id', 'name', 'slug'],
          },
        },
        {
          relation: 'challengeResponses',
          scope: {
            fields: ['id', 'companyId', 'status'],
            where: {
              companyId: userCompanyId,
            },
          },
        },
        {
          relation: 'shortlistedCompanies',
          scope: {
            where: {
              companyId: userCompanyId,
            },
          },
        },
        {
          relation: 'participationRequests',
          scope: {
            where: {
              companyId: userCompanyId,
            },
          },
        },
      ],
    };

    const { data: [challenge] } = await this.find(filter);

    return challenge;
  };

  getChallengeResponseData = async (id, companyId) => {
    const filter = {
      include: [
        {
          relation: 'company',
          scope: {
            fields: ['id', 'name', 'slug'],
          },
        },
        {
          relation: 'shortlistedCompanies',
          scope: {
            where: {
              companyId,
            },
          },
        },
        {
          relation: 'challengeResponses',
          scope: {
            where: {
              companyId,
            },
          },
        },
        yesNoQuestionsFragment,
      ],
    };

    const { data } = await this.findById(id, filter);

    return data;
  };

  getSharedChallengeForRegistration = (id) => this.findById(id, {
    fields: ['id', 'status', 'response_deadline', 'source'],
  });

  getDataForChallengeLink = async (id) => {
    const { data } = await this.findById(id, {
      fields: ['id', 'status', 'slug', 'companyId', 'source'],
      include: {
        relation: 'company',
        scope: {
          fields: ['id', 'slug'],
        },
      },
    });

    return data;
  };

  getRetailerChallenges = (companyId) => {
    const filter = {
      where: {
        companyId,
      },
      order: `response_deadline ${sortOrder.DESC}`,
      include: [
        {
          relation: 'event',
          scope: {
            fields: ['id', 'name'],
          },
        },
        ...challengeDashboardRelationsFragment,
      ],
    };

    return this.find(filter);
  };

  getResponses = (id) => {
    const filter = {
      where: {
        status: {
          nin: [
            challengeResponseStatus.REJECTED,
            challengeResponseStatus.REMOVED_FROM_SHORTLIST,
          ],
        },
      },
      include: {
        relation: 'company',
        scope: {
          fields: ['id', 'name', 'logo', 'ratingsReviews', 'slug'],
          include: {
            relation: 'ratingsReviews',
            scope: {
              fields: ['id', 'ratings'],
            },
          },
        },
      },
    };

    return this.getRequest(`/${id}/challengeResponses`, filter);
  };

  getReviewers = (id) => this.getRequest(`/${id}/challengeReviewers`, {
    include: {
      relation: 'user',
      scope: {
        fields: ['id', 'firstName', 'lastName', 'email'],
      },
    },
  });

  saveChallenge = (body) => this.postRequest('/save-challenge', body);

  requestRevision = (id) => this.postRequest(`/${id}/request-revision`);

  cancelChallenge = (id, body) => this.postRequest(`/${id}/cancel`, body);

  pauseChallenge = (id, body) => this.patchRequest(`/${id}/pause`, body);

  restartChallenge = (id, body) => this.patchRequest(`/${id}/restart`, body);

  completeChallenge = (id, body) => this.patchRequest(`/${id}/complete`, body);

  getVendorChallenges = () => this.getRequest('/aggregate/vendor');

  getMilestones = (id) => this.getRequest(`/${id}/milestones`, {
    scope: {
      order: `dueDate ${sortOrder.ASC}`,
    },
  });

  addMilestone = (id, body) => this.postRequest(`/${id}/milestones`, body);

  editMilestone = (id, milestoneId, body) => this.putRequest(`/${id}/milestones/${milestoneId}`, body);

  getChallengeEvent = (id) => this.getRequest(`/${id}/event`);

  getChallengeDetails = async (id) => {
    const { data } = await this.findById(id, {
      include: [
        yesNoQuestionsFragment,
        {
          relation: 'company',
          scope: {
            fields: ['id', 'slug'],
          },
        },
      ],
    });

    return data;
  };

  saveYesNoQuestions = (id, data) => this.postRequest(`/${id}/yes-no-questions`, data);

  checkIsSlugUnique = (query) => this.getRequest('/validate-slug', {}, query);
}

const challengeAPI = new ChallengeAPI();

export default challengeAPI;
