import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

const PhoneInput = ({
  className,
  meta: { error, submitFailed },
  label,
  placeholder,
  input: { name, value, onChange },
  id,
}) => (
  <FormLabel className={className}>
    {label}
    <NumberFormat
      sx={{ mt: 1 }}
      placeholder={placeholder}
      customInput={TextField}
      format="+## ### ### ### ### ### ##"
      type="tel"
      inputMode="tel"
      name={name}
      value={value}
      onValueChange={(values) => onChange(values.value)}
      fullWidth
      error={error && submitFailed}
      helperText={submitFailed && error}
      id={id}
    />
  </FormLabel>
);

PhoneInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  id: PropTypes.string.isRequired,
};

PhoneInput.defaultProps = {
  className: null,
  placeholder: null,
};

export default PhoneInput;
