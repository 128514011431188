/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import containerStyles from './styles';

const FailSendMessage = ({ onClose }) => (
  <div css={containerStyles}>
    <Typography sx={{ mb: 2 }} variant="h3">
      Sorry
    </Typography>
    <Typography sx={{ mb: 2 }} variant="body1">
      We could not deliver this message now. Please wait before trying again.
    </Typography>
    <Button onClick={onClose}>Close</Button>
  </div>
);

FailSendMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FailSendMessage;
