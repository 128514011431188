/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import * as classes from './styles';

const Sidebar = ({ children, className, isExpanded }) => (
  <aside
    css={[classes.sidebar, isExpanded ? classes.expanded : classes.hidden]}
    className={className}
  >
    <div css={[classes.content, !isExpanded && classes.contentHidden]}>
      {children}
    </div>
  </aside>
);

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
};

Sidebar.defaultProps = {
  className: null,
};

export default Sidebar;
