import { generatePath } from 'react-router-dom';
import routes from 'constants/routes';
import companyStatus from 'constants/companyStatus';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';

export const getRedirectUnauthorizedUser = (location) => {
  const search = new URLSearchParams({ backPath: location.pathname }).toString();

  return { pathname: routes.LOGIN, search };
};

export const getRedirectAuthenticatedFromPublicVendorPage = (user, slug) => {
  const { type } = user.company;

  if (type === companyStatus.RETAILER) {
    return generatePath(routes.VENDOR_PROFILE_TAB, {
      slug,
      tab: VENDOR_INFO_TABS.BASIC_DETAILS.path,
    });
  }

  return routes.MAIN;
};
