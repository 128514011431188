/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import ChallengesTable from 'components/Shared/ChallengesTable';
import ChallengeCard from 'components/Shared/ChallengeCard';
import ChallengeDashboardFallback from 'components/Shared/ChallengeDashboardFallback';
import formatDate from 'utils/formatDate';
import { isEditable } from 'utils/challengeCheckers';
import sortOrder from 'constants/sortOrder';
import challengeStatus from 'constants/challengeStatus';
import routes from 'constants/routes';
import retailerDisplayedChallengeStatuses from 'constants/retailerDisplayedChallengeStatus';
import DashboardChallengeType from 'types/DashboardChallengeType';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';

const ChallengesList = ({ challenges, isSourceShown }) => {
  const [challengesSortOrder, setSortOrder] = useState(sortOrder.DESC);

  const sortByDeadline = useCallback((a, b) => {
    if (a.response_deadline && b.response_deadline) {
      const msA = Date.parse(a.response_deadline);
      const msB = Date.parse(b.response_deadline);

      return challengesSortOrder === sortOrder.ASC ? msA - msB : msB - msA;
    }

    if (!a.response_deadline && b.response_deadline) {
      return challengesSortOrder === sortOrder.ASC ? -1 : 1;
    }

    if (a.response_deadline && !b.response_deadline) {
      return challengesSortOrder === sortOrder.ASC ? 1 : -1;
    }

    return 0;
  }, [challengesSortOrder]);

  const sortedChallenges = useMemo(
    () => challenges.sort(sortByDeadline),
    [challenges, sortByDeadline],
  );

  const handleToggleDeadlineSorting = () => setSortOrder((prevOrder) => (
    prevOrder === sortOrder.ASC ? sortOrder.DESC : sortOrder.ASC
  ));

  const getStatusClassName = (displayedStatus) => {
    switch (displayedStatus) {
      case retailerDisplayedChallengeStatuses.DRAFT:
        return classes.draftStatus;
      case retailerDisplayedChallengeStatuses.ACTIVE:
        return classes.activeStatus;
      case retailerDisplayedChallengeStatuses.REQUESTED_REVISION:
        return classes.requestedRevisionStatus;
      case retailerDisplayedChallengeStatuses.APPROVED_REVISION:
        return classes.approvedRevisionStatus;
      case retailerDisplayedChallengeStatuses.IN_REVISION:
        return classes.inRevisionStatus;
      case retailerDisplayedChallengeStatuses.PAUSED:
        return classes.pausedStatus;
      case retailerDisplayedChallengeStatuses.AWAITING_UPDATE:
        return classes.awaitingUpdateStatus;
      case retailerDisplayedChallengeStatuses.COMPLETED:
        return classes.completedStatus;
      case retailerDisplayedChallengeStatuses.CANCELLED:
        return classes.cancelledStatus;
      default:
        return null;
    }
  };

  const renderChallengeStatus = (challenge) => {
    const { displayedStatus } = challenge;

    return <span css={getStatusClassName(displayedStatus)}>{displayedStatus}</span>;
  };

  const getChallengeDeadline = (challenge) => {
    const { response_deadline: deadline } = challenge;

    if (!deadline) {
      return 'Not set';
    }

    return formatDate(deadline, DATE_FORMAT);
  };

  const getChallengePathname = (challenge) => {
    const { id } = challenge;

    const isChallengeEditable = isEditable(challenge);

    return isChallengeEditable
      ? generatePath(routes.EDIT_SAVED_CHALLENGE, { id })
      : generatePath(routes.SAVED_CHALLENGE, { id });
  };

  const getMilestone = ({ milestones }) => {
    const [challengeMilestone] = milestones;

    return challengeMilestone;
  };

  const formatMilestoneDeadline = (milestone) => formatDate(milestone.dueDate, DATE_FORMAT);

  const checkIsDraftChallenge = (challenge) => (
    challenge.status === challengeStatus.DRAFT
    || challenge.status === challengeStatus.DRAFT_REVISION
  );

  return (
    <div>
      {sortedChallenges.length ? (
        <>
          <ChallengesTable
            css={classes.desktopUI}
            challenges={sortedChallenges}
            getChallengePathname={getChallengePathname}
            getMilestone={getMilestone}
            renderChallengeStatus={renderChallengeStatus}
            getChallengeDeadline={getChallengeDeadline}
            formatMilestoneDeadline={formatMilestoneDeadline}
            checkIsDraft={checkIsDraftChallenge}
            onToggleDeadlineSorting={handleToggleDeadlineSorting}
            isSourceShown={isSourceShown}
          />

          <div css={classes.mobileUI}>
            {sortedChallenges.map((challenge) => (
              <ChallengeCard
                key={challenge.id}
                css={classes.card}
                challenge={challenge}
                getChallengePathname={getChallengePathname}
                getMilestone={getMilestone}
                renderChallengeStatus={renderChallengeStatus}
                getChallengeDeadline={getChallengeDeadline}
                formatMilestoneDeadline={formatMilestoneDeadline}
                checkIsDraft={checkIsDraftChallenge}
                isSourceShown={isSourceShown}
              />
            ))}
          </div>
        </>
      ) : (
        <ChallengeDashboardFallback />
      )}
    </div>
  );
};

ChallengesList.propTypes = {
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired).isRequired,
  isSourceShown: PropTypes.bool.isRequired,
};

export default ChallengesList;
