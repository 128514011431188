const moneyFormat = (amount) => {
  if (amount && parseInt(amount, 10)) {
    const number = parseInt(amount, 10);

    if (number <= 999) {
      return number;
    }

    if (number > 9999 && number <= 99999) {
      const roundedNum = number.toString().split(/(?=(?:\d{3})+(?!\d))/);

      return roundedNum;
    }

    if (number > 99999 && number <= 999999) {
      const roundedNum = number.toString().split(/(?=(?:\d{3})+(?!\d))/);

      return roundedNum;
    }

    if (number > 999999 && number <= 999999999) {
      const roundedNum = Math.round((number / 1000000) * 100) / 100;

      return `${roundedNum}m`;
    }

    if (number > 999999999) {
      const roundedNum = Math.round((number / 1000000000) * 100) / 100;

      return `${roundedNum}bn`;
    }

    return number;
  }

  return '';
};

export default moneyFormat;
