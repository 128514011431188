import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import PageBody from 'components/UI/PageBody';
import routes from 'constants/routes';
import UsersList from './UsersList';

const breadcrumbs = [
  { name: 'Home', to: routes.MAIN },
  { name: 'My company', to: routes.MANAGE_USERS },
];

const ManageUsers = () => (
  <PageBody>
    <Breadcrumbs sx={{ mb: 5 }}>
      {breadcrumbs.map(({ name, to }) => <Link key={to} to={to}>{name}</Link>)}
    </Breadcrumbs>

    <Typography sx={{ mb: 5 }} variant="h1">Users</Typography>

    <UsersList />
  </PageBody>
);

export default ManageUsers;
