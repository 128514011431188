/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { Button, DialogActions, Typography } from '@mui/material';
import GeneralModal from 'components/UI/GeneralModal';
import userAPI from 'api/user';
import getErrorMessage from 'utils/getErrorMessage';
import actions from './styles';

const VerifyEmailModal = ({
  email,
  isOpen,
  onClose,
  enqueueSnackbar,
}) => {
  const handleResendVerificationLink = () => {
    userAPI.resendVerificationLink(email)
      .then(() => enqueueSnackbar('Link was sent', { variant: 'success' }))
      .catch((error) => {
        const errorMessage = getErrorMessage(error, 'Failed to resend link');
        enqueueSnackbar(errorMessage, { variant: 'error' });
      })
      .finally(() => onClose());
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title="You have not verified your email address"
      maxWidth="sm"
    >
      <Typography sx={{ mb: 5 }} variant="body2">
        We have sent you an email to verify your address. If you have not received this, or the link
        has expired, you can use this button to send a new email now.
      </Typography>
      <DialogActions css={actions}>
        <Button onClick={handleResendVerificationLink}>Resend email</Button>
        <Button variant="text" onClick={onClose}>Cancel</Button>
      </DialogActions>
    </GeneralModal>
  );
};

VerifyEmailModal.propTypes = {
  email: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

VerifyEmailModal.defaultProps = {
  email: null,
};

export default withSnackbar(VerifyEmailModal);
