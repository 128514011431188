import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ChallengeResponseForm from 'components/ChallengeResponseForm/ChallengeResponseFormPage';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import withRouteGuards from 'containers/withRouteGuards';
import { approvedCompanyGuard, authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const ChallengeResponseFormPage = () => {
  const { challengeId } = useParams();
  const companyId = useSelector((state) => state.user.company.id);

  const getInitialProps = useCallback(async () => {
    const {
      shortlistedCompanies: [shortlistedCompany],
      challengeResponses: [challengeResponse],
      company,
      ...challenge
    } = await challengeAPI.getChallengeResponseData(challengeId, companyId);

    return {
      isShortlisted: !!shortlistedCompany,
      challenge,
      challengeResponse,
      company,
    };
  }, [challengeId, companyId]);

  return <FetchContainer getInitialProps={getInitialProps} component={ChallengeResponseForm} />;
};

export default withRouteGuards([authorizedRouteGuard, approvedCompanyGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGE_RESPONSE)(ChallengeResponseFormPage)),
);
