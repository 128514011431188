/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GeneralModal from 'components/UI/GeneralModal';
import TextFieldInput from 'components/Form/TextFieldInput';
import SelectField from 'components/Form/SelectField';
import { required } from 'lib/validation';
import validate from './validate';
import * as classes from './styles';

const InviteUserModal = ({
  isOpen,
  onClose,
  onSendInvitation,
  emailDomains,
}) => (
  <GeneralModal
    css={classes.modal}
    isOpen={isOpen}
    onClose={onClose}
    title="Invite user"
  >
    <Typography sx={{ mb: 2 }} variant="body2">
      Please enter work email of the user you want to invite. Use enter or
      space key to add more emails.
    </Typography>
    <div>
      <Form
        validate={validate}
        onSubmit={onSendInvitation}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div css={classes.field}>
              <Field
                css={classes.username}
                label="Invite user using company email"
                type="text"
                name="username"
                placeholder="Invite user using company email"
                component={TextFieldInput}
                validate={required}
              />
              <Field
                css={classes.domain}
                name="domain"
                fullWidth
                component={SelectField}
                validate={required}
                emptyOptionLabel="Not selected"
              >
                {emailDomains.map((emailDomain) => (
                  <option key={emailDomain} value={emailDomain}>
                    {emailDomain}
                  </option>
                ))}
              </Field>
            </div>
            <div css={classes.buttons}>
              <Button type="submit">Send invite</Button>
              <Button onClick={onClose} variant="text">Cancel</Button>
            </div>
          </form>
        )}
      />
    </div>
  </GeneralModal>
);

InviteUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendInvitation: PropTypes.func.isRequired,
  emailDomains: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default InviteUserModal;
