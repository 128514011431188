/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import challengeStatus from 'constants/challengeStatus';
import routes from 'constants/routes';
import containerStyles from './styles';

const ManageEventChallenge = ({ className }) => {
  const { state: { challenge }, cancelChallenge } = useContext(ChallengeContext);

  const { id, status } = challenge;

  const editChallengeUrl = generatePath(routes.EDIT_SAVED_CHALLENGE, { id });

  const challengeResponsesDashboardUrl = generatePath(
    routes.CHALLENGE_RESPONSES_DASHBOARD,
    { challengeId: id },
  );

  return (
    <div className={className} css={containerStyles}>
      <Button
        to={editChallengeUrl}
        component={Link}
        disabled={status === challengeStatus.CANCELLED}
      >
        Edit
      </Button>
      <Button
        onClick={() => cancelChallenge()}
        disabled={status === challengeStatus.CANCELLED}
      >
        {status === challengeStatus.CANCELLED ? 'Canceled' : 'Cancel'}
      </Button>
      <Button to={challengeResponsesDashboardUrl} component={Link}>
        View responses
      </Button>
    </div>
  );
};

ManageEventChallenge.propTypes = {
  className: PropTypes.string,
};

ManageEventChallenge.defaultProps = {
  className: null,
};

export default ManageEventChallenge;
