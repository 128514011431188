import PropTypes from 'prop-types';

export const CaseStudyType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  picture: PropTypes.string,
  link: PropTypes.string,
});

export const ArticleType = PropTypes.shape({
  picture: PropTypes.string,
  topic: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
});

export const AwardType = PropTypes.shape({
  name: PropTypes.string,
  year: PropTypes.string,
});
