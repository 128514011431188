/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import { generatePath, Link } from 'react-router-dom';
import { CalendarSearchRegular, ArrowSortRegular } from '@fluentui/react-icons';
import PageBody from 'components/UI/PageBody';
import formatDate from 'utils/formatDate';
import EventType from 'types/Event';
import routes from 'constants/routes';
import sortOrder from 'constants/sortOrder';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';
const ICON_SIZE = 72;

const EventsDashboard = ({ events, eventPagePathname }) => {
  const [eventsSortOrder, setSortOrder] = useState(sortOrder.ASC);

  const sortByEventDate = useCallback((a, b) => {
    const msA = Date.parse(a.eventDate);
    const msB = Date.parse(b.eventDate);

    return eventsSortOrder === sortOrder.ASC ? msA - msB : msB - msA;
  }, [eventsSortOrder]);

  const sortedEvents = useMemo(() => events.sort(sortByEventDate), [events, sortByEventDate]);

  const handleToggleSorting = () => setSortOrder((prevOrder) => (
    prevOrder === sortOrder.ASC ? sortOrder.DESC : sortOrder.ASC
  ));

  const getEventPathname = (event) => {
    const [slug] = event.slugs.slice(-1);

    return generatePath(eventPagePathname, { slug });
  };

  return (
    <PageBody css={!events.length && classes.emptyDashboard}>
      <Typography sx={{ mb: 4 }} variant="h1">
        Events
      </Typography>

      {sortedEvents.length ? (
        <>
          <div css={classes.tableHeader}>
            <Typography css={classes.caption} variant="caption1">Name</Typography>
            <div css={[classes.descriptionHeader, classes.eventDate]}>
              <button css={classes.sortButton} onClick={handleToggleSorting}>
                <Typography css={classes.caption} variant="caption1">Date</Typography>
                <ArrowSortRegular />
              </button>
            </div>
          </div>
          <ul>
            {sortedEvents.map((event) => (
              <li key={event.id} css={classes.item}>
                <Link
                  css={classes.link}
                  to={getEventPathname(event)}
                >
                  <Hidden smUp>
                    <Typography variant="subtitle2">{event.name}</Typography>
                  </Hidden>
                  <Hidden smDown>
                    <Typography variant="subtitle1">{event.name}</Typography>
                  </Hidden>

                  <div css={classes.eventDetails}>
                    <div css={classes.column}>
                      <Typography
                        css={[classes.columnTitle, classes.caption]}
                        variant="caption1"
                      >
                        Date
                      </Typography>
                      <Typography css={classes.eventDate} variant="body2">
                        {formatDate(event.eventDate, DATE_FORMAT)}
                      </Typography>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div css={classes.fallback}>
          <div css={classes.iconWrap}>
            <CalendarSearchRegular fontSize={ICON_SIZE} />
          </div>
          <Typography css={classes.subtitle} variant="subtitle2">There is not any events</Typography>
        </div>
      )}
    </PageBody>
  );
};

EventsDashboard.propTypes = {
  events: PropTypes.arrayOf(EventType.isRequired).isRequired,
  eventPagePathname: PropTypes.oneOf([
    routes.VENDOR_EVENT_DETAILS,
    routes.RETAILER_EVENT_DETAILS,
  ]).isRequired,
};

export default EventsDashboard;
