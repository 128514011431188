/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextFieldInput from 'components/UI/TextFieldInput';
import { required, validateUrl, validatePhoneLength } from 'lib/validation';
import PhoneInput from 'components/UI/PhoneInput';
import AvatarField from '../AvatarField';
import fieldStyles from './styles';

const COMPANY_PHONE_NUMBER = 'company_phone_number';

const BasicCompanyInfoFormSection = ({ className, uploadFieldName, userId }) => (
  <div className={className}>
    <Field
      sx={{ mb: 2 }}
      label="Company name"
      name="name"
      disabled
      validate={required}
      component={TextFieldInput}
    />
    <Field
      sx={{ mb: 2 }}
      label="Company website"
      type="url"
      name="companyWebsite"
      placeholder="https://"
      validate={[required, validateUrl]}
      component={TextFieldInput}
    />
    <Field
      css={fieldStyles}
      id={COMPANY_PHONE_NUMBER}
      label="Company phone number (including country code)"
      type="number"
      placeholder="44 444 444 444"
      name="number"
      validate={[required, validatePhoneLength]}
      component={PhoneInput}
    />
    <Field
      label="Company logo"
      name={uploadFieldName}
      component={AvatarField}
      userId={userId}
      model="company"
      validate={required}
    />
  </div>
);

BasicCompanyInfoFormSection.propTypes = {
  className: PropTypes.string,
  uploadFieldName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

BasicCompanyInfoFormSection.defaultProps = {
  className: null,
};

export default BasicCompanyInfoFormSection;
