import { css } from '@emotion/react';

export const bold = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);

export const options = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
    }
  `
);

export const revenue = (theme) => (
  css`
    display: block;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const revenueSection = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: flex-end;
    }
  `
);

export const control = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;

      &:not(:last-child) {
        display: block;
        margin-bottom: ${theme.spacing(2)};
      }
    }

    ${theme.breakpoints.up('sm')} {
      flex: 1;

      &:not(:last-child) {
        margin-right: ${theme.spacing(1)} !important;
      }
    }
  `
);

export const revenueInput = (theme) => (
  css`
    margin-top: 0 !important;

    ${theme.breakpoints.up('sm')} {
      width: auto !important;
    }
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);
