import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import JohnLewisLogo from 'static/images/partnerLogos/JohnLewis.png';
import DuneLogo from 'static/images/partnerLogos/Dune.png';
import RaphaLogo from 'static/images/partnerLogos/Rapha.png';
import BelstaffLogo from 'static/images/partnerLogos/Belstaff.png';
import KarenMillenLogo from 'static/images/partnerLogos/KarenMillen.png';
import SchuhLogo from 'static/images/partnerLogos/Schuh.png';
import BugabooLogo from 'static/images/partnerLogos/Bugaboo.png';
import './PartnerBanner.scss';

const CAROUSEL_ITEMS = [
  JohnLewisLogo,
  DuneLogo,
  RaphaLogo,
  BelstaffLogo,
  KarenMillenLogo,
  SchuhLogo,
  BugabooLogo,
];

const PartnerBanner = ({ className }) => {
  const handleOnDragStart = (e) => e.preventDefault();
  const responsive = {
    0: {
      items: 4,
    },
  };

  return (
    <div className={className}>
      <AliceCarousel
        mouseTrackingEnabled
        dotsDisabled
        autoPlay
        autoPlayInterval={3500}
        buttonsDisabled
        responsive={responsive}
      >
        {CAROUSEL_ITEMS.map((item) => (
          <img
            alt={item}
            key={item}
            src={item}
            onDragStart={handleOnDragStart}
            className="partner-carousel-image"
          />
        ))}
      </AliceCarousel>
    </div>
  );
};

PartnerBanner.propTypes = {
  className: PropTypes.string,
};

PartnerBanner.defaultProps = {
  className: null,
};

export default PartnerBanner;
