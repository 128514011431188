import PropTypes from 'prop-types';

const FieldInputType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
});

export default FieldInputType;
