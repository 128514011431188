/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import container from './styles';

const DropDownInput = ({
  input,
  meta: { touched, submitting, error },
  label,
  className,
  children,
  id,
  sx,
  disabled,
}) => {
  // TODO: temporary dirty hack for a11y. All select fields should have id and id should be required
  const inputId = id || input.name;

  return (
    <FormControl
      disabled={disabled}
      error={!!((touched || submitting) && error)}
      className={className}
      css={container}
      sx={sx}
    >
      {!!label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
      <NativeSelect {...input} inputProps={{ id: inputId }} fullWidth>
        <option value="">Not selected</option>
        {children}
      </NativeSelect>
      {!!((touched || submitting) && error) && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

DropDownInput.propTypes = {
  sx: PropTypes.object,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.node),
  ]),
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

DropDownInput.defaultProps = {
  className: null,
  label: null,
  id: null,
  fullWidth: false,
  sx: null,
  disabled: false,
};

export default DropDownInput;
