import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import PageBody from 'components/UI/PageBody';

const PageContainer = ({ error, renderChildren }) => (
  <PageBody>
    {error ? <Typography variant="body1">{error}</Typography> : renderChildren()}
  </PageBody>
);

PageContainer.propTypes = {
  onReturnBack: PropTypes.func,
  renderChildren: PropTypes.func.isRequired,
  error: PropTypes.string,
};

PageContainer.defaultProps = {
  error: null,
  onReturnBack: null,
};

export default PageContainer;
