import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import PublicChallengeSynopsis from 'components/PublicChallengeSynopsis';
import PageBody from 'components/UI/PageBody';
import challengeRequestParticipationStatus from 'constants/challengeParticipationRequestStatus';
import challengeStatus from 'constants/challengeStatus';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import getRedirectUrlForVendorChallenge from 'utils/getRedirectUrlForVendorChallenge';
import SharedChallenge from '../SharedChallenge/SharedChallenge';

const SHARED_CHALLENGE_FLAG = 'isShared';

const PublicChallenge = (props) => {
  const {
    challenge,
    challengeResponse,
    participationRequest,
    company: challengeOwnerCompany,
    isShortlisted,
  } = props;

  const isAuthenticated = useSelector((state) => !!state.user.authUser.id);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isShared = queryParams.get(SHARED_CHALLENGE_FLAG);

  const getRedirectUrl = () => {
    if (!isAuthenticated) {
      return null;
    }

    if (
      // Redirect user to regular version of the page if they sent a participation request or they
      // were excluded from the shortlist
      isShared
      && (
        (
          !challengeResponse
          && !!participationRequest
          && (
            participationRequest.status === challengeRequestParticipationStatus.PENDING
            || participationRequest.status === challengeRequestParticipationStatus.REJECTED
          )
        ) || (
          challengeResponse
          && challengeResponse.status === challengeResponseStatus.REMOVED_FROM_SHORTLIST
        )
      )
    ) {
      return { ...location, search: '' };
    }

    if (isShared && !challengeResponse && (
      challenge.status === challengeStatus.COMPLETED
      || challenge.status === challengeStatus.CANCELLED
      || challenge.status === challengeStatus.PAUSED
    )) {
      return { ...location, search: '' };
    }

    const redirectUrl = getRedirectUrlForVendorChallenge(
      challenge,
      challengeResponse,
      isShortlisted,
      challengeOwnerCompany,
    );

    return redirectUrl === location.pathname ? null : redirectUrl;
  };

  const redirectUrl = getRedirectUrl();

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <PageBody>
      {isShared ? <SharedChallenge {...props} /> : <PublicChallengeSynopsis {...props} />}
    </PageBody>
  );
};

PublicChallenge.propTypes = {
  challenge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(challengeStatus)).isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  challengeResponse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(challengeResponseStatus)).isRequired,
  }),
  isShortlisted: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  participationRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(challengeRequestParticipationStatus)).isRequired,
  }),
};

PublicChallenge.defaultProps = {
  isShortlisted: false,
  challengeResponse: null,
  participationRequest: null,
};

export default PublicChallenge;
