import PropTypes from 'prop-types';

const FieldArrayMetaType = PropTypes.shape({
  dirty: PropTypes.bool.isRequired,
  error: PropTypes.any,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  warning: PropTypes.string,
});

export default FieldArrayMetaType;
