import PropTypes from 'prop-types';

const EventAgendaType = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      stationId: PropTypes.number.isRequired,
      hostCompanyName: PropTypes.string.isRequired,
      guestCompanyName: PropTypes.string.isRequired,
      guestRole: PropTypes.string.isRequired,
      hostRole: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
    }),
  ).isRequired,
);

export default EventAgendaType;
