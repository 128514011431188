import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import companyStatus from 'constants/companyStatus';
import { SALES_EMAIL_ADDRESS } from 'constants/contacts';
import ReviewList from 'components/Reviews/ReviewsList';
import ReviewType from 'types/Review';
import LocationType from 'types/Location';
import { addVendorReview } from 'store/thunk/vendorInfo';
import EmptyReviewsList from '../EmptyReviewsList';
import ReviewVendorModal from '../ReviewVendorModal';
import './RatingsReviewsTab.scss';

class RatingsReviewsTab extends Component {
  static propTypes = {
    companyType: PropTypes.oneOf(Object.values(companyStatus)).isRequired,
    companyId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    ratingsReviews: PropTypes.arrayOf(ReviewType).isRequired,
    canReviewCompany: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    onAddReview: PropTypes.func.isRequired,
    location: LocationType.isRequired,
  };

  state = {
    isReviewFormOpen: false,
  };

  componentDidMount() {
    const { location: { search }, canReviewCompany } = this.props;

    const queryParams = new URLSearchParams(search);
    const shouldOpenReviewForm = queryParams.has('openModal');

    if (canReviewCompany && !this.isUserReviewedCompany && shouldOpenReviewForm) {
      this.setState({ isReviewFormOpen: true });
    }
  }

  get isUserReviewedCompany() {
    const { userId, ratingsReviews } = this.props;

    return ratingsReviews.some((review) => review.userId === userId);
  }

  handleCloseModal = () => this.setState({ isReviewFormOpen: false });

  handleReviewVendor = () => {
    const { enqueueSnackbar, companyName } = this.props;

    if (this.isUserReviewedCompany) {
      enqueueSnackbar(
        `You have already provided a review of ${companyName},
       If you would like to make changes to your review,
       please contact support: ${SALES_EMAIL_ADDRESS}`,
        {
          variant: 'error',
        },
      );
    } else {
      this.setState({ isReviewFormOpen: true });
    }
  };

  handleSubmitReview = async (values) => {
    const {
      companyId,
      enqueueSnackbar,
      userId,
      onAddReview,
    } = this.props;

    try {
      await onAddReview({ companyId, userId, ...values });

      enqueueSnackbar('Review was saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to save review', { variant: 'error' });
    }

    this.handleCloseModal();
  };

  static getReviewerCompanyLogo(review) {
    const { user } = review;

    return user ? user.company.smallLogoLink : null;
  }

  static getReviewerCompanyName(review) {
    const { user } = review;

    return user ? user.company.name : null;
  }

  render() {
    const {
      companyName,
      ratingsReviews,
      canReviewCompany,
      companyType,
    } = this.props;
    const { isReviewFormOpen } = this.state;

    return (
      <>
        <div>
          {!ratingsReviews || !ratingsReviews.length ? (
            <EmptyReviewsList
              companyName={companyName}
              isVendorUser={companyType === companyStatus.VENDOR}
              canReviewCompany={canReviewCompany}
              onAddReview={this.handleReviewVendor}
            />
          ) : (
            <>
              <div className="vendor-profile-reviews-top">
                <Typography className="vendor-profile-reviews-top__title" variant="h1">
                  Ratings and reviews
                </Typography>
                {canReviewCompany && (
                  <Button onClick={this.handleReviewVendor}>Rate and Review</Button>
                )}
              </div>
              <ReviewList
                showReviewerName
                getLogoUrl={RatingsReviewsTab.getReviewerCompanyLogo}
                getCompanyName={RatingsReviewsTab.getReviewerCompanyName}
                canVote={companyType === companyStatus.RETAILER}
                reviews={ratingsReviews}
              />
            </>
          )}
        </div>
        <ReviewVendorModal
          isOpen={isReviewFormOpen}
          onClose={this.handleCloseModal}
          onSubmit={this.handleSubmitReview}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { name, id, ratingsReviews } = state.vendorInfo.company;

  return {
    companyType: state.user.company.type,
    canReviewCompany: state.user.company.type === companyStatus.RETAILER,
    userId: state.user.authUser.id,
    companyName: name,
    companyId: id,
    ratingsReviews,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddReview: (review) => dispatch(addVendorReview(review)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(RatingsReviewsTab)),
);
