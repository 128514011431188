/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { CheckmarkCircle16Regular, DismissCircle16Regular } from '@fluentui/react-icons';
import recommendSolutionOptions from 'constants/review';
import * as classes from './styles';

const RecommendationMessage = ({ recommendSolution }) => (
  <div css={classes.recommendation}>
    {recommendSolution === recommendSolutionOptions.RECOMMEND
      ? (
        <>
          <CheckmarkCircle16Regular css={classes.recommendedIcon} />
          <Typography variant="caption1">Would recommend</Typography>
        </>
      ) : (
        <>
          <DismissCircle16Regular css={classes.notRecommendedIcon} />
          <Typography variant="caption1">Would not recommend</Typography>
        </>
      )}
  </div>
);

RecommendationMessage.propTypes = {
  recommendSolution: PropTypes.oneOf(Object.values(recommendSolutionOptions)).isRequired,
};

export default RecommendationMessage;
