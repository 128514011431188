/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  propTypes,
} from 'redux-form';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer/DataCaptureFormContainer';
import FormLayout from 'components/Shared/FormLayout';
import CompanyTagFieldArray from 'components/Shared/CompanyTagFieldArray';
import TextFieldInput from 'components/UI/TextFieldInput';
import CloudHostingField from 'components/Shared/CloudHostingField';
import { required, validateTagsArrayLength } from 'lib/validation';
import section from './styles';

const MAX_ALLOWED_TAGS = 5;

class AccountPage extends Component {
  static propTypes = {
    onUpdateProfile: PropTypes.func.isRequired,
    ...propTypes,
  };

  saveCompanyData = (isDraft) => (body) => {
    const { onUpdateProfile } = this.props;

    onUpdateProfile(body, isDraft);
  };

  render() {
    const {
      handleSubmit,
      dirty,
      submitSucceeded,
      valid,
    } = this.props;

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <FormLayout
          buttonText="Submit"
          onSubmit={handleSubmit(this.saveCompanyData(false))}
          title="Account"
        >
          <div>
            <CloudHostingField css={section} />
            <Field
              sx={{ mb: 2 }}
              label="What are your key responsibilities in the business?"
              multiline
              rows="6"
              placeholder="Please provide a list of your key responsibilities"
              name="retailers.accountData.keyResponsibilities"
              validate={[required]}
              component={TextFieldInput}
            />
            <Field
              sx={{ mb: 2 }}
              label="Your team&apos;s top priorities for the next 12 months"
              multiline
              rows="6"
              placeholder="Please provide a list of your top priorities for the next 12 months"
              name="retailers.accountData.teamPriorities"
              validate={[required]}
              component={TextFieldInput}
            />
            <Field
              sx={{ mb: 2 }}
              label="Your company&apos;s top priorities for the next 12 months"
              multiline
              rows="6"
              placeholder="Please provide a list of your company's top priorities for the next 12 months"
              name="retailers.accountData.companyPriorities"
              validate={[required]}
              component={TextFieldInput}
            />
            <FieldArray
              name="tags"
              label={`Add tags (Max ${MAX_ALLOWED_TAGS} tags allowed)`}
              component={CompanyTagFieldArray}
              validate={[validateTagsArrayLength(0, MAX_ALLOWED_TAGS)]}
            />
          </div>
        </FormLayout>
      </DataCaptureFormContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { cloudHosting, retailers, tags } = state.user.company;

  return {
    initialValues: {
      tags,
      cloudHosting,
      retailers,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ enableReinitialize: true, form: 'accountPage' })(AccountPage),
);
