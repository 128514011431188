/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayPropTypes } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from 'components/Form/Checkbox';
import * as classes from './styles';

const SupportFields = ({
  className,
  fields,
  meta: { error, submitFailed },
}) => (
  <FormLabel className={className} component="fieldset" error={!!(submitFailed && error)}>
    <legend css={classes.legend}>
      What types of support do you offer your clients? Please check all that apply.
    </legend>
    <div css={classes.options}>
      {fields.map((field, index) => (
        <Field
          css={classes.option}
          key={field}
          type="checkbox"
          label={fields.get(index).name}
          name={`${field}.checked`}
          component={Checkbox}
        />
      ))}
    </div>
  </FormLabel>
);

SupportFields.propTypes = {
  className: PropTypes.string,
  ...fieldArrayPropTypes,
};

SupportFields.defaultProps = {
  className: null,
};

export default SupportFields;
