/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import SortButton from 'components/UI/SortButton';
import sortOrder from 'constants/sortOrder';
import insertHost from 'utils/picture';
import ReviewRequestType from 'types/ReviewRequest';
import ReviewRequestMessage from '../ReviewRequestMessage';
import * as classes from './styles';

const RequestReviewsTable = ({
  className,
  onFormatDate,
  onOpenReviewForm,
  requests,
}) => {
  const [order, setSortOrder] = useState(sortOrder.DESC);

  const handleToggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === sortOrder.DESC ? sortOrder.ASC : sortOrder.DESC));
  };

  const sortedRequests = requests.sort((a, b) => {
    const msA = Date.parse(a.createdAt);
    const msB = Date.parse(b.createdAt);

    return order === sortOrder.ASC ? msA - msB : msB - msA;
  });

  return (
    <table className={className} css={classes.table}>
      <thead>
      <tr>
        <Typography variant="caption1" component="th" css={[classes.th, classes.logoCell]}>
          Logo
        </Typography>
        <Typography variant="caption1" component="th" css={[classes.th, classes.vendorNameTd]}>
          Name
        </Typography>
        <Typography variant="caption1" component="th" css={[classes.message, classes.th]}>
          Message
        </Typography>
        <th css={[classes.creationDateTd, classes.th]}>
          <SortButton
            label="Date"
            onClick={handleToggleSortOrder}
          />
        </th>
      </tr>
      </thead>
      <tbody>
      {sortedRequests.map((request) => (
        <Fragment key={request.id}>
          <tr
            css={classes.row}
            onClick={() => onOpenReviewForm(request)}
          >
            <td css={[classes.logoCell, classes.td]}>
              {!!request.company.logo && (
                <img
                  src={insertHost(request.company.logo)}
                  alt={`${request.company.name} logo`}
                  css={classes.logo}
                />
              )}
            </td>
            <Typography variant="subtitle2" component="td" css={[classes.vendorNameTd, classes.td]}>
              {request.company.name}
            </Typography>
            <Typography variant="body2" component="td" css={classes.td}>
              <ReviewRequestMessage
                message={request.textMessage}
                companyName={request.company.name}
              />
            </Typography>
            <Typography variant="body2" component="td" css={[classes.creationDateTd, classes.td]}>
              {onFormatDate(request.createdAt)}
            </Typography>
          </tr>
        </Fragment>
      ))}
      </tbody>
    </table>
  );
};

RequestReviewsTable.propTypes = {
  className: PropTypes.string,
  onFormatDate: PropTypes.func.isRequired,
  onOpenReviewForm: PropTypes.func.isRequired,
  requests: PropTypes.arrayOf(ReviewRequestType.isRequired).isRequired,
};

RequestReviewsTable.defaultProps = {
  className: null,
};

export default RequestReviewsTable;
