import { css } from '@emotion/react';

export const errorContainer = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: ${theme.spacing(8)};
    font-family: ${theme.mainFont};
    text-align: center;
  `
);

export const top = (theme) => (
  css`
    ${theme.breakpoints.down('sm')} {
      width: 280px;
      margin-bottom: ${theme.spacing(2)};
    }

    ${theme.breakpoints.up('sm')} {
      width: 380px;
      margin-bottom: ${theme.spacing(6)};
    }
  `
);

export const logo = css`
  width: 100%;
  height: auto;
`;

export const title = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const subtitle = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
    font-size: 30px;
    font-weight: 700;
  `
);

export const getButtonStyles = (color) => css`
  background-color: ${color} !important;

  @media (hover: none) {
    &:focus,
    &:active {
      background-color: ${color} !important;
    }
  }
`;

export const support = (theme) => (
  css`
    margin-top: ${theme.spacing(2)};
  `
);
