import { css } from '@emotion/react';

export const challengeName = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
    padding-right: ${theme.spacing(3)};
  `
);

export const icon = (theme) => css`
  position: absolute;
  top: ${theme.spacing(0.5)};
  right: ${theme.spacing(0.5)};
  color: ${theme.palette.primary.main};
`;

export const card = (theme) => (
  css`
    position: relative;
    padding: ${theme.spacing(2)};
    border-radius: 4px;
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const row = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const splittedRow = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(2)};
  `
);

export const column = (theme) => (
  css`
    flex: 1;
    width: calc(50% - ${theme.spacing(1)});
  `
);

export const caption = (theme) => (
  css`
    margin-bottom: ${theme.spacing(0.5)};
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const milestoneDueDate = (theme) => (
  css`
    margin-right: ${theme.spacing(1)};
    color: ${theme.primaryPalette.menuGrey};
  `
);
