/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReviewVendorForm from 'components/ReviewVendorForm';
import dialogStyles from './styles';

const ReviewVendorModal = ({ isOpen, onClose, onSubmit }) => {
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      css={dialogStyles}
      fullScreen={isMobileDevice}
      maxWidth={false}
      onClose={onClose}
      open={isOpen}
    >
      <ReviewVendorForm onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  );
};

ReviewVendorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ReviewVendorModal;
