import APIClient from './client';

class ChallengeReviewerAPI extends APIClient {
  constructor() {
    super('/challenge-reviewers');
  }

  loadChallengeReviewers = (challengeId, companyId) => {
    const filter = { where: { challengeId, companyId }, include: 'user' };

    return this.find(filter);
  };
}

const challengeReviewerAPI = new ChallengeReviewerAPI();

export default challengeReviewerAPI;
