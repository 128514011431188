/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import challengeRequirementAPI from 'api/challengeRequirement';
import reasonChange from 'constants/reasonChangeAutocomplete';
import * as classes from './styles';

const DEBOUNCE_TIMEOUT = 500;
const MIN_INPUT_LENGTH = 2;

const filterOptions = createFilterOptions({
  trim: true,
  stringify: ({ description }) => description,
});

const RequirementDescriptionField = ({
  className,
  id,
  input: {
    value,
    onChange,
  },
  meta,
  addedQuestionTexts,
  onOpenModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const inputRef = useRef(null);

  const formatValue = (str) => str.toLowerCase().trim();

  const handleSearch = useMemo(() => (
    debounce(async (query, active, addedQuestions) => {
      try {
        if (!query) {
          setSuggestions([]);

          return;
        }

        const requirements = await challengeRequirementAPI.getRequirementsByName(query);

        // TODO: put this logic in MUI autosuggest filter
        const filteredRequirements = requirements.filter(((suggestedQuestion) => (
          addedQuestions.every((description) => suggestedQuestion.description !== description)
        )));

        if (active) {
          setSuggestions(filteredRequirements);
        }
      } catch (_) {
        enqueueSnackbar('Failed to search', { variant: 'error' });
      }
    }, DEBOUNCE_TIMEOUT)
  ), [enqueueSnackbar]);

  useEffect(() => {
    if (value.trim().length <= MIN_INPUT_LENGTH) {
      setOpen(false);
    }
  }, [value]);

  useEffect(() => {
    let active = true;

    const formattedValue = formatValue(value);

    if (inputRef.current === document.activeElement) {
      handleSearch(formattedValue, active, addedQuestionTexts);
    }

    return () => {
      active = false;
    };
  }, [handleSearch, value, enqueueSnackbar, addedQuestionTexts]);

  const handleSelectOption = (selectedOption) => {
    const { description } = selectedOption;
    const trimmedValue = description.trim();

    onChange(trimmedValue);
  };

  const handleChange = (_, newValue, reason) => {
    if (reason === reasonChange.SELECT_OPTION) {
      handleSelectOption(newValue);
    }
  };

  const renderOption = (props, option) => (
    <li {...props} css={classes.option}>
      <Typography variant="body2">
        {option.description}
      </Typography>
    </li>
  );

  const renderInput = (params) => (
    <TextField
      {...params}
      fullWidth
      inputRef={inputRef}
      color="primary"
      placeholder="Question"
      onChange={onChange}
    />
  );

  // eslint-disable-next-line react/prop-types
  const SuggestionsContainer = ({ children, ...props }) => (
    <Paper {...props}>
      {children}
      <div css={classes.popupFooter}>
        <Button css={classes.openModalButton} onClick={onOpenModal}>View all</Button>
      </div>
    </Paper>
  );

  return (
    <div className={className}>
      <Autocomplete
        id={id}
        options={suggestions}
        autoComplete
        forcePopupIcon={false}
        clearOnBlur={false}
        disableClearable
        onOpen={() => setOpen(true)}
        // Important: we need to close the popup with suggestions after a moment when a user clicks
        // on the button 'View all
        onClose={() => setTimeout(() => setOpen(false))}
        isOptionEqualToValue={(option, selectedOption) => (
          formatValue(option.description) === formatValue(selectedOption.description)
        )}
        open={isOpen}
        inputValue={value}
        getOptionLabel={(option) => option.description || ''}
        onChange={handleChange}
        onInputChange={onChange}
        renderInput={renderInput}
        renderOption={renderOption}
        filterOptions={filterOptions}
        noOptionsText={<Typography variant="body2">No options</Typography>}
        PaperComponent={SuggestionsContainer}
      />

      {!!(meta.error && meta.submitFailed) && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </div>
  );
};

RequirementDescriptionField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  addedQuestionTexts: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

RequirementDescriptionField.defaultProps = {
  className: null,
};

export default RequirementDescriptionField;
