import { generatePath } from 'react-router-dom';
import vendorDataCaptureFormTabs from 'constants/vendorDataCaptureFormTabs';
import routes from 'constants/routes';

export const VENDOR_REGISTRATION_STEPS = {
  BASIC_DETAILS: 1,
  SOLUTION: 2,
  TECHNICAL_DETAILS: 3,
  ENDORSEMENTS: 4,
  COMPANY: 5,
  GROWTH_STAFF: 6,
};

export const VENDOR_REGISTRATION_STEPS_DATA = {
  BASIC_DETAILS: {
    title: 'Basic details',
    path: vendorDataCaptureFormTabs.BASIC_DETAILS,
    stepNumber: VENDOR_REGISTRATION_STEPS.BASIC_DETAILS,
  },
  SOLUTION: {
    title: 'Solution',
    path: vendorDataCaptureFormTabs.SOLUTION,
    stepNumber: VENDOR_REGISTRATION_STEPS.SOLUTION,
  },
  TECHNICAL_DETAILS: {
    title: 'Technical details',
    path: vendorDataCaptureFormTabs.TECHNICAL_DETAILS,
    stepNumber: VENDOR_REGISTRATION_STEPS.TECHNICAL_DETAILS,
  },
  ENDORSEMENTS: {
    title: 'Endorsements',
    path: vendorDataCaptureFormTabs.ENDORSEMENTS,
    stepNumber: VENDOR_REGISTRATION_STEPS.ENDORSEMENTS,
  },
  COMPANY: {
    title: 'Company',
    path: vendorDataCaptureFormTabs.COMPANY,
    stepNumber: VENDOR_REGISTRATION_STEPS.COMPANY,
  },
  GROWTH_STAFF: {
    title: 'Growth & staff',
    path: vendorDataCaptureFormTabs.GROWTH_STAFF,
    stepNumber: VENDOR_REGISTRATION_STEPS.GROWTH_STAFF,
  },
};

export const profileMenuList = Object.values(VENDOR_REGISTRATION_STEPS_DATA)
  .map(({ title, path }) => ({
    title,
    to: generatePath(routes.EDIT_VENDOR_DETAILS, { activeTab: path }),
  }));
