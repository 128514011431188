import { formatDistance, parseISO } from 'date-fns';
import { isExpired } from './challengeCheckers';
import formatDate from './formatDate';

const DATE_FORMAT = 'dd/MM/yyyy';

const formatChallengeDeadline = (challenge) => {
  const { response_deadline: deadline } = challenge;

  if (isExpired(challenge)) {
    return `${formatDistance(new Date(), parseISO(deadline))} ago`;
  }

  return formatDate(deadline, DATE_FORMAT);
};

export default formatChallengeDeadline;
