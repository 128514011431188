/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Send from '@mui/icons-material/Send';

import routes from 'constants/routes';
import { requestApprove } from 'store/thunk/company';
import ManageProfileButton from 'components/Shared/ManageProfileButton';
import ShareProfile from 'components/Shared/ShareProfile';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import CompanyType from 'types/Company';
import { VENDOR_REGISTRATION_STEPS_DATA } from '../constants';
import VendorRegistrationBanner from '../VendorRegistrationBanner';
import * as classes from './styles';

const FormHeader = ({
  enqueueSnackbar,
  activeTab,
  className,
  company,
  onRequestApprove,
}) => {
  const currentPageUrl = generatePath(routes.EDIT_VENDOR_DETAILS, { activeTab });
  const currentStep = Object.values(VENDOR_REGISTRATION_STEPS_DATA)
    .find(({ path }) => path === activeTab);

  const profileUrl = generatePath(
    routes.VENDOR_PROFILE_TAB,
    { slug: company.slug, tab: VENDOR_INFO_TABS.BASIC_DETAILS.path },
  );

  const sendCompanyApprovalRequest = useCallback(
    () => onRequestApprove()
      .then(() => enqueueSnackbar('Success', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Failed to send request', { variant: 'error' })),
    [enqueueSnackbar, onRequestApprove],
  );

  return (
    <>
      <div className={className}>
        <Breadcrumbs sx={{ mb: 2 }}>
          <Link to={routes.MAIN}>Home</Link>
          <Link to={profileUrl}>Company</Link>
          <Link to={currentPageUrl}>{currentStep ? currentStep.title : 'Unknown'}</Link>
        </Breadcrumbs>

        <div css={classes.container}>
          <Typography css={classes.title} variant="h1">{company.name}</Typography>
          <div css={classes.buttons}>
            {company.vendors.areBasicDetailsSaved && !company.userRequestApprove && (
              <ManageProfileButton icon={Send} onClick={sendCompanyApprovalRequest} title="Submit profile" />
            )}
            <ShareProfile disabled={!company.adminApprove} companyId={company.id} />
          </div>
        </div>
      </div>

      {!company.adminApprove && (
        <VendorRegistrationBanner isApprovalRequestSent={company.userRequestApprove} />
      )}
    </>
  );
};

FormHeader.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  className: PropTypes.string,
  company: PropTypes.shape(CompanyType).isRequired,
  activeTab: PropTypes.string.isRequired,
  onRequestApprove: PropTypes.func.isRequired,
};

FormHeader.defaultProps = {
  className: null,
  isApproved: false,
};

export default connect(
  (state) => ({ company: state.user.company }),
  { onRequestApprove: requestApprove },
)(withSnackbar(FormHeader));
