import PropTypes from 'prop-types';

const FieldArrayFieldsType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  forEach: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  map: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  pop: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  shift: PropTypes.func.isRequired,
  swap: PropTypes.func.isRequired,
  unshift: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
});

export default FieldArrayFieldsType;
