/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import ChallengeRequirementType from 'types/ChallengeRequirement';
import requirementStyles from './styles';

const SuggestedRequirementsList = ({
  className,
  onToggle,
  onCheckIsSelected,
  requirements,
}) => (
  requirements.length ? (
    <div className={className}>
      {requirements.map((requirement) => (
         <FormControlLabel
          key={requirement.id}
          css={requirementStyles}
          label={requirement.description}
          control={(
            <Checkbox
              checked={onCheckIsSelected(requirement)}
              onChange={onToggle(requirement)}
            />
          )}
        />
      ))}
    </div>
  ) : (
    <Typography variant="body2">No requirements matched your search</Typography>
  )
);

SuggestedRequirementsList.propTypes = {
  className: PropTypes.string,
  onCheckIsSelected: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  requirements: PropTypes.arrayOf(ChallengeRequirementType.isRequired).isRequired,
};

SuggestedRequirementsList.defaultProps = {
  className: null,
};

export default SuggestedRequirementsList;
