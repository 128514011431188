const validateNewPasswordConfirmation = ({ newPassword, confirmPassword }) => {
  const errors = {};

  if (!!newPassword && !!confirmPassword && newPassword !== confirmPassword) {
    errors.confirmPassword = 'Password and confirm password must match';
  }

  return errors;
};

export default validateNewPasswordConfirmation;
