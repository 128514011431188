/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Header from 'components/Header';
import PartnerBanner from '../PartnerBanner';
import FooterLinks from '../FooterLinks';
import * as classes from './styles';

const AuthPageLayout = ({ children }) => (
  <div css={classes.wrapper}>
    <Header />
    <main css={classes.main}>
      {children}
    </main>
    <footer css={classes.footer}>
      <PartnerBanner css={classes.partners} />
      <FooterLinks css={classes.links} />
    </footer>
  </div>
);

AuthPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthPageLayout;
