import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import insertHost from 'utils/picture';
import { ArticleType, CaseStudyType } from 'types/Endorsements';
import './SavedEndorsement.scss';

const SavedEndorsement = ({ className, document, showLink }) => (
  <div className={clsx('saved-endorsement', className)}>
    {!!document.picture && (
      <div className="saved-endorsement__logo-container">
        <img className="small-logo saved-endorsement__logo" src={insertHost(document.picture)} alt="" />
      </div>
    )}
    <div className="saved-endorsement__content">
      <Typography className="saved-endorsement__title" variant="subtitle2">
        {document.title || document.topic}
      </Typography>
      <Typography className="saved-endorsement__body" variant="body1">
        {document.text}
      </Typography>
      {showLink && !!document.link && (
        <a
          className="saved-endorsement__link link"
          href={document.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </a>
      )}
    </div>
  </div>
);

SavedEndorsement.propTypes = {
  className: PropTypes.string,
  document: PropTypes.oneOfType([CaseStudyType.isRequired, ArticleType.isRequired]).isRequired,
  showLink: PropTypes.bool.isRequired,
};

SavedEndorsement.defaultProps = {
  className: null,
};

export default SavedEndorsement;
