/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import Chip from 'components/UI/Chip';
import SavedMilestones from 'components/Challenge/SavedMilestones';
import { groupQuestionsByRequirements } from 'utils/prepareChallengeYesNoQuestions';
import formatChallengeReviewer from 'utils/formatChallengeReviewer';
import questionChallengeOptions from 'constants/questionChallengeOptions';
import * as classes from './styles';

const ChallengePrivateDetails = ({ className }) => {
  const {
    state:
      {
        challenge: {
          yesNoQuestions,
          openEndedQuestions,
          whitelistVendors,
          blacklistVendors,
          metadata,
        },
        reviewers,
        milestones,
      },
  } = useContext(ChallengeContext);

  const [mustHaveQuestions, niceToHaveQuestions] = groupQuestionsByRequirements(yesNoQuestions);

  return (
    <div css={classes.wrap} className={className}>
      {!!(yesNoQuestions && yesNoQuestions.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Specific requirements that need a Yes or No answer
          </Typography>
          {!!mustHaveQuestions.length && (
            <div css={classes.questionsWrap}>
              <h5 css={classes.yesNoSection} variant="subtitle2">{questionChallengeOptions.MUST_HAVE}</h5>
              {mustHaveQuestions.map((question, i) => (
                <Typography key={question.id} variant="body1">
                  {`${i + 1}. ${question.requirement.description}`}
                </Typography>
              ))}
            </div>
          )}

          {!!niceToHaveQuestions.length && (
            <div css={classes.questionsWrap}>
              <h5 css={classes.yesNoSection} variant="subtitle2">{questionChallengeOptions.NICE_TO_HAVE}</h5>
              {niceToHaveQuestions.map((question, i) => (
                <Typography key={question.id} variant="body1">
                  {`${i + 1}. ${question.requirement.description}`}
                </Typography>
              ))}
            </div>
          )}
        </div>
      )}
      {!!(openEndedQuestions && openEndedQuestions.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Other additional questions
          </Typography>
          {openEndedQuestions.map((openEndedQuestion) => (
            <Typography key={openEndedQuestion.question} variant="body1">
              {openEndedQuestion.question}
            </Typography>
          ))}
        </div>
      )}
      {!!(whitelistVendors && whitelistVendors.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Included vendors
          </Typography>
          {whitelistVendors.map((whitelistVendor) => (
            <Typography key={whitelistVendor} variant="body1">
              {whitelistVendor}
            </Typography>
          ))}
        </div>
      )}
      {!!(blacklistVendors && blacklistVendors.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Excluded vendors
          </Typography>
          {blacklistVendors.map((blacklistVendor) => (
            <Typography key={blacklistVendor} variant="body1">
              {blacklistVendor}
            </Typography>
          ))}
        </div>
      )}
      {!!(reviewers && reviewers.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Users to review this challenge
          </Typography>
          {reviewers.map((reviewer) => (
            <Typography key={reviewer.id} variant="body1">
              {formatChallengeReviewer(reviewer)}
            </Typography>
          ))}
        </div>
      )}
      {!!(metadata && metadata.length) && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Tags
          </Typography>
          <ul>
            {metadata.map((metaItem) => (
              <Chip
                key={metaItem}
                css={classes.tag}
                component="li"
                label={metaItem}
                color="primary"
              />
            ))}
          </ul>
        </div>
      )}
      {!!milestones.length && (
        <div css={classes.section}>
          <Typography css={classes.subtitle} variant="subtitle1">
            Milestones
          </Typography>
          <SavedMilestones milestones={milestones} />
        </div>
      )}
    </div>
  );
};

ChallengePrivateDetails.propTypes = {
  className: PropTypes.string,
};

ChallengePrivateDetails.defaultProps = {
  className: null,
};

export default ChallengePrivateDetails;
