import PropTypes from 'prop-types';
import { RatingType } from 'types/Review';

export const VendorCompanyInfoType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  ratingsReviews: PropTypes.arrayOf(
    PropTypes.shape({
      ratings: PropTypes.arrayOf(RatingType.isRequired).isRequired,
    }).isRequired,
  ).isRequired,
});

export const ChallengeResponseType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  demonstrationVideo: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool.isRequired,
      questionId: PropTypes.string.isRequired,
    }).isRequired,
  ),
  company: VendorCompanyInfoType.isRequired,
});
