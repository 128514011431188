import Cookies from 'js-cookie';
import { authCookies } from 'constants/storageKeys';
import contentTypes from 'constants/contentTypes';
import axiosInstance from './axios';

class APIClient {
  constructor(endpoint) {
    this.client = axiosInstance;
    this.cookies = Cookies;
    this.endpoint = endpoint;
  }

  getHeaders(contentType = contentTypes.APPLICATION_JSON, isAuthRequired = true) {
    const headers = isAuthRequired
      ? { 'Content-Type': contentType, Authorization: this.cookies.get(authCookies.TOKEN) }
      : { 'Content-Type': contentType };

    return { headers };
  }

  create = (body = {}) => this.client.post(this.endpoint, body, this.getHeaders());

  put(body) {
    return this.client.put(this.endpoint, body, this.getHeaders());
  }

  patch(body) {
    return this.client.patch(this.endpoint, body, this.getHeaders());
  }

  updateById(id, body) {
    return this.client.patch(`${this.endpoint}/${id}`, body, this.getHeaders());
  }

  count(filter = {}) {
    return this.client.get(
      `${this.endpoint}/count`,
      { params: { where: filter }, ...this.getHeaders() },
    );
  }

  findById(id, filter = {}) {
    return this.client.get(`${this.endpoint}/${id}`, { params: { filter }, ...this.getHeaders() });
  }

  find(filter = {}) {
    return this.client.get(this.endpoint, { params: { filter }, ...this.getHeaders() });
  }

  findOne(filter = {}) {
    return this.client.get(
      `${this.endpoint}/findOne`,
      { params: { filter }, ...this.getHeaders() },
    );
  }

  deleteById(id) {
    return this.client.delete(`${this.endpoint}/${id}`, this.getHeaders());
  }

  updateAll(body, filter) {
    return this.client.post(`${this.endpoint}/update`, body,
      { params: { where: filter }, ...this.getHeaders() });
  }

  replaceOrCreateById(body = {}) {
    return this.client.post(this.endpoint, { params: { body }, ...this.getHeaders() });
  }

  replaceById(id, body) {
    return this.client.post(`${this.endpoint}/${id}`, body, this.getHeaders());
  }

  upsertWithWhere(filter, body) {
    return this.client.post(`${this.endpoint}/upsertWithWhere`, body,
      { params: { where: filter }, ...this.getHeaders() });
  }

  postRequest(path, body = {}, headers = {}) {
    const { headers: defaultHeaders } = this.getHeaders();

    return this.client.post(`${this.endpoint}${path}`, body,
      { headers: { ...defaultHeaders, ...headers } });
  }

  getRequest(path, filter = {}, query) {
    return this.client.get(`${this.endpoint}${path}`,
      { params: { filter, ...query }, ...this.getHeaders() });
  }

  patchRequest(path, body = {}) {
    return this.client.patch(`${this.endpoint}${path}`, body, this.getHeaders());
  }

  putRequest(path, body = {}) {
    return this.client.put(`${this.endpoint}${path}`, body, this.getHeaders());
  }

  deleteRequest(path) {
    return this.client.delete(`${this.endpoint}${path}`, this.getHeaders());
  }

  postFile(path, body, query) {
    return this.client.post(
      `${this.endpoint}${path}`,
      body,
      {
        params: query,
        ...this.getHeaders(contentTypes.X_WWW_FORM_URL_ENCODED),
      },
    );
  }
}

export default APIClient;
