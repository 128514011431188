import APIClient from './client';

class ChallengeResponseAPI extends APIClient {
  constructor() {
    super('/challengeResponses');
  }

  getChallengeResponseByChallengeId = (challengeId, companyId) => {
    const filter = { where: { challengeId, companyId } };

    return this.findOne(filter);
  };

  postChallengeResponse = (body) => this.postRequest('/save-vendor-response', body);

  requestResponseEdit = (id) => this.postRequest(`/${id}/request-revision`);

  getChallengeResponseForRetailer = (companyId, challengeId) => (
    this.findOne({ where: { companyId, challengeId } })
  );

  endChallengeParticipation = (challengeId) => (
    this.postRequest(`/${challengeId}/end-participation`)
  );

  requestChallengeParticipation = (challengeId) => (
    this.postRequest(`/${challengeId}/request-participation`)
  );
}

const challengeResponseAPI = new ChallengeResponseAPI();

export default challengeResponseAPI;
