import { css } from '@emotion/react';

export const savedArticles = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(4)};
    }
  `
);

export const submit = css`
  text-align: center;
`;

export const imageUpload = (theme) => (
  css`
    max-width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);
