/** @jsxImportSource @emotion/react */
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';

import eventTopicPriorityListAPI from 'api/eventTopicPriorityList';
import eventTopicNotesAPI from 'api/eventTopicNotes';
import routes from 'constants/routes';
import EventTopicType from 'types/EventTopic';
import EventType from 'types/Event';
import EventTopicNoteType from 'types/EventTopicNote';
import EventPriorityListType from 'types/EventPriorityList';
import EventPriorityList from '../EventPriorityList';
import TopicCard from '../TopicCard';
import list from './styles';

const DEBOUNCE_TIMEOUT = 800;

const ManageTopicPriorities = ({
  event,
  topics,
  priorityList: initialPriorityList,
  notes: initialNotes,
  orderedTopics,
  isOnboarding,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const initialPriorityListId = initialPriorityList ? initialPriorityList.id : null;

  const [priorityListId, setPriorityListId] = useState(initialPriorityListId);
  const [notes, setNotes] = useState(initialNotes);

  const generateSlots = () => {
    const emptySlotsCount = topics.length - orderedTopics.length;

    if (emptySlotsCount > 0) {
      const emptySlots = Array
        .from({ length: emptySlotsCount })
        .map((_, i) => ({ id: i }));

      return [...orderedTopics, ...emptySlots];
    }

    return orderedTopics;
  };

  const getUnselectedTopics = () => {
    if (orderedTopics.length) {
      const topicsIdList = initialPriorityList.priorities;

      return topics.filter((topic) => !topicsIdList.includes(topic.id));
    }

    return topics;
  };

  const [slots, setSlots] = useState(generateSlots());
  const [unorderedTopics, setUnorderedTopics] = useState(getUnselectedTopics());

  const [selectedSlot, selectSlot] = useState(null);

  const handleError = useCallback(() => {
    enqueueSnackbar('Failed to update priorities', { variant: 'error' });
  }, [enqueueSnackbar]);

  const handleCreateNote = async ({ id: topicId, comment }) => {
    const body = { listId: priorityListId, topicId, comment };

    const { data } = await eventTopicNotesAPI.upsertWithWhere(
      { listId: priorityListId, topicId },
      body,
    );

    setNotes((prevNotes) => [...prevNotes, data]);
  };

  const handleUpdateNote = async ({ id: submittedTopicId, comment }, { id: initialTopicId }) => {
    const noteToUpdate = notes.find((note) => note.topicId === initialTopicId);

    if (noteToUpdate) {
      const body = { ...noteToUpdate, topicId: submittedTopicId, comment };

      const { data: updatedNote } = await eventTopicNotesAPI.put(body);

      setNotes((prevNotes) => prevNotes.map((note) => (
        note.id === updatedNote.id ? updatedNote : note
      )));
    }
  };

  const handleDeleteNote = async ({ id: topicId }) => {
    const noteToDelete = notes.find((note) => note.topicId === topicId);

    if (!noteToDelete) {
      return;
    }

    const { id: noteToDeleteId } = noteToDelete;

    await eventTopicNotesAPI.deleteById(noteToDeleteId);

    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteToDeleteId));
  };

  const handleSaveNote = async (submittedValues, initialValues) => {
    try {
      const isNewTopicWithNoteChosen = !initialValues && submittedValues.comment;
      const isNoteAddedToChosenTopic = (
        initialValues
        && initialValues.id
        && !initialValues.comment && submittedValues.comment
      );
      const isNoteRemoved = initialValues && initialValues.comment && !submittedValues.comment;
      const isNoteAssignedToOtherTopic = initialValues && initialValues.id !== submittedValues.id;
      const isNoteEdited = initialValues && initialValues.comment !== submittedValues.comment;

      if (isNewTopicWithNoteChosen || isNoteAddedToChosenTopic) {
        await handleCreateNote(submittedValues);
      } else if (isNoteRemoved) {
        await handleDeleteNote(submittedValues);
      } else if (isNoteAssignedToOtherTopic || isNoteEdited) {
        await handleUpdateNote(submittedValues, initialValues);
      }
    } catch (_) {
      handleError();
    }
  };

  const handleFillSlot = (submittedValues, initialValues) => {
    const { id: submittedTopicId } = submittedValues;

    const unorderedTopic = unorderedTopics.find(({ id }) => id === submittedTopicId);
    const isTopicInSlotChanged = !!unorderedTopic;

    if (isTopicInSlotChanged) {
      const slotToSelect = slots.find((_, index) => index === selectedSlot);
      const isSlotFilled = !!slotToSelect && typeof slotToSelect.id === 'string';

      setUnorderedTopics((prevList) => {
        const filteredTopics = prevList.filter(({ id }) => id !== submittedTopicId);

        if (isSlotFilled) {
          const newUnorderedTopicList = [...filteredTopics, slotToSelect];

          return orderBy(newUnorderedTopicList, 'name', 'asc');
        }

        return filteredTopics;
      });

      setSlots((prevSlots) => (
        prevSlots.map((prev, index) => (index === selectedSlot ? unorderedTopic : prev))
      ));
    }

    handleSaveNote(submittedValues, initialValues);
  };

  const handleSavePriorityList = useCallback(async (submittedSlots, id) => {
    try {
      /**
       * Unselected slots have ids with type Number while selected slots have ids with type
       * String
       */
      const filledSlots = submittedSlots.filter((slot) => typeof slot.id === 'string');
      const prioritiesList = filledSlots.map(({ id: slotId }) => slotId);

      const body = { eventId: event.id, priorities: prioritiesList };

      const prioritiesListBody = id ? { id, ...body } : body;

      const { data } = await eventTopicPriorityListAPI.put(prioritiesListBody);

      setPriorityListId(data.id);
    } catch (_) {
      handleError();
    }
  }, [event.id, handleError]);

  const debouncedSave = useMemo(() => (
    debounce(handleSavePriorityList, DEBOUNCE_TIMEOUT)
  ), [handleSavePriorityList]);

  const handleRemovePriority = async (topic) => {
    const updatedSlots = slots.map((slot, i) => {
      if (slot.id === topic.id) {
        return { id: i };
      }

      return slot;
    });

    // Filter is added in order to prevent ids' confusing
    const filteredSlots = updatedSlots
      .map((slot, index) => ((!slot.name) ? { id: index } : slot));

    setSlots(filteredSlots);

    setUnorderedTopics((prevList) => {
      const newUnorderedTopicList = [...prevList, topic];

      return orderBy(newUnorderedTopicList, 'name', 'asc');
    });

    await handleSavePriorityList(updatedSlots, priorityListId);
    await handleDeleteNote(topic);
  };

  useEffect(() => {
    const isListFilled = slots.some((slot) => typeof slot.id === 'string');

    if (!priorityListId || isListFilled) {
      debouncedSave(slots, priorityListId);
    }
  }, [slots, priorityListId, debouncedSave]);

  const handleFinishOnboarding = () => {
    handleSavePriorityList(slots, priorityListId);

    const [slug] = event.slugs;
    const eventDetailsPage = generatePath(routes.RETAILER_EVENT_DETAILS, { slug });
    history.push(eventDetailsPage);
  };

  return (
    <div>
      <EventPriorityList
        css={list}
        slots={slots}
        onSetSlots={setSlots}
        renderSlot={(slot, index) => {
          const note = notes.find(({ topicId }) => slot.id === topicId);

          return (
            <TopicCard
              topic={slot}
              note={note ? note.comment : null}
              onSelectSlot={() => selectSlot(index)}
              onCancel={() => selectSlot(null)}
              topics={
                typeof selectedSlot === 'number'
                && slots[selectedSlot]
                && typeof slots[selectedSlot].id === 'string'
                  ? [...new Set([...unorderedTopics, slots[selectedSlot]])] : unorderedTopics
              }
              onSave={handleFillSlot}
              onRemove={handleRemovePriority}
              slots={slots}
              isSlotSelected={typeof selectedSlot === 'number' && selectedSlot === index}
            />
          );
        }}
      />
      {isOnboarding && <Button onClick={handleFinishOnboarding} sx={{ mt: 4 }}>Continue</Button>}
    </div>
  );
};

ManageTopicPriorities.propTypes = {
  event: EventType.isRequired,
  topics: PropTypes.arrayOf(EventTopicType.isRequired).isRequired,
  orderedTopics: PropTypes.arrayOf(EventTopicType.isRequired),
  priorityList: EventPriorityListType,
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired).isRequired,
  isOnboarding: PropTypes.bool.isRequired,
};

ManageTopicPriorities.defaultProps = {
  priorityList: null,
  orderedTopics: [],
};

export default ManageTopicPriorities;
