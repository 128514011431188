const notificationColors = {
  INFO: '#e8f9ff',
  ERROR: '#fff3f3',
  SUCCESS: '#faffcc',
  WARNING: '#fff5c8',
};

const SVG_ICON_SIZE = 16;

const sx = (theme) => ({
  '& .SnackbarItem-message': {
    fontFamily: `${theme.mainFont} !important`,
  },
  '& .SnackbarItem-message svg': {
    position: 'relation',
    width: SVG_ICON_SIZE,
    height: SVG_ICON_SIZE,
    marginRight: theme.spacing(1),
  },
  '& .SnackbarContent-root': {
    borderRadius: '4px !important',
    color: `${theme.primaryPalette.grey} !important`,
    boxShadow: 'none',
  },
  '& .SnackbarItem-variantSuccess': {
    backgroundColor: `${notificationColors.SUCCESS} !important`,

    '.MuiSvgIcon-root': {
      color: theme.primaryPalette.primaryYellow,
    },
  },
  '& .SnackbarItem-variantError': {
    backgroundColor: `${notificationColors.ERROR} !important`,

    '.MuiSvgIcon-root': {
      color: theme.primaryPalette.errorColor,
    },
  },
  '& .SnackbarItem-variantInfo': {
    backgroundColor: `${notificationColors.INFO} !important`,

    '.MuiSvgIcon-root': {
      color: '#36b5dd',
    },
  },
  '& .SnackbarItem-variantWarning': {
    backgroundColor: `${notificationColors.WARNING} !important`,

    '.MuiSvgIcon-root': {
      color: '#ffb800',
    },
  },
});

export default sx;
