/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PageBody from 'components/UI/PageBody';
import BottomBar from 'components/UI/BottomBar';
import routes from 'constants/routes';
import getRetailerDisplayedChallengeStatus from 'utils/getDisplayedRetailerChallengeStatus';
import DashboardChallengeType from 'types/DashboardChallengeType';
import AllChallengesContent from '../AllChallengesContent';
import DashboardEventType from '../dashboardEventType';
import * as classes from './styles';

const AllChallengesDashboard = ({ challenges, events }) => {
  const challengesWithDisplayedStatus = challenges.map((challenge) => ({
    ...challenge,
    displayedStatus: getRetailerDisplayedChallengeStatus(challenge),
  }));

  return (
    <>
      <PageBody css={classes.pageBody}>
        <div css={classes.content}>
          <Typography variant="h1">RFIs</Typography>
          <Button
            css={classes.createChallengeLink}
            component={Link}
            to={routes.CREATE_NEW_CHALLENGE}
          >
            Create new RFI
          </Button>
        </div>

        <AllChallengesContent challenges={challengesWithDisplayedStatus} events={events} />
      </PageBody>

      <BottomBar
        css={classes.bottomBar}
        buttonText="Create new RFI"
        component={Link}
        to={routes.CREATE_NEW_CHALLENGE}
      />
    </>
  );
};

AllChallengesDashboard.propTypes = {
  challenges: PropTypes.arrayOf(DashboardChallengeType.isRequired).isRequired,
  events: PropTypes.arrayOf(DashboardEventType.isRequired).isRequired,
};

export default AllChallengesDashboard;
