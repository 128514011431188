/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import compact from 'lodash/compact';
import mapValues from 'lodash/mapValues';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
  propTypes,
} from 'redux-form';
import Typography from '@mui/material/Typography';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';
import documentationType from 'constants/documentationType';
import changeVideoLink from 'utils/video';
import RadioButtonYesNo from 'components/UI/RadioButtonYesNo';
import TextFieldInput from 'components/UI/TextFieldInput';
import CustomFieldArray from 'components/UI/CustomFieldArray';
import SubmitButton from 'components/UI/SubmitButton';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import AvatarField from 'components/Shared/AvatarField';
import { maxLength15, maxLength10, validateFieldArrayLength } from 'lib/validation';
import VideoPlayer from 'components/UI/VideoPlayer';
import getInitialFormArrayValue from 'utils/getInitialFieldArrayValue';
import { ProductServiceType } from 'types/Vendor';
import CompanyDocumentation from '../../CompanyDocumentation';
import {
  PRICING_FIELDS_RESTRICTIONS,
  PRODUCT_FEATURES_FIELDS_RESTRICTIONS,
  FUTURE_FEATURES_FIELDS_RESTRICTIONS,
} from './constants';
import * as classes from './styles';

const MAX_UPLOAD_PRODUCT_DOCUMENTATION = 2;

class Solution extends Component {
  componentDidMount() {
    this.normalizeShowcaseProductField();
  }

  // TODO: add logic in validator that will enforce to specify either screenshot or link
  // depending on chosen option in `showcaseProduct` field.
  normalizeShowcaseProductField = () => {
    const {
      changeFieldValue,
      initialValues: { showcaseProduct, websiteScreenshot, videoLink },
    } = this.props;

    if (videoLink && (!showcaseProduct || showcaseProduct !== 'no')) {
      changeFieldValue('showcaseProduct', 'yes');
    }

    if (websiteScreenshot && (videoLink === '' || !videoLink) && (!showcaseProduct || showcaseProduct === '')) {
      changeFieldValue('showcaseProduct', 'no');
    }

    if (!websiteScreenshot && !videoLink) {
      changeFieldValue('showcaseProduct', '');
    }
  };

  saveCompanyData = (isDraft) => (values) => {
    const { onUpdateProfile, productService } = this.props;
    const cleanedProductServiceFields = mapValues(values, (value) => (
      Array.isArray(value) ? compact(value) : value
    ));

    const updatedData = {
      vendors: {
        productService: {
          ...productService,
          ...cleanedProductServiceFields,
        },
      },
    };

    return onUpdateProfile(updatedData, isDraft);
  };

  render() {
    const {
      dirty,
      youtubeLinkFormValue,
      handleSubmit,
      showcaseProductValue = '',
      userId,
      valid,
      submitSucceeded,
      submitting,
    } = this.props;

    const videoLink = changeVideoLink(youtubeLinkFormValue);

    return (
      <DataCaptureFormContainer
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <div css={classes.section}>
          <Field
            css={classes.field}
            title="A product demo video will become mandatory for the platform in due course. If you don’t have an existing one it’s easy to record a short product demo on Zoom or other tools like Google Hangouts and convert it to a YouTube or Vimeo link"
            name="showcaseProduct"
            component={RadioButtonYesNo}
          />
          {showcaseProductValue === yesNoOptions.YES && (
            <>
              <Field
                sx={{ mb: 2 }}
                placeholder="https://"
                name="videoLink"
                label="Please provide a YouTube or Vimeo link:"
                component={TextFieldInput}
              />
              {videoLink && <VideoPlayer url={videoLink} css={classes.video} />}
            </>
          )}
          {showcaseProductValue === yesNoOptions.NO && (
            <Field
              component={AvatarField}
              userId={userId}
              model="company"
              fileNamePrefix="websiteScreenshot"
              name="websiteScreenshot"
            />
          )}
        </div>

        <div css={classes.section}>
          <Typography sx={{ mb: 3 }} variant="h2">
            Product documentation
          </Typography>
          <CompanyDocumentation
            descriptionPlaceholder="Description (10 words maximum)"
            descriptionValidator={maxLength10}
            maxFiles={MAX_UPLOAD_PRODUCT_DOCUMENTATION}
            title="You can upload up to two documentation files"
            type={documentationType.PRODUCT}
          />
        </div>

        <div css={classes.section}>
          <CustomFieldArray
            css={classes.field}
            label="Main methods of pricing for your solution"
            minItems={PRICING_FIELDS_RESTRICTIONS.MIN}
            maxItems={PRICING_FIELDS_RESTRICTIONS.MAX}
            arrayValidators={[validateFieldArrayLength(
              PRICING_FIELDS_RESTRICTIONS.MIN, PRICING_FIELDS_RESTRICTIONS.MAX,
            )]}
            name="pricing"
          />
          <CustomFieldArray
            css={classes.field}
            label="Product Features"
            minItems={PRODUCT_FEATURES_FIELDS_RESTRICTIONS.MIN}
            maxItems={PRODUCT_FEATURES_FIELDS_RESTRICTIONS.MAX}
            fieldValidators={[maxLength15]}
            arrayValidators={[validateFieldArrayLength(
              PRODUCT_FEATURES_FIELDS_RESTRICTIONS.MIN,
              PRODUCT_FEATURES_FIELDS_RESTRICTIONS.MAX,
            )]}
            name="features"
            buttonText="Add feature"
          />
          <Field
            css={classes.field}
            label="Video demonstration URL (YouTube/Vimeo)"
            name="productDemoVideo"
            type="text"
            component={TextFieldInput}
          />
          <CustomFieldArray
            minItems={FUTURE_FEATURES_FIELDS_RESTRICTIONS.MIN}
            maxItems={FUTURE_FEATURES_FIELDS_RESTRICTIONS.MAX}
            label="Future Product Features"
            fieldValidators={[maxLength15]}
            arrayValidators={[validateFieldArrayLength(
              FUTURE_FEATURES_FIELDS_RESTRICTIONS.MIN,
              FUTURE_FEATURES_FIELDS_RESTRICTIONS.MAX,
            )]}
            name="roadmap"
          />
        </div>
        <SubmitButton
          isLoading={submitting}
          onClick={handleSubmit(this.saveCompanyData(false))}
        >
          Save
        </SubmitButton>
      </DataCaptureFormContainer>
    );
  }
}

Solution.propTypes = {
  youtubeLinkFormValue: PropTypes.string,
  userId: PropTypes.string.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  showcaseProductValue: PropTypes.string,
  onUpdateProfile: PropTypes.func.isRequired,
  productService: ProductServiceType.isRequired,
  ...propTypes,
};

Solution.defaultProps = {
  showcaseProductValue: '',
  youtubeLinkFormValue: '',
};

const selector = formValueSelector('solutionTab');

const mapStateToProps = (state) => {
  const showcaseProductValue = selector(state, 'showcaseProduct');
  const youtubeLinkFormValue = selector(state, 'videoLink');

  const { productService = {} } = state.user.company.vendors;

  const {
    showcaseProduct,
    websiteScreenshot,
    pricing,
    features,
    productDemoVideo,
    roadmap,
    videoLink,
  } = productService;

  return {
    userId: state.user.authUser.id,
    youtubeLinkFormValue,
    showcaseProductValue,
    productService,
    initialValues: {
      showcaseProduct,
      websiteScreenshot,
      pricing: getInitialFormArrayValue(pricing),
      features: getInitialFormArrayValue(features),
      productDemoVideo,
      roadmap: getInitialFormArrayValue(roadmap),
      videoLink,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change('solutionTab', field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  enableReinitialize: true,
  form: 'solutionTab',
})(Solution));
