/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import wrap from './styles';

const colors = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

// MUI chip doesn't have end adornment. That's why this wrapper was created.
const CustomChip = ({
  color,
  clickable,
  label,
  ...rest
}) => (
  <Chip
    label={(
      <span css={wrap}>
        {label}
        {color === colors.PRIMARY && <Check sx={{ ml: 1 }} fontSize="small" />}
      </span>
    )}
    color={color}
    clickable={clickable}
    {...rest}
  />
);

CustomChip.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)).isRequired,
  clickable: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

CustomChip.defaultProps = {
  clickable: false,
};

export default CustomChip;
