/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/UI/TextFieldInput';
import DatePicker from 'components/UI/DatePicker';
import GeneralModal from 'components/UI/GeneralModal';
import { required, validateIsTodayOrAfter } from 'lib/validation';
import * as classes from './styles';

const ManageMilestoneForm = ({
  isOpen,
  onClose,
  handleSubmit,
  onSave,
  reset,
}) => {
  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSaveMilestone = async (values) => {
    const { milestoneName, ...rest } = values;

    await onSave({ name: milestoneName, ...rest });
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Manage milestone"
      maxWidth="sm"
    >
      <Typography sx={{ mb: 3 }} variant="body1">
        Note: Milestones will trigger reminder notifications to all participants in the RFI,
        including the responding companies.
      </Typography>
      <Field
        component={TextFieldInput}
        name="milestoneName"
        label="Milestone label"
        type="text"
        validate={[required]}
        sx={{ mb: 2 }}
      />
      <Field
        label="Due date"
        css={classes.field}
        name="dueDate"
        validate={[required, validateIsTodayOrAfter]}
        component={DatePicker}
      />
      <div css={classes.buttonsWrap}>
        <Button onClick={handleSubmit(handleSaveMilestone)}>Save</Button>
        <Button onClick={handleClose} variant="outlined">Cancel</Button>
      </div>
    </GeneralModal>
  );
};

ManageMilestoneForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  ...propTypes,
};

export default reduxForm({ form: 'editMilestoneForm', enableReinitialize: true })(ManageMilestoneForm);
