import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import TextFieldInput from 'components/Form/TextFieldInput';
import SubmitButton from 'components/UI/SubmitButton';
import { required } from 'lib/validation';

const CancelChallenge = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field
          sx={{ mb: 2 }}
          label="Provide a reason to share with responders"
          name="cancellationReason"
          component={TextFieldInput}
          validate={required}
          multiline
          rows="6"
        />
        <SubmitButton isLoading={submitting} onClick={handleSubmit}>Save</SubmitButton>
      </form>
    )}
  />
);

CancelChallenge.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CancelChallenge;
