import { css } from '@emotion/react';

const MOBILE_BREAKPOINT = 768;

export const container = (theme) => (
  css`
    ${theme.breakpoints.up(MOBILE_BREAKPOINT)} {
      display: flex;
      justify-content: space-between;
    }
  `
);

export const item = (theme) => (
  css`
    &:not(:last-child) {
      ${theme.breakpoints.down(MOBILE_BREAKPOINT)} {
        margin-bottom: ${theme.spacing(1)};
      }
    }
  `
);
