import APIClient from './client';

class EventHostMeetingPriorityListAPI extends APIClient {
  constructor() {
    super('/event-host-meeting-priority-lists');
  }

  getMeetingPrioritiesOfVendor = async (eventId, userId) => {
    const { data: [priorityList] } = await this.find({
      where: {
        userId,
        eventId,
      },
      include: {
        relation: 'retailUsers',
        scope: {
          include: {
            relation: 'user',
            scope: {
              fields: ['id', 'companyId', 'position'],
              include: [
                {
                  relation: 'company',
                  scope: {
                    fields: ['id', 'name'],
                  },
                },
                {
                  relation: 'eventTopicPriorityLists',
                  scope: {
                    include: ['notes', 'topics'],
                    where: {
                      eventId,
                    },
                  },
                },
              ],
            },
          },
        },
      },
    });

    return priorityList;
  };
}

const eventHostMeetingPriorityListAPI = new EventHostMeetingPriorityListAPI();

export default eventHostMeetingPriorityListAPI;
