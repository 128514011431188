import { css } from '@emotion/react';

export const table = (theme) => (
  css`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 ${theme.spacing(1)};
  `
);

export const row = (theme) => (
  css`
    border-radius: 4px;
    padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const th = (theme) => (
  css`
    padding-bottom: ${theme.spacing(1)};
    text-align: left;
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const cell = (theme) => (
  css`
    box-sizing: border-box;
    padding-right: ${theme.spacing(1)};
    padding-left: ${theme.spacing(1)};
    vertical-align: middle;

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(0.5)};
    }
  `
);

export const email = css`
  max-width: 225px;
`;

export const extendedName = css`
  max-width: 275px;
`;

export const phoneNumberTd = css`
  width: 140px;
`;

export const contentToCopy = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(0.5)};
    overflow: hidden;
    width: inherit;
    cursor: pointer;

    &:hover {
      svg {
        visibility: visible;
      }
    }
  `
);

export const textWithOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const copyIcon = css`
  flex-shrink: 0;
  visibility: hidden;
`;

export const role = css`
  width: 160px;
`;

export const icon = (theme) => css`
  color: ${theme.palette.primary.main};
`;

export const deleteIcon = (theme) => css`
  color: ${theme.palette.error.main};
`;
