import get from 'lodash/get';
import companyStatus from 'constants/companyStatus';
import userRoles from 'constants/userRoles';

export const isUnauthenticated = (user) => !(user && user.id);

export const isAuthenticated = (user) => !!(user && user.id);

const checkCompanyType = (user, type) => {
  const { type: userCompanyType } = user.company;

  return userCompanyType === type;
};

export const isRetailer = (user) => checkCompanyType(user, companyStatus.RETAILER);

export const isVendor = (user) => checkCompanyType(user, companyStatus.VENDOR);

export const isAdmin = (user) => {
  const role = get(user, 'invitedRoles[0].roleName', null);

  return role === userRoles.ADMIN;
};

export const isUserCompanyApproved = (user) => user.company.adminApprove;
