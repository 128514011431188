import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';

import SearchTags from 'components/SolutionsSearch/SearchTags';
import { FoundedType, ProductServiceType } from 'types/Vendor';
import CompanyType from 'types/Company';
import TagType from 'types/Tags';
import List from '../List';
import 'styles/VendorProfile.scss';

const BasicDetails = ({ company, isTagClickable }) => {
  const filterEmptyValues = (items) => (items ? items.filter((item) => item.trim() !== '') : []);

  const marketCountries = filterEmptyValues(company.vendors.marketCountries);
  const clientsList = filterEmptyValues(company.vendors.clientsList);

  return (
    <div>
      <div className="vendor-profile-section">
        <Typography className="vendor-profile-section-title" variant="h2">
          Overview
        </Typography>
        <Typography className="vendor-profile-section-title" variant="body1">
          {company.vendors.shortDescription}
        </Typography>
        <Typography variant="body1">{company.vendors.companyOverview}</Typography>
      </div>

      <div className="vendor-profile-section">
        <Typography className="vendor-profile-section-title" variant="h2">
          Solution
        </Typography>
        <Typography variant="body1">{company.vendors.solutionOverview}</Typography>
      </div>

      <List
        className="vendor-profile-section"
        title="Business benefits"
        list={company.vendors.productService.bussinessBenefits}
      />

      {!!(company.vendors.founded.where && company.vendors.founded.when) && (
        <div className="vendor-profile-section">
          <Typography className="vendor-profile-section-title" variant="h2">
            Founded
          </Typography>
          <Typography variant="body1">
            {company.vendors.founded.where} {company.vendors.founded.when}
          </Typography>
        </div>
      )}

      <List className="vendor-profile-section" title="Clients" list={clientsList} />
      <List className="vendor-profile-section" title="Where they operate" list={marketCountries} />

      {!!(company.tags && company.tags.length) && (
        <SearchTags
          className="vendor-profile-section"
          isTagClickable={isTagClickable}
          tags={company.tags}
        />
      )}
    </div>
  );
};

BasicDetails.propTypes = {
  company: PropTypes.shape({
    ...CompanyType,
    tags: PropTypes.arrayOf(TagType.isRequired).isRequired,
    vendors: PropTypes.shape({
      founded: FoundedType.isRequired,
      productService: ProductServiceType.isRequired,
      shortDescription: PropTypes.string.isRequired,
      companyOverview: PropTypes.string.isRequired,
      solutionOverview: PropTypes.string.isRequired,
      clientsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      marketCountries: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
  }).isRequired,
  isTagClickable: PropTypes.bool.isRequired,
};

export default connect((state) => ({ company: state.vendorInfo.company }))(BasicDetails);
