/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ReviewType from 'types/Review';
import ReviewTableCard from '../ReviewTableCard';
import * as classes from './styles';

const ReviewsTable = ({
  className,
  reviews,
  showReviewerName,
  getFormattedDate,
  getRatings,
  canVote,
  getLogoUrl,
  getCompanyName,
}) => (
  <table css={classes.table} className={className}>
    <thead>
    <tr>
      <Typography
        css={[classes.cell, classes.th, classes.logo]}
        variant="caption1"
        component="th"
      >
        Logo
      </Typography>
      <Typography
        css={[classes.cell, classes.th, classes.name]}
        variant="caption1"
        component="th"
      >
        Name
      </Typography>
      <Typography
        css={[classes.cell, classes.th, classes.overallRating]}
        variant="caption1"
        component="th"
      >
        Overall rating
      </Typography>
      <Typography
        css={[classes.cell, classes.th, classes.overallRating]}
        variant="caption1"
        component="th"
      />
      {showReviewerName && (
        <Typography
          css={[classes.cell, classes.th]}
          variant="caption1"
          component="th"
        >
          Reviewer
        </Typography>
      )}
      <Typography
        css={[classes.cell, classes.th]}
        variant="caption1"
        component="th"
      >
        Date
      </Typography>
    </tr>
    </thead>
    <tbody>
    {reviews.map((review) => (
      <ReviewTableCard
        key={review.id}
        review={review}
        getFormattedDate={getFormattedDate}
        getRatings={getRatings}
        showReviewerName={showReviewerName}
        canVote={canVote}
        getLogoUrl={getLogoUrl}
        getCompanyName={getCompanyName}
      />
    ))}
    </tbody>
  </table>
);

ReviewsTable.propTypes = {
  className: PropTypes.string,
  reviews: PropTypes.arrayOf(ReviewType.isRequired).isRequired,
  getFormattedDate: PropTypes.func.isRequired,
  getRatings: PropTypes.func.isRequired,
  canVote: PropTypes.bool.isRequired,
  showReviewerName: PropTypes.bool.isRequired,
  getLogoUrl: PropTypes.func.isRequired,
  getCompanyName: PropTypes.func.isRequired,
};

ReviewsTable.defaultProps = {
  className: null,
};

export default ReviewsTable;
