import PropTypes from 'prop-types';

const ReviewRelevanceVote = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  ratingsReviewsId: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  vote: PropTypes.oneOf([1, -1]).isRequired,
});

export default ReviewRelevanceVote;
