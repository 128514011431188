/** @jsxImportSource @emotion/react */
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';

import EventPriorityList from 'components/EditEventTopicPriorities/EventPriorityList';
import eventHostMeetingPriorityListAPI from 'api/eventHostMeetingPriorityList';
import EventAttendeeType from 'types/EventAttendee';
import EventType from 'types/Event';
import AttendeeCard from '../AttendeeCard';
import sortAttendees from './sortAttendees';

const DEBOUNCE_TIMEOUT_MS = 800;

const ManageBusinessPriorities = ({
  event,
  attendees,
  priorityListId: initialPriorityListId,
  orderedAttendees: initialAttendees,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const tags = useSelector((state) => state.user.company.tags);

  const getUnselectedAttendees = () => {
    const orderedAttendeesIds = attendeeSlots.reduce((acc, { id }) => (
      typeof id === 'string' ? [...acc, id] : acc
    ), []);

    const filteredAttendees = attendees.filter(({ id }) => !orderedAttendeesIds.includes(id));

    return sortAttendees(filteredAttendees, tags);
  };

  const generateSlots = () => {
    const emptySlotsCount = attendees.length - initialAttendees.length;

    if (emptySlotsCount > 0) {
      const emptySlots = Array
        .from({ length: emptySlotsCount })
        .map((_, i) => ({ id: i }));

      return [...initialAttendees, ...emptySlots];
    }

    return initialAttendees;
  };

  const [priorityListId, setPriorityListId] = useState(initialPriorityListId);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [attendeeSlots, setAttendeesSlots] = useState(generateSlots());
  const [unorderedAttendees, setUnorderedAttendees] = useState(getUnselectedAttendees());

  const handleSavePriorityList = useCallback(async (submittedSlots, id) => {
    try {
      /**
       * Unselected slots have ids with type Number while selected slots have ids with type
       * String
       */
      const invitationForUsers = submittedSlots.reduce((acc, slot) => (
        typeof slot.id === 'string' ? [...acc, slot.id] : acc
      ), []);

      const body = { eventId: event.id, priorities: invitationForUsers };

      const prioritiesListBody = id ? { id, ...body } : body;

      const { data } = await eventHostMeetingPriorityListAPI.put(prioritiesListBody);

      setPriorityListId(data.id);
    } catch (_) {
      enqueueSnackbar('Failed to update priorities', { variant: 'error' });
    }
  }, [enqueueSnackbar, event.id]);

  const debouncedSave = useMemo(() => (
    debounce(handleSavePriorityList, DEBOUNCE_TIMEOUT_MS)
  ), [handleSavePriorityList]);

  useEffect(() => {
    const isListFilled = attendeeSlots.length > 0;

    if ((!priorityListId && isListFilled) || priorityListId) {
      debouncedSave(attendeeSlots, priorityListId);
    }
  }, [attendeeSlots, priorityListId, debouncedSave]);

  const handleSelectAttendee = (slotId) => (attendee) => {
    setUnorderedAttendees((prevAttendees) => {
      const replacedAttendee = typeof slotId === 'string'
        ? attendeeSlots.find(({ id }) => id === slotId && attendee.id !== slotId)
        : null;

      const filteredAttendees = prevAttendees.filter(({ id }) => id !== attendee.id);

      return replacedAttendee ? [...filteredAttendees, replacedAttendee] : filteredAttendees;
    });

    setAttendeesSlots((prevSlots) => prevSlots.map((slot) => (
      slot.id === slotId ? attendee : slot
    )));
  };

  const handleDeselectAttendee = (attendee) => {
    setUnorderedAttendees((prevAttendees) => [...prevAttendees, attendee]);
    setAttendeesSlots((prevSlots) => (
      prevSlots.map((slot) => (slot.id === attendee.id ? { id: Math.random() } : slot))
    ));
  };

  return (
    <EventPriorityList
      slots={attendeeSlots}
      onSetSlots={setAttendeesSlots}
      renderSlot={(slot) => (
        <AttendeeCard
          slot={slot}
          onClose={() => setSelectedSlotId(null)}
          onSubmit={handleSelectAttendee(slot.id)}
          onRemove={handleDeselectAttendee}
          onSelect={() => setSelectedSlotId(slot.id)}
          unorderedAttendees={unorderedAttendees}
          isAttendeeSelected={selectedSlotId !== null && slot.id === selectedSlotId}
        />
      )}
    />
  );
};

ManageBusinessPriorities.propTypes = {
  event: EventType.isRequired,
  attendees: PropTypes.arrayOf(EventAttendeeType).isRequired,
  orderedAttendees: PropTypes.arrayOf(EventAttendeeType).isRequired,
  priorityListId: PropTypes.string,
};

ManageBusinessPriorities.defaultProps = {
  priorityListId: null,
};

export default ManageBusinessPriorities;
