import { css } from '@emotion/react';

export const button = (theme) => (
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(0.5)};
    border: none;
    background-color: transparent;
    cursor: pointer;
  `
);

export const icon = (theme) => (
  css`
    color: ${theme.palette.primary.main};

    ${theme.breakpoints.up('sm')} {
      font-size: 24px;
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
    }
  `
);
