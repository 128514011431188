import APIClient from './client';

class ChallengeParticipationRequestAPI extends APIClient {
  constructor() {
    super('/challenge-participation-request');
  }

  sendParticipationRequest = (challengeId) => this.postRequest('/send', { challengeId });

  getChallengeParticipationRequest = async (challengeId, companyId) => {
    const filter = { where: { challengeId, companyId } };

    const { data } = await this.find(filter);
    const [participationRequest] = data;

    return participationRequest;
  };
}

const challengeParticipationRequestAPI = new ChallengeParticipationRequestAPI();

export default challengeParticipationRequestAPI;
