import PropTypes from 'prop-types';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import challengeStatus from 'constants/challengeStatus';
import retailerDisplayedChallengeStatuses from 'constants/retailerDisplayedChallengeStatus';
import MilestoneType from 'types/Milestone';

const DashboardChallengeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  response_deadline: PropTypes.string,
  status: PropTypes.oneOf(Object.values(challengeStatus)).isRequired,
  milestones: PropTypes.arrayOf(MilestoneType.isRequired).isRequired,
  isCreatedFromEvent: PropTypes.bool.isRequired,
  challengeResponses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      challengeId: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(challengeResponseStatus)).isRequired,
    }).isRequired,
  ).isRequired,
  source: PropTypes.string.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  displayedStatus: PropTypes.oneOf(Object.values(retailerDisplayedChallengeStatuses)),
});

export default DashboardChallengeType;
