/** @jsxImportSource @emotion/react */
import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import tagAPI from 'api/tag';
import ErrorIconFilled from 'svg/ErrorIconFilled';
import RequirementTagsAutocomplete from '../RequirementTagsAutocomplete';
import SuggestedRequirementsList from '../SuggestedRequirementsList';
import * as classes from './styles';

const QuestionHints = ({
  addedQuestions,
  isDisplayed,
  onClose,
  onSubmit,
  tags: challengeTags,
}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);
  const [suggestedRequirements, setSuggestedRequirements] = useState([]);
  const [selectedRequirements, setSelectedRequirements] = useState([]);

  const handleInitFetching = () => {
    setLoaded(false);
    setError(false);
  };

  const handleFetchSuccess = (tagsWithRequirements) => {
    const sortedRequirements = (
      tagsWithRequirements
        .reduce((acc, tag) => {
          const { challengeRequirements, name } = tag;

          challengeRequirements.forEach((requirement) => {
            const requirementIndex = acc.findIndex(({ id }) => id === requirement.id);

            if (requirementIndex === -1) {
              // eslint-disable-next-line no-param-reassign
              acc = [...acc, { ...requirement, tags: [name] }];
            } else {
              // eslint-disable-next-line no-param-reassign
              acc = [
                ...acc.slice(0, requirementIndex),
                { ...acc[requirementIndex], tags: [...acc[requirementIndex].tags, name] },
                ...acc.slice(requirementIndex + 1),
              ];
            }
          });

          return acc;
        }, [])
        .sort((a, b) => b.tags.length - a.tags.length)
    );

    setSuggestedRequirements(sortedRequirements);
    setSelectedRequirements((prevSelectedRequirements) => (
      prevSelectedRequirements.filter((requirement) => (
        sortedRequirements.some(({ id }) => id === requirement.id)
      ))
    ));
  };

  useEffect(() => {
    if (isDisplayed) {
      setSelectedTags(challengeTags);
      handleInitFetching();

      tagAPI
        .getTagsWithRequirements(challengeTags, addedQuestions)
        .then((result) => handleFetchSuccess(result))
        .catch(() => setError(true))
        .finally(() => setLoaded(true));
    }
  }, [addedQuestions, challengeTags, isDisplayed]);

  const handleClose = () => {
    setError(false);
    setLoaded(false);

    setSelectedTags([]);
    setSuggestedRequirements([]);

    onClose();
  };

  const handleSubmit = (chosenRequirements) => {
    onSubmit(chosenRequirements);
    setSelectedRequirements([]);

    handleClose();
  };

  const checkIsRequirementSelected = (requirement) => selectedRequirements.some(({ id }) => (
    id === requirement.id
  ));

  const handleToggleRequirement = (requirement) => (e) => {
    const { checked } = e.target;

    if (!checked) {
      setSelectedRequirements((prevRequirements) => (
        prevRequirements.filter(({ id }) => id !== requirement.id)
      ));
    } else {
      setSelectedRequirements((prevRequirements) => [...prevRequirements, requirement]);
    }
  };

  return (
    <>
      <DialogContent css={classes.content}>
        <RequirementTagsAutocomplete
          css={classes.tagAutocomplete}
          tags={selectedTags}
          onSetTags={setSelectedTags}
        />

        {isError && (
          <div css={classes.errorContainer}>
            <ErrorIconFilled css={classes.errorIcon} />
            <Typography variant="body2">
              Failed to load requirements for your RFI. Please try again later
            </Typography>
          </div>
        )}

        {!isLoaded && <CircularProgress css={classes.loading} />}

        {isLoaded && !isError && !suggestedRequirements.length && (
          <Typography variant="body2">
            We haven&apos;t found any requirements related to selected tags. Please, add more
            tags in order to receive suggestions for your requirements.
          </Typography>
        )}

        {(isLoaded && !isError && !!suggestedRequirements.length) && (
          <>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Search and select questions you want to add to your RFI.
            </Typography>
            <SuggestedRequirementsList
              css={classes.suggestedQuestions}
              onCheckIsSelected={checkIsRequirementSelected}
              onToggle={handleToggleRequirement}
              requirements={suggestedRequirements}
            />
          </>
        )}
      </DialogContent>

      <DialogActions css={classes.buttons}>
        <Button
          onClick={() => handleSubmit(selectedRequirements)}
          disabled={!isLoaded || selectedRequirements.length === 0}
          css={classes.addRequirements}
        >
          {selectedRequirements.length
            ? `Add ${selectedRequirements.length} ${selectedRequirements.length === 1 ? 'requirement' : 'requirements'}`
            : 'Add requirements'
          }
        </Button>
        <Button variant="text" onClick={handleClose} css={classes.cancel}>
          Cancel
        </Button>
    </DialogActions>
   </>
  );
};

QuestionHints.propTypes = {
  addedQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default memo(QuestionHints);
