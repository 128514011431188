import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ChallengeResponseForRetailer from 'components/ChallengeResponse';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import companyAPI from 'api/company';
import challengeResponseAPI from 'api/challengeResponse';
import handlePageDataFetchError from 'utils/handlePageDataFetchError';
import withRouteGuards from 'containers/withRouteGuards';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import FetchContainer from 'containers/FetchContainer';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const ChallengeResponseForRetailerPage = (props) => {
  const { challengeId, companySlug } = useParams();
  const { state: { challengeResponses } } = useContext(ChallengeContext);

  const getInitialProps = useCallback(async () => {
    try {
      const { data: vendorCompany } = await companyAPI.getResponderCompanyBySlug(companySlug);

      const challengeResponse = challengeResponses
        && challengeResponses.length
        && challengeResponses.find(({ companyId }) => companyId === vendorCompany.id);

      if (challengeResponse) {
        return {
          vendorCompany,
          challengeResponse,
        };
      }

      const { data } = await challengeResponseAPI.getChallengeResponseForRetailer(
        vendorCompany.id,
        challengeId,
      );

      return {
        vendorCompany,
        challengeResponse: data,
      };
    } catch (error) {
      const errorMessage = handlePageDataFetchError(error);

      return { error: errorMessage };
    }
  }, [challengeId, companySlug, challengeResponses]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={ChallengeResponseForRetailer}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGE_RESPONSE)(ChallengeResponseForRetailerPage)),
);
