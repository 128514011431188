/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import SavedMilestones from 'components/Challenge/SavedMilestones';
import formatDate from 'utils/formatDate';
import { EventChallengeType } from 'types/Challenge';
import MilestoneType from 'types/Milestone';
import sectionStyles from './styles';

const EventChallenge = ({ challenge, milestones, isOwner }) => {
  const {
    response_deadline: deadline,
    challenge_description: description,
    objectives,
  } = challenge;

  return (
    <div>
      <div css={sectionStyles}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">The RFI</Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
      <div css={sectionStyles}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">RFI objectives</Typography>
        <Typography variant="body1">{objectives}</Typography>
      </div>
      <div css={sectionStyles}>
        <Typography variant="body1">Response deadline</Typography>
        <Typography variant="h3" component="p">{formatDate(deadline, 'dd/MM/yyyy')}</Typography>
      </div>
      {!!(isOwner && milestones.length) && (
        <div css={sectionStyles}>
          <Typography sx={{ mb: 1 }} variant="subtitle1">Saved milestones</Typography>
          <SavedMilestones milestones={milestones} />
        </div>
      )}
    </div>
  );
};

EventChallenge.propTypes = {
  isOwner: PropTypes.bool,
  challenge: EventChallengeType.isRequired,
  milestones: PropTypes.arrayOf(MilestoneType.isRequired),
};

EventChallenge.defaultProps = {
  isOwner: false,
  milestones: [],
};

export default EventChallenge;
