import get from 'lodash/get';
import merge from 'lodash/merge';
import { UNITED_KINGDOM } from 'constants/countries';
import { validateRelatedFields } from 'lib/validation';
import { yesNoOptions } from 'constants/yesNoRadioButtonOptions';

const validate = (values) => {
  const minRevenueErrors = validateRelatedFields(values, [
    'vendors.customers.currency.currencySmallest',
    'vendors.customers.currency.smallestValue',
  ]);

  const maxRevenueErrors = validateRelatedFields(values, [
    'vendors.customers.currency.largestValue',
    'vendors.customers.currency.currencyLargest',
  ]);

  let officeErrors;

  const headOfficeCountry = get(values, 'offices[0].country');
  const hasOfficeInUK = get(values, 'offices[1].officeInside');

  if (
    headOfficeCountry
    && headOfficeCountry !== UNITED_KINGDOM
    && hasOfficeInUK === yesNoOptions.YES
  ) {
    officeErrors = validateRelatedFields(values, [
      'offices[1].address1',
      'offices[1].city',
      'offices[1].post',
      'offices[1].registrationNumber',
      'offices[1].VAT',
    ]);
  }

  let fundingErrors;
  const isCompanyVentureBacked = get(values, 'vendors.financialData.isVentureBacked');

  if (isCompanyVentureBacked === yesNoOptions.YES) {
    fundingErrors = validateRelatedFields(values, [
      'vendors.financialData.funding.currency',
      'vendors.financialData.funding.amount',
    ]);
  }

  return merge({}, officeErrors, minRevenueErrors, maxRevenueErrors, fundingErrors);
};

export default validate;
