import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import YesNoAnswer from 'components/UI/YesNoAnswer';
import './YesNoAnswerRow.scss';

const YES_NO_ANSWER_ICON_SIZE = 30;

const YesNoAnswerRow = ({
  answer: {
    description,
    value,
    mustHave,
    index,
  },
}) => (
  <tr
    className={clsx(
      'response-yes-no-answer-row',
      mustHave && 'response-yes-no-answer-row_highlighted',
    )}
  >
    <td className="response-yes-no-answer-row__cell">
      <Typography variant="body1">{`Q${index + 1}. ${description}`}</Typography>
    </td>
    <td className="response-yes-no-answer-row__cell response-yes-no-answer-row__cell_centered">
      <YesNoAnswer
        className="response-yes-no-answer-row__mark"
        isSelected={!!value}
        size={YES_NO_ANSWER_ICON_SIZE}
      />
    </td>
    <td className="response-yes-no-answer-row__cell response-yes-no-answer-row__cell_centered">
      <YesNoAnswer
        className="response-yes-no-answer-row__mark"
        isSelected={(typeof value === 'boolean' && !value)}
        size={YES_NO_ANSWER_ICON_SIZE}
      />
    </td>
  </tr>
);

YesNoAnswerRow.propTypes = {
  answer: PropTypes.shape({
    index: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
    value: PropTypes.bool,
    mustHave: PropTypes.bool.isRequired,
  }).isRequired,
};

export default YesNoAnswerRow;
