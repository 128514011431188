/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Field,
  fieldInputPropTypes,
  fieldMetaPropTypes,
  fieldArrayFieldsPropTypes,
} from 'redux-form';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { validateIsBoolean } from 'lib/validation';
import * as classes from './styles';

const ChallengeAnswerField = ({
  input: { onChange, value },
  meta: { error, submitFailed },
  label,
}) => (
  <div css={classes.answer}>
    <div css={classes.info}>
      <Typography variant="body1">
        {label}
      </Typography>
      {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
    <div css={classes.options}>
      <div css={classes.button}>
        <Button
          onClick={() => onChange(true)}
          color={value ? 'primary' : 'secondary'}
        >
          Yes
        </Button>
      </div>
      <div css={classes.button}>
        <Button
          onClick={() => onChange(false)}
          // Important: we need to distinguish `null` and `false` values
          color={value === false ? 'primary' : 'secondary'}
        >
          No
        </Button>
      </div>
    </div>
  </div>
);

ChallengeAnswerField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  label: PropTypes.string.isRequired,
};

const ChallengeAnswersFieldArray = ({ className, fields }) => (
  !!fields.length && (
    <div className={className}>
      {fields.map((field, i) => (
        <Field
          key={fields.get(i).name}
          component={ChallengeAnswerField}
          name={`${field}.value`}
          label={fields.get(i).description}
          validate={[validateIsBoolean]}
        />
      ))}
    </div>
  )
);

ChallengeAnswersFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

ChallengeAnswersFieldArray.defaultProps = {
  className: null,
};

export default ChallengeAnswersFieldArray;
