import isEmpty from 'lodash/isEmpty';
import companyStatus from 'constants/companyStatus';
import { sendErrorNotification, sendSuccessNotification } from 'store/modules/notifications';
import { getCompanyError } from 'store/actions/vendorInfo';
import companyAPI from 'api/company';
import * as actions from '../actions';

export const requestApprove = () => async (dispatch, getState) => {
  const state = getState();
  const { id } = state.user.company;

  const { data: company } = await companyAPI.requestApprove(id);

  dispatch(actions.setCompanyUser(company));
};

export const updateCompanyProfile = (body) => async (dispatch, getState) => {
  const state = getState();
  const companyId = state.user.company.id;

  const {
    retailers: retailerData,
    vendors: vendorData,
    tags,
    integrationLabels,
    ...companyData
  } = body;

  const [company, retailers, vendors] = await Promise.all([
    !isEmpty(companyData) && companyAPI.updateCompany(companyId, companyData),
    retailerData && companyAPI.updateRetailerProfile(companyId, retailerData),
    vendorData && companyAPI.updateVendorProfile(companyId, vendorData),
  ]);

  const result = {
    ...company,
    retailers,
    vendors,
  };

  if (tags) {
    result.tags = tags;
  }

  if (integrationLabels) {
    result.integrationLabels = integrationLabels;
  }

  dispatch(actions.setCompanyUser(result));
};

export const getPrivateVendorProfile = (slug) => async (dispatch, getState) => {
  const { user: { company: { slug: userCompanySlug, type } } } = getState();

  dispatch(actions.clearCompany());

  if (type === companyStatus.RETAILER || userCompanySlug === slug) {
    const company = await companyAPI.getVendorProfile(slug);

    if (company) {
      dispatch(actions.getCompany(company));
    } else {
      dispatch(getCompanyError());
    }
  }
};

export const getPublicVendorProfile = (slug) => async (dispatch) => {
  dispatch(actions.clearCompany());

  const company = await companyAPI.getVendorProfile(slug);

  if (company && company.adminApprove) {
    dispatch(actions.getCompany(company));
  } else {
    dispatch(getCompanyError());
  }
};

export const savePrimaryContact = (body) => async (dispatch) => {
  try {
    const { data: { company, user } } = await companyAPI.savePrimaryContact(body);
    dispatch(actions.setCompanyUser(company));

    if (user) {
      dispatch(actions.addActiveUser(user));
    }

    dispatch(sendSuccessNotification('Contact saved'));
  } catch (error) {
    dispatch(sendErrorNotification(error, 'Failed to update contact'));
  }
};
