/** @jsxImportSource @emotion/react */
import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import uniqBy from 'lodash/uniqBy';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { LightbulbFilament20Regular } from '@fluentui/react-icons';
import tagAPI from 'api/tag';
import usePrevious from 'utils/usePrevious';
import * as classes from './styles';

const REQUIREMENTS_LIMIT = 5;

const RequirementRecommendations = ({
  selectedTags,
  addedQuestionTexts,
  onOpenHints,
  onAddRequirement: onAddRequirementAsQuestion,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [recommendedRequirements, setRecommendedRequirements] = useState([]);

  const prevStateSelectedTags = usePrevious(selectedTags);

  const getRecommendations = useCallback(async (questions) => {
    try {
      setLoading(true);

      const tags = await tagAPI.getTagsWithRequirements(
        selectedTags,
        questions,
        REQUIREMENTS_LIMIT,
      );

      const requirements = tags
        .map(({ challengeRequirements }) => challengeRequirements)
        .flat();

      const filteredRequirements = uniqBy(requirements, 'description').slice(0, REQUIREMENTS_LIMIT);

      setRecommendedRequirements(filteredRequirements);
      setLoading(false);
    } catch (_) {
      setRecommendedRequirements([]);
      enqueueSnackbar('Failed to fetch new requirements', { variant: 'error' });
    }
  }, [selectedTags, enqueueSnackbar]);

  useEffect(() => {
    if (prevStateSelectedTags !== selectedTags) {
      getRecommendations(addedQuestionTexts);
    }
  }, [getRecommendations, prevStateSelectedTags, selectedTags, addedQuestionTexts]);

  const handleAddRecommendedRequirement = (description) => {
    const isAlreadyAdded = addedQuestionTexts.some((question) => description === question);

    setRecommendedRequirements(((prevState) => (
      prevState.filter((option) => option.description !== description)
    )));

    if (isAlreadyAdded) {
      enqueueSnackbar('Already added to the list', { variant: 'warning' });
    } else {
      onAddRequirementAsQuestion({
        description,
        mustHave: false,
      });
    }
  };

  return (
    <div css={classes.recommendationWrap}>
      {isLoading && (
        <div css={classes.loading}>
          <CircularProgress />
        </div>
      )}

      {!isLoading && recommendedRequirements.length
        ? recommendedRequirements.map(({ id, description }) => (
          <div
            key={id}
            css={classes.recommendedRequirement}
          >
            <Typography variant="body2" css={classes.description}>{description}</Typography>
            <Button
              css={classes.addButton}
              variant="text"
              onClick={() => handleAddRecommendedRequirement(description)}
            >
              Add
            </Button>
          </div>
        )) : (
          <div css={classes.recommendedRequirement}>
            <Typography variant="body2">
              No recommended requirements
            </Typography>
          </div>
        )
      }
      {!isLoading && !!recommendedRequirements.length && (
        <div>
          <button css={classes.showMoreButton} onClick={onOpenHints} type="button">
            <LightbulbFilament20Regular css={classes.icon} />
            <Typography variant="body2">Show more</Typography>
          </button>
        </div>
      )}
    </div>
  );
};

RequirementRecommendations.propTypes = {
  addedQuestionTexts: PropTypes.arrayOf(PropTypes.string),
  selectedTags: PropTypes.arrayOf(PropTypes.string.isRequired),
  onOpenHints: PropTypes.func.isRequired,
  onAddRequirement: PropTypes.func.isRequired,
};

RequirementRecommendations.defaultProps = {
  addedQuestionTexts: [],
  selectedTags: [],
};

export default RequirementRecommendations;
