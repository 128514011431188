import { css } from '@emotion/react';

export const spinnerContainer = css`
  padding: 100px 0;
  text-align: center;
`;

export const description = (theme) => (
  css`
    max-width: 700px;
    margin-bottom: ${theme.spacing(3)};
  `
);

export const paragraph = (theme) => (
  css`
    white-space: pre-line;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const subtitle = css`
  display: block;
`;
