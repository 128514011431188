export const RETAILER_TYPES = [
  'Grocery',
  'Electricals',
  'Fashion',
  'Health & Beauty',
  'Dept Store',
  'Gen merchandise',
  'Home & DIY',
  'Entertainment',
  'Sports & leisure',
  'Other:',
];

export const DEPARTMENTS = [
  'Marketing and Customer Service',
  'Digital and Mobile',
  'In-store Retail',
  'Payments and Commerce',
  'Supply Chain and Warehousing',
  'Logistics and Last Mile',
  'Retail Operations',
  'Data and Analytics',
];

const SALES_CYCLES_MAX_MONTHS = 18;

export const SALES_CYCLE_OPTIONS = [
  ...Array.from({ length: SALES_CYCLES_MAX_MONTHS }, (_, i) => i + 1),
  `${SALES_CYCLES_MAX_MONTHS}+`,
];

export const CURRENCY_OPTIONS = [
  { value: 'GBP', label: '(£) GBP' },
  { value: 'EUR', label: '(€) EUR' },
  { value: 'USD', label: '($) USD' },
];

export const COMPANY_GROWTH_STAGES = ['Early', 'Growth', 'Mature'];
