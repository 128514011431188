import { css } from '@emotion/react';

export const container = css`
  text-align: center;
`;

export const image = (theme) => (
  css`
    display: block;
    width: 150px;
    margin: 0 auto ${theme.spacing(2)};
  `
);

export const text = (theme) => (
  css`
    padding: 0 ${theme.spacing(5)} ${theme.spacing(2)};
  `
);
