import { css } from '@emotion/react';

const MAX_LOGO_WIDTH = 115;

export const row = (theme) => (
  css`
    height: ${theme.spacing(7)};
    padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
    border-radius: 4px;
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const rowOpened = (theme) => (
  css`
    height: ${theme.spacing(7)};
    padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
    border-radius: 4px;
    box-shadow:
      -10px 0 10px -10px rgba(50, 55, 64, 0.07), /* left shadow */
      10px 0 10px -10px rgba(50, 55, 64, 0.07), /* right shadow */
      0 -10px 10px -10px rgba(50, 55, 64, 0.07); /* top shadow */
  `
);

export const cell = (theme) => (
  css`
    box-sizing: border-box;
    padding-right: ${theme.spacing(1)};
    padding-left: ${theme.spacing(1)};
    vertical-align: middle;

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(0.5)};
    }
  `
);

export const logoWrap = (theme) => (
  css`
    max-width: ${MAX_LOGO_WIDTH}px;
    padding-top: ${theme.spacing(0.75)};
    padding-bottom: ${theme.spacing(0.75)};
  `
);

export const logo = css`
  max-width: ${MAX_LOGO_WIDTH}px;
  height: 44px;
  object-fit: contain;
`;

export const name = css`
  max-width: 130px;
`;

export const overallRating = css`
  max-width: 140px;
`;

export const collapse = (theme) => (
  css`
    margin-top: -${theme.spacing(2.5)};
    padding-top: ${theme.spacing(2)};
    box-shadow: -10px 0 10px -10px rgba(50, 55, 64, 0.07),
      10px 0 10px -10px rgba(50, 55, 64, 0.07),
      0 10px 10px -10px rgba(50, 55, 64, 0.07);
  `
);

export const collapseChevron = (theme) => (
  css`
    color: ${theme.palette.primary.main};
    transition: transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms;
  `
);

export const rotatedChevron = css`
  transform: rotate(180deg);
`;

export const ratings = (theme) => (
  css`
    position: relative;
    display: flex;
    gap: ${theme.spacing(8)};
  `
);

export const filledRatings = (theme) => (
  css`
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(3)};

    &:before {
      content: "";
      position: absolute;
      top: -4px;
      display: block;
      width: calc(100% - ${theme.spacing(4)});
      height: 1px;
      background-color: ${theme.primaryPalette.midLightGrey};
    }
  `
);

export const voteReview = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
  `
);

export const ratingIcon = (theme) => (
  css`
    .MuiRating-iconFilled {
      color: ${theme.primaryPalette.lightOrange} !important;
    }
  `
);

export const columns = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `
);

export const ratingField = css`
  display: flex;
  gap: 10px;
`;

export const prosCons = css`
  flex: 1;
`;
