/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import tagAPI from 'api/tag';
import useDebounce from 'utils/useDebounce';
import keyCodes from 'constants/keyCodes';
import reasonChange from 'constants/reasonChangeAutocomplete';
import * as classes from './styles';

const MIN_INPUT_LENGTH = 2;

const filterOptions = createFilterOptions({
  trim: true,
  stringify: ({ name }) => name,
});

const TagSearchInput = ({
  className,
  value,
  onSetValue,
  onSelect,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const formatValue = (oldValue) => oldValue.toLowerCase().trim();

  const handleSearch = useCallback(async (query, active) => {
    try {
      if (!query) {
        setSuggestions([]);

        return;
      }

      const tags = await tagAPI.getSuggestions(query);

      if (active) {
        setSuggestions(tags);
      }
    } catch (_) {
      enqueueSnackbar('Failed to search', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const debouncedSearch = useDebounce(handleSearch);

  useEffect(() => {
    if (value.trim().length <= MIN_INPUT_LENGTH) {
      setOpen(false);
    }
  }, [value]);

  useEffect(() => {
    let active = true;

    const formattedValue = formatValue(value);

    setSuggestions([]);

    debouncedSearch(formattedValue, active);

    return () => {
      active = false;
    };
  }, [debouncedSearch, value, enqueueSnackbar]);

  const handleSelectOption = (selectedOption) => {
    const { name } = selectedOption;
    const trimmedValue = name.trim();

    onSelect(trimmedValue);
  };

  const onInputChange = (_, newInputValue, reason) => {
    if (reason === 'input') {
      onSetValue(newInputValue);
    }
  };

  const handleChange = (_, newValue, reason) => {
    if (reason === reasonChange.SELECT_OPTION) {
      handleSelectOption(newValue);
    }
  };

  const handleKeyDown = (e) => {
    const trimmedValue = value.trim();

    if (trimmedValue && e.keyCode === keyCodes.ENTER) {
      onSelect(trimmedValue);
    }
  };

  const renderOption = (props, option) => {
    const matches = match(option.name, value);
    const parts = parse(option.name, matches);

    return (
      <li {...props}>
        <Typography variant="body1">
          {parts.map((part, index) => (
            <span key={index} css={part.highlight && classes.highlightStyle}>
              {part.text}
            </span>
          ))}
        </Typography>
      </li>
    );
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      fullWidth
      css={classes.input}
      placeholder="Find solution"
      onKeyDown={handleKeyDown}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      className={className}
      id="search-autocomplete"
      options={suggestions}
      autoComplete
      forcePopupIcon={false}
      clearOnBlur={false}
      clearIcon={null}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      noOptionsText={null}
      isOptionEqualToValue={(option, selectedOption) => (
        formatValue(option.name) === formatValue(selectedOption.name)
      )}
      open={isOpen && suggestions.length > 0}
      inputValue={value}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      filterOptions={filterOptions}
    />
  );
};

TagSearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onSetValue: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

TagSearchInput.defaultProps = {
  className: null,
};

export default TagSearchInput;
