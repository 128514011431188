import { css } from '@emotion/react';

export const savedUpload = (theme) => (
  css`
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const fileInfo = (theme) => (
  css`
    flex: 1;
    margin-right: ${theme.spacing(1)};
  `
);

export const fieldset = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const formGroup = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);
