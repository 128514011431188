export const AGENDA_TAB = 'AGENDA';

export const eventTabKeys = {
  EVENT_DETAILS: 'Event details',
  BUSINESS_PRIORITIES: 'Business priorities',
  ATTENDEES: 'Attendees',
  RFIS: 'Your RFIs',
  [AGENDA_TAB]: 'Agenda',
};

export const retailerEventTabIds = {
  [eventTabKeys.EVENT_DETAILS]: 0,
  [eventTabKeys.BUSINESS_PRIORITIES]: 1,
  [eventTabKeys.ATTENDEES]: 2,
  [eventTabKeys.RFIS]: 3,
  [eventTabKeys[AGENDA_TAB]]: 4,
};
