/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import containerStyles from './styles';

const ChallengeSubmitSuccess = ({ onOpenNewChallenge }) => (
  <div css={containerStyles}>
    <Typography sx={{ mb: 3 }} variant="h1">
      Success!
    </Typography>
    <Typography sx={{ mb: 3 }} variant="body1">
      Your RFI was sent to the Validify team and someone will be in touch soon.
    </Typography>
    <Button onClick={onOpenNewChallenge}>Set another RFI</Button>
  </div>
);

ChallengeSubmitSuccess.propTypes = {
  onOpenNewChallenge: PropTypes.func.isRequired,
};

export default ChallengeSubmitSuccess;
