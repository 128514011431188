/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import * as classes from './styles';

const StatusBar = ({
  children,
  className,
  isActive,
  ...rest
}) => (
  <div
    className={className}
    css={[
      classes.root,
      rest.onClick && classes.clickable,
      isActive ? classes.active : classes.notActive,
    ]}
    {...rest}
  >
    {children}
  </div>
);

StatusBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

StatusBar.defaultProps = {
  className: null,
  isActive: true,
  onClick: null,
};

export default StatusBar;
