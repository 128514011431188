import { useCallback } from 'react';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import routes from 'constants/routes';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';

const PublicChallengePageRedirect = () => {
  const { id } = useParams();

  const getInitialProps = useCallback(async () => {
    const challenge = await challengeAPI.getDataForChallengeLink(id);
    const { company: { slug: retailerSlug }, slug: rfiSlug } = challenge;

    return { rfiSlug, retailerSlug };
  }, [id]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={({ rfiSlug, retailerSlug }) => (
        <Redirect
          to={generatePath(routes.PUBLIC_CHALLENGE_INFO, { rfiSlug, retailerSlug })}
        />
      )}

    />
  );
};

export default PublicChallengePageRedirect;
