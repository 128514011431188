import { css } from '@emotion/react';

export const main = (theme) => (
  css`
    max-width: ${theme.layout.publicPageContent};
    margin: 0 auto;
  `
);

export const head = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacing(1)} ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(4)};
  `
);

export const section = (theme) => (
  css`
    &:not(:last-child) {
       margin-bottom: ${theme.spacing(4)};
    }
  `
);

export const list = (theme) => (
  css`
    margin: 10px 0;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 20px;
    }
  `
);

export const highlight = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);
