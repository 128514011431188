import { css } from '@emotion/react';
import insertHost from 'utils/picture';

export const getWrapStyles = ({ background }) => (theme) => css`
  border-bottom: 1px solid ${theme.primaryPalette.midLightGrey};
  background-image: linear-gradient(to right, #fff 20%, transparent 70%), url(${insertHost(background)});
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-position: center;
  filter: drop-shadow(0px 0px 20px rgba(50, 55, 64, 0.05));

  ${theme.breakpoints.up('sm')} {
    padding: ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(10)};
  }

  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)} ${theme.spacing(10)};
  }
`;

export const head = (theme) => css`
  display: flex;
  gap: ${theme.spacing(3)};
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const button = (theme) => css`
  ${theme.breakpoints.up('sm')} {
    flex-shrink: 0;
  }
`;

export const titleWrap = css`
  position: relative;
`;

export const backButton = css`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

export const subtitle = (theme) => css`
  ${theme.breakpoints.up('sm')} {
    margin-top: ${theme.spacing(1)};
  }

  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(2)};
  }
`;

export const light = (theme) => (
  css`
    color: ${theme.primaryPalette.menuGrey};
  `
);
