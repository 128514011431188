import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Typography,
  Box,
} from '@mui/material';
import companyAPI from 'api/company';
import getErrorMessage from 'utils/getErrorMessage';
import PageBody from 'components/UI/PageBody';
import routes from 'constants/routes';
import EmailDomainsChangeRequest from '../EmailDomainsChangeRequest';

const ManageCompanyEmailDomains = () => {
  const [isModalOpen, setModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const emailDomains = useSelector((state) => state.user.company.emailDomains);
  const companyId = useSelector((state) => state.user.company.id);

  const handleSubmitEmailDomains = async (values) => {
    try {
      await companyAPI.requestEmailDomainsChange(companyId, values);

      setModal(false);
      enqueueSnackbar('Successfully sent', { variant: 'success' });
    } catch (error) {
      const errorMessage = getErrorMessage(error, 'Failed to send request');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <PageBody>
      <div>
        <Breadcrumbs sx={{ mb: 5 }}>
          <Link to={routes.MAIN}>Home</Link>
          <Link to={routes.MANAGE_USERS}>My company</Link>
          <Link to={routes.MANAGE_EMAIL_DOMAINS}>Email domains</Link>
        </Breadcrumbs>

        <Typography sx={{ mb: 5 }} variant="h1">
          Email domains
        </Typography>

        <Typography sx={{ mb: 3 }} type="body1">
          Any user with an email address in the following domains
          will be automatically added to your company. If you wish to
          make change use the request button below.
        </Typography>

        <Box sx={{ mb: 3 }}>
          <ul>
            {emailDomains.map((domain) => (
              <Typography key={domain} component="li" variant="h2">
                {domain}
              </Typography>
            ))}
          </ul>
        </Box>

        <Button onClick={() => setModal(true)}>Request changes</Button>
        <EmailDomainsChangeRequest
          isOpen={isModalOpen}
          onClose={() => setModal(false)}
          onSubmit={handleSubmitEmailDomains}
          initialValues={{ emailDomains }}
        />
      </div>
    </PageBody>
  );
};

export default ManageCompanyEmailDomains;
