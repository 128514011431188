import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import ChallengeResponseForRetailerPage from 'pages/ChallengeResponseForRetailer';
import ChallengeResponsesDashboardPage from 'pages/ChallengeResponsesDashboard';
import EditSavedChallengePage from 'pages/EditSavedChallenge';
import PublishedChallengePage from 'pages/PublishedChallenge';
import ChallengeResponseForRetailerRedirect from 'pages/ChallengeResponseForRetailerRedirect';
import routes from 'constants/routes';
import { challengeRedirects } from 'constants/challengeRedirects';
import ChallengeType, { EventChallengeType } from 'types/Challenge';
import { ChallengeProvider } from '../ChallengeProvider';

const ChallengeForRetailer = ({ challenge }) => (
  <ChallengeProvider challenge={challenge}>
    <Switch>
      <Route
        component={EditSavedChallengePage}
        path={routes.EDIT_SAVED_CHALLENGE}
        exact
      />
      <Route
        component={ChallengeResponsesDashboardPage}
        path={routes.CHALLENGE_RESPONSES_DASHBOARD}
        exact
      />
      <Route
        component={ChallengeResponseForRetailerRedirect}
        path={challengeRedirects.CHALLENGE_RESPONSE_FOR_RETAILER}
        exact
      />
      <Route
        component={ChallengeResponseForRetailerPage}
        path={routes.CHALLENGE_RESPONSE_FOR_RETAILER}
        exact
      />
      <Route
        component={PublishedChallengePage}
        path={routes.SAVED_CHALLENGE}
        exact
      />
    </Switch>
  </ChallengeProvider>
);

ChallengeForRetailer.propTypes = {
  challenge: PropTypes.oneOfType([ChallengeType, EventChallengeType]).isRequired,
};

export default ChallengeForRetailer;
