import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import SaveIcon from 'svg/save';
import { addVendorToSaved, deleteVendorFromSaved } from 'store/modules/savedVendors';
import ManageProfileButton from 'components/Shared/ManageProfileButton';

const SaveVendor = ({
  className,
  userId,
  isVendorSaved,
  companyId,
  onRemoveCompanyFromSavedList,
  onAddCompanyToSavedList,
}) => (
  <ManageProfileButton
    className={className}
    icon={SaveIcon}
    onClick={() => {
      isVendorSaved
        ? onRemoveCompanyFromSavedList(companyId)
        : onAddCompanyToSavedList(companyId, userId);
    }}
    title={isVendorSaved ? 'Saved' : 'Save'}
  />
);

SaveVendor.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  isVendorSaved: PropTypes.bool.isRequired,
  onAddCompanyToSavedList: PropTypes.func.isRequired,
  onRemoveCompanyFromSavedList: PropTypes.func.isRequired,
};

SaveVendor.defaultProps = {
  className: null,
  vendorName: null,
  companyId: null,
};

export default connect(
  (state) => {
    const userId = state.user.authUser.id;
    const companyId = get(state, 'vendorInfo.company.id', null);

    const isVendorSaved = state.savedVendors
      .some((savedVendorLink) => savedVendorLink.companyId === companyId);

    return {
      userId,
      isVendorSaved,
      companyId,
    };
  },
  (dispatch) => ({
    onAddCompanyToSavedList: (companyId, userId) => dispatch(addVendorToSaved(companyId, userId)),
    onRemoveCompanyFromSavedList: (companyId) => dispatch(deleteVendorFromSaved(companyId)),
  }),
)(SaveVendor);
