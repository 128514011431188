/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { withSnackbar } from 'notistack';

import PageBody from 'components/UI/PageBody';
import getMatchType from 'types/Match';
import vendorDataCaptureFormTabs from 'constants/vendorDataCaptureFormTabs';
import routes from 'constants/routes';
import { updateCompanyProfile } from 'store/thunk';
import getErrorMessage from 'utils/getErrorMessage';
import FormHeader from './FormHeader';
import BasicDetails from './BasicDetails';
import Solution from './Profile/Solution';
import TechnicalDetails from './Profile/TechnicalDetails';
import Endorsements from './Profile/Endorsments';
import Company from './Profile/Company';
import GrowthStaff from './GrowthStaff';
import { VENDOR_REGISTRATION_STEPS_DATA } from './constants';
import headerStyles from './styles';

const profileMenuList = Object.values(VENDOR_REGISTRATION_STEPS_DATA);

const VendorDataCaptureForm = ({
  enqueueSnackbar,
  match,
  onUpdateCompany,
  history,
}) => {
  const { activeTab } = match.params;

  const redirectUrl = generatePath(routes.EDIT_VENDOR_DETAILS, {
    activeTab: vendorDataCaptureFormTabs.BASIC_DETAILS,
  });

  const handleError = useCallback(
    (error) => {
      const message = getErrorMessage(error, 'Failed to update company profile');
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const handleSaveDraft = useCallback(
    (values) => onUpdateCompany(values).catch(handleError),
    [handleError, onUpdateCompany],
  );

  const handleCompleteStep = useCallback(
    (values) => onUpdateCompany(values)
      .then(() => {
        const currentStep = profileMenuList.find(({ path }) => path === activeTab);
        const nextStep = profileMenuList
          .find(({ stepNumber }) => stepNumber === currentStep.stepNumber + 1);

        if (!nextStep) {
          enqueueSnackbar('Profile was updated', { variant: 'success' });
        } else {
          const { path } = nextStep;
          const pathname = generatePath(routes.EDIT_VENDOR_DETAILS, { activeTab: path });

          history.push(pathname);
        }
      })
      .catch(handleError),
    [activeTab, enqueueSnackbar, handleError, history, onUpdateCompany],
  );

  const handleUpdateProfile = useCallback(
    (values, isDraft) => (isDraft ? handleSaveDraft(values) : handleCompleteStep(values)),
    [handleCompleteStep, handleSaveDraft],
  );

  const renderContent = () => {
    switch (activeTab) {
      case vendorDataCaptureFormTabs.BASIC_DETAILS:
        return <BasicDetails onUpdateProfile={handleUpdateProfile} />;

      case vendorDataCaptureFormTabs.SOLUTION:
        return <Solution onUpdateProfile={handleUpdateProfile} />;

      case vendorDataCaptureFormTabs.TECHNICAL_DETAILS:
        return <TechnicalDetails onUpdateProfile={handleUpdateProfile} />;

      case vendorDataCaptureFormTabs.ENDORSEMENTS:
        return <Endorsements onUpdateProfile={handleUpdateProfile} />;

      case vendorDataCaptureFormTabs.COMPANY:
        return <Company onUpdateProfile={handleUpdateProfile} />;

      case vendorDataCaptureFormTabs.GROWTH_STAFF:
        return <GrowthStaff onUpdateProfile={handleUpdateProfile} />;

      default:
        return <Redirect to={redirectUrl} />;
    }
  };

  return (
    <PageBody>
      <FormHeader css={headerStyles} activeTab={activeTab} />
      {renderContent()}
    </PageBody>
  );
};

VendorDataCaptureForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  match: getMatchType({ activeTab: PropTypes.string.isRequired }),
  history: PropTypes.object.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
};

export default connect(null, { onUpdateCompany: updateCompanyProfile })(
  withSnackbar(VendorDataCaptureForm),
);
