import { css } from '@emotion/react';

const button = (theme) => (
  css`
    position: absolute;
    right: 2px;
    top: 2px;

    .MuiSvgIcon-root {
      //width: 12px;
      //height: 10px;
      color: ${theme.primaryPalette.grey}!important;
    }
  `
);

export default button;
