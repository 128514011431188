import { css } from '@emotion/react';

const SHOW_TABLE_BREAKPOINT = 1400;

export const wrap = (theme) => (
  css`
    position: relative;
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacing(1)};

    &:before {
      content: "";
      position: absolute;
      top: -4px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${theme.primaryPalette.midLightGrey};
    }

    ${theme.breakpoints.up(SHOW_TABLE_BREAKPOINT)} {
      margin: 0 ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(1)};
    }

    ${theme.breakpoints.down('md')} {
      flex-wrap: wrap;
    }
  `
);

export const buttons = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.down('md')} {
      justify-content: flex-start;
    }

    ${theme.breakpoints.up('md')} {
      justify-content: flex-end;
    }
  `
);

export const selectedHelpfulIcon = (theme) => (
  css`
    color: ${theme.palette.primary.main};
  `
);

export const selectedNotHelpfulIcon = (theme) => (
  css`
    color: ${theme.palette.error.main};
  `
);
