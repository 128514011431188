import sortOrder from 'constants/sortOrder';

const eventDashboardFragment = {
  order: `eventDate ${sortOrder.ASC}`,
  where: {
    eventDate: {
      gt: new Date(),
    },
  },
};

export default eventDashboardFragment;
