import { css } from '@emotion/react';

export const wrap = (theme) => css`
  ${theme.breakpoints.down('md')} {
    flex: 1;
  }
`;

export const filter = (theme) => css`
  ${theme.breakpoints.up('md')} {
    width: 185px;
  }
`;

export const filterLabel = (theme) => css`
  color: ${theme.primaryPalette.menuGrey};
`;

export const filtersWrap = (theme) => css`
  display: flex;

  ${theme.breakpoints.up('md')} {
    justify-content: flex-end;
    gap: ${theme.spacing(4)};
  }

  ${theme.breakpoints.down('md')} {
    gap: ${theme.spacing(2)};
  }
`;

export const search = css`
  width: 100%;
`;
