import { css } from '@emotion/react';

const MOBILE_UI_BREAKPOINT = 1400;

export const statusColors = {
  REQUESTED_REVISION: '#37c6c6',
  APPROVED_REVISION: '#69d186',
  PAUSED: '#c699fe',
  COMPLETED: '#525fd2',
};

export const inRevisionStatus = (theme) => css`
  color: ${theme.primaryPalette.orange};
`;

export const draftStatus = (theme) => css`
  color: ${theme.primaryPalette.blue};
`;

export const activeStatus = (theme) => css`
  color: ${theme.primaryPalette.primaryYellow};
`;

export const awaitingUpdateStatus = (theme) => css`
  color: ${theme.primaryPalette.menu};
`;

export const requestedRevisionStatus = css`
  color: ${statusColors.REQUESTED_REVISION};
`;

export const approvedRevisionStatus = css`
  color: ${statusColors.APPROVED_REVISION};
`;

export const pausedStatus = css`
  color: ${statusColors.PAUSED};
`;

export const cancelledStatus = (theme) => css`
  color: ${theme.primaryPalette.errorColor};
`;

export const completedStatus = css`
  color: ${statusColors.COMPLETED};
`;

export const filters = (theme) => (
  css`
    ${theme.breakpoints.up('lg')} {
      margin-bottom: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('lg')} {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const desktopUI = (theme) => (
  css`
    ${theme.breakpoints.down(MOBILE_UI_BREAKPOINT)} {
      display: none;
    }
  `
);

export const mobileUI = (theme) => (
  css`
    ${theme.breakpoints.up(MOBILE_UI_BREAKPOINT)} {
      display: none;
    }
  `
);

export const card = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);
