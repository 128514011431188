import PropTypes from 'prop-types';
import { Description } from '@mui/icons-material';
import insertHost from 'utils/picture';
import './SavedAttachment.scss';

const SavedAttachment = ({ link, name }) => (
  <a
    className="saved-attachment"
    href={insertHost(link)}
    target="_blank"
    rel="noreferrer"
  >
    <Description className="saved-attachment__icon" /> {name}
  </a>
);

SavedAttachment.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SavedAttachment;
