import { css } from '@emotion/react';

const container = (theme) => (
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
  `
);

export default container;
