/** @jsxImportSource @emotion/react */
import { Field, FieldArray } from 'redux-form';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/UI/TextFieldInput';
import RadioButtonYesNo from 'components/UI/RadioButtonYesNo';
import {
  required,
  maxLength20,
  maxLength100,
  validateVideoLink,
  validateFieldArrayLength,
} from 'lib/validation';
import ChallengeAnswersFieldArray from '../ChallengeAnswersFieldArray';
import ClientReferencesFieldArray from '../ClientReferencesFieldArray';
import FreeFormAnswersFieldArray from '../FreeFormAnswersFieldArray';
import CaseStudyUploads from '../CaseStudyUploads';
import AttachmentField from '../AttachmentField';
import * as classes from './styles';

const CLIENT_REFERENCES_LIMIT = {
  MIN: 1,
  MAX: 3,
};

const ChallengeResponseForm = () => (
  <div>
    <div css={classes.section}>
      <Typography variant="h2" sx={{ mb: 3 }}>
        Please tell us how your business can solve this RFI
      </Typography>

      <Field
        sx={{ mb: 2 }}
        label="Short product description"
        placeholder="20 words maximum"
        name="productDescription"
        component={TextFieldInput}
        validate={[required, maxLength20]}
        multiline
        rows="2"
      />
      <Field
        label="Respond to the RFI"
        placeholder="How would your solution support their business requirement? (100 words maximum)"
        name="challengeRespond"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[required, maxLength100]}
      />
    </div>

    <div css={classes.section}>
      <Typography sx={{ mb: 3 }} variant="h2">
        Product Requirements
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Please respond to the specific product requirements. All product requirement
        questions are yes/no questions, however at the end of this section you can also add
        additional information for consideration.
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Note - providing a product demo video is required in order to be considered, as
        instructed in this section. Please note creating a tailored video addressing the
        clients requirements is proven to be a key success criteria for selection.
      </Typography>

      <FieldArray
        css={classes.field}
        name="questions"
        type="radio"
        component={ChallengeAnswersFieldArray}
      />
      <Field
        sx={{ mb: 2 }}
        label="5 min product demo video URL"
        placeholder="e.g. YouTube or Vimeo"
        name="demonstrationVideo"
        component={TextFieldInput}
        validate={[required, validateVideoLink]}
      />
      <Field
        label="Additional commentaries on functional requirements"
        placeholder="100 words maximum"
        name="additionalCommentOnRequirements"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[maxLength100]}
      />
    </div>

    <FieldArray
      css={classes.section}
      name="openEndedQuestions"
      component={FreeFormAnswersFieldArray}
    />

    <div css={classes.section}>
      <Typography sx={{ mb: 3 }} variant="h2">
        Technical details
      </Typography>
      <Field
        sx={{ mb: 2 }}
        label="Minimum deployment time"
        placeholder="100 words maximum"
        name="minimumDeploymentTime"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[required, maxLength100]}
      />
      <Field
        component={RadioButtonYesNo}
        title="Does your solution conform with GDPR regulations"
        name="GDPRRegulations"
        type="radio"
        validate={[required]}
      />
    </div>

    <div css={classes.section}>
      <Typography sx={{ mb: 3 }} variant="h2">
        References
      </Typography>
      <FieldArray
        css={classes.field}
        name="clientReferences"
        component={ClientReferencesFieldArray}
        maxItems={CLIENT_REFERENCES_LIMIT.MAX}
        validate={validateFieldArrayLength(
          CLIENT_REFERENCES_LIMIT.MIN,
          CLIENT_REFERENCES_LIMIT.MAX,
        )}
      />
      <Field
        sx={{ mb: 2 }}
        label="Provide 1 relevant case study"
        placeholder="100 words maximum"
        name="caseStudyInfo"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[required, maxLength100]}
      />

      <FormLabel component="fieldset" css={classes.field}>
        <FormLabel css={classes.label} component="legend">
          Please upload any additional information on relevant case study
        </FormLabel>
        <FieldArray name="caseStudyAssets" component={CaseStudyUploads} />
      </FormLabel>
    </div>

    <div css={classes.section}>
      <Typography sx={{ mb: 3 }} variant="h2">
        Pricing
      </Typography>
      <Field
        label="Your pricing model"
        placeholder="100 words maximum"
        name="pricingModel"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[required, maxLength100]}
      />
    </div>

    <div css={classes.section}>
      <Field
        sx={{ mb: 2 }}
        label="Any additional information"
        placeholder="100 words maximum"
        name="additionalInfo"
        component={TextFieldInput}
        rows="6"
        multiline
        validate={[maxLength100]}
      />
      <Field name="attachment" component={AttachmentField} />
    </div>
  </div>
);

export default ChallengeResponseForm;
