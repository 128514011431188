import { css } from '@emotion/react';

export const buttonsWrap = css`
  display: flex;
  justify-content: space-between;
`;

export const field = (theme) => (
  css`
    display: block;
    width: 100%;
    margin-bottom: ${theme.spacing(2)};

    .MuiInput-root {
      width: 100%;
    }
  `
);
