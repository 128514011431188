import { css } from '@emotion/react';

const banner = (theme) => (
  css`
    align-items: center;
    margin-bottom: ${theme.spacing(4)};
    color: #fff;
    background-color: ${theme.secondaryPalette.blue};
  `
);

export default banner;
