import PropTypes from 'prop-types';

const DocumentUploadType = PropTypes.exact({
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export default DocumentUploadType;
