import { css } from '@emotion/react';

export const question = (theme) => (
  css`
    display: flex;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const fields = (theme) => (
  css`
    flex: 1;
    margin-right: ${theme.spacing(1)};
  `
);
