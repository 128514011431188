import APIClient from './client';
import eventDashboardFragment from './fragments/eventDashboardFragment';

const defaultSearchParams = {
  FROM: 0,
  SIZE: 100,
};

class CompanyAPI extends APIClient {
  constructor() {
    super('/companies');
  }

  isCompanyNameTaken = async (name) => {
    const filter = { name };

    const { data: { count } } = await this.count(filter);

    return count > 0;
  };

  findCompanyWithTakenEmailDomain = async (domain) => {
    const { data: [company] } = await this.find({
      where: {
        emailDomains: {
          inq: [domain],
        },
      },
      fields: ['id', 'type'],
      limit: 1,
    });

    return company;
  };

  addIntegrationToCompany = (companyId, integrationId) => (
    this.putRequest(`/${companyId}/integrationLabels/rel/${integrationId}`)
  );

  removeIntegrationFromCompany = (companyId, integrationId) => (
    this.deleteRequest(`/${companyId}/integrationLabels/rel/${integrationId}`)
  );

  deleteActiveUserFromCompany = (companyId, userId) => (
    this.deleteRequest(`/${companyId}/users/${userId}`)
  );

  updateRetailerProfile = async (companyId, body) => {
    const { data } = await this.putRequest(`/${companyId}/retailers`, body);

    return data;
  };

  updateVendorProfile = async (companyId, body) => {
    const { data } = await this.putRequest(`/${companyId}/vendors`, body);

    return data;
  };

  updateCompany = async (companyId, body) => {
    const { data } = await this.updateById(companyId, body);

    return data;
  };

  deleteTagFromCompany = (id, tagId) => this.deleteRequest(`/${id}/tags/rel/${tagId}`);

  addTagToCompany = (id, tagId) => this.putRequest(`/${id}/tags/rel/${tagId}`);

  registerUserInExistingCompany = (body) => this.postRequest('/register-user-with-already-exist-company', body);

  getVendorProfile = async (slug) => {
    const filter = {
      include: [
        'vendors',
        'tags',
        'users',
        'integrationLabels',
        'retailers',
        'endorsementsModels',
        {
          relation: 'ratingsReviews',
          scope: {
            include: [
              {
                relation: 'user',
                scope: {
                  include: {
                    relation: 'company',
                    scope: {
                      fields: ['id', 'name', 'smallLogoLink'],
                    },
                  },
                },
              },
              'voteReviews',
              {
                relation: 'company',
                scope: {
                  fields: ['logo', 'name'],
                },
              },
            ],
          },
        },
      ],
      where: {
        slug,
      },
    };

    const { data: [company] } = await this.find(filter);

    return company;
  };

  contactVendor = (body) => this.postRequest('/contact', body);

  contactValidifyAdmin = (body) => this.postRequest('/new-account-query', body);

  getResponderCompanyBySlug = (slug) => {
    const filter = {
      where: {
        slug,
      },
      fields: ['id', 'name', 'logo', 'contactName', 'slug'],
      include: {
        relation: 'vendors',
        scope: {
          fields: 'shortDescription',
        },
      },
    };

    return this.findOne(filter);
  };

  getVendorCompanyForSavedList = (id) => {
    const filter = {
      fields: ['id', 'name', 'logo', 'slug'],
      include: ['vendors', 'ratingsReviews', 'tags'],
    };

    return this.findById(id, filter);
  };

  savePrimaryContact = (body) => this.postRequest('/save-primary-contact', body);

  getCompanyIntegrations = async (id) => {
    const filter = { include: ['integrationLabels'] };

    const { data } = await this.findById(id, filter);

    return data;
  };

  searchCompaniesByKeyword = async (
    keyword,
    from = defaultSearchParams.FROM,
    size = defaultSearchParams.SIZE,
  ) => {
    if (!keyword) {
      return [];
    }

    const { data } = await this.getRequest(
      '/search',
      null,
      {
        keyWord: keyword,
        from,
        size,
        showOnlyApproved: true,
      },
    );

    return data;
  };

  shareVendorProfile = (body) => this.postRequest('/share', body);

  requestEmailDomainsChange = (id, body) => (
    this.postRequest(`/${id}/request-email-domains-change`, body)
  );

  requestApprove = (id) => this.postRequest(`/${id}/request-approve`);

  getRetailerEvents = (id) => this.getRequest(`/${id}/retailerEvents`, eventDashboardFragment);

  getVendorEvents = (id) => this.getRequest(`/${id}/vendorEvents`, eventDashboardFragment);

  getRetailerEventsForChallengeDashboard = (id) => (
    this.getRequest(
      `/${id}/retailerEvents`,
      { fields: ['id', 'name'] },
    )
  );

  getCompanyBySlug = async (slug) => {
    const { data: [company] } = await this.find({
      where: { slug },
      fields: ['id'],
    });

    return company;
  };
}

const companyAPI = new CompanyAPI();

export default companyAPI;
