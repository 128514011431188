/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Typography from '@mui/material/Typography';

import RegisterForm from 'components/Auth/RegisterForm';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import routes from 'constants/routes';
import userAPI from 'api/user';
import eventAPI from 'api/event';
import getErrorMessage from 'utils/getErrorMessage';
import { UIContext } from 'containers/UIProvider';
import { authCookies, localStorageAuthKey } from 'constants/storageKeys';
import registrationReason from 'constants/registrationReason';
import VENDOR_INFO_TABS from 'components/VendorInfo/constants';
import preRegisteredUserFields from './preRegisteredUserFields';
import content from './styles';

const Register = ({ data }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { onSetUserLoaded } = useContext(UIContext);

  const handleAuthenticateUserAfterRegistration = (token, redirectLocation) => {
    const { userId, id } = token;

    Cookies.set(authCookies.USER_ID, userId);
    Cookies.set(authCookies.TOKEN, id);
    localStorage.setItem(localStorageAuthKey, true);

    onSetUserLoaded(false);
  };

  const handleRegisterPreRegisteredVendorForChallenge = async (values) => {
    const { data: token } = await userAPI.registerPreRegisteredVendorForChallenge(values);

    const { challengeId } = data;

    const redirectUrl = generatePath(routes.CHALLENGE_RESPONSE_FORM, { challengeId });

    handleAuthenticateUserAfterRegistration(token);

    history.replace(redirectUrl);
  };

  const handleRegisterRetailerForReview = async (values) => {
    const { data: token } = await userAPI.registerRetailerForReview(values);

    const search = new URLSearchParams({ openModal: true }).toString();
    const pathname = generatePath(routes.VENDOR_PROFILE_TAB, {
      slug: data.companySlug,
      tab: VENDOR_INFO_TABS.CONTACT.path,
    });

    handleAuthenticateUserAfterRegistration(token);

    history.replace({ pathname, search });
  };

  const handleRegisterEventAttendee = async ({ eventId, ...rest }) => {
    const { data: token } = await userAPI.registerForEvent(rest);

    handleAuthenticateUserAfterRegistration(token);

    const { data: event } = await eventAPI.findById(eventId);

    const [slug] = event.slugs;

    const search = new URLSearchParams({ isOnboarding: true }).toString();
    const pathname = generatePath(routes.EDIT_RETAILER_EVENT_BUSINESS_PRIORITIES, { slug });

    history.replace({ pathname, search });
  };

  const handleRegisterVendorForSharedChallenge = async (values) => {
    const { data: token } = await userAPI.registerVendorForSharedChallenge(values);

    const redirectUrl = generatePath(
      routes.CHALLENGE_RESPONSE_FORM,
      { challengeId: data.challengeId },
    );

    handleAuthenticateUserAfterRegistration(token);

    history.replace(redirectUrl);
  };

  const handleSubmit = async (values) => {
    // TODO: move this flow to API
    const { reason, ...body } = values;

    try {
      if (reason === registrationReason.INVITED_TO_COMPANY) {
        await userAPI.registerInvitedUser(values);

        history.push({
          pathname: routes.REGISTRATION_SUCCESS,
          search: new URLSearchParams({ isInvited: true }).toString(),
        });
      } else if (reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE) {
        await handleRegisterPreRegisteredVendorForChallenge(body);
      } else if (reason === registrationReason.INVITED_FOR_REVIEW) {
        await handleRegisterRetailerForReview(body);
      } else if (reason === registrationReason.JOINING_TO_COMPANY) {
        await userAPI.registerWithTakenEmailDomain(body);
        history.push(routes.REGISTRATION_SUCCESS);
      } else if (reason === registrationReason.PRE_REGISTERED_FOR_EVENT) {
        await handleRegisterEventAttendee(body);
      } else if (reason === registrationReason.INVITED_TO_SHARED_CHALLENGE) {
        await handleRegisterVendorForSharedChallenge(body);
      } else {
        await userAPI.registerNewUserWithCompany(body);
        history.push(routes.REGISTRATION_SUCCESS);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error, 'Failed to send request');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const handleGetInitialValues = () => {
    if (data && data.reason === registrationReason.INVITED_FOR_REVIEW) {
      return omit(data, 'companySlug');
    }

    if (data && data.reason === registrationReason.PRE_REGISTERED_FOR_CHALLENGE) {
      return pick(data, preRegisteredUserFields);
    }

    return data;
  };

  return (
    <PublicPageContentWrapper css={content}>
      <Typography sx={{ mb: 1 }} variant="h1">Register</Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Validify Access is where consumer brands source, evaluate and procure technology
        solutions.
      </Typography>
      <RegisterForm
        onSubmitUser={handleSubmit}
        initialValues={handleGetInitialValues()}
      />
    </PublicPageContentWrapper>
  );
};

Register.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      // registrationReason.INVITED_FOR_REVIEW
      reason: PropTypes.string.isRequired,
      companySlug: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      // registrationReason.INVITED_TO_COMPANY
      reason: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      // registrationReason.PRE_REGISTERED_FOR_CHALLENGE
      reason: PropTypes.string.isRequired,
      challengeId: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      companyWebsite: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      phoneNumber: PropTypes.shape({
        code: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
      }).isRequired,
      status: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      // registrationReason.PRE_REGISTERED_FOR_EVENT
      reason: PropTypes.string.isRequired,
      companyId: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      position: PropTypes.string,
      companyWebsite: PropTypes.string,
      companyName: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      // registrationReason.INVITED_TO_SHARED_CHALLENGE
      reason: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      challengeId: PropTypes.string.isRequired,
    }),
  ]),
};

Register.defaultProps = {
  data: null,
};

export default Register;
