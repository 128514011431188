/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GeneralModal from 'components/UI/GeneralModal';
import challengeResponseAPI from 'api/challengeResponse';
import routes from 'constants/routes';
import modalFooterStyles from './styles';

const EndChallengeParticipation = ({ challengeId, enqueueSnackbar }) => {
  const [isOpen, setModal] = useState(false);
  const history = useHistory();

  const handleEndParticipation = () => (
    challengeResponseAPI.endChallengeParticipation(challengeId)
      .then(() => {
        enqueueSnackbar('Success', { variant: 'success' });
        history.replace(routes.VENDOR_CHALLENGE_DASHBOARD);
      })
      .catch(() => enqueueSnackbar('Failed to send request', { variant: 'error' }))
  );

  return (
    <>
      <Button variant="outlined" onClick={() => setModal(true)}>
        End participation
      </Button>
      <GeneralModal
        onClose={() => setModal(false)}
        isOpen={isOpen}
        title="Are you sure?"
      >
        <Typography sx={{ mb: 3 }} variant="body1">
          You are about to end your participation in this RFI. Are you sure this is what you want
          to do?
        </Typography>
        <div css={modalFooterStyles}>
          <Button onClick={handleEndParticipation}>Confirm</Button>
        </div>
      </GeneralModal>
    </>
  );
};

EndChallengeParticipation.propTypes = {
  challengeId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(EndChallengeParticipation);
