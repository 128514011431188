import { darken, lighten } from '@mui/material/styles';

const generatePalette = (color) => ({
  main: color,
  light: lighten(color, 0.8),
  dark: darken(color, 0.2),
  veryLight: lighten(color, 0.9),
});

export default generatePalette;
