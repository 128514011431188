/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import EditRounded from '@mui/icons-material/EditRounded';
import formatDate from 'utils/formatDate';
import MilestoneType from 'types/Milestone';
import * as classes from './styles';

const DATE_FORMAT = 'dd/MM/yyyy';

const SavedMilestones = ({ className, milestones, onEdit }) => {
  if (!milestones.length) {
    return null;
  }

  return (
    <Typography
      className={className}
      css={classes.list}
      variant="body1"
      component="ol"
    >
      {milestones.map((milestone, i) => (
        <li key={milestone.id} css={classes.entryWrap}>
          <span css={classes.entry}>
            <Typography variant="body1">
              {milestone.name} - {formatDate(milestone.dueDate, DATE_FORMAT)}
            </Typography>
            {!!onEdit && (
              <Fab size="small" onClick={() => onEdit(i)}>
                <EditRounded fontSize="small" />
              </Fab>
            )}
          </span>
        </li>
      ))}
    </Typography>
  );
};

SavedMilestones.propTypes = {
  className: PropTypes.string,
  milestones: PropTypes.arrayOf(MilestoneType.isRequired).isRequired,
  onEdit: PropTypes.func,
};

SavedMilestones.defaultProps = {
  className: null,
  onEdit: null,
};

export default SavedMilestones;
