/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Hidden from '@mui/material/Hidden';
import { Bookmark16Regular, Bookmark16Filled } from '@fluentui/react-icons';
import { addVendorToSaved, deleteVendorFromSaved } from 'store/modules/savedVendors';
import Chip from 'components/UI/Chip';
import insertHost from 'utils/picture';
import getVendorAverageRating from 'utils/getVendorAverageRating';
import CompanySearchResultType from 'types/CompanySearchResult';
import * as classes from './styles';

const MAX_DISPLAYED_TAGS = 3;

const VendorCompanyCard = ({
  className,
  company,
  onClick,
  searchPathname,
}) => {
  const history = useHistory();

  const {
    clients,
    name,
    founded,
    logo,
    reviews,
    shortDescription,
    tags,
    id,
  } = company;
  const userId = useSelector((state) => state.user.authUser.id);
  const savedVendors = useSelector((state) => state.savedVendors);

  const dispatch = useDispatch();

  const isAuthenticated = !!userId;
  const isVendorSaved = savedVendors.some(({ companyId }) => companyId === id);

  const averageRating = getVendorAverageRating(reviews);
  const selectedTags = tags.slice(0, MAX_DISPLAYED_TAGS);

  const handleTagClick = (tagName) => {
    const search = new URLSearchParams({ query: tagName }).toString();

    history.push({ pathname: searchPathname, search });
  };

  return (
    <div
      className={className}
      css={classes.card}
      onClick={() => onClick(company)}
    >
      <div css={classes.head}>
        <div css={classes.companyDetails}>
          <Typography variant="h2" sx={{ mb: 0.25 }}>{name}</Typography>
          <Typography variant="caption1">
            {founded.where}
          </Typography>

          {averageRating > 0 && (
            <div css={classes.rating}>
              <Typography variant="subtitle2" color="primary">{averageRating}</Typography>
              <Rating
                label="Community rating"
                name="community-rating"
                readOnly
                value={averageRating}
              />
            </div>
          )}
        </div>
        {!!logo && (<img css={classes.logo} src={insertHost(logo)} alt="" />)}
      </div>

      {shortDescription && (
        <Typography sx={{ mb: 2 }} variant="body2">
          {shortDescription}
        </Typography>
      )}

      {!!clients && (
        <>
          <Typography variant="subtitle2">Clients</Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            {clients.join(', ')}
          </Typography>
        </>
      )}

      <div css={classes.footer}>
        <div onClick={(e) => e.stopPropagation()} css={classes.tags}>
          {selectedTags.map((tag) => (
            <Chip
              key={tag.id}
              clickable
              color="primary"
              label={tag.name}
              onClick={() => handleTagClick(tag.name)}
            />
          ))}
        </div>

        {isAuthenticated && (
          <FormGroup>
            <FormControlLabel
              css={classes.checkbox}
              control={isVendorSaved
                ? <Bookmark16Filled css={classes.icon} />
                : <Bookmark16Regular css={classes.icon} />
              }
              checked={isVendorSaved}
              label={(
                <Hidden smDown>
                  {isVendorSaved ? 'Saved' : 'Save'}
                </Hidden>
              )}
              // Important: when a vendor is added/removed from saved list, the app shouldn't
              // open profile
              onClick={(e) => {
                e.stopPropagation();
                isVendorSaved
                  ? dispatch(deleteVendorFromSaved(id))
                  : dispatch(addVendorToSaved(id, userId));
              }}
            >
            </FormControlLabel>
          </FormGroup>
        )}
      </div>
    </div>
  );
};

VendorCompanyCard.propTypes = {
  className: PropTypes.string,
  company: CompanySearchResultType.isRequired,
  onClick: PropTypes.func.isRequired,
  searchPathname: PropTypes.string.isRequired,
};

VendorCompanyCard.defaultProps = {
  className: null,
};

export default VendorCompanyCard;
