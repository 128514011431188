export const AGENDA_TAB = 'AGENDA';

export const eventTabNames = {
  EVENT_DETAILS: 'Event details',
  ATTENDEES: 'Attendees',
  [AGENDA_TAB]: 'Agenda',
};

export const vendorEventTabIds = {
  [eventTabNames.EVENT_DETAILS]: 0,
  [eventTabNames.ATTENDEES]: 1,
  [eventTabNames[AGENDA_TAB]]: 2,
};
