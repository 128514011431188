import VendorChallengeDashboard from 'components/VendorChallengeDashboard/AllChallengesPage';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import withRouteGuards from 'containers/withRouteGuards';
import { approvedCompanyGuard, authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const VendorChallengeDashboardPage = () => {
  const getInitialProps = async () => {
    const { data: challenges } = await challengeAPI.getVendorChallenges();

    return { challenges };
  };

  return <FetchContainer getInitialProps={getInitialProps} component={VendorChallengeDashboard} />;
};

export default withRouteGuards([authorizedRouteGuard, approvedCompanyGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGES_DASHBOARD)(VendorChallengeDashboardPage)),
);
