/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import { primaryPalette } from 'theme/mainTheme';
import DarkLogo from 'svg/darkLogo';
import LightLogo from 'svg/lightLogo';
import checkHasAuthToken from 'utils/checkHasAuthToken';
import routes from 'constants/routes';
import { EMAIL_SUPPORT } from 'constants/contacts';
import * as classes from './styles';

const ErrorContainer = ({
  className,
  title,
  subtitle,
  description,
  isSupportLinkShown,
}) => {
  const theme = useTheme();

  const getHomeUrl = () => {
    const isAuthenticated = checkHasAuthToken();

    return isAuthenticated ? routes.MAIN : routes.LOGIN;
  };

  return (
    <div className={className} css={classes.errorContainer}>
      <div css={classes.top}>
        {/* TODO: should check is dark mode from a single theme. Now the app has two themes (global
          * and for event) and this check is used in order detect that an event theme uses dark
          * mode.
          */}
        {theme.backgroundColor === primaryPalette.grey ? (
          <LightLogo css={classes.logo} />
        ) : (
          <DarkLogo css={classes.logo} />
        )}
      </div>
      <Typography css={classes.title} variant="h1">{title}</Typography>
      {!!subtitle && <Typography css={classes.subtitle}>{subtitle}</Typography>}
      <Typography sx={{ mb: 3 }} variant="body1">{description}</Typography>
      <Button
        css={classes.getButtonStyles(primaryPalette.primaryYellow)}
        component={RouterLink}
        to={getHomeUrl()}
      >
        Home
      </Button>
      {isSupportLinkShown && (
        <div css={classes.support}>
          <Link href={`mailto:${EMAIL_SUPPORT}`} variant="body1">
            Or contact support
          </Link>
        </div>
      )}
    </div>
  );
};

ErrorContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  isSupportLinkShown: PropTypes.bool,
};

ErrorContainer.defaultProps = {
  className: null,
  subtitle: null,
  isSupportLinkShown: false,
};

export default ErrorContainer;
