import { css } from '@emotion/react';

export const formControl = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};

    .MuiFormControlLabel-root {
      margin: 0;
    }
  `
);

export const radioGroup = (theme) => (
  css`
    padding-left: ${theme.spacing(1)};
  `
);

export const select = css`
   max-width: 300px;

  & option:disabled {
    color: rgba(112, 112, 112, 0.4);
  }
`;
