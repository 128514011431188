import userAPI from 'api/user';
import userRoleAPI from 'api/userRole';
import companyStatus from 'constants/companyStatus';
import { sendErrorNotification, sendSuccessNotification } from '../modules/notifications';

import * as actions from '../actions';

export const clearAndLogout = () => (dispatch) => {
  dispatch(actions.resetUserState());
};

export const getUser = (id) => async (dispatch, getState) => {
  const state = getState();
  const isProfileLoaded = !!state.user.authUser.id && state.user.authUser.id === id;

  if (isProfileLoaded) {
    return;
  }

  const user = await userAPI.getUserWithCompany(id);

  const {
    type,
    retailerEvents,
    vendorEvents,
  } = user.company;

  const eventMenu = type === companyStatus.RETAILER ? retailerEvents : vendorEvents;

  dispatch(actions.getUserSuccess(user, eventMenu));
};

export const updateUserThunk = (id, body) => async (dispatch) => {
  const { data: user } = await userAPI.updateById(id, body);

  dispatch(actions.updateUser(user));
};

export const updateUserRole = (userId, role) => async (dispatch) => {
  try {
    await userRoleAPI.assignRole(userId, role.id);
    dispatch(actions.updateUserRoleSuccess(userId, role));
    dispatch(sendSuccessNotification('Role successfully assigned'));
  } catch (err) {
    dispatch(sendErrorNotification(err));
  }
};

export const deleteUserRole = (id) => async (dispatch, getState) => {
  const state = getState();
  const { users } = state.user.company;

  const { invitedRoles: [currentRole] } = users.find(({ id: userId }) => userId === id);

  try {
    await userAPI.deleteUserRole(id, currentRole.id);
    dispatch(actions.deleteUserRoleSuccess(id));
    dispatch(sendSuccessNotification('Role successfully removed'));
  } catch (err) {
    dispatch(sendErrorNotification(err));
  }
};
