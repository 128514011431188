import get from 'lodash/get';
import httpResponseCodes from 'constants/httpResponseCodes';
import getErrorMessage from './getErrorMessage';

const handlePageDataFetchError = (error) => {
  const status = get(error, 'response.status');

  if (status < httpResponseCodes.INTERNAL_SERVER_ERROR) {
    const errorMessage = getErrorMessage(error);

    return errorMessage;
  }

  throw error;
};

export default handlePageDataFetchError;
