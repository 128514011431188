/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import reasonChange from 'constants/reasonChangeAutocomplete';
import tagAPI from 'api/tag';
import suggestionStyles from './styles';

const DEBOUNCE_TIMEOUT = 500;
const MIN_INPUT_LENGTH = 2;

const requirementTagsInput = (params) => (
  <TextField {...params} fullWidth label="Tags" />
);

const renderSelectedProperties = (selectedOptions, getPropertyProps) => (
  selectedOptions.map((option, index) => (
    <Chip
      key={option.id}
      color="primary"
      variant="outlined"
      label={option}
      {...getPropertyProps({ index })}
    />
  ))
);

const RequirementTagsAutocomplete = ({ className, tags, onSetTags }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestedTags, setSuggestedTags] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const formatValue = (value) => value.toLowerCase().trim();

  const onInputChange = (_, newInputValue) => setInputValue(newInputValue);

  const handleSearch = useMemo(() => debounce((query, isActive) => {
    const formattedQuery = formatValue(query);

    if (!query) {
      setSuggestedTags([]);
    } else {
      tagAPI.getSuggestions(formattedQuery)
        .then((results) => {
          if (isActive) {
            setSuggestedTags(results.map(({ name }) => name));
          }
        })
        .catch(() => enqueueSnackbar('Failed to search', { variant: 'error' }));
    }
  }, DEBOUNCE_TIMEOUT), [enqueueSnackbar]);

  useEffect(() => {
    let active = true;

    handleSearch(inputValue, active);

    return () => {
      active = false;
    };
  }, [inputValue, handleSearch]);

  const renderSuggestion = (props, option) => (
    <MenuItem key={option} value={option} css={suggestionStyles} {...props}>
      <Checkbox checked={tags.some((tag) => tag === option)} />
      <ListItemText
        primary={option}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </MenuItem>
  );

  const handleChange = (_, newValue, reason) => {
    if (reason === reasonChange.SELECT_OPTION || reason === reasonChange.REMOVE_OPTION) {
      onSetTags(newValue);
    }
  };

  return (
    <Autocomplete
      className={className}
      id="requirement-tag-autocomplete"
      disableClearable
      inputValue={inputValue}
      multiple
      options={suggestedTags}
      autoComplete
      getOptionLabel={(option) => option}
      includeInputInList
      noOptionsText={<Typography variant="body2">No options</Typography>}
      autoSelect
      value={tags}
      onChange={handleChange}
      open={inputValue.length > MIN_INPUT_LENGTH}
      onInputChange={onInputChange}
      renderInput={requirementTagsInput}
      renderTags={renderSelectedProperties}
      renderOption={renderSuggestion}
    />
  );
};

RequirementTagsAutocomplete.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSetTags: PropTypes.func.isRequired,
};

RequirementTagsAutocomplete.defaultProps = {
  className: null,
};

export default RequirementTagsAutocomplete;
