import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, propTypes } from 'redux-form';
import clsx from 'clsx';
import SubmitButton from 'components/UI/SubmitButton';
import TextFieldInput from 'components/UI/TextFieldInput';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import './AddUserForm.scss';

const AddUserForm = ({
  className,
  handleSubmit,
  submitting,
  submitSucceeded,
  reset,
}) => {
  useEffect(() => {
    if (submitSucceeded) {
      reset();
    }
  }, [submitSucceeded, reset]);

  return (
    <div className={clsx('add-user-form', className)}>
      <Field
        className="add-user-form__field"
        type="email"
        name="email"
        validate={[required, validateEmail, validateIsCorporateEmailDomain]}
        component={TextFieldInput}
      />
      <SubmitButton isLoading={submitting} onClick={handleSubmit}>
        Invite user
      </SubmitButton>
    </div>
  );
};

AddUserForm.propTypes = {
  className: PropTypes.string,
  ...propTypes,
};

AddUserForm.defaultProps = {
  className: null,
};

export default reduxForm({ form: 'addUserToCompanyForm' })(AddUserForm);
