import { css } from '@emotion/react';

const buttonStyles = (theme) => (
  css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    padding: 0;
    color: ${theme.primaryPalette.menuGrey};
    text-align: left;
    cursor: pointer;
    outline: none;

    &,
    &:hover,
    &:focus,
    &:active {
      border: none;
      background: none;
    }
  `
);

export default buttonStyles;
