/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { required } from 'lib/validation';
import TextFieldInput from 'components/Form/TextFieldInput';
import * as classes from './styles';

const UserPhoneFields = ({ className, isRequired }) => (
  <fieldset className={className}>
    <div css={classes.fieldsContainer}>
      <Field
        validate={isRequired && required}
        css={classes.codeField}
        placeholder="+44"
        name="phoneNumber.code"
        component={TextFieldInput}
      />
      <Field
        label="Phone number"
        validate={isRequired && required}
        css={classes.number}
        placeholder="0999999999"
        name="phoneNumber.number"
        component={TextFieldInput}
      />
    </div>
  </fieldset>
);

UserPhoneFields.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

UserPhoneFields.defaultProps = {
  className: null,
  isRequired: false,
};

export default UserPhoneFields;
