import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import SelectField from 'components/Form/SelectField';
import SubmitButton from 'components/UI/SubmitButton';

const CompleteChallenge = ({ responders, onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field
          fullWidth
          name="winnerId"
          component={SelectField}
          label="Select a winner of the RFI"
          placeholder="Select a company"
          showEmptyOption={false}
          normalize={(value) => value || null}
          emptyOptionLabel="No winner"
          sx={{ mb: 2 }}
        >
          {responders.map((company) => (
            <option key={company.id} value={company.id}>{company.name}</option>
          ))}
        </Field>
        <SubmitButton isLoading={submitting} type="submit">Save</SubmitButton>
      </form>
    )}
  />
);

CompleteChallenge.propTypes = {
  responders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

CompleteChallenge.defaultProps = {
  initialValues: null,
};

export default CompleteChallenge;
