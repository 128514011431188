import { useContext } from 'react';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import routes from 'constants/routes';
import { authCookies, localStorageAuthKey } from 'constants/storageKeys';
import { UIContext } from 'containers/UIProvider';

const useLogout = () => {
  const history = useHistory();
  const { onSetUserLoaded } = useContext(UIContext);

  const handleLogout = (redirectUrl = routes.LOGIN) => {
    cookies.remove(authCookies.USER_ID);
    cookies.remove(authCookies.TOKEN);
    localStorage.removeItem(localStorageAuthKey);
    onSetUserLoaded(false);
    history.replace(redirectUrl);
  };

  return handleLogout;
};

export default useLogout;
