/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Clear from '@mui/icons-material/Clear';
import TextFieldInput from 'components/UI/TextFieldInput';
import Checkbox from 'components/Form/Checkbox';
import { maxLength } from 'lib/validation';
import * as classes from './styles';

const MAX_QUESTION_WORD_COUNT = 50;
const validateQuestionWordCount = maxLength(MAX_QUESTION_WORD_COUNT);

const OpenEndedQuestionFieldArray = ({ fields }) => (
  <div>
    {fields.map((field, i) => (
      <div key={field} css={classes.question}>
        <div css={classes.fields}>
          <Field
            sx={{ mb: 1 }}
            name={`${field}.question`}
            component={TextFieldInput}
            validate={[validateQuestionWordCount]}
            placeholder="Enter your requirement here"
            multiline
            rows="4"
          />
          <Field
            name={`${field}.isRequired`}
            type="checkbox"
            component={Checkbox}
            label="Response is mandatory?"
          />
        </div>
        <Fab onClick={() => fields.remove(i)} size="small">
          <Clear fontSize="small" />
        </Fab>
      </div>
    ))}

    <Button onClick={() => fields.push({ question: '', isRequired: false })}>
      Add requirement
    </Button>
  </div>
);

OpenEndedQuestionFieldArray.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

export default OpenEndedQuestionFieldArray;
