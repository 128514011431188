import { css } from '@emotion/react';

export const container = css`
  position: relative;
`;

export const remove = css`
  position: absolute;
  left: 100%;
  transform: translate(-50%, -50%);
`;
