import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';
import routes from 'constants/routes';
import getMatchType from 'types/Match';

const ChallengeResponseForRetailerRedirect = ({ match }) => {
  const { challengeId, companySlug } = match.params;

  const responseUrl = generatePath(routes.CHALLENGE_RESPONSE_FOR_RETAILER,
    { challengeId, companySlug });

  return <Redirect to={responseUrl} />;
};

ChallengeResponseForRetailerRedirect.propTypes = {
  match: getMatchType({
    challengeId: PropTypes.string.isRequired,
    companySlug: PropTypes.string.isRequired,
  }),
};

export default ChallengeResponseForRetailerRedirect;
