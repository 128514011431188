import { css } from '@emotion/react';

export const editorContainer = (theme) => (
  css`
    margin-bottom: ${theme.spacing(5)};
  `
);

export const getScaledImageClassName = (scale) => (
  css`
    transform: scale(${scale});
  `
);

export const title = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const zoomIcon = (theme) => (
  css`
    color: ${theme.primaryPalette.grey};
  `
);

export const buttons = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
`;

export const input = css`
  display: none;
`;
