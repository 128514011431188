const videoPathGenerator = (value) => {
  let result = '';

  if (value && value.indexOf('youtube') > -1) {
    const arr = value.split('&');
    result = arr[0].replace('watch?v=', 'embed/');
  } else if (value && value.indexOf('vimeo') > -1) {
    const arr = value.split('/');
    const vimeoId = arr.slice(-1);
    result = `https://player.vimeo.com/video/${vimeoId}`;
  } else if (value) {
    result = value;
  }

  return result;
};

export default videoPathGenerator;
