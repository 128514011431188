/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  reduxForm,
  propTypes,
  formValueSelector,
  FieldArray,
} from 'redux-form';
import pick from 'lodash/pick';
import get from 'lodash/get';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { COUNTRIES } from 'constants/countries';
import TextFieldInput from 'components/UI/TextFieldInput';
import DropDownInput from 'components/UI/DropDownInput';
import OfficeAddressFormSection from 'components/Shared/OfficeAddressFormSection';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import BasicCompanyInfoFormSection from 'components/Shared/BasicCompanyInfoFormSection';
import {
  maxLength100,
  maxLength1000,
  maxLength15,
  maxLength200,
  required,
  validateFieldArrayLength,
  validateTagsArrayLength,
  validateYearsFounded,
} from 'lib/validation';
import CustomFieldArray from 'components/UI/CustomFieldArray';
import CompanyTagFieldArray from 'components/Shared/CompanyTagFieldArray';
import getInitialFieldArrayValue from 'utils/getInitialFieldArrayValue';
import focusFirstInvalidFormControl from 'utils/focusFirstInvalidFormControl';
import {
  BUSINESS_BENEFITS_FIELDS_RESTRICTIONS,
  CLIENTS_FIELDS_RESTRICTIONS,
  MARKET_COUNTRIES,
  TAGS_RESTRICTIONS,
} from '../Profile/Solution/constants';
import * as classes from './styles';

class BasicDetails extends Component {
  static propTypes = {
    companyName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    onUpdateProfile: PropTypes.func.isRequired,
    vendorEmail: PropTypes.string.isRequired,
    countryValue: PropTypes.string,
    ...propTypes,
  };

  static defaultProps = {
    countryValue: null,
  };

  handleSubmitBasicDetails = (isDraft) => ({ vendors, bussinessBenefits, ...rest }) => {
    const { onUpdateProfile } = this.props;

    const values = {
      vendors: {
        ...vendors,
        productService: { ...vendors.productService, bussinessBenefits },
        areBasicDetailsSaved: true,
      },
      ...rest,
    };

    return onUpdateProfile(values, isDraft);
  };

  render() {
    const {
      handleSubmit,
      dirty,
      valid,
      userId,
      form,
      submitSucceeded,
      countryValue,
    } = this.props;

    return (
      <DataCaptureFormContainer
        isDirty={dirty}
        isValid={valid}
        submitSucceeded={submitSucceeded}
        onSubmit={handleSubmit(this.handleSubmitBasicDetails(true))}
      >
        <Form
          name={form}
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmitBasicDetails(false))}
          // Disable HTML5 validation
          noValidate
        >
          <div css={classes.section}>
            <Typography sx={{ mb: 3 }} variant="h2">
              Company details
            </Typography>
            <BasicCompanyInfoFormSection
              css={classes.field}
              uploadFieldName="logo"
              userId={userId}
            />
            <Field
              label="Company overview"
              sx={{ mb: 2 }}
              placeholder="Please provide a brief overview of your company. (100 words maximum)"
              name="vendors.companyOverview"
              multiline
              rows="12"
              validate={[required, maxLength100]}
              component={TextFieldInput}
            />
            <Field
              fullWidth
              sx={{ mb: 2 }}
              name="vendors.founded.where"
              component={DropDownInput}
              label="Founded where"
              validate={required}
            >
              {COUNTRIES.map((contry) => (
                <option value={contry} key={contry}>
                  {contry}
                </option>
              ))}
            </Field>
            <Field
              sx={{ mb: 2 }}
              label="Year founded"
              type="number"
              name="vendors.founded.when"
              placeholder="YYYY"
              validate={[required, validateYearsFounded]}
              component={TextFieldInput}
            />
            <CustomFieldArray
              css={classes.field}
              minItems={CLIENTS_FIELDS_RESTRICTIONS.MIN}
              maxItems={CLIENTS_FIELDS_RESTRICTIONS.MAX}
              label="Clients"
              arrayValidators={[
                validateFieldArrayLength(
                  CLIENTS_FIELDS_RESTRICTIONS.MIN,
                  CLIENTS_FIELDS_RESTRICTIONS.MAX,
                ),
              ]}
              name="vendors.clientsList"
              buttonText="Add client"
            />

            <FieldArray
              css={classes.field}
              name="tags"
              label="Add tags that best describe the solution and service you provide (Choose between 5 and 10 tags)"
              component={CompanyTagFieldArray}
              validate={[validateTagsArrayLength(TAGS_RESTRICTIONS.MIN, TAGS_RESTRICTIONS.MAX)]}
            />
            <CustomFieldArray
              minItems={MARKET_COUNTRIES.MIN}
              fieldValidators={[maxLength15]}
              arrayValidators={[validateFieldArrayLength(MARKET_COUNTRIES.MIN)]}
              label="Add all countries where you have an office/operation"
              name="vendors.marketCountries"
              buttonText="Add country"
            />
          </div>

          <OfficeAddressFormSection css={classes.section} countryValue={countryValue} />

          <div css={classes.section}>
            <Typography sx={{ mb: 3 }} variant="h2">
              Solution
            </Typography>
            <Field
              sx={{ mb: 2 }}
              label="Introduce your solution"
              placeholder="Please provide a description of your solution.(200 words maximum)"
              name="vendors.shortDescription"
              type="text"
              multiline
              rows="6"
              validate={[required, maxLength200]}
              component={TextFieldInput}
            />
            <CustomFieldArray
              css={classes.field}
              minItems={BUSINESS_BENEFITS_FIELDS_RESTRICTIONS.MIN}
              maxItems={BUSINESS_BENEFITS_FIELDS_RESTRICTIONS.MAX}
              label="Business Benefits"
              fieldValidators={[maxLength15]}
              arrayValidators={[
                validateFieldArrayLength(
                  BUSINESS_BENEFITS_FIELDS_RESTRICTIONS.MIN,
                  BUSINESS_BENEFITS_FIELDS_RESTRICTIONS.MAX,
                ),
              ]}
              name="bussinessBenefits"
            />

            <Field
              label="Solution details"
              placeholder="Tell us a bit more about the problem your solution solves (1000 words maximum)"
              name="vendors.solutionOverview"
              type="text"
              multiline
              rows="12"
              validate={[required, maxLength1000]}
              component={TextFieldInput}
            />
          </div>
          <Button type="submit">Save</Button>
        </Form>
      </DataCaptureFormContainer>
    );
  }
}

const selector = formValueSelector('basicDetails');

const mapStateToProps = (state) => {
  const { vendors } = state.user.company;
  const { clientsList, marketCountries, ...rest } = vendors;
  const bussinessBenefits = get(vendors, 'productService.bussinessBenefits');

  const countryValue = selector(state, 'offices[0].country');

  return {
    userId: state.user.authUser.id,
    companyName: state.user.company.name,
    vendorEmail: state.user.authUser.email,
    countryValue,
    initialValues: {
      ...pick(state.user.company, ['name', 'tags', 'companyWebsite', 'number', 'logo', 'offices']),
      bussinessBenefits: getInitialFieldArrayValue(bussinessBenefits),
      vendors: {
        ...rest,
        clientsList: getInitialFieldArrayValue(clientsList),
        marketCountries: getInitialFieldArrayValue(marketCountries),
      },
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    enableReinitialize: true,
    form: 'basicDetails',
    onSubmitFail: focusFirstInvalidFormControl,
  })(BasicDetails),
);
