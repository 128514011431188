/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header';
import BurgerIcon from 'components/UI/BurgerIcon';
import NavigationMenu from './NavigationMenu';
import Sidebar from './Sidebar';
import * as classes from './styles';

const GeneralLayout = ({ children }) => {
  const [isOpen, setSidebar] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setSidebar(false);
  }, [location.pathname]);

  const onToggleSidebar = () => setSidebar((prev) => !prev);

  return (
    <div>
      <Header>
        <div css={classes.headerIconContainer}>
          <BurgerIcon css={classes.burger} isOpen={isOpen} onClick={onToggleSidebar} />
        </div>
      </Header>

      <Sidebar isExpanded={isOpen} css={classes.content}>
        <NavigationMenu />
      </Sidebar>

      <main css={[classes.content, classes.mainContent, isOpen && classes.mainContentCollapsed]}>
        {children}
      </main>
    </div>
  );
};

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GeneralLayout;
