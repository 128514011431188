import { useSelector } from 'react-redux';
import EventsDashboard from 'components/Events/EventsDashboard';
import FetchContainer from 'containers/FetchContainer';
import companyAPI from 'api/company';
import withRouteGuards from 'containers/withRouteGuards';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';
import routes from 'constants/routes';

const RetailerEventsDashboardPage = () => {
  const companyId = useSelector((state) => state.user.company.id);

  const getInitialProps = async () => {
    const { data: events } = await companyAPI.getRetailerEvents(companyId);

    return { events };
  };

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={EventsDashboard}
      eventPagePathname={routes.RETAILER_EVENT_DETAILS} />
  );
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.EVENTS_DASHBOARD)(RetailerEventsDashboardPage)),
);
