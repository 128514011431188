const pageTitles = {
  CHALLENGE: 'RFI - Validify',
  CHALLENGES_DASHBOARD: 'RFIs dashboard - Validify',
  CHALLENGE_RESPONSE: 'Response on RFI - Validify',
  CHALLENGE_RESPONSES_DASHBOARD: 'RFIs responses dashboard - Validify',
  CREATE_NEW_CHALLENGE: 'Create a new RFI - Validify',
  EDIT_COMPANY_PROFILE: 'Edit company profile - Validify',
  EDIT_USER_PROFILE: 'Edit my profile - Validify',
  EDIT_USER: 'Edit user - Validify',
  EDIT_SAVED_CHALLENGE: 'Edit RFI - Validify',
  EVENT_VENDOR_BUSINESS_PRIORITIES: 'Event meeting priorities - Validify',
  EVENT_RETAILER_BUSINESS_PRIORITIES: 'Event business priorities - Validify',
  EVENT_CHALLENGE_FORM: 'Create an RFI for the event - Validify',
  EVENT_PAGE: 'Event details - Validify',
  EVENTS_DASHBOARD: 'Events dashboard - Validify',
  FORGOT_PASSWORD: 'Forgot password - Validify',
  LOGIN: 'Login - Validify',
  MAIN: 'Home - Validify',
  MANAGE_PRIMARY_COMPANY_CONTACT: 'My company, primary contact - Validify',
  MANAGE_USERS: 'My company, users - Validify',
  MANAGE_EMAIL_DOMAINS: 'My company, email domains - Validify',
  PRIVACY: 'Privacy policy - Validify',
  RATINGS_REVIEWS: 'Reviews, my reviews - Validify',
  REGISTER: 'Registration - Validify',
  REGISTRATION_SUCCESS: 'Registration, thank you for registration - Validify',
  REQUEST_REVIEW: 'Reviews, request review - Validify',
  SAVED_VENDORS: 'Saved solutions - Validify',
  SEARCH_SOLUTION_PAGE: 'Solution search - Validify',
  RECOVER_PASSWORD: 'Recover password - Validify',
  TERMS_AND_CONDITIONS: 'Terms & conditions - Validify',
  USER_PROFILE: 'My profile - Validify',
  VENDOR_PROFILE: 'Vendor profile - Validify',
  VENDOR_PAYMENTS: 'Payments - Validify',
  DEFAULT: 'Validify',
};

export default pageTitles;
