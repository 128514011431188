import { css } from '@emotion/react';

const step = (theme) => (
  css`
    font-family: ${theme.boldFont};
    color: ${theme.primaryPalette.signalRed};
  `
);

export default step;
