/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { ArrowExit20Regular } from '@fluentui/react-icons';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ApproveCompanyTooltip from 'components/Shared/ApproveCompanyTooltip';
import useLogout from 'utils/useLogout';
import checkHasAuthToken from 'utils/checkHasAuthToken';
import MenuItemShape from 'types/MenuItem';
import LocationType from 'types/Location';
import * as classes from './styles';

const listItemTypographyProps = {
  variant: 'subtitle2',
  component: 'span',
};

const subListItemTypographyProps = {
  variant: 'body2',
  component: 'span',
};

const NavigationMenu = ({
  menu,
  history,
  location,
  onDisabledClick,
  className,
  vendorCompanyName,
}) => {
  const handleLogout = useLogout();

  const match = useRouteMatch();

  const isAuthenticated = checkHasAuthToken();

  const checkIsMenuItemSelected = ({ to, submenu }) => {
    const { pathname } = location;

    return (
      to === pathname
      || (submenu && submenu.some(({ to: submenuPath }) => submenuPath === pathname))
    );
  };

  return (
    <div css={classes.wrapper}>
      {!!vendorCompanyName && (
        <div css={classes.name}>{vendorCompanyName}</div>
      )}
      <List
        className={className}
        css={[classes.list, !!vendorCompanyName && classes.vendorProfileMenu]}
        disablePadding
      >
        {menu.map((item) => {
          const isSelected = (
            (item.isSelected && item.isSelected(match))
            || checkIsMenuItemSelected(item)
          );
          const Icon = item.icon;

          const menuItem = (
            <Fragment key={item.to}>
              <ListItemButton
                disabled={item.disabled}
                css={classes.listItemOuter}
                selected={isSelected}
                onClick={() => history.push(item.to)}
              >
              <span css={classes.listItemInner}>
                {item.icon && (<Icon css={classes.menuItemIcon} />)}
                <ListItemText
                  component="span"
                  css={classes.listItemText}
                  primary={item.title}
                  primaryTypographyProps={listItemTypographyProps}
                />
              </span>
              </ListItemButton>
              {!!(item.submenu && item.submenu.length) && (
                <Collapse css={isSelected && classes.subListOpen} in={isSelected}>
                  <List
                    onClick={() => onDisabledClick && onDisabledClick()}
                    css={classes.subList}
                  >
                    {item.submenu.map((subitem) => (
                      <ListItemButton
                        key={subitem.to}
                        css={[
                          classes.listItemOuter,
                        ]}
                        disabled={subitem.disabled}
                        selected={subitem.isSelected
                          ? subitem.isSelected()
                          : location.pathname === subitem.to
                        }
                        dense
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(subitem.to);
                        }}
                      >
                      <span css={[classes.listItemInner, classes.subListItem]}>
                        <ListItemText
                          css={classes.listItemText}
                          primary={subitem.title}
                          primaryTypographyProps={subListItemTypographyProps}
                        />
                      </span>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );

          return item.isCompanyApproveRequired && item.disabled ? (
            <ApproveCompanyTooltip key={item.title}>
              {menuItem}
            </ApproveCompanyTooltip>
          ) : (
            menuItem
          );
        })}
        {isAuthenticated && (
          <ListItemButton
            css={[classes.listItemOuter, classes.menuItemLogout]}
            onClick={() => handleLogout()}
          >
          <span css={classes.listItemInner}>
            <ArrowExit20Regular css={classes.menuItemIcon} />
            <ListItemText
              primary="Log out"
              primaryTypographyProps={listItemTypographyProps}
              css={[classes.listItemText, classes.listItemLogoutText]}
            />
          </span>
          </ListItemButton>
        )}
      </List>
    </div>
  );
};

NavigationMenu.propTypes = {
  history: PropTypes.object.isRequired,
  location: LocationType.isRequired,
  className: PropTypes.string,
  menu: PropTypes.arrayOf(MenuItemShape.isRequired).isRequired,
  onDisabledClick: PropTypes.func,
  match: PropTypes.object.isRequired,
  vendorCompanyName: PropTypes.string.isRequired,
};

NavigationMenu.defaultProps = {
  className: null,
  onDisabledClick: null,
};

export default withRouter(NavigationMenu);
