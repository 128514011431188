import { css } from '@emotion/react';

export const highlightStyle = (theme) => (
  css`
    font-family: ${theme.boldFont};
  `
);

export const input = (theme) => (
  css`
    & .MuiInputBase-root {
      height: 40px!important;
      padding: 11px 15px;
      border: none!important;
      border-radius: 4px!important;
      background-color: ${theme.primaryPalette.lightYellow};

      &::before {
        display: none;
      }

      &.MuiInput-underline:after {
        border-bottom: 0 !important;
      }
    }
    & .MuiAutocomplete-inputRoot {
      box-sizing: border-box;
      width: 100%;
      height: 34px;
      padding: 0 ${theme.spacing(2)};
      border: 2px solid ${theme.primaryPalette.midLightGrey};
      font-family: ${theme.mainFont};
      font-size: 14px;
      color: ${theme.primaryPalette.grey};
      border-radius: 10px;

      &::placeholder {
        color: #d1d1d1;
      }

      &:before, &:after, &:hover:not(.Mui-disabled):before {
        border-bottom: none !important;
      }
    }
  `
);

export const formWrap = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      display: flex;
      align-items: center;
    }

    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  `
);

export const autocomplete = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex: 1;
      margin-right: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
