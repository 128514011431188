/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import * as classes from './styles';

const BurgerIcon = ({
  className,
  disabled,
  isOpen,
  onClick,
}) => (
  <button
    css={classes.burgerIcon}
    className={className}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    <div css={[classes.burgerIconBar, isOpen && classes.burgerIconBarOpen]} />
    <div css={[classes.burgerIconBar, isOpen && classes.burgerIconBarOpen]} />
    <div css={[classes.burgerIconBar, isOpen && classes.burgerIconBarOpen]} />
  </button>
);

BurgerIcon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

BurgerIcon.defaultProps = {
  className: null,
  disabled: false,
};

export default BurgerIcon;
