/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import routes from 'constants/routes';
import LocationType from 'types/Location';
import contentStyles from './styles';

const ThankYouForRegistration = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const isInvited = params.has('isInvited');

  return (
    <PublicPageContentWrapper css={contentStyles}>
      <Typography sx={{ mb: 5 }} variant="h1">Success</Typography>
      <div>
        {isInvited ? (
          <>
            <Typography sx={{ mb: 5 }} variant="body2">
              You are now registered and approved. Please use the sign in button below to continue
              using the platform.
            </Typography>
            <Button component={Link} to={routes.LOGIN}>Sign In</Button>
          </>
        ) : (
          <Typography variant="body2">
            We have sent you an email to verify your address. Once completed, you will then be
            able to login to the platform and continue.
          </Typography>
        )}
      </div>
    </PublicPageContentWrapper>
  );
};

ThankYouForRegistration.propTypes = {
  location: LocationType.isRequired,
};

export default ThankYouForRegistration;
