import { css } from '@emotion/react';

export const responseTeam = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const challengeDetails = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const actions = (theme) => (
  css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(6)};
  `
);
