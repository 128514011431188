import PropTypes from 'prop-types';

const FileIcon = ({ className }) => (
  <svg className={className} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33333 5.57812V0H1C0.445833 0 0 0.438867 0 0.984375V20.0156C0 20.5611 0.445833 21 1 21H15C15.5542 21 16 20.5611 16 20.0156V6.5625H10.3333C9.78333 6.5625 9.33333 6.11953 9.33333 5.57812ZM16 4.9998V5.25H10.6667V0H10.9208C11.1875 0 11.4417 0.102539 11.6292 0.287109L15.7083 4.30664C15.8958 4.49121 16 4.74141 16 4.9998Z"
      fill="#D1D700"
    />
  </svg>
);

FileIcon.propTypes = {
  className: PropTypes.string,
};

FileIcon.defaultProps = {
  className: null,
};

export default FileIcon;
