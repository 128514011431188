import omit from 'lodash/omit';
import challengeStatus from 'constants/challengeStatus';

const CANCEL_CHALLENGE = 'CANCEL_CHALLENGE';
const PAUSE_CHALLENGE = 'PAUSE_CHALLENGE';
const RESTART_CHALLENGE = 'RESTART_CHALLENGE';
const COMPLETE_CHALLENGE = 'COMPLETE_CHALLENGE';
const REQUEST_REVISION = 'REQUEST_REVISION';
const GET_CHALLENGE_RESPONSES = 'GET_CHALLENGE_RESPONSES';
const GET_CHALLENGE_REVIEWERS = 'GET_CHALLENGE_REVIEWERS';
const GET_CHALLENGE_MILESTONES = 'GET_CHALLENGE_MILESTONES';

export const cancelChallengeSuccess = (cancellationReason) => ({
  type: CANCEL_CHALLENGE,
  cancellationReason,
});

export const pauseChallengeSuccess = (expectedRestart, pauseReason) => ({
  type: PAUSE_CHALLENGE,
  expectedRestart,
  pauseReason,
});

export const restartChallengeSuccess = (deadline) => ({ type: RESTART_CHALLENGE, deadline });

export const completeChallengeSuccess = (winnerId) => ({ type: COMPLETE_CHALLENGE, winnerId });

export const requestRevisionSuccess = () => ({ type: REQUEST_REVISION });

export const getChallengeResponsesSuccess = (challengeResponses) => ({
  type: GET_CHALLENGE_RESPONSES,
  challengeResponses,
});

export const getChallengeReviewersSuccess = (challengeReviewers) => ({
  type: GET_CHALLENGE_REVIEWERS,
  challengeReviewers,
});

export const getChallengeMilestonesSuccess = (milestones) => ({
  type: GET_CHALLENGE_MILESTONES,
  milestones,
});

const reducer = (state, action) => {
  switch (action.type) {
    case CANCEL_CHALLENGE: {
      const updatedChallenge = {
        ...omit(state.challenge, ['expectedRestart', 'pauseReason']),
        status: challengeStatus.CANCELLED,
        cancellationReason: action.cancellationReason,
      };

      return { ...state, challenge: updatedChallenge };
    }

    case PAUSE_CHALLENGE: {
      const { expectedRestart, pauseReason } = action;

      return {
        ...state,
        challenge: {
          ...state.challenge,
          status: challengeStatus.PAUSED,
          expectedRestart,
          pauseReason,
        },
      };
    }

    case RESTART_CHALLENGE: {
      const updatedChallenge = {
        ...omit(state.challenge, ['expectedRestart', 'pauseReason']),
        status: challengeStatus.SUBMITTED,
        response_deadline: action.deadline,
      };

      return { ...state, challenge: updatedChallenge };
    }

    case COMPLETE_CHALLENGE: {
      const { winnerId } = action;

      return {
        ...state,
        challenge: {
          ...state.challenge,
          status: challengeStatus.COMPLETED,
          winnerId,
        },
      };
    }

    case REQUEST_REVISION: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          status: challengeStatus.REQUESTED_REVISION,
        },
      };
    }

    case GET_CHALLENGE_RESPONSES: {
      return {
        ...state,
        challengeResponses: action.challengeResponses,
      };
    }

    case GET_CHALLENGE_REVIEWERS: {
      return {
        ...state,
        reviewers: action.challengeReviewers,
      };
    }

    case GET_CHALLENGE_MILESTONES: {
      return {
        ...state,
        milestones: action.milestones,
      };
    }

    default:
      return state;
  }
};

export default reducer;
