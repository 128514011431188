/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { ChevronDown20Filled } from '@fluentui/react-icons';
import * as classes from './styles';

const SavedPriorityCard = ({
  className,
  index,
  children,
  title,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <div css={classes.entry} className={className}>
      <div css={classes.position}>
        <Typography variant="body1">{index}</Typography>
      </div>
      <div css={classes.card}>
        <div css={classes.collapseHeader}>
          <Typography variant="subtitle1">{title}</Typography>
          {!!children && (
            <IconButton onClick={() => setCollapsed(!isCollapsed)} css={classes.button}>
              <ChevronDown20Filled css={[classes.chevron, isCollapsed && classes.rotatedChevron]} />
            </IconButton>
          )}
        </div>

        {!!children && (
          <Collapse in={isCollapsed}>
            <div css={classes.contentWrapper}>
              {children}
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

SavedPriorityCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node,
};

SavedPriorityCard.defaultProps = {
  className: null,
};

export default SavedPriorityCard;
