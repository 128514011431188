import CompanyPropertyAPI from './companyProperty';

class IntegrationAPI extends CompanyPropertyAPI {
  constructor() {
    super('/integration-label');
  }
}

const integrationAPI = new IntegrationAPI();

export default integrationAPI;
