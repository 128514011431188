/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import EventAgendaType from 'types/EventAgenda';
import * as classes from './styles';

const EventAgendaMeetings = ({ agenda, renderMeetingDetails, attendeeToMeet }) => (
  <div css={classes.sessions}>
    {agenda.map((sessions, sessionIndex) => (
      <div key={sessionIndex} css={classes.session}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Session {sessionIndex + 1}</Typography>
        <table css={classes.table}>
          <thead>
            <tr>
              <Typography
                variant="caption1"
                component="th"
                css={[classes.cell, classes.th, classes.slot]}
              >
                Slot
              </Typography>
              <Typography
                variant="caption1"
                component="th"
                css={[classes.cell, classes.th, classes.time]}
              >
                Time
              </Typography>
              <Typography
                variant="caption1"
                component="th"
                css={[classes.cell, classes.th, classes.attendeeToMeet]}
              >
                {attendeeToMeet}
              </Typography>
            </tr>
          </thead>
          <tbody>
            {sessions.map((meeting, meetingIndex) => (
              <tr key={meetingIndex} css={classes.row}>
                <Typography
                  variant="subtitle2"
                  component="td"
                  css={[classes.td, classes.cell, classes.slot]}
                >
                  {meetingIndex + 1}
                </Typography>
                <Typography
                  variant="body2"
                  component="td"
                  css={[classes.td, classes.cell, classes.time]}
                >
                  {meeting && meeting.startTime}
                </Typography>
                <td css={[classes.td, classes.cell, classes.attendeeToMeet]}>
                  {meeting
                    ? renderMeetingDetails(meeting)
                    : <Typography variant="subtitle2" css={classes.emptySlot}>
                        Empty slot
                      </Typography>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))}
  </div>
);

EventAgendaMeetings.propTypes = {
  agenda: EventAgendaType.isRequired,
  renderMeetingDetails: PropTypes.func.isRequired,
  attendeeToMeet: PropTypes.string.isRequired,
};

export default EventAgendaMeetings;
