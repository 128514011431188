import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import 'styles/VendorProfile.scss';

const TextBlock = ({ className, title, text }) => (
  !!text && (
    <div className={className}>
      <Typography className="vendor-profile-section-title" variant="h2">{title}</Typography>
      <Typography variant="body1">{text}</Typography>
    </div>
  )
);

TextBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

TextBlock.defaultProps = {
  className: null,
  text: null,
};

export default TextBlock;
