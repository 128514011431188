/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import Typography from '@mui/material/Typography';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import SelectField from 'components/Form/SelectField';
import TextFieldInput from 'components/Form/TextFieldInput';
import SubmitButton from 'components/UI/SubmitButton';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import composeValidators from 'utils/composeValidators';
import UserType from 'types/User';
import InvitedUserType from 'types/InvitedUser';
import { yesNoOptions, yesNoOptionList } from 'constants/yesNoRadioButtonOptions';
import * as classes from './styles';

const EditPrimaryContact = ({
  activeUsers,
  invitedUsers,
  title,
  initialValues,
  onSubmit,
  className,
}) => {
  const handleFieldChanges = useMemo(() => createDecorator(
    {
      field: 'id',
      updates: {
        isRegistered: (idValue) => activeUsers.some(({ id }) => id === idValue),
      },
    },
  ), [activeUsers]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[handleFieldChanges]}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} className={className}>
          <div css={classes.section}>
            <Typography variant="h2" component="fieldset" sx={{ mb: 1 }}>{title}</Typography>
            <RadioButtonGroup
              sx={{ mb: 2 }}
              legend="Is new user?"
              name="isNewUser"
              options={yesNoOptionList}
              isRequired
            />

            <div css={classes.fields}>
              {values.isNewUser === yesNoOptions.NO ? (
                <Field
                  name="id"
                  validate={required}
                  component={SelectField}
                  emptyOptionLabel="Not selected"
                >
                  {activeUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.firstName} {user.lastName} - {user.email}
                    </option>
                  ))}
                  {invitedUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.invitedUserEmail}
                    </option>
                  ))}
                </Field>
              ) : (
                <div css={classes.addNewUser}>
                  <Field
                    sx={{ mb: 1 }}
                    placeholder="Contact first name"
                    name="contactFirstName"
                    validate={required}
                    component={TextFieldInput}
                  />
                  <Field
                    sx={{ mb: 1 }}
                    placeholder="Contact last name"
                    name="contactLastName"
                    validate={required}
                    component={TextFieldInput}
                  />
                  <Field
                    placeholder="Contact email address"
                    name="contactEmail"
                    validate={
                      composeValidators(required, validateEmail, validateIsCorporateEmailDomain)
                    }
                    component={TextFieldInput}
                  />
                </div>
              )}
            </div>
            <SubmitButton type="submit" isLoading={submitting}>Save</SubmitButton>
          </div>
        </form>
      )}
    />
  );
};

EditPrimaryContact.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  activeUsers: PropTypes.arrayOf(UserType.isRequired).isRequired,
  invitedUsers: PropTypes.arrayOf(InvitedUserType.isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

EditPrimaryContact.defaultProps = {
  className: null,
};

export default EditPrimaryContact;
