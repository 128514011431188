import {
  CalendarLtr24Regular,
  Scales24Regular,
  Lightbulb24Regular,
  Save24Regular,
  Star24Regular,
} from '@fluentui/react-icons';
import routes from 'constants/routes';
import { profileMenu } from 'components/RetailerDataCaptureForm/constants';
import companyStatus from 'constants/companyStatus';
import ProfileIcon from 'svg/profile';
import { homeMenuItem, getCompanyUsersMenuItem } from './commonMenuItems';

const isChallengeMenuActive = ({ path }) => (
  path === routes.RETAILER_CHALLENGE_DASHBOARD
  || path === routes.SAVED_CHALLENGE
  || path === routes.CHALLENGE_RESPONSES_DASHBOARD
  || path === routes.CHALLENGE_RESPONSE_FOR_RETAILER
  || path === routes.EDIT_SAVED_CHALLENGE
  || path === routes.CREATE_NEW_CHALLENGE
);

const retailerMenu = [
  homeMenuItem,
  {
    to: routes.SEARCH_SOLUTION_PAGE,
    title: 'Solution search',
    icon: Lightbulb24Regular,
  },
  {
    to: routes.SAVED_SOLUTIONS,
    title: 'Saved solutions',
    icon: Save24Regular,
  },
  {
    title: 'RFIs',
    to: routes.RETAILER_CHALLENGE_DASHBOARD,
    isSelected: isChallengeMenuActive,
    icon: Scales24Regular,
  },
  {
    title: 'Events',
    to: routes.RETAILER_EVENTS_DASHBOARD,
    icon: CalendarLtr24Regular,
  },
  {
    isAdminRightsRequired: true,
    title: 'Profile',
    to: routes.EDIT_RETAILER_DETAILS,
    submenu: profileMenu,
    icon: ProfileIcon,
  },
  getCompanyUsersMenuItem(companyStatus.RETAILER),
  {
    title: 'Ratings',
    to: routes.RATINGS_REVIEWS,
    icon: Star24Regular,
  },
];

export default retailerMenu;
