import { css } from '@emotion/react';

export const table = (theme) => (
  css`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 ${theme.spacing(1)};
  `
);

export const row = (theme) => (
  css`
    border-radius: 4px;
    padding: ${theme.spacing(1.25)} ${theme.spacing(2)};
    box-shadow: ${theme.boxShadows.regular};
  `
);

export const th = (theme) => (
  css`
    padding-bottom: ${theme.spacing(1)};
    text-align: left;
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const cell = (theme) => (
  css`
    box-sizing: border-box;
    padding-right: ${theme.spacing(1)};
    padding-left: ${theme.spacing(1)};
    vertical-align: middle;

    &:first-of-type {
      padding-left: ${theme.spacing(2)};
    }

    &:last-of-type {
      padding-right: ${theme.spacing(0.5)};
    }
  `
);

export const descriptionHeader = (theme) => (
  css`
    display: flex;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `
);

export const deadline = css`
  min-width: 100px;
  font-family: inherit;
`;

export const sortButton = (theme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  padding: 0;
  color: ${theme.primaryPalette.menuGrey};
  text-align: left;
  font: inherit;
  cursor: pointer;
  outline: none;

  &,
  &:hover,
  &:focus,
  &:active {
    border: none;
    background: none;
  }
`;

export const name = css`
  max-width: 225px;
`;

export const extendedName = css`
  max-width: 275px;
`;

export const eventName = css`
  max-width: 140px;
`;

export const status = css`
  width: 160px;
`;

export const milestone = css`
  max-width: 200px;
`;

export const croppedText = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const milestoneDate = (theme) => (
  css`
    margin-right: ${theme.spacing(1)};
    color: ${theme.primaryPalette.menuGrey};
  `
);

export const icon = (theme) => css`
  color: ${theme.palette.primary.main};
`;
