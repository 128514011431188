/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import insertHost from 'utils/picture';
import ReviewType from 'types/Review';
import RecommendationMessage from '../RecommendationMessage';
import VoteReview from '../VoteReview';
import * as classes from './styles';

const ReviewMobileCard = ({
  className,
  review,
  getFormattedDate,
  getRatings,
  canVote,
  showReviewerName,
  getLogoUrl,
  getCompanyName,
}) => {
  const [[overallRating], featureRatings] = getRatings(review);
  const filteredRatings = featureRatings.filter(({ value }) => value > 0);

  const logoUrl = getLogoUrl(review);
  const companyName = getCompanyName(review);

  return (
    <div className={className} css={classes.card}>
      <div css={[classes.row, classes.splittedRow]}>
        <div css={classes.column}>
          <Typography variant="subtitle2">
            {companyName}
          </Typography>
          <Typography css={classes.caption} variant="caption1">
            {getFormattedDate(review)}
          </Typography>
        </div>

        {!!logoUrl && <img css={classes.logo} src={insertHost(logoUrl)} alt="" />}
      </div>

      <div css={[classes.row, classes.splittedRow]}>
        <div css={classes.column}>
          <Rating
            css={classes.ratingIcon}
            name={overallRating.field}
            value={overallRating.value}
            readOnly
          />
        </div>

        <div css={classes.column}>
          <RecommendationMessage recommendSolution={review.recommendSolution} />
        </div>
      </div>

      {showReviewerName && (
        <div css={[classes.row, classes.splittedRow]}>
          <div>
            <Typography css={classes.caption} variant="caption1">Reviewer</Typography>
          </div>

          <div>
            <Typography css={classes.caption} variant="caption1">
              <Typography variant="caption1">
                {review.user
                  ? <>{review.user.firstName} {review.user.lastName}</>
                  : 'No name'}
              </Typography>
            </Typography>
          </div>
        </div>
      )}

      <div css={[
        classes.ratings,
        classes.wrap,
        !!(filteredRatings.length || review.pros || review.cons) && classes.nonEmptyRatings,
      ]}>
        {!!filteredRatings.length && (
          <div css={classes.wrap}>
            {filteredRatings.map(({ field, value }) => (
              <div css={classes.ratingsContainer} key={field}>
                <Rating
                  css={classes.ratingIcon}
                  name={field}
                  readOnly
                  value={value}
                />
                <Typography variant="body2">{field}</Typography>
              </div>
            ))}
          </div>
        )}

        {!!(review.pros || review.cons) && (
          <>
            {!!review.pros && (
              <div>
                <Typography variant="subtitle2">Pros</Typography>
                <Typography variant="body2">{review.pros}</Typography>
              </div>
            )}
            {!!review.cons && (
              <div>
                <Typography variant="subtitle2">Cons</Typography>
                <Typography variant="body2">{review.cons}</Typography>
              </div>
            )}
          </>
        )}

        {canVote && (<VoteReview votes={review.voteReviews} reviewId={review.id} />)}
      </div>
    </div>
  );
};

ReviewMobileCard.propTypes = {
  className: PropTypes.string,
  review: ReviewType.isRequired,
  getFormattedDate: PropTypes.func.isRequired,
  getRatings: PropTypes.func.isRequired,
  canVote: PropTypes.bool.isRequired,
  showReviewerName: PropTypes.bool.isRequired,
  getLogoUrl: PropTypes.func.isRequired,
  getCompanyName: PropTypes.func.isRequired,
};

ReviewMobileCard.defaultProps = {
  className: null,
};

export default ReviewMobileCard;
