/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import withEventAttendeeRouteGuard from 'containers/withEventAttendeeRouteGuard';
import EventPageTheme from 'components/Events/EventPageTheme';
import EventPageBreadcrumbs from 'components/Events/EventPageBreadcrumbs';
import BackButton from 'components/UI/BackButton';
import PageBody from 'components/UI/PageBody';
import EventType from 'types/Event';
import EventTopicType from 'types/EventTopic';
import EventTopicNoteType from 'types/EventTopicNote';
import EventPriorityListType from 'types/EventPriorityList';
import routes from 'constants/routes';
import ManageTopicPriorities from './ManageTopicPriorities';
import * as classes from './styles';

const EditEventTopicPriorities = ({
  event,
  topics,
  priorityList,
  notes,
  orderedTopics,
}) => {
  const { primaryColor } = event;
  const location = useLocation();
  const isOnboarding = new URLSearchParams(location.search).has('isOnboarding');

  const content = (
    <PageBody>
      {!isOnboarding && (
        <EventPageBreadcrumbs
          sx={{ mb: 1 }}
          event={event}
          pageBreadcrumbs={[{ to: location, title: 'Meeting topic priorities' }]}
        />
      )}
      <div css={classes.headerContainer}>
        {!isOnboarding && <BackButton css={classes.backButton} />}
        <Typography variant="h1">Set business priorities</Typography>
      </div>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Please tell us the topics you are focused on
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        We will always attempt to arrange one-to-one meetings with your highest priorities.
        However, events always have limited capacity and this is not always possible.
      </Typography>
      <Typography sx={{ mb: 3 }} variant="subtitle1">
        Select and order topics
      </Typography>
      <ManageTopicPriorities
        isOnboarding={isOnboarding}
        event={event}
        topics={topics}
        orderedTopics={orderedTopics}
        priorityList={priorityList}
        notes={notes}
      />
    </PageBody>
  );

  return primaryColor
    ? (
      <EventPageTheme primaryColor={primaryColor}>
        {content}
      </EventPageTheme>
    ) : content;
};

EditEventTopicPriorities.propTypes = {
  event: EventType.isRequired,
  priorityList: EventPriorityListType,
  orderedTopics: PropTypes.arrayOf(EventTopicType.isRequired),
  topics: PropTypes.arrayOf(EventTopicType.isRequired).isRequired,
  notes: PropTypes.arrayOf(EventTopicNoteType.isRequired),
};

EditEventTopicPriorities.defaultProps = {
  priorityList: null,
  notes: [],
  orderedTopics: [],
};

export default withEventAttendeeRouteGuard(routes.RETAILER_EVENT_DETAILS)(EditEventTopicPriorities);
