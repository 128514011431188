/** @jsxImportSource @emotion/react */
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import reviewRequestAPI from 'api/reviewRequest';
import PageBody from 'components/UI/PageBody';
import TextFieldInput from 'components/Form/TextFieldInput';
import BottomBar from 'components/UI/BottomBar';
import { required, validateEmail, validateIsCorporateEmailDomain } from 'lib/validation';
import routes from 'constants/routes';
import composeValidators from 'utils/composeValidators';
import * as classes from './styles';

const ReviewRequestForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  let submit = () => {};

  const handleRequestReview = async (values, form) => {
    const { retailerEmail, userName, ...rest } = values;

    try {
      const email = retailerEmail.toLowerCase();
      const data = { email, userName, ...rest };

      await reviewRequestAPI.create(data);

      form.restart();

      enqueueSnackbar('Successfully sent request', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to send request', { variant: 'error' });
    }
  };

  return (
    <>
      <PageBody css={classes.pageBody}>
        <div>
          <Breadcrumbs sx={{ mb: 5 }}>
            <Link to={routes.MAIN}>Home</Link>
            <Link to={routes.REQUEST_REVIEW}>Request review</Link>
          </Breadcrumbs>

          <Typography sx={{ mb: 5 }} variant="h1">
            Request new review
          </Typography>

          <div css={classes.form}>
            <Typography variant="body2" css={classes.subtitle}>
              We will send a message to a person you want to provide a review of your company.
            </Typography>
            <Form
              onSubmit={handleRequestReview}
              render={({ handleSubmit }) => {
                submit = handleSubmit;

                return (
                  <form onSubmit={handleSubmit}>
                    <div css={classes.row}>
                      <Field
                        placeholder="Name"
                        name="userName"
                        component={TextFieldInput}
                        validate={required}
                        label="Recipient name"
                      />
                      <Field
                        name="retailerEmail"
                        placeholder="Email"
                        type="email"
                        validate={
                          composeValidators(required, validateEmail, validateIsCorporateEmailDomain)
                        }
                        component={TextFieldInput}
                        label="Recipient email"
                      />
                    </div>
                    <Field
                      placeholder="Please provide a short note to send with your request."
                      name="textMessage"
                      label="Personal message"
                      type="text"
                      multiline
                      component={TextFieldInput}
                    />
                  </form>
                );
              }}
            />
          </div>

          <Button css={classes.button} onClick={(e) => submit(e)} type="submit">Send request</Button>
        </div>
      </PageBody>

      <BottomBar
        css={classes.bottomBar}
        buttonText="Send request"
        type="submit"
        onClick={(e) => submit(e)}
      />
    </>
  );
};

export default ReviewRequestForm;
