import { css } from '@emotion/react';

export const group = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const image = (theme) => (
  css`
    max-width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const submit = css`
  text-align: center;
`;

export const button = (theme) => (
  css`
    display: block;
    padding: 0;
    font-family: ${theme.mainFont};
    color: ${theme.primaryPalette.grey};;
    background-color: transparent;
    border: none;
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);
