import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './ChallengeResponsesTableContainer.scss';

const ChallengeResponsesTableContainer = ({ children }) => {
  const tableContainerElement = useRef(null);

  const [horizontalScrollbarWidth, setHorizontalScrollbarWidth] = useState(0);
  const [verticalScrollbarWidth, setVerticalScrollbarWidth] = useState(0);

  useEffect(() => {
    const horizontalScrollbar = tableContainerElement && tableContainerElement.current
      ? tableContainerElement.current.offsetHeight - tableContainerElement.current.clientHeight
      : 0;

    const verticalScrollbar = tableContainerElement && tableContainerElement.current
      ? tableContainerElement.current.offsetWidth - tableContainerElement.current.clientWidth
      : 0;

    setHorizontalScrollbarWidth(horizontalScrollbar);
    setVerticalScrollbarWidth(verticalScrollbar);
  }, [tableContainerElement]);

  return (
    <div className="responses-table-container">
      {!!horizontalScrollbarWidth && <div className="responses-table-container__shadow-mask" style={{ right: verticalScrollbarWidth }} />}
      <div
        className="responses-table-container__inner"
        ref={tableContainerElement}
      >
        {children}
      </div>
    </div>
  );
};

ChallengeResponsesTableContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChallengeResponsesTableContainer;
