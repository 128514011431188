import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ChallengeResponseForVendor from 'components/ChallengeResponseForVendor';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import challengeResponseAPI from 'api/challengeResponse';
import handlePageDataFetchError from 'utils/handlePageDataFetchError';
import withRouteGuards from 'containers/withRouteGuards';
import { approvedCompanyGuard, authorizedRouteGuard, vendorRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const ChallengeResponseForVendorPage = (props) => {
  const { challengeId } = useParams();
  const companyId = useSelector((state) => state.user.company.id);

  const getInitialProps = useCallback(async () => {
    try {
      const [challenge, { data: challengeResponse }] = await Promise.all([
        challengeAPI.getChallengeDetails(challengeId),
        challengeResponseAPI.getChallengeResponseByChallengeId(challengeId, companyId),
      ]);

      return { challenge, challengeResponse };
    } catch (error) {
      const errorMessage = handlePageDataFetchError(error);

      return { error: errorMessage };
    }
  }, [challengeId, companyId]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={ChallengeResponseForVendor}
      {...props}
    />
  );
};

export default withRouteGuards([authorizedRouteGuard, approvedCompanyGuard, vendorRouteGuard])(
  (withPageTitle(pageTitles.CHALLENGE_RESPONSE)(ChallengeResponseForVendorPage)),
);
