/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Fab from '@mui/material/Fab';
import Clear from '@mui/icons-material/Clear';
import * as classes from './styles';

const SavedEndorsementContainer = ({ className, children, onDelete }) => (
  <div css={classes.container} className={className}>
    <Fab
      onClick={onDelete}
      size="small"
      css={classes.remove}
    >
      <Clear fontSize="small" />
    </Fab>
    {children}
  </div>
);

SavedEndorsementContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired,
};

SavedEndorsementContainer.defaultProps = {
  className: null,
};

export default SavedEndorsementContainer;
