import { memo } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';

/**
 * Important: part of emails contain a link to the vendor profile page in an format. The old slugs
 * preserved uppercase letters and replaced spaces by underscores, the new format uses
 * kebab-case. This wrapper checks is the old format used and redirects to new url if necessary.
 */
const withOldSlugReplacement = (Component) => {
  const VendorProfilePage = (props) => {
    const { slug } = useParams();
    const { pathname } = useLocation();

    const isOldSlugFormat = slug.includes('_') || /[A-Z]/g.test(slug);
    const newSlug = isOldSlugFormat
      // Important: don't use replaceAll because this method is not supported in older versions of
      // Safari
      ? slug
        .split('_')
        .join('-')
        .toLowerCase()
      : null;

    if (newSlug) {
      const newUrl = pathname.replace(slug, newSlug);

      return <Redirect to={newUrl} />;
    }

    return <Component {...props} />;
  };

  return memo(VendorProfilePage);
};

export default withOldSlugReplacement;
