/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import SelectField from 'components/Form/SelectField';
import TextFieldInput from 'components/Form/TextFieldInput';
import { required } from 'lib/validation';
import EventTopicType from 'types/EventTopic';
import * as classes from './styles';

const TopicPriorityForm = ({
  topics,
  onSave,
  onClose,
  initialValues,
  className,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitTopic = async (values, form) => {
    if (!form.pristine) {
      try {
        await onSave(values, initialValues);

        onClose();
      } catch (_) {
        enqueueSnackbar('Failed to submit topic', { variant: 'error' });
      }
    }
  };

  return (
    <div css={classes.form} className={className}>
      <Form
        onSubmit={handleSubmitTopic}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="id"
              fullWidth
              sx={{ mb: 2 }}
              component={SelectField}
              validate={required}
              emptyOptionLabel="Not selected"
            >
              {topics.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Field>

            <Field
              fullWidth
              label="Describe your interest"
              name="comment"
              component={TextFieldInput}
              type="text"
              multiline
              sx={{ mb: 3 }}
            />

            <div css={classes.formButtonsWrap}>
              <Button type="submit" variant="outlined">Save</Button>
              <Button onClick={() => onClose()} variant="text">Cancel</Button>
            </div>
          </form>
        )}
      />

    </div>
  );
};

TopicPriorityForm.propTypes = {
  className: PropTypes.string,
  topics: PropTypes.arrayOf(EventTopicType.isRequired).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    note: PropTypes.string,
  }),
};

TopicPriorityForm.defaultProps = {
  className: null,
  initialValues: null,
};

export default TopicPriorityForm;
