/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import BackButton from 'components/UI/BackButton';
import EventType from 'types/Event';
import EventPageBreadcrumbs from '../EventPageBreadcrumbs';
import * as classes from './styles';

const EventHeader = ({
  event,
  children,
  subtitle,
  className,
}) => {
  const { background, name } = event;

  return (
    <div css={classes.getWrapStyles({ background })} className={className}>
      <EventPageBreadcrumbs sx={{ mb: 1 }} event={event} />
      <div css={classes.head}>
        <div>
          <div css={classes.titleWrap}>
            <BackButton css={classes.backButton} />
            <Typography variant="h1">{name}</Typography>
          </div>
          {!!subtitle && (
            <Typography
              css={[classes.subtitle, !background && classes.light]}
              variant="caption1"
              component="p"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        {!!children && <div>{children}</div>}
      </div>
    </div>
  );
};

EventHeader.propTypes = {
  className: PropTypes.string,
  event: EventType.isRequired,
  children: PropTypes.node,
  subtitle: PropTypes.string,
};

EventHeader.defaultProps = {
  className: null,
  children: null,
  subtitle: null,
};

export default EventHeader;
