import get from 'lodash/get';

// Important: you need to assign name to form element for correct work of this function
const focusFirstInvalidFormControl = (errors, _, __, { form: formName }) => {
  const form = document.forms[formName];

  if (!form) {
    return;
  }

  const formElements = form.elements;

  for (const element of formElements) {
    const isFormControlInvalid = get(errors, element.name);

    if (isFormControlInvalid) {
      element.scrollIntoView({ block: 'center' });
      element.focus();
      break;
    }
  }
};

export default focusFirstInvalidFormControl;
