import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@mui/material';

import './Modal.css';

const Modal = ({
  children, show, modalClosed,
}) => (
    <Dialog
      open={show}
      onClose={modalClosed}
      maxWidth='sm'
    >
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
);

Modal.propTypes = {
  show: PropTypes.bool,
  modalClosed: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Modal.defaultProps = {
  show: false,
  children: () => { },
};

export default Modal;
