/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import {
  generatePath,
  Redirect,
  Link,
  useLocation,
} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import PageBody from 'components/UI/PageBody';
import { ChallengeContext } from 'components/RetailerChallenges/ChallengeProvider';
import routes from 'constants/routes';
import challengeStatus from 'constants/challengeStatus';
import DEFAULT_CHALLENGE_SOURCE from 'constants/defaultChallengeSource';
import EventChallenge from '../EventChallenge';
import RegularChallenge from '../RegularChallenge';
import ManageEventChallenge from '../ManageEventChallenge';
import manageEventChallengeStyles from './styles';

const PublishedChallenge = () => {
  const location = useLocation();
  const { state: { challenge, milestones } } = useContext(ChallengeContext);

  if (challenge.status === challengeStatus.DRAFT) {
    return <Redirect to={generatePath(routes.EDIT_SAVED_CHALLENGE, { id: challenge.id })} />;
  }

  return (
    <PageBody>
      <Breadcrumbs sx={{ mb: 5 }}>
        <Link to={routes.MAIN}>Home</Link>
        <Link to={routes.RETAILER_CHALLENGE_DASHBOARD}>RFIs</Link>
        <Link to={location}>{challenge.name}</Link>
      </Breadcrumbs>

      <Typography sx={{ mb: 5 }} variant="h1">{challenge.name}</Typography>

      {challenge.source === DEFAULT_CHALLENGE_SOURCE
        ? <RegularChallenge />
        : (
          <>
            <ManageEventChallenge css={manageEventChallengeStyles} />
            <EventChallenge challenge={challenge} milestones={milestones} isOwner />
          </>
        )
      }
    </PageBody>
  );
};

export default PublishedChallenge;
