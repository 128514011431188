/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Location16Regular, CalendarLtr16Regular } from '@fluentui/react-icons';
import formatDate from 'utils/formatDate';
import formatMultilineText from 'utils/formatMultilineText';
import EventType from 'types/Event';
import * as classes from './styles';

const DATE_FORMAT = 'do MMMM yyyy, h:mm aaa';

const EventDetails = ({ event, className }) => {
  const { description, eventDate, location } = event;
  const formattedEventDate = formatDate(eventDate, DATE_FORMAT);
  const formattedEventLocation = formatMultilineText(location);

  return (
    <div css={classes.content} className={className}>
      <div css={classes.info}>
        <CalendarLtr16Regular css={classes.icon} />
        <Typography variant="body1">
          {formattedEventDate}
        </Typography>
      </div>
      <div css={classes.info}>
        <Location16Regular css={classes.icon} />
        <Typography variant="body1">
          {formattedEventLocation}
        </Typography>
      </div>
      {!!description && (
        <Typography sx={{ mt: 3 }} variant="body1">{description}</Typography>
      )}
    </div>
  );
};

EventDetails.propTypes = {
  className: PropTypes.string,
  event: EventType.isRequired,
};

EventDetails.defaultProps = {
  children: null,
};

export default EventDetails;
