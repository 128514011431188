import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PageBody from 'components/UI/PageBody';
import ChallengeForm from 'components/ChallengeForm';
import initialValues from 'components/ChallengeForm/emptyFormValues';
import routes from 'constants/routes';

const CreateNewChallenge = () => (
  <PageBody>
    <Breadcrumbs sx={{ mb: 5 }}>
      <Link to={routes.MAIN}>Home</Link>
      <Link to={routes.RETAILER_CHALLENGE_DASHBOARD}>RFIs</Link>
      <Link to={routes.CREATE_NEW_CHALLENGE}>Create new</Link>
    </Breadcrumbs>
    <ChallengeForm initialValues={initialValues} />
  </PageBody>
);

export default CreateNewChallenge;
