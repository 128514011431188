import { css } from '@emotion/react';

export const content = (theme) => (
  css`
    box-sizing: border-box;
    max-width: 730px;
    border-radius: 8px;
    box-shadow: ${theme.boxShadows.regular};

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2.25)};
    }
  `
);

export const info = (theme) => css`
  display: flex;
  align-items: stretch;
  gap: ${theme.spacing(1.5)};
  color: ${theme.primaryPalette.primaryYellow};

  &:first-of-type {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const icon = (theme) => css`
  flex-shrink: 0;
  padding-top: 3px;
  color: ${theme.palette.primary.main};
`;
