import sortOrder from 'constants/sortOrder';
import OVERALL_RATING_FIELD_NAME from 'constants/overallRatingFieldName';

const getVotesSum = (votes) => votes.reduce((acc, { vote }) => acc + vote, 0);

const getOverallRating = (review) => (
  review.ratings.find(({ field }) => field === OVERALL_RATING_FIELD_NAME)
);

export const sortReviewsByVotes = (reviews, order) => (
  reviews.sort((first, second) => {
    const firstVotesSum = getVotesSum(first.voteReviews);
    const secondVotesSum = getVotesSum(second.voteReviews);

    return (
      order === sortOrder.ASC ? firstVotesSum - secondVotesSum : secondVotesSum - firstVotesSum
    );
  })
);

export const sortBySubmissionDate = (reviews, order) => (
  reviews.sort((first, second) => {
    const msA = Date.parse(first.createdAt);
    const msB = Date.parse(second.createdAt);

    return order === sortOrder.ASC ? msA - msB : msB - msA;
  })
);

export const sortByOverallRating = (reviews, order) => (
  reviews.sort((first, second) => {
    const { value: firstOverallRating } = getOverallRating(first);
    const { value: secondOverallRating } = getOverallRating(second);

    return (
      order === sortOrder.ASC
        ? firstOverallRating - secondOverallRating
        : secondOverallRating - firstOverallRating
    );
  })
);
