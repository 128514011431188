import { generatePath } from 'react-router-dom';
import routes from 'constants/routes';
import retailerDataCaptureFormTabs from 'constants/retailerDataCaptureFormTabs';

export const RETAILER_REGISTRATION_STEPS = {
  BASIC_DETAILS: 1,
  SALES_STORES: 2,
  ACCOUNT: 3,
};

export const RETAILER_REGISTRATION_STEPS_DATA = {
  BASIC_DETAILS: {
    title: 'Basic details',
    path: retailerDataCaptureFormTabs.BASIC_DETAILS,
    stepNumber: RETAILER_REGISTRATION_STEPS.BASIC_DETAILS,
  },
  SALES_STORES: {
    title: 'Sales & stores',
    path: retailerDataCaptureFormTabs.SALES_STORES,
    stepNumber: RETAILER_REGISTRATION_STEPS.SALES_STORES,
  },
  ACCOUNT: {
    title: 'Account',
    path: retailerDataCaptureFormTabs.ACCOUNT,
    stepNumber: RETAILER_REGISTRATION_STEPS.ACCOUNT,
  },
};

const retailerRegistrationStepsList = Object.values(RETAILER_REGISTRATION_STEPS_DATA);

export const profileMenu = retailerRegistrationStepsList
  .map(({ path, title }) => ({
    to: generatePath(routes.EDIT_RETAILER_DETAILS, { activeTab: path }),
    title,
  }));
