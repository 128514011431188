const yesNoQuestionsFragment = {
  relation: 'yesNoQuestions',
  scope: {
    fields: ['id', 'mustHave', 'challengeRequirementId'],
    include: {
      relation: 'requirement',
      scope: {
        fields: ['id', 'description'],
      },
    },
  },
};

export default yesNoQuestionsFragment;
