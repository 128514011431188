import PropTypes from 'prop-types';

const NoPendingRequestsIcon = ({ className }) => (
  <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M16.5177 15.534C13.0561 15.534 10.2499 18.3403 10.2499 21.8019V48.0519C10.2499 51.5135 13.0561 54.3198 16.5177 54.3198H24.1784V67.6786C24.1784 68.2466 24.8217 68.5757 25.2823 68.2434L44.582 54.3198H62.482C65.9436 54.3198 68.7499 51.5135 68.7499 48.0519V21.8019C68.7499 18.3403 65.9436 15.534 62.482 15.534H16.5177ZM6.07129 21.8019C6.07129 16.0325 10.7483 11.3555 16.5177 11.3555H62.482C68.2514 11.3555 72.9284 16.0325 72.9284 21.8019V48.0519C72.9284 53.8213 68.2514 58.4983 62.482 58.4983H45.932L27.7271 71.6321C24.5029 73.9582 19.9999 71.6543 19.9999 67.6786V58.4983H16.5177C10.7483 58.4983 6.07129 53.8213 6.07129 48.0519V21.8019Z"
      fill="currentColor"
    />
    <path
      d="M20.7835 21.5475C21.0769 20.9529 21.9248 20.9529 22.2182 21.5475L23.33 23.8001L25.8159 24.1613C26.4721 24.2567 26.7341 25.0631 26.2592 25.5259L24.4604 27.2793L24.8851 29.7552C24.9972 30.4087 24.3112 30.9071 23.7243 30.5985L21.5009 29.4296L19.2774 30.5985C18.6905 30.9071 18.0045 30.4087 18.1166 29.7552L18.5413 27.2793L16.7425 25.5259C16.2677 25.0631 16.5297 24.2567 17.1858 24.1613L19.6717 23.8001L20.7835 21.5475Z"
      fill="currentColor"
    />
    <path
      d="M32.7835 21.5475C33.0769 20.9529 33.9248 20.9529 34.2182 21.5475L35.33 23.8001L37.8159 24.1613C38.4721 24.2567 38.7341 25.0631 38.2592 25.5259L36.4604 27.2793L36.8851 29.7552C36.9972 30.4087 36.3112 30.9071 35.7243 30.5985L33.5009 29.4296L31.2774 30.5985C30.6905 30.9071 30.0045 30.4087 30.1166 29.7552L30.5413 27.2793L28.7425 25.5259C28.2677 25.0631 28.5297 24.2567 29.1858 24.1613L31.6717 23.8001L32.7835 21.5475Z"
      fill="currentColor"
    />
    <path
      d="M44.7835 21.5475C45.0769 20.9529 45.9248 20.9529 46.2182 21.5475L47.33 23.8001L49.8159 24.1613C50.4721 24.2567 50.7341 25.0631 50.2592 25.5259L48.4604 27.2793L48.8851 29.7552C48.9972 30.4087 48.3112 30.9071 47.7243 30.5985L45.5009 29.4296L43.2774 30.5985C42.6905 30.9071 42.0045 30.4087 42.1166 29.7552L42.5413 27.2793L40.7425 25.5259C40.2677 25.0631 40.5297 24.2567 41.1858 24.1613L43.6717 23.8001L44.7835 21.5475Z"
      fill="currentColor"
    />
    <path
      d="M56.7835 21.5475C57.0769 20.9529 57.9248 20.9529 58.2182 21.5475L59.33 23.8001L61.8159 24.1613C62.4721 24.2567 62.7341 25.0631 62.2592 25.5259L60.4604 27.2793L60.8851 29.7552C60.9972 30.4087 60.3112 30.9071 59.7243 30.5985L57.5009 29.4296L55.2774 30.5985C54.6905 30.9071 54.0045 30.4087 54.1166 29.7552L54.5413 27.2793L52.7425 25.5259C52.2677 25.0631 52.5297 24.2567 53.1858 24.1613L55.6717 23.8001L56.7835 21.5475Z"
      fill="currentColor"
    />
  </svg>
);

NoPendingRequestsIcon.propTypes = {
  className: PropTypes.string,
};

NoPendingRequestsIcon.defaultProps = {
  className: null,
};

export default NoPendingRequestsIcon;
