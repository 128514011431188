/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import Create from '@mui/icons-material/Create';
import formatChallengeReviewer from 'utils/formatChallengeReviewer';
import ChallengeWatcherType from 'types/ChallengeWatcher';
import * as classes from './styles';

const ChallengeReviewersTable = ({
  className,
  onEditClick,
  onRemoveClick,
  reviewers,
}) => (
  <table className={className}>
    <tbody>
      {reviewers.map((reviewer) => (
        <tr key={reviewer.id}>
          <td css={[classes.reviewer, classes.cell]}>
            <Typography variant="body1">
              {formatChallengeReviewer(reviewer)}
            </Typography>
          </td>

          <td css={classes.cell}>
            {!reviewer.userId && (
              <Fab onClick={() => onEditClick(reviewer)} size="small">
                <Create fontSize="small" />
              </Fab>
            )}
          </td>
          <td css={classes.cell}>
            <Fab size="small" onClick={() => onRemoveClick(reviewer)}>
              <Close fontSize="small" />
            </Fab>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

ChallengeReviewersTable.propTypes = {
  className: PropTypes.string,
  reviewers: PropTypes.arrayOf(ChallengeWatcherType.isRequired).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

ChallengeReviewersTable.defaultProps = {
  className: null,
};

export default ChallengeReviewersTable;
