import PropTypes from 'prop-types';
import clsx from 'clsx';
import questionChallengeOptions from 'constants/questionChallengeOptions';
import { processYesNoAnswers } from 'utils/prepareChallengeYesNoQuestions';
import { ChallengeYesNoQuestionType } from 'types/Challenge';
import YesNoAnswerRow from '../YesNoAnswerRow';
import './ChallengeResponseYesNoAnswers.scss';

const ChallengeResponseYesNoAnswers = ({ className, questions, answers }) => {
  const [mustHaveAnswers, niceToHaveAnswers] = processYesNoAnswers(questions, answers);

  return (
    <table className={clsx('yes-no-answers', className)}>
      <thead>
        <tr>
          <th className="yes-no-answers__caption" />
          <th className="yes-no-answers__caption">Yes</th>
          <th className="yes-no-answers__caption">No</th>
        </tr>
      </thead>
      <tbody>
        {!!mustHaveAnswers.length && (
          <>
            <tr>
              <td className="yes-no-answers__section-title" colSpan={3}>
                {questionChallengeOptions.MUST_HAVE}
              </td>
            </tr>
            {mustHaveAnswers.map((answer) => (
              <YesNoAnswerRow key={answer.questionId} answer={answer} />
            ))}
          </>
        )}

        {niceToHaveAnswers.map((answer) => (
          <YesNoAnswerRow key={answer.questionId} answer={answer} />
        ))}
      </tbody>
    </table>
  );
};

ChallengeResponseYesNoAnswers.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.arrayOf(ChallengeYesNoQuestionType.isRequired).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
};

ChallengeResponseYesNoAnswers.defaultProps = {
  className: null,
};

export default ChallengeResponseYesNoAnswers;
