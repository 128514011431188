/** @jsxImportSource @emotion/react */
import Skeleton from '@mui/material/Skeleton';
import Header from 'components/Header';
import PageContentPlaceholder from 'components/Shared/PageContentPlaceholder';
import * as classes from './styles';

const SIDEBAR_MENU_ITEMS_COUNT = 5;

const sidebarItemsArray = Array.from({ length: SIDEBAR_MENU_ITEMS_COUNT }, (_, k) => k);

const PageLoading = () => (
  <>
    <Header />
    <main css={classes.wrapper}>
      <div css={classes.body}>
        <aside css={classes.sidebar}>
          {sidebarItemsArray.map((item) => (
            <div key={item} css={classes.linkPlaceholder}>
              <Skeleton />
            </div>
          ))}
        </aside>
        <div css={classes.content}>
          <PageContentPlaceholder />
        </div>
      </div>
    </main>
  </>
);

export default PageLoading;
