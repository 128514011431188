/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import insertHost from 'utils/picture';
import Modal from 'components/UI/Modal';
import PageBody from 'components/UI/PageBody';
import UserType from 'types/User';
import AvatarFallback from 'static/images/user-no-avatar.png';
import ChangePasswordForm from '../ChangePasswordForm';
import SocialMediaLink from '../SocialMediaLink';
import { formatSocialMediaLink, formatLinkedIn, formatInstagram } from './formatSocialMediaLink';
import * as classes from './styles';

const socialMediaData = {
  FACEBOOK: { host: 'facebook.com', name: 'Facebook' },
  TWITTER: { host: 'twitter.com', name: 'Twitter' },
  INSTAGRAM: { host: 'instagram.com', name: 'Instagram' },
  LINKEDIN: { host: 'linkedin.com', name: 'LinkedIn' },
};

const UserProfilePage = ({
  user: {
    firstName,
    lastName,
    phoneNumber,
    position,
    email,
    linkedin,
    facebook,
    twitter,
    instagram,
    icon,
    description,
  },
}) => {
  const [isModalOpen, setModal] = useState(false);

  const avatarSrc = insertHost(icon) || AvatarFallback;
  const isSocialMediaEmpty = !!(linkedin || facebook || twitter || instagram);

  return (
    <>
      <PageBody>
        <Breadcrumbs sx={{ mb: 5 }}>
          <Link to={routes.MAIN}>Home</Link>
          <Link to={routes.USER_PROFILE}>User profile</Link>
        </Breadcrumbs>
        <Typography sx={{ mb: 5 }} variant="h1">
          Your profile
        </Typography>

        <div css={[classes.avatarContainer, classes.section]}>
          <img css={classes.avatar} src={avatarSrc} alt="" />
        </div>

        <Typography sx={{ mb: 2 }} variant="h2">
          Profile details
        </Typography>
        <div css={[classes.section, classes.profileDetails]}>
          <div>
            <Typography css={classes.line} variant="body1">
              {firstName} {lastName}
            </Typography>
            {position && (
              <Typography css={classes.line} variant="body1">
                {position}
              </Typography>
            )}
            <Typography css={classes.line} variant="body1">
              {email}
            </Typography>
            {phoneNumber && phoneNumber.code && phoneNumber.number && (
              <Typography css={classes.line} variant="body1">
                {phoneNumber.code} {phoneNumber.number}
              </Typography>
            )}
          </div>

          {isSocialMediaEmpty && (
            <div>
              {linkedin && (
                <SocialMediaLink
                  css={classes.line}
                  link={formatSocialMediaLink(
                    linkedin,
                    socialMediaData.LINKEDIN.host,
                    formatLinkedIn,
                  )}
                  name={socialMediaData.LINKEDIN.name}
                />
              )}
              {twitter && (
                <SocialMediaLink
                  css={classes.line}
                  link={formatSocialMediaLink(twitter, socialMediaData.TWITTER.host)}
                  name={socialMediaData.TWITTER.name}
                />
              )}
              {facebook && (
                <SocialMediaLink
                  css={classes.line}
                  link={formatSocialMediaLink(facebook, socialMediaData.FACEBOOK.host)}
                  name={socialMediaData.FACEBOOK.name}
                />
              )}
              {instagram && (
                <SocialMediaLink
                  css={classes.line}
                  link={formatSocialMediaLink(
                    instagram,
                    socialMediaData.INSTAGRAM.host,
                    formatInstagram,
                  )}
                  name={socialMediaData.INSTAGRAM.name}
                />
              )}
            </div>
          )}
        </div>

        {description && (
          <Typography css={classes.section} variant="body1">{description}</Typography>
        )}

        <div css={classes.footer}>
          <Button component={Link} to={routes.EDIT_USER_PROFILE}>Edit details</Button>
          <Button onClick={() => setModal(true)}>Change password</Button>
        </div>
      </PageBody>

      <Modal show={isModalOpen} modalClosed={() => setModal(false)}>
        <ChangePasswordForm onCloseModal={() => setModal(false)} />
      </Modal>
    </>
  );
};

UserProfilePage.propTypes = {
  user: UserType.isRequired,
};

export default connect((state) => ({ user: state.user.authUser }))(UserProfilePage);
