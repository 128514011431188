import { css } from '@emotion/react';

const MOBILE_UI_BREAKPOINT = 1400;

export const table = (theme) => (
  css`
    ${theme.breakpoints.down(MOBILE_UI_BREAKPOINT)} {
      display: none;
    }
  `
);

export const cards = (theme) => (
  css`
    ${theme.breakpoints.up(MOBILE_UI_BREAKPOINT)} {
      display: none;
    }
  `
);

export const card = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)};
    }
  `
);

export const select = (theme) => css`
  ${theme.breakpoints.up('md')} {
    margin-bottom: ${theme.spacing(3)};
  }

  ${theme.breakpoints.down('md')} {
    flex: 1;
    width: 100%;
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const filter = (theme) => css`
  ${theme.breakpoints.up('md')} {
    width: 185px;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const filterLabel = (theme) => css`
  color: ${theme.primaryPalette.menuGrey};
`;
