import { css } from '@emotion/react';

export const card = (theme) => css`
  max-width: 730px;

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2.5)};
  }
`;

export const hint = (theme) => css`
  margin-bottom: ${theme.spacing(4)};
`;
