import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes } from 'redux-form';
import FormLayout from 'components/Shared/FormLayout';
import DataCaptureFormContainer from 'components/Shared/DataCaptureFormContainer';
import { required } from 'lib/validation';
import TextFieldInput from 'components/UI/TextFieldInput';
import DropDownInput from 'components/UI/DropDownInput';
import companyRevenue from 'constants/companyRevenue';
import globalSales from 'constants/globalSales';

class SalesStores extends Component {
  static propTypes = {
    onUpdateProfile: PropTypes.func.isRequired,
    ...propTypes,
  };

  saveCompanyData = (isDraft) => (body) => {
    const { onUpdateProfile } = this.props;

    onUpdateProfile(body, isDraft);
  };

  render() {
    const {
      dirty,
      valid,
      handleSubmit,
      submitSucceeded,
    } = this.props;

    return (
      <DataCaptureFormContainer
        isDirty={dirty}
        onSubmit={handleSubmit(this.saveCompanyData(true))}
        isValid={valid}
        submitSucceeded={submitSucceeded}
      >
        <FormLayout
          title="Sales &amp; stores"
          buttonText="Continue"
          onSubmit={handleSubmit(this.saveCompanyData(false))}
        >
          <Field
            sx={{ mb: 2 }}
            placeholder="e.g. 5"
            label="Number of UK stores"
            name="retailers.stores.UKStores"
            validate={[required]}
            component={TextFieldInput}
          />
          <Field
            sx={{ mb: 2 }}
            label="Number of stores internationally"
            placeholder="e.g. 30"
            name="retailers.stores.internationalStores"
            validate={[required]}
            component={TextFieldInput}
          />
          <Field
            sx={{ mb: 2 }}
            name="retailers.sales.global.lastFinancialYear"
            label="Global Sales (in £ last financial year)"
            validate={[required]}
            component={DropDownInput}
            fullWidth
          >
            {globalSales.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Field>
          <Field
            sx={{ mb: 2 }}
            label="Percentage of total sales online"
            placeholder="e.g. 45%"
            name="retailers.sales.global.online"
            validate={[required]}
            component={TextFieldInput}
          />
          <Field
            sx={{ mb: 2 }}
            name="retailers.sales.global.companyRevenue"
            label="Please add company revenue"
            placeholder="Please confirm your company revenue"
            validate={[required]}
            component={DropDownInput}
            fullWidth
          >
            {companyRevenue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
          <Field
            label="Key technologies used (Ecommerce, CRM, EPOS Etc)"
            placeholder="Please provide a list of your key technologies"
            multiline
            rows="6"
            name="retailers.accountData.keyTechnologies"
            validate={[required]}
            component={TextFieldInput}
          />
        </FormLayout>
      </DataCaptureFormContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: { retailers: state.user.company.retailers },
});

export default connect(mapStateToProps)(
  reduxForm({ enableReinitialize: true, form: 'salesStoresPages' })(SalesStores),
);
