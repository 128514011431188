import { css } from '@emotion/react';

export const wrapper = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 100vh;

    &::before {
      display: block;
      content: '';
      width: 100%;
      ${theme.mixins.toolbar};
    }
  `
);

export const body = css`
  display: flex;
  flex-grow: 1;
`;

export const content = css`
  margin-bottom: auto;
  flex-grow: 1;
`;

export const sidebar = (theme) => (
  css`
    width: ${theme.layout.sidebar};
    background-color: ${theme.primaryPalette.midLightGrey};

    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `
);

export const linkPlaceholder = css`
  padding: 16px 32px;
`;
