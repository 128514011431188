import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import './FormLayout.scss';

const FormLayout = ({
  buttonText,
  children,
  title,
  onSubmit,
}) => (
  <div className="form-layout">
    <Typography variant="h1" className="form-layout__heading">
      {title}
    </Typography>
    <div>{children}</div>
    {buttonText && onSubmit && (
      <div className="form-layout__submit">
        <Button onClick={onSubmit}>{buttonText}</Button>
      </div>
    )}
  </div>
);

FormLayout.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
};

FormLayout.defaultProps = {
  buttonText: null,
  onSubmit: null,
};

export default FormLayout;
