export const PRICING_FIELDS_RESTRICTIONS = {
  MIN: 0,
  MAX: 3,
};

export const MARKET_COUNTRIES = {
  MIN: 1,
};

export const BUSINESS_BENEFITS_FIELDS_RESTRICTIONS = {
  MIN: 1,
  MAX: 4,
};

export const PRODUCT_FEATURES_FIELDS_RESTRICTIONS = {
  MIN: 0,
  MAX: 4,
};

export const FUTURE_FEATURES_FIELDS_RESTRICTIONS = {
  MIN: 0,
  MAX: 4,
};

export const CLIENTS_FIELDS_RESTRICTIONS = {
  MIN: 1,
  MAX: 6,
};

export const TAGS_RESTRICTIONS = {
  MIN: 5,
  MAX: 10,
};
