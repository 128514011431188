import EventPageTheme from 'components/Events/EventPageTheme';
import ChallengeForm from 'components/ChallengeForm';
import PageBody from 'components/UI/PageBody';
import EventType from 'types/Event';

const EventChallengeForm = ({ event }) => {
  const initialValues = {
    source: event.id,
    isCreatedFromEvent: true,
  };

  const { primaryColor } = event;

  return (
    <PageBody>
      {primaryColor ? (
        <EventPageTheme primaryColor={primaryColor}>
          <ChallengeForm event={event} initialValues={initialValues} />
        </EventPageTheme>
      ) : (
        <ChallengeForm event={event} initialValues={initialValues} />
      )}
    </PageBody>
  );
};

EventChallengeForm.propTypes = {
  event: EventType.isRequired,
};

export default EventChallengeForm;
