import { css } from '@emotion/react';

const RADIO_GROUP_BREAKPOINT = 400;

export const section = (theme) => (
  css`
    margin-bottom: ${theme.spacing(6)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(4)};
  `
);

export const subtitle = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const label = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const radioGroup = (theme) => (
  css`
    display: flex;

    ${theme.breakpoints.down(RADIO_GROUP_BREAKPOINT)} {
      flex-direction: column;
    }
  `
);
