import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { Typography } from '@mui/material';

import SavedCaseStudy from 'components/Shared/SavedCaseStudy';
import SavedEndorsement from 'components/Shared/SavedEndorsement';
import AwardIcon from 'svg/award';
import { ArticleType, AwardType, CaseStudyType } from 'types/Endorsements';
import 'styles/VendorProfile.scss';
import './Endorsements.scss';

const Endorsements = ({ caseStudies, articles, awards }) => {
  const filteredAwards = awards.filter(({ name }) => !!name && !!name.trim());

  return (
    <div className="vendor-endorsements">
      <Typography className="vendor-profile-title" variant="h1">
        Endorsements
      </Typography>

      {!!caseStudies.length && (
        <div className="vendor-profile-block">
          <Typography className="vendor-profile-section-title" variant="h3">
            Testimonials and case studies
          </Typography>
          {caseStudies.map((document, i) => (
            <SavedCaseStudy key={i} className="vendor-endorsements__item" document={document} />
          ))}
        </div>
      )}

      {!!articles.length && (
        <div className="vendor-profile-block">
          <Typography className="vendor-profile-section-title" variant="h3">
            Articles
          </Typography>
          {articles.map((document, i) => (
            <SavedEndorsement
              key={i}
              className="vendor-endorsements__item"
              document={document}
              showLink
            />
          ))}
        </div>
      )}

      {!!filteredAwards.length && (
        <div className="vendor-profile-block">
          <Typography className="vendor-profile-section-title" variant="h3">
            Awards
          </Typography>
          <ul>
            {filteredAwards.map(({ name, year }) => (
              <li key={`${name}-${year}`} className="vendor-endorsements__award">
                <AwardIcon className="vendor-endorsements__award-icon" />
                <Typography variant="subtitle2" component="span">{`${name} - ${year}`}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Endorsements.propTypes = {
  awards: PropTypes.arrayOf(AwardType.isRequired),
  caseStudies: PropTypes.arrayOf(CaseStudyType.isRequired),
  articles: PropTypes.arrayOf(ArticleType.isRequired),
};

Endorsements.defaultProps = {
  articles: [],
  awards: [],
  caseStudies: [],
};

const mapStateToProps = (state) => {
  const companyVendor = get(state, 'vendorInfo.company.vendors', {});

  return pick(companyVendor, ['caseStudies', 'articles', 'awards']);
};

export default connect(mapStateToProps)(Endorsements);
