/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field, fieldArrayFieldsPropTypes, fieldArrayMetaPropTypes } from 'redux-form';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Clear from '@mui/icons-material/Clear';
import TextFieldInput from 'components/UI/TextFieldInput';
import { validateUrl } from 'lib/validation';
import * as classes from './styles';

const validateCompanyUrl = (value) => value && validateUrl(value);

const ClientReferencesFieldArray = ({
  className,
  fields,
  meta: { error, submitFailed },
  maxItems,
}) => (
  <div className={className}>
    <div css={classes.description}>
      <Typography variant="body1">
        List the top 3 most relevant existing client references (company names and url&apos;s if
        relevant)
      </Typography>
      {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
    </div>

    {fields.map((company, i) => (
      <div key={i}>
        <Typography sx={{ mb: 1 }} variant="h3">Reference {i + 1}</Typography>
        <div css={classes.item}>
          <div css={classes.fields}>
            <Field
              sx={{ mb: 2 }}
              component={TextFieldInput}
              name={`${company}.companyName`}
              placeholder="Please enter company name"
            />
            <Field
              component={TextFieldInput}
              name={`${company}.companyUrl`}
              placeholder="Please enter company site"
              validate={validateCompanyUrl}
            />
          </div>
          <Fab onClick={() => fields.remove(i)} size="small">
            <Clear fontSize="small" />
          </Fab>
        </div>
      </div>
    ))}
    {fields.length < maxItems && (
      <div>
        <Button onClick={() => fields.push({ companyName: '', companyUrl: '' })}>
          Add reference
        </Button>
      </div>
    )}
  </div>
);

ClientReferencesFieldArray.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  meta: PropTypes.shape(fieldArrayMetaPropTypes).isRequired,
  maxItems: PropTypes.number.isRequired,
};

ClientReferencesFieldArray.defaultProps = {
  className: null,
};

export default ClientReferencesFieldArray;
