import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    box-sizing: border-box;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing(2.5)} ${theme.spacing(3)} ${theme.spacing(3.5)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3.5)} 0 ${theme.spacing(3.5)};
    }
  `
);

export const link = (theme) => (
  css`
    padding: ${theme.spacing(1.5)};

    ${theme.breakpoints.down('sm')} {
      display: block;
      text-align: center;
    }
  `
);
