/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import cookie from 'js-cookie';
import get from 'lodash/get';
import { getUser } from 'store/thunk';
import PageLoading from 'containers/PageLoading';
import FetchError from 'components/FetchError';
import { UIContext } from 'containers/UIProvider';
import { AuthContext } from 'containers/AuthProvider';
import httpResponseCodes from 'constants/httpResponseCodes';
import { authCookies, localStorageAuthKey } from 'constants/storageKeys';
import { clearAndLogout } from 'store/thunk/user';
import containerStyles from './styles';

const UILoadUserData = ({ children }) => {
  const [isError, setError] = useState(false);

  const dispatch = useDispatch();

  const { setSessionStatus } = useContext(AuthContext);
  const { isUserLoaded, onSetUserLoaded } = useContext(UIContext);

  const getUserData = useCallback(async () => {
    const isLoggedIn = localStorage.getItem(localStorageAuthKey) === 'true';
    const token = cookie.get(authCookies.TOKEN);

    try {
      if (isLoggedIn && token) {
        const userId = cookie.get(authCookies.USER_ID);
        await dispatch(getUser(userId));
      } else if (isLoggedIn && !token) {
        setSessionStatus(true);
      } else {
        dispatch(clearAndLogout());
      }
    } catch (error) {
      const responseStatus = get(error, 'response.status');

      // We handle 401 errors separately in axios response interceptor
      if (responseStatus !== httpResponseCodes.UNAUTHORIZED) {
        setError(true);
      }
    }
  }, [dispatch, setSessionStatus]);

  useEffect(() => {
    if (!isUserLoaded) {
      getUserData().then(() => onSetUserLoaded(true));
    }
  }, [getUserData, isUserLoaded, onSetUserLoaded]);

  if (isError) {
    return <FetchError css={containerStyles} />;
  }

  return isUserLoaded ? children : <PageLoading />;
};

UILoadUserData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UILoadUserData;
