/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { ChevronDown20Filled } from '@fluentui/react-icons';
import insertHost from 'utils/picture';
import ReviewType from 'types/Review';
import RecommendationMessage from '../RecommendationMessage';
import VoteReview from '../VoteReview';
import * as classes from './styles';

const ReviewTableCard = ({
  review,
  canVote,
  showReviewerName,
  getFormattedDate,
  getRatings,
  getLogoUrl,
  getCompanyName,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const [[overallRating], featureRatings] = getRatings(review);
  const filteredRatings = featureRatings.filter(({ value }) => value > 0);

  const logoUrl = getLogoUrl(review);
  const companyName = getCompanyName(review);

  return (
    <>
      <tr
        key={review.id}
        css={[isCollapsed ? classes.rowOpened : classes.row]}
      >
        <td css={[classes.logoWrap, classes.cell]}>
          {!!logoUrl && <img css={classes.logo} src={insertHost(logoUrl)} alt="" />}
        </td>
        <td css={[classes.cell, classes.name]}>
          <Typography variant="subtitle2">{companyName}</Typography>
        </td>
        <td css={[classes.cell, classes.overallRating]}>
          <Rating
            css={classes.ratingIcon}
            name={overallRating.field}
            value={overallRating.value}
            readOnly
          />
        </td>
        <td css={[classes.cell, classes.overallRating]}>
          <RecommendationMessage recommendSolution={review.recommendSolution} />
        </td>
        {showReviewerName && (
          <td css={[classes.cell]}>
            <Typography variant="caption1">
              {review.user
                ? `${review.user.firstName} ${review.user.lastName}`
                : 'No name'}
            </Typography>
          </td>
        )}
        <td css={[classes.cell]}>
          <Typography variant="body2">{getFormattedDate(review)}</Typography>
        </td>
        <td css={classes.cell}>
          {!!(canVote || filteredRatings.length || review.pros || review.cons) && (
            <IconButton
              aria-label="set-collapsed-content"
              onClick={() => setCollapsed(!isCollapsed)}
            >
              <ChevronDown20Filled css={[
                classes.collapseChevron, isCollapsed && classes.rotatedChevron]}
              />
            </IconButton>
          )}
        </td>
      </tr>

      <tr>
        <td colSpan={7}>
          <Collapse in={isCollapsed} css={classes.collapse}>
            <div css={[
              classes.ratings,
              !!(filteredRatings.length || review.pros || review.cons) && classes.filledRatings,
            ]}>
              {!!filteredRatings.length && (
                <div css={classes.columns}>
                  {filteredRatings.map(({ field, value }) => (
                    <div css={classes.ratingField} key={field}>
                      <Rating
                        css={classes.ratingIcon}
                        name={field}
                        readOnly
                        value={value}
                      />
                      <Typography variant="body2">{field}</Typography>
                    </div>
                  ))}
                </div>
              )}

              {(!!review.pros || !!review.cons) && (
                <div css={[classes.columns, classes.prosCons]}>
                  {!!review.pros && (
                    <div>
                      <Typography variant="subtitle2">Pros</Typography>
                      <Typography variant="body2">{review.pros}</Typography>
                    </div>
                  )}

                  {!!review.cons && (
                    <div>
                      <Typography variant="subtitle2">Cons</Typography>
                      <Typography variant="body2">{review.cons}</Typography>
                    </div>
                  )}
                </div>
              )}
            </div>

            {canVote && (
              <VoteReview
                css={classes.voteReview}
                votes={review.voteReviews}
                reviewId={review.id}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  );
};

ReviewTableCard.propTypes = {
  review: ReviewType.isRequired,
  getFormattedDate: PropTypes.func.isRequired,
  getRatings: PropTypes.func.isRequired,
  canVote: PropTypes.bool.isRequired,
  showReviewerName: PropTypes.bool.isRequired,
  getLogoUrl: PropTypes.func.isRequired,
  getCompanyName: PropTypes.func.isRequired,
};

export default ReviewTableCard;
