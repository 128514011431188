import { css } from '@emotion/react';

const section = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);

export default section;
