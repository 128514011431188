import APIClient from './client';

class EventRetailerUserAPI extends APIClient {
  constructor() {
    super('/event-retail-users');
  }

  getRetailerUsersOfEvent = async (eventId) => {
    const { data } = await this.find({
      where: {
        eventId,
      },
      include: [
        {
          relation: 'user',
          scope: {
            fields: ['id', 'firstName', 'lastName', 'position', 'companyId'],
            include: [
              {
                relation: 'company',
                scope: {
                  fields: ['id', 'name'],
                },
              },
            ],
          },
        },
      ],
    });

    return data;
  };

  isUserEventAttendee = async (userId, eventId) => {
    const { data: { count } } = await this.count({ userId, eventId });

    return count > 0;
  };
}

const eventRetailerUserAPI = new EventRetailerUserAPI();

export default eventRetailerUserAPI;
