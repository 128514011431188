import { css } from '@emotion/react';

const container = (theme) => (
  css`
    padding-top: ${theme.spacing(5)};
    text-align: center;
  `
);

export default container;
