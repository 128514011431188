import { css } from '@emotion/react';

export const li = (theme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1.5)};
  max-width: 730px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const wrap = (theme) => (
  css`
    display: flex;
    align-items: center;
    width: 100%;
    color: ${theme.palette.primary.main};
    box-shadow: ${theme.boxShadows.large};
    border-radius: 8px;
    border: none;
  `
);

export const dragHandle = (theme) => css`
  align-self: stretch;
  height: inherit;
  padding: ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1.5)};
  background: ${theme.palette.primary.veryLight};
  // Important: two values are specified due to different browser support
  cursor: grab;
  cursor: move;
`;

export const handleIcon = (theme) => css`
  color: ${theme.palette.primary.main};
`;

export const counter = (theme) => css`
  display: flex;
  justify-content: center;
  align-self: stretch;
  width: 30px;
  padding: ${theme.spacing(2)} 0 0;
`;

export const content = (theme) => (
  css`
    flex: 1;
    align-self: stretch;
    max-width: 700px;
    padding: 0 ${theme.spacing(2)};
  `
);
