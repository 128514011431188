const routes = {
  CHALLENGE_FOR_RETAILER_PAGES: '/retailer/rfis/:id',
  CHALLENGE_RESPONSE_FORM: '/rfi/respond/:challengeId',
  CHALLENGE_RESPONSE_FOR_RETAILER: '/retailer/rfis/:challengeId/overview/:companySlug',
  CHALLENGE_RESPONSE_FOR_VENDOR: '/vendor/rfis/:challengeId/response',
  CHALLENGE_RESPONSES_DASHBOARD: '/retailer/rfis/:challengeId/overview',
  CREATE_NEW_CHALLENGE: '/retailer/rfis/create',
  EDIT_RETAILER_DETAILS: '/registration/retailer/:activeTab',
  EDIT_USER_PROFILE: '/user/edit-profile',
  EDIT_VENDOR_DETAILS: '/registration/vendor/:activeTab',
  EDIT_SAVED_CHALLENGE: '/retailer/rfis/:id/edit',
  EDIT_RETAILER_EVENT_BUSINESS_PRIORITIES: '/retailer/events/:slug/business-priorities/edit',
  EDIT_VENDOR_EVENT_BUSINESS_PRIORITIES: '/vendor/events/:slug/business-priorities/edit',
  EVENT_CHALLENGE_FORM: '/retailer/events/:slug/add-rfi',
  EVENT_CHALLENGE_FOR_VENDOR: '/vendors/rfis/:id',
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGIN: '/auth/login',
  MAIN: '/',
  MANAGE_PRIMARY_COMPANY_CONTACT: '/company/manage/contact',
  MANAGE_USERS: '/company/manage/users',
  EDIT_USER: '/company/manage/users/:id',
  MANAGE_EMAIL_DOMAINS: '/company/manage/email-domains',
  PRIVACY: '/privacy',
  PUBLIC_CHALLENGE_INFO: '/public/:retailerSlug/rfis/:rfiSlug',
  PUBLIC_VENDOR_INFO: '/public/vendor/:slug',
  PUBLIC_VENDOR_INFO_TAB: '/public/vendor/:slug/:tab',
  PUBLIC_SEARCH_SOLUTION: '/public/search',
  RATINGS_REVIEWS: '/ratings-reviews',
  REGISTER: '/auth/register',
  REGISTRATION_SUCCESS: '/auth/thank-you',
  REQUEST_REVIEW: '/vendor/request-ratings-reviews',
  RETAILER_CHALLENGE_DASHBOARD: '/retailer/rfis',
  RETAILER_EVENT_DETAILS: '/retailer/events/:slug',
  RETAILER_EVENTS_DASHBOARD: '/retailer/events',
  SAVED_CHALLENGE: '/retailer/rfis/:id',
  SAVED_SOLUTIONS: '/vendors',
  SEARCH_SOLUTION_PAGE: '/retailer/search',
  RECOVER_PASSWORD: '/set-password',
  TERMS_AND_CONDITIONS: '/terms',
  USER_PROFILE: '/user/profile',
  VENDOR_CHALLENGE_DASHBOARD: '/vendor/rfis',
  VENDOR_EVENT_DETAILS: '/vendor/events/:slug',
  VENDOR_EVENTS_DASHBOARD: '/vendor/events',
  VENDOR_INFO: '/vendor/:slug',
  VENDOR_PROFILE_TAB: '/vendor/:slug/:tab',
  VENDOR_PAYMENTS: '/vendor-payments',
};

export default routes;
