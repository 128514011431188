/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { CheckmarkFilled } from '@fluentui/react-icons';
import FieldArrayFieldsType from 'types/FieldArrayFields';
import RatingField from '../RatingField';
import * as classes from './styles';

const RatingsFieldArray = ({ className, fields }) => (
  <div className={className} css={classes.container}>
    {fields.map((item, index) => (
      <Field
        key={index}
        css={classes.item}
        icon={<CheckmarkFilled />}
        label={fields.value[index].field}
        name={`${item}.value`}
        component={RatingField}
      />
    ))}
  </div>
);

RatingsFieldArray.propTypes = {
  className: PropTypes.string,
  fields: FieldArrayFieldsType.isRequired,
};

RatingsFieldArray.defaultProps = {
  className: null,
};

export default RatingsFieldArray;
