import APIClient from './client';

/**
 *  Important: it's an abstract class used by tag and integration API
 */
class CompanyPropertyAPI extends APIClient {
  getSuggestions = async (query) => {
    const filter = {
      limit: 10,
      where: {
        name: {
          like: query,
          options: 'i',
        },
      },
    };

    const { data } = await this.find(filter);

    return data;
  };

  getPropertyByName = async (query) => {
    const filter = {
      limit: 1,
      where: {
        name: {
          like: `^${query}$`,
          options: 'i',
        },
      },
    };

    const { data: [tag] } = await this.find(filter);

    return tag;
  };
}

export default CompanyPropertyAPI;
