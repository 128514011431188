/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import NoPendingRequestsIcon from 'svg/noPendingRequests';
import * as classes from './styles';

const NoRequestsFallback = () => (
  <div css={classes.container}>
    <div css={classes.iconWrapper}>
      <NoPendingRequestsIcon />
    </div>
    <Typography variant="subtitle2" css={classes.text}>
      There is not any requests
    </Typography>
  </div>
);

export default NoRequestsFallback;
