import PropTypes from 'prop-types';

const EventType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  background: PropTypes.string,
  primaryColor: PropTypes.string,
  maxTopicPriorities: PropTypes.number.isRequired,
});

export default EventType;
