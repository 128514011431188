import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button, FormHelperText, FormLabel } from '@mui/material';
import UploadIcon from 'svg/upload';
import './FileUpload.scss';

const FileUpload = ({
  accept,
  onUpload,
  restrictionInfo,
  isIcon,
  name,
  label,
  maxSize,
}) => {
  const [error, setError] = useState(null);

  const onDropAccepted = useCallback(
    (fileList) => {
      const [file] = fileList;

      setError(null);

      const formData = new FormData();

      formData.append('file', file);

      onUpload(formData)
        .then(({ body }) => body)
        .catch((e) => setError('Failed to save file on server'));
    },
    [onUpload],
  );

  const onDropRejected = useCallback(() => setError('Failed to upload file'), [setError]);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: false,
    accept,
    maxSize,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <>
      {isIcon ? (
        <label className="change-file-label">
          Change file
          <input {...getInputProps()} />
        </label>
      ) : (
        <div {...getRootProps()}>
          {!!label && <FormLabel className="file-upload-label">{label}</FormLabel>}
          <div className="file-upload">
            <div className="file-upload__heading">
              <UploadIcon className="file-upload__icon" />
              <p className="file-upload__title">Drop your files here</p>
            </div>

            <Button className="file-upload__open" name={name}>
              Open
            </Button>
            <div className="file-upload__restrictions">{restrictionInfo}</div>
            <input {...getInputProps()} />
          </div>
        </div>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  restrictionInfo: PropTypes.string.isRequired,
  isIcon: PropTypes.bool,
  name: PropTypes.string,
};

FileUpload.defaultProps = {
  label: null,
  isIcon: false,
  name: null,
};

export default FileUpload;
