import { css } from '@emotion/react';

export const message = (theme) => css`
  display: flex;
  align-items: center;

  ${theme.breakpoints.up('lg')} {
    width: 520px;
  }
`;

export const truncate = css`
  width: 100%
`;

export const button = (theme) => css`
  display: inline;
  border: none;
  box-sizing: content-box;
  padding: 0;
  background: none;
  cursor: pointer;
  font: inherit;
  color: ${theme.palette.primary.main};
  white-space: normal;
  word-wrap: break-word;
`;
