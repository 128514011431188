import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PublicChallenge from 'components/ChallengePages/PublicChallenge';
import FetchContainer from 'containers/FetchContainer';
import challengeAPI from 'api/challenge';
import withRouteGuards from 'containers/withRouteGuards';
import { publicChallengeSynopsisGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';
import companyAPI from 'api/company';

const PublicChallengePage = (props) => {
  const { rfiSlug: challengeSlug, retailerSlug } = useParams();
  const userCompanyId = useSelector((state) => state.user.company.id);

  const getInitialProps = useCallback(async () => {
    const challengeOwnerCompany = await companyAPI.getCompanyBySlug(retailerSlug);

    const {
      challengeResponses: [challengeResponse],
      shortlistedCompanies: [shortlistedCompany],
      participationRequests: [participationRequest],
      company,
      user,
      ...challenge
    } = await challengeAPI.getPublicChallengeDetails(
      challengeSlug,
      challengeOwnerCompany.id,
      userCompanyId,
    );

    return {
      challengeResponse,
      challenge,
      company,
      participationRequest,
      user,
      isShortlisted: !!shortlistedCompany,
    };
  }, [userCompanyId, challengeSlug, retailerSlug]);

  return (
    <FetchContainer getInitialProps={getInitialProps} component={PublicChallenge} {...props} />
  );
};

export default withRouteGuards([publicChallengeSynopsisGuard])(
  (withPageTitle(pageTitles.CHALLENGE)(PublicChallengePage)),
);
