import { css } from '@emotion/react';

const MAX_CONTENT_WIDTH = 1100;

const base = (theme) => css`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  transition: max-width 0.2s ease-in;

  ${theme.breakpoints.up('sm')} {
    padding: 30px 50px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 15px 25px;
  }

  ${theme.breakpoints.up('md')} {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export default base;
