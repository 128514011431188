import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import App from 'components/App';

const DEVELOPMENT_MODE = 'development';
const PRODUCTION_MODE = 'production';

/**
 * You must apply the local NODE_ENV to the init function
 * so that errors are reported accurately, and not as false
 * positives in Sentry
 */
Sentry.init({
  dsn: process.env.REACT_APP_BUILD_ENV === DEVELOPMENT_MODE || !process.env.REACT_APP_SENTRY_RELEASE
    ? null
    : process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_BUILD_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  tracesSampleRate: 0.2,
});

if (process.env.REACT_APP_BUILD_ENV === PRODUCTION_MODE) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TRACKING_ID });
}

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
