/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ChevronLeftRegular } from '@fluentui/react-icons';
import ListItem from 'components/UI/ListItem';
import PublicPageContentWrapper from 'components/UI/PublicPageContentWrapper';
import { GO_CARDLESS_LINK, LICENCE_PLAN_OPTIONS, paypalButtonConfig } from './constants';
import * as classes from './styles';

const VendorPayments = () => {
  const history = useHistory();

  return (
    <PublicPageContentWrapper>
      <div css={classes.main}>
        <div css={classes.container}>
          <IconButton color="primary" onClick={() => history.goBack()}>
            <ChevronLeftRegular css={classes.chevron} />
          </IconButton>
          <Typography variant="h1">
            Simple, transparent pricing
          </Typography>
        </div>
        <Typography sx={{ mb: 3 }} variant="body1">
          Choose what suits you best
        </Typography>

        <div css={classes.offers}>
          <div css={classes.box}>
            <div>
              <Typography variant="h2" component="span">£</Typography>
              <span css={classes.sum}>60</span>
              <Typography variant="subtitle1" component="span">/ month</Typography>
            </div>
            <Typography variant="body1" css={classes.billingPeriod}>
              Billed monthly
            </Typography>
            <form action={paypalButtonConfig.SUBMIT_URL} method="post" target="_top">
              <input type="hidden" name="cmd" value={paypalButtonConfig.ACTION} />
              <input type="hidden" name="hosted_button_id" value={paypalButtonConfig.ID} />
              <Button
                fullWidth
                type="image"
                src={paypalButtonConfig.BUTTON_SRC}
                border="0"
                name="submit"
              >
                Pay now
              </Button>
            </form>
          </div>

          <div css={classes.box}>
            <Typography variant="subtitle2" color="primary" css={classes.discount}>Save 15%</Typography>
            <div>
              <Typography variant="h2" component="span">£</Typography>
              <span css={classes.sum}>600</span>
              <Typography variant="subtitle1" component="span">/ year</Typography>
            </div>
            <Typography variant="body1" css={classes.billingPeriod}>
              Billed annually
            </Typography>
            <Button
              fullWidth
              onClick={() => {
                window.location.href = GO_CARDLESS_LINK;
              }}
            >
              Pay now
            </Button>
          </div>
        </div>

        <Typography sx={{ mb: 2 }} variant="subtitle1">
          Licence includes:
        </Typography>
        <ul>
          {LICENCE_PLAN_OPTIONS.map((item, i) => <ListItem key={i}>{item}</ListItem>)}
        </ul>
      </div>
    </PublicPageContentWrapper>
  );
};

export default VendorPayments;
