import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPublicVendorProfile } from 'store/thunk/company';
import VendorProfileForGuest from 'components/PublicVendorPage';
import FetchContainer from 'containers/FetchContainer';
import withRouteGuards from 'containers/withRouteGuards';
import withOldSlugReplacement from 'components/VendorInfo/withOldSlugReplacement';
import { publicVendorProfileGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const VendorProfileForGuestPage = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const getInitialProps = useCallback(async () => {
    await dispatch(getPublicVendorProfile(slug));
  }, [dispatch, slug]);

  return (
    <FetchContainer
      getInitialProps={getInitialProps}
      component={VendorProfileForGuest}
      {...props}
    />
  );
};

export default withRouteGuards([publicVendorProfileGuard])(
  withOldSlugReplacement(
    (withPageTitle(pageTitles.VENDOR_PROFILE)(VendorProfileForGuestPage)),
  ),
);
