import { css } from '@emotion/react';

export const section = css`
  display: flex;
  flex-wrap: wrap;
`;

export const fields = (theme) => (
  css`
    ${theme.breakpoints.up('sm')} {
      flex: 1;
    }

    ${theme.breakpoints.down('sm')} {
      order: 1;
      width: 100%;
    }
  `
);

export const emptyUpload = css`
  width: 100%;
`;

export const filledUpload = (theme) => (
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${theme.breakpoints.up('sm')} {
      margin-right: ${theme.spacing(2)};
    }
  `
);

export const uploadedImage = (theme) => (
  css`
    max-width: 110px;
    height: auto;
    margin-bottom: ${theme.spacing(1)};
  `
);

export const field = (theme) => (
  css`
    display: block;
    margin-bottom: ${theme.spacing(2)};
  `
);
