import { css } from '@emotion/react';

export const entry = (theme) => (
  css`
    display: flex;
    align-items: flex-end;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  `
);

export const input = css`
  flex: 1;
`;
