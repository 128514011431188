import PropTypes from 'prop-types';
import challengeResponseStatus from 'constants/challengeResponseStatus';
import challengeRequestParticipationStatus from 'constants/challengeParticipationRequestStatus';
import challengeLogTypes from 'constants/challengeLogType';

const ChallengeDashboardItem = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    retailers: PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      genericShortDescription: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  challengeResponses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      challengeId: PropTypes.string.isRequired,
      isParticipationRequestSent: PropTypes.bool,
      status: PropTypes.oneOf(Object.values(challengeResponseStatus)).isRequired,
    }).isRequired,
  ),

  participationRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.oneOf([
        challengeRequestParticipationStatus.REJECTED,
        challengeRequestParticipationStatus.PENDING,
      ]).isRequired,
      challengeId: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
    }).isRequired,
  ),

  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),

  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(challengeLogTypes)).isRequired,
    }).isRequired,
  ),
});

export default ChallengeDashboardItem;
