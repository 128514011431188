import { css } from '@emotion/react';

export const datepicker = (theme) => css`
  display: block;

  & svg {
    fill: ${theme.palette.text.primary};
  }
`;

export const input = (theme) => (
  css`
    display: block;
    margin-top: ${theme.spacing(1)};

    & .MuiIconButton-root {
      margin-right: 0 !important;
    }
  `
);
