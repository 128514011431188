/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { withSnackbar } from 'notistack';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SubmitButton from 'components/UI/SubmitButton';
import TextFieldInput from 'components/Form/TextFieldInput';
import contactFormTrigger from 'constants/contactFormTrigger';
import routes from 'constants/routes';
import { required } from 'lib/validation';
import companyAPI from 'api/company';
import * as classes from './styles';

const ContactForm = ({
  enqueueSnackbar,
  contactName,
  companyId,
  onSuccess,
  trigger,
}) => {
  const handleSubmitMessage = (values) => (
    companyAPI.contactVendor({ receiverCompanyId: companyId, triggeredBy: trigger, ...values })
      .then(() => {
        onSuccess();
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to send message', { variant: 'error' }))
  );

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Please be advised that all communications sent using the Validify Access platform are
        monitored by Validify in line with our{' '}
        <Link component={RouterLink} to={routes.TERMS_AND_CONDITIONS}>
          Terms &amp; Conditions
        </Link>
      </Typography>
      <Form
        onSubmit={handleSubmitMessage}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div css={classes.fields}>
              {!!contactName && (
                <TextField
                  sx={{ mb: 2 }}
                  name="contactPerson"
                  disabled
                  defaultValue={contactName}
                  fullWidth
                />
              )}
              <Field
                type="text"
                name="comment"
                validate={required}
                label="Message"
                component={TextFieldInput}
                multiline
                rows="3"
              />
            </div>
            <div css={classes.submit}>
              <SubmitButton
                isLoading={submitting}
                type="submit"
              >
                Send Message
              </SubmitButton>
            </div>
          </form>
        )}
      />
    </div>
  );
};

ContactForm.propTypes = {
  contactName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  trigger: PropTypes.oneOf(Object.values(contactFormTrigger)).isRequired,
};

export default withSnackbar(ContactForm);
