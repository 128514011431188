/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Edit20Regular, Eye20Regular } from '@fluentui/react-icons';
import { isEditable } from 'utils/challengeCheckers';
import DashboardChallengeType from 'types/DashboardChallengeType';
import * as classes from './styles';

const ChallengeCard = ({
  className,
  challenge,
  getMilestone,
  renderChallengeStatus,
  getChallengeDeadline,
  formatMilestoneDeadline,
  getChallengePathname,
  checkIsDraft,
  isSourceShown,
}) => {
  const nextMilestone = getMilestone(challenge);
  const isDraftChallenge = checkIsDraft(challenge);

  return (
    <div css={classes.card} className={className}>
      <Typography css={classes.challengeName} variant="subtitle2">
        {challenge.name}
      </Typography>
      <IconButton
        css={classes.icon}
        aria-label="Open challenge details"
        component={Link}
        to={getChallengePathname(challenge)}
      >
        {isEditable(challenge) ? <Edit20Regular /> : <Eye20Regular />}
      </IconButton>

      <div css={[classes.row, classes.splittedRow]}>
        <div css={classes.column}>
          <Typography css={classes.caption} variant="caption1">Deadline</Typography>
          <Typography variant="body2">{getChallengeDeadline(challenge)}</Typography>
        </div>

        <div css={classes.column}>
          <Typography css={classes.caption} variant="caption1">Status</Typography>
          <Typography variant="body2">{renderChallengeStatus(challenge)}</Typography>
        </div>
      </div>

      {(!!((challenge.event && isSourceShown) || !isDraftChallenge)) && (
        <div css={[classes.row, classes.splittedRow]}>
          {!!(challenge.event && isSourceShown) && (
            <div css={classes.column}>
              <Typography css={classes.caption} variant="caption1">Event</Typography>
              <Typography variant="body2">{challenge.event.name}</Typography>
            </div>
          )}

          {!isDraftChallenge && (
            <div css={classes.column}>
              <Typography css={classes.caption} variant="caption1">Responses</Typography>
              <Typography variant="body2">{challenge.challengeResponses.length}</Typography>
            </div>
          )}
        </div>
      )}

      {!!nextMilestone && (
        <div css={classes.row}>
          <Typography css={classes.caption} variant="caption1">Next milestone</Typography>
          <Typography variant="body2">
            <span css={classes.milestoneDueDate}>{formatMilestoneDeadline(nextMilestone)}</span>
            <span>{nextMilestone.name}</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

ChallengeCard.propTypes = {
  className: PropTypes.string,
  challenge: DashboardChallengeType.isRequired,
  formatMilestoneDeadline: PropTypes.func.isRequired,
  getMilestone: PropTypes.func.isRequired,
  renderChallengeStatus: PropTypes.func.isRequired,
  getChallengeDeadline: PropTypes.func.isRequired,
  getChallengePathname: PropTypes.func.isRequired,
  checkIsDraft: PropTypes.func.isRequired,
  isSourceShown: PropTypes.bool.isRequired,
};

ChallengeCard.defaultProps = {
  className: null,
};

export default ChallengeCard;
