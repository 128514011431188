/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextFieldInput from 'components/Form/TextFieldInput';
import { required } from 'lib/validation';
import companyAPI from 'api/company';
import routes from 'constants/routes';
import getErrorMessage from 'utils/getErrorMessage';
import ThankYouForRequest from '../ThankYouForRequest';
import * as classes from './styles';

const InviteNewUser = ({ handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isThankYouForRequestShown, setShowThankYouForRequest] = useState(false);

  const handleSendRequest = async (values) => {
    try {
      await companyAPI.contactValidifyAdmin(values);

      setShowThankYouForRequest(true);
    } catch (error) {
      const errorMessage = getErrorMessage(error, 'Failed to send message');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return isThankYouForRequestShown ? (
    <ThankYouForRequest onClose={handleClose} />
  ) : (
    <div>
      <Typography variant="h3" sx={{ mb: 2 }}>
        To access the Validify platform you need to login or register.
      </Typography>
      <div css={classes.buttons}>
        <div css={classes.button}>
          <Button component={Link} to={routes.LOGIN}>Login</Button>
        </div>
        <div css={classes.button}>
          <Button component={Link} to={routes.REGISTER}>Register</Button>
        </div>
      </div>

      <Typography variant="body1" sx={{ mb: 2 }}>
        Validify is a vendor management platform that gives consumer brands the tools to source,
        evaluate and manage the procurement of technology solutions into their business.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        If you would like to find out more about the Validify platform you can contact the team.
      </Typography>
      <Form
        onSubmit={handleSendRequest}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div css={classes.fields}>
              <Field
                sx={{ mb: 2 }}
                type="text"
                name="name"
                label="Name"
                component={TextFieldInput}
                validate={required}
              />
              <Field
                sx={{ mb: 2 }}
                type="email"
                name="userEmail"
                label="Email"
                component={TextFieldInput}
                validate={required}
              />
              <Field
                type="text"
                name="company"
                label="Company"
                component={TextFieldInput}
                validate={required}
              />
            </div>
            <div css={classes.submit}>
              <Button type="submit">Submit</Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

InviteNewUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default InviteNewUser;
