import PropTypes from 'prop-types';

const UserType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,

  phoneNumber: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string,
  }),
  position: PropTypes.string,
  emailVerified: PropTypes.bool,
  icon: PropTypes.string,
  description: PropTypes.string,
  keyRole: PropTypes.arrayOf(PropTypes.string.isRequired),

  linkedin: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
});

export default UserType;
