/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import PageBody from 'components/UI/PageBody';
import CompanyPrimaryContact from 'components/Shared/CompanyPrimaryContact';
import companyStatus from 'constants/companyStatus';
import routes from 'constants/routes';
import PrimaryContacts from '../PrimaryContacts';
import * as classes from './styles';

class ManagePrimaryContact extends Component {
  static propTypes = {
    companyType: PropTypes.oneOf(Object.values(companyStatus)).isRequired,
  };

  render() {
    const { companyType } = this.props;

    return (
      <PageBody>
        <Breadcrumbs sx={{ mb: 5 }}>
          <Link to={routes.MAIN}>Home</Link>
          <Link to={routes.MANAGE_USERS}>My company</Link>
          <Link to={routes.MANAGE_PRIMARY_COMPANY_CONTACT}>
            {companyType === companyStatus.RETAILER ? 'Primary contact' : 'Company contacts'}
          </Link>
        </Breadcrumbs>

        <Typography sx={{ mb: 5 }} variant="h1">Contacts</Typography>

        <div css={classes.section}>
          <Typography sx={{ mb: 1 }} variant="h2">Primary contact</Typography>
          <Typography sx={{ mb: 2 }} type="body1">
            The primary contact will receive messages and notifications from this platform.{' '}
            {companyType === companyStatus.VENDOR && (
              'The Sales, Marketing and Technical leads are for Validify internal use only'
            )}
          </Typography>
          <CompanyPrimaryContact css={classes.fields} />
        </div>

        {companyType === companyStatus.VENDOR && <PrimaryContacts />}
      </PageBody>
    );
  }
}

const mapStateToProps = (state) => ({
  companyType: state.user.company.type,
});

export default connect(mapStateToProps)(ManagePrimaryContact);
