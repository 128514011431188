import sortOrder from 'constants/sortOrder';
import APIClient from './client';

class EventTopicAPI extends APIClient {
  constructor() {
    super('/event-topics');
  }

  getEventTopics = async (eventId) => {
    const { data: topics } = await this.find({
      order: `name ${sortOrder.ASC}`,
      where: {
        eventId,
      },
    });

    return topics;
  };
}

const eventTopicAPI = new EventTopicAPI();

export default eventTopicAPI;
