import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import VendorProfile from 'components/VendorInfo';
import withOldSlugReplacement from 'components/VendorInfo/withOldSlugReplacement';
import withRouteGuards from 'containers/withRouteGuards';
import FetchContainer from 'containers/FetchContainer';
import { authorizedRouteGuard } from 'utils/routeGuard';
import { getSavedVendorList } from 'store/modules/savedVendors';
import { getPrivateVendorProfile } from 'store/thunk/company';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const VendorProfileForAuthenticatedPage = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const userId = useSelector((state) => state.user.authUser.id);

  const getInitialProps = useCallback(async () => {
    await Promise.all([
      dispatch(getPrivateVendorProfile(slug)),
      dispatch(getSavedVendorList(userId)),
    ]);
  }, [dispatch, slug, userId]);

  return <FetchContainer getInitialProps={getInitialProps} component={VendorProfile} {...props} />;
};

export default withRouteGuards([authorizedRouteGuard])(
  withOldSlugReplacement(
    (withPageTitle(pageTitles.VENDOR_PROFILE)(VendorProfileForAuthenticatedPage)),
  ),
);
