import { css } from '@emotion/react';

export const form = (theme) => css`
  padding: 0 ${theme.spacing(1.5)} ${theme.spacing(3)} 0;
`;

export const formButtonsWrap = (theme) => css`
  display: flex;
  gap: ${theme.spacing(3)};
`;

export const priorities = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);
