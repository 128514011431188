import { css } from '@emotion/react';

export const list = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const description = css`
  font-size: 12px;
`;
