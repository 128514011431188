import { css } from '@emotion/react';

export const dropdown = (theme) => css`
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 170px;
  padding: 0;
  background: ${theme.primaryPalette.white};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 10px rgba(50, 55, 64, 0.07));

  /* TODO: implement popper component */
  ${theme.breakpoints.up('lg')} {
    right: -140px;
  }

  ${theme.breakpoints.down('lg')} {
    top: 0;
    right: 0;
  }
`;

export const icon = (theme) => css`
  color: ${theme.palette.primary.main};
`;

export const dropdownButton = (theme) => css`
  display: flex;
  gap: 10px;
  padding: ${theme.spacing(2)} ${theme.spacing(1.5)};
  cursor: pointer;
`;
