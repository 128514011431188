import companyAPI from 'api/company';
import savedVendorAPI from 'api/savedVendor';
import { sendErrorNotification, sendSuccessNotification } from './notifications';

const GET_SAVED_VENDOR_SUCCESS = 'GET_SAVED_VENDOR_SUCCESS';
const ADD_VENDOR_TO_SAVED = 'ADD_VENDOR_TO_SAVED';
const DELETE_VENDOR_FROM_SAVED = 'DELETE_VENDOR_FROM_SAVED';

const getSavedVendorSuccess = (savedVendors) => ({
  type: GET_SAVED_VENDOR_SUCCESS,
  savedVendors,
});

const addCompanyLink = (companyLink) => ({
  type: ADD_VENDOR_TO_SAVED,
  companyLink,
});

const deleteVendorLink = (companyLinkId) => ({
  type: DELETE_VENDOR_FROM_SAVED,
  companyLinkId,
});

const initialValues = [];

const reducer = (state = initialValues, action) => {
  switch (action.type) {
    case GET_SAVED_VENDOR_SUCCESS:
      return action.savedVendors.filter((vendor) => vendor.company);

    case ADD_VENDOR_TO_SAVED:
      return [...state, action.companyLink];

    case DELETE_VENDOR_FROM_SAVED:
      return state.filter((item) => item.id !== action.companyLinkId);

    default:
      return state;
  }
};

export default reducer;

export const getSavedVendorList = (userId) => async (dispatch, getState) => {
  const { savedVendors } = getState();

  if (savedVendors.length) {
    return;
  }

  await savedVendorAPI.getSavedVendors(userId)
    .then((res) => dispatch(getSavedVendorSuccess(res.data)));
};

export const addVendorToSaved = (companyId, userId) => (dispatch) => (
  Promise.all([
    savedVendorAPI.create({ companyId, userId }),
    companyAPI.getVendorCompanyForSavedList(companyId),
  ])
    .then((res) => {
      const [{ data: savedVendorLink }, { data: company }] = res;

      const payload = { ...savedVendorLink, company };

      dispatch(sendSuccessNotification('Success'));
      dispatch(addCompanyLink(payload));
    })
    .catch((error) => (
      dispatch(sendErrorNotification(error, 'Failed to add to list'))
    ))
);

export const deleteVendorFromSaved = (companyId) => (dispatch, getState) => {
  const { savedVendors } = getState();
  const companyLink = savedVendors.find((item) => item.companyId === companyId);

  if (!companyLink) {
    return dispatch(sendErrorNotification(new Error('Cannot find vendor')));
  }

  return (
    savedVendorAPI.deleteById(companyLink.id)
      .then(() => {
        dispatch(sendSuccessNotification('Success'));
        dispatch(deleteVendorLink(companyLink.id));
      })
      .catch((error) => (
        dispatch(sendErrorNotification(error, 'Failed to remove from list'))
      ))
  );
};
