import { css } from '@emotion/react';

export const description = (theme) => (
  css`
    margin-bottom: ${theme.spacing(2)};
  `
);

export const item = (theme) => (
  css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `
);

export const fields = (theme) => (
  css`
    flex-grow: 1;
    margin-right: ${theme.spacing(1)};;
  `
);
