import userConstants from '../constants/user';

export const getUserSuccess = (user, eventMenu) => ({
  type: userConstants.GET_USER_SUCCESS,
  user,
  eventMenu,
});

export const getUserFail = (error) => ({
  type: userConstants.GET_USER_FAIL,
  error,
});

export const loginUserFail = (error) => ({
  type: userConstants.LOGIN_USER_FAIL,
  error,
});

export const clearUserError = () => ({
  type: userConstants.CLEAR_USER_ERROR,
});

export const resetUserState = () => ({
  type: userConstants.RESET_USER_STATE,
});

export const updateUser = (data) => ({
  type: userConstants.UPDATE_USER,
  data,
});

export const setCompanyUser = (company) => ({
  type: userConstants.SET_COMPANY_USER,
  company,
});

export const updateUserRoleSuccess = (id, role) => ({
  type: userConstants.UPDATE_USER_ROLE_SUCCESS,
  id,
  role,
});

export const deleteUserRoleSuccess = (id) => ({
  type: userConstants.DELETE_USER_ROLE_SUCCESS,
  id,
});

export const addActiveUser = (user) => ({ type: userConstants.ADD_ACTIVE_USER, user });
