import { css } from '@emotion/react';

export const hint = (theme) => (
  css`
    margin-bottom: ${theme.spacing(3)};
  `
);

export const card = (theme) => (
  css`
    max-width: 700px;

    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export const list = (theme) => (
  css`
    padding-top: ${theme.spacing(1.25)};
  `
);
