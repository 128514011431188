import { css } from '@emotion/react';

const field = (theme) => (
  css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);

export default field;
