import { css } from '@emotion/react';

export const container = (theme) => (
  css`
    display: flex;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    border-radius: 10px;

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3)} ${theme.spacing(2)};;
    }

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(2)} ${theme.spacing(4)};;
    }
  `
);

export const content = (theme) => (
  css`
    margin-top: ${theme.spacing(1)};
    font-family: ${theme.boldFont};
    line-height: 1.3;
  `
);

export const icon = (theme) => (
  css`
    height: auto;

    ${theme.breakpoints.down('sm')} {
      width: 40px;
      margin-right: ${theme.spacing(2)};;
    }

    ${theme.breakpoints.up('sm')} {
      width: 55px;
      margin-right: ${theme.spacing(4)};;
    }
  `
);

export const title = css`
  color: currentColor;
`;

export const list = (theme) => (
  css`
    padding-left: ${theme.spacing(2)};
    list-style-type: decimal;
  `
);
