import PropTypes from 'prop-types';
import { generatePath, Redirect } from 'react-router-dom';
import routes from 'constants/routes';
import getMatchType from 'types/Match';

const SavedChallengeRedirect = ({ match }) => {
  const { id } = match.params;
  const challengeUrl = generatePath(routes.SAVED_CHALLENGE, { id });

  return <Redirect to={challengeUrl} />;
};

SavedChallengeRedirect.propTypes = {
  match: getMatchType({ id: PropTypes.string.isRequired }),
};

export default SavedChallengeRedirect;
