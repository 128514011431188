const userActionType = {
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAIL: 'GET_USER_FAIL',
  LOGIN_USER_FAIL: 'LOGIN_USER_FAIL',
  RESET_USER_STATE: 'RESET_USER_STATE',
  UPDATE_USER: 'UPDATE_USER',
  SET_COMPANY_USER: 'SET_COMPANY_USER',
  CHANGE_TAB: 'CHANGE_TAB',
  CLEAR_USER_ERROR: 'CLEAR_USER_ERROR',
  UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
  DELETE_USER_ROLE_SUCCESS: 'DELETE_USER_ROLE_SUCCESS',
  ADD_ACTIVE_USER: 'ADD_ACTIVE_USER',
};

export default userActionType;
