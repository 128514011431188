import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SavedSolutions from 'components/SolutionsSearchPages/SavedSolutions';
import FetchContainer from 'containers/FetchContainer';
import withRouteGuards from 'containers/withRouteGuards';
import { getSavedVendorList } from 'store/modules/savedVendors';
import { authorizedRouteGuard, retailerRouteGuard } from 'utils/routeGuard';
import withPageTitle from 'containers/withPageTitle';
import pageTitles from 'constants/pageTitles';

const SavedSolutionsPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.authUser.id);

  const getInitialProps = useCallback(async () => {
    await dispatch(getSavedVendorList(userId));
  }, [userId, dispatch]);

  return <FetchContainer getInitialProps={getInitialProps} component={SavedSolutions} />;
};

export default withRouteGuards([authorizedRouteGuard, retailerRouteGuard])(
  (withPageTitle(pageTitles.SAVED_VENDORS)(SavedSolutionsPage)),
);
